<template>
  <hi-card>
    <module-block-editable v-if="projectDetails" class="view-block-spacious">
      <module-labeled-info label="Supply Date" value="1">
        <hi-dialog-trigger class="cursor-pointer">
          <hi-date-time-display
            v-if="projectDetails.estimateSupplyDate"
            month-only
            :model-value="projectDetails.estimateSupplyDate"
          />
          <span v-else>N/A</span>
          <project-change-supply-date-dialog :project-data="projectDetails" />
        </hi-dialog-trigger>
      </module-labeled-info>

      <module-labeled-info label="Build Status" value="1">
        <hi-dialog-trigger class="cursor-pointer">
          {{ projectDetails.buildStatus || "N/A" }}
          <project-change-build-status-dialog :project-data="projectDetails" />
        </hi-dialog-trigger>
      </module-labeled-info>
      <module-labeled-info label="Project Status" value="1">
        <hi-dialog-trigger class="cursor-pointer">
          {{ projectDetails.projStatus }}
          <project-change-proj-status-dialog :project-data="projectDetails" />
        </hi-dialog-trigger>
      </module-labeled-info>
    </module-block-editable>
  </hi-card>
</template>

<script>
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import { computed } from "vue";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import HiCard from "@/hive-vue3/components/HiCard";
import ModuleLabeledInfo from "@/hive-vue3/modules/_common/ModuleLabeledInfo";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ProjectChangeProjStatusDialog from "@/hive-vue3/modules/projects/parts/ProjectChangeProjStatusDialog";
import ProjectChangeBuildStatusDialog from "@/hive-vue3/modules/projects/parts/ProjectChangeBuildStatusDialog";
import ProjectChangeSupplyDateDialog from "@/hive-vue3/modules/projects/parts/ProjectChangeSupplyDateDialog";
export default {
  name: "ProjectStatusViewCard",
  components: {
    ProjectChangeSupplyDateDialog,
    ProjectChangeBuildStatusDialog,
    ProjectChangeProjStatusDialog,
    HiDialogTrigger,
    ModuleLabeledInfo,
    HiCard,
    HiDateTimeDisplay,
    ModuleBlockEditable,
  },
  setup() {
    const projectDetails = computed(() => {
      return getProjectById(projectsState.currentProjectId);
    });
    return {
      projectDetails,
    };
  },
};
</script>

<style scoped></style>
