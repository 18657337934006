<template>
  <span v-if="userdata">
    <span :class="{ ['font-bold']: !noBold }">{{ userdata.name }}</span>
    <span v-if="showEmail"> &nbsp;{{ userdata.email }}</span>
    <span v-if="showMobile"> &nbsp;{{ userdata.mobile }}</span>
  </span>
</template>

<script>
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { computed } from "vue";

export default {
  name: "UserDetailsSpan",
  props: {
    userId: String,
    showEmail: Boolean,
    showMobile: Boolean,
    noBold: Boolean,
  },
  setup(props) {
    return {
      userdata: computed(() => {
        if (props.userId) {
          return useFirestoreDoc("/system/users/people", props.userId);
        }
        return null;
      }),
    };
  },
};
</script>

<style scoped></style>
