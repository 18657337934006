<template>
  <div>
    <div class="relative w-full">
      <div style="visibility: hidden; min-height: 1em" ref="placeholder">x</div>
      <div class="absolute-origin w-full" ref="box1" :style="styles[0]"></div>
      <div class="absolute-origin w-full" ref="box2" :style="styles[1]"></div>
    </div>
  </div>
</template>

<script>
import { templateRef } from "@vueuse/core";
import { watchModelOnMounted } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { reactive } from "vue";

export default {
  name: "HiTextCarousel",
  props: {
    modelValue: String,
  },
  setup(props) {
    const box1 = templateRef("box1");
    const box2 = templateRef("box2");
    const placeholder = templateRef("placeholder");
    const preset = { opacity: 0, transition: "opacity 1s" };
    let currentBox = 0;
    const styles = [reactive({ ...preset }), reactive({ ...preset })];
    const boxes = [box1, box2];
    watchModelOnMounted(props, (val, oldVal) => {
      if (
        (!oldVal && val && val.length) ||
        (val && val.length > oldVal.length)
      ) {
        //only set placeholder to the bigger content
        placeholder.value.innerHTML = val;
      }
      let style = styles[currentBox];
      style.opacity = 0;
      // console.log(val);
      //if val is empty, just fadeout current.
      if (!val || !val.length) {
        return;
      }
      currentBox = 1 - currentBox;
      style = styles[currentBox];
      style.opacity = 1;
      boxes[currentBox].value.innerHTML = val;
    });
    return {
      styles,
    };
  },
};
</script>

<style scoped></style>
