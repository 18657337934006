<template>
  <div v-if="user" class="flex gap-6">
    <system-user-avatar big :user-id="userId" />
    <div>
      <div class="tp-big-fancy-display">{{ user.name }} {{ user.surname }}</div>
      <div class="tp-subtitle mb-2">{{ user.email }}</div>
      <!--      <div class="tp-description">{{ user.id + ".roles" }}{{ user.roles }}</div>-->
      <hi-tagger
        emptyValue="sales"
        :items="systemRoles"
        v-model="roleTaggerValue"
        multiple
        can-edit
        label="Roles"
      />
      <!--      <hi-fire-doc collection-path="system" doc-id="userPermissions">-->
      <!--        <hi-fire-doc-tagger-->
      <!--          -->
      <!--          :value-key="user.id + '.roles'"-->
      <!--          can-edit-->
      <!--          multiple-->
      <!--        />-->
      <!--      </hi-fire-doc>-->
    </div>
    <hr />
  </div>
</template>

<script>
// import HiAvatar from "@/hive-vue3/components/HiAvatar";
import {
  firestoreDoc,
  updateFirestoreDoc,
  useSystemRoles,
  useSystemUserReactiveId,
} from "@/hive-vue3/firebase";
// import HiFireDocTagger from "@/hive-vue3/components/fireDoc/HiFireDocTagger";
// import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import HiTagger from "@/hive-vue3/components/HiTagger";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { ref, watch, watchEffect } from "vue";
import { isEqual } from "lodash";
import { deleteField } from "firebase/firestore";
import SystemUserAvatar from "@/hive-vue3/modules/users/SystemUserAvatar";
export default {
  name: "SystemUserDetails",
  components: { SystemUserAvatar, HiTagger },
  props: {
    userId: String,
  },
  setup(props) {
    const user = useSystemUserReactiveId(props);
    const systemRoles = useSystemRoles();
    const permissionsDocRef = firestoreDoc("system", "userPermissions");
    const permissionsDoc = useFirestoreDoc(permissionsDocRef);
    const roleTaggerValue = ref();

    watchEffect(() => {
      const userPermission = permissionsDoc[props.userId];
      if (!userPermission) return;
      roleTaggerValue.value = userPermission.roles;
    });
    watch(roleTaggerValue, (v) => {
      if (!props.userId) return;
      const userPermission = permissionsDoc[props.userId];

      //this is like a bi-direction sync. Needs to check if value is new.
      if (!isEqual(v, userPermission.roles)) {
        if (!v || !v.length) {
          v = deleteField();
        }
        updateFirestoreDoc(
          permissionsDocRef,
          { [props.userId]: { roles: v } },
          { meta: false }
        );
      }
    });
    return {
      user,
      systemRoles,
      roleTaggerValue,
    };
  },
};
</script>

<style scoped></style>
