<template>
  <hi-image :src="url" />
</template>

<script>
import HiImage from "@/hive-vue3/components/HiImage";
import { ref, watchEffect } from "vue";
import { getVaultFileData } from "@/hive-vue3/firebase/fileVault";
export default {
  name: "HiVaultImage",
  components: { HiImage },
  props: {
    fileId: String,
  },
  setup(props) {
    const url = ref("");
    watchEffect(async () => {
      const file = await getVaultFileData(props.fileId);
      // console.log(file);
      if (file) url.value = file.thumbUrl || file.url;
    });
    return {
      url,
    };
  },
};
</script>

<style scoped></style>
