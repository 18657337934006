<template>
  <div class="___hi-image inline-block relative" ref="container">
    <!--    按比例撑大小，显示图片加载错误-->
    <div :style="ratioStyles" class="w-full max-w-full min-w-fit min-h-fit">
      <div
        v-if="!hideError && state.error"
        class="text-rose-300 text-left min-h-fit"
        style="min-width: 200px"
      >
        <img :src="src" />
        <!--        <div class="flex items-center gap-2">-->
        <!--          <hi-svg-->
        <!--            :path="imageNotFound.path"-->
        <!--            :view-box="imageNotFound.viewBox"-->
        <!--            style="width: 1.5em; fill: currentColor"-->
        <!--          />-->
        <!--          <div>Image Not Found!</div>-->
        <!--        </div>-->
        <!--        <div class="text-xs">src: {{ src }}</div>-->
      </div>
    </div>
    <!--    背景图片显示在这里-->
    <div
      style="background-position: center; background-repeat: no-repeat"
      :style="styles"
      class="w-full h-full absolute left-0 top-0 max-w-full overflow-hidden"
      ref="img"
    >
      <!--        @slot a full size container for contents display  -->
      <slot />
      <router-link
        v-if="link"
        :to="link"
        class="block absolute top-0 left-0 w-full h-full"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch, watchEffect } from "vue";
import preloadImage from "@/hive-vue3/utils/preloaders/preloadImage";
import { templateRef } from "@vueuse/core";
import useElementVisible from "@/hive-vue3/utils/sensors/useElementVisible";
import { wait } from "@/hive-vue3/utils/miscs";
// import imageNotFound from "@/hive-vue3/assets/svg/paths/imageNotFound";
// import HiSvg from "@/hive-vue3/components/HiSvg";

/**
 * 使用 background image 显示图片
 * 不指定尺寸和比例的情况下基本和 img 标签相似。
 * aspectRatio - height/width
 *
 * 动态改变 src 必须提供 ratio 或者 尺寸！否者尺寸将根据第一个图片计算。
 *
 * //todo to support dynamic changed src without ratio or size.
 *
 */
export default {
  name: "HiImage",
  // components: { HiSvg },
  props: {
    src: { type: String },
    /**
     * height/width
     */
    aspectRatio: { type: Number },
    aspectSquare: Boolean, //1:1
    aspect43: Boolean, //4:3
    aspectGolden: Boolean, //16:9
    aspectCine: Boolean, //2.35:1
    aspectPanorama: Boolean, //32:9

    /**
     * cover - default. See css cover
     * contain - See css contain
     * stretch - stretch image to match size
     */
    mode: {
      type: String,
      default: "cover",
      validator(value) {
        // The value must match one of these strings
        return ["cover", "contain", "stretch"].includes(value);
      },
    },
    /**
     * fade in when image loaded
     */
    fadeIn: Boolean,
    /**
     * fade in only when image is visible.
     * Non-reactive support only.
     */
    fadeOnScroll: Boolean,
    /**
     * percentage of visible area of the image when considered as visible. default 20%.
     * used for fadeOnScroll
     */
    scrollThreshold: {
      type: Number,
      default: 0.2,
    },
    /**
     * fade in speed. default 0.5s
     */
    fadeInSpeed: {
      type: String,
      default: "1s",
    },
    fadeInToOpacity: {
      type: Number,
      default: 1,
    },
    /**
     * See vue-router to
     */
    link: {
      type: [String, Object],
    },
    hideError: Boolean,
    debug: Boolean,
  },
  emits: ["load", "error"],
  setup(props, { emit }) {
    const img = templateRef("img");
    const originSize = reactive({ width: 0, height: 0 });
    const styles = reactive({
      "background-image": props.src ? "url(" + props.src + ")" : "none",
    });
    const ratioStyles = reactive({
      width: "0",
      height: "0",
    });
    const state = reactive({
      loaded: false,
      error: false,
    });

    watchEffect(() => {
      if (props.mode === "stretch") {
        styles["background-size"] = "100% 100%";
      } else {
        styles["background-size"] = props.mode;
      }

      let ratio;
      if (props.aspectSquare) {
        //1:1
        ratio = 1;
      } else if (props.aspect43) {
        //4:3
        ratio = 3 / 4;
      } else if (props.aspectGolden) {
        //16:9
        ratio = 9 / 16;
      } else if (props.aspectCine) {
        ratio = 1 / 2.35;
      } else if (props.aspectPanorama) {
        ratio = 9 / 32;
      } else {
        ratio = props.aspectRatio;
      }

      if (props.debug) {
        console.log("debug", props.src);
        console.log("specified ratio", ratio);
      }

      if (img.value) {
        //already mounted
        const el = img.value;
        //if height hasn't been set, use origin ratio
        if (!ratio && originSize.height && !el.clientHeight) {
          ratio = originSize.width ? originSize.height / originSize.width : 0;
          if (props.debug) {
            console.log("height not set. Use original ratio.", ratio);
          }
        }
        // if width hasn't been set, use origin width
        if (originSize.width && !el.clientWidth) {
          if (props.debug) {
            console.log("width not set. Use original width.", originSize);
          }
          ratioStyles.width = originSize.width + "px";
        }
      }

      if (ratio) {
        // if (typeof ratio !== "number") {
        //   //support for aspectRatio===true
        //   if (state.error) return;
        //   ratio = originSize.width ? originSize.height / originSize.width : 0;
        // }
        // ratioStyles.height = 0;
        //percentage padding is related to parent width
        ratioStyles["padding-top"] = ratio * 100 + "%";
      }
    });
    if (props.fadeOnScroll) {
      // eslint-disable-next-line no-unused-vars
      const isVisible = useElementVisible(img, props.scrollThreshold);
      watch(isVisible, (v) => {
        // console.log("visible", v);
        if (v) styles.opacity = props.fadeInToOpacity;
        else styles.opacity = 0;
      });
    }

    onMounted(() => {
      // const displayHeight = img.value.clientHeight;
      // if (displayHeight === 0) {
      //   watchEffect(() => {
      //     console.log(img.value.clientHeight, img.value.clientWidth);
      //     if (originSize.width && img.value.clientHeight === 0)
      //       ratioStyles.width = originSize.width + "px";
      //   });
      // }
      watchEffect(() => {
        if (!props.src) {
          styles["background-image"] = "none";
          state.error = false;
          return;
        }
        // console.log(props.src);
        if (props.fadeIn || props.fadeOnScroll) {
          // console.log("set opacity to 0");
          styles.opacity = 0;
          styles.transition = "opacity " + props.fadeInSpeed;
        }

        preloadImage(props.src)
          .then((size) => {
            originSize.width = size.width;
            originSize.height = size.height;
            state.loaded = true;
            state.error = false;
            styles["background-image"] = `url("${props.src}")`;
            // console.log("url(" + props.src + ")");
            // console.log(styles);
            emit("load");
            if (props.fadeIn) {
              wait(200).then(() => {
                styles.opacity = props.fadeInToOpacity;
              });
            }
          })

          .catch(() => {
            // console.warn(e);
            state.error = true;
            emit("error");

            ratioStyles.height = "auto";
            ratioStyles.width = "auto";
          });
      });
    });

    return { styles, state, ratioStyles };
  },
};
</script>

<style scoped></style>
