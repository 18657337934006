<template>
  <hi-card>
    <div class="p-2 flex flex-row-reverse gap-2">
      <!--      <hi-button primary :disabled="!sendEnable">Send</hi-button>-->

      <hi-button @click="onTax" :disabled="!sendEnable">Tax-Invoice</hi-button>
      <hi-button @click="onProForma" :disabled="!sendEnable"
        >Pro-Forma</hi-button
      >

      <hi-button
        @click="invoiceState.currentMiddleColView = 'edit'"
        :disabled="invoiceState.isUnSaved || isSend"
        >Edit</hi-button
      >
    </div>
    <hi-dialog v-model="state.previewDialog" height="800px" width="1200px">
      <hi-pdf-viewer :source="state.previewSrc" />
      <template v-slot:footer>
        <hi-button @click="downloadInvoice" :disabled="!state.downloadEnable">
          Download
        </hi-button>
      </template>
    </hi-dialog>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import HiButton from "@/hive-vue3/components/HiButton";
import { invoiceState } from "@/hive-vue3/modules/invoice/state";
import { getInvoiceById } from "@/hive-vue3/modules/invoice/data";
import { computed, reactive } from "vue";
import {
  formSendEnable,
  requestInvoiceFile,
  requestInvoicePdf,
} from "@/hive-vue3/modules/quotation/data";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiPdfViewer from "@/hive-vue3/components/HiPdfViewer";
import { downVaultFile } from "@/hive-vue3/firebase/fileVault";
import hive from "@/hive-vue3/components/hive";
export default {
  name: "InvoiceFunctionCard",
  components: { HiPdfViewer, HiDialog, HiButton, HiCard },
  setup() {
    const state = reactive({
      previewDialog: false,
      previewSrc: undefined,
      downloadEnable: true,
      isInv: false,
    });

    async function onProForma() {
      state.isInv = false;
      const invoiceData = getInvoiceById(invoiceState.currentInvoiceId);

      const res = await requestInvoicePdf(invoiceData);
      if (res) {
        // console.log(res);
        state.previewSrc = res;
        state.previewDialog = true;
      }
    }

    async function onTax() {
      state.isInv = true;
      const invoiceData = getInvoiceById(invoiceState.currentInvoiceId);

      const res = await requestInvoicePdf(invoiceData, true);
      if (res) {
        // console.log(res);
        state.previewSrc = res;
        state.previewDialog = true;
      }
    }

    const sendEnable = computed(() => {
      const invoiceData = getInvoiceById(invoiceState.currentInvoiceId);
      return formSendEnable(invoiceData) && !invoiceState.isUnSaved;
    });

    const isSend = computed(() => {
      const invoiceData = getInvoiceById(invoiceState.currentInvoiceId);
      return invoiceData.status === "send";
    });

    async function downloadInvoice() {
      state.downloadEnable = false;
      const invoiceData = getInvoiceById(invoiceState.currentInvoiceId);
      invoiceData.type = state.isInv ? "tax" : "proForma";

      const res = await requestInvoiceFile(invoiceData);
      if (res) {
        downVaultFile(res);
      } else {
        hive.toastWarning("Request Error ");
      }
      state.downloadEnable = true;
    }

    return {
      onProForma,
      state,
      invoiceState,
      isSend,
      sendEnable,
      downloadInvoice,
      onTax,
    };
  },
};
</script>

<style scoped></style>
