<template>
  <layout-three-column
    module-name="enquiry"
    :page-name="name"
    @backToSummary="enquiryState.currentEnquiryId = undefined"
  >
    <template v-slot:col1>
      <hi-card class="h-full w-full">
        <hi-div flex-col-full-height-to-support-grow>
          <div class="bg-light-400 p-4 flex items-center justify-between">
            <div class="tp-title-faded">Enquiry</div>
            <new-enquiry-dialog />
          </div>
          <div class="grow">
            <hi-tab-group v-model="currentTab" class="h-full">
              <hi-tab class="h-full pt-1" label="open">
                <enquiry-list class="h-full" />
              </hi-tab>
              <hi-tab class="h-full pt-1" label="closed">
                <enquiry-closed-list class="h-full" />
              </hi-tab>
            </hi-tab-group>
          </div>
        </hi-div>
      </hi-card>
    </template>
    <template v-if="enquiryState.currentEnquiryId" v-slot:col2>
      <enquiry-details
        class="h-full"
        :enquiry-id="enquiryState.currentEnquiryId"
      />
    </template>
    <template v-if="enquiryState.currentEnquiryId" v-slot:col3>
      <hi-tab-group class="h-full">
        <hi-tab label="Activity">
          <activity-list class="h-full overflow-auto" />
        </hi-tab>
        <hi-tab label="History">
          <history-viewer
            class="h-full overflow-auto"
            :target-id="enquiryState.currentEnquiryId"
            target="enquiry"
          />
        </hi-tab>
        <hi-tab label="Emails">
          <gmail-thread-common-viewer
            class="h-full overflow-auto"
            :email="email"
            :pipe-id="pipeId"
            target="enquiry"
            :target-id="enquiryState.currentEnquiryId"
          />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:summary>
      <basic-enquiry-num-card class="w-1/2" />
    </template>
  </layout-three-column>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import EnquiryList from "@/hive-vue3/modules/enquiries/EnquiryList";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import EnquiryClosedList from "@/hive-vue3/modules/enquiries/EnquiryClosedList";
import HiDiv from "@/hive-vue3/components/HiDiv";
import NewEnquiryDialog from "@/hive-vue3/modules/enquiries/NewEnquiryDialog";
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import EnquiryDetails from "@/hive-vue3/modules/enquiries/EnquiryDetails";
import { enquiryState } from "./state";
import { computed, ref, watch, watchEffect } from "vue";
import {
  getEnquiryById,
  getEnquiryPipeIdById,
} from "@/hive-vue3/modules/enquiries/data";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
import HistoryViewer from "@/hive-vue3/modules/history/HistoryViewer";
import GmailThreadCommonViewer from "@/hive-vue3/modules/gmail/components/GmailRelativeMailsCommonViewer";
import { getContactsPeople } from "@/hive-vue3/modules/contacts/data";
import ActivityList from "@/hive-vue3/modules/activities/ActivityList";
import { provideActivityData } from "@/hive-vue3/modules/activities/data";
import { useRouter } from "vue-router";
import BasicEnquiryNumCard from "@/hive-vue3/modules/enquiries/dashboardCards/BasicEnquiryNumCard";

export default {
  name: "EnquiriesViewFullKit",

  components: {
    BasicEnquiryNumCard,
    ActivityList,
    GmailThreadCommonViewer,
    HistoryViewer,
    EnquiryDetails,
    LayoutThreeColumn,
    NewEnquiryDialog,
    HiDiv,
    EnquiryClosedList,
    HiTab,
    HiTabGroup,
    EnquiryList,
    HiCard,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    const pipeId = computed(() => {
      if (enquiryState.currentEnquiryId) {
        return getEnquiryPipeIdById(enquiryState.currentEnquiryId);
      }
      return "";
    });

    const currentTab = ref("open");

    watchEffect(() => {
      if (enquiryState.currentEnquiryId) {
        const enquiry = getEnquiryById(enquiryState.currentEnquiryId);
        if (enquiry) {
          if (enquiry.status === "open") {
            currentTab.value = "open";
          } else {
            currentTab.value = "closed";
          }
        } else {
          currentTab.value = "open";
        }
      } else {
        currentTab.value = "open";
      }
    });

    provideActivityData(pipeId);
    setRouterId(props, enquiryState, "currentEnquiryId", "/enquiries/");
    const router = useRouter();

    watch(
      () => enquiryState.currentEnquiryId,
      (v) => {
        if (v) {
          router.push("/enquiries/" + v);
        } else {
          router.push("/enquiries");
        }
      }
    );

    return {
      enquiryState,
      pipeId,
      email: computed(() => {
        const enquiryDetails = getEnquiryById(enquiryState.currentEnquiryId);
        if (enquiryDetails && enquiryDetails.peopleId) {
          const peopleDetails = getContactsPeople(enquiryDetails.peopleId);
          if (peopleDetails && peopleDetails.email) {
            return peopleDetails.email;
          }
        }
        return "";
      }),
      name: computed(() => {
        const enquiryDetails = getEnquiryById(enquiryState.currentEnquiryId);
        if (enquiryDetails && enquiryDetails.name) {
          return enquiryDetails.name;
        }
        return undefined;
      }),
      currentTab,
    };
  },
};
</script>

<style scoped></style>
