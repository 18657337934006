<template>
  <system-users-full-kit class="h-full" />
</template>

<script>
import SystemUsersFullKit from "@/hive-vue3/modules/users/SystemUsersFullKit";
export default {
  name: "UsersView",
  components: { SystemUsersFullKit },
};
</script>

<style scoped></style>
