<template>
  <div class="">
    <div class="grow">
      <div class="tp-subtitle flex items-center">
        <div class="ellipse-flex-grow-item">
          <contacts-entity :entity-data="entity" />
        </div>
        <hi-tags
          small
          v-if="entity.tags"
          :model-value="entity.tags"
          :tag-doc-path="dbPaths.entityTagDoc"
        >
        </hi-tags>
      </div>
    </div>
    <div v-if="entity.address" class="tp-description-sm">
      {{ entity.address.vicinity }}

      {{ entity.address.postcode }} {{ entity.address.stateShort }}
    </div>
    <div v-else-if="entity.registration" class="tp-description-sm">
      {{ entity.registration.postcode }}
      {{ entity.registration.stateCode }}
    </div>
    <!--    =========== popup ==============-->
  </div>
</template>

<script>
import HiTags from "@/hive-vue3/components/HiTags";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import { computed } from "vue";
import { useContactsPeople } from "@/hive-vue3/modules/contacts/data";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
export default {
  name: "ContactsEntitySummary",
  components: { ContactsEntity, HiTags },
  props: {
    entityId: String,
    entityData: Object,
    popup: Boolean,
  },
  setup(props) {
    return {
      dbPaths,
      entity: computed(() => {
        if (props.entityData) return props.entityData;
        else if (props.entityId) return useContactsPeople(props.entityId);
        return null;
      }),
    };
  },
};
</script>

<style scoped></style>
