import { firestoreCollection, getSnapshotData } from "./utils";
import { getCurrentScope, onScopeDispose, reactive } from "vue";
import { onSnapshot, query, where as w } from "firebase/firestore";

function isDef(v) {
  return v !== undefined && v !== null;
}

/**
 *
 * @param colRefOrCalPath
 * @param options
 * @returns {reactive<{data: *[], where: function}>}
 */
export default function (colRefOrCalPath, options = { persistData: false }) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;
  const data = reactive({
    data: [],
    where: qWhere,
  });
  const path =
    colRefOrCalPath && colRefOrCalPath.path
      ? colRefOrCalPath.path
      : colRefOrCalPath;
  let whereResult;
  let close;
  function listen() {
    // console.log("listen");
    if (initTimeout) {
      clearTimeout(initTimeout);
      initTimeout = undefined;
    }
    stopListen();
    const theCol = firestoreCollection(path);
    // console.log(".......................");
    // console.log(theCol);
    const q = whereResult ? query(theCol, whereResult) : query(theCol);
    // console.log(q);
    close = onSnapshot(
      q,
      (snapshot) => {
        data.data = snapshot.docs.map(getSnapshotData).filter(isDef);
        // console.log("snap", data);
      },
      errorHandler
    );
  }
  function stopListen() {
    // console.log("stop listen");
    if (close) close();
  }
  function qWhere(field, op, value) {
    whereResult = w(field, op, value);
    listen();
  }
  let initTimeout = setTimeout(() => {
    // console.log("let's listen");
    listen();
  }, 100);

  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      stopListen();
    });
  }
  return data;
}
