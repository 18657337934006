<template>
  <span class="max-w-full">
    <span class="inline-flex flex-wrap max-w-full gap-2">
      <hi-chip
        v-for="item in cItems"
        :key="item.value"
        :selected="isSelected(item)"
        selectable
        @click="selectItem(item)"
      >
        <span>{{ item.text }}</span>
        <span
          v-if="secondaryTextKey && item.secondaryText"
          class="opacity-80 font-normal"
        >
          ({{ item.secondaryText }})</span
        >
      </hi-chip>
      <hi-chip v-if="multiple && canSelectAll" functional @click="selectAll">
        Select All</hi-chip
      >
      <hi-chip functional v-if="docPath && canAdd && !adding"
        ><span
          @click="
            adding = true;
            $emit('tagAddingStarted');
          "
          class="text-xs text-center w-[50px] inline-block"
          >Add New</span
        ></hi-chip
      >
      <hi-chip v-else-if="adding" editable :style="editStyles">
        <hi-simple-inline-input
          class="min-w-[50px]"
          :submit-fn="submitNewTag"
          :cancel-fn="cancelAdding"
        />
      </hi-chip>
      <!-- @slot Use this for more built in chips usually functional chips -->
      <slot />
    </span>
  </span>
</template>

<script>
import formControl from "../logics/formControl";

import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import textValuePair from "@/hive-vue3/components/_commonLogic/textValuePair";
import HiChip from "@/hive-vue3/components/HiChip";
import HiSimpleInlineInput from "@/hive-vue3/components/HiSimpleInlineInput";
import { reactive, ref } from "vue";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import {
  encodeDocId,
  firestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import hive from "@/hive-vue3/components/hive";

export default {
  name: "HiChipsSelectable",
  components: { HiSimpleInlineInput, HiChip },
  props: {
    /**
     * [{text,value}]
     */
    items: {
      type: [Array, Object],
    },
    textKey: {
      type: String,
      default: "text",
    },
    secondaryTextKey: String,
    valueKey: {
      type: String,
      default: "value",
    },
    multiple: Boolean,
    mandatory: Boolean,
    modelValue: null,
    formKey: String,
    noForm: Boolean,
    canSelectAll: Boolean,
    /**
     * to remove items starts with "__"
     */
    removePrivateItems: Boolean,
    collectionPath: String,
    docPath: {
      type: String,
    },
    canAdd: Boolean,
  },
  emits: [
    "change",
    "update:modelValue",
    "tagAddingCanceled",
    "tagAddingStarted",
    "tagAdded",
  ],
  setup(props, context) {
    if (props.docPath && props.items) {
      console.warn(
        `HiChipsSelectable: props.items has been specified. props.docPath ${props.docPath} has been ignored!`
      );
    }
    const control = formControl(props, context, {});
    const model = control.model;

    let doc, docRef;
    if (props.docPath) {
      if (props.collectionPath) {
        doc = useFirestoreDoc(props.collectionPath, props.docPath);
        docRef = firestoreDoc(props.collectionPath, props.docPath);
      } else {
        doc = useFirestoreDoc(props.docPath);
        docRef = firestoreDoc(props.docPath);
      }
    }
    const cItems = textValuePair(props, doc);
    // console.log(cItems);
    function isSelected(item) {
      // console.log(item);
      // console.log(model.value);
      if (!model.value) return false;
      const itemVal = item.value;
      if (Array.isArray(model.value)) {
        // console.log("is array");
        for (const v of model.value) {
          // console.log(v, item.value);
          if (v === itemVal) return true;
        }
      } else {
        if (itemVal === model.value) return true;
      }

      return false;
    }
    function selectItem(item) {
      // console.log(model.value);
      // console.log(cItems);
      const selected = Array.isArray(model.value)
        ? (model.value && [...model.value]) || []
        : model.value
        ? [model.value]
        : [];
      const itemVal = item.value;
      // console.log(model);
      // console.log(selected);
      // console.log("item", item.value);
      if (props.multiple) {
        if (isSelected(item)) {
          // console.log("remove", item.value);
          arrayRemove(selected, itemVal);
        } else {
          // console.log(state.selected.length);
          selected.push(itemVal);
          // console.log(state.selected.length);
          // console.log(JSON.stringify(state));
          // console.log(state.selected);
        }
      } else {
        const sel = selected[0];
        if (sel === itemVal) {
          selected.length = 0;
        } else {
          selected[0] = itemVal;
          // console.log(item);
        }
      }
      setModelValue(selected);
    }
    function setModelValue(selected) {
      // console.log(selected);
      // console.log("******************");
      let val;
      if (!selected.length) {
        val = null;
      } else if (props.multiple) {
        // console.log(selected.length);
        val = selected;
      } else {
        val = selected[0];
      }
      model.value = val;
      // console.log("emit", val);
      context.emit("change", val);
    }
    function selectAll() {
      if (!props.multiple) {
        console.warn(
          "HiSelect.selectAll is not allowed when multiple is turned off."
        );
        return;
      }
      const selected = [];
      for (const item of cItems.value) {
        selected.push(item.value);
      }
      setModelValue(selected);
    }
    ////// adding //////////////////
    const adding = ref(false);
    //the style for inline editor
    const editStyles = reactive({ opacity: 1 });
    const editing = ref(false);

    function initStyle() {
      editStyles.opacity = 1;
    }
    async function submitNewTag(text) {
      editStyles.opacity = 0.5;
      const key = encodeDocId(text.trim().toLowerCase());
      if (doc[key]) {
        hive.toastError(`Tag '${text}' already exists!`);
        initStyle();
        return;
      }
      const data = {};
      data[key] = text;
      await updateFirestoreDoc(docRef, data);

      if (props.multiple) {
        if (model.value && model.value.push) {
          model.value.push(key);
        } else {
          model.value = [key];
        }
        context.emit("change", model.value);
        context.emit("tagAdded", key);
      } else {
        model.value = key;

        editing.value = false;
      }
      adding.value = false;
      initStyle();
    }
    function cancelAdding() {
      initStyle();
      context.emit("tagAddingCanceled");
      adding.value = false;
    }
    return {
      submitNewTag,
      cancelAdding,
      adding,
      editing,
      editStyles,
      isSelected,
      selectItem,
      cItems,
      selectAll,
    };
  },
};
</script>

<style scoped>
/*.mnp {*/
/*  @apply mr-2 mb-1 mt-1 p-2 pt-0.5 pb-0.5;*/
/*}*/
/*.selectable-chip {*/
/*  @apply mnp rounded-lg bg-secondary text-secondary-contrast text-sm cursor-pointer bg-opacity-30 transition-colors;*/
/*  letter-spacing: 0.05em;*/
/*}*/
/*.selectable-chip-selected {*/
/*  @apply bg-primary text-primary-contrast;*/
/*}*/
/*.selectAll {*/
/*  @apply mnp text-xxs text-gray-400 rounded-lg;*/
/*}*/
</style>
