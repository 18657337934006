export function lazyFunction(fn, delayMs) {
  let timeout;
  return (...argu) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => fn(...argu), delayMs);
  };
}
export function tryRun(fn) {
  try {
    fn();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
