export default function (url) {
  // if (process && process.server) {
  //   return new Promise((resolve) => {
  //     resolve();
  //   });
  // }
  const img = document.createElement("img");
  img.src = url;
  img.style.position = "fixed";
  img.style.bottom = "-100px";
  img.style.visibility = "hidden";
  document.body.appendChild(img);
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const size = { width: img.clientWidth, height: img.clientHeight };
      img.remove();
      // console.log("imgloaded", url);
      resolve(size);
    };
    img.onerror = reject;
  });
}
