<template>
  <hi-data-list-kit
    :sortables="sortables"
    :search-fn="enquiryListSearchFn"
    search-input-placeholder="Name, Email or Mobile"
    @select="onSelect"
    :current-id="enquiryState.currentEnquiryId"
  >
    <template v-slot="{ item }">
      <enquiry-list-item :enquiry="item" />
    </template>
  </hi-data-list-kit>
</template>

<script>
import {
  enquiryListSearchFn,
  getClosedEnquires,
} from "@/hive-vue3/modules/enquiries/data";
import EnquiryListItem from "@/hive-vue3/modules/enquiries/EnquiryListItem";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import { computed } from "vue";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";

export default {
  name: "EnquiryClosedList",
  components: {
    HiDataListKit,
    EnquiryListItem,
  },
  setup() {
    const closedEnquires = computed(() => {
      return getClosedEnquires();
    });

    const storeValue = createDataListStore(closedEnquires);

    const store = provideDataListStore(storeValue);

    // provideOpeningEnquiriesListStore();
    // const store = provideClosedEnquiriesListStore();
    store.actions.defaultSortBy("enquiryAt", true, "date");
    function onSelect(item) {
      // console.log(item);
      enquiryState.currentEnquiryId = item.id;
    }
    const sortables = [
      {
        key: "__dateCreated",
        text: "Date",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "__dateUpdated",
        text: "Update",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
    ];

    return {
      onSelect,
      sortables,
      enquiryListSearchFn,
      enquiryState,
    };
  },
};
</script>

<style scoped></style>
