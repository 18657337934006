<template>
  <span class="inline-block relative shrink-0 grow-0">
    <img
      v-if="photo"
      :style="{ width: size + 'px', height: size + 'px' }"
      :class="[
        ring ? 'ring-2' : '',
        ringThicker ? 'ring-4' : '',
        'rounded-full',
        ringPrimary ? 'ring-primary/50' : 'ring-' + ringColor,
      ]"
      :src="photo"
      alt=""
    />
    <span
      v-else
      :style="{ width: size + 'px', height: size + 'px' }"
      :class="[
        ring ? 'ring-2' : '',
        ringThicker ? 'ring-4' : '',
        'rounded-full',
        ringPrimary ? 'ring-primary/50' : 'ring-' + ringColor,
      ]"
      class="inline-block overflow-hidden bg-gray-100"
    >
      <svg
        class="h-full w-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </span>
    <span
      v-if="badge"
      class="absolute top-0 right-0 block h-1.5 w-1.5 rounded-full ring-2 ring-white bg-gray-300"
    ></span>
  </span>
</template>

<script>
export default {
  name: "hi-avatar",
  props: {
    size: {
      type: Number,
      default: 45,
    },
    badge: Boolean,
    photo: String,
    ring: Boolean,
    /**
     * not working for now
     */
    ringColor: {
      type: String,
      default: "white",
    },
    ringPrimary: Boolean,
    ringThicker: Boolean,
  },
};
</script>

<style scoped>
.placeholder-primary {
  @apply ring-primary/50;
}
</style>
