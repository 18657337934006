<template>
  <!--  We dont' use transition-fade here for more specific animation effects.-->
  <teleport to="body">
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
      :css="false"
      mode="out-in"
      appear
      :duration="{ enter: 500, leave: 1000 }"
    >
      <div
        v-if="modelValue"
        class="z-modal fixed overflow-hidden m-0 p-0 left-0 top-0 w-full h-screen"
        :class="{
          'flex-center': centerContent,
          'pointer-events-auto': modelValue,
          'pointer-events-none': !modelValue,
        }"
      >
        <div ref="content" class="opacity-0">
          <slot />
        </div>

        <div
          ref="background"
          class="fixed m-0 p-0 left-0 top-0 w-full h-screen opacity-0"
          @click="clickOutside"
          :style="{
            'background-color': color,
            'z-index': -1,
          }"
        ></div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onUnmounted } from "vue";
import gsap from "gsap";
import { templateRef } from "@vueuse/core";
import {
  lockDocumentScrolling,
  unlockDocumentScrolling,
} from "@/hive-vue3/utils/dom/lockDocumentScrolling";
// import { templateRef } from "@vueuse/core";
// import TransitionFade from "../transitions/TransitionFade";

export default {
  name: "hi-modal",
  // components: { TransitionFade },
  emits: ["clickBackground", "modalOut", "update:modelValue"],
  props: {
    color: { type: String, default: "rgba(0,0,0,0.7)" },
    keyEvent: Boolean,
    modelValue: Boolean,
    centerContent: {
      type: Boolean,
      default: true,
    },
    contentYMotionEnter: {
      type: Number,
      default: 0,
    },
    contentYMotionLeave: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const content = templateRef("content");
    // const bg = templateRef("background");
    const background = templateRef("background");
    const keyPress = (e) => {
      if (e.key === "Escape") {
        emit("update:modelValue", false);
      }
    };
    /*
    blow 2 functions are to prevent body scrolling
     */

    const showMod = () => {
      // console.log("model show");
      if (props.keyEvent) document.addEventListener("keyup", keyPress);
      lockDocumentScrolling();
    };
    const hideMod = () => {
      // console.log("model hide");
      if (props.keyEvent) document.removeEventListener("keyup", keyPress);
      unlockDocumentScrolling();
    };
    // watch(
    //   () => props.modelValue,
    //   (show) => {
    //     if (show) {
    //       showMod();
    //     } else {
    //       hideMod();
    //     }
    //   }
    // );
    function clickOutside() {
      emit("clickBackground");
    }
    // onMounted(() => {
    //
    // });
    onUnmounted(() => {
      hideMod();
    });
    let bgDiv, contentDiv;
    return {
      clickOutside,
      beforeEnter() {
        showMod();
      },
      enter(el, done) {
        // console.log("enter", el);
        bgDiv = background.value;
        contentDiv = content.value;
        // console.log(contentDiv);

        gsap.to(bgDiv, {
          duration: 0.5,
          opacity: 1,
          ease: "circ.out",
          onComplete: done,
        });

        gsap.set(contentDiv, {
          yPercent: props.contentYMotionEnter,
        });
        gsap.to(contentDiv, {
          duration: 0.5,
          delay: 0.2,
          ease: "circ.out",
          opacity: 1,
          yPercent: 0,
        });
      },
      leave(el, done) {
        // console.log(props.modelValue);
        // console.log("leave", el);
        /**
         * setting class doesn't work for no reason. disable modal before start fading out from here.
         */
        gsap.set(el, { pointerEvents: "none" });
        gsap.to(bgDiv, {
          duration: 1,
          delay: 0.1, //add delay to improve smoothness
          opacity: 0,

          ease: "circ.out",
          onComplete: () => {
            hideMod();
            emit("modalOut");
            done();
          },
        });
        gsap.to(contentDiv, {
          opacity: 0,
          yPercent: props.contentYMotionLeave,
          delay: 0.2,
          ease: "circ.out",
          duration: 0.6,
        });
      },
      // leave(el, done) {
      //   console.log(el);
      //   console.log(bg.value);
      //
      // },
    };
  },
};
</script>

<style lang="scss" scoped></style>
