<template>
  <div class="flex gap-2">
    <hi-card class="h-full min-w-[422px] w-1/3">
      <system-user-list />
    </hi-card>
    <hi-card
      class="h-full grow p-8 w-1/3"
      :style="{
        opacity: usersState.selectedUser ? 1 : 0.2,
      }"
    >
      <system-user-details
        :userId="usersState.selectedUser && usersState.selectedUser.id"
      />
    </hi-card>
    <hi-card
      class="h-full grow p-8 w-1/3"
      :style="{
        opacity: usersState.selectedUser ? 1 : 0.2,
      }"
    >
      <hi-feeds />
      <hi-avatar-group />
    </hi-card>
  </div>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import SystemUserList from "@/hive-vue3/modules/users/SystemUserList";
import SystemUserDetails from "@/hive-vue3/modules/users/SystemUserDetails";
import { usersState } from "@/hive-vue3/modules/users/state";
import HiFeeds from "@/hive-vue3/components/HiFeeds";
import HiAvatarGroup from "@/hive-vue3/components/HiAvatarGroup";
export default {
  name: "SystemUsersFullKit",
  components: {
    HiAvatarGroup,
    HiFeeds,
    SystemUserDetails,
    SystemUserList,
    HiCard,
  },
  setup() {
    // console.log(usersState);
    return {
      usersState,
    };
  },
};
</script>

<style scoped></style>
