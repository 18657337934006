<template>
  <layout-three-column
    full-card-first-col
    module-name="accounts"
    :page-name="name"
    @backToSummary="accountState.currentAccountId = undefined"
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Accounts</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button text> Add New Account </hi-button>
        <add-new-account-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <hi-tab-group v-model="currentTab" class="h-full">
        <hi-tab label="Active" class="h-full pt-1">
          <accounts-active-list class="h-full" />
        </hi-tab>
        <hi-tab label="Closed" class="h-full pt-1">
          <accounts-closed-list class="h-full" />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-if="accountState.currentAccountId" v-slot:col2>
      <hi-card class="p-4 min-h-[155px]">
        <accounts-title-card />
      </hi-card>
      <hi-card class="p-4 min-h-[160px]">
        <accounts-info-card />
      </hi-card>
      <activity-tools-card
        class="min-h-[40px]"
        :pipe-id="pipeId"
        target="accounts"
        :target-id="accountState.currentAccountId"
        :main-owner="mainOwner"
      />
      <module-title-card title="People" class="grow">
        <template v-slot:tools>
          <hi-dialog-trigger>
            <hi-icon
              class="clickable-icon"
              :path="commonIcons.add"
              title="Link contact"
            />
            <add-people-to-account-dialog />
          </hi-dialog-trigger>
        </template>
        <accoounts-people-list />
      </module-title-card>
    </template>
    <template v-if="accountState.currentAccountId" v-slot:col3>
      <hi-tab-group class="h-full">
        <hi-tab label="Activity">
          <activity-list />
        </hi-tab>
        <hi-tab label="Leads">
          <accounts-leads-list class="h-full overflow-hidden" />
        </hi-tab>
        <hi-tab label="Projects">
          <accounts-projects-list class="h-full overflow-hidden" />
        </hi-tab>
        <hi-tab label="Emails">
          <gmail-relative-mails-common-viewer
            class="h-full overflow-auto"
            :email="email"
            :pipeId="pipeId"
            target="accounts"
            :target-id="accountState.currentAccountId"
          />
        </hi-tab>
        <hi-tab label="History">
          <history-viewer
            class="h-full overflow-auto"
            :target-id="accountState.currentAccountId"
            target="account"
          />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:summary>
      <active-account-num-card class="w-1/2" />
      <my-account-num-card class="w-1/2" />
      <account-num-by-user
        v-if="permission.admin || permission.maintenance"
        class="w-1/2"
      />
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiButton from "@/hive-vue3/components/HiButton";
import AddNewAccountDialog from "@/hive-vue3/modules/accounts/AddNewAccountDialog";
import HiCard from "@/hive-vue3/components/HiCard";
import AccountsTitleCard from "@/hive-vue3/modules/accounts/AccountsTitleCard";
import AccountsInfoCard from "@/hive-vue3/modules/accounts/AccountsInfoCard";
import ModuleTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import AccoountsPeopleList from "@/hive-vue3/modules/accounts/AccoountsPeopleList";
import AddPeopleToAccountDialog from "@/hive-vue3/modules/accounts/AddPeopleToAccountDialog";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import AccountsLeadsList from "@/hive-vue3/modules/accounts/AccountsLeadsList";
import AccountsProjectsList from "@/hive-vue3/modules/accounts/AccountsProjectsList";
import ActivityToolsCard from "@/hive-vue3/modules/activities/components/ActivityToolsCard";
import { computed, watch } from "vue";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";
import ActivityList from "@/hive-vue3/modules/activities/ActivityList";
import { provideActivityData } from "@/hive-vue3/modules/activities/data";
import GmailRelativeMailsCommonViewer from "@/hive-vue3/modules/gmail/components/GmailRelativeMailsCommonViewer";
import { useRouter } from "vue-router";
import AccountsActiveList from "@/hive-vue3/modules/accounts/AccountsActiveList";
import AccountsClosedList from "@/hive-vue3/modules/accounts/AccountsClosedList";
import { ref, watchEffect } from "vue";
import ActiveAccountNumCard from "@/hive-vue3/modules/accounts/dashboardCards/BasicAccountNumCard";
import AccountNumByUser from "@/hive-vue3/modules/accounts/dashboardCards/AccountNumByUser";
import { useCurrentFirebaseUserPermissions } from "@/hive-vue3/firebase";
import MyAccountNumCard from "@/hive-vue3/modules/accounts/dashboardCards/MyAccountNumCard";
import HistoryViewer from "@/hive-vue3/modules/history/HistoryViewer";
import { getContactsPeopleEmailById } from "@/hive-vue3/modules/contacts/data";
import { arrayUnique } from "@/hive-vue3/modules/utils/utils";
export default {
  name: "AccountViewFullKil",
  components: {
    HistoryViewer,
    MyAccountNumCard,
    AccountNumByUser,
    ActiveAccountNumCard,
    AccountsClosedList,
    AccountsActiveList,
    GmailRelativeMailsCommonViewer,
    ActivityList,
    ActivityToolsCard,
    AccountsProjectsList,
    AccountsLeadsList,
    HiTab,
    HiTabGroup,
    AddPeopleToAccountDialog,
    AccoountsPeopleList,
    HiIcon,
    ModuleTitleCard,
    AccountsInfoCard,
    AccountsTitleCard,
    HiCard,
    AddNewAccountDialog,
    HiButton,
    HiDialogTrigger,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    const router = useRouter();
    setRouterId(props, accountState, "currentAccountId", "/accounts/");

    watch(
      () => accountState.currentAccountId,
      (v) => {
        if (v) {
          router.push("/accounts/" + v);
        } else {
          router.push("/accounts");
        }
      }
    );

    const currentTab = ref("Active");

    watchEffect(() => {
      if (accountState.currentAccountId) {
        const res = getAccountById(accountState.currentAccountId);
        if (res) {
          if (res.status !== "closed") {
            currentTab.value = "Active";
          } else {
            currentTab.value = "Closed";
          }
        } else {
          currentTab.value = "Active";
        }
      } else {
        currentTab.value = "Active";
      }
    });

    const pipeId = computed(() => {
      const account = getAccountById(accountState.currentAccountId);
      if (account && account.pipeId) {
        return account.pipeId;
      }
      return "";
    });

    const email = computed(() => {
      const returnArr = [];
      const accountDetails = getAccountById(accountState.currentAccountId);
      if (accountDetails && accountDetails.email) {
        returnArr.push(accountDetails.email);
      }

      if (
        accountDetails &&
        accountDetails.people &&
        accountDetails.people.length
      ) {
        for (const i of accountDetails.people) {
          returnArr.push(getContactsPeopleEmailById(i));
        }
      }

      return arrayUnique(returnArr);
    });

    const name = computed(() => {
      const accountDetails = getAccountById(accountState.currentAccountId);
      if (accountDetails && accountDetails.name) {
        return accountDetails.name;
      }
      return undefined;
    });

    const mainOwner = computed(() => {
      const accountDetails = getAccountById(accountState.currentAccountId);
      if (accountDetails && accountDetails.mainOwner) {
        return accountDetails.mainOwner;
      }
      return undefined;
    });

    provideActivityData(pipeId);

    return {
      commonIcons,
      accountState,
      pipeId,
      email,
      name,
      currentTab,
      mainOwner,

      permission: useCurrentFirebaseUserPermissions(),
    };
  },
};
</script>

<style scoped></style>
