<template>
  <div
    class="closed transition-all duration-300 ease-in-out absolute left-0 top-0 z-navi h-screen text-center overflow-hidden divide-y divide-white divide-opacity-20 flex flex-col"
    :class="{ expended: expended }"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @click="mouseClick"
    style="
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      background-color: #777777;
    "
  >
    <!--  ********************  user ********************  -->
    <div v-if="user" class="flex items-center py-2" style="width: 200px">
      <center-box class="nav-icon"
        ><hi-avatar :size="35" :photo="user.photoUrl"
      /></center-box>
      <transition-fade-css>
        <div v-if="expended" class="text-sm px-1 text-left">
          <div class="text-white font-bold space-y-1">
            {{ user.displayName }}
          </div>
        </div>
      </transition-fade-css>
    </div>
    <!--  ********************  modules ********************  -->
    <div class="pt-2">
      <slot />
    </div>
    <!--  ********************  system ********************  -->
    <div class="flex-grow mt-2"></div>
    <div class="pb-2 pt-2">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>
import { computed, provide, ref } from "vue";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import HiAvatar from "@/hive-vue3/components/HiAvatar";
import { useCurrentFirebaseUser } from "@/hive-vue3/firebase";
// import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import { lazyRef } from "@/hive-vue3/utils/reactiveHelpers/lazy";
import TransitionFadeCss from "@/hive-vue3/transitions/TransitionFadeCss";

export default {
  name: "HiNavDrawer",
  components: { TransitionFadeCss, HiAvatar, CenterBox },
  setup() {
    const expended = ref(false);
    provide("menu-expended", expended);
    const setter = lazyRef(expended, 300);

    function mouseEnter() {
      setter.lazy(true);
    }
    function mouseLeave() {
      setter.now(false);
    }
    function mouseClick() {
      // console.log("click");
      setter.now(false);
    }
    const user = computed(() => {
      return useCurrentFirebaseUser();
    });
    return {
      expended,
      mouseEnter,
      mouseLeave,
      mouseClick,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "nav";
</style>
