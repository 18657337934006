<template>
  <div class="flow-root">
    <div class="space-y-2">
      <div v-for="(event, eventIdx) in timeline" :key="event.time + eventIdx">
        <hi-dialog-trigger>
          <div class="flex space-x-2">
            <div
              class="--this-is-feed-left w-14 h-full inline-flex h-10 items-center justify-end"
            >
              <HiDateTimeDisplay
                :model-value="event.time"
                friendly
                short
                class="tp-description-sm"
              />
            </div>
            <div
              class="--this-is-icon w-10 shrink-0 grow-0 flex flex-col"
              :title="tips[event.type]"
            >
              <div
                class="w-10 h-10 rounded-full flex items-center justify-center bg-gray-200 grow-0 shrink-0"
              >
                <hi-icon :path="icons[event.type]" class="text-xl" />
              </div>
              <div class="grow pt-2">
                <div
                  class="--this-is-the-line w-1/2 h-full border-r border-gray-200"
                ></div>
              </div>
            </div>
            <div class="grow w-0">
              <div class="tp-title flex h-10 items-center truncate">
                {{ event.title }}
              </div>
              <div class="tp-subtitle truncate">{{ event.description }}</div>
            </div>
          </div>
          <hi-dialog v-if="event.type === 'email'" width="800px" height="600px">
            <gmail-thread-mails-viewer :thread-id="event.id" />
          </hi-dialog>
          <hi-dialog v-else width="800px" title="Activity Details">
            <activity-details :activity-id="event.id" />
          </hi-dialog>
        </hi-dialog-trigger>
      </div>
    </div>

    <div class="flex space-x-2">
      <div class="--this-is-left-space-holder w-14"></div>
      <div class="w-10 text-center text-xl text-gray-400">
        <hi-icon :path="icons.more" />
      </div>
    </div>
  </div>
</template>

<script>
import HiIcon from "@/hive-vue3/components/HiIcon";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  mdiCoffeeOutline,
  mdiDotsHorizontalCircleOutline,
  mdiEmailReceiveOutline,
  mdiHomeVariantOutline,
  mdiLaptop,
  mdiPhoneIncomingOutline,
  mdiUploadOutline,
  mdiEmailSendOutline,
} from "@mdi/js";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import GmailThreadMailsViewer from "@/hive-vue3/modules/gmail/components/GmailThreadMailsViewer";
import ActivityDetails from "@/hive-vue3/modules/activities/ActivityDetails";

export default {
  name: "ActivityFeeds",
  components: {
    ActivityDetails,
    GmailThreadMailsViewer,
    HiDialog,
    HiDialogTrigger,
    HiDateTimeDisplay,
    HiIcon,
  },
  props: {
    timeline: Array,
  },
  setup() {
    const icons = {
      "Send Email": mdiEmailSendOutline,
      email: mdiEmailReceiveOutline,
      "Phone Call": mdiPhoneIncomingOutline,
      "Meeting (Onsite)": mdiHomeVariantOutline,
      Event: mdiCoffeeOutline,
      "Meeting (Online)": mdiLaptop,
      more: mdiDotsHorizontalCircleOutline,
      Update: mdiUploadOutline,
      "Send Quotation": mdiUploadOutline,
    };
    const tips = {
      "Send Email": "Email Send",
      email: "Email Received",
      "Phone Call": "Incoming Call",
      "Meeting (Onsite)": "Shop Visiting",
      Event: "Appointment",
      "Meeting (Online)": "Zoom Meeting",
      Update: "Update",
      "Send Quotation": "Quotation Send",
    };
    return {
      icons,
      tips,
    };
  },
};
</script>

<style scoped></style>
