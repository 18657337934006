<template>
  <div class="flex items-baseline" v-if="display || defaultDisplay">
    <div class="w-20 tp-small-label">{{ computedLabel }}</div>
    <div class="grow w-0">
      <slot>{{ display || defaultDisplay }}</slot>
    </div>
  </div>
</template>

<script>
import { capitalize, decamelize } from "@/hive-vue3/utils/stringUtils";
import { computed, inject } from "vue";

export default {
  name: "ModuleLabeledInfo",
  props: {
    label: String,
    data: Object,
    valueKey: String,
    value: [String, Number],
    defaultDisplay: String,
  },
  setup(props) {
    const injected = inject("module-info-block-data", undefined);
    function makeLabel() {
      if (props.label) return props.label;
      return capitalize(decamelize(props.valueKey));
    }
    const display = computed(() => {
      if (props.value) return props.value;
      if (props.data && props.valueKey) {
        return props.data[props.valueKey];
      }
      if (injected.value && props.valueKey) {
        return injected.value[props.valueKey];
      }
      return null;
    });
    return {
      display,
      computedLabel: computed(makeLabel),
      // vif: computed(() => {
      //   const value =
      //     props.value || (props.object && props.object[props.valueKey]);
      //   return !!value;
      // }),
    };
  },
};
</script>

<style scoped></style>
