<template>
  <hi-card>
    <div v-if="formData" class="px-16 py-8 font-regular">
      <hi-banner v-if="formData.status === 'draft'" warning
        >This is a Draft. Invoice Id: INV-{{
          formData.invoiceNum.toString().padStart(4, "0")
        }}</hi-banner
      >
      <hi-banner v-if="formData.status === 'send'" successful
        >This invoice already send. Invoice Id: INV-{{
          formData.invoiceNum.toString().padStart(4, "0")
        }}</hi-banner
      >

      <div class="pt-4 flex w-full">
        <div class="w-1/3">
          <p>ATT</p>
          <br />
          <div class="flex items-start gap-2">
            <p class="font-bold">{{ attention }}</p>
            <hi-icon
              class="clickable-icon"
              size="12px"
              :path="mdiOpenInNew"
              title="Link Account"
              @click="jumpToPeople(formData.attention)"
            />
          </div>

          <div class="flex pt-4 items-start gap-2" v-if="client">
            <p class="font-bold">
              {{ client }}
            </p>
            <hi-icon
              class="clickable-icon"
              size="12px"
              :path="mdiOpenInNew"
              title="Link Account"
              @click="jumpToEntity(formData.attention)"
            />
          </div>
        </div>
        <div class="w-1/3">
          <p>DATE</p>
          <br />
          <p class="font-bold">
            {{ getDate(formData.dateCreated) }}
          </p>
        </div>
        <div class="w-1/3">
          <p>INVOICE #</p>
          <br />
          <div class="flex items-start gap-2">
            <p>
              #
              <span class="font-bold" v-if="formData.invoiceNum">
                INV-{{ formData.invoiceNum.toString().padStart(4, "0") }}</span
              >
            </p>
            <hi-icon
              class="clickable-icon"
              size="12px"
              :path="mdiOpenInNew"
              title="Link Account"
              @click="jumpToInvoice(formData.id)"
            />
          </div>
        </div>
      </div>

      <div class="pt-6">
        <p>PROJECT</p>
        <div class="flex pt-4 items-start gap-2">
          <p class="font-bold" v-html="address"></p>
          <hi-icon
            class="clickable-icon"
            size="12px"
            :path="mdiOpenInNew"
            title="Link Account"
            @click="jumpToProject(formData.projectId)"
          />
        </div>
      </div>
      <div class="pt-6">
        <table class="w-full font-regular table-fixed">
          <tr>
            <th class="w-60 text-left">COSTS</th>
            <th class="min-w-[120px] w-[120px] text-right">QUANTITY</th>
            <th class="min-w-[90px] w-[90px] text-right">RATE</th>
            <th class="min-w-[100px] w-[100px] text-right">AMOUNT</th>
          </tr>
          <tr v-for="(item, index) in formData.items" :key="item + index">
            <td style="overflow-wrap: break-word; white-space: pre-line">
              {{ item.item }}
            </td>
            <td>
              {{
                item.quantity
                  ? item.quantity.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })
                  : item.quantity
              }}
            </td>
            <td>
              {{
                item.rate
                  ? item.rate.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : item.rate
              }}
            </td>
            <td>
              {{
                (item.rate * item.quantity).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}
            </td>
          </tr>
          <tr class="border-none">
            <td />
            <td class="font-bold">Subtotal</td>
            <td />
            <td>{{ subTotal }}</td>
          </tr>
          <tr class="border-none">
            <td />
            <td class="font-bold border-b border-black">GST</td>
            <td class="border-b border-black" />
            <td class="border-b border-black">{{ gst }}</td>
          </tr>
          <tr class="border-none">
            <td />
            <td class="font-bold">TOTAL</td>
            <td />
            <td>{{ total }}</td>
          </tr>
          <tr class="border-none" v-if="formData.depositDue">
            <td />
            <td class="font-bold">Deposit Due</td>
            <td />
            <td>
              {{
                formData.depositDue
                  ? formData.depositDue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : formData.depositDue
              }}
            </td>
          </tr>
          <tr class="border-none" v-if="formData.depositPaid !== undefined">
            <td />
            <td class="font-bold">Deposit Paid</td>
            <td />
            <td>
              {{
                formData.depositPaid
                  ? formData.depositPaid.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : formData.depositPaid
              }}
            </td>
          </tr>
        </table>
      </div>

      <div class="pt-16 font-medium w-full">
        <p>
          {{ formData.depositRate }}% deposit, balance payable upon
          {{ formData.depositCondition }}
        </p>
        <br />
        <p class="w-full">
          By placing a payment against this quotation, you hereby accept in full
          all items listed on this quotation & acknowledge that you have read
          and understood the Artimber Terms & Conditions and agree to proceed on
          this basis.
        </p>
        <br />
      </div>
    </div>
  </hi-card>
</template>

<script>
import { computed } from "vue";
import moment from "moment";
import { mdiOpenInNew } from "@mdi/js";
import {
  getContactsEntityNameById,
  getContactsEntityTradingNameById,
  getContactsFullNameById,
  jumpToEntityByEntityId,
  jumpToPeopleByPeopleId,
} from "@/hive-vue3/modules/contacts/data";
import {
  getProjectById,
  jumpToProjectById,
} from "@/hive-vue3/modules/projects/data";
import {
  getGst,
  getQuotationIdStr,
  getSubTotal,
  getTotal,
} from "@/hive-vue3/modules/quotation/data";
import {
  getInvoiceById,
  jumpToInvoiceById,
} from "@/hive-vue3/modules/invoice/data";
import HiCard from "@/hive-vue3/components/HiCard";
import { getDateFromTsOrDate } from "@/hive-vue3/firebase";
import HiBanner from "@/hive-vue3/components/HiBanner";
import { useRouter } from "vue-router";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "InvoicePreview",
  components: {
    HiIcon,
    HiBanner,
    HiCard,
  },
  props: {
    invoiceData: Object,
    invoiceId: String,
  },
  setup(props) {
    const formData = computed(() => {
      if (props.invoiceData) return props.invoiceData;
      return getInvoiceById(props.invoiceId);
    });

    function getDate(date) {
      if (date) {
        return moment(getDateFromTsOrDate(date))
          .format("DD MMM YYYY")
          .toUpperCase();
      }
      return "";
    }

    const attention = computed(() => {
      if (formData.value && formData.value.attention) {
        return getContactsFullNameById(formData.value.attention);
      }
      return "";
    });

    const client = computed(() => {
      if (formData.value && formData.value.client) {
        if (formData.value.legalName) {
          return getContactsEntityNameById(formData.value.client);
        } else {
          return getContactsEntityTradingNameById(formData.value.client);
        }
      }
      return "";
    });

    const address = computed(() => {
      if (formData.value && formData.value.projectId) {
        const project = getProjectById(formData.value.projectId);
        if (project) {
          if (project.isNameOfficial) {
            return `${project.name}<br>${project.address.formatted}`;
          }
          return `${project.address.formatted}`;
        }
      }
      return null;
    });
    const router = useRouter();

    async function jumpToInvoice(id) {
      await jumpToInvoiceById(router, id);
    }
    async function jumpToPeople(id) {
      await jumpToPeopleByPeopleId(router, id);
    }
    async function jumpToEntity(id) {
      await jumpToEntityByEntityId(router, id);
    }
    async function jumpToProject(id) {
      await jumpToProjectById(router, id);
    }

    return {
      gst: computed(() => {
        if (formData.value && formData.value.items) {
          return getGst(formData.value);
        }
        return 0;
      }),
      subTotal: computed(() => {
        if (formData.value && formData.value.items) {
          return getSubTotal(formData.value);
        }
        return 0;
      }),
      total: computed(() => {
        if (formData.value && formData.value.items) {
          return getTotal(formData.value);
        }
        return 0;
      }),
      getDate,
      getQuotationIdStr,
      attention,
      client,
      address,
      formData,
      jumpToInvoice,
      jumpToPeople,
      jumpToEntity,
      jumpToProject,
      mdiOpenInNew,
    };
  },
};
</script>

<style scoped>
@font-face {
  font-family: "NaN Holo";
  src: url("../../assets/artimber/ttf/NaNHolo-Regular.ttf");
}
@font-face {
  font-family: "NaN Holo Bold";
  src: url("../../assets/artimber/ttf/NaNHolo-Bold.ttf");
}
@font-face {
  font-family: "NaN Holo Medium";
  src: url("../../assets/artimber/ttf/NaNHolo-Medium.ttf");
}

.font-regular {
  font-family: "NaN Holo";
}
.font-bold {
  font-family: "NaN Holo Bold";
}
.font-medium {
  font-family: "NaN Holo Medium";
}
tr {
  @apply border-b border-black;
}
td {
  @apply text-right align-bottom py-2;
}
td:first-child {
  @apply text-left;
}
</style>
