<template>
  <hi-card>
    <div
      v-for="(item, index) in users"
      :key="item + index"
      class="p-2 border-b-2"
    >
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="item.id" />
        <div class="w-[180px]">
          <div>Active: {{ state[item.id].accActiveNum }}</div>
          <div>${{ state[item.id].accActiveAmount.toLocaleString() }}</div>
        </div>
        <div class="w-[180px]">
          <div>Closed: {{ state[item.id].accClosedNum }}</div>
          <div>${{ state[item.id].accClosedAmount.toLocaleString() }}</div>
        </div>
        <div class="w-[180px]">
          <div>Total: {{ state[item.id].accNum }}</div>
          <div>${{ state[item.id].accAmount.toLocaleString() }}</div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { useSystemUsers } from "@/hive-vue3/firebase";
import {
  getAllAccountsByUserId,
  getAmountByGivenAccountArray,
} from "@/hive-vue3/modules/accounts/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { computed, watchEffect } from "vue";
import { reactive } from "vue";
export default {
  name: "AccountNumByUser",
  components: { UserDetailsSpan, HiCard },
  setup() {
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [];
      for (const i in users) {
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push(users[i]);
        }
      }
      return returnArr;
    });

    const state = reactive({});
    watchEffect(() => {
      for (const i of userNoMaintenance.value) {
        if (!state[i.id]) {
          state[i.id] = {};
        }
        const accounts = getAllAccountsByUserId(i.id);
        const activeAccounts = accounts.filter((v) => v.status === "active");
        const closedAccounts = accounts.filter((v) => v.status === "closed");

        state[i.id].accNum = accounts.length;
        state[i.id].accAmount = getAmountByGivenAccountArray(accounts);

        state[i.id].accActiveNum = activeAccounts.length;
        state[i.id].accActiveAmount =
          getAmountByGivenAccountArray(activeAccounts);
        state[i.id].accClosedNum = closedAccounts.length;
        state[i.id].accClosedAmount =
          getAmountByGivenAccountArray(closedAccounts);
      }
    });

    return {
      users: userNoMaintenance,
      state,
    };
  },
};
</script>

<style scoped></style>
