<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard
      :initial-values="initialValues"
      :submit-fn="submit"
      @successful="open = false"
    >
      {{ time }}
      <hi-form-value-holder
        form-key="taskId"
        :model-value="initialValues.taskId"
      />
      <hi-form-item label="Scheduled Time">
        <hi-date-picker form-key="scheduledTime" v-model="time" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref, watch } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import hive from "@/hive-vue3/components/hive";
import { requestChangeSchedule } from "@/hive-vue3/api/request";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
export default {
  name: "EdmChangeScheduleDialog",
  components: {
    HiFormValueHolder,
    HiDatePicker,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    initialValues: Object,
  },
  setup() {
    const open = ref(false);
    const time = ref();
    watch(
      () => open.value,
      (v) => {
        if (v) {
          time.value = undefined;
        }
      }
    );

    async function submit(data) {
      if (data.scheduledTime < new Date()) {
        const confirm = await hive.confirm(
          "The time you set is earlier than now. If confirmed, the email will be sent immediately."
        );
        if (!confirm) {
          return {
            successful: false,
          };
        }
      }
      await requestChangeSchedule({
        taskId: data.taskId,
        scheduledTime: data.scheduledTime,
        type: "edm",
      });

      return {
        successful: true,
      };
    }

    return {
      open,
      submit,
      time,
    };
  },
};
</script>

<style scoped></style>
