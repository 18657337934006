<template>
  <hi-dialog v-model="open" persist :close-button="false" slim-footer>
    <slot />
    <div style="width: 30%; min-width: 300px"></div>
    <template v-slot:footer>
      <div class="w-full flex items-center justify-around">
        <hi-button @click="cancel" secondary class="min-w-16">{{
          cancelText
        }}</hi-button>
        <hi-button @click="ok" primary class="min-w-16">{{ okText }}</hi-button>
      </div>
    </template>
  </hi-dialog>
</template>

<script>
import HiButton from "../HiButton";
import HiDialog from "./HiDialog";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";

export default {
  name: "HiConfirm",
  components: { HiDialog, HiButton },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    okText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  emits: ["ok", "cancel", "update:modelValue"],
  setup(props, context) {
    const open = modelRef(props, context.emit);
    function ok() {
      context.emit("ok");
      // context.emit("update:modelValue", false);
      open.value = false;
    }
    function cancel() {
      context.emit("cancel");
      // context.emit("update:modelValue", false);
      open.value = false;
    }
    return {
      // clickBg,
      ok,
      open,
      cancel,
    };
  },
};
</script>

<style scoped></style>
