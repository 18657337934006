<template>
  <div>
    <div>
      <div class="tp-small-label">Device</div>
      <div class="tp-description">{{ deviceInfo }}</div>
    </div>
    <div>
      <div class="tp-small-label">System Info</div>
      <div class="tp-description">{{ systemInfo }}</div>
    </div>
  </div>
</template>

<script>
import { UAParser } from "ua-parser-js";
import { ref, watchEffect } from "vue";
export default {
  name: "HiUaInfo",
  props: {
    ua: String,
  },
  setup(props) {
    const info = ref();
    const deviceInfo = ref();
    const systemInfo = ref();
    watchEffect(() => {
      if (props.ua) {
        const ua = UAParser(props.ua);
        info.value = ua;
        let device = "";
        // { model: '', type: '', vendor: '' }
        const type = ua.device.type;
        if (type && type.length) {
          device += type;
        } else {
          device += "Computer";
        }
        const model = ua.device.model;
        if (model && model.length) {
          device += " (" + model + ")";
        }
        const vendor = ua.device.vendor;
        if (vendor && vendor.length) {
          device += "by " + vendor;
        }
        const sys = `${ua.browser.name} (${ua.browser.version}) on ${ua.os.name} ${ua.os.version}`;
        deviceInfo.value = device;
        systemInfo.value = sys;
      }
    });
    return {
      info,
      deviceInfo,
      systemInfo,
    };
  },
};
</script>

<style scoped></style>
