<template>
  <div class="inline-block">
    <div class="relative w-full h-full">
      <transition-fade :opacity="0.5">
        <div
          class="absolute inset-0 flex items-start z-1 pointer-events-none hi-placeholder opacity-50 hi-control-padding"
          v-if="displayPlaceholder"
        >
          {{ displayPlaceholder }}
        </div>
      </transition-fade>
    </div>

    <date-picker
      v-model="model"
      class="x--input overflow-hidden w-full h-full"
      :enable-time-picker="!disableTime"
      :text-input="textInput"
      :range="range"
      @focus="focus"
      @blur="blur"
      :class="{ error: error }"
      :time-picker="timeOnly"
      :month-picker="monthOnly"
    />
    <div class="flex">
      <hi-form-error class="grow" :model-value="error" />
      <div><slot name="extra-info" /></div>
    </div>
  </div>
</template>

<script>
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import { computed } from "vue";
// Refer:
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "HiDatePicker",
  components: { HiFormError, TransitionFade, DatePicker },
  props: {
    range: Boolean,
    modelValue: Date,
    validator: Function,
    formKey: String,
    mandatory: Boolean,
    placeholder: String,
    disableTime: Boolean,
    textInput: Boolean,
    timeOnly: Boolean,
    monthOnly: Boolean,
  },
  emits: ["focus", "blur", "update:modelValue"],
  setup(props, context) {
    const control = formControl(props, context, { validator: props.validator });
    function focus() {
      context.emit("focus");
    }
    function blur() {
      control.touch();
      context.emit("blur");
    }
    const displayPlaceholder = computed(() => {
      const val = control.model.value;
      if (val && val.length) return null;
      return props.placeholder;
    });
    return {
      model: control.model,
      error: control.error,
      focus,
      blur,
      displayPlaceholder,
    };
  },
};
</script>

<style scoped></style>
