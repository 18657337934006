<template>
  <div class="view-block-spacious">
    <div class="small-label">User Input Name</div>
    <div class="tp-big-fancy-display mb-2">
      {{ docData.name }}
    </div>
    <div class="tp-description-sm">From: {{ docData.from }}</div>
    <div class="tp-description-sm" v-if="docData.sources">
      Sources: {{ docData.sources.join(", ") }}
    </div>
    <div class="tp-description-sm" v-if="docData.status === 'close'">
      Close Reason:
      {{
        docData.reason
          ? docData.reason
          : docData.isConverted
          ? "Convert To Leads"
          : "unknown"
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EnquiryTitle",
  props: {
    docData: Object,
  },
};
</script>

<style scoped></style>
