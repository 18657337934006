<template>
  <hi-data-list-kit
    :sortables="accountsSortableRules"
    :searchables="['name', 'email']"
    search-input-placeholder="Account name, or Email"
    :filter-value="filterOwner"
    filter-key="mainOwner"
    min-item-height="85"
    :current-id="accountState.currentAccountId"
    @select="(v) => (accountState.currentAccountId = v.id)"
  >
    <template v-slot:filterArea>
      <hi-select
        placeholder="Main Owner..."
        v-model="filterOwner"
        :items="userNoMaintenance"
        no-form
      />
    </template>

    <template v-slot="{ item }">
      <account-list-summary :account-data="item" />
    </template>
  </hi-data-list-kit>
</template>

<script>
import { computed } from "vue";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import { getAllClosedAccounts } from "@/hive-vue3/modules/accounts/data";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import AccountListSummary from "@/hive-vue3/modules/accounts/AccountListSummary";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import { ref } from "vue";
import { useSystemUsers } from "@/hive-vue3/firebase";

export default {
  name: "AccountsClosedList",
  components: { HiSelect, AccountListSummary, HiDataListKit },
  setup() {
    const accountsList = computed(() => {
      return getAllClosedAccounts();
    });

    const storeValue = createDataListStore(accountsList);
    provideDataListStore(storeValue);

    const filterOwner = ref(undefined);
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [{ text: "NAN", value: "noFilter" }];
      for (const i in users) {
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push({
            text: users[i].name,
            value: users[i].id,
          });
        }
      }
      return returnArr;
    });

    return {
      accountState,
      userNoMaintenance,
      filterOwner,
      accountsSortableRules: [
        {
          key: "__dateCreated",
          text: "Date",
          type: dataListSortingTypes.DATE,
          desc: true,
          DEFAULT: true,
        },
        {
          key: "__dateUpdated",
          text: "Date",
          type: dataListSortingTypes.DATE,
          desc: true,
          DEFAULT: false,
        },
        {
          key: "name",
          text: "Name",
          type: dataListSortingTypes.ALPHA,
          desc: false,
          DEFAULT: false,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
