<template>
  <layout-three-column
    class="h-full"
    full-card-first-col
    module-name="Projects"
    :page-name="name"
    @backToSummary="projectsState.currentProjectId = undefined"
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Projects</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button text>Add new project</hi-button>
        <project-new-project-dialog title="Add new project" />
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <hi-tab-group v-model="currentTab" class="h-full">
        <hi-tab label="active" class="h-full pt-1">
          <projects-active-list class="h-full" />
        </hi-tab>
        <hi-tab label="won" class="h-full pt-1">
          <projects-win-list class="h-full" />
        </hi-tab>
        <hi-tab label="lost" class="h-full pt-1">
          <projects-lose-list class="h-full" />
        </hi-tab>
      </hi-tab-group>
    </template>

    <template v-if="projectsState.currentProjectId" v-slot:col2>
      <project-title-card editable />
      <project-status-view-card />
      <projects-account-info-card />
      <activity-tools-card
        :target-id="projectsState.currentProjectId"
        target="project"
        :pipe-id="pipeId"
        :main-owner="mainOwner"
      />
      <project-contacts-view-card />
      <quotation-list-view-card
        class="grow"
        :project-id="projectsState.currentProjectId"
      />
    </template>
    <template v-if="projectsState.currentProjectId" v-slot:col3>
      <hi-tab-group class="h-full">
        <hi-tab class="h-full pt-1" label="Activity">
          <activity-list class="h-full overflow-auto" />
        </hi-tab>
        <hi-tab class="h-full pt-1" label="Products">
          <project-product-view
            class="h-full overflow-auto"
            :pipe-id="pipeId"
          />
        </hi-tab>
        <hi-tab class="h-full pt-1" label="History">
          <history-viewer
            class="h-full overflow-auto"
            :target-id="projectsState.currentProjectId"
            target="project"
          />
        </hi-tab>
        <hi-tab label="Emails">
          <gmail-relative-mails-common-viewer
            class="h-full overflow-auto"
            :email="emails"
            :pipeId="pipeId"
            target="projects"
            :target-id="projectsState.currentProjectId"
          />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:summary>
      <basic-project-num-card class="w-1/2" />
      <my-project-num-card class="w-1/2" />
      <project-num-by-user
        v-if="permission.admin || permission.maintenance"
        class="w-1/2"
      />
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import ProjectNewProjectDialog from "@/hive-vue3/modules/projects/ProjectNewProjectDialog";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import { computed, ref, watch, watchEffect } from "vue";
import ProjectTitleCard from "@/hive-vue3/modules/projects/ProjectTitleCard";
import ProjectContactsViewCard from "@/hive-vue3/modules/projects/ProjectContactsViewCard";
import {
  getProjectById,
  getProjectStakeHoldersEmailListById,
} from "@/hive-vue3/modules/projects/data";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiButton from "@/hive-vue3/components/HiButton";
import QuotationListViewCard from "@/hive-vue3/modules/quotation/QuotationListViewCard";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import ProjectProductView from "@/hive-vue3/modules/projects/ProjectProductView";
import ProjectsActiveList from "@/hive-vue3/modules/projects/ProjectsActiveList";
import ProjectsWinList from "@/hive-vue3/modules/projects/ProjectsWinList";
import ProjectsLoseList from "@/hive-vue3/modules/projects/ProjectsLoseList";
import ProjectStatusViewCard from "@/hive-vue3/modules/projects/ProjectStatusViewCard";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
import ActivityToolsCard from "@/hive-vue3/modules/activities/components/ActivityToolsCard";
import HistoryViewer from "@/hive-vue3/modules/history/HistoryViewer";
import ProjectsAccountInfoCard from "@/hive-vue3/modules/projects/ProjectsAccountInfoCard";
import ActivityList from "@/hive-vue3/modules/activities/ActivityList";
import { provideActivityData } from "@/hive-vue3/modules/activities/data";
import GmailRelativeMailsCommonViewer from "@/hive-vue3/modules/gmail/components/GmailRelativeMailsCommonViewer";
import { useRouter } from "vue-router";
import BasicProjectNumCard from "@/hive-vue3/modules/projects/dashboardCards/BasicProjectNumCard";
import ProjectNumByUser from "@/hive-vue3/modules/projects/dashboardCards/ProjectNumByUser";
import MyProjectNumCard from "@/hive-vue3/modules/projects/dashboardCards/MyProjectNumCard";
import { useCurrentFirebaseUserPermissions } from "@/hive-vue3/firebase";

export default {
  name: "ProjectsViewFullKit",
  components: {
    MyProjectNumCard,
    ProjectNumByUser,
    BasicProjectNumCard,
    GmailRelativeMailsCommonViewer,
    ActivityList,
    ProjectsAccountInfoCard,
    HistoryViewer,
    ActivityToolsCard,
    ProjectStatusViewCard,
    ProjectsLoseList,
    ProjectsWinList,
    ProjectsActiveList,
    ProjectProductView,
    HiTab,
    HiTabGroup,
    QuotationListViewCard,
    HiButton,
    HiDialogTrigger,
    ProjectContactsViewCard,
    ProjectTitleCard,
    ProjectNewProjectDialog,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    setRouterId(props, projectsState, "currentProjectId", "/projects/");

    const projectData = computed(() => {
      return getProjectById(projectsState.currentProjectId);
    });
    const pipeId = computed(() => {
      if (projectData.value && projectData.value.pipeId) {
        return projectData.value.pipeId;
      }
      return "";
    });
    const name = computed(() => {
      if (projectData.value && projectData.value.name) {
        return projectData.value.name;
      }
      return "";
    });
    const mainOwner = computed(() => {
      if (projectData.value && projectData.value.mainOwner) {
        return projectData.value.mainOwner;
      }
      return "";
    });

    const currentTab = ref("active");

    watchEffect(() => {
      if (projectsState.currentProjectId) {
        const project = getProjectById(projectsState.currentProjectId);
        if (project) {
          if (
            project.projStatus !== "Closed Won" &&
            project.projStatus !== "Closed Lost"
          ) {
            currentTab.value = "active";
          } else if (project.projStatus === "Closed Won") {
            currentTab.value = "won";
          } else {
            currentTab.value = "lost";
          }
        } else {
          currentTab.value = "active";
        }
      } else {
        currentTab.value = "active";
      }
    });

    provideActivityData(pipeId);

    const router = useRouter();

    watch(
      () => projectsState.currentProjectId,
      (v) => {
        if (v) {
          router.push("/projects/" + v);
        } else {
          router.push("/projects");
        }
      }
    );

    const emails = computed(() => {
      return getProjectStakeHoldersEmailListById(
        projectsState.currentProjectId
      );
    });

    const test = ref("");
    return {
      projectsState,
      test,
      projectData,
      pipeId,
      emails,
      name,
      currentTab,
      permission: useCurrentFirebaseUserPermissions(),
      mainOwner,
    };
  },
};
</script>

<style scoped></style>
