<template>
  <div v-if="historiesObj.histories && historiesObj.histories.length">
    <hi-list :items="historiesObj.histories">
      <template v-slot="{ item: history }">
        <div class="tp-subtitle flex items-baseline gap-4">
          <div class="break-words w-2/3">
            <div class="flex flex-wrap gap-1">
              <div>
                <span class="font-bold uppercase"
                  >[{{ history.target }}]&nbsp;- </span
                ><span class="font-bold">[{{ history.action }}]&nbsp;</span>
              </div>
            </div>
            <div class="w-full break-words whitespace-pre-line">
              {{ history.comments }}
            </div>
            <div v-if="history.contacts && history.contacts.length">
              Relative Contacts:
              <span
                v-for="(user, index) in history.contacts"
                :key="user + index"
              >
                <user-details-span :user-id="user" />,
                <contacts-people :people-id="user" />
              </span>
            </div>

            <div v-if="history.time">
              Date:
              <hi-date-time-display :model-value="history.time" />
            </div>
          </div>
          <div class="grow text-right">
            <div>
              <hi-date-time-display :model-value="history.__dateCreated" />
            </div>
            <span> <user-details-span :user-id="history.__createdBy" /></span>
          </div>
        </div>
      </template>
    </hi-list>
  </div>
  <div v-else>
    <hi-view-no-data />
  </div>
</template>

<script>
import { reactive } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { where, orderBy } from "firebase/firestore";
import HiList from "@/hive-vue3/components/HiList";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import { mdiFile } from "@mdi/js";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";
export default {
  name: "HistoryViewer",
  components: {
    HiViewNoData,
    ContactsPeople,
    HiDateTimeDisplay,
    UserDetailsSpan,
    HiList,
  },
  props: {
    target: String,
    targetId: String,
  },
  setup(props) {
    const historiesObj = reactive({
      histories: undefined,
    });

    /**
     * 读取Firebase原始数据
     */
    watchPropsImmediately(props, "targetId", (id) => {
      if (id) {
        console.log(props.target, props.targetId);
        historiesObj.histories = useFirestoreCollectionAsRef(`/histories`, {
          query: [
            where("target", "==", props.target),
            where("targetId", "==", id),
            orderBy("__dateCreated", "desc"),
          ],
        });
      }
    });

    return {
      historiesObj,
      mdiFile,
    };
  },
};
</script>

<style scoped></style>
