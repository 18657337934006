<template>
  <div class="flex-center w-full h-full flex-col p-5">
    <div class="h-12 w-12 rounded-full bg-red-100 flex-center">
      <hi-icon :path="mdiAlertOutline" class="text-red-500 text-xl" />
    </div>
    <div class="pt-6 pb-1 tp-title">
      <slot name="title" />
    </div>

    <slot />
  </div>
</template>

<script>
import { mdiAlertBoxOutline, mdiAlertOutline } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "HiViewError",
  components: { HiIcon },
  setup() {
    return { mdiAlertBoxOutline, mdiAlertOutline };
  },
};
</script>

<style scoped></style>
