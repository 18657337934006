<template>
  <span v-if="entity">
    <span v-if="entity.name">{{ entity.name }}</span>
    <!--    <span v-if="entity.name && entity.tradingName"> (trading as </span-->
    <!--    >-->
    <span v-if="entity.tradingName">({{ entity.tradingName }})</span><slot
  /></span>
  <!--  <span :class="{ 'cursor-pointer': popup }" @click="if (popup) open = true;">-->
  <!--    {{ name }} <slot />-->
  <!--    <hi-dialog-->
  <!--      title="Entity Details"-->
  <!--      close-button-->
  <!--      v-model="open"-->
  <!--      v-if="popup"-->
  <!--      width="680px"-->
  <!--    >-->
  <!--&lt;!&ndash;      <contacts-entity-details :entity-id="entityId" />&ndash;&gt;-->
  <!--    </hi-dialog>-->
  <!--  </span>-->
</template>

<script>
// import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
// import ContactsEntityDetails from "@/hive-vue3/modules/contacts/ContactsEntityDetails";
import { getContactsEntity } from "@/hive-vue3/modules/contacts/data";
import { computed } from "vue";
export default {
  name: "ContactsEntity",
  // components: { ContactsEntityDetails, HiDialog },
  props: {
    entityId: String,
    entityData: Object,
  },
  setup(props) {
    // const name = computed(() => {
    //   if (props.entityData) return props.entityData.name;
    //   else {
    //     return getContactsEntityNameById(props.entityId);
    //   }
    // });

    return {
      // name,
      entity: computed(() => {
        if (props.entityData) return props.entityData;
        else if (props.entityId) return getContactsEntity(props.entityId);
        return null;
      }),
    };
  },
};
</script>

<style scoped></style>
