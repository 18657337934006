<template>
  <div class="mx-auto">
    <vue-pdf-embed :source="source" :page="page" />
  </div>
</template>

<script>
//todo: vue-pdf-embed canvas is not responsive
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "HiPdfViewer",
  components: {
    VuePdfEmbed,
  },
  props: {
    source: String,
    page: Number,
  },
};
</script>

<style scoped></style>
