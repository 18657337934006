import { getStorageRef } from "@/hive-vue3/firebase/storage";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { nextTick, reactive } from "vue";

import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  firestoreDoc,
  getFirestoreDocData,
  isFirestoreDocExists,
  newFirestoreDoc,
} from "@/hive-vue3/firebase/utils";
import { fileMd5 } from "@/hive-vue3/utils/browser-md5";
import { downloadFileFromUrl } from "@/hive-vue3/utils/fileSaver";
import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";
// import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";

/**
 * Introducing fileVault for files never gets deleted.
 */

export const FILE_VAULT = "-FILE-VAULT-";

/**
 *
 * @returns {{cancel: function, upload: (function(File,String=null): Promise<unknown>), fileDoc: UnwrapNestedRefs<{}>, state: UnwrapNestedRefs<{running: boolean, progress: number, completed: boolean, error: boolean}>}}
 */
export function vaultUploader() {
  // Create a reference to 'mountains.jpg'

  const state = reactive({
    error: false,
    completed: false,
    progress: 0,
    running: false,
    info: "Initialising...",
  });

  // const fileQ = useFirestoreDocQueryable(FILE_VALUT);
  // console.log(fileQ);
  let uploadTask;

  // let canceled = false;
  function cancel() {
    state.error = false;
    state.completed = false;
    state.running = false;
    state.progress = 0;
    state.info = "";
    if (uploadTask) {
      // canceled = true;
      uploadTask.cancel();
    }
  }

  let filePath;

  /**
   *
   * @param file
   * @param hash provide md5 hash if already has one. Will do hashing here if not provided.
   * @returns {Promise<unknown>}
   */

  async function upload(file, hash = null) {
    // console.log(file);
    cancel();
    state.running = true;
    if (!hash) {
      state.info = "Hashing...";
      //to update state.info in UI.
      await nextTick();
      // const timer = new Date().getTime();

      hash = await fileMd5(file);
    }

    // console.log(
    //   "hash finished: " + hash,
    //   "time(ms): " + (new Date().getTime() - timer)
    // );

    const exists = await isFirestoreDocExists(FILE_VAULT, hash);
    if (exists) {
      console.log("file exists");
      return new Promise((resolve) => {
        // fileQ.setId(hash);
        state.error = false;
        state.completed = true;
        state.running = false;
        state.progress = 100;
        state.info = "All Done";
        resolve(hash);
      });
    }
    filePath = FILE_VAULT + "/" + hash;
    const fileRef = getStorageRef(filePath);
    uploadTask = uploadBytesResumable(fileRef, file);
    state.info = "Uploading...";

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          state.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case "paused":
              state.running = false;
              break;
            case "running":
              state.running = true;
              break;
          }
        },
        (error) => {
          if (error.code.indexOf("canceled") > 0) {
            return;
          }
          console.error(error);
          state.error = error;
          state.info = "Error.";
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          state.completed = true;
          state.info = "Updating database...";
          // console.log("uploaded");
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          // fileQ.setId(hash);
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log("File available at", downloadURL);
            const fileData = {
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              url: downloadURL,
              path: filePath,
            };
            await newFirestoreDoc(FILE_VAULT, fileData, hash);
            resolve(hash);
            state.info = "All done.";
            state.running = false;
          });
        }
      );
    });
  }

  return {
    /**
     * error,
     * completed,
     * percent,
     * running,
     * path //path of the folder
     */
    state,
    upload,
    cancel,
    // /**
    //  * reactive firebase doc
    //  */
    // fileDoc: fileQ.data,
  };
}

export function useVaultFile(id) {
  return useFirestoreDoc(FILE_VAULT, id);
}

export function useVaultFileQueryable(id = null) {
  return useFirestoreDocQueryable(FILE_VAULT, id);
}
export async function getVaultFileData(id) {
  if (!id) return null;
  return await getFirestoreDocData(firestoreDoc(FILE_VAULT, id));
}

/**
 * 配置跨域
 * https://cloud.google.com/storage/docs/configuring-cors#gsutil_2
 *
 * @param id
 */
export function downVaultFile(id) {
  getVaultFileData(id).then((file) => {
    downloadFileFromUrl(file.url, file.name, file.type);
  });
}
