<template>
  <layout-three-column
    middle-bigger
    full-card-first-col
    module-name="Quotations"
    :page-name="name"
    @backToSummary="quotationState.currentQuotationId = undefined"
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Quotations</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button text> Add New Quotation</hi-button>
        <add-new-quotation-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <quotation-list class="h-full" />
    </template>
    <template v-slot:col2 v-if="quotationState.currentQuotationId">
      <quotation-function-card />
      <quotation-preview
        v-if="quotationState.currentMiddleColView === 'preview'"
        class="grow h-0"
        :quotation-id="quotationState.currentQuotationId"
        :revision-id="quotationState.revisionId"
      />
      <hi-card
        v-else-if="quotationState.currentMiddleColView === 'edit'"
        class="grow h-0"
      >
        <quotation-editor-form
          :initial-values="quotationData"
          @successful="
            quotationState.currentMiddleColView = 'preview';
            quotationState.isUnSaved = false;
          "
          @cancel="
            quotationState.currentMiddleColView = 'preview';
            quotationState.isUnSaved = false;
          "
        />
      </hi-card>
      <hi-card v-else class="grow h-0">
        <invoice-editor-form
          :initial-values="quotationData"
          @successful="
            quotationState.currentMiddleColView = 'preview';
            quotationState.isUnSaved = false;
          "
          @cancel="
            quotationState.currentMiddleColView = 'preview';
            quotationState.isUnSaved = false;
          "
        />
      </hi-card>
      <!--      <quotation-multi-funciton-card />-->
    </template>
    <template v-slot:col3 v-if="quotationState.currentQuotationId"> </template>
  </layout-three-column>
</template>

<script>
import QuotationList from "@/hive-vue3/modules/quotation/QuotationList";
import { quotationState } from "@/hive-vue3/modules/quotation/state";
import { computed, watch } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import AddNewQuotationDialog from "@/hive-vue3/modules/quotation/AddNewQuotationDialog";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiButton from "@/hive-vue3/components/HiButton";
import {
  getQuotationById,
  getQuotationByIdAndRevisionId,
  getQuotationIdStr,
} from "@/hive-vue3/modules/quotation/data";
import { useRouter } from "vue-router";
import QuotationPreview from "@/hive-vue3/modules/quotation/QuotationPreview";
import QuotationFunctionCard from "@/hive-vue3/modules/quotation/QuotationFunctionCard";
import QuotationEditorForm from "@/hive-vue3/modules/quotation/QuotationEditorForm";
import HiCard from "@/hive-vue3/components/HiCard";
import InvoiceEditorForm from "@/hive-vue3/modules/invoice/InvoiceEditorForm";

export default {
  name: "QuotationViewFullKit",
  components: {
    InvoiceEditorForm,
    HiCard,
    QuotationEditorForm,
    QuotationFunctionCard,
    QuotationPreview,
    // QuotationMultiFuncitonCard,
    HiButton,
    HiDialogTrigger,
    AddNewQuotationDialog,
    LayoutThreeColumn,
    QuotationList,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    const router = useRouter();
    watchPropsImmediately(props, "urlId", (v) => {
      if (v) {
        const quotation = getQuotationById(v);
        if (quotation.revisions) {
          quotationState.revisionId = (
            Object.keys(quotation.revisions).length - 1
          ).toString();
        }
        quotationState.currentQuotationId = v;
      } else {
        if (quotationState.currentQuotationId) {
          const quotation = getQuotationById(quotationState.currentQuotationId);
          quotationState.revisionId = (
            Object.keys(quotation.revisions).length - 1
          ).toString();
          router.replace("/quotations/" + quotationState.currentQuotationId);
        } else {
          quotationState.revisionId = "0";
        }
      }
    });

    watch(
      () => quotationState.currentQuotationId,
      (v) => {
        if (v) {
          router.push("/quotations/" + v);
        } else {
          router.push("/quotations");
        }
      }
    );

    const quotationData = computed(() => {
      return getQuotationByIdAndRevisionId(
        quotationState.currentQuotationId,
        quotationState.revisionId
      );
    });

    return {
      quotationState,
      quotationData,
      name: computed(() => {
        if (quotationData.value && quotationData.value.quoteNum) {
          return (
            getQuotationIdStr(
              quotationData.value.quoteNum,
              quotationState.revisionId
            ) || undefined
          );
        }
        return undefined;
      }),
    };
  },
};
</script>

<style scoped></style>
