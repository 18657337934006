<template>
  <hi-tagger v-bind="$props" v-model="tagValue" />
</template>

<script>
// import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
// import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
// import HiChip from "@/hive-vue3/components/HiChip";
// import HiSimpleInlineInput from "@/hive-vue3/components/HiSimpleInlineInput";
// import {
//   encodeDocId,
//   firestoreDoc,
//   updateFirestoreDoc,
// } from "@/hive-vue3/firebase";
// import hive from "@/hive-vue3/components/hive";
import { ref } from "vue";
import fireDocHelper from "@/hive-vue3/components/fireDoc/logics/fireDocHelper";
// import HiIcon from "@/hive-vue3/components/HiIcon";
// import { mdiSquareEditOutline } from "@mdi/js";
// import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
// import { lazyFunction } from "@/hive-vue3/utils/functionUtils";
import HiTagger from "@/hive-vue3/components/HiTagger";

export default {
  name: "HiFireDocTagger",
  components: {
    HiTagger,
    // HiTagger,
    // TransitionFade,
    // HiIcon,
    // HiSimpleInlineInput,
    // HiChip,
    // HiChipsSelectable,
  },
  props: {
    collectionPath: String,
    docPath: {
      type: String,
    },
    /**
     * the tag items. if items specified, docPath & collectionPath will be ignored & will not be able to add new tags.
     */
    items: {
      type: [Array, Object],
    },
    valueKey: String,
    multiple: Boolean,
    label: {
      type: String,
      default: "Tags",
    },
    emptyValue: {
      type: String,
      default: "N/A",
    },
    canEdit: Boolean,
    editMode: Boolean,
  },
  setup(props) {
    // if (props.docPath && props.items) {
    //   console.warn(
    //     `HiFireDocTagger: props.items has been specified. props.docPath ${props.docPath} has been ignored!`
    //   );
    // }
    // const adding = ref(false);
    // //the style for inline editor
    // const editStyles = reactive({ opacity: 1 });
    // const editing = ref(false);
    //
    // let doc, docRef;
    // if (props.docPath) {
    //   doc = useFirestoreDoc(
    //     props.collectionPath || props.docPath,
    //     props.docPath
    //   );
    //   docRef = firestoreDoc(
    //     props.collectionPath || props.docPath,
    //     props.docPath
    //   );
    // }
    //
    // function initStyle() {
    //   editStyles.opacity = 1;
    // }
    // async function submitNewTag(text) {
    //   editStyles.opacity = 0.5;
    //   const key = encodeDocId(text);
    //   if (doc[key]) {
    //     hive.toastError(`Tag '${text}' already exists!`);
    //     initStyle();
    //     return;
    //   }
    //   const data = {};
    //   data[key] = text;
    //   await updateFirestoreDoc(docRef, data);
    //
    //   if (props.multiple) {
    //     await helper.pushToMeAsArray(key);
    //     lazySwitchOffEditing();
    //   } else {
    //     await helper.updateMe(key);
    //     editing.value = false;
    //   }
    //   adding.value = false;
    //   initStyle();
    // }
    // function cancelAdding() {
    //   initStyle();
    //   lazySwitchOffEditing();
    //   adding.value = false;
    // }
    //
    // const lazySwitchOffEditing = lazyFunction(() => {
    //   if (adding.value) return;
    //   editing.value = false;
    // }, 4000);
    //
    // function edit() {
    //   editing.value = true;
    //   lazySwitchOffEditing();
    // }
    // function onChange() {
    //   if (!props.multiple) {
    //     editing.value = false;
    //   } else {
    //     lazySwitchOffEditing();
    //   }
    // }

    //// db operations /////////////
    const helper = fireDocHelper(props);
    const tagValue = ref();
    helper.syncRef(tagValue);

    return {
      // tags: computed(() => props.items || doc),
      // submitNewTag,
      // cancelAdding,
      // adding,
      // editing,
      // editStyles,
      tagValue,
      // mdiSquareEditOutline,
      // onChange,
      // edit,
    };
  },
};
</script>

<style scoped></style>
