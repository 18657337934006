<template>
  <span @click="click" class="cursor-pointer"><slot /></span>
</template>

<script>
import { inject } from "vue";

export default {
  name: "HiStageLink",
  props: {
    back: Boolean,
    home: Boolean,
    to: String,
  },
  setup(props) {
    const back = inject("hi-stages-back", undefined);
    const goto = inject("hi-stages-goto");
    if (!back) {
      console.warn(
        "hi-stage-link can only be used within hi-stages. No hi-stages found!"
      );
    }
    function click() {
      if (props.back) {
        back();
      } else if (props.home) {
        goto("home");
      } else {
        goto(props.to);
      }
    }
    return {
      click,
    };
  },
};
</script>

<style scoped></style>
