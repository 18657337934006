import axios from "axios";
import { getAuth } from "firebase/auth";
import { serverAddress } from "@/conf/serverAddress";

// ===== Enquiry =====
export const requestAddEnquiry = async (data) => {
  return await requestPost("/system/addEnquiry", data);
};

// ====== Activity =======
/**
 * 请求服务器发送 activity 提醒邮件
 * activity 邮件会发送到 users 里除了发起人的所有人
 * users 数组在前台尽量使用 arrayUnique 主动去重， 以避免后台重复发送
 * 邮件 template: https://edm.artimber.au/20221019/systemNotificationTemplate.html
 * @param data
 * @returns {Promise<any>}
 */
export const requestActivityNotification = async (data) => {
  // ===== 请求发送提醒邮件的数据结构 =====
  // const conf = {
  //   users: ["iEzco88IEUcVtEwRYudZd3LisGf2"],
  //   smallTitle: "New activity in",
  //   mainTitle: "Test Project of Artimber",
  //   timeStr: "30 Sep 15:92",
  //   message: "Hi, this is a test message for system notification",
  //   directLink: "https://my.artimber.au",
  //   breadcrumbs: [
  //     {
  //       href: "https://my.artimber.au",
  //       text: "My Artimber",
  //     },
  //     {
  //       href: "https://my.artimber.au/leads",
  //       text: "leads",
  //     },
  //   ],
  // };

  const conf = {
    smallTitle: "New activity in",
    ...data,
  };

  return await requestPost("/system/activityNotification", conf);
};

// ========== Schedule ============

/**
 * Schedule 相关配置函数
 * 新建 Schedule, 若 scheduledTime 小于当前时间则会立即执行
 * 目前只有 Edm，使用 type 及 vars 指定 EdmId, 并指定执行时间
 * @param data
 * @returns {Promise<any>}
 */
export const requestSetSchedule = async (data) => {
  // scheduledTime: data.scheduledTime,
  //   type: "edm",
  //   vars: {
  //   edmId: edmState.currentEdmId,
  // },
  return await requestPost("/schedule/setSchedule", data);
};

/**
 * Schedule 相关配置函数
 * 取消服务器 Schedule
 * 给定 ScheduleId 使服务器取消 Schedule
 * @param data
 * @returns {Promise<any>}
 */
export const requestCancelSchedule = async (data) => {
  // {
  //   taskId: edmDetails.taskId,
  // }
  return await requestPost("/schedule/cancelSchedule", data);
};

/**
 * Schedule 相关配置函数
 * 给定 taskId 修改 schedule 时间
 * @param data
 * @returns {Promise<any>}
 */
export const requestChangeSchedule = async (data) => {
  // {
  //   taskId: taskId,
  //   scheduledTime: scheduledTime,
  //   type: "edm",
  // }
  return await requestPost("/schedule/changeSchedule", data);
};

// ====== twilio =======
/**
 * 电话转接
 * 给定当前电话的 Sid 使服务器进行电话转接
 * @param data
 * @returns {Promise<any>}
 */
export const requestRedirectPhoneCall = async (data) => {
  // {
  //   callId: CallSid,
  //   transNumber: mobileNum,
  //   uid: system userId
  // }
  return await requestPost("/system/redirectCall", data);
};

// ===== Quotation / Invoice ===========
/**
 * 使用模板发送 quotation
 * @param data
 * @returns {Promise<any>}
 */
export const requestSendQuotationEmail = async (data) => {
  return await requestPost("/system/email/quotation/send", data);
};

/**
 * 向服务器请求 quotation 文件
 * @param quotationData
 * @returns {Promise<any>}
 */
export const requestQuotationPDF = async (quotationData) => {
  return await requestPost("/system/quotation/generate", quotationData);
};

/**
 * 向服务器请求 quotation 文件 （无背景色）
 * @param quotationData
 * @returns {Promise<any>}
 */
export const requestQuotationPDFNoBackground = async (quotationData) => {
  return await requestPost(
    "/system/quotation/generate/noBackground",
    quotationData
  );
};

/**
 * 向服务器请求 quotation 预览文件
 * @param quotationData
 * @returns {Promise<any>}
 */
export const requestQuotationPreviewPDFUrl = async (quotationData) => {
  return await requestPost("/system/quotation/preview/request", quotationData);
};

/**
 * 向服务器请求 Invoice 文件
 * @param quotationData
 * @returns {Promise<any>}
 */
export const requestInvoiceFileUrl = async (quotationData) => {
  return await requestPost(
    "/system/invoice/generate/noBackground",
    quotationData
  );
};

/**
 * 向服务器请求 Invoice 预览文件
 * @param quotationData
 * @returns {Promise<any>}
 */
export const requestInvoicePreviewPDFUrl = async (quotationData) => {
  return await requestPost("/system/invoice/preview/request", quotationData);
};

// ======= 系统用户相关 ========
/**
 * 添加用户
 * @returns {Promise<any>}
 */
export const requestCreateUser = async () => {
  return await requestPost("/system/create");
};

/**
 * 发送新用户邀请邮件
 * @param toEmail
 * @param toName
 * @returns {Promise<any>}
 */
export const requestSendInvitationEmail = async (toEmail, toName) => {
  return await requestPost("/system/email/invitation", {
    toEmail,
    toName,
  });
};

/**
 * 邀请用户邮件模板
 * @param data
 * @returns {Promise<any>}
 */
export const requestCreateInvitationTemplate = async (data) => {
  return await requestPost("/system/email/invitation/create", data);
};

// ====== 邮件相关 ============
/**
 * 服务器抓取 template 并优化媒体链接
 * @param templateUrl
 * @returns {Promise<any>}
 */
export const requestGetMailTemplateFromRemoteUrl = async (templateUrl) => {
  return await requestPost("/edm/getFromRemoteUrl", { templateUrl });
};

// ======== Google API ==============

/**
 * gmail 授权相关
 * @param data
 * @returns {Promise<any>}
 */
export const requestSetGmailToken = async (data) => {
  return await requestPost("/gmail/setAuth", data);
};

/**
 * Google API
 * 将邮件标记为已读
 * @param messageId
 * @returns {Promise<any>}
 */
export const requestSetEmailAsRead = async (messageId) => {
  return await requestPost("/gmail/markAsRead", { messageId });
};

/**
 * google API
 * 获取授权
 * @returns {Promise<any>}
 */
export const requestNewAccessToken = async () => {
  return await requestPost("/gmail/getToken");
};

// ========== ABN Check ==============

/**
 * 通过公司名获取 ABN
 * return {
 *     abn:"21169314112",
 *     entityStatusCode:"Active",
 *     effectiveFrom: "2014-04-30",
 *     asicNumber: "169314112",
 *     entityTypeCode: "PRV",
 *     entityTypeDescription:"Australian Private Company",
 *     gstEffectiveFrom": "2014-04-30",
 *     organisationName: "HIVE PROPERTIES PTY. LTD.",
 *     effectiveFrom: "2014-04-30",
 *     stateCode:"VIC",
 *     postcode: "3126",
 * }
 */
``;
export async function requestAbnByName(
  name,
  maxSearchResults = 10,
  activeOnly = true
) {
  return await requestPost("/system/abnLookupByName", {
    name,
    maxSearchResults,
    activeOnly,
  });
}

/**
 * 通过 abn 获取公司名
 * @param abn
 * @returns {Promise<*>}
 */
export async function requestNameByAbn(abn) {
  return await requestPost("/system/abnLookupByAbn", {
    abn,
  });
}

// ========= Netbank ========

export async function requestAddTransactions(transactions) {
  return await requestPost("/system/netBank/transactions/insert", {
    transactions,
  });
}
export async function requestGetTransactions(accountId, limit, offset) {
  return await requestPost("/system/netBank/transactions/get", {
    accountId,
    limit,
    offset,
  });
}

// ========= 通用接口 ========

/**
 * 使用后台 Mailgun 接口验证邮件地址可达性
 * @param email
 * @returns {Promise<{successful: boolean, errorMsg: string}|any>}
 */
export const requestEmailVerification = async (email) => {
  if (email) {
    return await requestPost("/system/verifyEmail", {
      email,
    });
  }
  return {
    successful: false,
    errorMsg: "No Email Address",
  };
};

/**
 * 通用服务： get anywhere
 * @param url
 * @param config
 * @returns {Promise<any>}
 */
export const requestGetFromAnyUrl = async (url, config = {}) => {
  return await requestPost("/system/getFromUrl", { url, config });
};

/**
 * 通用服务： post anywhere
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
export const requestPostFromAnyUrl = async (url, data, config = {}) => {
  return await requestPost("/system/postFromUrl", { url, data, config });
};

/**
 * 后台通用接口
 * @param url
 * @param data
 * @returns {Promise<any>}
 */

const requestPost = async (url, data) => {
  let baseUrl = serverAddress;

  const idToken = await getAuth().currentUser.getIdToken();
  // console.log(idToken);

  const res = await axios.post(baseUrl + url, data, {
    headers: {
      idToken,
    },
  });
  return res.data;
};

// ===== 待处理 =====

export const requestZWave = async (data) => {
  return await requestPost("/system/zwave/set", data);
};

export const requestStartNewCall = async (data) => {
  return await requestPost("/system/startNewCall", data);
};
