import $el from "./$el";

export function writeHtmlToIframe(el, html) {
  const iframe = $el(el);
  if (!iframe) {
    console.log("writeHtmlToIframe() -> el is not valid", el);
    return;
  }
  const doc = iframe.contentWindow.document;
  doc.open();
  doc.write(html);
  doc.close();
}
