<template>
  <hi-autocomplete
    :items="contacts"
    :search-keys="['name', 'email']"
    placeholder="Input contact name or email..."
    item-value-key="id"
    :format-display-fn="formatDisplay"
    :add-new-dialog-component="NewContactsPeopleDialog"
    @change="(v) => $emit('change', v)"
    v-model="model"
    :excludes="excludes"
    ref="input"
    :form-key="formKey"
    :auto-focus="autoFocus"
    :no-form="noForm"
    :disabled="disabled"
  />
</template>

<script>
import { useContactPeopleList } from "@/hive-vue3/modules/contacts/data";
import NewContactsPeopleDialog from "@/hive-vue3/modules/contacts/ContactsNewPeopleDialog";
import HiAutocomplete from "@/hive-vue3/components/form/controls/HiAutocomplete";
import { templateRef } from "@vueuse/core";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import formControl from "@/hive-vue3/components/form/logics/formControl";

export default {
  name: "MfcInputContactsPeople",
  components: {
    HiAutocomplete,
  },
  props: {
    modelValue: String,
    excludes: Array,
    ...commonFormInputProps,
    disabled: Boolean,
  },
  emits: ["change", "update:modelValue"],
  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;
    const contacts = useContactPeopleList();
    function formatDisplay(item) {
      return `${item.name} | ${item.mobile} | ${item.email}`;
    }
    const input = templateRef("input");
    function focus() {
      input.value && input.value.focus();
    }
    return {
      focus,
      contacts,
      formatDisplay,
      NewContactsPeopleDialog,
      model,
    };
  },
};
</script>

<style scoped></style>
