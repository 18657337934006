<template>
  <div v-if="!showOnProgressOnly || (modelValue > 0 && modelValue < 100)">
    <svg class="___hi-progress-ring" viewBox="0 0 100 100" ref="root">
      <!--      the progress indicator ring-->
      <circle
        class="__progress-ring__circle"
        stroke="currentColor"
        :stroke-width="thickness"
        fill="transparent"
        :r="radius"
        cx="50%"
        cy="50%"
        :style="style"
      />
      <!--      the full ring with opacity 0.3-->
      <circle
        stroke="currentColor"
        :stroke-width="thickness"
        fill="transparent"
        :r="radius"
        cx="50%"
        cy="50%"
        style="opacity: 0.3"
      />
      <!--      the number display-->
      <text
        v-if="text"
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        fill="currentColor"
        style="font: bold 30px sans-serif; opacity: 0.5"
      >
        {{
          Math.round(modelValue * Math.pow(10, decimal)) / Math.pow(10, decimal)
        }}
      </text>
    </svg>
  </div>
</template>

<script>
// import { templateRef } from "@vueuse/core";
import { reactive, ref, watchEffect } from "vue";

export default {
  name: "HiProgressRing",
  props: {
    thickness: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    text: {
      type: Boolean,
      default: true,
    },
    showOnProgressOnly: {
      type: Boolean,
    },
    decimal: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    // const circle = templateRef("root");
    const radius = ref(50);
    const style = reactive({
      strokeDasharray: "",
      strokeDashoffset: "",
    });
    watchEffect(() => {
      const r = (radius.value = 50 - props.thickness);
      const circumference = r * 2 * Math.PI;
      const offset = circumference - (props.modelValue / 100) * circumference;
      style.strokeDashoffset = `${offset}`;
      style.strokeDasharray = `${circumference} ${circumference}`;
    });
    return {
      radius,
      style,
    };
  },
};
</script>

<style scoped>
.___hi-progress-ring {
}
.__progress-ring__circle {
  transition: 0.5s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
