<template>
  <layout-three-column no-big-screen full-card-first-col>
    <template v-slot:module-title>
      <div class="tp-title-faded">EDM</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button text>Add New Campaign</hi-button>
        <edm-new-campaign-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <hi-tab-group class="h-full">
        <hi-tab label="Campaign">
          <edm-campaign-list class="h-full" />
        </hi-tab>
        <hi-tab label="Group"> 798 </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:col2 v-if="edmState.currentEdmId">
      <edm-sent-result-card
        v-if="edmDetails.status === 'sent'"
        :sent-edm-id="edmState.currentEdmId"
      />
      <edm-details-edit-card v-else />
    </template>
    <template v-slot:col3 v-if="edmDetails.template">
      <html-viewer
        class="w-full h-full"
        :html="edmDetails.template"
      ></html-viewer>
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import HtmlViewer from "@/views/HtmlViewer";
import { edmState } from "@/hive-vue3/modules/edm/state.js";
import EdmCampaignList from "@/hive-vue3/modules/edm/EdmCampaignList";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiButton from "@/hive-vue3/components/HiButton";
import EdmNewCampaignDialog from "@/hive-vue3/modules/edm/EdmNewCampaignDialog";
import EdmDetailsEditCard from "@/hive-vue3/modules/edm/EdmDetailsEditCard";
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";
import EdmSentResultCard from "@/hive-vue3/modules/edm/EdmSentResultCard";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
export default {
  name: "EdmViewFullKit",
  components: {
    EdmSentResultCard,
    EdmDetailsEditCard,
    EdmNewCampaignDialog,
    HiButton,
    HiDialogTrigger,
    EdmCampaignList,
    HtmlViewer,
    HiTab,
    HiTabGroup,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    const edmDetails = useFirestoreDocReactiveId(
      "edm/campaigns/registers",
      edmState,
      "currentEdmId"
    );

    setRouterId(props, edmState, "currentEdmId", "/edm/");

    return {
      edmState,
      edmDetails,
    };
  },
};
</script>

<style scoped></style>
