<template>
  <span class="capitalize">{{ position }}</span>
</template>

<script>
import { useContactsEntityPositionWithReactiveId } from "@/hive-vue3/modules/contacts/data";

export default {
  name: "ContactsEntityPosition",
  props: {
    positionId: String,
  },
  setup(props) {
    const position = useContactsEntityPositionWithReactiveId(props);
    return {
      position,
    };
  },
};
</script>

<style scoped></style>
