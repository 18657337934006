import {
  newFirestoreDoc,
  useCurrentFirebaseUserId,
} from "@/hive-vue3/firebase";
// import { ActivityProvider } from "@/hive-vue3/modules/activities/activitiesDataProvider";
import { computed, reactive } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { where } from "firebase/firestore";
import fireStoreQueryBuilder from "@/hive-vue3/firebase/fireStoreQueryBuilder";
import { ActivityProvider } from "@/hive-vue3/modules/activities/activitiesDataProvider";
import { requestActivityNotification } from "@/hive-vue3/api/request";
import { getAccountNameById } from "@/hive-vue3/modules/accounts/data";
import moment from "moment";
import { getEnquiryNameById } from "@/hive-vue3/modules/enquiries/data";
import { getPeopleNameByLeadId } from "@/hive-vue3/modules/leads/data";
import { getProjectNameById } from "@/hive-vue3/modules/projects/data";

/**
 *       const activityData = new DefaultActivityData();
 *       activityData.action = "Link New Contact";
 *       activityData.target = "project";
 *       activityData.targetId = props.projectId;
 *       activityData.comments = `Link contact ${formatContactsPeopleName(
 *         contact,
 *         { title: false }
 *       )} as ${data.type}`;
 *       activityData.actionTime = new Date();
 *       activityData.pipeId = getProjectById(props.projectId).pipeId;
 *       await addNewActivity(activityData);
 *
 */

export function DefaultActivityData() {
  this.action = null;
  this.byUser = null;
  this.byEmail = null;
  this.title = null;
  this.comments = null;
  this.flags = {
    hasFile: false,
  };
  this.contacts = null;
  this.target = null;
  this.targetId = null;
  this.actionTime = null;
  this.users = null;
  this.pipeId = null;
  this.var = null;
}

export async function addNewActivity(activityData) {
  const fireRes = await newFirestoreDoc("activities", activityData);
  if (activityData.users) {
    const baseUrl = "https://my.artimber.au/";

    const breadcrumbs = [
      {
        href: baseUrl,
        text: "My Artimber",
      },
    ];

    const m = moment(new Date());
    const timeStr = m.format("DD MMM HH:mm");
    const message = activityData.comments;

    let mainTitle;
    let directLink;

    switch (activityData.target) {
      case "accounts": {
        directLink = baseUrl + "accounts/" + activityData.targetId;
        const accountName = getAccountNameById(activityData.targetId);
        breadcrumbs.push({
          href: baseUrl + "accounts/",
          text: "accounts",
        });
        breadcrumbs.push({
          href: directLink,
          text: accountName,
        });
        mainTitle = accountName;
        console.log("Add activity to accounts: " + activityData.targetId);
        break;
      }
      case "enquiry": {
        directLink = baseUrl + "enquiries/" + activityData.targetId;
        const enquiryName = getEnquiryNameById(activityData.targetId);
        breadcrumbs.push({
          href: baseUrl + "enquiries/",
          text: "enquiry",
        });
        breadcrumbs.push({
          href: directLink,
          text: enquiryName,
        });
        mainTitle = enquiryName;
        console.log("Add activity to enquiry: " + activityData.targetId);
        break;
      }

      case "leads": {
        directLink = baseUrl + "leads/" + activityData.targetId;
        const leadName = getPeopleNameByLeadId(activityData.targetId);
        breadcrumbs.push({
          href: baseUrl + "leads/",
          text: "leads",
        });
        breadcrumbs.push({
          href: directLink,
          text: leadName,
        });
        mainTitle = leadName;
        console.log("Add activity to leads: " + activityData.targetId);
        break;
      }

      case "project": {
        directLink = baseUrl + "projects/" + activityData.targetId;
        const projectName = getProjectNameById(activityData.targetId);
        breadcrumbs.push({
          href: baseUrl + "projects/",
          text: "projects",
        });
        breadcrumbs.push({
          href: directLink,
          text: projectName,
        });
        mainTitle = projectName;
        console.log("Add activity to projects: " + activityData.targetId);
        break;
      }
      default:
        console.warn("No target for the activity");
        break;
    }

    let users = [];
    const currentUserId = useCurrentFirebaseUserId();

    for (const i of activityData.users) {
      if (i !== currentUserId.value) {
        users.push(i);
      }
    }

    console.log(users, mainTitle, timeStr, message, directLink, breadcrumbs);
    requestActivityNotification({
      users,
      mainTitle,
      timeStr,
      message,
      directLink,
      breadcrumbs,
    })
      .then()
      .catch((e) => {
        console.warn("activity request error: " + e);
      });
  }

  return fireRes;
}

export function provideActivityData(pipeIdRef) {
  const activityClass = new ActivityProvider();
  activityClass.provide();

  const threadDocs = reactive({
    docRaws: undefined,
  });

  watchPropsImmediately(pipeIdRef, "value", async (v) => {
    if (v) {
      // console.log(v);
      threadDocs.docRaws = await useFirestoreCollectionAsRef(
        "/system/syncedEmail/allThreads",
        {
          query: [where("pipeIds", "array-contains", v)],
        }
      );
    }
  });

  const threads = computed(() => {
    const returnArr = [];
    if (threadDocs.docRaws && threadDocs.docRaws.length) {
      for (const i of threadDocs.docRaws) {
        returnArr.push(i.threadId);
      }
    }
    return returnArr;
  });

  watchPropsImmediately(threads, "value", async (v) => {
    if (pipeIdRef.value) {
      activityClass.resetActivitySources();
      const query2 = fireStoreQueryBuilder("/activities");
      query2.where("pipeId", "==", pipeIdRef.value);
      activityClass.addActivitiesSourceFromFirestore(query2);

      if (v.length) {
        for (const i of v) {
          const query3 = fireStoreQueryBuilder("/system/syncedEmail/allMail");
          query3.where("threadId", "==", i);
          activityClass.addActivitiesSourceFromFirestore(query3, {
            type: "email",
            idKey: "threadId",
            timeKey: "saveTime",
            userKey: "emailAddresses",
            titleFn: (snapData) => {
              return snapData.headers.subject;
            },
            descriptionFn: (snapData) => {
              return snapData.snippet;
            },
          });
        }
      }
      activityClass.read();
    }
  });
}

export async function provideActivityDataByUserId(peopleIdRef) {
  const activityClass = new ActivityProvider();
  activityClass.provide();

  watchPropsImmediately(peopleIdRef, "value", async (v) => {
    const threadDocs = reactive({
      docRaws: undefined,
    });

    threadDocs.docRaws = await useFirestoreCollectionAsRef(
      "/system/syncedEmail/allThreads",
      {
        query: [where("__updatedBy", "==", v)],
      }
    );

    const threads = computed(() => {
      const returnArr = [];
      if (threadDocs.docRaws && threadDocs.docRaws.length) {
        for (const i of threadDocs.docRaws) {
          if (i.pipeIds && i.pipeIds.length) {
            returnArr.push(i.threadId);
          }
        }
      }
      return returnArr;
    });

    watchPropsImmediately(threads, "value", async (v) => {
      activityClass.resetActivitySources();
      const query2 = fireStoreQueryBuilder("/activities");
      query2.where("__createdBy", "==", peopleIdRef.value);
      activityClass.addActivitiesSourceFromFirestore(query2);

      if (v.length) {
        for (const i of v) {
          const query3 = fireStoreQueryBuilder("/system/syncedEmail/allMail");
          query3.where("threadId", "==", i);
          activityClass.addActivitiesSourceFromFirestore(query3, {
            type: "email",
            idKey: "threadId",
            timeKey: "saveTime",
            userKey: "emailAddresses",
            titleFn: (snapData) => {
              return snapData.headers.subject;
            },
            descriptionFn: (snapData) => {
              return snapData.snippet;
            },
          });
        }
      }

      activityClass.read();
    });
  });
}
