import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import {
  formatContactsPeopleName,
  getContactsPeople,
} from "@/hive-vue3/modules/contacts/data";

const cache = {
  leads: undefined,
};

function initLeads() {
  if (!cache.leads) {
    cache.leads = useFirestoreCollectionAsRefGlobally("leads");
  }
}

function getToBeAssignedLeads() {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (!i.mainOwner && i.status !== "closed") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

function getAssignedLeads() {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.mainOwner && i.status !== "closed") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getClosedLeads() {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.status === "closed") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getAllLeadsByUserId(userId) {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.mainOwner === userId) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getActiveLeadsByUserId(userId) {
  const returnArr = getAllLeadsByUserId(userId);
  return returnArr.filter((v) => v.status !== "closed");
}

export function getClosedLeadsByUserId(userId) {
  const returnArr = getAllLeadsByUserId(userId);
  return returnArr.filter((v) => v.status === "closed");
}

export function getActiveLeads() {
  const a = getToBeAssignedLeads();
  const b = getAssignedLeads();
  return [...a, ...b];
}

export function getLeadById(leadId) {
  initLeads();
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.id === leadId) {
        return i;
      }
    }
  }
  return undefined;
}

export function getLeadsByAccount(accountId) {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.accountId === accountId) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}
export function getLeadsByProject(projectName) {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.projects && i.projects.indexOf(projectName) >= 0) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getLeadPipeIdById(leadId) {
  initLeads();
  if (cache.leads && cache.leads.value) {
    for (const i of cache.leads.value) {
      if (i.id === leadId) {
        return i.pipeId;
      }
    }
  }
  return undefined;
}
export function getPeopleEmailByLeadId(leadId) {
  initLeads();
  const leadDetails = getLeadById(leadId);
  if (!leadDetails || !leadDetails.peopleId) return "";
  // console.log(leadDetails);
  const peopleDetails = getContactsPeople(leadDetails.peopleId);
  if (peopleDetails && peopleDetails.email) {
    return peopleDetails.email;
  }
  return "";
}
export function getPeopleNameByLeadId(leadId) {
  initLeads();
  const leadDetails = getLeadById(leadId);
  if (!leadDetails || !leadDetails.peopleId) return undefined;
  const peopleDetails = getContactsPeople(leadDetails.peopleId);

  const res = formatContactsPeopleName(peopleDetails);
  if (res) {
    return res;
  } else {
    return undefined;
  }
}

export function getLeadsByGroups(groups) {
  initLeads();
  const returnArr = [];
  if (cache.leads && cache.leads.value) {
    if (groups.indexOf("all") !== -1) {
      return cache.leads.value;
    } else {
      for (const i of cache.leads.value) {
        for (const j of groups) {
          if (i.groups && i.groups.indexOf(j) !== -1) {
            returnArr.push(i);
          }
        }
      }
    }
  }
  return returnArr;
}

export async function assignLead(leadId, assignedDetails) {
  await updateFirestoreDoc(firestoreDoc("leads", leadId), {
    ...assignedDetails,
    status: "assigned",
  });
}

export async function closeLead(leadId, data = undefined) {
  await updateFirestoreDoc(firestoreDoc("leads", leadId), {
    status: "closed",
    ...data,
  });
}

export async function reOpenLead(leadId) {
  await updateFirestoreDoc(firestoreDoc("leads", leadId), {
    status: "assigned",
  });
}

export async function updateLeadById(leadId, data) {
  await updateFirestoreDoc(firestoreDoc("leads", leadId), data);
}

export const leadsSortRules = [
  {
    key: "__dateCreated",
    text: "Create",
    type: dataListSortingTypes.DATE,
    desc: true,
    DEFAULT: false,
  },
  {
    key: "__dateUpdated",
    text: "Update",
    type: dataListSortingTypes.DATE,
    desc: true,
    DEFAULT: false,
  },
];

export function jumpToLeadsById(router, leadId) {
  router.push("/leads/" + leadId);
}

// export function setRouterId(props, reactiveObj, key) {
//   const router = useRouter();
//
//   watchPropsImmediately(props, "urlId", (v) => {
//     if (v && v !== "default") {
//       reactiveObj[key] = v;
//     }
//   });
//
//   watch(
//     () => reactiveObj[key],
//     (v) => {
//       if (v) {
//         router.push(v);
//       }
//     }
//   );
// }
