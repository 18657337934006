<template>
  <slot />
  <div class="flex">
    <hi-form-error class="grow" :model-value="error" />
    <div><slot name="extra-info" /></div>
  </div>
  <div v-if="isDebugging" class="flex gap-2">
    <div class="debug-label">formKey: {{ formKey }}</div>

    <div class="debug-info">{{ modelValue }}</div>
  </div>
</template>

<script>
import { watchModelImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import HiFormError from "@/hive-vue3/components/form/HiFormError";

export default {
  name: "HiFormValueHolderArray",
  components: { HiFormError },
  props: {
    formKey: String,
    modelValue: null,
    validator: Function,
  },
  setup(props, context) {
    const control = formControl(props, context);
    //todo: Formatter/Validator 数组封装
    watchModelImmediately(props, (v) => {
      if (v) {
        control.model.value = v;
      }
    });
    return {
      isDebugging: control.isDebugging,
      error: control.error,
    };
  },
};
</script>

<style scoped></style>
