<template>
  <div>
    <div class="bg-light-400 view-block flex items-center" v-if="!hideTitle">
      <div class="tp-small-title-faded">{{ title }}</div>
      <div class="grow"></div>
      <div><slot name="tools" /></div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleTitledBlock",
  props: {
    title: String,
    hideTitle: Boolean,
  },
};
</script>

<style scoped></style>
