<template>
  <div ref="container">
    <div class="relative">
      <div class="absolute right-0 top-0" v-if="editable && mouseOver">
        <hi-icon
          :path="commonIcons.edit"
          class="clickable-icon"
          @click="openEditor = true"
        />
      </div>
      <slot />
      <hi-dialog v-model="openEditor" :title="dialogTitle">
        <slot name="editor" :closeDialog="close" />
      </hi-dialog>
    </div>
  </div>
</template>

<script>
import { templateRef } from "@vueuse/core";
import useMouseOver from "@/hive-vue3/utils/sensors/useMouseOver";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";

export default {
  name: "ModuleBlockEditable",
  components: { HiDialog, HiIcon },
  props: {
    editable: Boolean,
    dialogTitle: String,
  },
  setup() {
    const openEditor = ref(false);
    const container = templateRef("container");
    const mouseOver = useMouseOver(container);
    return {
      commonIcons,
      mouseOver,
      openEditor,
      close() {
        openEditor.value = false;
      },
    };
  },
};
</script>

<style scoped></style>
