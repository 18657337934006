// import SparkMD5 from "spark-md5";
// import Md5 from "crypto-js/md5";
import base64 from "crypto-js/enc-base64";
// import CryptoJS from "crypto-js";
import latin1 from "crypto-js/enc-latin1";
import md5 from "crypto-js/md5";
//
// console.log(CryptoJS);
//
// const blobSlice =
//     File.prototype.slice ||
//     File.prototype.mozSlice ||
//     File.prototype.webkitSlice,
//   chunkSize = 2097152;
//
/**
 *
 * @param file File
 */
export function fileMd5(file) {
  // Read in chunks of 2MB
  // const chunks = Math.ceil(file.size / chunkSize),
  //   spark = new SparkMD5.ArrayBuffer(),
  //   fileReader = new FileReader();
  //
  // let currentChunk = 0;
  //
  // fileReader.onload = function (e) {
  //   console.log("read chunk nr", currentChunk + 1, "of", chunks);
  //   spark.append(e.target.result); // Append array buffer
  //   currentChunk++;
  //
  //   if (currentChunk < chunks) {
  //     loadNext();
  //   } else {
  //     console.log("finished loading");
  //     console.info("computed hash", spark.end()); // Compute hash
  //   }
  // };
  //
  // fileReader.onerror = function () {
  //   console.warn("oops, something went wrong.");
  // };
  //
  // function loadNext() {
  //   const start = currentChunk * chunkSize,
  //     end = start + chunkSize >= file.size ? file.size : start + chunkSize;
  //
  //   fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  // }
  //
  // loadNext();

  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onloadend = function () {
      // const hash = Md5(reader.result);
      const hash = md5(latin1.parse(this.result));
      resolve(hash.toString());
      // console.log("base64", base64.stringify(hash));
    };
    reader.onerror = function () {
      reject();
    };

    reader.readAsBinaryString(file);
  });
}
export async function fileBase64Md5(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onloadend = function () {
      // const hash = Md5(reader.result);
      const hash = md5(latin1.parse(this.result));

      resolve(base64.stringify(hash));
      // console.log("base64", base64.stringify(hash));
    };
    reader.onerror = function () {
      reject();
    };

    reader.readAsBinaryString(file);
  });
}
