import {
  computed,
  inject,
  provide,
  reactive,
  ref,
  // watchEffect
} from "vue";
import { subDays } from "date-fns";
import useFirestoreCollectionByQueryBuilder from "@/hive-vue3/firebase/useFirestoreCollectionByQueryBuilder";

/**
 * Default source params:
 *       timeKey = "actionTime",
 *       userKey = "users",
 *       titleKey = "title",
 *       descriptionKey = "comments",
 *       descriptionFn = null,
 *       type = null,
 *       typeKey = "action",
 * Formatted Object : { time, users, type, title, description }
 *
 */
export class ActivityProvider {
  #sources = ref([]);
  // activities = reactive([]);
  #settings = {};
  constructor(days = 300) {
    this.#settings.days = days;
  }
  #activities = null;
  #toDate = null;

  #pool = reactive([]);

  getDay() {
    return this.#settings;
  }

  #read() {
    const srcs = this.#sources.value;
    const toDate = this.#toDate || new Date();
    // eslint-disable-next-line no-unused-vars
    const fromDate = subDays(toDate, this.#settings.days);
    for (let i = 0; i < srcs.length; i++) {
      const src = srcs[i];
      if (!src.query.build) {
        console.warn("Unsupported query. Please use fireStoreQueryBuilder.");
        return;
      }
      const query = src.query.clone();
      if (this.#toDate) {
        query.where(src.timeKey, "<=", this.#toDate);
      }
      // query.where(src.timeKey, ">", fromDate);
      const data = useFirestoreCollectionByQueryBuilder(query, {
        mapFn(snapData) {
          // console.log(snapData);
          const id = snapData[src.idKey];
          let time = snapData[src.timeKey].toDate();
          if (!(time instanceof Date)) {
            time = new Date(time);
          }
          const user = snapData[src.userKey];
          const users = Array.isArray(user) ? user : [user];
          const type = src.type ? src.type : snapData[src.typeKey];
          const title = src.titleFn
            ? src.titleFn(snapData)
            : snapData[src.titleKey];
          const description = src.descriptionFn
            ? src.descriptionFn(snapData)
            : snapData[src.descriptionKey];
          // console.log(snapData[src.descriptionKey], src.descriptionKey);
          return { id, time, users, type, title, description };
        },
      });
      this.#pool.push(data);
    }
  }

  addActivitiesSourceFromFirestore(
    queryBuilder,
    {
      idKey = "id",
      timeKey = "__dateCreated",
      userKey = "users",
      titleKey = "title",
      titleFn = null,
      descriptionKey = "comments",
      descriptionFn = null,
      type = null,
      typeKey = "action",
    } = {}
  ) {
    if (this.#provided) {
      console.warn("Cannot add source. Activities already provided.");
      return;
    }
    const srcs = this.#sources.value;
    const src = {
      query: queryBuilder,
      idKey,
      timeKey,
      userKey,
      titleKey,
      titleFn,
      descriptionKey,
      descriptionFn,
      type,
      typeKey,
    };
    srcs.push(src);
    // this.#read();
    return this.#sources.value;
  }

  #provided = false;
  #compute() {
    // const arr = [];
    // for (let i = 0; i < this.#pool.length; i++) {
    //   arr.push(this.#pool[i]);
    // }
    // arr.sort((a, b) => {
    //   return a.getTime() - b.getTime();
    // });
    // this.#activities.splice(0, this.#activities.length, arr);
    // this.activities.push([1, 2, 3, 4, 5, 6]);
  }
  provide() {
    if (!this.#provided) {
      this.#provided = true;
      // watchEffect(() => console.log("watch"));

      this.#activities = computed(() => {
        const arr = [];

        for (let i = 0; i < this.#pool.length; i++) {
          const poolRaw = JSON.parse(JSON.stringify(this.#pool[i]));
          // console.log(poolRaw.length);
          arr.push(...poolRaw);
        }
        arr.sort((a, b) => {
          const dateA = new Date(a.time);
          const dateB = new Date(b.time);
          return dateB - dateA;
        });
        // this.#activities.splice(0, this.#activities.length, arr);
        return arr;
      });
      this.#read();
    }

    provide("hive-vue-activities", this.#activities);
  }

  resetActivitySources() {
    this.#provided = false;
    this.#sources.value = [];
    this.#pool.splice(0, this.#pool.length);
  }
  read() {
    this.#read();
  }
}

export function injectActivities() {
  return inject("hive-vue-activities", []);
}
