<template>
  <hi-form-hive-standard
    :initial-values="initialValue"
    :submit-fn="submit"
    @successful="$emit('successful')"
  >
    <hi-form-item label="Name *">
      <hi-input mandatory form-key="name" />
    </hi-form-item>
    <hi-form-item label="Type *">
      <hi-select
        mandatory
        multiple
        :items="[
          { text: 'Architect', value: 'Architect' },
          { text: 'Builder', value: 'Builder' },
          { text: 'Developer', value: 'Developer' },
          { text: 'Flooring Contractor', value: 'Flooring Contractor' },
          { text: 'Flooring installer', value: 'Flooring installer' },
          { text: 'Interior Designer', value: 'Interior Designer' },
          { text: 'Joiner', value: 'Joiner' },
          { text: 'Owner', value: 'Owner' },
          { text: 'Sub Contractor', value: 'Sub Contractor' },
          { text: 'Supplier', value: 'Supplier' },
          { text: 'Other', value: 'Other' },
        ]"
        form-key="type"
      />
    </hi-form-item>
    <hi-form-item label="Quality *">
      <hi-chips-selectable
        mandatory
        :items="[
          { text: 'A', value: 'A' },
          { text: 'B', value: 'B' },
          { text: 'C', value: 'C' },
        ]"
        form-key="quality"
      />
    </hi-form-item>
    <hi-form-item label="Size *">
      <hi-chips-selectable
        mandatory
        :items="[
          { text: '1st Tier', value: '1st Tier' },
          { text: '2nd Tier', value: '2nd Tier' },
          { text: '3rd Tier', value: '3rd Tier' },
        ]"
        form-key="size"
      />
    </hi-form-item>

    <hi-form-item label="Main Owner">
      <div class="flex flex-col gap-2">
        <user-details-span show-email :user-id="mainOwner" />
        <mfc-system-user-selector form-key="mainOwner" v-model="mainOwner" />
      </div>
    </hi-form-item>

    <hi-form-item label="Sub Owners">
      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <user-details-span
            v-for="(user, index) in subOwners"
            :key="user + index"
            show-email
            :user-id="user"
          />
        </div>
        <mfc-system-user-selector
          multiple
          :disabled-ids="[mainOwner]"
          form-key="subOwners"
          v-model="subOwners"
        />
      </div>
    </hi-form-item>
  </hi-form-hive-standard>
</template>

<script>
/* eslint-disable */
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { updateAccountById } from "@/hive-vue3/modules/accounts/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import { ref } from "vue";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { useSystemUsers } from "@/hive-vue3/firebase";
import moment from "moment";
import { requestActivityNotification } from "@/hive-vue3/api/request";
import { arrayUnique } from "@/hive-vue3/modules/utils/utils";
export default {
  name: "AccountsBasicInfoEditor",
  components: {
    MfcSystemUserSelector,
    UserDetailsSpan,
    HiChipsSelectable,
    HiSelect,
    HiFormHiveStandard,
    HiInput,
    HiFormItem,
  },
  props: {
    initialValue: Object,
    accountId: String,
  },
  setup(props) {
    const mainOwner = ref(undefined);
    const subOwners = ref(undefined);

    async function submit(formData) {
      if (
        formData.mainOwner !== props.initialValue.mainOwner ||
        JSON.stringify(formData.subOwners) !==
          JSON.stringify(props.initialValue.subOwners)
      ) {
        const systemUsers = useSystemUsers();
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.ASSIGN;
        historyObj.target = "account";
        historyObj.targetId = props.accountId;
        historyObj.comments = `Assign owners: ${
          formData.mainOwner ? systemUsers[formData.mainOwner].name : "N/A"
        } (Main), ${
          formData.subOwners && formData.subOwners.length
            ? formData.subOwners.map((v) => systemUsers[v].name).join(", ")
            : ""
        }`;

        await addNewHistory(historyObj);

        let initialSubOwners = props.initialValue.subOwners;
        let newSubOwners = formData.subOwners;
        if (!initialSubOwners) {
          initialSubOwners = [];
        }
        if (!newSubOwners) {
          newSubOwners = [];
        }
        const users = arrayUnique([
          props.initialValue.mainOwner,
          ...initialSubOwners,
          formData.mainOwner,
          ...newSubOwners,
        ]);

        const m = moment(new Date());
        const timeStr = m.format("DD MMM HH:mm");
        console.log(users)
        requestActivityNotification({
          users,
          smallTitle: "Owner Changes in Accounts",
          mainTitle: props.initialValue.name,
          timeStr,
          message: historyObj.comments,
          directLink: "https://my.artimber.au/accounts/" + props.accountId,
          breadcrumbs: [
            {
              href: "https://my.artimber.au/",
              text: "My Artimber",
            },
            {
              href: "https://my.artimber.au/" + "accounts/",
              text: "accounts",
            },
            {
              href: "https://my.artimber.au/accounts/" + props.accountId,
              text: props.initialValue.name,
            },
          ],
        }).then();
      }



      await updateAccountById(props.accountId, formData);
      return { successful: true };
    }
    return {
      submit,
      mainOwner,
      subOwners,
    };
  },
};
</script>

<style scoped></style>
