<template>
  <div class="flex">
    <div class="grow w-0">
      <div class="tp-subtitle flex items-center">
        <div class="ellipse-flex-grow-item font-bold">
          {{ account.name }} - {{ account.size || "N/A" }}-
          {{ account.quality || "N/A" }}
        </div>
      </div>
      <div v-if="account.type" class="tp-description-sm font-bold">
        {{ account.type.join(", ") }}
      </div>
      <div
        v-if="account.entity"
        class="tp-subtitle hi-marker-disabled truncate"
      >
        <contacts-entity :entity-id="account.entity" />
      </div>

      <div v-if="account.address" class="tp-description-sm flex space-x-1">
        A.<hi-address-display :model-value="account.address" />
      </div>
    </div>
    <div class="min-w-[120px]">
      <div class="tp-description-sm">
        Created At: <br />
        <hi-date-time-display :model-value="account.__dateCreated" friendly />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import HiAddressDisplay from "@/hive-vue3/components/misc/HiAddressDisplay";

export default {
  name: "AccountListSummary",
  components: { HiAddressDisplay, ContactsEntity, HiDateTimeDisplay },
  props: {
    accountId: String,
    accountData: Object,
  },
  setup(props) {
    const account = computed(() => {
      if (props.accountData) {
        return props.accountData;
      }
      return getAccountById(props.accountId);
    });

    return {
      account,
    };
  },
};
</script>

<style scoped></style>
