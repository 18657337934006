import { onMounted, onUnmounted, ref } from "vue";
import $el from "../dom/$el";
import { useResizeObserver } from "@vueuse/core";

const mutationConfig = { attributes: true, childList: true, subtree: true };
/**
 * this is identical to vueuse useElementBounding. Except this function detects not only window.scroll event but all element scroll on the page.
 * @param target
 * @returns {{top: Ref<UnwrapRef<number>>, left: Ref<UnwrapRef<number>>, bottom: Ref<UnwrapRef<number>>, width: Ref<UnwrapRef<number>>, x: Ref<UnwrapRef<number>>, y: Ref<UnwrapRef<number>>, update: function, right: Ref<UnwrapRef<number>>, height: Ref<UnwrapRef<number>>}}
 */
export default function (target) {
  const height = ref(0);
  const bottom = ref(0);
  const left = ref(0);
  const right = ref(0);
  const top = ref(0);
  const width = ref(0);
  const x = ref(0);
  const y = ref(0);

  function update() {
    // console.log("update bounding....");
    const el = $el(target);
    // console.log(target, el);

    if (!el) {
      height.value = 0;
      bottom.value = 0;
      left.value = 0;
      right.value = 0;
      top.value = 0;
      width.value = 0;
      x.value = 0;
      y.value = 0;
      return;
    }

    const rect = el.getBoundingClientRect();

    if (height.value !== rect.height) height.value = rect.height;
    if (bottom.value !== rect.bottom) bottom.value = rect.bottom;
    if (left.value !== rect.left) left.value = rect.left;
    if (right.value !== rect.right) right.value = rect.right;
    if (top.value !== rect.top) top.value = rect.top;
    if (width.value !== rect.width) width.value = rect.width;
    if (x.value !== rect.x) x.value = rect.x;
    if (y.value !== rect.y) y.value = rect.y;
    // console.log("bounding updated");
  }
  let timeout;
  function lazyUpdate() {
    // console.log("lazy");
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(update, 500);
  }

  const observer = new MutationObserver(lazyUpdate);

  onMounted(() => {
    update();
    document.addEventListener("scroll", lazyUpdate, true);
    window.addEventListener("resize", lazyUpdate);
    observer.observe(document.body, mutationConfig);
    // window.addEventListener("resize", () => console.log("resize"));
  });
  onUnmounted(() => {
    document.removeEventListener("scroll", lazyUpdate, true);
    window.removeEventListener("resize", lazyUpdate);
    observer.disconnect();
  });

  useResizeObserver(target, lazyUpdate);

  return {
    height,
    bottom,
    left,
    right,
    top,
    width,
    x,
    y,
    update,
  };
}
