<template>
  <div style="min-width: 420px">
    <div class="p-4 text-right">
      <dialog-invite-system-user />
    </div>
    <div class="hi-ui-title">Active Users</div>
    <hi-list :items="userList" selectable v-model="usersState.selectedUser">
      <template v-slot="{ item: user }">
        <div class="flex items-center gap-4">
          <hi-avatar :size="45" :photo="user.photoUrl" />
          <div class="grow">
            <div class="tp-title">
              {{ user.name || user.displayName }}
              {{ user.name && user.surname ? user.surname : "" }}
            </div>
            <div class="tp-subtitle">{{ user.email }}</div>
            <div v-if="user.roles">
              <span
                class="tp-description-sm hi-separator-mid-dot"
                v-for="role in sortAlphabetically(user.roles)"
                :key="user.id + role"
                >{{ systemRoles[role] }}</span
              >
            </div>
          </div>
          <div>
            <div class="tp-description-sm text-right">
              Last login:
              <hi-date-time-display v-model="user.lastLoginAt" friendly />
            </div>
            <div class="tp-description-sm text-right">
              Active since:
              <hi-date-time-display v-model="user.activeAt" friendly />
            </div>
          </div>
        </div>
      </template>
    </hi-list>
    <!--    //=========================-->
    <div class="hi-ui-title pt-4" v-if="Object.keys(invitations).length">
      Pending Users
    </div>
    <hi-list :items="invitations" dense>
      <template v-slot="{ item: user, index }">
        <div class="tp-subtitle flex items-baseline gap-4">
          <div>
            <div class="font-bold">{{ user.name }} {{ user.surname }}</div>
            <div class="tp-description-sm">{{ user.email }}</div>
            <div>
              <span
                class="tp-description-sm hi-separator-mid-dot"
                v-for="role in sortAlphabetically(user.roles)"
                :key="user.id + role"
                >{{ systemRoles[role] }}</span
              >
            </div>
          </div>

          <div class="grow text-right">
            Invited At:
            <hi-date-time-display
              class="block tp-description-sm"
              v-model="user.invitedAt"
              friendly
            />
            <div>
              <hi-button
                class="uppercase"
                small
                text
                @click="deleteInvitation(index)"
                >Delete</hi-button
              >
              <hi-button
                class="ml-4 uppercase"
                small
                text
                @click="resendInvitation(user.name, user.email)"
                >Resend Invitation</hi-button
              >
            </div>
          </div>
        </div>
      </template>
    </hi-list>
  </div>
</template>

<script>
// import useFirestoreCollection from "@/hive-vue3/firebase/useFirestoreCollection";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
// import { reactive } from "vue";
import {
  firestoreDoc,
  useSystemRoles,
  useSystemUsers,
} from "@/hive-vue3/firebase";
import { deleteField, updateDoc } from "firebase/firestore";
import HiList from "@/hive-vue3/components/HiList";
import HiAvatar from "@/hive-vue3/components/HiAvatar";
import { sortAlphabetically } from "@/hive-vue3/utils/arrayUtils";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import DialogInviteSystemUser from "@/hive-vue3/modules/users/DialogInviteSystemUser";
import HiButton from "@/hive-vue3/components/HiButton";
import hive from "@/hive-vue3/components/hive";
import { usersState } from "@/hive-vue3/modules/users/state";
import { requestSendInvitationEmail } from "@/hive-vue3/api/request";
// import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";

export default {
  name: "SystemUserList",
  components: {
    HiButton,
    DialogInviteSystemUser,
    HiDateTimeDisplay,
    HiAvatar,
    HiList,
  },
  setup() {
    // const state = reactive({
    //   showDialog: false,
    //   userId: "",
    // });
    // const userList = useFirestoreCollection("/system/users/people");
    // const permissions = useFirestoreDoc("system", "userPermissions");
    // const userList = useFirestoreDocQueryable("/system/users/people");
    const invitations = useFirestoreDoc("system", "userInvitations");
    const userList = useSystemUsers();

    // const getUser = async (id) => {
    //   state.showDialog = true;
    //   state.userId = id;
    //   console.log(id);
    // };

    const deleteInvitation = async (index) => {
      const invite = invitations[index];
      const yes = await hive.confirm(
        `Are you sure you want to delete the invitation to <br/>${invite.name} <${invite.email}>?`
      );
      if (!yes) return;
      // console.log(index);
      const doc = firestoreDoc("system", "userInvitations");
      await updateDoc(doc, {
        [index]: deleteField(),
      });
    };

    // function formatRoles(roles) {
    //   const arr = [];
    //   for (let role in roles) {
    //     if (roles[role]) {
    //       arr.push(role);
    //     }
    //   }
    //   return sortAlphabetically(arr);
    // }

    const systemRoles = useSystemRoles();

    async function resendInvitation(name, email) {
      // 发送邀请邮件
      await requestSendInvitationEmail(email, name);
    }

    return {
      userList,
      invitations,
      // getUser,
      // state,
      deleteInvitation,
      // formatRoles,
      systemRoles,
      usersState,
      sortAlphabetically,
      resendInvitation,
    };
  },
};
</script>

<style lang="scss" scoped></style>
