<template>
  <div class="relative max-w-full">
    <hi-loading-mask v-model="state.submitting" />
    <slot />
    <div class="flex items-center gap-4 mt-5">
      <hi-button v-if="cancelButton" @click="$emit('cancel')">
        Cancel
      </hi-button>
      <slot name="tools-left" />
      <div class="grow"></div>
      <slot name="tools-right" />
      <hi-button
        v-if="submitButton"
        @click="submit"
        :disabled="!submitEnable"
        primary
        >{{ submitText }}
      </hi-button>
    </div>
  </div>
</template>

<script>
import HiLoadingMask from "@/hive-vue3/components/overlays/HiLoadingMask";
import HiButton from "@/hive-vue3/components/HiButton";
import { reactive } from "vue";
export default {
  name: "HiDivWithSubmitStatusContainer",
  components: { HiButton, HiLoadingMask },
  props: {
    submitButton: Boolean,
    cancelButton: Boolean,
    submitEnable: Boolean,
    submitText: {
      type: String,
      default: "Submit",
    },
    submitFn: {
      type: Function,
      required: true,
    },
  },
  emits: ["successful", "error", "cancel"],
  setup(props, { emit }) {
    const state = reactive({
      submitting: false,
      error: false,
      errorMessage: "",
      successful: false,
    });
    async function submit() {
      // console.log("onSubmit");
      if (!props.submitFn) {
        console.error(
          "HiDivWithSubmitStatusContainer needs a submitFn as a prop!"
        );
        return;
      }
      state.submitting = true;
      const result = await props.submitFn();
      state.submitting = false;
      if (result.successful) {
        state.successful = true;
        state.error = false;
        state.errorMessage = "";
        emit("successful");
      } else {
        state.successful = false;
        state.error = true;
        state.errorMessage = result.errorMessage;
        emit("error", result.errorMessage);
      }
    }
    return {
      state,
      submit,
    };
  },
};
</script>

<style scoped></style>
