import { orderBy, limit, limitToLast, where, query } from "firebase/firestore";
import { firestoreCollection } from "@/hive-vue3/firebase/utils";

export default function fireStoreQueryBuilder(collectionPath) {
  const q = [];
  let ordered = false,
    limited = false;
  function _orderBy(key, directionStr) {
    q.push(orderBy(key, directionStr));
    ordered = true;
  }
  function _limit(n) {
    q.push(limit(n));
    limited = true;
  }
  function _limitToLast(n) {
    q.push(limitToLast(n));
    limited = true;
  }
  function _where(filed, opStr, value) {
    q.push(where(filed, opStr, value));
  }
  function _set(query) {
    q.length = 0;
    q.push(...query);
  }
  return {
    _set,
    clone() {
      const builder = fireStoreQueryBuilder(collectionPath);
      builder._set(q);
      return builder;
    },
    isOrdered() {
      return ordered;
    },
    isLimited() {
      return limited;
    },
    orderBy: _orderBy,
    limit: _limit,
    limitToLast: _limitToLast,
    where: _where,
    build() {
      return query(firestoreCollection(collectionPath), ...q);
    },
  };
}
