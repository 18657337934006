import { watch, ref } from "vue";

/**
 * Two way sync props and ref.
 * this is to solve the problem that when there's no v-model attached to a component, the emit("update") cannot set the props.modelValue issue.
 * modelRef turns model value into a ref, which can be used internally as a ref and updates modelValue as well.
 * setup(props,{emit}){
 *   modelREf(props,emit)
 * }
 *
 *
 * @param props {Object} component props
 * @param emit
 * @param propName {String} default "modelValue"
 * @param onPropUpdate {Function}
 * @returns {ref<*>}
 */
export default function (
  props,
  emit,
  { propName = "modelValue", onPropUpdate = null } = {}
) {
  let propValue = props[propName];
  const model = ref(propValue);
  // console.log(propName, emit);
  watch(model, (v) => {
    // console.log("set model", propName, v, propValue);
    if (propValue !== v) {
      emit(`update:${propName}`, v);
      // console.log(`update:${propName}`, v);
    }
  });
  watch(
    () => props[propName],
    (v) => {
      // console.log("debug model...............");
      // console.log(v);
      // console.log(model.value);
      propValue = v;
      if (v !== model.value) {
        model.value = v;
        // console.log(v);
        if (onPropUpdate) {
          onPropUpdate(v);
        }
      }
    }
  );
  return model;
}
