<template>
  <span>
    <hi-dialog-button v-bind="$props" ref="dialog" :width="width">
      <hi-form-hive-standard
        @cancel="close"
        @successful="close"
        :submit-fn="submitFn"
        cancel-button
      >
        <slot />
      </hi-form-hive-standard>
    </hi-dialog-button>
  </span>
</template>

<script>
import HiDialogButton from "@/hive-vue3/components/dialogs/HiDialogButton";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { templateRef } from "@vueuse/core/index";
export default {
  name: "HiFormDialogHiveStandard",
  components: { HiFormHiveStandard, HiDialogButton },
  props: {
    modelValue: Boolean,
    submitFn: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      default: "Add",
    },
    outlineButton: Boolean,
    textButton: Boolean,
    primaryButton: Boolean,
    debug: Boolean,
    title: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: "50vw",
    },
  },
  setup() {
    const dialog = templateRef("dialog");

    function close() {
      // console.log("---- cancel");
      // console.log(data);
      dialog.value.close();
    }

    return {
      close,
    };
  },
};
</script>

<style scoped></style>
