<template>
  <div class="--hi-button-group inline-flex"><slot /></div>
</template>

<script>
export default {
  name: "HiButtonGroup",
};
</script>

<style>
.--hi-button-group > *:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.--hi-button-group > *:first-child:not(:last-child) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.--hi-button-group > *:not(:last-child):not(:first-child) {
  border-right: none;
  border-radius: 0;
}
</style>
