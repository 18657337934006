<template>
  <module-titled-block v-show="reg" title="Registration">
    <module-info-block :data="reg" class="view-block">
      <module-labeled-info label="Type" value-key="entityTypeCode">
        {{ reg.entityTypeCode }} ({{ reg.entityTypeDescription }})
      </module-labeled-info>
      <module-labeled-info label="A.B.N" value-key="abn">
        <hi-abn :model-value="reg.abn" />
      </module-labeled-info>
      <module-labeled-info value-key="asicNumber" label="ASIC" />
      <module-labeled-info value-key="effectiveFrom" />
      <module-labeled-info value-key="gstEffectiveFrom" label="GST Since" />
      <module-labeled-info value-key="postcode" label="Locale">
        {{ reg.postcode }} {{ reg.stateCode }}
      </module-labeled-info>
    </module-info-block>
  </module-titled-block>
  <module-titled-block v-show="!reg" title="Registration (null)">
    <template v-slot:tools>
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link another entity"
        />
        <contacts-entity-add-abn-dialog />
      </hi-dialog-trigger>
    </template>
  </module-titled-block>
</template>

<script>
import { computed } from "vue";
import { computedEntityByIdOrData } from "@/hive-vue3/modules/contacts/data";
import ModuleTitledBlock from "@/hive-vue3/modules/_common/ModuleTitledBlock";
import ModuleLabeledInfo from "@/hive-vue3/modules/_common/ModuleLabeledInfo";
import ModuleInfoBlock from "@/hive-vue3/modules/_common/ModuleInfoBlock";
import HiAbn from "@/hive-vue3/components/misc/HiAbn";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import ContactsEntityAddAbnDialog from "@/hive-vue3/modules/contacts/ContactsEntityAddAbnDialog";

export default {
  name: "ContactsEntityRegistrationInfo",
  components: {
    ContactsEntityAddAbnDialog,
    HiIcon,
    HiDialogTrigger,
    HiAbn,
    ModuleInfoBlock,
    ModuleLabeledInfo,
    ModuleTitledBlock,
  },
  props: {
    entityId: String,
    entityData: Object,
  },
  setup(props) {
    return {
      commonIcons,
      reg: computed(() => {
        const entity = computedEntityByIdOrData(props);
        if (entity.value) {
          return entity.value.registration;
        } else {
          return null;
        }
      }),
    };
  },
};
</script>

<style scoped></style>
