import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";

import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import { computed } from "vue";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { capitalize } from "@/hive-vue3/utils/stringUtils";
import { firestoreDoc } from "@/hive-vue3/firebase";
import {
  arrayUnion,
  setDoc,
  arrayRemove,
  deleteField,
} from "firebase/firestore";

const cache = {
  entities: null,
  people: null,
  positions: null,
};
const store = {
  entities: null,
  people: null,
};
function initPositions() {
  if (!cache.positions) {
    cache.positions = useFirestoreDoc(dbPaths.positionTagDoc, null, {
      persistData: true,
    });
  }
}
export function useContactsEntityPosition(id) {
  initPositions();
  return computed(() => cache.positions[id]);
}
export function useContactsEntityPositionWithReactiveId(
  props,
  idKey = "positionId"
) {
  initPositions();
  return computed(() => {
    const id = props[idKey];
    return cache.positions[id];
  });
}
export function getContactsEntityPosition(positionId) {
  initPositions();
  return cache.positions[positionId];
}
export function useContactsPositions() {
  initPositions();
  return cache.positions;
}
function initEntities() {
  if (!cache.entities) {
    const source = useFirestoreCollectionAsRefGlobally(dbPaths.entities);
    const listStore = createDataListStore(source);
    cache.entities = source;
    store.entities = listStore;
  }
}
export const provideContactsEntityListStore = () => {
  initEntities();
  return provideDataListStore(store.entities);
};
export const useContactEntities = () => {
  initEntities();
  return cache.entities;
};
export const useContactsEntity = (entityId) => {
  return computed(() => {
    return getContactsEntity(entityId);
  });
};
export const getContactsEntity = (entityId) => {
  initEntities();

  const entities = cache.entities;
  if (entities.value && entities.value.length) {
    for (let i = 0; i < entities.value.length; i++) {
      const entity = entities.value[i];
      if (entity.id === entityId) return entity;
    }
  }
  return undefined;
};

function initPeople() {
  if (!cache.people) {
    // console.log("initPeople");
    const source = useFirestoreCollectionAsRefGlobally(dbPaths.people);
    const listStore = createDataListStore(source);
    cache.people = source;
    store.people = listStore;
  }
}

export function contactsPeopleDataListSearchFn(search, people) {
  console.log(search, people);
  const name = formatContactsPeopleName(people).toLowerCase();
  const mobile = people.mobile ? people.mobile.toString() : "";
  const email = people.email;
  return (
    name.indexOf(search) >= 0 ||
    (mobile && mobile.indexOf(search) >= 0) ||
    (email && email.indexOf(search) >= 0)
  );
}
export const provideContactsPeopleListStore = () => {
  initPeople();
  return provideDataListStore(store.people);
};
export const useContactPeopleList = () => {
  initPeople();
  return cache.people;
};
export const useContactsPeople = (peopleId) => {
  return computed(() => {
    return getContactsPeople(peopleId);
  });
};
export const getContactsPeople = (peopleId) => {
  initPeople();

  const people = cache.people;
  // console.log(cache.people);
  if (people.value && people.value.length) {
    for (let i = 0; i < people.value.length; i++) {
      const p = people.value[i];
      if (p.id === peopleId) return p;
    }
  }
  return undefined;
};
export const useContactsPeopleByEmail = (email) => {
  return computed(() => {
    return getContactsPeopleByEmail(email);
  });
};

export const getContactsPeopleByEmail = (email) => {
  initPeople();

  const people = cache.people;
  if (people.value && people.value.length) {
    for (let i = 0; i < people.value.length; i++) {
      const p = people.value[i];
      if (p.email === email) return p;
    }
  }
  return undefined;
};

export const getContactsPeopleEmailById = (peopleId) => {
  initPeople();

  const people = cache.people;
  if (people.value && people.value.length) {
    for (let i = 0; i < people.value.length; i++) {
      const p = people.value[i];
      if (p.id === peopleId) return p.email;
    }
  }
  return undefined;
};

export const useContactsPeopleByMobile = (mobile) => {
  return computed(() => {
    return getContactsPeopleByMobile(mobile);
  });
};

export const getContactsPeopleByMobile = (mobile) => {
  initPeople();

  const people = cache.people;
  if (people.value && people.value.length) {
    for (let i = 0; i < people.value.length; i++) {
      const p = people.value[i];
      if (p.mobile === mobile) return p;
    }
  }
  return undefined;
};

export const getContactsPeopleByGroup = (groups) => {
  initPeople();
  const returnArr = [];
  const people = cache.people;
  if (people.value && people.value.length) {
    if (groups.indexOf("all") !== -1) {
      return people.value;
    } else {
      for (let i = 0; i < people.value.length; i++) {
        const p = people.value[i];
        for (const j of groups) {
          if (p.groups && p.groups.indexOf(j) !== -1) {
            returnArr.push(p);
          }
        }
      }
    }
  }
  return returnArr;
};

export function getContactsPeopleInEntity(entityId) {
  initPeople();
  if (!entityId) return [];
  const people = cache.people;
  if (!people.value) return [];
  return people.value.filter(
    (p) => p.entities && p.entities.indexOf(entityId) >= 0
  );
}

export function useContactsPeopleByEntityIdInProps(props, idKey = "entityId") {
  return computed(() => {
    const val = props[idKey];
    return getContactsPeopleInEntity(val);
  });
}

export function formatContactsPeopleName(peopleData, { title = true } = {}) {
  if (!peopleData || !peopleData.name) return "";
  let str = title && peopleData.title ? peopleData.title + ". " : "";
  str += capitalize(peopleData.name);
  if (peopleData.midName) str += " " + capitalize(peopleData.midName);
  // console.log(peopleData.surname);
  if (peopleData.surname) str += " " + capitalize(peopleData.surname);
  return str;
}

export const getContactsEntityNameById = (entityId) => {
  initEntities();
  const entities = cache.entities;
  if (entities.value && entities.value.length) {
    for (let i = 0; i < entities.value.length; i++) {
      const p = entities.value[i];
      if (p.id === entityId) return p.name || p.tradingName;
    }
  }
  return undefined;
};

export const getContactsEntityTradingNameById = (entityId) => {
  initEntities();
  const entities = cache.entities;
  if (entities.value && entities.value.length) {
    for (let i = 0; i < entities.value.length; i++) {
      const p = entities.value[i];
      if (p.id === entityId) return p.tradingName || p.name;
    }
  }
  return undefined;
};

export const getContactsFullNameById = (peopleId) => {
  initPeople();

  const people = cache.people;
  if (people.value && people.value.length) {
    for (let i = 0; i < people.value.length; i++) {
      const p = people.value[i];
      if (p.id === peopleId) return p.name + " " + p.surname;
    }
  }
  return undefined;
};

export function computedPeopleByIdOrData(props) {
  return computed(() => {
    if (!props.peopleId && !props.peopleData) {
      // console.warn(
      //   "Both peopleId and peopleData is missing from props. Empty value returned."
      // );
      return {};
    }
    if (props.peopleData) return props.peopleData;
    return getContactsPeople(props.peopleId);
  });
}

export function computedEntityByIdOrData(props) {
  return computed(() => {
    if (!props.entityId && !props.entityData) {
      // console.warn(
      //   "Both peopleId and peopleData is missing from props. Empty value returned."
      // );
      return {};
    }
    if (props.entityData) return props.entityData;
    return getContactsEntity(props.entityId);
  });
}

export async function linkPeopleToEntity(peopleId, entityId, position) {
  const doc = firestoreDoc(dbPaths.people, peopleId);
  const vals = {
    entities: arrayUnion(entityId),
  };
  if (position) {
    vals.entityPositions = { [entityId]: position };
  }
  await setDoc(doc, vals, { merge: true });
}

export async function removePeopleFromEntity(peopleId, entityId) {
  const doc = firestoreDoc(dbPaths.people, peopleId);
  const vals = {
    entities: arrayRemove(entityId),
    ["entityPositions." + entityId]: deleteField(),
  };
  await setDoc(doc, vals, { merge: true });
}

export async function jumpToPeopleByPeopleId(router, peopleId) {
  router.push("/contacts/people/" + peopleId);
}

export async function jumpToEntityByEntityId(router, entityId) {
  router.push("/contacts/entity/" + entityId);
}
