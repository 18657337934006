import {
  firestoreDoc,
  newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";

const cache = {
  invoices: null,
};
function initInvoices() {
  if (!cache.invoices) {
    cache.invoices = useFirestoreCollectionAsRefGlobally("invoices");
  }
}

export function getInvoices() {
  initInvoices();
  if (cache.invoices && cache.invoices.value) {
    return cache.invoices.value;
  }
  return [];
}
export function getInvoiceById(invoiceId) {
  initInvoices();
  if (cache.invoices && cache.invoices.value) {
    for (const i of cache.invoices.value) {
      if (i.id === invoiceId) {
        return i;
      }
    }
  }
  return {};
}

export async function addNewInvoice(invoiceData) {
  const res = await newFirestoreDoc("invoices", invoiceData);
  return res.id;
}
export async function updateInvoiceById(invoiceId, invoiceData) {
  return await updateFirestoreDoc(
    firestoreDoc("invoices", invoiceId),
    invoiceData
  );
}

export async function jumpToInvoiceById(router, invoiceId) {
  return await router.push("/invoice/" + invoiceId);
}
