<template>
  <hi-form-hive-standard
    :submit-fn="submit"
    cancel-button
    @successful="onSuccessful"
    @cancel="$emit('cancel')"
  >
    <hi-form-item label="Name *">
      <hi-input mandatory form-key="name" />
    </hi-form-item>
    <hi-form-item label="Type *">
      <hi-select
        mandatory
        multiple
        :items="[
          { text: 'Architect', value: 'Architect' },
          { text: 'Builder', value: 'Builder' },
          { text: 'Developer', value: 'Developer' },
          { text: 'Flooring Contractor', value: 'Flooring Contractor' },
          { text: 'Flooring installer', value: 'Flooring installer' },
          { text: 'Interior Designer', value: 'Interior Designer' },
          { text: 'Joiner', value: 'Joiner' },
          { text: 'Owner', value: 'Owner' },
          { text: 'Sub Contractor', value: 'Sub Contractor' },
          { text: 'Supplier', value: 'Supplier' },
          { text: 'Other', value: 'Other' },
        ]"
        form-key="type"
      />
    </hi-form-item>
    <hi-form-item label="Quality *">
      <hi-chips-selectable
        mandatory
        :items="[
          { text: 'A', value: 'A' },
          { text: 'B', value: 'B' },
          { text: 'C', value: 'C' },
        ]"
        form-key="quality"
      />
    </hi-form-item>
    <hi-form-item label="Size *">
      <hi-chips-selectable
        mandatory
        :items="[
          { text: '1st Tier', value: '1st Tier' },
          { text: '2nd Tier', value: '2nd Tier' },
          { text: '3rd Tier', value: '3rd Tier' },
        ]"
        form-key="size"
      />
    </hi-form-item>
    <hi-form-item label="Email">
      <hi-input email form-key="email" />
    </hi-form-item>
    <hi-form-item label="Address">
      <hi-input-address form-key="address" />
    </hi-form-item>

    <hi-form-item label="Main Owner">
      <div class="flex flex-col gap-2">
        <user-details-span show-email :user-id="mainOwner" />
        <mfc-system-user-selector form-key="mainOwner" v-model="mainOwner" />
      </div>
    </hi-form-item>

    <hi-form-item label="Sub Owners">
      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <user-details-span
            v-for="(user, index) in subOwners"
            :key="user + index"
            show-email
            :user-id="user"
          />
        </div>
        <mfc-system-user-selector
          multiple
          form-key="subOwners"
          v-model="subOwners"
        />
      </div>
    </hi-form-item>

    <hi-form-item label="People">
      <mfc-input-contacts-people form-key="people" />
    </hi-form-item>
    <hi-form-item label="Entity">
      <mfc-input-contacts-entity form-key="entity" />
    </hi-form-item>
  </hi-form-hive-standard>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { ref } from "vue";
import MfcInputContactsEntity from "@/hive-vue3/modules/contacts/controls/MfcInputContactsEntity";
import { createNewAccount } from "@/hive-vue3/modules/accounts/data";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { pipeIdGenerator } from "@/hive-vue3/modules/utils/utils";
export default {
  name: "AddNewAccountForm",
  components: {
    HiChipsSelectable,
    MfcInputContactsPeople,
    MfcInputContactsEntity,
    UserDetailsSpan,
    MfcSystemUserSelector,
    HiInputAddress,
    HiSelect,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
  },
  setup(props, { emit }) {
    const mainOwner = ref(undefined);
    const subOwners = ref();
    const docId = ref(undefined);

    async function submit(formData) {
      try {
        if (formData.people) {
          formData.people = [formData.people];
        }
        formData.pipeId = pipeIdGenerator();
        formData.status = "active";
        const res = await createNewAccount(formData);
        docId.value = res.id;
        return { successful: true };
      } catch (e) {
        return { successful: false, errorMsg: e };
      }
    }

    function onSuccessful() {
      emit("addSuccessful", docId.value);
    }

    return { submit, mainOwner, subOwners, docId, onSuccessful };
  },
};
</script>

<style scoped></style>
