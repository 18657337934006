import { getSnapshotData } from "./utils";
import { getCurrentScope, onScopeDispose, reactive } from "vue";
import { onSnapshot } from "firebase/firestore";

function isDef(v) {
  return v !== undefined && v !== null;
}

/**
 *
 * @param queryBuilder
 * @param options
 * @returns {UnwrapNestedRefs<*[]>}
 */
export default function (
  queryBuilder,
  options = { persistData: false, mapFn: null }
) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;
  const data = reactive([]);

  function map(snap) {
    const data = getSnapshotData(snap);
    if (options.mapFn) {
      return options.mapFn(data);
    } else {
      return data;
    }
  }
  let close;
  function listen() {
    stopListen();
    const q = queryBuilder.build();
    // console.log(q);
    close = onSnapshot(
      q,
      (snapshot) => {
        // console.log(snapshot.docs);
        const docs = snapshot.docs.map(map).filter(isDef);
        data.splice(0);
        data.push(...docs);
        // console.log("snap", data);
      },
      errorHandler
    );
  }
  function stopListen() {
    if (close) close();
  }
  listen();
  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      stopListen();
    });
  }
  return data;
}
