<template>
  <div class="flex">
    <!--    <div>{{ peopleId }}</div>-->
    <div class="grow" v-if="people">
      <div class="tp-subtitle flex items-center">
        <div class="ellipse-flex-grow-item font-bold">
          {{ formatContactsPeopleName(people, { title: false }) }}
          <span class="tp-description font-normal">
            <span v-if="additionalInfo">{{ additionalInfo }}</span>
            <!--          @slot default slot to display additional info after name-->
            <slot />
          </span>
        </div>
        <hi-tags
          small
          v-if="people.tags"
          :model-value="people.tags"
          :tag-doc-path="dbPaths.peopleTagDoc"
        >
        </hi-tags>
      </div>
      <div
        class="tp-subtitle hi-marker-disabled"
        v-if="!hideEntity && people.entities && people.entities.length"
      >
        <contacts-entity :entity-id="entity">
          <span v-if="people.entityPositions && people.entityPositions[entity]">
            - {{ decodeDocId(people.entityPositions[entity]) }}
            <!--            <contacts-entity-position-->
            <!--              :position-id="people.entityPositions[entity]"-->
            <!--            />-->
          </span>
        </contacts-entity>
      </div>
      <div class="tp-description-sm flex space-x-1">
        <span v-if="people.mobile">M.{{ people.mobile }}</span>
        <span v-if="people.email">E.{{ people.email }}</span>
      </div>
    </div>
    <!--    ======== popup ===================-->
    <!--    <contacts-people-overview-popup-->
    <!--      :people-id="people.id"-->
    <!--      v-if="popup && people && people.id"-->
    <!--      v-model="open"-->
    <!--    >-->
    <!--      &lt;!&ndash;          slot: to add contents & actions button at the bottom of the popup&ndash;&gt;-->
    <!--      <slot name="popup-footer" />-->
    <!--    </contacts-people-overview-popup>-->
  </div>
</template>

<script>
import HiTags from "@/hive-vue3/components/HiTags";
import dbPaths from "./dbPaths";
import { computed, ref } from "vue";
import {
  formatContactsPeopleName,
  getContactsPeople,
} from "@/hive-vue3/modules/contacts/data";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
// import ContactsPeopleOverviewPopup from "@/hive-vue3/modules/contacts/ss/ContactsPeopleWithOverviewPopup";
// import ContactsEntityPosition from "@/hive-vue3/modules/contacts/ContactsEntityPosition";
import { decodeDocId } from "@/hive-vue3/firebase";
export default {
  name: "ContactsPeopleSummary",
  components: {
    // ContactsEntityPosition,
    // ContactsPeopleOverviewPopup,
    ContactsEntity,
    HiTags,
  },
  props: {
    peopleId: String,
    peopleData: Object,
    hideEntity: Boolean,
    // popup: Boolean,
    additionalInfo: String,
    entityId: String,
  },
  setup(props) {
    const open = ref(false);

    const people = computed(() => {
      // console.log(props.peopleId, props.peopleData);
      if (props.peopleData) return props.peopleData;
      else if (props.peopleId) {
        // console.log(getContactsPeople(props.peopleId));
        return getContactsPeople(props.peopleId);
      }
      return null;
    });
    const entity = computed(() => {
      if (props.entityId) {
        return props.entityId;
      }
      if (people.value && people.value.entities) {
        return people.value.entities[0];
      }
      return undefined;
    });

    return {
      decodeDocId,
      dbPaths,
      people,
      open,
      formatContactsPeopleName,
      entity,
    };
  },
};
</script>
