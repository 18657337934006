<template>
  <div class="flex-center w-full h-full flex-col p-5">
    <div class="text-2xl bold mb-3 opacity-50">¯\_(ツ)_/¯</div>
    <div class="opacity-50"><slot>Nothing found...</slot></div>
  </div>
</template>

<script>
export default {
  name: "HiViewNoResult",
};
</script>

<style scoped></style>
