<template>
  <products-view-full-kit />
</template>

<script>
import ProductsViewFullKit from "@/hive-vue3/modules/products/productsViewFullKit";
export default {
  name: "ProductsView",
  components: { ProductsViewFullKit },
};
</script>

<style scoped></style>
