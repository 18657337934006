<template>
  <div>
    <hi-fire-doc
      :collection-path="dbPaths.people"
      :doc-id="peopleId"
      v-if="peopleId"
      class="flex flex-col gap-2 justify-stretch h-full"
    >
      <template v-slot="{ docData }">
        <!--          =================== title ===========================-->
        <hi-card>
          <module-block-editable class="view-block-spacious" editable>
            <!--              <div class="tp-big-fancy-display pb-3">-->
            <!--                {{ docData.title }}. {{ docData.name }}-->
            <!--              </div>-->
            <div class="flex gap-4">
              <contacts-people
                class="tp-big-fancy-display pb-3"
                :people-data="docData"
                title
                gender
              />
              <hi-icon
                class="clickable-icon"
                :path="mdiOpenInNew"
                title="Link peopleId"
                @click="jump(peopleId)"
              />
            </div>
            <hi-fire-doc-tagger
              label="Tags"
              collection-path="contacts"
              doc-path="peopleTags"
              multiple
              value-key="tags"
              can-edit
            />

            <div class="tp-description-sm">
              Added by:
              <user-details-span :user-id="docData.__createdBy" no-bold /> at
              <hi-date-time-display :model-value="docData.__dateCreated" />
            </div>
            <template v-slot:editor="{ closeDialog }">
              <contacts-people-basic-info-editor
                @successful="closeDialog"
                :original-data="docData"
              />
            </template>
          </module-block-editable>
        </hi-card>
        <!--          ============================ contact details ============================-->
        <hi-card>
          <contacts-people-contact-info
            class="view-block"
            :people-data="docData"
            editable
          />
        </hi-card>
        <!--          ============================== entities =============================-->
        <hi-card>
          <contacts-entities-by-people-view :people-data="docData" editable />
        </hi-card>
        <!--        <div v-else class="view-block tp-description text-center">-->
        <!--          This Contact doesn't belong to any entity.-->
        <!--          <add-entity-to-people-dialog :people-id="peopleId"-->
        <!--            >Click to add entity.</add-entity-to-people-dialog-->
        <!--          >-->
        <!--        </div>-->
        <hi-card class="grow"> </hi-card>
      </template>
    </hi-fire-doc>
  </div>
</template>

<script>
import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import HiFireDocTagger from "@/hive-vue3/components/fireDoc/HiFireDocTagger";
import HiCard from "@/hive-vue3/components/HiCard";
// import AddEntityToPeopleDialog from "@/hive-vue3/modules/contacts/parts/LinkEntityToPeopleDialog";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import { mdiFaceMan, mdiFaceWoman } from "@mdi/js";
import ContactsPeopleContactInfo from "@/hive-vue3/modules/contacts/ContactsPeopleContactInfo";
import ContactsPeopleBasicInfoEditor from "@/hive-vue3/modules/contacts/ContactsPeopleBasicInfoEditor";
import ContactsEntitiesByPeopleView from "@/hive-vue3/modules/contacts/ContactsEntitiesByPeopleView";
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import { mdiOpenInNew } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { useRouter } from "vue-router";
import { jumpToPeopleByPeopleId } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "ContactsPeopleDetails",
  components: {
    HiIcon,
    HiDateTimeDisplay,
    UserDetailsSpan,
    ModuleBlockEditable,
    ContactsEntitiesByPeopleView,
    ContactsPeopleBasicInfoEditor,
    ContactsPeopleContactInfo,
    ContactsPeople,
    // AddEntityToPeopleDialog,
    HiCard,
    HiFireDocTagger,
    HiFireDoc,
  },
  props: {
    peopleId: String,
  },
  setup() {
    const icons = {
      male: mdiFaceMan,
      female: mdiFaceWoman,
    };

    const router = useRouter();

    function jump(peopleId) {
      jumpToPeopleByPeopleId(router, peopleId);
    }

    return {
      dbPaths,
      icons,
      mdiOpenInNew,
      jump,
    };
  },
};
</script>

<style scoped>
@import "../moduleStyles.css";
</style>
