<template>
  <div>
    <!--    disable tabindex when focused to allow shift tab back to prev item-->
    <span
      class="relative"
      :style="{ 'pointer-events': disabled ? 'none' : 'auto' }"
      @mousedown="clickInput"
      ref="input"
      :tabindex="tabbable ? (disabled || hasFocus ? -1 : 0) : -1"
      @focusin="onFocus"
      @focusout="onBlur"
    >
      <slot />
    </span>
    <teleport to="body">
      <transition-fade-down-css>
        <div
          class="absolute z-top"
          style="
            padding-top: var(--dropdown-gap);
            padding-bottom: var(--dropdown-gap);
            transition: all 0.3s ease-in;
          "
          ref="popup"
          v-if="modelValue"
          :style="popStyle"
          @mousedown="onDropdownClick"
        >
          <div class="hi-dropdown-container overflow-auto">
            <slot name="dropdown" />
          </div>
        </div>
      </transition-fade-down-css>
    </teleport>
  </div>
</template>
<script>
import useBoundingClientRect from "@/hive-vue3/utils/sensors/useBoundingClientRect";
import { reactive, ref, watchEffect } from "vue";
import { watchModel } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { templateRef } from "@vueuse/core";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
// import clickOutside from "@/hive-vue3/utils/sensors/clickOutside";
import TransitionFadeDownCss from "@/hive-vue3/transitions/TransitionFadeDownCss";
import shortcuts from "@/hive-vue3/utils/keyboard/shortcuts";
// import shortcuts from "@/hive-vue3/utils/keyboard/shortcuts";
/**
 * 由于 html 没有办法 关闭 child element 的 tabindex，该组件需要把所有放在 slot 里面的 tabbable element 的 tabindex 设置为 ‘-1’，如不关闭 tabbable element，tab key 将需要大于一次按键才能跳出该组件。
 */
export default {
  name: "HiDropdownContainer",
  components: { TransitionFadeDownCss },
  props: {
    modelValue: Boolean,
    tabbable: {
      type: Boolean,
      default: true,
    },
    /**
     * this is for focus the input element usually within the default slot.
     * 基本上是 tab 到该组件需要focus 到实际的输入框（由于输入框的 tabindex 必须是 -1） ，以及点击 dropdown 里面的东西之后，需要把焦点还给输入框。
     */
    focusFn: Function,
    /**
     * this prop is to hijack popup management. if set true, the popup will be fully managed by external, meaning the dropdown will only display when the model is set to true by external.
     */
    customPopup: Boolean,
    /**
     * disable click & tab.
     * this prop only manage functionality of disabled. the looking needs to managed by others.
     */
    disabled: Boolean,
    mathWidth: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clickOutside", "update:modelValue", "focus", "blur"],
  setup(props, { emit }) {
    // let isFocus = false;
    // console.log(props.target);
    const input = templateRef("input");
    const bonding = useBoundingClientRect(input);
    const model = modelRef(props, emit);
    // const popup = templateRef("popup");

    const popStyle = reactive({
      top: "0",
      left: "0",
      "max-height": "100%",
      // width: "100px",
    });
    watchEffect(() => {
      const bottom = bonding.bottom.value;
      const top = bonding.top.value;
      const right = bonding.right.value;
      const left = bonding.left.value;
      const winHeight = window.innerHeight;
      const winWidth = window.innerWidth;
      const bottomSpace = winHeight - bottom;
      const rightSpace = winWidth - right;

      // const dropHeight = 40 * computedItems.value.length;

      if (props.mathWidth) {
        popStyle.width = bonding.width.value + "px";
      } else {
        popStyle.width = "";
      }

      // popStyle.left = bonding.left.value + "px";
      // popStyle.left = "";
      // popStyle.right =
      //   winWidth - (bonding.left.value + bonding.width.value) + "px";
      // console.log(
      //   bonding.left.value,
      //   right,
      //   bonding.left.value + bonding.width.value
      // );
      //
      if (left > rightSpace) {
        popStyle.left = "";
        popStyle.right = winWidth - right + "px";
        popStyle["max-width"] = right - 15 + "px";
      } else {
        popStyle.left = left + "px";
        popStyle.right = "";
        popStyle["max-width"] = winWidth - left - 15 + "px";
      }

      if (top > bottomSpace) {
        //display on top
        popStyle.top = "";
        popStyle.bottom = winHeight - top + "px";
        popStyle["max-height"] = top - 15 + "px";
      } else {
        popStyle.bottom = "";
        popStyle.top = bottom + "px";
        popStyle["max-height"] = winHeight - bottom - 15 + "px";
      }
    });
    // eslint-disable-next-line no-unused-vars
    watchModel(props, (v) => {
      if (v) bonding.update();
    });
    const { keyMap } = shortcuts({ element: input });
    keyMap.escape = function () {
      model.value = false;
    };
    // watchEffect(() => {
    //   // keyControls.enabled = !!model.value;
    // });
    // keyMap.tab = function () {
    //   // isFocus = false;
    //   // emit("blur");
    //   // model.value = false;
    // };

    function setModel(value) {
      if (props.customPopup) return;
      model.value = value;
    }

    // clickOutside([input, popup], () => {
    // model.value = false;
    // emit("clickOutside");
    // if (!isFocus) {
    //   return;
    // }
    // emit("blur");
    // });
    const hasFocus = ref(false);

    const clickInput = () => {
      // console.log("click...................");
      //not hasFocus meaning this is the click to a blur component(this is why we use mousedown to make sure click event if before focus event.) logic is handled by onFocus in this case.
      if (!hasFocus.value) {
        return;
      }
      // console.log("click when it's focused.................");
      setModel(!model.value);
      // if (!props.listenClickEvent) {
      //   return;
      // }
      // // console.log("switch");
      // if (!isFocus) {
      //   isFocus = true;
      //   emit("focus");
      // }
      // model.value = !model.value;
      // console.log("switched");
    };

    function onFocus() {
      // console.log("focus.......................");
      hasFocus.value = true;
      // model.value = true;
      setModel(true);
      if (props.focusFn) props.focusFn();
      emit("focus");
    }
    let dropdownClicked = false;
    function onDropdownClick(event) {
      // console.log("dropdown click", model.value, event.preventDefault);
      if (!model.value) {
        event.preventDefault();
        return false;
      }
      if (props.focusFn) props.focusFn();
      dropdownClicked = true;
    }
    function onBlur() {
      // console.log("blur..........................");
      if (dropdownClicked) {
        dropdownClicked = false;
        input.value.focus();
      } else {
        // model.value = false;
        setModel(false);
        hasFocus.value = false;
        emit("blur");
      }
    }
    return {
      popStyle,
      clickInput,
      onFocus,
      onDropdownClick,
      onBlur,
      hasFocus,
    };
  },
};
</script>
