<template>
  <module-title-card title="Account">
    <template v-if="!(project && project.accountId)" v-slot:tools>
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link Account"
        />
        <project-link-account-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-else v-slot:tools>
      <hi-icon
        class="clickable-icon mr-4"
        :path="mdiTrashCanOutline"
        title="Remove Account"
        @click="removeAccount(project.accountId)"
      />
      <hi-icon
        class="clickable-icon"
        :path="mdiOpenInNew"
        title="Link Account"
        @click="jumpToAccount(project.accountId)"
      />
    </template>
    <accounts-title-card
      v-if="project && project.accountId"
      class="p-4"
      :account-id="project.accountId"
      :editable="false"
    />
  </module-title-card>
</template>

<script>
import ModuleTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { computed } from "vue";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import AccountsTitleCard from "@/hive-vue3/modules/accounts/AccountsTitleCard";
import { mdiOpenInNew, mdiTrashCanOutline } from "@mdi/js";
import { useRouter } from "vue-router";
import hive from "@/hive-vue3/components/hive";
import {
  getProjectById,
  updateProjectDetails,
} from "@/hive-vue3/modules/projects/data";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import ProjectLinkAccountDialog from "@/hive-vue3/modules/projects/ProjectLinkAccountDialog";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getAccountNameEmailById } from "@/hive-vue3/modules/accounts/data";
export default {
  name: "ProjectsAccountInfoCard",
  components: {
    ProjectLinkAccountDialog,
    AccountsTitleCard,
    HiDialogTrigger,
    HiIcon,
    ModuleTitleCard,
  },
  setup() {
    const project = computed(() => {
      return getProjectById(projectsState.currentProjectId);
    });
    const router = useRouter();

    function jumpToAccount(accountId) {
      router.push(`/accounts/${accountId}`);
    }

    async function removeAccount(accountId) {
      const res = await hive.confirm("Are you sure to unlink this account?");
      if (res) {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.UNLINK;
        historyObj.target = "project";
        historyObj.targetId = projectsState.currentProjectId;
        historyObj.comments = `Unlink account ${getAccountNameEmailById(
          accountId
        )}`;

        await addNewHistory(historyObj);

        await updateProjectDetails(projectsState.currentProjectId, {
          accountId: null,
        });
      }
    }

    return {
      commonIcons,
      project,
      mdiOpenInNew,
      jumpToAccount,
      mdiTrashCanOutline,
      removeAccount,
    };
  },
};
</script>

<style scoped></style>
