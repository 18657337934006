<template>
  <hi-dialog-button
    v-bind="$props"
    ref="dialog"
    :width="width"
    :min-width="minWidth"
  >
    <hi-fire-new-doc-form
      :collection-path="collectionPath"
      @cancel="close"
      @successful="close"
      :data-processor-fn="dataProcessorFn"
    >
      <slot />
    </hi-fire-new-doc-form>
  </hi-dialog-button>
</template>

<script>
import HiFireNewDocForm from "@/hive-vue3/components/fireDoc/HiFireNewDocForm";

import HiDialogButton from "@/hive-vue3/components/dialogs/HiDialogButton";
import { templateRef } from "@vueuse/core";
export default {
  name: "HiFireNewDocDialog",
  components: { HiDialogButton, HiFireNewDocForm },
  props: {
    dataProcessorFn: Function,
    modelValue: Boolean,
    collectionPath: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: "Add New Doc",
    },
    outlineButton: Boolean,
    textButton: Boolean,
    smallButton: Boolean,
    primaryButton: Boolean,
    debug: Boolean,
    closeButton: Boolean,
    title: {
      type: String,
      default: "Add new document to Firestore",
    },
    submitFn: Function,
    width: {
      type: [Number, String],
    },
    minWidth: [Number, String],
  },
  setup() {
    const dialog = templateRef("dialog");

    function close() {
      // console.log("---- cancel");
      // console.log(data);
      dialog.value.close();
    }

    return {
      close,
    };
  },
};
</script>

<style scoped></style>
