<template>
  <system-user-avatar
    v-for="(id, index) in userIds"
    :key="id + '-' + index"
    :user-id="id"
    class="relative rounded-full"
    ring
    :style="{ zIndex: 100 - index }"
    :small="small"
    :big="big"
  />
</template>

<script>
import SystemUserAvatar from "@/hive-vue3/modules/users/SystemUserAvatar";

export default {
  name: "SystemUserAvatarGroup",
  components: { SystemUserAvatar },
  props: {
    userIds: Array,
    small: Boolean,
    big: Boolean,
  },
};
</script>

<style scoped></style>
