<template>
  <hi-dialog title="Add New Size" close-button width="600px" v-model="open">
    <hi-form-hive-standard
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      cancel-button
      :initial-values="initialValues"
    >
      <hi-form-item label="Pattern*">
        <hi-select
          :items="patternSelectorObj"
          item-capitalize
          form-key="pattern"
          mandatory
        />
      </hi-form-item>

      <div class="p-2 border-2 border border-dashed">
        <div>Size 1</div>
        <div class="flex gap-4 w-full">
          <div class="flex gap-4 w-full items-center">
            <div>Length*</div>
            <hi-input finance mandatory form-key="length" />
          </div>
          <div class="flex gap-4 w-full items-center">
            <div>Width*</div>
            <hi-input finance mandatory form-key="width" />
          </div>
        </div>
      </div>
      <div class="p-2 border-2 border border-dashed">
        <div class="flex justify-between">
          <div>Size 2</div>
          <hi-check no-form v-model="formStatus.enableSize2"
            >Enable Size 2
          </hi-check>
        </div>
        <div class="flex gap-4 w-full">
          <div class="flex gap-4 w-full items-center">
            <div>Length</div>
            <hi-input
              :disabled="!formStatus.enableSize2"
              finance
              form-key="length2"
              v-model="formData.length2"
            />
          </div>
          <div class="flex gap-4 w-full items-center">
            <div>Width</div>
            <hi-input
              :disabled="!formStatus.enableSize2"
              finance
              form-key="width2"
              v-model="formData.width2"
            />
          </div>
        </div>
      </div>
      <hi-form-item label="Thickness">
        <hi-input integer mandatory form-key="thickness" />
      </hi-form-item>
      <hi-form-item label="Veneer">
        <hi-input integer mandatory form-key="veneer" />
      </hi-form-item>

      <div class="p-2 border-2 border border-dashed space-y-1">
        <div>UV Lacquer Price</div>

        <hi-form-item label="Prime*">
          <hi-input
            form-key="UV.prime"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Medium*">
          <hi-input
            form-key="UV.medium"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Practical*">
          <hi-input
            form-key="UV.practical"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>
      </div>

      <div class="p-2 border-2 border border-dashed space-y-1">
        <div>Reactive Stain Price</div>
        <hi-form-item label="Prime*">
          <hi-input
            form-key="RS.prime"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Medium*">
          <hi-input
            form-key="RS.medium"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Practical*">
          <hi-input
            form-key="RS.practical"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>
      </div>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref, computed, reactive, watch } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import {
  getPatternByPatternId,
  getPatternsSelectObject,
  newSizeAndPriceToFirebase,
} from "@/hive-vue3/modules/products/data";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiCheck from "@/hive-vue3/components/form/controls/HiCheck";
import { productState } from "@/hive-vue3/modules/products/state";
import { camelize } from "@/hive-vue3/utils/stringUtils";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
export default {
  name: "productAddNewSizeDialog",
  components: {
    HiCheck,
    HiInput,
    HiSelect,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  setup() {
    const open = ref(false);

    const formData = reactive({
      length2: undefined,
      width2: undefined,
    });

    const formStatus = reactive({
      enableSize2: false,
    });

    watchPropsImmediately(open, "value", (v) => {
      if (!v) {
        formStatus.enableSize2 = false;
      }
    });

    const initialValues = computed(() => {
      const res = getPatternByPatternId(productState.currentPattern);

      if (res.type) {
        return {
          pattern: productState.currentPattern,
          type: camelize(res.type),
        };
      }
      return {
        pattern: undefined,
        type: undefined,
      };
    });

    watch(formStatus, (data) => {
      if (!data.enableSize2) {
        formData.length2 = undefined;
        formData.width2 = undefined;
      }
    });

    async function submit(formdata) {
      const data = {};
      Object.assign(data, formdata);
      data.size = [];
      data.size[0] = {
        length: data.length,
        width: data.width,
      };

      delete data.length;
      delete data.width;
      if (formStatus.enableSize2) {
        if (!data.length2 || !data.width2) {
          return { successful: false, errorMessage: "Size2 is mandatory." };
        }
        data.size[1] = {
          length: data.length2,
          width: data.width2,
        };
      }

      delete data.length2;
      delete data.width2;

      const res = await newSizeAndPriceToFirebase({ ...data, isActive: true });
      if (res) {
        return { successful: true };
      }
      return { successful: false, errorMessage: "Size Already Exits" };
    }

    const patternSelectorObj = computed(() => {
      return getPatternsSelectObject();
    });

    return {
      open,
      submit,
      patternSelectorObj,
      formStatus,
      formData,
      initialValues,
    };
  },
};
</script>

<style scoped></style>
