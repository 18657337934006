<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 574.9 95.3"
    xml:space="preserve"
    style="fill: currentColor"
  >
    <g>
      <path
        d="M557.1,16.3h3.3c0.6,0,1.1,0.3,1.1,1s-0.4,1-1.1,1h-3.3V16.3z M565.3,17.1c0-2.4-1.6-3.7-4.5-3.7h-7.7v11.7h4v-4.2h1.3
		c1,0,1.2,0.3,1.4,0.8l1.3,3.5h4.2l-1.6-3.5c-0.3-0.5-0.5-0.9-1.1-1.1v-0.4C564.4,19.8,565.3,19,565.3,17.1 M558.8,28.4
		c-5.8,0-9.5-3.5-9.5-9.1c0-5.6,3.7-9.1,9.5-9.1c5.8,0,9.5,3.5,9.5,9.1C568.3,24.9,564.6,28.4,558.8,28.4 M558.8,6.9
		c-8.1,0-13.1,4.8-13.1,12.4s5,12.4,13.1,12.4c8.1,0,13.1-4.8,13.1-12.4S566.9,6.9,558.8,6.9 M539.6,28.1c-9.5,0-16,3.8-19.1,11.2
		h-2.2v-8.9l-2.4-2.4h-19.4l-2.4,2.4v55.9l2.4,2.4h19.4l2.4-2.4V58.6c0-7.6,5-11.4,15.2-11.4h7.6l2.4-2.4V30.5l-2.4-2.4H539.6z
		 M455.5,43.7c5.8,0,9.9,2.9,11,8.1h-22.1C445.4,46.6,449.6,43.7,455.5,43.7 M489.5,61.2c0.2-22-12.9-34.3-34.5-34.3
		c-21.4,0-34.7,12.1-34.7,31.6s13.3,31.6,35.6,31.6c17.8,0,29.2-7.6,32.2-19.3l-2.4-2.4h-19.3c-1.5,3.2-5.6,4.8-10.3,4.8
		c-6.7,0-11.6-3.8-12-9.6h42.9L489.5,61.2z M380.1,71.6c-7.1,0-11.6-5-11.6-13s4.4-13,11.6-13s11.6,5,11.6,13S387.2,71.6,380.1,71.6
		 M389.8,27.1c-8.9,0-14.9,3.3-17.9,9.9h-2.2V10.2l-2.4-2.4h-19.4l-2.4,2.4v76.2l2.4,2.4h16.6l2.7-2.7l2.1-6.2h2.4
		c3.2,6.6,9.3,9.9,18.1,9.9c15.7,0,26.2-11.3,26.2-31.4S405.4,27.1,389.8,27.1 M316,26.8c-9.4,0-16.8,3.9-20.2,11.7H294
		c-2.3-7.6-8.4-11.7-17.5-11.7c-9.4,0-16.7,4.1-19.9,11.7h-2.2v-8l-2.4-2.4h-19.4l-2.4,2.4v55.9l2.4,2.4h19.4l2.4-2.4V57.1
		c0-7,4-10.4,9-10.4c5.3,0,8.1,3.6,8.1,9.9v29.8l2.4,2.4h19.4l2.4-2.4V57.1c0-7,3.9-10.4,9-10.4c5.3,0,8.1,3.6,8.1,9.9v29.8l2.4,2.4
		h19.4l2.4-2.4V50.9C337,34.9,329.5,26.8,316,26.8 M195.9,86.5l2.4,2.4h19.4l2.4-2.4V30.5l-2.4-2.4h-19.4l-2.4,2.4V86.5z M192.2,21
		l2.4,2.4h26.8l2.4-2.4V10.2l-2.4-2.4h-26.8l-2.4,2.4V21z M189.5,43.7V30.5l-2.4-2.4h-16.3V10.2l-2.4-2.4H149l-2.4,2.4v17.9h-4.5
		c-9.8,0-16.6,2.9-20.4,11.2h-2.2v-8.9l-2.4-2.4H97.7l-2.4,2.4v55.9l2.4,2.4h19.4l2.4-2.4V58.6c0-7.6,5-12.5,15.2-12.5h11.9v23.8
		c0,11.9,6.5,19,25.6,19h14.9l2.4-2.4V72.5l-2.4-2.4h-7.3c-6.8,0-9-1.8-9-7.1v-17h16.3L189.5,43.7z M45.3,29.3h3.2l6.4,24.9h-16
		L45.3,29.3z M62.6,7.8H31.2l-3.7,2.7L3.9,85.2l2.7,3.7H27l3.7-2.8l2.9-11.3h26.6L63.1,86l3.7,2.8h20.5l2.7-3.7L66.3,10.5L62.6,7.8z
		"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArtimberLogo",
};
</script>

<style scoped></style>
