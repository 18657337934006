<template>
  <div class="hi-tab-view flex flex-col">
    <div class="relative bg-light-400">
      <div
        class="absolute border-b border-primary/30 w-full z-1 inset-0 hi-inset-shadow-bottom-light"
      />

      <div class="flex p-2 pb-0 hi-tab-bar relative z-[2]">
        <div
          v-for="(tab, index) in tabs"
          :key="tab"
          @click="setCurrent(index)"
          class="transition-all duration-300 hi-tab-item"
          :class="{
            active: index === currentTab,
          }"
        >
          {{ utils.beautifyAsTitle(tab) }}
        </div>
      </div>
    </div>
    <!--    Fixed Grow Issue-->
    <div class="flex-grow hi-tab-body h-0">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, provide, ref } from "vue";
import { beautifyAsTitle } from "@/hive-vue3/utils/stringUtils";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { watchModelOnMounted } from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default {
  name: "HiTabGroup",
  props: {
    /**
     * current tab label
     */
    modelValue: String,
  },
  setup(props, { emit }) {
    const tabs = ref([]);
    const currentTab = ref(0);
    const model = modelRef(props, emit);
    provide("hi-tabs", {
      register(label) {
        if (tabs.value.length === 0) model.vaue = label;
        // console.log("register", label);
        tabs.value.push(label);
      },
      currentTab: computed(() => tabs.value[currentTab.value]),
    });
    function setCurrent(index) {
      currentTab.value = index;
      model.value = tabs.value[index];
    }
    function setCurrentByLabel(label) {
      // console.log(tabs.value);
      const labels = tabs.value;
      for (let i = 0; i < labels.length; i++) {
        if (label === labels[i]) {
          setCurrent(i);
          return;
        }
      }
      console.warn(`HiTabGroup found unregistered label value: '${label}'.`);
    }
    watchModelOnMounted(
      props,
      (label) => {
        if (!label) return;
        if (currentTab.value === model.value) return;
        setCurrentByLabel(label);
      },
      "modelValue",
      { immediate: true }
    );
    // onMounted(() => {
    //   setCurrentByLabel(model.value);
    // });
    return {
      tabs,
      setCurrent,
      currentTab,
      utils: {
        beautifyAsTitle,
      },
    };
  },
};
</script>
