<template>
  <div
    class="-inputBase pointer-events-none select-none"
    :class="{
      focus: hasFocus && !disabled && !locked,
      error: error,
      valid: valid && model && model.length,
      disabled: disabled,
    }"
    @mousedown="onMouseDown"
    @click="focus"
    ref="container"
  >
    <div class="relative w-full">
      <div class="flex w-full">
        <div
          class="hi-input-icon-left grow-0 shrink-0"
          v-if="prefixIcon || prefixText"
          style=""
          :class="{
            'pointer-events-auto': !disabled && !prefixDisabled,
            active: prefixToggleOn,
          }"
          :title="prefixTip"
          @click="$emit('prefix-click')"
        >
          <hi-icon v-if="prefixIcon" :path="prefixIcon" />
          <span v-if="prefixText">{{ prefixText }}</span>
        </div>

        <!--      reset placeholder to empty to allow costume placeholder display-->
        <div class="grow items-stretch relative">
          <transition-fade :end-opacity="0.5">
            <div
              class="hi-control-padding absolute flex items-center z-1 pointer-events-none hi-placeholder opacity-50 inset-0"
              v-if="displayPlaceholder"
            >
              <div class="h-full w-full ellipse">{{ displayPlaceholder }}</div>
            </div>
          </transition-fade>
          <input
            :disabled="disabled || locked"
            placeholder=" "
            v-model="model"
            :type="password ? 'password' : 'text'"
            class="bg-transparent hi-control-padding hi-inputable w-full"
            :class="{ '-large': large, 'pointer-events-auto': !disabled }"
            v-auto-focus="autoFocus"
            @input="$emit('input', $event.target.value)"
            @focus="onFocus"
            @click="onFocus"
            @blur="onBlur"
            ref="input"
          />
        </div>

        <a
          tabindex="-1"
          target="_blank"
          class="hi-input-icon-right grow-0 shrink-0"
          v-if="suffixText || suffixIcon"
          style="padding-left: 0.5em; padding-right: 0.5em"
          :class="{
            'pointer-events-auto':
              !disabled || !suffixDisabled || !(suffixLink && !suffixHref),
            active: suffixToggleOn || (suffixLink && suffixHref),
            disabled: suffixDisabled,
          }"
          :href="suffixHref"
          :title="suffixTip"
          @click="$emit('suffix-click')"
        >
          <hi-icon v-if="suffixIcon" :path="suffixIcon" />
          <span v-if="suffixText">{{ suffixText }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import autoFocus from "@/hive-vue3/directives/autoFocus";
import { computed, ref } from "vue";
import { templateRef } from "@vueuse/core/index";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiIcon from "@/hive-vue3/components/HiIcon";
export default {
  name: "InputBase",
  components: { TransitionFade, HiIcon },
  emits: [
    "focus",
    "blur",
    "input",
    "update:modelValue",
    "prefix-click",
    "suffix-click",
  ],
  directives: { autoFocus },
  props: {
    modelValue: [String, Number],
    placeholder: String,
    autoFocus: Boolean,
    disabled: Boolean,
    error: [Boolean, String],
    valid: Boolean,
    prefixIcon: String,
    prefixText: String,
    prefixTip: String,
    prefixDisabled: Boolean,
    prefixToggleOn: Boolean,
    suffixIcon: String,
    suffixText: String,
    suffixTip: String,
    suffixDisabled: Boolean,
    suffixToggleOn: Boolean,
    password: Boolean,
    /**
     * turn suffix to a clickable link. will disable automatic when there's no href
     */
    suffixLink: Boolean,
    suffixHref: String,
    large: Boolean,

    locked: Boolean,
  },
  setup(props, { emit }) {
    const model = modelRef(props, emit);
    const hasFocus = ref(false);
    const input = templateRef("input");
    // const container = templateRef("container");
    const displayPlaceholder = computed(() => {
      const val = model.value;

      if (val && val.length) {
        return null;
      }
      // console.log("placeholder", props.placeholder);
      return props.placeholder;
    });
    function focus() {
      if (props.disabled || props.locked || !input.value) return;
      input.value.focus();
    }
    // function onFocusIn() {
    //   console.log("focus-in");
    //
    //   // console.log(
    //   //   "............... focusin ...............hasFocus:" + hasFocus.value
    //   // );
    //   if (!hasFocus.value) focus();
    //   // console.log(container.value.querySelector(":focus"));
    //   // if (hasFocus.value) return;
    //   // hasFocus.value = true;
    //   // // console.log("focus in");
    //   //
    //   // emit("focus");
    //   // justClicked = false;
    // }
    let justClicked = false;
    // mouse event for container, mousedown to set justClicked switch, click to set focus
    function onMouseDown() {
      // console.log("........... mousedown ...........");
      // console.log("click justClicked set to true");
      justClicked = true;
    }
    function onBlur() {
      // console.log("base blur justClicked:" + justClicked);
      if (justClicked) {
        return;
      }
      if (!hasFocus.value) {
        return;
      }
      hasFocus.value = false;
      emit("blur");
    }
    function onFocus() {
      // console.log("base focus justClicked:" + justClicked);
      justClicked = false;
      // console.log("justClick set to false");
      if (hasFocus.value) return;
      hasFocus.value = true;
      // console.log("focus in");
      emit("focus");
    }
    // async function onFocusOut() {
    //   console.log(
    //     ".......... focusout ..............justClicked: " + justClicked
    //   );
    //   if (justClicked) {
    //     justClicked = false;
    //     return;
    //   }
    //   //div.focusout if fired before input getting focus. nextTick to check if input is focused.
    //   await nextTick();
    //   if (!hasFocus.value) return;
    //   const div = container.value;
    //   if (div) {
    //     //cannot use document.activeElement because activeElement won't clear when the whole window loses focus.
    //     const active = div.parentNode.querySelector(":focus");
    //     // console.log("active element....................");
    //     // console.log(active);
    //     if (div === active || div.contains(active)) {
    //       // console.log("focusout event fired but focus is still in");
    //       // console.log(document.activeElement);
    //       return;
    //     }
    //   }
    //   // console.log(document.activeElement);
    //   // console.log(div.contains(document.activeElement));
    //   // if (
    //   //   div &&
    //   //   (div === document.activeElement || div.contains(document.activeElement))
    //   // ) {
    //   //
    //   // }
    //
    //   // console.log("focus out..........", e);
    //   hasFocus.value = false;
    //   emit("blur");
    // }
    return {
      model,
      displayPlaceholder,
      hasFocus,
      input,
      focus,
      blur() {
        input.value.blur();
      },
      onFocus,
      onBlur,
      // onFocusIn,
      // onFocusOut,
      onMouseDown,
    };
  },
};
</script>

<style scoped></style>
