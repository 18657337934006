<template>
  <contacts-view-full-kit class="h-full" />
</template>

<script>
import ContactsViewFullKit from "@/hive-vue3/modules/contacts/ContactsViewFullKit";
export default {
  name: "ContactsView",
  components: { ContactsViewFullKit },
};
</script>

<style scoped></style>
