<template>
  <hi-dialog
    title="Create New Quotation"
    width="800px"
    close-button
    v-model="open"
  >
    <quotation-editor-form @cancel="open = false" />
  </hi-dialog>
</template>

<script>
import { ref } from "vue";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import QuotationEditorForm from "@/hive-vue3/modules/quotation/QuotationEditorForm";
export default {
  name: "AddNewQuotationDialog",
  components: {
    QuotationEditorForm,
    HiDialog,
  },
  props: {
    newRevision: Boolean,
    edit: Boolean,
    defaultFormData: Object,
  },
  setup() {
    return {
      open: ref(false),
    };
  },
};
</script>

<style scoped></style>
