<template>
  <layout-three-column
    class="h-full"
    full-card-first-col
    middle-bigger
    no-big-screen
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Patterns</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button text>Add New Pattern</hi-button>

        <products-add-new-pattern-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <products-pattern-list class="h-full overflow-auto" />
    </template>
    <template v-slot:col2>
      <hi-card class="h-full">
        <hi-div flex-col-full-height-to-support-grow>
          <div class="bg-light-400 p-4 flex items-center">
            <div class="tp-title-faded">Sizes</div>
            <div class="grow"></div>
            <hi-dialog-trigger>
              <hi-button text>Add New Size</hi-button>
              <product-add-new-size-dialog />
            </hi-dialog-trigger>
          </div>

          <div class="grow">
            <products-size-full-card class="h-full" />
          </div>
        </hi-div>
      </hi-card>
    </template>

    <template v-slot:col3>
      <div class="h-full flex flex-col">
        <div class="bg-light-400 p-4 flex items-center">
          <div class="tp-title-faded">Colors</div>
          <div class="grow"></div>

          <hi-dialog-trigger>
            <hi-button text>Add New Color</hi-button>
            <product-add-new-color-dialog
              :initial-value="{
                type:
                  productState.currentSettingTab === 'UV Lacquer' ? 'UV' : 'RS',
              }"
            />
          </hi-dialog-trigger>
        </div>

        <hi-tab-group v-model="productState.currentSettingTab" class="grow h-0">
          <hi-tab label="UV Lacquer" class="overflow-auto">
            <div class="flex flex-col h-full">
              <products-color-list collection="UV" />
            </div>
          </hi-tab>
          <hi-tab label="Reactive Stain" class="overflow-auto">
            <div class="flex flex-col h-full">
              <products-color-list collection="RS" />
            </div>
          </hi-tab>
        </hi-tab-group>
      </div>
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import { productState } from "@/hive-vue3/modules/products/state";
import ProductsPatternList from "@/hive-vue3/modules/products/productsPatternList";
import ProductsSizeFullCard from "@/hive-vue3/modules/products/productsSizeFullCard";
import HiCard from "@/hive-vue3/components/HiCard";
import HiDiv from "@/hive-vue3/components/HiDiv";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ProductAddNewSizeDialog from "@/hive-vue3/modules/products/productAddNewSizeDialog";
import HiButton from "@/hive-vue3/components/HiButton";
import { camelize } from "@/hive-vue3/utils/stringUtils";
import ProductAddNewColorDialog from "@/hive-vue3/modules/products/productAddNewColorDialog";
import ProductsColorList from "@/hive-vue3/modules/products/productsColorList";
import ProductsAddNewPatternDialog from "@/hive-vue3/modules/products/productsAddNewPatternDialog";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";

export default {
  name: "productsViewFullKit",
  components: {
    ProductsAddNewPatternDialog,
    ProductsColorList,
    ProductAddNewColorDialog,
    HiButton,
    ProductAddNewSizeDialog,
    HiDialogTrigger,
    HiDiv,
    HiCard,
    ProductsSizeFullCard,
    ProductsPatternList,
    HiTab,
    HiTabGroup,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    setRouterId(props, productState, "currentPattern", "/products/");

    return {
      productState,
      camelize,
    };
  },
};
</script>

<style scoped></style>
