<template>
  <hi-dialog width="600px" v-model="open" title="New Campaign">
    <hi-form-hive-standard
      :submit-fn="submit"
      cancel-button
      @successful="open = false"
      @cancel="open = false"
    >
      <hi-form-item label="Subject *">
        <hi-input form-key="subject" />
      </hi-form-item>
      <hi-form-item label="Template URL *">
        <hi-input form-key="templateUrl" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { ref } from "vue";
import { requestGetMailTemplateFromRemoteUrl } from "@/hive-vue3/api/request";
import { edmState } from "@/hive-vue3/modules/edm/state";
import { newFirestoreDoc } from "@/hive-vue3/firebase";
export default {
  name: "EdmNewCampaignDialog",
  components: { HiInput, HiFormItem, HiFormHiveStandard, HiDialog },
  setup() {
    const open = ref(false);
    async function submit(data) {
      const res = await requestGetMailTemplateFromRemoteUrl(data.templateUrl);
      if (res.data && res.data.html) {
        await newFirestoreDoc("/edm/campaigns/registers", {
          subject: data.subject,
          status: "edit",
          template: res.data.html,
        });
        edmState.currentHtml = res.data.html;
        return { successful: true };
      }
      console.log(res);
      return { successful: false };
    }
    return {
      open,
      submit,
    };
  },
};
</script>

<style scoped></style>
