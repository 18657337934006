<template>
  <hi-fire-new-doc-dialog
    min-width="800px"
    :data-processor-fn="dataProcessor"
    v-bind="$props"
    :collection-path="dbPaths.people"
    button-text="Add New People"
    title="Add New People as Contact"
    close-button
  >
    <hi-form-item label="Title">
      <hi-chips-selectable
        :items="{
          Mr: 'Mr.',
          Miss: 'Miss.',
          Mrs: 'Mrs.',
          Ms: 'Ms.',
        }"
        value-key="titleValue"
        text-key="titleName"
        form-key="title"
      />
    </hi-form-item>
    <hi-form-item label="First Name*">
      <hi-input form-key="name" mandatory :model-value="raw.name" auto-focus />
    </hi-form-item>
    <hi-form-item label="Middle Name">
      <hi-input form-key="midName" :model-value="raw.midName" />
    </hi-form-item>
    <hi-form-item label="Surname">
      <hi-input form-key="surname" :model-value="raw.surname" />
    </hi-form-item>
    <hi-form-item label="Gender">
      <hi-chips-selectable
        :items="{
          male: 'Male',
          female: 'Female',
          other: 'Other',
        }"
        value-key="genderValue"
        text-key="genderName"
        form-key="gender"
      />
    </hi-form-item>
    <hi-form-item label="Entity">
      <mfc-input-contact-entity form-key="entity" :model-value="entityId" />
    </hi-form-item>
    <hi-form-item label="Position">
      <hi-chips-selectable
        :doc-path="dbPaths.positionTagDoc"
        can-add
        form-key="position"
      />
    </hi-form-item>
    <hi-form-item label="Mobile">
      <hi-input
        form-key="mobile"
        mobile
        :validator="
          uniqueValueValidator(dbPaths.people, 'mobile', {
            label: 'Mobile Number',
            formatter: mobileE164,
          })
        "
        :model-value="raw.mobile"
      />
    </hi-form-item>
    <hi-form-item label="Email">
      <hi-input
        form-key="email"
        :model-value="raw.email"
        email
        :validator="uniqueValueValidator(dbPaths.people, 'email')"
      />
    </hi-form-item>
    <hi-form-item label="Address">
      <hi-input-address form-key="address" />
    </hi-form-item>
  </hi-fire-new-doc-dialog>
</template>

<script>
import HiFireNewDocDialog from "@/hive-vue3/components/fireDoc/HiFireNewDocDialog";
import dbPaths from "./dbPaths";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import { uniqueDbValueValidator } from "@/hive-vue3/firebase";
import formatters from "@/hive-vue3/utils/formatters";
import MfcInputContactEntity from "@/hive-vue3/modules/contacts/controls/MfcInputContactsEntity";
import { ref, watchEffect } from "vue";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";

export default {
  name: "ContactsNewPeopleDialog",
  components: {
    HiChipsSelectable,
    MfcInputContactEntity,
    HiInputAddress,
    HiInput,
    HiFormItem,
    HiFireNewDocDialog,
  },
  props: {
    rawData: Object,
    entityId: String,
  },
  setup(props) {
    const mobileE164 = formatters.mobileE164;
    const rawDataRef = ref({});
    watchEffect(() => {
      const raw = {};
      // eslint-disable-next-line vue/no-setup-props-destructure
      const rawData = props.rawData;
      if (!rawData) {
        rawDataRef.value = {};
        return;
      }
      const name = rawData.name;
      const surname = rawData.surname;

      const firstName = rawData.firstName;
      const mobile = rawData.mobile;
      const phone = rawData.phone;
      const postcode = rawData.postcode || rawData.post;
      if (firstName) {
        raw.name = firstName;
      } else if (name) {
        // firstName must be at least 2 chars
        if (name.indexOf(" ") > 1) {
          const names = name.replace(/\s+/g, " ").split(" ");
          raw.name = names[0];
          if (names.length > 2) {
            raw.midName = names[1];
            raw.surname = names[2];
          } else {
            raw.surname = names[1];
          }
        } else {
          raw.name = name;
        }
      }
      if (surname) raw.surname = surname;
      if (mobile) raw.mobile = mobile;
      if (phone) raw.phone = phone;
      if (postcode) raw.phostcode = postcode;
      if (rawData.email) raw.email = rawData.email;
      rawDataRef.value = raw;
    });

    function dataProcessor(data) {
      const entity = data.entity;
      const position = data.position;
      if (entity) {
        data.entities = [entity];
        delete data.entity;
        if (position) {
          data.entityPositions = { [entity]: position };
          delete data.position;
        }
      }

      return data;
    }

    return {
      dbPaths,
      mobileE164,
      uniqueValueValidator: uniqueDbValueValidator,
      raw: rawDataRef,
      dataProcessor,
    };
  },
};
</script>

<style scoped></style>
