<template>
  <div class="flex-center w-full h-full flex-col">
    <div class="opacity-50"><empty-box class="w-12" /></div>
    <div class="opacity-50"><slot>No Data Yet...</slot></div>
  </div>
</template>

<script>
import EmptyBox from "@/hive-vue3/assets/svg/vue/EmptyBox";

export default {
  name: "HiViewNoData",
  components: { EmptyBox },
};
</script>

<style scoped></style>
