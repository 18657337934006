<template>
  <div class="flex gap-2" v-if="productDetails && productDetails.size">
    <div class="grow">
      <div class="min-w-[220px] font-bold">{{ product.shortNum }}</div>
      <div>
        <div class="flex items-center gap-4">
          <div class="font-bold capitalize">{{ product.grade }}</div>
          <div>
            {{
              productValue
                ? `$${productValue.toLocaleString()}`
                : "On Application"
            }}
          </div>
        </div>
      </div>
      <!--      <product-size-line-item :size-item="productDetails.size" />-->
    </div>
    <color-item :color-id="product.colorId" class="w-[90px]" />
    <pattern-item :pattern-id="product.patternId" class="w-[90px]" />
  </div>
</template>

<script>
import { computed } from "vue";
import { decamelize } from "@/hive-vue3/utils/stringUtils";
import { decodeDocId } from "@/hive-vue3/firebase";
import {
  getColorById,
  getPatternByPatternId,
  getPatternSizeBySizeId,
} from "@/hive-vue3/modules/products/data";
import ColorItem from "@/hive-vue3/modules/projects/parts/product/ColorItem";
import PatternItem from "@/hive-vue3/modules/projects/parts/product/PatternItem";
// import ProductSizeLineItem from "@/hive-vue3/modules/products/productSizeLineItem";

export default {
  name: "productsInfoView",
  components: {
    // ProductSizeLineItem,
    PatternItem,
    ColorItem,
  },
  props: {
    product: Object,
  },
  setup(props) {
    const productDetails = computed(() => {
      if (props.product && props.product.sizeId) {
        const color = getColorById(props.product.colorId);
        const pattern = getPatternByPatternId(props.product.patternId);
        const size = getPatternSizeBySizeId(props.product.sizeId);
        const grade = props.product.grade;

        return {
          color,
          pattern,
          size,
          grade,
        };
      }
      return {};
    });

    const productValue = computed(() => {
      if (
        productDetails.value &&
        productDetails.value.size &&
        productDetails.value.size.size &&
        productDetails.value.size.size.length &&
        productDetails.value.color &&
        productDetails.value.color.type &&
        productDetails.value.grade
      ) {
        return productDetails.value.size[productDetails.value.color.type][
          productDetails.value.grade
        ];
      }
      return 0;
    });

    return {
      decamelize,
      decodeDocId,
      productDetails,
      productValue,
    };
  },
};
</script>

<style scoped></style>
