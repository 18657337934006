<template>
  <hi-list no-padding :items="patterns" selectable @select="selectPattern">
    <template v-slot="{ item: pattern }">
      <products-pattern-summary :pattern-data="pattern" editable />
    </template>
  </hi-list>
</template>

<script>
import { productState } from "@/hive-vue3/modules/products/state";
import { decamelize, camelize } from "@/hive-vue3/utils/stringUtils";
import HiList from "@/hive-vue3/components/HiList";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import ProductsPatternSummary from "@/hive-vue3/modules/products/productsPatternSummary";
import { computed } from "vue";
import { getPatternList } from "@/hive-vue3/modules/products/data";
export default {
  name: "productsPatternList",
  components: {
    ProductsPatternSummary,
    HiList,
  },

  setup() {
    const patterns = computed(() => {
      return getPatternList();
    });

    function selectPattern(item) {
      // console.log(item);
      productState.currentPattern = item.id;
    }

    return {
      patterns,
      decamelize,
      productState,
      camelize,
      commonIcons,
      selectPattern,
    };
  },
};
</script>

<style scoped></style>
