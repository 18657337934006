<template>
  <hi-dialog-button
    button-text="Add New Entity"
    close-button
    title="Add New Entity"
    width="700px"
    v-model="isOpen"
    @close="$emit('close')"
    persist
  >
    <hi-stage-group
      :loading-state="state.loading"
      v-model="state.currentStage"
      style="min-height: 565px"
    >
      <hi-stage name="abn-lookup" home>
        <hi-au-business-search
          @select="selectBusiness"
          class="border rounded"
        />
        <div class="text-right pt-2">
          <hi-stage-link to="no-abn"
            ><hi-button text>Add Entity Without ABN</hi-button></hi-stage-link
          >
        </div>
      </hi-stage>
      <hi-stage name="no-abn">
        <div class="flex flex-col h-full">
          <div class="grow flex-center">
            Entity Name
            <br /><br />
            <hi-input
              large
              class="w-full"
              auto-focus
              v-model="state.manualName"
            />
          </div>
          <div class="flex justify-between">
            <hi-stage-link @click="back2home"
              ><hi-button>Back</hi-button></hi-stage-link
            >
            <hi-stage-link to="details"
              ><hi-button primary :disabled="!state.manualName"
                >Next</hi-button
              ></hi-stage-link
            >
          </div>
        </div>
      </hi-stage>
      <hi-stage name="details">
        <hi-au-business-details
          v-if="state.abn"
          v-model="selectedBusiness"
          class="hi-bg-light1 border-b border-gray-200"
          :abn="state.abn"
        />
        <div class="tp-big-fancy-display" v-if="state.manualName">
          {{ state.manualName }}
        </div>
        <div class="m-6">
          <hi-form
            :disabled="!selectedBusiness && !state.manualName"
            cancel-button
            cancel-text="Back"
            @submit="submit"
            @cancel="back2home"
          >
            <span v-if="selectedBusiness">
              <hi-form-value-holder
                form-key="name"
                :model-value="selectedBusiness.name"
              />
              <hi-form-value-holder
                form-key="abn"
                :model-value="selectedBusiness.abn"
              />
              <hi-form-value-holder
                form-key="registration"
                :model-value="selectedBusiness"
              />
            </span>

            <hi-form-item label="Street Address">
              <hi-input-address form-key="address" auto-focus />
            </hi-form-item>
            <hi-form-item label="Postal Address">
              <hi-input-address form-key="addressPostal" />
            </hi-form-item>

            <span v-if="state.manualName">
              <hi-form-value-holder
                form-key="tradingName"
                :model-value="state.manualName"
              />
            </span>
            <hi-form-item label="Trading Name" v-else>
              <hi-input form-key="tradingName" />
            </hi-form-item>
            <hi-form-item label="Phone">
              <hi-input form-key="phone" />
            </hi-form-item>
            <hi-form-item label="Email">
              <hi-input form-key="email" email />
            </hi-form-item>
          </hi-form>
        </div>
      </hi-stage>
      <hi-stage name="final">
        <hi-view-successful>
          <hi-fire-doc
            :collection-path="dbPaths.entities"
            :doc-id="state.docId"
            class="flex-center"
          >
            <template v-slot="{ docData }">
              <div class="tp-big-fancy-display">{{ docData.name }}</div>
              <div class="tp-subtitle" v-if="docData.registration">
                A.B.N. {{ docData.registration.abn }}
              </div>
              <div class="font-bold pb-8">Added Successfully!</div>

              <hi-fire-doc-tagger
                label="Add tags"
                collection-path="contacts"
                doc-path="entityTags"
                edit-mode
                multiple
                value-key="tags"
              />
            </template>
          </hi-fire-doc>
          <div class="flex justify-between pt-16 w-full">
            <hi-button text @click="back2home">Add Another</hi-button>
            <hi-button primary wide @click="done">Done</hi-button>
          </div>
        </hi-view-successful>
      </hi-stage>
      <hi-stage name="error">
        <hi-view-error>
          <div class="pb-6">{{ state.error }}</div>
          <hi-stage-link home>
            <hi-button text>Back</hi-button>
          </hi-stage-link>
        </hi-view-error>
      </hi-stage>
    </hi-stage-group>
  </hi-dialog-button>
</template>

<script>
import HiStageGroup from "@/hive-vue3/components/HiStageGroup";
import HiStage from "@/hive-vue3/components/HiStage";
import { reactive, ref } from "vue";
import HiAuBusinessSearch from "@/hive-vue3/au/HiAuBusinessSearch";
import HiAuBusinessDetails from "@/hive-vue3/au/HiAuBusinessDetails";
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import {
  countDocsWithCertainValue,
  newFirestoreDoc,
} from "@/hive-vue3/firebase";
import dbPaths from "./dbPaths";
import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import HiStageLink from "@/hive-vue3/components/HiStageLink";
import HiButton from "@/hive-vue3/components/HiButton";
import HiFireDocTagger from "@/hive-vue3/components/fireDoc/HiFireDocTagger";
import HiViewError from "@/hive-vue3/views/HiViewError";
import HiViewSuccessful from "@/hive-vue3/views/HiViewSuccessful";
import HiDialogButton from "@/hive-vue3/components/dialogs/HiDialogButton";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
export default {
  name: "NewContactsEntityDialog",
  components: {
    HiFormValueHolder,
    HiDialogButton,
    HiViewSuccessful,
    HiViewError,
    HiFireDocTagger,
    HiButton,
    HiStageLink,
    HiFireDoc,
    HiInput,
    HiInputAddress,
    HiFormItem,
    HiForm,
    HiAuBusinessDetails,
    HiAuBusinessSearch,
    HiStage,
    HiStageGroup,
  },
  props: {
    modelValue: Boolean,
  },
  emits: ["close", "added", "update:modelValue"],
  setup(props, { emit }) {
    const isOpen = modelRef(props, emit);
    const selectedBusiness = ref();
    const state = reactive({
      abn: "",
      currentStage: "home",
      docId: null,
      loading: false,
      error: "",
      manualName: undefined,
    });
    async function selectBusiness(item) {
      // console.log(item);
      state.abn = item.abn;
      state.loading = true;
      const count = await countDocsWithCertainValue(
        dbPaths.entities,
        "abn",
        item.abn
      );
      // console.log(count);
      if (count > 0) {
        state.error = `${item.name} [ABN ${item.abn}] already exists.`;
        state.currentStage = "error";
      } else {
        state.currentStage = "details";
      }
      state.loading = false;
    }
    async function submit(data) {
      // const biz = selectedBusiness.value;
      // data.name = biz.name;
      // data.abn = biz.abn;
      // data.registration = biz;
      // console.log(data);
      const doc = await newFirestoreDoc(dbPaths.entities, data);
      // console.log(doc);
      state.docId = doc.id;
      state.currentStage = "final";
      data.id = doc.id;
      // console.log(data);
      emit("added", data);
    }
    function back2home() {
      state.currentStage = "home";
      state.manualName = undefined;
      state.abn = undefined;
      selectedBusiness.value = undefined;
    }
    function done() {
      back2home();
      isOpen.value = false;
    }
    return {
      selectedBusiness,
      selectBusiness,
      state,
      submit,
      back2home,
      dbPaths,
      isOpen,
      done,
    };
  },
};
</script>

<style scoped></style>
