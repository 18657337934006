<template>
  <div class="space-x-4 select-none">
    <system-user-avatar
      v-for="id in selectableIds"
      :key="id"
      :user-id="id"
      @click="select(id)"
      class="user-avatar"
      :class="{
        'selected-avatar': isSelected(id),
        'disabled-avatar': disabledIds && disabledIds.indexOf(id) >= 0,
        'pointer-events-none': notSelectable(id),
      }"
      :ring-thicker="isSelected(id)"
      ring-primary
      title
    />
  </div>
</template>

<script>
import formControl from "@/hive-vue3/components/form/logics/formControl";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import { useSystemUsers } from "@/hive-vue3/firebase";
import { watchEffect, ref } from "vue";
import SystemUserAvatar from "@/hive-vue3/modules/users/SystemUserAvatar";
import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";

export default {
  name: "MfcSystemUserSelector",
  components: { SystemUserAvatar },
  props: {
    ...commonFormInputProps,
    multiple: Boolean,
    roles: Array,
    disabledIds: Array,
  },
  setup(props, context) {
    const users = useSystemUsers();
    const control = formControl(props, context, {});
    const model = control.model;
    const selectableIds = ref();
    watchEffect(() => {
      const arr = [];
      for (let id in users) {
        const user = users[id];
        // console.log(props.roles);
        if (props.roles && props.roles.length) {
          const roles = user.roles;
          if (roles.some((r) => props.roles.includes(r))) {
            arr.push(id);
          }
        } else {
          if (user.roles.indexOf("maintenance") === -1) {
            arr.push(id);
          }
        }
      }
      selectableIds.value = arr;
    });
    //to remove disabled ids from the values
    watchEffect(() => {
      if (props.disabledIds && props.multiple && model.value) {
        arrayRemove(model.value, ...props.disabledIds);
      }
      if (!props.multiple) {
        if (props.disabledIds && props.disabledIds.indexOf(model.value) >= 0) {
          model.value = undefined;
        }
      }
    });
    function isSelected(id) {
      if (props.multiple) {
        if (model.value) {
          return model.value.indexOf(id) >= 0;
        }
        return false;
      } else {
        return model.value === id;
      }
    }
    function notSelectable(id) {
      if (!props.mandatory) return false;
      if (props.multiple) {
        if (model.value && model.value.length === 1 && model.value[0] === id) {
          return true;
        } else {
          return false;
        }
      } else {
        // console.log(model.value === id);
        return model.value === id;
      }
    }
    function select(id) {
      if (!props.multiple) {
        if (model.value === id) {
          model.value = undefined;
        } else {
          model.value = id;
        }
      } else {
        if (!model.value) {
          model.value = [id];
        } else {
          const index = model.value.indexOf(id);
          if (index >= 0) {
            //if multiple, must select at least one
            // if (model.value.length === 1) {
            //   return;
            // }
            model.value.splice(index, 1);
            if (!model.value.length) model.value = undefined;
          } else {
            model.value.push(id);
          }
        }
      }
      // console.log(model.value);
    }
    return {
      selectableIds,
      isSelected,
      notSelectable,
      model,
      select,
    };
  },
};
</script>

<style scoped>
.user-avatar {
  cursor: pointer;
  opacity: 0.3;
}
.disabled-avatar {
  opacity: 0.05;
  pointer-events: none;
}
.selected-avatar {
  opacity: 1;
}
</style>
