<template>
  <hi-form-hive-standard
    submit-without-change
    cancel-button
    :submit-fn="submit"
  >
    <div>
      <div class="tp-big-fancy-display mb-5">
        {{
          getQuotationIdStr(quotationData.quoteNum, quotationData.revisionId)
        }}
      </div>
      <div>{{ itemLength }} items in total: ${{ total }}.</div>

      <div class="flex gap-4">
        <div>Send To:</div>
        <div>
          <div
            v-for="(people, index) in sendTo"
            :key="people + index"
            class="flex gap-4 items-center"
          >
            <contacts-people :people-id="people" email class="grow" />
            <hi-icon :path="mdiCloseCircle" @click="listRemove(index)" />
          </div>
        </div>
      </div>

      <div class="mt-5 mb-2">Add another recipient:</div>
      <mfc-input-contacts-people no-form v-model="others" />
    </div>
  </hi-form-hive-standard>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { computed, ref } from "vue";
import { quotationState } from "@/hive-vue3/modules/quotation/state";
import {
  getQuotationByIdAndRevisionId,
  getQuotationIdStr,
  getTotal,
  quotationStatusSend,
} from "@/hive-vue3/modules/quotation/data";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiCloseCircle } from "@mdi/js";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import {
  getContactsEntityNameById,
  getContactsEntityTradingNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import {
  getProjectById,
  getProjectPipeIdById,
} from "@/hive-vue3/modules/projects/data";
import {
  requestQuotationPDF,
  requestQuotationPDFNoBackground,
  requestSendQuotationEmail,
} from "@/hive-vue3/api/request";
import {
  addNewActivity,
  DefaultActivityData,
} from "@/hive-vue3/modules/activities/data";
import hive from "@/hive-vue3/components/hive";
import { getDateFromTsOrDate } from "@/hive-vue3/firebase";
export default {
  name: "quotationSendForm",
  components: {
    MfcInputContactsPeople,
    HiIcon,
    ContactsPeople,
    HiFormHiveStandard,
  },
  setup() {
    const sendTo = ref([]);
    const others = ref(undefined);

    const quotationData = computed(() => {
      return getQuotationByIdAndRevisionId(
        quotationState.currentQuotationId,
        quotationState.revisionId
      );
    });

    watchPropsImmediately(quotationData, "value", (v) => {
      if (v && v.attention) {
        sendTo.value = [v.attention];
      }
    });
    watchPropsImmediately(others, "value", (v) => {
      if (v) {
        sendTo.value.push(v);
      }
    });

    const itemLength = computed(() => {
      if (quotationData.value) {
        return quotationData.value.items.length;
      }
      return 0;
    });

    async function submit() {
      const formData = quotationData.value;
      if (formData) {
        const formHeader = {};
        if (formData.legalName) {
          formHeader.client = getContactsEntityNameById(formData.client);
        } else {
          formHeader.client = getContactsEntityTradingNameById(formData.client);
        }

        const getProjectAddress = () => {
          const project = getProjectById(formData.projectId);

          if (project.isNameOfficial) {
            return `${project.name}\n${project.address.formatted}`;
          }
          return `${project.address.formatted}`;
        };

        const getEmailProjectAddress = () => {
          const project = getProjectById(formData.projectId);

          if (project.isNameOfficial) {
            return `${project.name}, ${project.address.formatted}`;
          }
          return `${project.address.formatted}`;
        };

        formHeader.projectAddress = getProjectAddress();

        formHeader.attention = getContactsFullNameById(formData.attention);
        formHeader.quoteNum = formData.quoteNum;

        const formBody = {};
        formBody.dateCreated = getDateFromTsOrDate(formData.dateCreated);
        formBody.validUntil = getDateFromTsOrDate(formData.validUntil);
        formBody.depositRate = formData.depositRate;
        formBody.items = formData.items;
        formBody.revisionId = formData.revisionId;
        formBody.depositCondition = formData.depositCondition;
        console.log({ ...formHeader, ...formBody });

        // request pdf md5
        const resQuotation = await requestQuotationPDF({
          ...formHeader,
          ...formBody,
        });
        const resQuotationNoBackground = await requestQuotationPDFNoBackground({
          ...formHeader,
          ...formBody,
        });

        if (resQuotation.successful && resQuotationNoBackground.successful) {
          // console.log(resQuotation.message);

          const activityData = new DefaultActivityData();

          activityData.title = "Quotation Send";
          activityData.flags.hasFile = true;
          activityData.action = "Send Quotation";
          activityData.target = "project";
          activityData.targetId = formData.projectId;
          activityData.comments = `Send Quotation to ${sendTo.value
            .map((v) => {
              return getContactsFullNameById(v);
            })
            .join(", ")}.`;
          activityData.actionTime = new Date();
          activityData.pipeId = getProjectPipeIdById(activityData.targetId);
          activityData.var = {
            revisionId: formBody.revisionId,
            files: [resQuotation.message, resQuotationNoBackground.message],
            fileNoBackground: resQuotationNoBackground.message,
          };
          activityData.contacts = sendTo.value;

          const fireRes = await addNewActivity(activityData);

          // send email to contact

          const activityId = fireRes.id;

          // console.log(sendTo.value);

          for (const i in sendTo.value) {
            await requestSendQuotationEmail({
              activityId,
              sendTo: sendTo.value[i],
              projectAddress: getEmailProjectAddress(),
              quotationNum: getQuotationIdStr(
                formHeader.quoteNum,
                formBody.revisionId
              ),
            });
          }
          quotationStatusSend(
            quotationState.currentQuotationId,
            formBody.revisionId
          );
          return { successful: true };
        } else {
          hive.toastError(
            resQuotation.errorMsg || resQuotationNoBackground.errorMsg
          );
        }
      } else {
        hive.toastError("No Quotation Found");
      }
    }

    function listRemove(index) {
      sendTo.value.splice(index, 1);
    }

    return {
      submit,
      quotationData,
      total: computed(() => {
        if (quotationData.value) {
          return getTotal(quotationData.value);
        }
        return 0;
      }),
      itemLength,
      getQuotationIdStr,
      mdiCloseCircle,
      sendTo,
      others,
      listRemove,
    };
  },
};
</script>

<style scoped></style>
