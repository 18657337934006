import { onMounted, onUnmounted, reactive } from "vue";
import $el from "../dom/$el";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

/**
 * keyMap can also take any key shortcuts as below formats(Key is taken from event.key).
 * ctrl[Key] - combination with control key. e.g. 'ctrlA','ctrl1'
 * key[Key] - single key pressed. e.g. 'keyA','key1'
 * @param element
 * @param event
 * @param focusOnly
 * @param preventDefault
 * @param enabled
 * @returns {{keyMap: {ctrlUp: undefined, shiftUp: undefined, shiftEnter: undefined, right: undefined, down: undefined, ctrlDown: undefined, shiftLeft: undefined, shiftRight: undefined, shiftDown: undefined, ctrlRight: undefined, left: undefined, ctrlLeft: undefined, enter: undefined, up: undefined, ctrlEnter: undefined, escape: undefined}, keyControls: UnwrapNestedRefs<{preventDefault: boolean, enabled: boolean}>}}
 */
export default function shortcuts({
  element = document,
  event = "keydown",
  // focusOnly = false,
  preventDefault = false,
  enabled = true,
} = {}) {
  const map = {
    enter: undefined,
    escape: undefined,
    ctrlEnter: undefined,
    shiftEnter: undefined,
    left: undefined,
    right: undefined,
    up: undefined,
    down: undefined,
    ctrlLeft: undefined,
    ctrlRight: undefined,
    ctrlUp: undefined,
    ctrlDown: undefined,
    shiftLeft: undefined,
    shiftRight: undefined,
    shiftUp: undefined,
    shiftDown: undefined,
    tab: undefined,
  };
  const controls = reactive({
    preventDefault,
    enabled,
  });

  function run(func, e) {
    if (controls.preventDefault) e.preventDefault();
    if (typeof func === "function") func(e);
  }
  function ctrl(e) {
    return e.ctrlKey || e.metaKey;
  }
  function onPress(e) {
    // console.log(e);
    const code = e.keyCode;
    // enter ////////////////////
    if (code === 13 && (map.enter || map.ctrlEnter || map.shiftEnter)) {
      if (map.ctrlEnter && ctrl(e)) {
        run(map.ctrlEnter, e);
      } else if (map.shiftEnter && e.shiftKey) {
        run(map.shiftEnter, e);
      } else if (map.enter) {
        run(map.enter, e);
      }
      // escape ///////////////
    } else if (code === 27 && map.escape) {
      run(map.escape, e);
      // left //////////////////////
    } else if (code === 37 && (map.left || map.ctrlLeft || map.shiftLeft)) {
      if (map.ctrlLeft && ctrl(e)) {
        run(map.ctrlLeft, e);
      } else if (map.shiftLeft && e.shiftKey) {
        run(map.shiftLeft, e);
      } else if (map.left) {
        run(map.left, e);
      }
      // up //////////////////////
    } else if (code === 38 && (map.up || map.ctrlUp || map.shiftUp)) {
      if (map.ctrlUp && ctrl(e)) {
        run(map.ctrlUp, e);
      } else if (map.shiftUp && e.shiftKey) {
        run(map.shiftUp, e);
      } else if (map.up) {
        run(map.up, e);
      }
      // right //////////////////////
    } else if (code === 39 && (map.right || map.ctrlRight || map.shiftRight)) {
      if (map.ctrlRight && ctrl(e)) {
        run(map.ctrlRight, e);
      } else if (map.shiftRight && e.shiftKey) {
        run(map.shiftRight, e);
      } else if (map.right) {
        run(map.right, e);
      }
      // down //////////////////////
    } else if (code === 40 && (map.down || map.ctrlDown || map.shiftDown)) {
      if (map.ctrlDown && ctrl(e)) {
        run(map.ctrlDown, e);
      } else if (map.shiftDown && e.shiftKey) {
        run(map.shiftDown, e);
      } else if (map.down) {
        run(map.down, e);
      }
      // all others
    } else if (code === 9 && map.tab) {
      run(map.tab, e);
    } else {
      const key = e.key.toUpperCase();
      const ctrlFn = map["ctrl" + key];
      const keyFn = map["key" + key];
      if (ctrl(e) && ctrlFn) {
        run(ctrlFn, e);
      } else if (keyFn) {
        run(keyFn, e);
      }
    }
  }

  function listen() {
    // console.log("listen", element);
    $el(element).addEventListener(event, onPress);
  }
  function unListen() {
    const el = $el(element);
    el && el.removeEventListener(event, onPress);
  }
  onMounted(() => {
    // element = $el(element);
    // if (focusOnly) {
    //   element.addEventListener("focus", listen);
    //   element.addEventListener("blur", unListen);
    // } else {
    //   listen();
    // }
    // console.log(element);
    watchPropsImmediately(controls, "enabled", (v) => {
      if (v) {
        listen();
      } else {
        unListen();
      }
    });
  });

  onUnmounted(unListen);
  return {
    keyMap: map,
    keyControls: controls,
  };
}
