<template>
  <input-base
    ref="input"
    class="hi-input"
    v-model="model"
    :disabled="disabled"
    :placeholder="placeholder"
    :autoFocus="autoFocus"
    :large="large"
    @blur="onBlur"
    @input="onInput"
    :suffixIcon="suffixIcon"
    @suffix-click="onSuffixClick"
  />
</template>

<script>
import { computed } from "vue";
import InputBase from "@/hive-vue3/components/form/controls/base/InputBase";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { lazyFunction } from "@/hive-vue3/utils/functionUtils";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
export default {
  name: "HiSearchInput",
  props: {
    placeholder: String,
    modelValue: String,
    disabled: Boolean,
    autoFocus: Boolean,
    realtime: Boolean,
    realtimeDelayMs: {
      type: Number,
      default: 500,
    },
    large: Boolean,
  },
  components: { InputBase },
  emits: ["search", "update:modelValue"],
  setup(props, { emit }) {
    const model = modelRef(props, emit);

    let lastValue;
    function emitSearchEvent() {
      // console.log(model.value);
      const val = model.value && model.value.trim();
      // if (!val || !val.length) return;
      if (val === lastValue) return;
      lastValue = val;
      // console.log(val);
      emit("search", val);
    }
    const lazyEvent = lazyFunction(emitSearchEvent, props.realtimeDelayMs);
    function onInput() {
      if (props.realtime) {
        lazyEvent();
      }
    }
    function onBlur() {
      emitSearchEvent();
    }
    function hasSearchValue() {
      const val = model.value && model.value.trim();
      return !!(val && val.length);
    }
    function onSuffixClick() {
      if (props.realtime) model.value = undefined;

      emitSearchEvent();
    }
    const suffixIcon = computed(() => {
      if (props.realtime && hasSearchValue()) return commonIcons.clear;
      return commonIcons.search;
    });

    return {
      model,
      onInput,
      onBlur,
      onSuffixClick,
      suffixIcon,
    };
  },
};
</script>
