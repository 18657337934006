import { ref } from "vue";
import { getAuth } from "firebase/auth";
import { encodeDocId } from "@/hive-vue3/firebase";
import {
  requestNewAccessToken,
  requestSetGmailToken,
} from "@/hive-vue3/api/request";
import hive from "@/hive-vue3/components/hive";
import watchFirestoreDoc from "@/hive-vue3/firebase/watchFirestoreDoc";
import loadScript from "@/hive-vue3/utils/dom/loadScript";
import apiKeys from "@/conf/apiKeys";
import { getCurrentDomain } from "@/hive-vue3/utils/devUrlResolver";

export async function initGapi() {
  const SCOPES = "https://mail.google.com/";
  try {
    await loadScript("https://accounts.google.com/gsi/client");
  } catch (e) {
    console.log(e);
  }
  return window.google.accounts.oauth2.initCodeClient({
    client_id: apiKeys.google.clientId,
    scope: SCOPES,
    ux_mode: "popup",
    callback: async (res) => {
      res.redirectUri = getCurrentDomain();
      const setAuth = await requestSetGmailToken(res);
      if (setAuth.successful) {
        return true;
      } else {
        await hive.alert(setAuth.errorMsg);
        return false;
      }
    },
  });
}
export async function getGoogleAuth2Client() {
  await initGapi();
  // 从后台获取Token授权
  const accessToken = (await requestNewAccessToken()).data.token;
  await window.gapi.client.setToken({ access_token: accessToken });
}

export async function grantGoogleOfflineAccess() {
  const client = await initGapi();
  client.requestCode();
}

const isGoogleAuthed = ref(false);
export function useGoogleAuthed() {
  watchFirestoreDoc(
    `/system/users/gmailAuth/`,
    encodeDocId(getAuth().currentUser.email),
    (doc) => {
      isGoogleAuthed.value = !!(doc && doc.tokens);
    }
  );
  return isGoogleAuthed;
}
