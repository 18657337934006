import {
  watchProps,
  watchPropsImmediately,
} from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { useElementSize } from "@vueuse/core/index";
import {
  effectScope,
  inject,
  isRef,
  onScopeDispose,
  provide,
  reactive,
  watch,
} from "vue";

function filter(item, filters) {
  const f = (key, filter) => {
    const itemVal = item[key];
    if (Array.isArray(itemVal)) return itemVal.indexOf(filter) >= 0;
    else return itemVal === filter;
  };
  for (let key in filters) {
    const filterVal = filters[key];
    // console.log(filterVal, item[key]);
    if (filterVal === "noFilter" || filterVal === undefined) {
      return true;
    }
    if (Array.isArray(filterVal)) {
      for (let i = 0; i < filterVal.length; i++) {
        if (f(key, filterVal[i])) return true;
      }
    } else {
      return f(key, filterVal);
    }
  }
  return false;
}

const getTime = (date) => {
  if (!date) return null;
  if (date.milliseconds) return date.milliseconds;
  if (date.seconds) return date.seconds;
  if (date.getTime) return date.getTime();
};

/**
 * sortTypes: date,time,number,alpha(default)
 * @param source
 * @param settings{{itemsPerPage}}}
 * @param source
 * @param settings
 * @returns {{scope: EffectScope, state: {totalItems: number, currentPageItems: *[], searchFn: *, autoItemHeight: number, filters: {}, loading: boolean, sortType: string, ready: boolean, itemsPerPage: (number|*), totalPages: number, noResult: boolean, from: number, noData: boolean, sortBy: null, to: number, currentPage: number, currentItemId: null, searchValue: null, searchables: (string[]|*[]|*|*[]), desc: boolean}, actions: {gotoPage: gotoPage, search: (function(*): *), defaultSortBy: defaultSortBy, updateFilters: updateFilters, nextPage: nextPage, setCurrentItem: setCurrentItem, prevPage: prevPage, initDataSourceIfNotInitialised(*): void, sortBy: sortBy, autoItemsPerPage: autoItemsPerPage}}}
 */
export const createDataListStore = (source, settings = {}) => {
  // console.log(settings);
  const store = reactive({
    source: source,
    // filters: {},
    // filters: settings.filters || {},
    // searchables: settings.searchables || [],
    // searchFn: settings.searchFn,
  });

  const state = reactive({
    from: 0,
    to: 0,
    totalPages: 0,
    totalItems: 0,
    currentPageItems: [],
    loading: true,
    ready: false,
    noData: false,
    noResult: false,
    itemsPerPage: settings.itemsPerPage || 0, //zero means display all
    autoItemHeight: 0,
    currentPage: 1,
    sortBy: null,
    sortType: "alpha",
    desc: false,
    currentItemId: null,
    searchValue: null,
    filters: {},
    searchables: [],
    searchFn: null,
  });
  const results = reactive({
    desc: [],
    asc: [],
  });
  ///////////////////// start scope ///////////////////////////////////
  const scope = effectScope(true);
  scope.run(() => {
    onScopeDispose(() => {
      console.log("scope disposed.");
    });

    watchPropsImmediately(store, "source", (v) => {
      if (typeof v !== "undefined") {
        state.ready = true;
        state.loading = false;
        // console.log(v.length, returns.noData);
        if (!v.length) state.noData = true;
        else state.noData = false;
      } else {
        state.ready = false;
        state.loading = true;
      }
      // console.log(returns.loading);
      calcResults();
    });

    watchPropsImmediately(state, ["filters", "searchables"], () => {
      calcResults();
    });
    watch(
      () => state.filters,
      () => {
        calcResults();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    watchProps(state, ["searchValue", "sortBy", "sortType", "desc"], () => {
      calcResults();
    });
    watchPropsImmediately(state, ["currentPage", "itemsPerPage"], () => {
      // console.log("currentPage changed", v);
      calcTotalPages();
      calcCurrentPageItems();
    });
  });

  //////////////////////// end scope //////////////////////////////
  /**
   *
   * @param elementRef  ref
   * @param minHeight   Number
   * @param initialValue   String
   */
  const autoItemsPerPage = (elementRef, minHeight) => {
    const { height } = useElementSize(elementRef);

    // console.log(elementRef);
    watch(height, (v) => {
      // console.log("height", v);
      const items = Math.floor(v / minHeight);
      // itemHeightRef.value = v / items;
      // console.log(v / items);
      state.itemsPerPage = items;
      state.autoItemHeight = v / items;
      // console.log("minHeight", minHeight);
      // console.log(items);
      // console.log("auto height", returns.autoItemHeight);
    });
  };
  const gotoPage = (page) => {
    // console.log("goto", page);
    state.currentPage = page;
  };
  const prevPage = () => {
    if (state.currentPage > 0) state.currentPage--;
  };
  const nextPage = () => {
    if (state.currentPage < state.totalPages) state.currentPage++;
  };

  const updateFilters = (filter, val) => {
    state.filters[filter] = val;
  };

  /**
   *
   * @param column
   * @param desc
   * @param type "alpha"|"number"|"date"|"time"
   */
  const sortBy = (column, desc = false, type = "alpha") => {
    state.sortBy = column;
    state.desc = desc;
    state.sortType = type;
  };

  const defaultSortBy = (column, desc = false, type = "alpha") => {
    if (state.sortBy) return;
    state.sortBy = column;
    state.desc = desc;
    state.sortType = type;
  };

  function doSearch(item) {
    const search = state.searchValue.toLowerCase();
    if (state.searchFn) {
      // console.log("use searchFn");
      const result = state.searchFn(search, item);
      return !!result;
    }
    const searchables = state.searchables;
    for (let i = 0; i < searchables.length; i++) {
      const key = searchables[i];

      let value = item[key];
      if (value && typeof value === "string") {
        value = value.toLowerCase();
      }
      if (value && value.indexOf(search) >= 0) {
        return true;
      }
    }
    return false;
  }
  function calcTotalPages() {
    if (state.totalItems === 0 || state.itemsPerPage === 0) {
      state.totalPages = 1;
    } else {
      state.totalPages = Math.ceil(state.totalItems / state.itemsPerPage);
    }
    //防止当前页大于总页数。itemsPerPage 改变的时候会出现。
    if (state.currentPage > state.totalPages)
      state.currentPage = state.totalPages;
  }
  function calcResults() {
    // console.log("calcResults searchValue", state.searchValue);
    // console.log("calcResults", returns.ready, returns.noData);
    if (!state.ready || state.noData) return null;
    // console.log("re-calc results....");
    const hasFilters = Object.keys(state.filters).length > 0;
    // console.log(Object.keys(state.filters));
    let items = [];
    let source = store.source;
    if (isRef(source)) source = source.value;
    if (hasFilters || (state.searchValue && state.searchValue.length)) {
      //do filter & search
      for (let i = 0; i < source.length; i++) {
        const item = source[i];
        // console.log(filter(item, state.filters));
        if (hasFilters && !filter(item, state.filters)) {
          continue;
        }
        if (state.searchValue && state.searchValue.length && !doSearch(item)) {
          // console.log("search no", item.name);
          continue;
        }
        items.push(item);
      }
      if (!items.length) {
        // console.log("no result");
        results.desc = results.asc = [];
        state.noResult = true;
        state.totalItems = 0;
        return null;
      }
    } else {
      items = source || [];
    }

    if (state.sortBy) {
      //do sort
      const sort = state.sortBy;
      // const desc = state.desc ? -1 : 1;
      const type = state.sortType.toLowerCase();
      // console.log("sort by '" + returns.sortBy + "', type:" + type);

      items.sort((a, b) => {
        a = a[sort];
        b = b[sort];
        if (type === "date" || type === "time") {
          return getTime(a) - getTime(b);
        } else {
          if (typeof a === "string") a = a.trim();
          if (typeof b === "string") b = b.trim();
          if (type === "number") {
            return a - b;
          } else {
            if (!a) {
              return -1;
            }
            if (!a.localeCompare) {
              return -1;
            }
            return a.localeCompare(b, "en", { sensitivity: "base" });
          }
        }
      });
    }
    results.asc = items;
    results.desc = [...items].reverse();

    state.noResult = results.asc.length === 0;
    state.totalItems = items.length;
    calcTotalPages();

    calcCurrentPageItems();
  }

  function calcCurrentPageItems() {
    if (!results.asc.length) {
      state.currentPageItems = [];
    } else {
      const re = state.desc ? results.desc : results.asc;
      // console.log("after sort", re);
      if (state.itemsPerPage === 0) state.currentPageItems = re;
      else {
        const from = (state.currentPage - 1) * state.itemsPerPage;
        const to = from + state.itemsPerPage;
        // console.log(re);
        state.currentPageItems = re.slice(from, to);
      }
    }
    state.from =
      state.totalItems === 0
        ? 0
        : (state.currentPage - 1) * state.itemsPerPage + 1;
    const t = state.from + state.currentPageItems.length - 1;
    state.to = t > state.totalItems ? state.totalItems : t;
  }

  async function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const setCurrentItemId = (itemId) => {
    // todo: Optimise Highlight
    // eslint-disable-next-line no-async-promise-executor
    const waitForInit = new Promise(async (resolve, reject) => {
      let i = 0;
      while (i < 100) {
        const itemIndex = getItemIndex(itemId);
        if (state.itemsPerPage && itemIndex !== -1) {
          resolve(itemIndex);
        }
        i = i + 1;
        await sleep(30);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      reject();
    });
    waitForInit
      .then((itemIndex) => {
        state.currentPage = Math.floor(itemIndex / state.itemsPerPage) + 1;
        state.currentItemId = itemId;
      })
      .catch(() => {});

    // if (itemIndex !== -1) {
    //   state.currentPage = Math.floor(itemIndex / state.itemsPerPage) + 1;
    // }
    // console.log(
    //   state.itemsPerPage,
    //   state.currentPage,
    //   itemIndex,
    //   state.itemsPerPage
    // );
  };

  function getItemIndex(itemId) {
    if (state.desc) {
      for (let i = 0; i < results.desc.length; i++) {
        if (results.desc[i].id === itemId) {
          return i;
        }
      }
      return -1;
    } else {
      for (let i = 0; i < results.asc.length; i++) {
        if (results.asc[i].id === itemId) {
          return i;
        }
      }
      return -1;
    }
  }

  // const { currentPage, displayItems, totalItems, totalPages, from, to } =
  //   toRefs(computes);
  return {
    state,
    actions: {
      gotoPage,
      prevPage,
      nextPage,

      initDataSourceIfNotInitialised(data) {
        // console.log("init data");
        if (!store.source) store.source = data;
      },
      updateFilters,
      sortBy,
      search: (v) => (state.searchValue = v),
      autoItemsPerPage,
      setCurrentItemId,
      defaultSortBy,
    },
    scope,
  };
};

export const provideDataListStore = (listStore) => {
  provide("hi-list-store", listStore);
  return listStore;
};
export function injectDataListStore() {
  const data = inject("hi-list-store", undefined);
  if (data === undefined) {
    console.warn("listStore not provided!");
  }
  return data;
}
///globals
const storeDepot = {};
export function globalDataListStore(token, source, settings = {}) {
  if (!storeDepot[token]) {
    storeDepot[token] = createDataListStore(source, settings);
  }
  return storeDepot[token];
}

// const collectionSourceDepot = {};
// const collectionStoreDepot = {};
// export function globalDataListStoreByFirestoreCollection(
//   collectionPath,
//   settings
// ) {
//   const token = "fs-collection/" + collectionPath;
//   if (!collectionSourceDepot[token]) {
//     const source = useFirestoreCollectionAsRef(collectionPath, {
//       persistData: true,
//     });
//     const store = createDataListStore(source, settings);
//     collectionSourceDepot[token] = store;
//   }
//   return collectionSourceDepot[token];
// }
