<template>
  <hi-card>
    <div class="p-4 flex flex-col gap-2">
      <div class="flex gap-4">
        <div class="w-[180px]">Active Projects:</div>
        <div class="w-[40px]">{{ activeNumber }}</div>
        <div>${{ activeAmount.toLocaleString() }}</div>
      </div>
      <div class="flex gap-4">
        <div class="w-[180px]">Deposit Paid Projects:</div>
        <div class="w-[40px]">{{ depositPaidNum }}</div>
        <div>${{ depositPaidAmount.toLocaleString() }}</div>
      </div>
      <div class="flex gap-4">
        <div class="w-[180px]">Win Projects:</div>
        <div class="w-[40px]">{{ winNum }}</div>
        <div>${{ winAmount.toLocaleString() }}</div>
      </div>
      <div class="flex gap-4">
        <div class="w-[180px]">Lost Projects:</div>
        <div class="w-[40px]">{{ lostNum }}</div>
        <div>${{ lostAmount.toLocaleString() }}</div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { computed } from "vue";
import {
  getActiveProjects,
  getDepositPaidProjects,
  getLostProjects,
  getProjectTotalAmountByProjectArray,
  getWinProjects,
} from "@/hive-vue3/modules/projects/data";
export default {
  name: "BasicProjectNumCard",
  components: { HiCard },
  setup() {
    const activeNumber = computed(() => {
      const res = getActiveProjects();
      return res.length;
    });

    const depositPaidNum = computed(() => {
      const res = getDepositPaidProjects();
      return res.length;
    });

    const winNum = computed(() => {
      const res = getWinProjects();
      return res.length;
    });

    const lostNum = computed(() => {
      const res = getLostProjects();
      return res.length;
    });

    const activeAmount = computed(() => {
      const projects = getActiveProjects();
      return getProjectTotalAmountByProjectArray(projects);
    });
    const depositPaidAmount = computed(() => {
      const projects = getDepositPaidProjects();
      return getProjectTotalAmountByProjectArray(projects);
    });
    const winAmount = computed(() => {
      const projects = getWinProjects();
      return getProjectTotalAmountByProjectArray(projects);
    });

    const lostAmount = computed(() => {
      const projects = getLostProjects();
      return getProjectTotalAmountByProjectArray(projects);
    });
    return {
      activeNumber,
      activeAmount,
      depositPaidNum,
      winNum,
      lostNum,
      depositPaidAmount,
      winAmount,
      lostAmount,
    };
  },
};
</script>

<style scoped></style>
