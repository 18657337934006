<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard :submit-fn="submit" @successful="open = false">
      <hi-form-item label="Send Name">
        <hi-input form-key="sendName" :validator="verifySendName" mandatory />
      </hi-form-item>
      <hi-form-item label="Reply To">
        <hi-input email form-key="replyTo" mandatory />
      </hi-form-item>
      <hi-form-item label="Send Address">
        <hi-input
          mandatory
          form-key="sendAddr"
          suffix-text="@artimber.au"
          :validator="
            (v) => {
              if (v.indexOf('@') === -1) {
                return true;
              }
              return 'Invalid Send Address';
            }
          "
        />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { requestEmailVerification } from "@/hive-vue3/api/request";
import useFirestoreCollection from "@/hive-vue3/firebase/useFirestoreCollection";
import { newFirestoreDoc } from "@/hive-vue3/firebase";
export default {
  name: "EdmAddNewSenderDialog",
  components: { HiInput, HiFormItem, HiFormHiveStandard, HiDialog },
  setup() {
    const open = ref(false);
    const senderCollection = useFirestoreCollection("/edm/assets/senders");

    function verifySendName(v) {
      if (v && senderCollection && senderCollection.data) {
        const senders = senderCollection.data;
        for (const i of senders) {
          if (v.toLowerCase() === i.sendName.toLowerCase()) {
            return "Send Name Already Exists.";
          }
        }
        return true;
      }
      return true;
    }

    async function submit(data) {
      const res = await requestEmailVerification(data.replyTo);
      if (!res.successful) {
        return {
          successful: false,
          errorMessage: "Reply To: " + res.errorMsg,
        };
      }
      data.sendAddr = data.sendAddr + "@artimber.au";

      await newFirestoreDoc("/edm/assets/senders", data);

      return {
        successful: true,
      };
    }
    return {
      open,
      submit,
      verifySendName,
    };
  },
};
</script>

<style scoped></style>
