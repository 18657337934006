<template>
  <div>
    <div v-if="authLoaded && !loggedIn" class="text-center">
      <div class="mb-3"><slot /></div>
      <hi-button
        primary
        outline
        @click="googleLoginPopup"
        v-if="google"
        class="inline-flex space-x-4 items-center px-4"
        style="font-size: large; letter-spacing: 0.02em"
      >
        <svg style="width: 1em; height: 1em" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
          />
        </svg>

        <span>Sign in with Google</span>
      </hi-button>
      <div>{{ loginError }}</div>
    </div>
    <div v-else-if="authLoaded">
      <button @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
import {
  googleLoginPopup,
  logout,
  useAuthLoaded,
  useLoggedIn,
  useLoginError,
} from "@/hive-vue3/firebase";
import HiButton from "@/hive-vue3/components/HiButton";
import { mdiGoogle } from "@mdi/js";

export default {
  name: "LoginBasic",
  components: { HiButton },
  props: {
    google: Boolean,
  },
  setup() {
    const loginError = useLoginError();
    const icons = {
      google: mdiGoogle,
    };
    return {
      loggedIn: useLoggedIn(),
      authLoaded: useAuthLoaded(),
      googleLoginPopup,
      logout,
      loginError,
      icons,
    };
  },
};
</script>
