import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import {
  firestoreDoc,
  getCurrentFirebaseUserId,
  newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import { arrayUnion } from "firebase/firestore";
import { computed } from "vue";
import { useQuotationsByProjectId } from "@/hive-vue3/modules/quotation/data";

import {
  getContactsEntityNameById,
  getContactsFullNameById,
  getContactsPeople,
} from "@/hive-vue3/modules/contacts/data";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";

const dbPaths = {
  project: "projects",
  projectSpecs: "projectSpecs",
};

const cache = {
  projects: undefined,
  winProjects: undefined,
  activeProjects: undefined,
  loseProjects: undefined,
  products: undefined,
};
const store = {
  winProjects: undefined,
  activeProjects: undefined,
  loseProjects: undefined,
};

function initProjects() {
  if (!cache.projects) {
    cache.projects = useFirestoreCollectionAsRefGlobally("/projects");
    cache.products = useFirestoreCollectionAsRefGlobally("/projectSpecs");
  }
}

export const provideWinProjectsListStore = () => {
  initProjects();
  store.winProjects = createDataListStore(
    computed(() => {
      // console.log("computed running...");
      const returnArr = [];
      if (cache.projects.value) {
        for (const i of cache.projects.value) {
          if (i.projStatus === "Closed Won") {
            returnArr.push(i);
          }
        }
      }
      return returnArr;
    })
  );

  return provideDataListStore(store.winProjects);
};

export function getProjectsByMainOwnerId(ownerId) {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.mainOwner === ownerId) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export const getActiveProjects = () => {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.projStatus !== "Closed Lost" && i.projStatus !== "Closed Won") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
};

export const getDepositPaidProjects = () => {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.projStatus === "Deposit Paid") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
};
export const getWinProjects = () => {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.projStatus === "Closed Won") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
};
export const getLostProjects = () => {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.projStatus === "Closed Lost") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
};

export const getProjectStakeHoldersEmailListById = (projectId) => {
  initProjects();
  const project = getProjectById(projectId);

  const returnArr = [];
  if (project.contacts && project.contacts.length) {
    for (const i of project.contacts) {
      const people = getContactsPeople(i.peopleId);
      returnArr.push(people.email);
    }
  }
  return returnArr.length ? returnArr : "";
};

export const getProjectsByAccountId = (accountId) => {
  initProjects();
  const returnArr = [];
  if (cache.projects && cache.projects.value) {
    for (const i of cache.projects.value) {
      if (i.accountId === accountId) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
};

export const provideLoseProjectsListStore = () => {
  initProjects();

  store.loseProjects = createDataListStore(
    computed(() => {
      const returnArr = [];
      if (cache.projects.value) {
        for (const i of cache.projects.value) {
          if (i.projStatus === "Closed Lost") {
            returnArr.push(i);
          }
        }
      }
      return returnArr;
    })
  );

  return provideDataListStore(store.loseProjects);
};

export async function AddNewProjectToFirebase(projectData) {
  return await newFirestoreDoc("/projects", projectData);
}

export function getProjectById(projectId) {
  initProjects();
  const projects = cache.projects;
  if (projects.value && projects.value.length) {
    for (let i = 0; i < projects.value.length; i++) {
      const p = projects.value[i];
      if (p.id === projectId) return p;
    }
  }
}

export function getProjectNameById(projectId) {
  initProjects();
  const projects = cache.projects;
  if (projects.value && projects.value.length) {
    for (let i = 0; i < projects.value.length; i++) {
      const p = projects.value[i];
      if (p.id === projectId) return p.name;
    }
  }
}

export function getProjectPipeIdById(projectId) {
  initProjects();
  const projects = cache.projects;
  if (projects.value && projects.value.length) {
    for (let i = 0; i < projects.value.length; i++) {
      const p = projects.value[i];
      if (p.id === projectId) return p.pipeId;
    }
  }
}

export function useProjectsWithFullAddress() {
  initProjects();
  return computed(() => {
    if (cache.projects && cache.projects.value) {
      const projects = [...cache.projects.value];
      if (projects && projects.length) {
        for (let i = 0; i < projects.length; i++) {
          if (projects[i] && projects[i].address) {
            projects[i].formatted = projects[i].address.formatted;
          }
        }
      }
      return projects;
    } else {
      return [];
    }
  });
}

export async function addProjectContacts(projectId, contactData) {
  contactData.addDate = new Date();
  contactData.addBy = getCurrentFirebaseUserId();
  await updateFirestoreDoc(firestoreDoc(dbPaths.project, projectId), {
    contacts: arrayUnion(contactData),
  });
}

export async function updateProjectDetails(projectId, projectData) {
  await updateFirestoreDoc(
    firestoreDoc(dbPaths.project, projectId),
    projectData
  );
}

export async function removeContactFromProjectByIndex(projectId, index) {
  const project = getProjectById(projectId);
  const contacts = JSON.parse(JSON.stringify(project.contacts));
  const contact = contacts[index];

  const historyObj = new DefaultHistoryData();
  historyObj.action = historyActions.REMOVE;
  historyObj.target = "project";
  historyObj.targetId = projectId;

  historyObj.comments = `Remove stakeholder: ${
    contact.peopleId
      ? getContactsFullNameById(contact.peopleId) + "(Contact)"
      : ""
  } ${
    contact.entityId
      ? getContactsEntityNameById(contact.entityId) + "(Entity)"
      : ""
  }`;

  await addNewHistory(historyObj);

  contacts.splice(index, 1);
  await updateFirestoreDoc(firestoreDoc(dbPaths.project, projectId), {
    contacts,
  });
}

const quotationCache = {};
const quotationStore = {};

export function provideQuotationListStore(projectId) {
  if (!quotationCache.projectId) {
    const source = useQuotationsByProjectId(projectId);
    // console.log(source);
    const listStore = createDataListStore(source);
    quotationCache[projectId] = source;
    quotationStore[projectId] = listStore;
    return provideDataListStore(listStore);
  }
  // initProjects();
  // return provideDataListStore(store.projects);
}

export function addProductToProject(projectId, productData) {
  productData.pipeId = getProjectPipeIdById(projectId);
  productData.isCustom = false;
  productData.isActive = true;
  newFirestoreDoc(dbPaths.projectSpecs, productData).catch((e) => {
    console.error("addProductToProject Error. ", e);
  });

  // updateFirestoreDoc(firestoreDoc(dbPaths.project, projectId), {
  //   products: arrayUnion(productData),
  // }).catch((e) => {
  //   console.error("addProductToProject Error. ", e);
  // });
}

// Products
export function getProjectProductByPipeId(pipeId, isActive) {
  initProjects();
  const returnArr = [];
  if (cache.products && cache.products.value) {
    for (const i of cache.products.value) {
      if (i.isActive === isActive && i.pipeId === pipeId) {
        returnArr.push(i);
      }
    }
  }

  returnArr.sort((a, b) => {
    a = a["__createdBy"];
    b = b["__createdBy"];
    return getTime(b) - getTime(a);
  });

  return returnArr;
}

const getTime = (date) => {
  if (!date) return null;
  if (date.milliseconds) return date.milliseconds;
  if (date.seconds) return date.seconds;
  if (date.getTime) return date.getTime();
};

export function projectListSearchFn(search, project) {
  const address = project.address.formatted.toLowerCase();
  const name = project.name.toLowerCase();
  const type = project.projType;
  const status = project.projStatus.toLowerCase();
  const account = getAccountById(project.accountId);
  let accountName = "";
  if (account && account.name) {
    accountName = account.name.toLowerCase();
  }
  return (
    address.indexOf(search) >= 0 ||
    name.indexOf(search) >= 0 ||
    type.indexOf(search) >= 0 ||
    status.indexOf(search) >= 0 ||
    accountName.indexOf(search) >= 0
  );
}

export function jumpToProjectById(router, projectId) {
  router.push("/projects/" + projectId);
}
export function getProjectTotalAmountByProjectArray(projectArr) {
  if (projectArr && projectArr.length) {
    let returnValue = 0;
    for (const i of projectArr) {
      if (i.estimateAmount) {
        returnValue += i.estimateAmount;
      }
    }
    return returnValue;
  }
  return 0;
}

export const projectSortRules = [
  {
    key: "__dateCreated",
    text: "Date",
    type: dataListSortingTypes.DATE,
    desc: true,
    DEFAULT: true,
  },
  {
    key: "name",
    text: "Name",
    type: dataListSortingTypes.ALPHA,
    desc: false,
    DEFAULT: false,
  },
  {
    key: "projStatus",
    text: "Status",
    type: dataListSortingTypes.Number,
    desc: true,
    DEFAULT: false,
  },
];
