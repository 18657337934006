<template>
  <hi-autocomplete
    :items="projectsInput"
    :search-keys="['name', 'formatted']"
    placeholder="Input project name or address..."
    item-value-key="id"
    :format-display-fn="formatDisplay"
    v-bind="$props"
    v-model="model"
    :add-new-dialog-component="ProjectNewProjectDialog"
    :form-key="formKey"
  >
    <template v-slot="{ item }">
      <project-summary :project-data="item" />
    </template>
  </hi-autocomplete>
</template>

<script>
import HiAutocomplete from "@/hive-vue3/components/form/controls/HiAutocomplete";
import { useProjectsWithFullAddress } from "@/hive-vue3/modules/projects/data";
import ProjectNewProjectDialog from "@/hive-vue3/modules/projects/ProjectNewProjectDialog";
import ProjectSummary from "@/hive-vue3/modules/projects/ProjectSummary";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
export default {
  name: "MfcProjectSelector",
  components: { ProjectSummary, HiAutocomplete },
  props: {
    formKey: String,
    modelValue: String,
    ...commonFormInputProps,
  },
  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;

    const projectsInput = useProjectsWithFullAddress();

    function formatDisplay(item) {
      return `${item.name}  <span class='pl-1 tp-description-sm'>[ ${item.formatted}]</span> `;
    }

    return {
      projectsInput,
      formatDisplay,
      ProjectNewProjectDialog,
      model,
    };
  },
};
</script>

<style scoped></style>
