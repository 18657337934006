<template>
  <hi-dialog title="Add A New Event" close-button v-model="open" width="600px">
    <hi-form-hive-standard
      cancel-button
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      :initial-values="
        initialValues || {
          time: new Date(),
        }
      "
    >
      <hi-form-item label="Title *">
        <hi-input mandatory form-key="title" />
      </hi-form-item>
      <hi-form-item label="Time *">
        <hi-date-picker mandatory form-key="time" />
      </hi-form-item>
      <hi-form-item label="Comments *">
        <hi-textarea mandatory form-key="comments" />
      </hi-form-item>
      <hi-form-item label="Relative Contacts *">
        <mfc-input-contacts-people-multiple mandatory form-key="contacts" />
      </hi-form-item>

      <hi-form-item label="Relative System User">
        <div class="flex flex-col">
          <user-details-span
            v-for="(user, index) in users"
            :key="user + index"
            show-email
            :user-id="user"
          />
        </div>
      </hi-form-item>

      <hi-form-item label="">
        <mfc-system-user-selector multiple form-key="users" v-model="users" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { ref } from "vue";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import MfcInputContactsPeopleMultiple from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeopleMultiple";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import {
  addNewActivity,
  DefaultActivityData,
} from "@/hive-vue3/modules/activities/data";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
export default {
  name: "ActivityNewEventDialog",
  components: {
    HiInput,
    MfcSystemUserSelector,
    UserDetailsSpan,
    MfcInputContactsPeopleMultiple,
    HiTextarea,
    HiFormItem,
    HiDatePicker,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    target: String,
    targetId: String,
    pipeId: String,
    initialValues: Object,
    mainOwner: String,
  },
  setup(props) {
    const open = ref(false);
    const users = ref([]);

    watchPropsImmediately(open, "value", (v) => {
      if (v) {
        users.value = [];
      }
    });

    async function submit(formData) {
      const activityData = new DefaultActivityData();
      activityData.target = props.target;
      activityData.targetId = props.targetId;
      activityData.pipeId = props.pipeId;
      activityData.action = "Event";
      activityData.title = formData.title;
      activityData.actionTime = formData.time;
      activityData.comments = formData.comments;
      activityData.contacts = formData.contacts;
      activityData.users = formData.users || [];
      if (props.mainOwner) {
        if (activityData.users.indexOf(props.mainOwner) === -1) {
          activityData.users.push(props.mainOwner);
        }
      }

      await addNewActivity(activityData);
      return { successful: true };
    }

    return {
      open,
      submit,
      users,
    };
  },
};
</script>

<style scoped></style>
