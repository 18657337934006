<template>
  <layout-three-column
    module-name="contacts"
    :module-section="state.currentTab"
    :page-name="name"
    @backToSummary="
      state.currentTab === 'people'
        ? (state.selectedPeopleId = undefined)
        : (state.selectedEntityId = undefined)
    "
  >
    <template v-slot:col1>
      <hi-card class="h-full w-full">
        <hi-div flex-col-full-height-to-support-grow>
          <div class="bg-light-400 p-4 flex items-center">
            <div class="tp-title-faded">Contacts</div>
            <div class="grow"></div>
            <new-contacts-people-dialog text-button smallButton />
            <new-contacts-entity-dialog text-button smallButton />
          </div>
          <div class="grow">
            <hi-tab-group class="h-full" v-model="state.currentTab">
              <hi-tab class="h-full" label="people">
                <contacts-people-list class="h-full" @select="onSelectPeople" />
              </hi-tab>
              <hi-tab class="h-full" label="entity">
                <contacts-entity-list class="h-full" @select="onSelectEntity" />
              </hi-tab>
            </hi-tab-group>
          </div>
        </hi-div>
      </hi-card>
    </template>
    <template
      v-slot:col2
      v-if="state.selectedEntityId || state.selectedPeopleId"
    >
      <!--      <hi-card-->
      <!--        :style="{-->
      <!--          opacity:-->
      <!--            (state.currentTab === 'people' && state.selectedPeopleId !== null) ||-->
      <!--            (state.currentTab === 'entities' && state.selectedEntityId !== null)-->
      <!--              ? 1-->
      <!--              : 0.2,-->
      <!--        }"-->
      <!--        class="h-full w-full"-->
      <!--      >-->

      <transition-fade class="h-full" faster>
        <contacts-people-details
          v-if="state.currentTab === 'people' && state.selectedPeopleId"
          :people-id="state.selectedPeopleId"
        />
        <contacts-entity-details
          v-else-if="state.currentTab === 'entity' && state.selectedEntityId"
          :entity-id="state.selectedEntityId"
        />
      </transition-fade>
      <!--      </hi-card>-->
    </template>
    <template
      v-slot:col3
      v-if="
        (state.currentTab === 'people' && state.selectedPeopleId) ||
        (state.currentTab === 'entity' && state.selectedEntityId)
      "
    >
      <hi-tab-group class="h-full">
        <hi-tab label="emails">
          <gmail-relative-mails-common-viewer
            :email="email"
            class="h-full overflow-auto"
          />
        </hi-tab>
        <hi-tab label="Enquiry">
          <people-enquiries-list />
        </hi-tab>
        <hi-tab
          label="Accounts"
          v-if="state.currentTab === 'people' && state.selectedPeopleId"
        >
          <people-accounts-list />
        </hi-tab>
      </hi-tab-group>
    </template>
  </layout-three-column>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import ContactsEntityList from "@/hive-vue3/modules/contacts/ContactsEntityList";
import ContactsPeopleList from "@/hive-vue3/modules/contacts/ContactsPeopleList";
import { contactsState } from "./state";
import HiDiv from "@/hive-vue3/components/HiDiv";
import NewContactsPeopleDialog from "@/hive-vue3/modules/contacts/ContactsNewPeopleDialog";
import NewContactsEntityDialog from "@/hive-vue3/modules/contacts/ContactsNewEntityDialog";
import ContactsPeopleDetails from "@/hive-vue3/modules/contacts/ContactsPeopleDetails";
import ContactsEntityDetails from "@/hive-vue3/modules/contacts/ContactsEntityDetails";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import { computed, ref, watch } from "vue";
import {
  formatContactsPeopleName,
  getContactsEntity,
  getContactsPeople,
} from "@/hive-vue3/modules/contacts/data";
import { useRouter } from "vue-router";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import GmailRelativeMailsCommonViewer from "@/hive-vue3/modules/gmail/components/GmailRelativeMailsCommonViewer";
import PeopleEnquiriesList from "@/hive-vue3/modules/contacts/PeopleEnquiriesList";
import PeopleAccountsList from "@/hive-vue3/modules/contacts/PeopleAccountsList";
export default {
  name: "ContactsViewFullKit",
  components: {
    PeopleAccountsList,
    PeopleEnquiriesList,
    GmailRelativeMailsCommonViewer,
    LayoutThreeColumn,
    TransitionFade,
    ContactsEntityDetails,
    ContactsPeopleDetails,
    NewContactsEntityDialog,
    NewContactsPeopleDialog,
    HiDiv,
    ContactsPeopleList,
    ContactsEntityList,
    HiTab,
    HiTabGroup,
    HiCard,
  },
  props: {
    urlType: String,
    urlId: String,
  },
  setup(props) {
    const state = contactsState;
    const peopleDetails = ref({});
    function onSelectPeople(people) {
      state.selectedPeopleId = people.id;
      peopleDetails.value = people;
    }
    function onSelectEntity(entity) {
      state.selectedEntityId = entity.id;
    }

    const router = useRouter();

    watchPropsImmediately(props, "urlId", (v) => {
      if (v) {
        state.currentTab = props.urlType;
        if (props.urlType === "people") {
          state.selectedPeopleId = props.urlId;
        }
        if (props.urlType === "entity") {
          state.selectedEntityId = props.urlId;
        }
      } else {
        if (state.currentTab === "people" && state.selectedPeopleId) {
          router.replace("/contacts/people/" + state.selectedPeopleId);
        } else if (state.currentTab === "entity" && state.selectedEntityId) {
          router.replace("/contacts/entity/" + state.selectedEntityId);
        } else {
          router.replace("/contacts/" + state.currentTab);
        }
      }
    });

    watch(
      () => state.selectedPeopleId,
      (v) => {
        if (v) {
          router.push("/contacts/people/" + v);
        } else {
          router.push("/contacts/people");
        }
      }
    );
    watch(
      () => state.selectedEntityId,
      (v) => {
        if (v) {
          router.push("/contacts/entity/" + v);
        } else {
          router.push("/contacts/entity");
        }
      }
    );

    watch(
      () => state.currentTab,
      (v) => {
        if (v === "people") {
          if (state.selectedPeopleId) {
            router.push("/contacts/people/" + state.selectedPeopleId);
          } else {
            router.push("/contacts/people");
          }
        }
        if (v === "entity") {
          if (state.selectedEntityId) {
            router.push("/contacts/entity/" + state.selectedEntityId);
          } else {
            router.push("/contacts/entity");
          }
        }
      }
    );

    return {
      state,
      onSelectPeople,
      onSelectEntity,
      email: computed(() => {
        if (state.currentTab === "people") {
          if (state.selectedPeopleId) {
            const people = getContactsPeople(state.selectedPeopleId);
            if (people && people.email) {
              return people.email;
            }
          }
          return "";
        } else {
          if (state.selectedEntityId) {
            const entity = getContactsEntity(state.selectedEntityId);
            if (entity && entity.email) {
              return entity.email;
            }
          }
          return "";
        }
      }),
      name: computed(() => {
        if (state.currentTab === "people") {
          if (state.selectedPeopleId) {
            const people = getContactsPeople(state.selectedPeopleId);
            if (people) {
              return formatContactsPeopleName(people);
            }
          }
          return undefined;
        } else {
          if (state.selectedEntityId) {
            const entity = getContactsEntity(state.selectedEntityId);
            if (entity && entity.name) {
              return entity.name;
            }
          }
          return undefined;
        }
      }),
    };
  },
};
</script>

<style scoped></style>
