<template>
  <hi-dialog-button
    close-button
    v-model="dialogState"
    :button-text="isActive ? 'Re-Active' : 'Obsolete'"
    title="Confirm obsolete"
    width="600px"
  >
    <hi-loading-mask :model-value="submitting" />
    <div class="mb-10" v-if="isActive">
      Are you sure to re-active this product?
    </div>
    <div class="mb-10" v-else>Are you sure to obsolete this product?</div>
    <product-info-view :product="product" class="mb-10" />
    <div class="flex justify-between">
      <hi-button @click="cancel">cancel</hi-button>
      <hi-button primary @click="confirm">confirm</hi-button>
    </div>
  </hi-dialog-button>
</template>

<script>
import HiDialogButton from "@/hive-vue3/components/dialogs/HiDialogButton";
import { ref } from "vue";
import HiButton from "@/hive-vue3/components/HiButton";
import ProductInfoView from "@/hive-vue3/modules/products/productsInfoView";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import HiLoadingMask from "@/hive-vue3/components/overlays/HiLoadingMask";
export default {
  name: "DialogConfirmObsolete",
  components: { HiLoadingMask, ProductInfoView, HiButton, HiDialogButton },
  props: {
    product: Object,
    isActive: Boolean,
  },
  setup(props) {
    const dialogState = ref(false);
    const submitting = ref(false);

    async function confirm() {
      submitting.value = true;
      // console.log(props.product.id);
      if (props.isActive) {
        await updateFirestoreDoc(
          firestoreDoc("/projectSpecs", props.product.id),
          {
            isActive: true,
          }
        );
      } else {
        await updateFirestoreDoc(
          firestoreDoc("/projectSpecs", props.product.id),
          {
            isActive: false,
          }
        );
      }

      dialogState.value = false;
    }
    function cancel() {
      dialogState.value = false;
    }
    return {
      dialogState,
      cancel,
      confirm,
      submitting,
    };
  },
};
</script>

<style scoped></style>
