<template>
  <div class="flex items-center gap-2">
    <div class="small-label">{{ label }}:</div>
    <transition-fade fast>
      <div
        class="flex items-center gap-2 pointer-events-none"
        v-if="!editing && !editMode"
      >
        <span class="na" v-if="!model || !model.length">{{ emptyValue }}</span>
        <hi-chip v-if="!multiple && model" selected>
          <span v-if="model">{{ tags[model] }}</span>
        </hi-chip>
        <div v-if="multiple" class="flex flex-wrap gap-2">
          <hi-chip v-for="tag in model" :key="tag" selected>
            {{ tags[tag] }}
          </hi-chip>
        </div>
        <hi-icon
          v-if="canEdit"
          :path="mdiSquareEditOutline"
          class="clickable-faded pointer-events-auto"
          @click="edit"
        />
      </div>

      <hi-chips-selectable
        no-form
        v-else-if="editMode || editing"
        :items="items"
        :doc-path="docPath"
        :collection-path="collectionPath"
        remove-private-items
        v-model="model"
        :multiple="multiple"
        @change="onChange"
        @tag-adding-started="adding = true"
        @tag-adding-canceled="adding = false"
        can-add
      >
        <!--    add new tags ============================-->
        <!--        if docPath isn't provided, cannot add new tags-->
        <!--        <hi-chip functional v-if="docPath && !adding"-->
        <!--          ><span-->
        <!--            @click="adding = true"-->
        <!--            class="text-xs text-center w-[50px] inline-block"-->
        <!--            >Add New</span-->
        <!--          ></hi-chip-->
        <!--        >-->
        <!--        <hi-chip v-else-if="adding" editable :style="editStyles">-->
        <!--          <hi-simple-inline-input-->
        <!--            class="min-w-[50px]"-->
        <!--            :submit-fn="submitNewTag"-->
        <!--            :cancel-fn="cancelAdding"-->
        <!--          />-->
        <!--        </hi-chip>-->
      </hi-chips-selectable>
    </transition-fade>
  </div>
</template>

<script>
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
// import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import HiChip from "@/hive-vue3/components/HiChip";
// import {
//   encodeDocId,
//   firestoreDoc,
//   updateFirestoreDoc,
// } from "@/hive-vue3/firebase";
// import hive from "@/hive-vue3/components/hive";
import { computed, ref } from "vue";
// import fireDocHelper from "@/hive-vue3/components/fireDoc/logics/fireDocHelper";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiSquareEditOutline } from "@mdi/js";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import { lazyFunction } from "@/hive-vue3/utils/functionUtils";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";

/**
 * A Tagger editor can take either Object/Array or a firestore doc as selectable items.
 * When doc is provided as source items, adding new tags will automatically be enabled but deleting tags are not supported here.
 */
export default {
  name: "HiTagger",
  components: {
    TransitionFade,
    HiIcon,
    HiChip,
    HiChipsSelectable,
  },
  props: {
    modelValue: [String, Array],
    collectionPath: String,
    docPath: {
      type: String,
    },
    /**
     * the tag items. if items specified, docPath & collectionPath will be ignored & will not be able to add new tags.
     */
    items: {
      type: [Array, Object],
    },

    multiple: Boolean,
    label: {
      type: String,
      default: "Tags",
    },
    emptyValue: {
      type: String,
      default: "N/A",
    },
    canEdit: Boolean,
    editMode: Boolean,
  },
  setup(props, { emit }) {
    const model = modelRef(props, emit);
    if (props.docPath && props.items) {
      console.warn(
        `HiTagger: props.items has been specified. props.docPath ${props.docPath} has been ignored!`
      );
    }
    const adding = ref(false);
    //the style for inline editor
    // const editStyles = reactive({ opacity: 1 });
    const editing = ref(false);

    let doc;
    if (props.docPath) {
      if (props.collectionPath) {
        doc = useFirestoreDoc(props.collectionPath, props.docPath);
      } else {
        doc = useFirestoreDoc(props.docPath);
      }
    }
    //
    // function initStyle() {
    //   editStyles.opacity = 1;
    // }
    // async function submitNewTag(text) {
    //   editStyles.opacity = 0.5;
    //   const key = encodeDocId(text);
    //   if (doc[key]) {
    //     hive.toastError(`Tag '${text}' already exists!`);
    //     initStyle();
    //     return;
    //   }
    //   const data = {};
    //   data[key] = text;
    //   await updateFirestoreDoc(docRef, data);
    //
    //   if (props.multiple) {
    //     if (model.value && model.value.push) {
    //       model.value.push(key);
    //     } else {
    //       model.value = [key];
    //     }
    //
    //     lazySwitchOffEditing();
    //   } else {
    //     model.value = key;
    //
    //     editing.value = false;
    //   }
    //   adding.value = false;
    //   initStyle();
    // }
    // function cancelAdding() {
    //   initStyle();
    //   lazySwitchOffEditing();
    //   adding.value = false;
    // }

    const lazySwitchOffEditing = lazyFunction(() => {
      if (adding.value) return;
      editing.value = false;
    }, 4000);

    function edit() {
      editing.value = true;
      lazySwitchOffEditing();
    }
    function onChange() {
      adding.value = false;
      if (!props.multiple) {
        editing.value = false;
      } else {
        console.log("onchange");
        lazySwitchOffEditing();
      }
    }

    return {
      tags: computed(() => props.items || doc),
      // submitNewTag,
      // cancelAdding,
      adding,
      editing,
      // editStyles,
      mdiSquareEditOutline,
      onChange,
      edit,
      model,
    };
  },
};
</script>

<style scoped></style>
