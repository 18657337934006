<template>
  <div class="select-none">
    <div class="relative hi-input" :class="{ error: error, focus: hasFocus }">
      <transition-fade :opacity="0.5">
        <div
          class="absolute inset-0 flex items-start z-1 pointer-events-none hi-placeholder opacity-50 hi-control-padding max-w-full overflow-hidden"
          v-if="displayPlaceholder"
        >
          {{ displayPlaceholder }}
        </div>
      </transition-fade>

      <textarea
        v-model="model"
        class="w-full hi-control-padding bg-transparent block"
        :class="{ 'auto-height': autoHeight }"
        @focus="focus"
        @blur="blur"
        ref="textarea"
        :rows="rows"
        @input="autoHeightFn"
      />
    </div>

    <div class="flex">
      <hi-form-error class="grow" :model-value="error" />
      <div><slot name="extra-info" /></div>
    </div>
  </div>
</template>

<script>
import formControl from "../logics/formControl";
import HiFormError from "../HiFormError";
import { computed, onMounted, ref } from "vue";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import { templateRef } from "@vueuse/core";
import { lazyFunction } from "@/hive-vue3/utils/functionUtils";

export default {
  name: "HiTextarea",
  components: { TransitionFade, HiFormError },
  props: {
    noForm: Boolean,
    modelValue: String,
    validator: Function,
    formKey: String,
    mandatory: Boolean,
    placeholder: String,
    autoHeight: Boolean,
    /**
     * Rows of the textarea. Also act as min height when it's autoHeight.
     * It's actually numbers only. String is for convince for static props.
     */
    rows: {
      type: [Number, String],
      default: 2,
    },
  },
  emits: ["focus", "blur", "update:modelValue"],
  setup(props, context) {
    let isMounted = false;
    const control = formControl(props, context, {
      validator: props.validator,
      onModelValueUpdate() {
        // console.log(control.model.value);
        if (!isMounted || !props.autoHeight) return;
        autoHeightFn();
      },
    });
    const hasFocus = ref(false);
    function focus() {
      context.emit("focus");
      hasFocus.value = true;
    }
    function blur() {
      control.touch();
      context.emit("blur");
      hasFocus.value = false;
    }
    const displayPlaceholder = computed(() => {
      const val = control.model.value;
      if (val && val.length) return null;
      return props.placeholder;
    });
    const textarea = templateRef("textarea");

    const autoHeightFn = lazyFunction(() => {
      if (!props.autoHeight) return;
      // console.log(textarea.value);
      textarea.value.style.height = "1px";
      let height = textarea.value.scrollHeight;
      // console.log("scrollHeight", height);
      if (height < minHeight) height = minHeight;
      textarea.value.style.height = height + "px";
      //transition cannot be supported here for transition actually effects scrollHeight, unless we have a shadow textarea somewhere else to calc height.
      // textarea.value.style.transition = "height 0.25s ease-in";
    }, 10);

    let minHeight = 0;
    onMounted(() => {
      // console.log(textarea.value.offsetHeight);
      //before we do autoHeight adjust, the original height is the minHeight
      minHeight = textarea.value.offsetHeight;
      isMounted = true;
      if (props.autoHeight) {
        autoHeightFn();
      }
    });
    return {
      model: control.model,
      error: control.error,
      focus,
      blur,
      displayPlaceholder,
      autoHeightFn,
      hasFocus,
    };
  },
};
</script>

<style scoped>
.auto-height {
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
}
</style>
