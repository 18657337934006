<template>
  <hi-form-hive-standard
    class="min-w-[600px]"
    :submit-fn="submit"
    :initial-values="initialValues"
  >
    <hi-form-item label="Project Name">
      <hi-input form-key="name" mandatory />
    </hi-form-item>

    <hi-form-item label="Is Name Official">
      <hi-chips-selectable
        :items="{
          Y: 'Yes',
          N: 'No',
        }"
        mandatory
        form-key="isNameOfficial"
      />
    </hi-form-item>
    <hi-form-item label="Estimate Amount">
      <hi-input form-key="estimateAmount" finance />
    </hi-form-item>
    <hi-form-item label="Project Address">
      <hi-input-address mandatory form-key="address" />
    </hi-form-item>
    <hi-form-item label="Project Type">
      <hi-select
        mandatory
        :items="[
          { value: 'residential', text: 'Residential' },
          { value: 'townhouse', text: 'Townhouse' },
          { value: 'boutique', text: 'Boutique' },
          { value: 'multi-res', text: 'Multi-Res' },
          { value: 'hospitality', text: 'Hospitality' },
          { value: 'workplaceFitout', text: 'Workplace Fitout' },
          { value: 'retail', text: 'Retail' },
          { value: 'education', text: 'Education' },
          { value: 'hotel', text: 'Hotel' },
          { value: 'health', text: 'Health' },
          { value: 'government', text: 'Government' },
          { value: 'other', text: 'Other' },
        ]"
        form-key="projType"
      />
    </hi-form-item>
    <hi-form-item label="Main Owner">
      <div class="flex flex-col gap-2">
        <user-details-span show-email :user-id="mainOwner" />
        <mfc-system-user-selector form-key="mainOwner" v-model="mainOwner" />
      </div>
    </hi-form-item>

    <hi-form-item label="Sub Owners">
      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <user-details-span
            v-for="(user, index) in subOwners"
            :key="user + index"
            show-email
            :user-id="user"
          />
        </div>
        <mfc-system-user-selector
          multiple
          :disabled-ids="[mainOwner]"
          form-key="subOwners"
          v-model="subOwners"
        />
      </div>
    </hi-form-item>
  </hi-form-hive-standard>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import { updateProjectDetails } from "@/hive-vue3/modules/projects/data";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import { computed, ref } from "vue";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import { useSystemUsers } from "@/hive-vue3/firebase";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { arrayUnique } from "@/hive-vue3/modules/utils/utils";
import moment from "moment";
import { requestActivityNotification } from "@/hive-vue3/api/request";

export default {
  name: "ProjectBasicInfoEditor",
  components: {
    MfcSystemUserSelector,
    UserDetailsSpan,
    HiSelect,
    HiInputAddress,
    HiChipsSelectable,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
  },
  props: {
    projectData: Object,
  },
  emits: ["successful", "cancel"],
  setup(props, { emit }) {
    const mainOwner = ref(undefined);
    const subOwners = ref(undefined);

    const initialValues = computed(() => {
      if (
        props.projectData &&
        props.projectData.estimateSupplyDate &&
        props.projectData.estimateSupplyDate.toDate
      ) {
        const returnObj = {};
        Object.assign(returnObj, props.projectData);

        returnObj.estimateSupplyDate = {};
        const estimateSupplyDate =
          props.projectData.estimateSupplyDate.toDate();
        returnObj.estimateSupplyDate.month = estimateSupplyDate.getMonth();
        returnObj.estimateSupplyDate.year = estimateSupplyDate.getFullYear();
        return returnObj;
      }
      return props.projectData;
    });

    async function submit(projectData) {
      if (!projectData.subOwners) {
        projectData.subOwners = [];
      }
      if (!projectData.mainOwner) {
        projectData.mainOwner = null;
      }

      if (
        projectData.mainOwner !== initialValues.value.mainOwner ||
        JSON.stringify(projectData.subOwners) !==
          JSON.stringify(initialValues.value.subOwners)
      ) {
        const systemUsers = useSystemUsers();
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.ASSIGN;
        historyObj.target = "project";
        historyObj.targetId = projectsState.currentProjectId;
        historyObj.comments = `Assign owners: ${
          projectData.mainOwner
            ? systemUsers[projectData.mainOwner].name
            : "N/A"
        } (Main), ${
          projectData.subOwners && projectData.subOwners.length
            ? projectData.subOwners.map((v) => systemUsers[v].name).join(", ")
            : ""
        }`;

        await addNewHistory(historyObj);

        let initialSubOwners = initialValues.value.subOwners;
        let newSubOwners = projectData.subOwners;
        if (!initialSubOwners) {
          initialSubOwners = [];
        }
        if (!newSubOwners) {
          newSubOwners = [];
        }
        const users = arrayUnique([
          initialValues.value.mainOwner,
          ...initialSubOwners,
          projectData.mainOwner,
          ...newSubOwners,
        ]);

        const m = moment(new Date());
        const timeStr = m.format("DD MMM HH:mm");
        console.log(users);
        requestActivityNotification({
          users,
          smallTitle: "Owner Changes in Project",
          mainTitle: initialValues.value.name,
          timeStr,
          message: historyObj.comments,
          directLink:
            "https://my.artimber.au/projects/" + projectsState.currentProjectId,
          breadcrumbs: [
            {
              href: "https://my.artimber.au/",
              text: "My Artimber",
            },
            {
              href: "https://my.artimber.au/" + "projects/",
              text: "projects",
            },
            {
              href:
                "https://my.artimber.au/projects/" +
                projectsState.currentProjectId,
              text: initialValues.value.name,
            },
          ],
        }).then();
      }

      if (projectData.estimateAmount !== initialValues.value.estimateAmount) {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.ADD;
        historyObj.target = "project";
        historyObj.targetId = projectsState.currentProjectId;
        historyObj.comments = `Add estimate amount: $${projectData.estimateAmount}`;

        await addNewHistory(historyObj);
      }

      projectData.isNameOfficial = projectData.isNameOfficial === "Y";
      await updateProjectDetails(projectsState.currentProjectId, projectData);
      emit("successful");
      return { successful: true };
    }
    return {
      submit,
      initialValues,
      subOwners,
      mainOwner,
    };
  },
};
</script>

<style scoped></style>
