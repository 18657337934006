<template>
  <hi-form :initial-values="initialValues" @submit="submit">
    <hi-form-item label="Start">
      <hi-date-picker mandatory time-only form-key="start" />
    </hi-form-item>
    <hi-form-item label="end">
      <hi-date-picker mandatory time-only form-key="end" />
    </hi-form-item>
  </hi-form>
</template>

<script>
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
export default {
  name: "TwilioWorkingTimeEditor",
  components: { HiDatePicker, HiFormItem, HiForm },
  props: {
    initialValues: Object,
  },
  setup() {
    function submit(data) {
      updateFirestoreDoc(firestoreDoc("phone", "settings"), {
        [twilioState.currentNumber]: {
          workingHours: {
            start:
              data.start.hours.toString().padStart(2, "0") +
              data.start.minutes.toString().padStart(2, "0"),
            end:
              data.end.hours.toString().padStart(2, "0") +
              data.end.minutes.toString().padStart(2, "0"),
          },
        },
      });
    }
    return {
      submit,
    };
  },
};
</script>

<style scoped></style>
