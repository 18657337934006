import {
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowUp,
  mdiCellphone,
  mdiClose,
  mdiCurrencyUsd,
  mdiEmail,
  mdiMagnify,
  mdiPhone,
  mdiPlus,
  mdiSquareEditOutline,
} from "@mdi/js";

export default {
  edit: mdiSquareEditOutline,
  add: mdiPlus,
  mobile: mdiCellphone,
  dollar: mdiCurrencyUsd,
  phone: mdiPhone,
  email: mdiEmail,
  clear: mdiClose,
  search: mdiMagnify,
  up: mdiArrowUp,
  down: mdiArrowDown,
  back: mdiArrowLeft,
};
