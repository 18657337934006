<template>
  <div>
    <router-link :to="link" class="nav-item" v-if="link">
      <div class="flex items-center">
        <center-box class="nav-icon"><hi-icon :path="icon" /> </center-box>
        <transition-fade-css>
          <div v-if="expended" class="nav-text"><slot /></div>
        </transition-fade-css>
      </div>
    </router-link>
    <div class="nav-item" v-else>
      <div class="flex items-center">
        <center-box class="nav-icon"><hi-icon :path="icon" /> </center-box>
        <transition-fade-css>
          <div v-if="expended" class="nav-text"><slot /></div>
        </transition-fade-css>
      </div>
    </div>
  </div>
</template>

<script>
import CenterBox from "@/hive-vue3/layouts/CenterBox";
// import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import { inject } from "vue";
import HiIcon from "@/hive-vue3/components/HiIcon";
import TransitionFadeCss from "@/hive-vue3/transitions/TransitionFadeCss";
export default {
  name: "HiNavDrawerItem",
  components: { TransitionFadeCss, HiIcon, CenterBox },
  props: {
    icon: String,
    link: {
      type: [String, Object],
    },
  },
  setup() {
    const expended = inject("menu-expended");
    return {
      expended,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "nav";
</style>
