<template>
  <hi-fire-doc
    collection-path="enquiries"
    :doc-id="enquiryId"
    class="flex flex-col gap-2"
    v-if="enquiryId"
  >
    <template v-slot="{ docData }">
      <div class="w-full h-full flex flex-col gap-2">
        <hi-card class="w-full">
          <enquiry-title :doc-data="docData" />
        </hi-card>
        <!--       ================ contact linking ===================-->
        <layout-title-card
          class="w-full"
          :title="`Contact (${docData.peopleId ? 'linked' : 'Please Link'})`"
        >
          <div v-if="docData.peopleId">
            <div class="view-block flex">
              <contacts-people-summary
                class="grow"
                v-if="docData.entityId"
                :people-id="docData.peopleId"
                :entity-id="docData.entityId"
              >
              </contacts-people-summary>
              <contacts-people-summary
                class="grow"
                v-else
                :people-id="docData.peopleId"
                hide-entity
              >
              </contacts-people-summary>
              <hi-button
                :disabled="docData.status !== 'open' || !docData.peopleId"
                text
                @click="
                  unLinkContact(docData.peopleId, enquiryId, docData.entityId)
                "
                >UnLink</hi-button
              >
            </div>
          </div>
          <div
            v-else
            :data-var="
              (matched = getMatchedContacts(docData.email, docData.phoneNum))
            "
            class="view-space-divided"
          >
            <div class="tp-description text-center view-block">
              <div v-if="matched && matched.length > 0">Matched Contacts</div>
              <div v-else>No matching contact...</div>
            </div>

            <div
              class="view-block flex"
              v-for="people in matched"
              :key="people.id"
            >
              <contacts-people-summary
                :people-data="people"
                hide-entity
                class="grow"
              />
              <hi-dialog-trigger>
                <hi-button text :disabled="docData.status === 'close'"
                  >Link</hi-button
                >
                <enquiry-link-contact-dialog :contact-id="people.id" />
              </hi-dialog-trigger>
            </div>
            <div class="view-block-spacious">
              <div class="tp-description text-center pb-2">
                Search contacts in database
              </div>
              <div class="flex space-x-1 items-center">
                <mfc-input-contacts-people
                  class="grow"
                  v-model="state.searchContact"
                />
                <hi-dialog-trigger>
                  <transition-fade-css>
                    <div v-if="state.searchContact">
                      <hi-button text>Link</hi-button>
                      <enquiry-link-contact-dialog
                        :contact-id="state.searchContact"
                      />
                    </div>
                  </transition-fade-css>
                </hi-dialog-trigger>
              </div>
            </div>
          </div>
        </layout-title-card>

        <activity-tools-card
          target="enquiry"
          :target-id="enquiryId"
          :pipe-id="docData.pipeId"
        />
        <!--      ================ toolbox ===================-->
        <hi-card class="w-full view-block-spacious">
          <hi-button
            text
            :disabled="docData.status !== 'open' || !docData.peopleId"
            @click="state.convertDialog = true"
            >Close & Convert to Leads</hi-button
          >
          <convert-enquiry-to-leads-dialog v-model="state.convertDialog" />

          <hi-dialog-trigger v-if="docData.status === 'open'">
            <hi-button text> Close Enquiry </hi-button>
            <enquiry-close-dialog />
          </hi-dialog-trigger>
          <hi-button
            v-else
            text
            @click="openEnquiry(docData.id)"
            :disabled="docData.isConverted"
            >Reopen Enquiry</hi-button
          >
        </hi-card>
        <!--      ================details===============-->
        <layout-title-card
          title="Original Enquiry Data"
          class="w-full grow h-0"
        >
          <div class="view-space-divided">
            <div class="view-block">
              <hi-icon-item :icon="icons.mdiAt">{{
                docData.email
              }}</hi-icon-item>
              <hi-icon-item :icon="icons.mdiPhone">{{
                docData.phoneNum
              }}</hi-icon-item>
              <hi-icon-item
                :icon="icons.mdiPostageStamp"
                v-if="docData.postcode"
                >{{ docData.postcode }}</hi-icon-item
              >
              <hi-icon-item :icon="icons.mdiDomain" v-if="docData.company">{{
                docData.company
              }}</hi-icon-item>
            </div>
            <div class="view-block">
              <div class="tp-small-label">Requests</div>
              <hi-icon-item
                :icon="icons.mdiCheck"
                v-for="req in docData.requests"
                :key="req"
                >{{ req }}</hi-icon-item
              >
            </div>
            <hi-ua-info
              class="view-block"
              :ua="docData.ua.raw"
              v-if="docData.ua"
            />
            <div class="view-block" v-if="docData.ip && docData.ip.address">
              <div class="tp-small-label">IP</div>
              <div class="tp-description">
                {{ docData.ip.address }} ({{ docData.ip.location.city }},
                {{ docData.ip.location.region }},
                {{ docData.ip.location.country }})
              </div>
            </div>
            <div class="view-block">
              <div class="tp-small-label">Ref URL</div>
              <div v-if="docData.userHeaders" class="tp-description">
                {{
                  !!docData.userHeaders.referrer
                    ? docData.userHeaders.referrer
                    : "Ref not done yet."
                }}
                <hi-icon :path="icons.mdiOpenInNew" />
              </div>
            </div>
          </div>
        </layout-title-card>
      </div>
    </template>
  </hi-fire-doc>
</template>

<script>
import HiIconItem from "@/hive-vue3/components/HiIconItem";
import {
  mdiAt,
  mdiCheck,
  mdiDomain,
  mdiOpenInNew,
  mdiPhone,
  mdiPostageStamp,
} from "@mdi/js";
import HiUaInfo from "@/hive-vue3/components/HiUaInfo";
import HiCard from "@/hive-vue3/components/HiCard";
import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import HiIcon from "@/hive-vue3/components/HiIcon";

import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import {
  addContactToEnquiry,
  closeEnquiry,
  openEnquiry,
  removeContactFromEnquiry,
} from "@/hive-vue3/modules/enquiries/data";
import HiButton from "@/hive-vue3/components/HiButton";
import LayoutTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import EnquiryTitle from "@/hive-vue3/modules/enquiries/parts/EnquiryTitle";
import {
  getContactsEntityNameById,
  getContactsFullNameById,
  getContactsPeopleByEmail,
  getContactsPeopleByMobile,
} from "@/hive-vue3/modules/contacts/data";
import Formatters from "@/hive-vue3/utils/formatters";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import { reactive } from "vue";
import ContactsPeopleSummary from "@/hive-vue3/modules/contacts/ContactsPeopleSummary";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import EnquiryLinkContactDialog from "@/hive-vue3/modules/enquiries/EnquiryLinkContactDialog";
import TransitionFadeCss from "@/hive-vue3/transitions/TransitionFadeCss";
import EnquiryCloseDialog from "@/hive-vue3/modules/enquiries/EnquiryCloseDialog";
import ConvertEnquiryToLeadsDialog from "@/hive-vue3/modules/enquiries/ConvertEnquiryToLeadsDialog";
import ActivityToolsCard from "@/hive-vue3/modules/activities/components/ActivityToolsCard";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import hive from "@/hive-vue3/components/hive";

export default {
  name: "EnquiryDetails",
  components: {
    ActivityToolsCard,
    ConvertEnquiryToLeadsDialog,
    EnquiryCloseDialog,
    TransitionFadeCss,
    EnquiryLinkContactDialog,
    HiDialogTrigger,
    ContactsPeopleSummary,
    MfcInputContactsPeople,
    EnquiryTitle,
    LayoutTitleCard,
    HiButton,
    HiIcon,
    HiFireDoc,
    HiCard,
    HiUaInfo,
    HiIconItem,
  },
  props: {
    enquiryId: String,
  },
  setup() {
    const state = reactive({});
    const icons = {
      mdiAt,
      mdiPhone,
      mdiPostageStamp,
      mdiDomain,
      mdiCheck,
      mdiOpenInNew,
    };

    function getMatchedContacts(email, mobile) {
      const returnList = [];
      let peopleByEmail, peopleByMobile;
      if (email) {
        peopleByEmail = getContactsPeopleByEmail(email);
      }
      if (mobile) {
        peopleByMobile = getContactsPeopleByMobile(
          Formatters.mobileE164(mobile)
        );
      }

      if (
        peopleByEmail &&
        peopleByMobile &&
        peopleByMobile.id !== peopleByEmail.id
      ) {
        returnList.push(peopleByEmail, peopleByMobile);
        return;
      }
      peopleByEmail = peopleByEmail || peopleByMobile;
      if (peopleByEmail) {
        returnList.push(peopleByEmail);
      }
      return returnList;
    }

    async function unLinkContact(peopleId, enquiryId, entityId) {
      const res = await hive.confirm("Are you sure to unlink the contact?");
      if (res) {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.UNLINK;
        historyObj.target = "enquiry";
        historyObj.targetId = enquiryState.currentEnquiryId;
        historyObj.comments = `Unlink ${getContactsFullNameById(
          peopleId
        )} by ${getContactsEntityNameById(entityId)}`;
        historyObj.item = "Contact";
        historyObj.value = null;
        historyObj.oldValue = {
          peopleId: peopleId,
        };
        await addNewHistory(historyObj);
        await removeContactFromEnquiry(enquiryId);
      }
    }

    return {
      icons,
      enquiryState,
      closeEnquiry,
      openEnquiry,
      getMatchedContacts,
      addContactToEnquiry,
      crashedContacts: [],
      state,
      unLinkContact,
    };
  },
};
</script>

<style>
@import "../moduleStyles.css";
</style>
