<template>
  <hi-tab-group>
    <hi-tab label="Preview">
      <hi-i-frame :html="html" class="w-full h-full" />
    </hi-tab>
    <hi-tab label="HTML">
      <hi-code-viewer class="w-full h-full" :code="html" language="html" dark />
    </hi-tab>
  </hi-tab-group>
</template>

<script>
import HiTab from "./HiTab";
import HiIFrame from "./HiIFrame";
import HiCodeViewer from "./HiCodeViewer";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
export default {
  name: "HiHtmlViewer",
  components: { HiTabGroup, HiCodeViewer, HiIFrame, HiTab },
  props: {
    html: String,
  },
};
</script>

<style scoped></style>
