<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard
      :initial-values="initialValues"
      @successful="open = false"
      :submit-fn="submit"
    >
      <hi-form-item label="Sender">
        <div class="flex justify-between">
          <hi-chips-selectable
            :items="edmSenderChips"
            form-key="senderId"
            mandatory
            v-model="selectedSender"
          />
          <hi-dialog-trigger>
            <hi-icon
              :path="commonIcons.add"
              class="hover:border-2 cursor-pointer"
            />
            <edm-add-new-sender-dialog />
          </hi-dialog-trigger>
        </div>
      </hi-form-item>
      <transition-fade-css>
        <div v-if="senderDetails.sendName">
          <hi-form-item label="Send Name">
            {{ senderDetails.sendName }}
          </hi-form-item>
          <hi-form-item label="Reply To">
            {{ senderDetails.replyTo }}
          </hi-form-item>
          <hi-form-item label="Send Address">
            {{ senderDetails.sendAddr }}
          </hi-form-item>
        </div>
      </transition-fade-css>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { computed, ref, watch } from "vue";
import useFirestoreCollection from "@/hive-vue3/firebase/useFirestoreCollection";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { edmState } from "@/hive-vue3/modules/edm/state";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import EdmAddNewSenderDialog from "@/hive-vue3/modules/edm/EdmAddNewSenderDialog";
import TransitionFadeCss from "@/hive-vue3/transitions/TransitionFadeCss";
export default {
  name: "EdmChangeSenderDialog",
  components: {
    TransitionFadeCss,
    EdmAddNewSenderDialog,
    HiDialogTrigger,
    HiIcon,
    HiChipsSelectable,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    initialValues: Object,
  },
  setup() {
    const open = ref(false);
    const selectedSender = ref();

    watch(
      () => open.value,
      (v) => {
        if (v) {
          selectedSender.value = undefined;
        }
      }
    );

    const edmSendersCollection = useFirestoreCollection("edm/assets/senders");
    const edmSenderChips = computed(() => {
      const returnObj = [];
      if (edmSendersCollection.data && edmSendersCollection.data.length) {
        for (const i of edmSendersCollection.data) {
          const obj = {};
          obj.value = i.id;
          obj.text = i.sendName;
          returnObj.push(obj);
        }
      }
      return returnObj;
    });

    const senderDetails = useFirestoreDocReactiveId(
      "edm/assets/senders",
      selectedSender,
      "value"
    );

    async function submit(data) {
      await updateFirestoreDoc(
        firestoreDoc("edm/campaigns/registers", edmState.currentEdmId),
        {
          senderId: data.senderId,
          sendName: senderDetails.sendName,
          replyTo: senderDetails.replyTo,
          sendAddr: senderDetails.sendAddr,
        }
      );

      return {
        successful: true,
      };
    }
    function changeValue(v) {
      console.log(v);
    }

    return {
      open,
      submit,
      edmSendersCollection,
      edmSenderChips,
      selectedSender,
      senderDetails,
      changeValue,
      commonIcons,
    };
  },
};
</script>

<style scoped></style>
