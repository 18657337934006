<template>
  <div>
    <transition-fade faster class="ml-2">
      <hi-image
        v-if="
          tagObj.starActivity &&
          tagObj.starActivity.pipeIds &&
          tagObj.starActivity.pipeIds.includes(pipeId)
        "
        :src="star_yellow"
        class="h-6 w-6"
        @click="unTagEmailThread"
      />
      <hi-image
        v-else
        :src="star_no_color"
        class="h-6 w-6"
        @click="tagEmailThread"
      />
    </transition-fade>
  </div>
</template>

<script>
import star_no_color from "@/hive-vue3/assets/star_border_black_20dp.png";
import star_yellow from "@/hive-vue3/assets/star_googyellow500_20dp.png";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiImage from "@/hive-vue3/components/HiImage";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { reactive } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import hive from "@/hive-vue3/components/hive";
export default {
  name: "GmailTagIndicator",
  components: { HiImage, TransitionFade },
  props: {
    pipeId: String,
    threadId: String,
    target: String,
    targetId: String,
  },
  setup(props) {
    const tagObj = reactive({
      starActivity: undefined,
    });

    watchPropsImmediately(props, "threadId", (v) => {
      if (v) {
        tagObj.starActivity = useFirestoreDoc(
          "/system/syncedEmail/allThreads",
          v
        );
      } else {
        tagObj.starActivity = undefined;
      }
    });

    async function tagEmailThread() {
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.ADD;
      historyObj.target = props.target;
      historyObj.targetId = props.targetId;
      historyObj.item = "thread";
      historyObj.value = {
        threadId: props.threadId,
      };
      historyObj.oldValue = null;
      await addNewHistory(historyObj);

      await updateFirestoreDoc(
        firestoreDoc("/system/syncedEmail/allThreads", props.threadId),
        {
          pipeIds: arrayUnion(props.pipeId),
          target: historyObj.target,
          targetId: historyObj.targetId,
        }
      );
    }

    async function unTagEmailThread() {
      const res = await hive.confirm("Are you sure to remove the tag?");
      if (res) {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.REMOVE;
        historyObj.target = props.target;
        historyObj.targetId = props.targetId;
        historyObj.item = "thread";
        historyObj.value = null;
        historyObj.oldValue = {
          threadId: props.threadId,
        };
        await addNewHistory(historyObj);

        await updateFirestoreDoc(
          firestoreDoc("/system/syncedEmail/allThreads", props.threadId),
          {
            pipeIds: arrayRemove(props.pipeId),
          }
        );
      }
    }

    return {
      star_no_color,
      star_yellow,
      tagObj,
      tagEmailThread,
      unTagEmailThread,
    };
  },
};
</script>

<style scoped></style>
