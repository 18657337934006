<template>
  <hi-data-list-kit
    :sortables="sortables"
    :searchables="searchables"
    @select="(item) => $emit('select', item)"
    :current-id="contactsState.selectedEntityId"
    search-input-placeholder="Name, Trading Name or ABN"
  >
    <template v-slot="{ item }">
      <contacts-entity-summary :entity-data="item" />
    </template>
  </hi-data-list-kit>
  <!--  <div class="flex flex-col" style="min-width: 420px">-->
  <!--    <div>-->
  <!--      <hi-search-input-->
  <!--        @search="onSearch"-->
  <!--        realtime-->
  <!--        placeholder="Name, Trading Name or ABN"-->
  <!--      />-->
  <!--    </div>-->
  <!--    <hi-data-list-->
  <!--      @select="onSelect"-->
  <!--      auto-items-per-page-->
  <!--      min-item-height="55"-->
  <!--      class="grow"-->
  <!--    >-->
  <!--      <template v-slot="{ item }">-->
  <!--        <contacts-entity-summary :entity-data="item" />-->
  <!--      </template>-->
  <!--    </hi-data-list>-->
  <!--    <hi-pagination class="grow-0 hi-footer" />-->
  <!--  </div>-->
</template>

<script>
// import HiDataList from "@/hive-vue3/components/dataDisplay/HiDataList";
// import HiPagination from "@/hive-vue3/components/dataDisplay/HiPagination";
// import HiSearchInput from "@/hive-vue3/components/HiSearchInput";
import { provideContactsEntityListStore } from "@/hive-vue3/modules/contacts/data";
import ContactsEntitySummary from "@/hive-vue3/modules/contacts/ContactsEntitySummary";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import { contactsState } from "@/hive-vue3/modules/contacts/state";
export default {
  name: "ContactsEntityList",
  components: {
    HiDataListKit,
    ContactsEntitySummary,
    // HiSearchInput,
    // HiPagination,
    // HiDataList,
  },
  emits: ["select"],
  setup() {
    provideContactsEntityListStore();
    const searchables = ["name", "tradingName", "abn"];
    const sortables = [
      {
        key: "__dateCreated",
        text: "Date",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "name",
        text: "Reg Name",
        type: dataListSortingTypes.ALPHA,
        desc: false,
        DEFAULT: false,
      },
      {
        key: "tradingName",
        text: "Trade",
        type: dataListSortingTypes.ALPHA,
        desc: false,
        DEFAULT: false,
      },
    ];
    // store.actions.defaultSortBy("__dateCreated", true, "date");
    // function onSelect(item) {
    //   console.log(item);
    //   emit("select", item);
    // }
    // function onSearch(v) {
    //   // console.log(v);
    //   store.actions.search(v);
    // }
    return {
      searchables,
      sortables,
      contactsState,
      // onSelect,
      // onSearch,
    };
  },
};
</script>

<style scoped></style>
