<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div ref="container">
    <hi-dropdown-container
      v-model="pop"
      @blur="onBlur"
      @focus="onFocus"
      :disabled="disabled"
    >
      <div
        ref="inputDisplay"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="hi-input w-full max-w-full flex"
        :class="{
          focus: hasFocus,
          error: error,
          disabled: disabled,
        }"
      >
        <div
          class="hi-control-padding ellipse transition-opacity select-none cursor-pointer grow"
          :class="{
            'hi-placeholder': !displayVal,
            'opacity-50': !displayVal,
            capitalize: itemCapitalize,
          }"
          style="padding-right: calc(var(--control-padding-x) + 2em)"
        >
          {{ displayVal || placeholder }}&nbsp;
        </div>
        <span class="hi-input-icon-right">
          <hi-icon :path="mdiChevronDown" />
        </span>
      </div>
      <template v-slot:dropdown>
        <hi-list
          selectable
          tick
          :multiple="multiple"
          :items="computedItems"
          keyboard
          v-model="model"
          value-key="value"
          @select="selectItem"
        >
          <template v-slot="{ item }">
            {{ item.text }}
          </template>
        </hi-list>
      </template>
    </hi-dropdown-container>
    <hi-form-error v-if="!hideError" :model-value="error" />
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
// import { templateRef } from "@vueuse/core";
import formControl from "../logics/formControl";
import commonFormInputProps from "../logics/commonFormInputProps";
// import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import textValuePair from "@/hive-vue3/components/_commonLogic/textValuePair";
import { mdiCheck, mdiChevronDown } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
// import clickOutside from "@/hive-vue3/utils/sensors/clickOutside";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import HiDropdownContainer from "@/hive-vue3/components/containers/HiDropdownContainer";
import HiList from "@/hive-vue3/components/HiList";
// import { tabNext } from "@/hive-vue3/utils/keyboard/tabSimulator";
// import { templateRef } from "@vueuse/core";
// import { isIterable } from "../../utils/objectUtils";
export default {
  name: "HiSelect",
  components: {
    HiList,
    HiDropdownContainer,
    HiFormError,
    HiIcon,
  },
  props: {
    ...commonFormInputProps,
    /**
     * [{text,value}] or {key:text}where key as value
     */
    items: {
      type: [Array, Object],
      required: true,
    },
    textKey: {
      type: String,
      default: "text",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    // /**
    //  * Text for selectAll
    //  */
    // allText: {
    //   type: String,
    //   default: "All",
    // },

    placeholder: {
      type: String,
      default: "Please select...",
    },
    /**
     * to enable select all
     */
    // enableSelectAll: Boolean,
    /**
     * allow multiple selections
     */
    multiple: Boolean,

    /**
     * Any object or Array, depends on the values in items.
     * When multiple value will always be an array(empty array when nothing is selected)
     */
    modelValue: null,
    itemCapitalize: Boolean,
  },
  setup(props, context) {
    const control = formControl(props, context);

    const model = control.model;

    // const inputDisplay = templateRef("inputDisplay");

    const computedItems = textValuePair(props);
    // console.log(computedItems);
    for (let i = 0; i < computedItems.value.length; i++) {
      const val = computedItems.value[i].value;
      if (typeof val !== "number" && typeof val !== "string") {
        console.error(
          "HiSelect values only support number & string. Found incompatible value below.",
          val
        );
      }
    }

    const state = reactive({
      pop: false,
      focus: false,
      selected: [],
    });

    function getItemByValue(val) {
      const items = computedItems.value;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.value === val) {
          return item;
        }
      }
    }
    const displayVal = computed(() => {
      const val = model.value;
      // console.log("props", props.modelValue);
      // console.log("modelval", val);
      if (!val || !val.length) return undefined;
      if (props.multiple) {
        const texts = [];
        for (let i = 0; i < val.length; i++) {
          const v = val[i];
          // console.log(item);
          const item = getItemByValue(v);
          texts.push(item.text);
        }
        return texts.join(", ");
      } else {
        return getItemByValue(val).text;
      }
    });
    function selectItem() {
      if (!props.multiple) {
        // tabNext(inputDisplay);
        // state.focus = false;
        state.pop = false;
      }
    }
    const { pop, focus: hasFocus } = toRefs(state);
    function onBlur() {
      // console.log("blur");
      if (state.focus) control.touch();
      state.focus = false;
    }

    function onFocus() {
      state.focus = true;
      // console.log(model.value);
    }

    return {
      pop,
      model,
      // isAll,
      hasFocus,
      // isSelected,
      selectItem,
      displayVal,
      computedItems,
      mdiCheck,
      mdiChevronDown,
      error: control.error,
      onFocus,
      onBlur,
    };
  },
};
</script>
