import $el from "./$el";
import { randomColor } from "../colors";

export function setStyle(element, obj) {
  element = $el(element);
  for (const [key, value] of Object.entries(obj)) {
    element.style[key] = value;
  }
}

/**
 * set a random color border for an element with given thickness. usually for testing.
 * @param element
 * @param thickness
 */
export function randomBorder(element, thickness = 1) {
  setStyle($el(element), {
    borderWidth: thickness + "px",
    borderColor: randomColor(),
    borderStyle: "solid",
  });
}
/**
 *
 * @param element DOM Element
 * @param prop property name
 * @returns {string} Computed CSS Style value for given prop.
 */

export function getComputedStyleValue(element, prop) {
  element = $el(element);
  if (element) {
    const s = window.getComputedStyle(element);
    return s.getPropertyValue(prop);
  }
  return undefined;
}
export function borderBox(element) {
  $el(element).style.boxSizing = "border-box";
}
