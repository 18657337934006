<template>
  <module-title-card title="Account">
    <template v-if="!(lead && lead.accountId)" v-slot:tools>
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link Account"
        />
        <leads-link-account-dialog />
      </hi-dialog-trigger>
    </template>
    <template v-else v-slot:tools>
      <hi-icon
        class="clickable-icon mr-4"
        :path="mdiTrashCanOutline"
        title="Remove Account"
        @click="removeAccount(lead.accountId)"
      />
      <hi-icon
        class="clickable-icon"
        :path="mdiOpenInNew"
        title="Link Account"
        @click="jumpToAccount(lead.accountId)"
      />
    </template>
    <accounts-title-card
      v-if="lead && lead.accountId"
      class="p-4"
      :account-id="lead.accountId"
      :editable="false"
    />
  </module-title-card>
</template>

<script>
import ModuleTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { computed } from "vue";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import { getLeadById, updateLeadById } from "@/hive-vue3/modules/leads/data";
import LeadsLinkAccountDialog from "@/hive-vue3/modules/leads/LeadsLinkAccountDialog";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import AccountsTitleCard from "@/hive-vue3/modules/accounts/AccountsTitleCard";
import { mdiOpenInNew, mdiTrashCanOutline } from "@mdi/js";
import { useRouter } from "vue-router";
import hive from "@/hive-vue3/components/hive";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getAccountNameEmailById } from "@/hive-vue3/modules/accounts/data";
export default {
  name: "LeadsAccountInfoCard",
  components: {
    AccountsTitleCard,
    HiDialogTrigger,
    LeadsLinkAccountDialog,
    HiIcon,
    ModuleTitleCard,
  },
  setup() {
    const lead = computed(() => {
      return getLeadById(leadsState.currentLeadId);
    });
    const router = useRouter();

    function jumpToAccount(accountId) {
      router.push(`/accounts/${accountId}`);
    }

    async function removeAccount(accountId) {
      const res = await hive.confirm("Are you sure to unlink this account?");
      if (res) {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.UNLINK;
        historyObj.target = "leads";
        historyObj.targetId = leadsState.currentLeadId;
        historyObj.comments = `Unlink account ${getAccountNameEmailById(
          accountId
        )}`;

        await addNewHistory(historyObj);

        await updateLeadById(leadsState.currentLeadId, {
          accountId: null,
        });
      }
    }

    return {
      commonIcons,
      lead,
      mdiOpenInNew,
      jumpToAccount,
      mdiTrashCanOutline,
      removeAccount,
    };
  },
};
</script>

<style scoped></style>
