<template>
  <twilio-view-full-kit class="h-full" />
</template>

<script>
import TwilioViewFullKit from "@/hive-vue3/modules/twilio/TwilioViewFullKit";
export default {
  name: "PhoneSettingView",
  components: { TwilioViewFullKit },
};
</script>

<style scoped></style>
