<template>
  <div class="inline-block box-border -HiInputAddress">
    <div class="w-full">
      <input-base
        class="hi-input"
        ref="input"
        v-model="inputModel"
        :placeholder="placeholder"
        :prefix-icon="mdiEarth"
        prefix-tip="Toggle Global Address"
        :prefix-toggle-on="isGlobal"
        @prefix-click="globalClicked"
        @input="userInput"
        @blur="onBlur"
        :error="error"
        :valid="valid"
        suffix-link
        :suffix-href="model && model.url"
        :suffix-icon="mdiMap"
        suffix-tip="Open in Google Maps"
        :auto-focus="autoFocus"
      />
    </div>
    <hi-form-error v-if="!hideError" v-model="error" />
  </div>
</template>

<script>
import loadScript from "@/hive-vue3/utils/dom/loadScript";
import apiKeys from "@/conf/apiKeys";
import { onMounted, ref, watch } from "vue";
import { templateRef } from "@vueuse/core";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import InputBase from "@/hive-vue3/components/form/controls/base/InputBase";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import { mdiEarth, mdiMap } from "@mdi/js";
import { wait } from "@/hive-vue3/utils/miscs";
import { squeeze } from "@/hive-vue3/utils/objectUtils";
function parseResult(result) {
  // console.log(result);
  // result.geometry = undefined;
  const components = result.address_components;
  const addObj = {
    floor: "",
    streetNumber: "",
    street: "",
    streetShort: "",
    postcode: "",
    postcodeSuffix: "",
    //refer to administrative_area_level_2. usually city council.
    admin: "",
    adminShort: "",
    // refer to administrative_area_level_1
    state: "",
    stateShort: "",
    city: "",
    cityShort: "",
    vicinity: result.vicinity,
    url: result.url,
    utcOffsetMinutes: 0,
    placeId: result.place_id || result.reference,
    globalCode: result.plus_code && result.plus_code.global_code,
    formatted: result.formatted_address.endsWith(", Australia")
      ? result.formatted_address.replace(", Australia", "")
      : result.formatted_address,

    country: "au",
    countryShort: "",
  };
  // const geo = result.geometry && result.geometry.location;
  // console.log(result.geometry);
  // console.log(result.geometry && result.geometry.location);
  // console.log(geo.lat(), geo.lng());

  // console.log(components);
  for (const component of components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "floor": {
        addObj.floor = `${component.long_name} ${addObj.floor}`;
        break;
      }

      case "street_number": {
        addObj.streetNumber = `${component.long_name} ${addObj.streetNumber}`;
        break;
      }

      case "route": {
        addObj.street += component.long_name;
        addObj.streetShort += component.short_name;
        break;
      }

      case "postal_code": {
        addObj.postcode = `${component.long_name}${addObj.postcode}`;
        break;
      }

      case "postal_code_suffix": {
        addObj.postcodeSuffix = `${addObj.postcodeSuffix}${component.long_name}`;
        break;
      }
      case "locality":
        addObj.city = component.long_name;
        addObj.cityShort = component.short_name;
        break;
      case "administrative_area_level_1": {
        addObj.state = component.long_name;
        addObj.stateShort = component.short_name;
        break;
      }
      case "administrative_area_level_2": {
        addObj.admin = component.long_name;
        addObj.adminShort = component.short_name;
        break;
      }
      case "country":
        addObj.country = component.long_name;
        addObj.countryShort = component.short_name;

        break;
    }
  }
  // console.log(addObj);
  squeeze(addObj);
  return addObj;
}
export default {
  name: "HiInputAddress",
  components: { HiFormError, InputBase },
  props: {
    ...commonFormInputProps,
    placeholder: String,
    modelValue: [String, Object],
    country: {
      type: [String, Array],
      default: "au",
    },
    autoFocus: Boolean,
  },
  emits: ["change", "blur", "update:modelValue"],
  setup(props, context) {
    const control = formControl(props, context, {
      validator,
      onModelValueUpdate(v) {
        inputModel.value = v && v.formatted;
      },
    });
    const inputModel = ref("");
    const mapLink = ref(undefined);

    const model = control.model;
    const isGlobal = ref(false);
    const hiInput = templateRef("input");

    let autocomplete;

    // let timer;

    function validator(value) {
      if (value.country) return true;
      return "Address is not valid.";
    }
    function userInput() {
      // model.value = undefined;
      model.value = hiInput.value.input.value;
    }
    function setOptions() {
      if (autocomplete) {
        const opts = {
          fields: [
            "address_components",
            "geometry",
            "formatted_address",
            "place_id",
            "vicinity",
            "url",
          ],
          types: ["address"],
        };
        if (!isGlobal.value && props.country) {
          opts.componentRestrictions = { country: props.country };
        } else {
          opts.componentRestrictions = { country: [] };
        }
        autocomplete.setOptions(opts);
      }
    }
    //reset options when settings changed.
    watch(isGlobal, setOptions);

    function globalClicked() {
      isGlobal.value = !isGlobal.value;
      hiInput.value.focus();
    }
    // let timber;
    async function onBlur() {
      context.emit("blur");
      //补偿 autocomplete 的延时。否者在返回结果之前会闪现错误。
      await wait(300);
      control.touch();
      // console.log(model.value);
      // timer = new Date().getTime();
    }
    // watch(inputModel, (v) => console.log(v));
    onMounted(() => {
      if (!apiKeys.googleMap) {
        console.error(
          "Google Map API Key not found in apiKeys! conf path: 'src/conf/apiKeys'"
        );
      } else {
        loadScript(
          "https://maps.googleapis.com/maps/api/js?libraries=places&key=" +
            apiKeys.googleMap +
            "&language=en"
        )
          .then(() => {
            autocomplete = new window.google.maps.places.Autocomplete(
              hiInput.value.input
            );
            //autocomplete is not reactive, need to call manually setOptions for the first time.
            setOptions();
            autocomplete.addListener("place_changed", () => {
              model.value = parseResult(autocomplete.getPlace());
              inputModel.value = model.value.formatted;
              context.emit("change", model.value);
              // console.log(new Date().getTime() - timer);
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
    return {
      error: control.error,
      valid: control.valid,
      mdiEarth,
      mdiMap,
      isGlobal,
      globalClicked,
      inputModel,
      userInput,
      onBlur,
      mapLink,
      model,
    };
  },
};
</script>

<style scoped></style>
