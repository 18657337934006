<template>
  <iframe class="border-none overflow-auto" ref="iframe"></iframe>
</template>

<script>
import { templateRef } from "@vueuse/core";
import { onMounted } from "vue";
import { watchProps } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { writeHtmlToIframe } from "@/hive-vue3/utils/dom/iframe";

export default {
  name: "HiIFrame",
  props: {
    html: String,
  },
  setup(props) {
    const iframe = templateRef("iframe");
    onMounted(() => {
      watchProps(
        props,
        "html",
        (v) => {
          writeHtmlToIframe(iframe, v);
        },
        true
      );
    });
  },
};
</script>

<style scoped></style>
