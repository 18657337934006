import { firestoreDoc, injectSnapshotToObject } from "./utils";
import { getCurrentScope, onScopeDispose, reactive } from "vue";
import { onSnapshot } from "firebase/firestore";

/**
 *
 * @param collectionPathOrFullPathOrDocRef
 * @param docId
 * @param options
 * @returns {reactive<{}>}
 */
export default function (
  collectionPathOrFullPathOrDocRef,
  docId = null,
  options = { persistData: false }
) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;
  const data = reactive({});
  const theDoc = firestoreDoc(collectionPathOrFullPathOrDocRef, docId);
  const close = onSnapshot(
    theDoc,
    (doc) => {
      injectSnapshotToObject(doc, data);
    },
    errorHandler
  );

  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      close();
    });
  }
  return data;
}
