<template>
  <hi-dialog width="600px" v-model="open" :title="title">
    <hi-form-hive-standard
      :submit-fn="submit"
      cancel-button
      @cancel="open = false"
    >
      <hi-form-item label="Project Name *">
        <hi-input form-key="name" mandatory />
      </hi-form-item>
      <hi-form-item label="Is Name Official *">
        <hi-chips-selectable
          model-value="Y"
          :items="{
            Y: 'Yes',
            N: 'No',
          }"
          mandatory
          form-key="isNameOfficial"
        />
      </hi-form-item>

      <hi-form-item label="Project Address *">
        <hi-input-address mandatory form-key="address" />
      </hi-form-item>
      <hi-form-item label="Project Type *">
        <hi-select
          mandatory
          :items="{
            residential: 'Residential',
            townhouse: 'Townhouse',
            boutique: 'Boutique',
            'multi-res': 'Multi-Res',
            hospitality: 'Hospitality',
            workplaceFitout: 'Workplace Fitout',
            retail: 'Retail',
            education: 'Education',
            hotel: 'Hotel',
            health: 'Health',
            government: 'Government',
            other: 'Other',
          }"
          form-key="projType"
        />
      </hi-form-item>
      <hi-form-item label="Project Status *">
        <hi-select
          mandatory
          :items="[
            {
              text: 'Sample Request',
              value: 'Sample Request',
            },
            {
              text: 'Builder Tender',
              value: 'Builder Tender',
            },
            {
              text: 'Builder Appointed',
              value: 'Builder Appointed',
            },
            {
              text: 'Quotation',
              value: 'Quotation',
            },
            {
              text: 'Sub Contractor Tender',
              value: 'Sub Contractor Tender',
            },
            {
              text: 'Sub Contractor Appointed',
              value: 'Sub Contractor Appointed',
            },
            {
              text: 'Proforma Invoice Issued',
              value: 'Proforma Invoice Issued',
            },
            {
              text: 'Deposit Paid',
              value: 'Deposit Paid',
            },
            {
              text: 'PO# Received',
              value: 'PO# Received',
            },
          ]"
          form-key="projStatus"
        />
      </hi-form-item>
      <hi-form-item label="Estimate Amount">
        <hi-input finance form-key="estimateAmount" />
      </hi-form-item>
      <hi-form-item label="Estimate Supply Date">
        <hi-date-picker form-key="estimateSupplyDate" month-only />
      </hi-form-item>
      <hi-form-item label="Build Status">
        <hi-select
          :items="{
            'In Construction': 'In Construction',
            Design: 'Design',
          }"
          form-key="buildStatus"
        />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { reactive, ref } from "vue";
import { AddNewProjectToFirebase } from "@/hive-vue3/modules/projects/data";
import { pipeIdGenerator } from "@/hive-vue3/modules/utils/utils";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import { useCurrentFirebaseUserId } from "@/hive-vue3/firebase";
export default {
  name: "ProjectNewProjectDialog",
  components: {
    HiDatePicker,
    HiSelect,
    HiFormHiveStandard,
    HiDialog,
    HiChipsSelectable,
    HiInputAddress,
    HiInput,
    HiFormItem,
  },
  props: {
    title: String,
  },
  setup() {
    const open = ref(false);

    const formState = reactive({
      dialogEnable: true,
    });

    async function submit(projectData) {
      projectData.isNameOfficial = projectData.isNameOfficial === "Y";
      projectData.pipeId = pipeIdGenerator();
      projectData.status = "active";
      if (projectData.estimateSupplyDate) {
        projectData.estimateSupplyDate = new Date(
          `${projectData.estimateSupplyDate.year}/${
            projectData.estimateSupplyDate.month + 1
          }`
        );
      }

      projectData.mainOwner = useCurrentFirebaseUserId().value;

      formState.dialogEnable = false;
      // console.log(projectData);
      await AddNewProjectToFirebase(projectData);

      open.value = false;

      return {
        successful: true,
      };
    }
    return {
      formState,
      submit,
      open,
    };
  },
};
</script>

<style scoped></style>
