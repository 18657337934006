<template>
  <div class="flex">
    <div class="grow" v-if="project">
      <div class="tp-subtitle flex items-center font-bold">
        {{ project.name }}{{ project.isNameOfficial ? " - Official" : "" }}
        <span class="capitalize"
          >&nbsp; ({{ decamelize(project.projType) }})</span
        >
      </div>

      <div class="tp-subtitle">
        {{ project.address.formatted }}
      </div>
      <div class="tp-subtitle">
        {{ project.projStatus
        }}{{ project.estimateAmount ? " - $" + project.estimateAmount : "" }}
      </div>
    </div>
    <div
      v-if="!noTimeDisplay"
      class="tp-description-sm text-right flex flex-col"
    >
      Create Time:
      <hi-date-time-display :model-value="project.__dateCreated" friendly />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import { decamelize } from "@/hive-vue3/utils/stringUtils";
export default {
  name: "ProjectSummary",
  components: { HiDateTimeDisplay },
  props: {
    projectData: Object,
    projectId: String,
    noTimeDisplay: Boolean,
  },
  setup(props) {
    return {
      decamelize,
      project: computed(() => {
        if (props.projectData) return props.projectData;
        else if (props.projectId) {
          return getProjectById(props.projectId);
        }
        return null;
      }),
    };
  },
};
</script>

<style scoped></style>
