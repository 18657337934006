<template>
  <div class="inline-flex space-x-2 select-none">
    <div
      v-for="item in items"
      :key="item.key"
      class="uppercase cursor-pointer transition-all"
      :class="{ active: item.key === activeKey }"
      @click.prevent="sort(item.key)"
    >
      <span class="mr-0.5">{{ item.text || item.key }}</span>
      <hi-icon :path="map[item.key].desc ? up : down" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { injectDataListStore } from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
export default {
  name: "HiDataListSorter",
  components: { HiIcon },
  props: {
    /**
     * [{key:String,text:String,type:String,desc:Boolean,DEFAULT:Boolean}]
     * type: alpha[default],number,date,time
     */
    items: Array,
  },
  setup(props) {
    const store = injectDataListStore();
    // console.log(store);
    const sortBy = store.actions.sortBy;
    // console.log(sortBy);
    const map = reactive({});
    const activeKey = ref(null);
    for (let i = 0; i < props.items.length; i++) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      const item = props.items[i];
      if (!item.key) {
        console.error("HiDataSorter found item without key.");
      } else {
        const o = {};
        const key = item.key;
        if (item.DEFAULT) {
          sortBy(key, item.desc, item.type);
          activeKey.value = key;
        }
        o.desc = item.desc || false;
        o.type = item.type;
        map[key] = o;
      }
    }
    const sort = (key) => {
      // :up="map[item.key].desc" :down="!map[item.key].desc"
      // console.log(!map[key].desc);
      if (activeKey.value === key) {
        map[key].desc = !map[key].desc;
      } else {
        activeKey.value = key;
      }
      // console.log(key, "desc:" + map[key].desc, "type:" + map[key].type);
      sortBy(key, map[key].desc, map[key].type);
    };

    const up = commonIcons.up;
    const down = commonIcons.down;

    return {
      map,
      activeKey,
      sort,
      up,
      down,
    };
  },
};
</script>

<style scoped>
.active {
  @apply text-primary-dark font-bold underline;
}
</style>
