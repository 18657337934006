<template>
  <div class="flex flex-col gap-2 justify-stretch">
    <hi-card v-if="docData">
      <module-layout-block-editable
        class="view-block-spacious"
        dialog-title="Edit Trading Name"
        editable
      >
        <div class="tp-big-fancy-display pb-3">
          <contacts-entity :entity-data="docData" />
        </div>
        <hi-tagger
          label="Tags"
          collection-path="contacts"
          doc-path="entityTags"
          multiple
          value-key="tags"
          can-edit
        />
        <div class="tp-description-sm">
          Added by:
          <user-details-span :user-id="docData.__createdBy" no-bold /> at
          <hi-date-time-display :model-value="docData.__dateCreated" />
        </div>

        <template v-slot:editor="{ closeDialog }">
          <hi-form-hive-standard
            @successful="closeDialog()"
            @cancel="closeDialog()"
            cancel-button
            :submit-fn="editTradingName"
            :initial-values="docData"
          >
            <hi-form-item label="Trading Name">
              <hi-input form-key="tradingName" mandatory />
            </hi-form-item>
          </hi-form-hive-standard>
        </template>
      </module-layout-block-editable>
      <!--        <div class="view-block break-all">-->
      <!--          {{ docData }}-->
      <!--        </div>-->
    </hi-card>
    <hi-card>
      <contacts-entity-contact-info :entity-data="docData" editable />
    </hi-card>
    <hi-card>
      <contacts-entity-registration-info :entity-data="docData" />
    </hi-card>
    <hi-card>
      <contacts-people-in-entity-view :entity-id="entityId" editable />
    </hi-card>
    <hi-card class="grow h-0" />
  </div>
</template>

<script>
// import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import ContactsPeopleInEntityView from "@/hive-vue3/modules/contacts/ContactsPeopleInEntityView";
import HiCard from "@/hive-vue3/components/HiCard";
import ContactsEntityRegistrationInfo from "@/hive-vue3/modules/contacts/ContactsEntityRegistrationInfo";
import ContactsEntityContactInfo from "@/hive-vue3/modules/contacts/ContactsEntityContactInfo";
import { computedEntityByIdOrData } from "@/hive-vue3/modules/contacts/data";
import HiTagger from "@/hive-vue3/components/HiTagger";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import ModuleLayoutBlockEditable from "@/hive-vue3/modules/_common/ModuleLayoutBlockEditable";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
export default {
  name: "ContactsEntityDetails",
  components: {
    HiFormItem,
    HiInput,
    HiFormHiveStandard,
    ModuleLayoutBlockEditable,
    ContactsEntity,
    HiDateTimeDisplay,
    UserDetailsSpan,
    HiTagger,
    ContactsEntityContactInfo,
    ContactsEntityRegistrationInfo,
    HiCard,
    ContactsPeopleInEntityView,
    // HiFireDoc,
  },
  props: {
    entityId: String,
  },
  setup(props) {
    async function editTradingName(data) {
      await updateFirestoreDoc(
        firestoreDoc("/contacts/data/entities", props.entityId),
        { ...data }
      );
      return { successful: true };
    }

    return {
      docData: computedEntityByIdOrData(props),
      dbPaths,
      editTradingName,
    };
  },
};
</script>

<style scoped>
@import "../moduleStyles.css";
</style>
