<template>
  <hi-list
    v-if="account && account.people && account.people.length"
    :items="account.people"
  >
    <template v-slot="{ item }">
      <hi-dialog-trigger>
        <contacts-people :people-id="item" email mobile class="gap-2" />
        <hi-dialog title="Contact Details" width="600px">
          <contacts-people-details :people-id="item" />
          <template v-slot:footer>
            <hi-button text @click="submit(item)">
              Remove&nbsp; <contacts-people :people-id="item" />&nbsp; From
              Current Account
            </hi-button>
          </template>
        </hi-dialog>
      </hi-dialog-trigger>
    </template>
  </hi-list>
  <hi-view-no-data v-else />
</template>

<script>
import HiList from "@/hive-vue3/components/HiList";
import { computed } from "vue";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import {
  getAccountById,
  updateAccountById,
} from "@/hive-vue3/modules/accounts/data";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ContactsPeopleDetails from "@/hive-vue3/modules/contacts/ContactsPeopleDetails";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiButton from "@/hive-vue3/components/HiButton";
import { arrayRemove } from "firebase/firestore";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getContactsFullNameById } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "AccoountsPeopleList",
  components: {
    HiButton,
    HiDialog,
    ContactsPeopleDetails,
    HiDialogTrigger,
    ContactsPeople,
    HiViewNoData,
    HiList,
  },
  setup() {
    const account = computed(() => {
      return getAccountById(accountState.currentAccountId);
    });

    async function submit(peopleId) {
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.UNLINK;
      historyObj.target = "account";
      historyObj.targetId = accountState.currentAccountId;
      historyObj.comments =
        `Unlink people to account: ` + getContactsFullNameById(peopleId);
      await addNewHistory(historyObj);
      await updateAccountById(accountState.currentAccountId, {
        people: arrayRemove(peopleId),
      });
    }
    return {
      submit,
      account,
    };
  },
};
</script>

<style scoped></style>
