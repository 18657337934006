<template>
  <div class="text-xxs space-y-2 flex flex-col items-center clickable">
    <div class="w-12 h-12 border flex-center bg-gray-200 rounded-lg">
      <hi-icon :path="mdiUpload" class="text-2xl" />
    </div>
    <div>Upload</div>
  </div>
</template>

<script>
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiUpload } from "@mdi/js";
export default {
  name: "PrivateUploadButton",
  components: { HiIcon },
  setup() {
    return {
      mdiUpload,
    };
  },
};
</script>

<style scoped></style>
