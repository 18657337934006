<template>
  <leads-view-full-kit class="h-full" />
</template>

<script>
import LeadsViewFullKit from "@/hive-vue3/modules/leads/LeadsViewFullKit";
export default {
  name: "LeadsView",
  components: { LeadsViewFullKit },
};
</script>

<style scoped></style>
