import { v1 } from "uuid";
import { reactive } from "vue";
import hive from "@/hive-vue3/components/hive";
import {
  firestoreDoc,
  getFirestoreDocData,
  newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase/utils";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";

import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";

import { deleteDoc } from "firebase/firestore";

/**
 * 只能 upload 一个文件，重复调用会删掉原来的文件。
 * @param folderPath
 * @returns {{cancel: cancel, deleteFile: deleteAndCancel, upload: (function(*, {name?: *, fileId?: *}): Promise<unknown>), fileDoc: {}, state: {running: boolean, path: string, progress: number, completed: boolean, error: boolean}}}
 */
export function storageUploader(folderPath = "/", collectionPath = "files") {
  // Create a reference to 'mountains.jpg'
  if (folderPath.charAt(folderPath.length - 1) !== "/") {
    folderPath += "/";
  }
  const state = reactive({
    error: false,
    completed: false,
    progress: 0,
    running: false,
    // url: null,
    path: folderPath,
  });

  const fileQ = useStorageFileQueryable();
  // console.log(fileQ);
  let uploadTask;
  // let canceled = false;
  function cancel() {
    state.error = false;
    state.completed = false;
    state.running = false;
    state.progress = 0;
    if (uploadTask) {
      // canceled = true;
      uploadTask.cancel();
    }
  }
  function deleteAndCancel() {
    cancel();
    const id = fileQ.getId();
    if (id) {
      deleteFile(id);
      filePath = null;
      fileQ.setId(null);
    }
  }
  let filePath;

  /**
   *
   * @param file
   * @param name    name for the file. default file.name
   * @param fileId  id in files collection. created new file in files collection if not provided.
   * @returns {Promise<unknown>}
   */

  function upload(file, { name = null, fileId = null }) {
    cancel();

    if (!name) {
      name = file.name;
    }
    filePath = folderPath + v1() + "@" + name;
    const fileRef = getStorageRef(filePath);
    uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          state.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case "paused":
              state.running = false;
              break;
            case "running":
              state.running = true;
              break;
          }
        },
        (error) => {
          if (error.code.indexOf("canceled") > 0) {
            return;
          }
          console.error(error);
          state.error = error.code;
          reject();
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          state.completed = true;
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log("File available at", downloadURL);
            const fileData = {
              name: name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              url: downloadURL,
              path: filePath,
            };
            if (fileId) {
              //// to update
              const file = await getStorageFileDocData(fileId);
              // console.log("update", file);
              if (file && file.path) {
                deleteFileFromStorage(file.path);
              }
              await updateFirestoreDoc(
                firestoreDoc(collectionPath, fileId),
                fileData
              );
              resolve(fileId);
              fileQ.setId(fileId);
            } else {
              //// to create new
              const newFile = await newFirestoreDoc(collectionPath, fileData);
              resolve(newFile.id);
              fileQ.setId(newFile.id);
            }
          });
        }
      );
    });
  }

  return {
    /**
     * error,
     * completed,
     * percent,
     * running,
     * path //path of the folder
     */
    state,
    upload,
    cancel,
    deleteFile: deleteAndCancel,
    /**
     * reactive firebase doc
     */
    fileDoc: fileQ.data,
  };
}

export function deleteFileFromStorage(path) {
  // console.log(path);
  const desertRef = getStorageRef(path);

  deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      //If the error is object-not-found, meaning file already deleted. No need for toastError.
      if (error.code.indexOf("object-not-found") < 0) {
        hive.toastError("Delete File Error: " + error.code);
        console.warn(error);
      } else {
        console.error(error);
      }
    });
}

export async function deleteFile(id) {
  if (!id) {
    console.error("deleteFile from storage id not provided.");
    return;
  }
  // console.log(id);
  const theDoc = firestoreDoc("files", id);
  const data = await getFirestoreDocData(theDoc);
  if (!data) {
    // console.warn("Delete file " + id + ". File not found!");
    return;
  }
  deleteFileFromStorage(data.path);
  return await deleteDoc(theDoc);
}

export function useStorageFileDoc(id) {
  return useFirestoreDoc("files", id);
}

export async function getStorageFileDocData(id) {
  return await getFirestoreDocData(firestoreDoc("files", id));
}

export function useStorageFileQueryable(id = null) {
  return useFirestoreDocQueryable("files", id);
}

export async function getStorageFileMetadata(id) {
  const file = await getFirestoreDocData(firestoreDoc("files", id));
  const ref = getStorageRef(file.path);
  return getMetadata(ref);
}
/**
 * Get a reference to the storage service ref.
 * @param path
 * @returns {StorageReference}
 */
export function getStorageRef(path) {
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage();

  // Create a storage reference from our storage service
  if (!path) return ref(storage);
  else return ref(storage, path);
}
