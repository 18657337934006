import { newFirestoreDoc } from "@/hive-vue3/firebase";

export const historyActions = {
  CLOSE: "CLOSE",
  REMOVE: "REMOVE",
  CHANGE: "CHANGE",
  ASSIGN: "ASSIGN",
  STATUS: "STATUS",
  ADD: "ADD",
  LINK: "LINK",
  UNLINK: "UNLINK",
  CONVERT: "CONVERT",
  REOPEN: "REOPEN",
};

export function DefaultHistoryData() {
  this.action = historyActions.CLOSE;
  this.target = null;
  this.targetId = null;
  this.comments = null;
  this.item = null;
  this.value = null;
  this.oldValue = null;
}

export async function addNewHistory(historyData) {
  return await newFirestoreDoc("histories", historyData);
}
