<template>
  <div
    class="flex gap-2 pt-2 pl-2"
    :class="{
      'bg-green-50': model.quantity && model.rate,
      'bg-rose-100':
        (model.quantity || model.rate || (model.item && model.item.length)) &&
        (!model.quantity || !model.rate || !model.item || !model.item.length),
      'bg-gray-100': !(
        model.quantity ||
        model.rate ||
        (model.item && model.item.length)
      ),
    }"
  >
    <div class="grow">
      <hi-textarea
        class="w-full"
        no-form
        auto-height
        :rows="1"
        v-model="model.item"
      />
    </div>
    <div class="w-[90px] grow-0 shrink-0">
      <hi-input
        class="w-full text-right"
        no-form
        three-decimal-number
        v-model="model.quantity"
      />
    </div>
    <div class="w-[120px] grow-0 shrink-0">
      <hi-input
        class="w-full text-right"
        no-form
        finance
        v-model="model.rate"
      />
    </div>
    <div class="w-[90px] grow-0 shrink-0 text-right hi-control-py">
      {{
        (model.rate &&
          model.quantity &&
          (Math.round(model.rate * model.quantity * 100) / 100).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
            }
          )) ||
        "0.00"
      }}
    </div>
    <div class="w-[50px] grow-0 shrink-0 hi-control-py text-center text-lg">
      <hi-icon
        :path="mdiCloseCircle"
        @click="$emit('delete')"
        class="clickable"
      />
    </div>
  </div>
</template>

<script>
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiCloseCircle } from "@mdi/js";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
export default {
  name: "QuotationEditorLine",
  components: { HiIcon, HiInput, HiTextarea },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const model = modelRef(props, emit);
    // const values = reactive({
    //   item: undefined,
    //   quantity: undefined,
    //   rate: undefined,
    // });

    return {
      model,
      mdiCloseCircle,
    };
  },
};
</script>

<style scoped></style>
