<template>
  <hi-data-list-kit
    :sortables="sortables"
    :search-fn="enquiryListSearchFn"
    search-input-placeholder="Name, Email or Mobile"
    @select="onSelect"
    :current-id="enquiryState.currentEnquiryId"
  >
    <template v-slot="{ item }">
      <enquiry-list-item :enquiry="item" />
    </template>
  </hi-data-list-kit>
</template>

<script>
import {
  getOpeningEnquiries,
  enquiryListSearchFn,
  // provideOpeningEnquiriesListStore,
} from "@/hive-vue3/modules/enquiries/data";
import EnquiryListItem from "@/hive-vue3/modules/enquiries/EnquiryListItem";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import { computed } from "vue";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
export default {
  name: "QueryList",
  components: {
    HiDataListKit,
    EnquiryListItem,
  },
  emits: ["select"],
  setup(props, { emit }) {
    const openingList = computed(() => {
      return getOpeningEnquiries();
    });

    const storeValue = createDataListStore(openingList);
    provideDataListStore(storeValue);

    function onSelect(item) {
      // console.log(item);
      // enquiryState.currentEnquiry = item;
      // console.log(item);
      emit("select", item);
      enquiryState.currentEnquiryId = item.id;
    }

    const sortables = [
      {
        key: "enquiryAt",
        text: "Date",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "__dateUpdated",
        text: "Update",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: false,
      },
    ];

    return {
      onSelect,
      sortables,
      enquiryListSearchFn,
      enquiryState,
    };
  },
};
</script>

<style scoped></style>
