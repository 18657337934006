<template>
  <div class="h-full">
    <div class="tp-title-faded mb-4">Dashboard</div>
    <div class="h-1/2 flex gap-4">
      <quotation-list-view-card class="w-1/4 h-full" :limit="10" />
      <hi-tab-group
        v-if="
          permission.maintenance ||
          permission.admin ||
          currentUserId === 'c5FSIxacH0R5XjaJaQ4h4CTq3Em2' ||
          currentUserId === '9otJUryIZdNaeBEvYB518enHzME3'
        "
        v-model="state.userTab"
        class="w-1/4 h-full"
      >
        <hi-tab label="Kate" class="overflow-x-auto">
          <activity-list />
        </hi-tab>
        <hi-tab label="Ben" class="overflow-x-auto">
          <activity-list />
        </hi-tab>
        <hi-tab label="Phyllis" class="overflow-x-auto">
          <activity-list />
        </hi-tab>
      </hi-tab-group>
    </div>
  </div>
</template>

<script>
import QuotationListViewCard from "@/hive-vue3/modules/quotation/QuotationListViewCard";
import {
  useCurrentFirebaseUserId,
  useCurrentFirebaseUserPermissions,
} from "@/hive-vue3/firebase";
import ActivityList from "@/hive-vue3/modules/activities/ActivityList";
import { provideActivityDataByUserId } from "@/hive-vue3/modules/activities/data";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import { reactive, ref } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
export default {
  name: "DashboardView",
  components: {
    HiTab,
    HiTabGroup,
    ActivityList,
    QuotationListViewCard,
  },
  setup() {
    const peopleId = ref("9otJUryIZdNaeBEvYB518enHzME3");
    const currentUserId = useCurrentFirebaseUserId();

    const permission = useCurrentFirebaseUserPermissions();

    const state = reactive({
      userTab: "Kate",
    });

    watchPropsImmediately(state, "userTab", (v) => {
      if (v) {
        if (v === "Kate") {
          peopleId.value = "9otJUryIZdNaeBEvYB518enHzME3";
        }
        if (v === "Ben") {
          peopleId.value = "8rKQpCO7RNYr3KByweJHZJZV1u52";
        }
        if (v === "Phyllis") {
          peopleId.value = "XpLPlvUml6VxEje2GdIdwHbqmYC3";
        }
      }
    });

    provideActivityDataByUserId(peopleId);
    return {
      currentUserId,
      state,
      permission,
    };
  },
};
</script>

<style scoped></style>
