<template>
  <div v-if="activity" class="flex flex-col gap-1 max-h-[600px] overflow-auto">
    <div class="flex flex-row-reverse">
      <hi-icon
        class="clickable-icon mx-6"
        :path="mdiOpenInNew"
        title="Link Account"
        @click="jumpToDetails(activity.target, activity.targetId)"
      />
    </div>
    <hi-form-item label="Title" class="font-bold">
      {{ activity.title }}
    </hi-form-item>
    <hi-form-item label="Time">
      <hi-date-time-display :model-value="activity.actionTime" />
    </hi-form-item>
    <hi-form-item label="Type">
      {{ activity.action }}
    </hi-form-item>

    <hi-form-item label="Comments">
      <div class="w-full break-words">
        {{ activity.comments }}
      </div>
    </hi-form-item>
    <hi-form-item label="Peoples">
      <div>
        <contacts-people
          v-for="(item, index) of activity.contacts"
          class="gap-2"
          :key="item + index"
          :people-id="item"
          email
          mobile
        />
      </div>
    </hi-form-item>
    <hi-form-item label="Users">
      <div>
        <system-user-avatar-group :user-ids="activity.users" small />
      </div>
    </hi-form-item>
    <hi-form-item
      v-if="activity.var && activity.var.files && activity.var.files.length"
      label="Files"
    >
      <div class="flex w-full flex-wrap gap-4">
        <hi-vault-file
          v-for="(item, index) in activity.var.files"
          :key="item + index"
          :file-id="item"
          class="h-[110px] w-[150px]"
        />
      </div>
    </hi-form-item>
    <hi-form-item label="Added by">
      <user-details-span :user-id="activity.__createdBy" />
    </hi-form-item>
    <hi-form-item label="Added At">
      <hi-date-time-display :model-value="activity.__dateCreated" />
    </hi-form-item>
  </div>
</template>

<script>
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import SystemUserAvatarGroup from "@/hive-vue3/modules/users/SystemUserAvatarGroup";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiVaultFile from "@/hive-vue3/components/firebase/HiVaultFile";
import { useRouter } from "vue-router";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiOpenInNew } from "@mdi/js";

export default {
  name: "ActivityDetails",
  components: {
    HiIcon,
    HiVaultFile,
    UserDetailsSpan,
    HiDateTimeDisplay,
    SystemUserAvatarGroup,
    ContactsPeople,
    HiFormItem,
  },
  props: {
    activityId: String,
  },
  setup(props) {
    const activity = useFirestoreDocReactiveId(
      "activities",
      props,
      "activityId"
    );

    const router = useRouter();

    function jumpToDetails(target, targetId) {
      switch (target) {
        case "accounts":
          router.push("/accounts/" + targetId);
          break;
        case "enquiry":
          router.push("/enquiries/" + targetId);
          break;
        case "leads":
          router.push("/leads/" + targetId);
          break;
        case "project":
          router.push("/projects/" + targetId);
          break;
      }

      console.log(target, targetId);
    }

    return {
      activity,
      mdiOpenInNew,
      jumpToDetails,
    };
  },
};
</script>

<style scoped></style>
