<template>
  <div class="min-w-[700px]">
    <!--    {{ originalData }}-->
    <hi-form :initial-values="originalData" @submit="submit">
      <hi-form-item label="Mobile">
        <hi-input
          form-key="mobile"
          mobile
          :validator="
            uniqueValueValidator(dbPaths.people, 'mobile', {
              byPassValue: originalData.mobile,
            })
          "
        />
      </hi-form-item>
      <hi-form-item label="Email">
        <hi-input
          form-key="email"
          email
          :validator="
            uniqueValueValidator(dbPaths.people, 'email', {
              byPassValue: originalData.email,
            })
          "
        />
      </hi-form-item>
      <hi-form-item label="Address">
        <hi-input-address form-key="address" />
      </hi-form-item>
    </hi-form>
  </div>
</template>

<script>
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import {
  firestoreDoc,
  uniqueDbValueValidator,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import formatters from "@/hive-vue3/utils/formatters";
export default {
  name: "ContactsPeopleContactInfoEditor",
  props: {
    //none reactive after mounted
    originalData: Object,
  },
  components: { HiInputAddress, HiInput, HiFormItem, HiForm },
  emits: ["successful"],
  setup(props, { emit }) {
    async function submit(data) {
      await updateFirestoreDoc(
        firestoreDoc(dbPaths.people, props.originalData.id),
        data
      );
      emit("successful");
    }
    return {
      dbPaths,
      mobileE164: formatters.mobileE164,
      uniqueValueValidator: uniqueDbValueValidator,
      submit,
    };
  },
};
</script>

<style scoped></style>
