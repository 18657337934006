let lastScrollY;
/**
 * lock document from scrolling.
 * used by modals.
 */
export const lockDocumentScrolling = () => {
  // console.log("model show");
  lastScrollY = window.scrollY;
  const body = document.body;
  body.style.position = "fixed";
  body.style.top = `-${lastScrollY}`;
};
export const unlockDocumentScrolling = () => {
  // console.log("model hide");
  const body = document.body;
  body.style.position = "";
  body.style.top = "";
  window.scrollTo(0, lastScrollY);
};
