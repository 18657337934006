<template>
  <div class="flex items-center">
    <div class="grow w-2/3" v-if="emailData.headers">
      <span class="font-bold" v-html="emailData.headers.from" />

      <div class="tp-subtitle flex">
        <div class="ellipse-flex-grow-item" v-html="emailData.snippet"></div>
      </div>

      <div class="tp-description-sm">
        {{ emailData.inbox ? "Received From" : "Send To" }}
        :
        <span v-if="emailData.inbox" v-html="emailData.headers.from" />
        <span v-else v-html="emailData.headers.to" />
      </div>
    </div>
    <div>
      <div class="tp-description-sm text-right flex flex-col">
        Last refresh:
        <hi-date-time-display :model-value="emailData.internalDate" friendly />
      </div>
    </div>
  </div>
</template>

<script>
import hiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
export default {
  name: "GmailMailSummary",
  components: {
    hiDateTimeDisplay,
  },
  props: {
    emailId: String,
    emailData: Object,
  },
};
</script>

<style scoped></style>
