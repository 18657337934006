import validator from "email-validator";

const phoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
const numbersReg = /^[0-9]*\.?[0-9]*$/;
// const email = /^[\w-.]+@([\w-]+\.)+[\w-]{2,12}$/;
const urlReg = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator
export default {
  email(e) {
    11;
    // console.log("test " + e + email.test(e));
    return validator.validate(e);
  },
  phoneNumber(n) {
    if (n && n.length < 8) return false;
    return phoneNumber.test(n);
  },
  /**
   * Support Au & Cn mobile numbers
   * @param n
   * @returns {boolean}
   */
  mobile(n) {
    if (!n) return false;
    const number = n
      //delete all non-digit except for +
      .replace(/[^\d+]/g, "")
      //replace leading 00
      .replace(/^00/, "+")
      //support incomplete numbers for common mistakes for Australian numbers.
      .replace(/^61/, "+61")
      .replace(/^6104/, "+614")
      //support for Chinese mainland as well.
      .replace(/^86/, "+86");

    return /^(04|\+614)\d{8}$/.test(number);
  },
  url(str) {
    return !!urlReg.test(str);
  },
  number(n) {
    return numbersReg.test(n);
  },
  digits(n) {
    return /^\d+$/.test(n) && Math.round(n);
  },
  digitsLength4(n) {
    return /^\d+$/.test(n) && Math.round(n) < 9999;
  },

  timeRange(start, end) {
    if (start < 0) {
      return new Error("Start time must be greater than 0.");
    }
    const max = 24 * 60;
    if (end > max) {
      return new Error(
        "End time must be less than " + max + ", the maximum minutes of a day."
      );
    }
    if (start > end) {
      return new Error(
        `End time(${end}) should not be greater then start time(${start}).`
      );
    }
    return true;
  },
};
