import $el from "@/hive-vue3/utils/dom/$el";
import { onMounted, onUnmounted, ref } from "vue";

export default function (element, threshold = 0) {
  const visible = ref(false);
  const observer = new IntersectionObserver(
    (inter) => {
      visible.value = inter.length ? inter[0].isIntersecting : false;
      // console.log(i);
      // const rect = $el(element).getBoundingClientRect();
      // const height = window.innerHeight;
      // const width = window.innerWidth;
      // console.log(rect);
      // console.log(width, height);
      // console.log(rect.x > width, rect.y > height);
      // if (rect.x > width || rect.y > height) {
      //   visible.value = false;
      // } else {
      //   visible.value = true;
      // }
    },
    {
      threshold,
    }
  );
  onMounted(() => {
    const el = $el(element);
    observer.observe(el);
  });
  onUnmounted(() => observer.disconnect());
  return visible;
}
