import {
  encodeDocId,
  firestoreDoc,
  newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import { camelize } from "@/hive-vue3/utils/stringUtils";
import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";
import {
  watchPropsImmediately,
  watchPropsImmediatelyAndForever,
} from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";

const dbPaths = {
  UVColor: "/products/_specs/collections/UV/colors",
  RSColor: "/products/_specs/collections/RS/colors",
  allPatterns: "products/_specs/patterns",
  allColors: "/products/_specs/colors",
};

const cache = {
  allFlooringProducts: undefined,
  allPatterns: undefined,
  UVColor: undefined,
  RSColor: undefined,
  allColors: undefined,
};

function initAllFlooringProducts() {
  if (!cache.allFlooringProducts) {
    cache.allFlooringProducts = useFirestoreCollectionAsRefGlobally(
      "products/flooring/allFlooringProducts"
    );
  }
}

function initAllPatterns() {
  if (!cache.allPatterns) {
    cache.allPatterns = useFirestoreCollectionAsRefGlobally(
      "products/_specs/patterns"
    );
  }
}
function initAllColors() {
  if (!cache.allColors) {
    cache.allColors = useFirestoreCollectionAsRefGlobally(dbPaths.allColors);
  }
}

export function getAllActivePatterns() {
  initAllPatterns();

  const returnArr = [];

  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.isActive) {
        if (i.type === "Engineered Flooring") {
          returnArr.unshift(i);
        } else {
          returnArr.push(i);
        }
      }
    }
  }
  return returnArr;
}

export function getPatternList() {
  initAllPatterns();
  const returnArray = [];
  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.isActive) {
        returnArray.unshift(i);
      } else {
        returnArray.push(i);
      }
    }
  }
  return returnArray;
}

export function getAllDeactivatePatterns() {
  initAllPatterns();
  if (cache.allPatterns && cache.allPatterns.value)
    return cache.allPatterns.value.filter((item) => {
      return !item.isActive;
    });
  return [];
}

export function getPatternsSelectObject() {
  initAllPatterns();

  const returnObj = {};
  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.isActive) {
        returnObj[i.id] = i.name;
      }
    }
  }
  return returnObj;
}

export function getPatternSizeList(patternId) {
  initAllFlooringProducts();
  const returnArray = [];
  if (cache.allFlooringProducts && cache.allFlooringProducts.value) {
    for (const i of cache.allFlooringProducts.value) {
      if (i.pattern === patternId) {
        if (i.isActive) {
          returnArray.unshift(i);
        } else {
          returnArray.push(i);
        }
      }
    }
  }
  return returnArray;
}

export function getActivePatternSizeList(type, pattern) {
  initAllFlooringProducts();
  const returnArray = [];
  if (cache.allFlooringProducts && cache.allFlooringProducts.value) {
    for (const i of cache.allFlooringProducts.value) {
      if (i.type === type && i.pattern === pattern) {
        if (i.isActive) {
          returnArray.unshift(i);
        }
      }
    }
  }
  return returnArray;
}

export function getPatternSizeBySizeId(sizeId) {
  initAllFlooringProducts();
  if (cache.allFlooringProducts && cache.allFlooringProducts.value) {
    for (const i of cache.allFlooringProducts.value) {
      // console.log(i);
      if (i.id === sizeId) {
        return i;
      }
    }
    return {};
  }
  return {};
}

export function getPatternIdsByType(type) {
  initAllFlooringProducts();

  const returnArray = [];
  if (cache.allFlooringProducts && cache.allFlooringProducts.value) {
    for (const i of cache.allFlooringProducts.value) {
      if (i.type === type) {
        if (returnArray.indexOf(i.pattern) === -1) {
          const res = getPatternByPatternId(i.pattern);
          if (res && res.isActive) {
            returnArray.unshift(i.pattern);
          } else {
            returnArray.push(i.pattern);
          }
        }
      }
    }
  }

  return returnArray;
}

export function getPatternByPatternId(patternId) {
  initAllPatterns();

  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.id === patternId) {
        return i;
      }
    }
  }

  return null;
}

export function usePatternList(
  collectionPath,
  reactiveObj,
  valueKey,
  options = { persistData: false }
) {
  const { docData, setId } = useFirestoreDocQueryable(
    collectionPath,
    null,
    options
  );
  if (options.persistData) {
    watchPropsImmediatelyAndForever(reactiveObj, valueKey, (v) =>
      setId(encodeDocId(camelize(v)))
    );
  } else {
    watchPropsImmediately(reactiveObj, valueKey, (v) =>
      setId(encodeDocId(camelize(v)))
    );
  }
  return docData;
}

export function getPatternByShortNumWithIgnoreId(shortNum, ignoreId) {
  initAllPatterns();
  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.shortNum === shortNum) {
        if (i.id !== ignoreId) return i;
      }
    }
  }
  return null;
}

export async function updatePatternDetails(patternId, patternData) {
  console.log();
  if (!getPatternByShortNumWithIgnoreId(patternData.shortNum, patternId)) {
    await updateFirestoreDoc(
      firestoreDoc("products/_specs/patterns", patternId),
      patternData
    );
    return true;
  }
  return false;
}

export function getPatternByShortNum(shortNum) {
  initAllPatterns();
  if (cache.allPatterns && cache.allPatterns.value) {
    for (const i of cache.allPatterns.value) {
      if (i.shortNum === shortNum) {
        return i;
      }
    }
  }
  return null;
}

export function getColorById(colorId) {
  initAllColors();
  if (cache.allColors && cache.allColors.value) {
    for (const i of cache.allColors.value) {
      if (i.id === colorId) {
        return i;
      }
    }
  }
  return null;
}

export async function addNewPatternToFirebase(formData) {
  if (getPatternByShortNum(formData.shortNum)) {
    return false;
  }
  await newFirestoreDoc(dbPaths.allPatterns, formData);
  return true;
}

export function getColorsByType(type) {
  initAllColors();

  const returnArr = [];

  if (cache.allColors && cache.allColors.value) {
    for (const i of cache.allColors.value) {
      if (i.type === type) {
        returnArr.push(i);
      }
    }
  }

  return returnArr;
}

export function getAllActiveColors() {
  initAllColors();

  const emergingColors = [];
  const enhancingColors = [];

  if (cache.allColors && cache.allColors.value) {
    for (const i of cache.allColors.value) {
      if (i.isActive) {
        if (i.collection === "Emerging") {
          emergingColors.push(i);
        } else {
          enhancingColors.push(i);
        }
      }
    }
  }

  emergingColors.sort((a, b) => {
    return parseInt(a.shortNum) - parseInt(b.shortNum);
  });
  enhancingColors.sort((a, b) => {
    return parseInt(a.shortNum) - parseInt(b.shortNum);
  });

  return [...emergingColors, ...enhancingColors];
}

export function getAllColorsByType(type) {
  initAllColors();
  const res = getColorsByType(type);
  const returnArray = [];
  if (res && res.length) {
    for (const i of res) {
      if (i && i.isActive) {
        returnArray.unshift(i);
      } else {
        returnArray.push(i);
      }
    }
  }

  returnArray.sort((a, b) => {
    return parseInt(a.shortNum) - parseInt(b.shortNum);
  });

  return returnArray;
}
export function getAllDeactivateColorsByType(type) {
  initAllPatterns();
  const res = getColorsByType(type);

  return res.filter((item) => {
    return !item.isActive;
  });
}

export function getColorByShortNum(shortNum) {
  initAllColors();

  if (cache.allColors && cache.allColors.value) {
    for (const i of cache.allColors.value) {
      if (i.shortNum === shortNum) {
        return i;
      }
    }
  }

  return null;
}

export function getColorByTypeAndId(type, id) {
  initAllColors();
  if (type === "UV") {
    if (cache.UVColor && cache.UVColor.value) {
      for (const i of cache.UVColor.value) {
        if (i.id === id) {
          return i;
        }
      }
    }
  } else {
    if (cache.RSColor && cache.RSColor.value) {
      for (const i of cache.RSColor.value) {
        if (i.id === id) {
          return i;
        }
      }
    }
  }
  return null;
}

export function getColorByTypeAndShortNumWithIgnoreId(shortNum, ignoreId) {
  initAllColors();

  if (cache.allColors && cache.allColors.value) {
    for (const i of cache.allColors.value) {
      if (i.shortNum === shortNum) {
        if (i.id !== ignoreId) return i;
      }
    }
  }

  return null;
}

export async function addColorsToFirebase(formData) {
  if (!getColorByShortNum(formData.shortNum)) {
    await newFirestoreDoc(dbPaths.allColors, formData);
    return true;
  }
}

export async function updateColorToFirebase(formData, id) {
  if (!getColorByTypeAndShortNumWithIgnoreId(formData.shortNum, id)) {
    await updateFirestoreDoc(firestoreDoc(dbPaths.allColors, id), formData);
    return true;
  }
}

export function checkRepeatPatternData(data) {
  initAllFlooringProducts();
  const { size, thickness, veneer, type, pattern } = data;

  if (cache.allFlooringProducts && cache.allFlooringProducts.value) {
    for (const i of cache.allFlooringProducts.value) {
      if (
        i.thickness === thickness &&
        i.veneer === veneer &&
        i.type === type &&
        i.pattern === pattern
      ) {
        if (size.length === i.size.length) {
          let flag = true;
          for (const t of size) {
            if (
              i.size.findIndex((item) => {
                return item.length === t.length && item.width === t.width;
              }) === -1
            ) {
              flag = false;
            }
          }

          if (flag) {
            return false;
          }
        }
      }
    }
  }
  return true;
}

export function getProductShortNumString(projectSpecItem) {
  console.log(projectSpecItem);
  if (
    projectSpecItem.pattern &&
    projectSpecItem.color &&
    projectSpecItem.size &&
    projectSpecItem.size.size[0]
  ) {
    let productClass = "";
    if (projectSpecItem.grade === "prime") {
      productClass = "AB";
    } else if (projectSpecItem.grade === "medium") {
      productClass = "ABC";
    } else {
      productClass = "ABCD";
    }

    return `${projectSpecItem.pattern.shortNum} - ${projectSpecItem.color.shortNum} - ${projectSpecItem.size.size[0].width} - ${projectSpecItem.size.veneer} - ${productClass}`;
  }
  return "";
}

export async function newSizeAndPriceToFirebase(formData) {
  if (checkRepeatPatternData(formData)) {
    await newFirestoreDoc(`products/flooring/allFlooringProducts`, formData);
    return true;
  } else {
    return false;
  }
}

export async function updateSizeAndPriceDetails(id, data) {
  await updateFirestoreDoc(
    firestoreDoc(`products/flooring/allFlooringProducts`, id),
    data
  );
}
