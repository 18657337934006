<template>
  <div
    class="w-full h-full overflow-x-hidden overflow-y-auto absolute top-0 flex flex-col"
    ref="container"
    v-if="visible"
  >
    <div v-if="conf.navi && !home" class="mb-4">
      <hi-stage-link back>
        <hi-icon-item class="clickable text-sm" :icon="mdiChevronLeft">
          Back to {{ parent }}
        </hi-icon-item>
      </hi-stage-link>
    </div>
    <div class="flex-grow">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import HiStageLink from "./HiStageLink";
import { templateRef } from "@vueuse/core";
import HiIconItem from "@/hive-vue3/components/HiIconItem";
import { mdiChevronLeft } from "@mdi/js";
import { capitalize } from "@/hive-vue3/utils/stringUtils";

export default {
  name: "HiStage",
  components: { HiIconItem, HiStageLink },
  props: {
    home: Boolean,
    name: String,
    label: String,
  },
  setup(props) {
    const container = templateRef("container");
    function name() {
      if (!props.home && !props.name) {
        console.warn("Stage name must provided for none home stages.");
        return "NoName";
      }
      return props.home ? "home" : props.name;
    }
    function label() {
      return props.label || capitalize(name());
    }

    const parent = inject("hi-stages-parent", undefined);
    if (!parent) {
      console.warn("HiStage can only be child of HiStages!");
      return;
    }
    const register = inject("hi-stages-register");
    register(name(), label(), container);
    // onMounted(() => {
    //   console.log("register", name(), container);
    //   register.element(name(), container);
    // });

    const aliveInstances = inject("hi-stage-aliveInstances");
    const visible = computed(() => aliveInstances[name()]);

    const conf = inject("hi-stage-conf");

    return {
      parent,
      visible,
      mdiChevronLeft,
      conf,
    };
  },
};
</script>

<style scoped></style>
