<template>
  <div>
    <transition-fade>
      <hi-view-loading v-if="loading" />
      <div class="error text-error" v-else-if="error">{{ error }}</div>
      <div class="w-full h-full" v-else>
        <slot :result="result">{{ result }}</slot>
      </div>
    </transition-fade>
  </div>
</template>

<script>
import { ref } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiViewLoading from "@/hive-vue3/views/HiViewLoading";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";

export default {
  name: "HiSimpleRemoteRequest",
  components: { HiViewLoading, TransitionFade },
  props: {
    param: null,
    requestFn: Function,
    modelValue: Object,
  },
  setup(props, { emit }) {
    const result = modelRef(props, emit);
    const loading = ref(false);
    const error = ref(null);
    watchPropsImmediately(props, "param", async (param) => {
      loading.value = true;
      error.value = null;
      try {
        const re = await props.requestFn(param);
        if (re.successful) {
          result.value = re.data;
        } else {
          error.value = re.errorMessage;
        }
        // console.log(re);
      } catch (err) {
        console.log(err);
        error.value = err;
      } finally {
        loading.value = false;
      }
    });
    return {
      result,
      loading,
      error,
    };
  },
};
</script>

<style scoped></style>
