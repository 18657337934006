<template>
  <hi-form-dialog-hive-standard
    :submit-fn="submit"
    width="600px"
    title="Invite New System User"
    button-text="Invite New User"
    text-button
  >
    <hi-form-item label="Email*"
      ><hi-input mandatory form-key="email" model-value="tst@asdf.com"
    /></hi-form-item>
    <hi-form-item label="Name*"
      ><hi-input mandatory form-key="name" model-value="tst"
    /></hi-form-item>
    <hi-form-item label="Surname*"
      ><hi-input mandatory form-key="surname" model-value="tst"
    /></hi-form-item>
    <hi-form-item label="Roles">
      <hi-chips-selectable
        multiple
        :items="roles"
        value-key="roleValue"
        text-key="RoleName"
        form-key="roles"
      />
    </hi-form-item>
  </hi-form-dialog-hive-standard>
</template>

<script>
import hiFormItem from "@/hive-vue3/components/form/HiFormItem";
import hiInput from "@/hive-vue3/components/form/controls/HiInput";
import hiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import {
  encodeDocId,
  firestoreDoc,
  updateFirestoreDoc,
  useSystemRoles,
} from "@/hive-vue3/firebase";
import HiFormDialogHiveStandard from "@/hive-vue3/components/form/HiFormDialogHiveStandard";
import { serverTimestamp } from "firebase/firestore";
// import { requestSendInvitationEmail } from "@/hive-vue3/api/request";
export default {
  name: "DialogInviteSystemUser",
  components: {
    HiFormDialogHiveStandard,
    hiFormItem,

    hiInput,
    hiChipsSelectable,
  },
  setup() {
    const roles = useSystemRoles();

    const submit = async (data) => {
      data.invitedAt = serverTimestamp();
      // const timestamp = new Date().getTime();
      const key = encodeDocId(data.email);
      const doc = firestoreDoc("system", "userInvitations");
      if (!data.roles || !data.roles.length) delete data.roles;
      console.log(data);
      try {
        await updateFirestoreDoc(doc, { [key]: data }, { meta: false });
        // 发送邀请邮件
        // await requestSendInvitationEmail(data.email, data.name);

        return { successful: true };
      } catch (e) {
        return { errorMessage: e };
      }
    };
    return {
      roles,
      submit,
    };
  },
};
</script>

<style scoped></style>
