<template>
  <div class="min-w-full max-h-full overflow-hidden relative">
    <div
      class="absolute inset-0 pointer-events-none"
      ref="autoItemsPerPageRef"
    ></div>
    <transition-fade>
      <hi-view-loading v-if="loading"></hi-view-loading>
      <hi-view-no-data v-else-if="noData">{{ noDataText }}</hi-view-no-data>
      <hi-view-no-result v-else-if="noResult">{{
        noResultText
      }}</hi-view-no-result>
      <hi-markable
        v-else
        class="w-full h-full block"
        :search="searchValue"
        :separate-word-search="false"
      >
        <hi-list
          selectable
          :no-padding="noPadding"
          :items="currentPageItems"
          class="max-h-full overflow-hidden w-full z-0"
          :class="{ absolute: autoItemsPerPage }"
          @select="selectItem"
          v-model="state.currentItemId"
          :item-height="itemHeightStyle"
          value-key="id"
          keyboard-on-focus
        >
          <template v-slot="{ item }">
            <div
              @select.stop
              class="flex flex-col justify-center items-stretch"
              :style="{
                'min-height': minItemHeight + 'px',
              }"
            >
              <slot v-bind="{ item }">{{ item }}</slot>
            </div>
          </template>
        </hi-list>
      </hi-markable>
    </transition-fade>
    <!--      <div-->
    <!--        v-else-->
    <!--        class="max-h-full overflow-hidden w-full z-0"-->
    <!--        :class="{ absolute: autoItemsPerPage }"-->
    <!--      >-->
    <!--        <div-->
    <!--          v-for="item in currentPageItems"-->
    <!--          :key="item.id || item"-->
    <!--          class="cursor-default select-none max-w-full"-->
    <!--          @click="selectItem(item)"-->
    <!--          :style="{-->
    <!--            'min-height': minItemHeight + 'px',-->
    <!--            height: itemHeightStyle,-->
    <!--          }"-->
    <!--          :class="{-->
    <!--            ['hi-select-item']: !noPadding,-->
    <!--            ['hi-select-item-no-padding']: noPadding,-->
    <!--            '-active': isSelected(item),-->
    <!--            'items-center': verticalCenterItem,-->
    <!--          }"-->
    <!--        >-->
    <!--          <slot v-bind="{ item }">{{ item }}</slot>-->
    <!--        </div>-->
    <!--      </div>-->
  </div>
</template>

<script>
import { injectDataListStore } from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import { templateRef } from "@vueuse/core";
import { computed, toRefs } from "vue";
import HiViewLoading from "@/hive-vue3/views/HiViewLoading";
import HiViewNoResult from "@/hive-vue3/views/HiViewNoResult";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiList from "@/hive-vue3/components/HiList";
import HiMarkable from "@/hive-vue3/components/HiMarkable";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default {
  name: "HiDataList",
  components: {
    HiMarkable,
    HiList,
    HiViewLoading,
    HiViewNoResult,
    HiViewNoData,
    TransitionFade,

    // HiMarkable,
  },
  props: {
    minItemHeight: [Number, String],
    autoItemsPerPage: Boolean,
    noDataText: {
      type: String,
      default: "No data yet...",
    },
    noResultText: {
      type: String,
      default: "Nothing found...",
    },
    noPadding: Boolean,
    currentId: String,
  },
  emits: ["select"],
  setup(props, { emit }) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    // const marker = templateRef("marker");
    const store = injectDataListStore();
    if (!store) {
      console.error(
        "HiDataList must have parent component provide a listStore!"
      );
      return;
    }
    const { state, actions } = store;
    // console.log(store);
    const {
      loading,
      currentPageItems,
      currentItemId,
      searchValue,
      noResult,
      noData,
      ready,
    } = toRefs(state);

    const container = templateRef("autoItemsPerPageRef");
    const itemHeightStyle = computed(() => {
      return props.autoItemsPerPage ? state.autoItemHeight + "px" : "";
    });
    // function isSelected(item) {
    //   if (!item || !state.currentItemId) return false;
    //   if (item.id) return state.currentItemId.id === item.id;
    //   return state.currentItemId === item;
    // }
    if (props.autoItemsPerPage) {
      actions.autoItemsPerPage(container, props.minItemHeight);
    }
    function selectItem(item) {
      // console.log(item);
      emit("select", item);
      // actions.setCurrentItemId(item.id);
    }

    watchPropsImmediately(props, "currentId", (v) => {
      actions.setCurrentItemId(v);
    });
    // watch(displayItems, () => marker.value && marker.value.mark());
    // console.log(displayItems);

    return {
      currentPageItems,
      selectItem,
      itemHeightStyle,
      currentItemId,
      loading,
      searchValue,
      noResult,
      noData,
      ready,
      // isSelected,
      state,
    };
  },
};
</script>

<style scoped></style>
