<template>
  <div class="w-full h-full">
    <transition-fade :y-percent="-5">
      <center-box class="absolute-full text-primary" v-if="!authLoaded">
        <hi-spinner style="width: 5vw" />
      </center-box>
    </transition-fade>
    <div v-if="loggedIn" class="w-full h-full">
      <slot />
    </div>
    <!--    this router-view is only for log in pape-->
    <router-view class="w-full h-full" v-else-if="authLoaded" />
  </div>
</template>

<script>
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import HiSpinner from "@/hive-vue3/components/HiSpinner";
import { useAuthLoaded, useLoggedIn } from "@/hive-vue3/firebase";

/**
 * A viewport to display app only when logged in. A spinner is displayed when loading auth info.
 */
export default {
  name: "AuthView",
  components: { HiSpinner, CenterBox, TransitionFade },
  setup() {
    const loggedIn = useLoggedIn();
    const authLoaded = useAuthLoaded();
    return {
      loggedIn,
      authLoaded,
    };
  },
};
</script>

<style scoped></style>
