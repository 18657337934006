<template>
  <module-layout-block-editable
    :editable="editable"
    :dialog-title="'Edit contact info for ' + entity.name"
    class="view-block-spacious"
  >
    <module-info-block :data="entity">
      <module-labeled-info value-key="email" />
      <module-labeled-info value-key="phone" />
      <module-labeled-info value-key="address">
        <hi-address-display :model-value="entity.address" />
      </module-labeled-info>
      <module-labeled-info value-key="addressPostal" label="Postal Address">
        <hi-address-display :model-value="entity.addressPostal" />
      </module-labeled-info>
    </module-info-block>

    <div v-if="!entity.email && !entity.mobile && !entity.address">
      No contact info yet.
    </div>
    <!-- ======================the editor dialog-->
    <template v-if="editable" v-slot:editor="{ closeDialog }">
      <contacts-entity-contact-info-editor
        :original-data="entity"
        @successful="closeDialog"
      />
    </template>
  </module-layout-block-editable>
</template>

<script>
import ModuleInfoBlock from "@/hive-vue3/modules/_common/ModuleInfoBlock";
import ModuleLayoutBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import ModuleLabeledInfo from "@/hive-vue3/modules/_common/ModuleLabeledInfo";
import HiAddressDisplay from "@/hive-vue3/components/misc/HiAddressDisplay";
import { computedEntityByIdOrData } from "@/hive-vue3/modules/contacts/data";
import ContactsEntityContactInfoEditor from "@/hive-vue3/modules/contacts/ContactsEntityContactInfoEditor";

export default {
  name: "ContactsEntityContactInfo",
  components: {
    ContactsEntityContactInfoEditor,
    ModuleInfoBlock,
    ModuleLayoutBlockEditable,
    ModuleLabeledInfo,
    HiAddressDisplay,
  },
  props: {
    entityId: String,
    entityData: Object,
    editable: Boolean,
  },
  setup(props) {
    return {
      entity: computedEntityByIdOrData(props),
    };
  },
};
</script>

<style scoped></style>
