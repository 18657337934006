import { isRef } from "vue";

/**
 *
 * @param element can be #id HTMLElement, ref or vm
 * @param warnIfNotElement
 * @returns {HTMLElement}
 */
export default function $el(element, warnIfNotElement = false) {
  if (typeof element === "string") {
    element = document.getElementById(element);
  } else if (isRef(element)) {
    element = element.value;
  }
  //if element argument is a Vue VM
  if (element && element.$el) {
    element = element.$el;
  }
  if (warnIfNotElement && !isElement(element)) {
    console.warn("Found none Element where Element is required.", element);
  }
  return element;
}

//Returns true if it is a DOM node
export function isNode(o) {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}

//Returns true if it is a DOM element
export function isElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}
