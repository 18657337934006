<template>
  <div>
    <div class="flex px-4 pt-2 border-b-2 font-bold">
      <div class="min-w-[180px]">Size</div>
      <div class="grow">
        <div class="flex justify-around">
          <div class="min-w-[70px]"></div>
          <div class="min-w-[125px]">Prime</div>
          <div class="min-w-[125px]">Medium</div>
          <div class="min-w-[125px]">Practical</div>
        </div>
      </div>
    </div>
    <hi-list no-padding :items="sizeList">
      <template v-slot="{ item: item }">
        <hi-dialog-trigger>
          <div
            class="flex p-4"
            :style="{
              'background-color': item.isActive
                ? ''
                : 'rgba(180, 190, 180, 0.2)',
              color: item.isActive ? '' : 'grey',
            }"
          >
            <div class="min-w-[180px]">
              <div class="w-[155px]" style="text-align-last: justify">
                <div>
                  {{
                    `${item.size[0].length} × ${item.size[0].width} × ${item.thickness} - ${item.veneer}`
                  }}
                </div>
                <div v-if="item.size.length === 2">
                  {{
                    `${item.size[1].length} × ${item.size[1].width} × ${item.thickness} - ${item.veneer}`
                  }}
                </div>
              </div>
            </div>
            <div class="grow">
              <div class="flex justify-around">
                <div class="font-bold min-w-[70px]">UV Price</div>
                <div class="min-w-[125px]">
                  {{
                    item.UV.prime
                      ? `$${item.UV.prime.toLocaleString()}`
                      : "On Application"
                  }}
                </div>
                <div class="min-w-[125px]">
                  {{ item.UV.medium ? `$${item.UV.medium}` : "On Application" }}
                </div>
                <div class="min-w-[125px]">
                  {{
                    item.UV.practical
                      ? `$${item.UV.practical}`
                      : "On Application"
                  }}
                </div>
              </div>
              <div class="flex justify-around">
                <div class="font-bold min-w-[70px]">RS Price</div>
                <div class="min-w-[125px]">
                  {{
                    item.RS.prime
                      ? `$${item.RS.prime.toLocaleString()}`
                      : "On Application"
                  }}
                </div>
                <div class="min-w-[125px]">
                  {{ item.RS.medium ? `$${item.RS.medium}` : "On Application" }}
                </div>
                <div class="min-w-[125px]">
                  {{
                    item.RS.practical
                      ? `$${item.RS.practical}`
                      : "On Application"
                  }}
                </div>
              </div>
            </div>
          </div>
          <product-edit-size-price-dialog :current-item="item" />
        </hi-dialog-trigger>
      </template>
    </hi-list>
  </div>
</template>

<script>
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { productState } from "@/hive-vue3/modules/products/state";
import { camelize } from "@/hive-vue3/utils/stringUtils";
import { computed } from "vue";
import HiList from "@/hive-vue3/components/HiList";
import { getPatternSizeList } from "@/hive-vue3/modules/products/data";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ProductEditSizePriceDialog from "@/hive-vue3/modules/products/productEditSizePriceDialog";

export default {
  name: "productsSizeFullCard",
  components: {
    ProductEditSizePriceDialog,
    HiDialogTrigger,
    HiList,
  },
  setup() {
    const sizeList = computed(() => {
      if (productState.currentPattern)
        return getPatternSizeList(productState.currentPattern);
      else return [];
    });
    return {
      commonIcons,
      productState,
      camelize,
      sizeList,
    };
  },
};
</script>

<style scoped>
.circle-active {
  width: 10px;
  height: 10px;
  background-color: #0cd70c;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.circle-deActive {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
