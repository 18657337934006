<template>
  <hi-list :items="accounts">
    <template v-slot="{ item }">
      <div class="flex items-center gap-4">
        <account-list-summary :account-data="item" class="grow" />

        <hi-icon
          class="clickable-icon"
          :path="mdiOpenInNew"
          title="Link Account"
          @click="jump(item.id)"
        />
      </div>
    </template>
  </hi-list>
</template>

<script>
import { computed } from "vue";
import { contactsState } from "@/hive-vue3/modules/contacts/state";
import {
  getAccountsByPeopleId,
  jumpToAccount,
} from "@/hive-vue3/modules/accounts/data";
import HiList from "@/hive-vue3/components/HiList";
import AccountListSummary from "@/hive-vue3/modules/accounts/AccountListSummary";
import { mdiOpenInNew } from "@mdi/js";
import { useRouter } from "vue-router";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "PeopleAccountsList",
  components: { HiIcon, AccountListSummary, HiList },
  setup() {
    const accounts = computed(() => {
      return getAccountsByPeopleId(contactsState.selectedPeopleId);
    });
    const router = useRouter();
    function jump(enquiryId) {
      jumpToAccount(router, enquiryId);
    }
    return {
      accounts,
      mdiOpenInNew,
      jump,
    };
  },
};
</script>

<style scoped></style>
