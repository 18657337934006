<template>
  <hi-dialog title="Edit Price" close-button width="600px" v-model="open">
    <hi-form-hive-standard
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      cancel-button
      :initial-values="currentItem"
    >
      <div class="p-2 border-2 border border-dashed space-y-1">
        <div>Pattern Size Details</div>
        <div class="flex gap-4">
          <products-pattern-summary
            :pattern-id="currentItem.pattern"
            no-status
          />
          <div class="pr-5 grow">
            <div class="py-1">Size(s):</div>
            <div class="min-w-[180px]">
              <div class="w-[155px]" style="text-align-last: justify">
                <div>
                  {{
                    `${currentItem.size[0].length} × ${currentItem.size[0].width} × ${currentItem.thickness} - ${currentItem.veneer}`
                  }}
                </div>
                <div v-if="currentItem.size.length === 2">
                  {{
                    `${currentItem.size[1].length} × ${currentItem.size[1].width} × ${currentItem.thickness} - ${currentItem.veneer}`
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 border-2 border border-dashed space-y-1">
        <div>UV Lacquer Price</div>

        <hi-form-item label="Prime*">
          <hi-input
            form-key="UV.prime"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Medium*">
          <hi-input
            form-key="UV.medium"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Practical*">
          <hi-input
            form-key="UV.practical"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>
      </div>

      <div class="p-2 border-2 border border-dashed space-y-1">
        <div>Reactive Stain Price</div>
        <hi-form-item label="Prime*">
          <hi-input
            form-key="RS.prime"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Medium*">
          <hi-input
            form-key="RS.medium"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>

        <hi-form-item label="Practical*">
          <hi-input
            form-key="RS.practical"
            lock-by-suffix
            suffix-text="O/A"
            locked-text="On Application"
            :locked-value="0"
            finance
            mandatory
          />
        </hi-form-item>
      </div>
    </hi-form-hive-standard>
    <template v-slot:more>
      <hi-button
        v-if="currentItem.isActive"
        class="ml-5"
        @click="deactivatePattern"
        >Deactivate</hi-button
      >
      <hi-button v-else class="ml-5" @click="reactivatePattern"
        >Reactivate</hi-button
      >
    </template>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { ref } from "vue";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import ProductsPatternSummary from "@/hive-vue3/modules/products/productsPatternSummary";
import HiButton from "@/hive-vue3/components/HiButton";
import { updateSizeAndPriceDetails } from "@/hive-vue3/modules/products/data";
export default {
  name: "productEditSizePriceDialog",
  components: {
    HiButton,
    ProductsPatternSummary,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    currentItem: Object,
  },
  setup(props) {
    const open = ref(false);

    async function submit(data) {
      await updateSizeAndPriceDetails(props.currentItem.id, data);
      return { successful: true };
    }

    async function deactivatePattern() {
      if (props.currentItem && props.currentItem.id) {
        await updateSizeAndPriceDetails(props.currentItem.id, {
          isActive: false,
        });
        open.value = false;
      }
    }
    async function reactivatePattern() {
      if (props.currentItem && props.currentItem.id) {
        await updateSizeAndPriceDetails(props.currentItem.id, {
          isActive: true,
        });
        open.value = false;
      }
    }

    return {
      open,
      submit,
      deactivatePattern,
      reactivatePattern,
    };
  },
};
</script>

<style scoped></style>
