<template>
  <div class="flex-center w-full h-full flex-col">
    <hi-spinner class="w-5vh opacity-50" />
    <div><slot>Loading...</slot></div>
  </div>
</template>

<script>
import HiSpinner from "@/hive-vue3/components/HiSpinner";

export default {
  name: "HiViewLoading",
  components: { HiSpinner },
};
</script>

<style scoped></style>
