import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import "./hive-vue3/styles/uiControls.scss";
import "./hive-vue3/styles/thin-scrollbar.scss";
import { initializeFirebaseWithAuth } from "@/hive-vue3/firebase";
import { firebaseConfig } from "@/conf/firebase";

initializeFirebaseWithAuth(firebaseConfig, router);

createApp(App).use(router).mount("#app");
