<template>
  <div v-if="people" class="view-space-divided">
    <div class="flex">
      <div class="view-block-spacious grow">
        <contacts-people
          class="tp-big-fancy-display pb-3"
          :people-data="people"
          title
          gender
        />
        <hi-tags
          small
          v-if="people.tags"
          :model-value="people.tags"
          :tag-doc-path="dbPaths.peopleTagDoc"
        >
        </hi-tags>
      </div>
      <hi-icon
        class="clickable-icon"
        :path="mdiOpenInNew"
        title="Link Account"
        @click="jump(people.id)"
      />
    </div>
    <contacts-people-contact-info
      class="view-block-spacious"
      :people-data="people"
    />
    <contacts-entities-by-people-view :people-data="people" />
  </div>
</template>

<script>
import {
  computedPeopleByIdOrData,
  jumpToPeopleByPeopleId,
} from "@/hive-vue3/modules/contacts/data";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import HiTags from "@/hive-vue3/components/HiTags";
import dbPaths from "./dbPaths";
import ContactsPeopleContactInfo from "@/hive-vue3/modules/contacts/ContactsPeopleContactInfo";
import ContactsEntitiesByPeopleView from "@/hive-vue3/modules/contacts/ContactsEntitiesByPeopleView";
import { mdiOpenInNew } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { useRouter } from "vue-router";
export default {
  name: "ContactsPeopleOverview",
  components: {
    HiIcon,
    ContactsEntitiesByPeopleView,
    ContactsPeopleContactInfo,
    HiTags,
    ContactsPeople,
  },
  props: {
    peopleId: String,
    peopleData: Object,
  },
  setup(props) {
    const people = computedPeopleByIdOrData(props);

    const router = useRouter();
    function jump(peopleId) {
      jumpToPeopleByPeopleId(router, peopleId);
    }
    return {
      people,
      dbPaths,
      mdiOpenInNew,
      jump,
    };
  },
};
</script>

<style scoped></style>
