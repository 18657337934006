<template>
  <module-title-card title="Stakeholders">
    <template v-slot:tools>
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link contact"
        />
        <project-add-contact-dialog
          :project-id="projectsState.currentProjectId"
        />
      </hi-dialog-trigger>
    </template>

    <hi-list
      class="grow overflow-auto"
      v-if="projectData && projectData.contacts && projectData.contacts.length"
      :items="projectData.contacts"
    >
      <template v-slot="{ item, index }">
        <div
          class="flex w-full items-center"
          @click="showContactInfo(item, index)"
        >
          <div class="w-[70px] min-w-[70px] tp-small-label">
            {{ item.type }}
          </div>
          <div class="w-[130px] min-w-[130px] pl-2">
            <contacts-people :people-id="item.peopleId" />
          </div>
          <div class="grow pl-2">
            <contacts-entity :entity-id="item.entityId" />
          </div>
        </div>
      </template>
    </hi-list>

    <hi-dialog
      title="Project Stakeholder"
      close-button
      width="680px"
      v-model="open"
    >
      <div v-if="status.currentItem">
        <div class="tp-description-sm">
          Add by:
          <user-details-span :user-id="status.currentItem.addBy" no-bold /> at
          <hi-date-time-display :model-value="status.currentItem.addDate" />
        </div>
        <contacts-people-overview
          v-if="status.currentItem.peopleId"
          :people-id="status.currentItem.peopleId"
        />
        <contacts-entity-overview
          v-else
          :entity-id="status.currentItem.entityId"
        />
      </div>
      <template v-slot:footer>
        <hi-button
          v-if="status.currentItem"
          text
          @click="
            removeContactFromProjectByIndex(
              projectData.id,
              status.currentIndex
            );
            open = false;
          "
        >
          Remove&nbsp;<contacts-people
            v-if="status.currentItem.peopleId"
            :people-id="status.currentItem.peopleId"
            class="font-bold"
          />&nbsp;<contacts-entity
            v-else
            :entity-id="status.currentItem.entityId"
            class="font-bold"
          />&nbsp; From Project&nbsp;
          <span class="font-bold"> {{ projectData.name }}</span>
        </hi-button>
      </template>
    </hi-dialog>
  </module-title-card>
</template>

<script>
import ModuleTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import { computed, reactive, ref } from "vue";
import HiList from "@/hive-vue3/components/HiList";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import {
  getProjectById,
  removeContactFromProjectByIndex,
} from "@/hive-vue3/modules/projects/data";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiIcon from "@/hive-vue3/components/HiIcon";
import ProjectAddContactDialog from "@/hive-vue3/modules/projects/ProjectAddStakehoderDialog";
import ContactsPeopleOverview from "@/hive-vue3/modules/contacts/ContactsPeopleOverview";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiButton from "@/hive-vue3/components/HiButton";
import ContactsEntityOverview from "@/hive-vue3/modules/contacts/ContactsEntityOverview";
export default {
  name: "ProjectContactsViewCard",
  components: {
    ContactsEntityOverview,
    HiButton,
    HiDialog,
    ContactsEntity,
    ContactsPeople,
    ContactsPeopleOverview,
    ProjectAddContactDialog,
    HiIcon,
    HiDialogTrigger,
    UserDetailsSpan,
    HiDateTimeDisplay,
    HiList,
    ModuleTitleCard,
  },
  setup() {
    const open = ref(false);
    const status = reactive({
      currentItem: undefined,
      currentIndex: undefined,
    });

    function showContactInfo(item, index) {
      status.currentItem = item;
      status.currentIndex = index;
      open.value = true;
    }

    return {
      open,
      commonIcons,
      projectsState,
      projectData: computed(() => {
        return getProjectById(projectsState.currentProjectId);
      }),
      removeContactFromProjectByIndex,
      showContactInfo,
      status,
    };
  },
};
</script>

<style scoped></style>
