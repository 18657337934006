<template>
  <span @click="click" ref="span">
    <slot />
  </span>
</template>

<script>
import { provide, ref } from "vue";

export default {
  name: "HiDialogTrigger",
  setup() {
    const span = ref();
    const control = ref(false);
    provide("dialog-controller", control);
    function click() {
      // console.log(span.value);
      for (const i of span.value.querySelectorAll(".hi-btn")) {
        if (i.getAttribute("class").indexOf("disabled") !== -1) {
          return;
        }
      }
      for (const i of span.value.querySelectorAll(".hi-trigger-btn")) {
        if (i.getAttribute("class").indexOf("disabled") !== -1) {
          return;
        }
      }
      control.value = true;
      // console.log("clicked");
    }
    return {
      click,
      span,
    };
  },
};
</script>

<style scoped></style>
