import { computed } from "vue";

/**
 * 根据 props 给定的参数生成标准化数组
 * [{
 *   text
 *   value
 *   secondaryText //if exists
 * }...]
 * props used:
 * items: data object array or object
 * valueKey: key for value, default "value"
 * textKey: key for text, default "text"
 * secondaryTextKey: key for secondary text, default null
 * removePrivateItems: remove key starting from "__", if doc provided, auto remove private items.
 *
 * @param props
 * @param doc the reactive firestore doc object
 * @returns {ComputedRef<Array>}
 */
export default function (props, doc = null) {
  return computed(() => {
    const items = props.items || doc;
    if (Array.isArray(items)) {
      // console.log("items isArray");
      if (props.valueKey || props.textKey || props.secondaryTextKey) {
        const arr = [];
        // if (items && items.value) items = items.value;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const obj = {};
          if (typeof item == "string") {
            obj.value = obj.text = item;
          } else {
            obj.text = item[props.textKey || "text"];
            obj.value = item[props.valueKey || "value"] || obj.text;
            if (props.secondaryTextKey) {
              obj.secondaryText = item[props.secondaryTextKey];
            }
          }

          arr.push(obj);
        }
        return arr;
      } else {
        // console.log("original items returned.");
        return items;
      }
    } else {
      const arr = [];
      // console.log(items);
      // if (items && items.value) items = items.value;
      for (const key in items) {
        // console.log(props.removePrivateItems);
        //to support private keys starting with "__"
        if (props.removePrivateItems || doc) {
          if (key.indexOf("__") === 0) continue;
        }
        arr.push({ value: key, text: items[key] });
      }
      //sort alphabetically
      return arr.sort((a, b) =>
        a.text.localeCompare(b.text, "en", { sensitivity: "base" })
      );
    }
  });
}
