<template>
  <div class="overflow-hidden relative" style="min-height: 100px">
    <hi-loading-mask :model-value="loadingState" /><slot />
  </div>
</template>

<script>
import { computed, nextTick, provide, reactive, ref, watchEffect } from "vue";
import gsap from "gsap";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import HiLoadingMask from "@/hive-vue3/components/overlays/HiLoadingMask";

export default {
  name: "HiStageGroup",
  components: { HiLoadingMask },
  props: {
    /**
     * not implemented yet.
     */
    keepHistoryAlive: Boolean,
    duration: {
      type: Number,
      default: 0.5,
    },
    ease: {
      type: String,
      default: "power4",
    },
    /**
     * The name of current stage
     */
    modelValue: {
      type: String,
      default: "home",
    },
    debug: Boolean,
    navi: Boolean,
    loadingState: Boolean,
  },
  setup(props, context) {
    const model = modelRef(props, context.emit, { onPropUpdate: gotoStage });
    let history = ["home"];
    const parent = ref(null);
    if (model.value !== "home") {
      history.push(model.value);
      parent.value = "home";
    }
    const dict = {};
    const elements = {};

    // const current = ref("home");
    const aliveInstances = reactive({});
    provide("hi-stages-register", (name, label, container) => {
      // label(name, label) {
      if (dict[name]) {
        console.warn("Duplicate stage name: " + name);
      }
      dict[name] = label;
      aliveInstances[name] = name === model.value;
      elements[name] = container;
      // },
      // element(name, el) {
      //   console.log(name, el);
      //   elements[name] = el;
      // },
    });
    provide("hi-stages-goto", gotoStage);
    const conf = reactive({ navi: props.navi });
    watchEffect(() => {
      conf.navi = props.navi;
    });
    provide("hi-stage-conf", conf);
    let currentStage = model.value;
    function gotoStage(name) {
      if (!elements[name]) {
        // debug.warn("Stage " + name + " not found!");
        return;
      }
      if (name === currentStage) return;

      aliveInstances[name] = true;

      if (!name) name = "home";
      if (name === "home") {
        history = ["home"];
        parent.value = null;
        goto(currentStage, "home", true);
      } else {
        const index = history.indexOf(name);
        let back = false;
        if (index >= 0) {
          history.length = index + 1;
          parent.value = history[index - 1];
          back = true;
        } else {
          parent.value = currentStage;
          history.push(name);
        }
        goto(currentStage, name, back);
      }

      // debug.log("Stages history", history);

      currentStage = model.value = name;
    }
    function goto(from, to, back) {
      // debug.log("Stage goto", from, to, back);
      const aniSettings = {
        duration: props.duration,
        ease: props.ease,
      };
      // console.log(aniSettings);
      aliveInstances[to] = true;
      nextTick(() => {
        // console.log(from, to);
        const fromEl = elements[from].value;
        const toEl = elements[to].value;
        // console.log(fromEl, toEl);
        if (back) {
          gsap.set(toEl, { left: "-100%" });
          gsap.to(fromEl, { ...aniSettings, left: "100%" });
        } else {
          gsap.set(toEl, { left: "100%" });
          gsap.to(fromEl, { ...aniSettings, left: "-100%" });
        }
        gsap.to(toEl, {
          ...aniSettings,
          left: 0,
          onComplete() {
            aliveInstances[from] = false;
          },
        });
      });
    }
    provide("hi-stages-back", () => {
      history.pop();
      const to = history[history.length - 1];
      goto(currentStage, to, true);
      currentStage = model.value = to;
      parent.value = history[history.length - 2];
    });
    provide(
      "hi-stages-parent",
      computed(() => dict[parent.value])
    );
    provide("hi-stage-aliveInstances", aliveInstances);
  },
};
</script>

<style scoped></style>
