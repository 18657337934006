<template>
  <div class="space-x-2" :class="{ flex: !inline, 'inline-flex': inline }">
    <div
      class="hi-label shrink-0"
      :style="{ width: labelWidth, minWidth: labelWidth }"
    >
      {{ label }}
    </div>
    <div
      class="flex-grow first-full-size flex items-center"
      style="min-width: 180px"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "HiFormItem",
  props: {
    label: String,
    inline: Boolean,
    smallLabel: Boolean,
    bigLabel: Boolean,
  },
  setup(props) {
    const labelWidth = props.smallLabel
      ? "100px"
      : props.bigLabel
      ? "200px"
      : "150px";
    return {
      labelWidth,
    };
  },
};
</script>

<style scoped>
::v-deep .first-full-size > *:first-child {
  width: 100%;
}
</style>
