<template>
  <hi-vault-file-drop-multiple
    v-if="multiple"
    v-model="model"
    :accept-mime="acceptMime"
    @upload="onUpload"
    @completed="onCompleted"
    as-image
    :image-display-cover="cover"
    :item-width="small ? '140px' : large ? '350px' : '240px'"
    :item-height="small ? '140px' : large ? '350px' : '240px'"
  />
  <hi-vault-file-drop
    v-else
    v-model="model"
    :accept-mime="acceptMime"
    @upload="onUpload"
    @completed="onCompleted"
    as-image
    :class="{
      size240: !small && !large,
      size140: small,
      size350: large,
    }"
    :image-display-cover="cover"
  />
</template>

<script>
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import HiVaultFileDrop from "@/hive-vue3/components/firebase/HiVaultFileDrop";
import HiVaultFileDropMultiple from "@/hive-vue3/components/firebase/HiVaultFileDropMultiple";
import mime from "@/hive-vue3/utils/constables/mime";

export default {
  name: "HiVaultImageControl",
  components: { HiVaultFileDropMultiple, HiVaultFileDrop },
  props: {
    ...commonFormInputProps,
    modelValue: [Array, String],
    multiple: Boolean,
    acceptMime: {
      type: Array,
      default() {
        return [mime.ImagePng, mime.ImageJpeg];
      },
    },
    cover: {
      type: Boolean,
    },
    small: Boolean,
    large: Boolean,
  },
  setup(props, context) {
    const control = formControl(props, context);
    const { model } = control;
    function onUpload() {
      control.hold();
    }
    function onCompleted() {
      control.unhold();
    }
    return {
      model,
      onUpload,
      onCompleted,
    };
  },
};
</script>

<style scoped>
.size240 {
  width: 240px;
  height: 240px;
}
.size140 {
  width: 140px;
  height: 140px;
}
.size350 {
  width: 350px;
  height: 350px;
}
</style>
