<template>
  <div>
    <div class="bg-yellow-50" v-if="debug">
      <div class="small-label">DEBUG</div>
      <div style="font-weight: bold">doc: {{ collectionPath }}/{{ docId }}</div>
      <div v-html="JSON.stringify(docData).replaceAll('\n', '<br\/>')"></div>
    </div>
    <div v-if="displayId" class="small-id-display">{{ docId }}</div>
    <slot :collectionPath="collectionPath" :docId="docId" :docData="docData" />
  </div>
</template>

<script>
import { provide } from "vue";
// import { useDoc, doc } from "../../firebase";
import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";
import { watchProps } from "@/hive-vue3/utils/reactiveHelpers/watchers";
// import { firestoreDoc } from "@/hive-vue3/firebase";

export default {
  name: "HiFireDoc",
  props: {
    collectionPath: {
      type: String,
      required: true,
    },
    docId: {
      type: String,
    },
    displayId: Boolean,
    debug: Boolean,
  },
  setup(props) {
    // let docRef = ref(firestoreDoc(props.collectionPath, props.docId));
    const fireDoc = useFirestoreDocQueryable(props.collectionPath, props.docId);
    watchProps(props, "docId", (v) => {
      fireDoc.setId(v);
      // docRef.value = firestoreDoc(props.collectionPath, v);
    });

    provide("hi-fire-doc-ref", fireDoc.docRef);
    // console.log(doc);
    provide("hi-fire-doc", fireDoc.data);
    return {
      docData: fireDoc.data,
    };
  },
};
</script>

<style scoped>
.small-id-display {
  @apply text-right text-gray-300 italic  p-2 pr-4;
  font-size: 9px;
}
</style>
