<template>
  <div>
    <div v-if="file" class="w-full h-full grow p-2 text-xs flex flex-col">
      <div
        class="grow flex items-end justify-stretch text-xs w-full text-center"
      >
        <hi-text-carousel v-model="tooltip" class="w-full" />
      </div>

      <div class="justify-center items-center space-x-2 flex p-2 text-2xl">
        <hi-icon
          v-if="file.url"
          :path="mdiDownloadBox"
          class="clickable"
          @mouseenter="tooltip = 'Download'"
          @click="$emit('download')"
          @mouseleave="tooltip = ''"
        />

        <hi-icon
          v-if="canUpload"
          :path="mdiFileReplace"
          class="clickable"
          @mouseenter="tooltip = 'Replace File'"
          @mouseleave="tooltip = ''"
          @click="$emit('replace')"
        />

        <hi-icon
          v-if="ctrlOrCmd && canDelete"
          :path="mdiDeleteForever"
          class="clickable"
          @mouseenter="tooltip = 'Delete Permanently'"
          @mouseleave="tooltip = ''"
          @click="$emit('delete')"
        />
      </div>
    </div>

    <center-box v-else-if="canUpload">
      <div
        class="text-3xl clickable border border-current flex-center w-10 h-10 rounded"
        @click="$emit('replace')"
      >
        <hi-icon :path="mdiUpload" />
      </div>
    </center-box>
  </div>
</template>

<script>
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiDownloadBox } from "@mdi/js/commonjs/mdi";
import { mdiDeleteForever, mdiFileReplace, mdiUpload } from "@mdi/js";
import useKeyModifierControlOrCommand from "@/hive-vue3/utils/sensors/useKeyModifierControlOrCommand";
import { templateRef } from "@vueuse/core/index";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import { ref } from "vue";
import HiTextCarousel from "@/hive-vue3/animations/HiTextCarousel";
export default {
  name: "PrivateFileOverlay",
  components: { HiTextCarousel, CenterBox, HiIcon },
  props: {
    file: Object,
    canUpload: Boolean,
    canDelete: Boolean,
  },
  emits: ["replace", "delete", "download"],
  setup() {
    const ctrlOrCmd = useKeyModifierControlOrCommand();
    const fileInput = templateRef("fileInput");
    const tooltip = ref("");
    return {
      mdiDownloadBox,
      mdiFileReplace,
      mdiDeleteForever,
      mdiUpload,
      ctrlOrCmd,
      fileInput,
      tooltip,
    };
  },
};
</script>

<style scoped></style>
