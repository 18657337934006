import { tabbable } from "tabbable";
import $el from "@/hive-vue3/utils/dom/$el";
import { nextTick } from "vue";

export function tabNext(element) {
  if (!element) return;
  element = $el(element);
  const tabbableInElement = tabbable(element, { includeContainer: true });
  if (!tabbableInElement.length) {
    console.warn("Cannot tabNext for given element is not tabbable. ", element);
  } else {
    const tabbables = tabbable(document.body);
    const t = tabbableInElement.pop();
    let index = tabbables.indexOf(t);
    index++;
    if (index >= tabbables.length) {
      index = 0;
    }
    // console.log(tabbables[index]);
    nextTick().then(() => tabbables[index].focus());
  }
}
