import { getCurrentScope, onScopeDispose } from "vue";
import { firestoreDoc, getSnapshotData } from "@/hive-vue3/firebase/utils";
import { onSnapshot } from "firebase/firestore";

/**
 *
 * @param collectionPathOrFullPath
 * @param docId
 * @param onUpdate
 * @param persist
 * @returns {Unsubscribe}
 */
export default function (
  collectionPathOrFullPath,
  docId = null,
  onUpdate,
  persist = false
) {
  const errorHandler = (err) => console.error(err);

  const theDoc = firestoreDoc(collectionPathOrFullPath, docId);
  const close = onSnapshot(
    theDoc,
    (doc) => {
      onUpdate(getSnapshotData(doc));
    },
    errorHandler
  );

  if (!persist && getCurrentScope()) {
    onScopeDispose(() => {
      close();
    });
  }
  return close;
}
