<template>
  <hi-data-list-kit
    @select="onSelect"
    search-input-placeholder="Name, QuoNumber, Address"
    :sortables="sortables"
    :search-fn="quotationListSearchFn"
    min-item-height="85"
    :current-id="quotationState.currentQuotationId"
  >
    <template v-slot="{ item }">
      <quotation-summary :quotation-data="item" />
    </template>
  </hi-data-list-kit>

  <!--  <div class="flex flex-col" style="min-width: 420px">-->
  <!--    <hi-data-list-->
  <!--      @select="onSelect"-->
  <!--      auto-items-per-page-->
  <!--      min-item-height="85"-->
  <!--      class="grow"-->
  <!--    >-->
  <!--      <template v-slot="{ item }">-->
  <!--        <quotation-summary :quotation-data="item" />-->
  <!--      </template>-->
  <!--    </hi-data-list>-->

  <!--    <hi-pagination class="grow-0 hi-footer" />-->
  <!--  </div>-->
</template>

<script>
// import HiDataList from "@/hive-vue3/components/dataDisplay/HiDataList";
// import HiPagination from "@/hive-vue3/components/dataDisplay/HiPagination";
import { provideQuotationListStore } from "@/hive-vue3/modules/quotation/data";
import { quotationState } from "@/hive-vue3/modules/quotation/state";
import {
  getContactsEntityNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import QuotationSummary from "@/hive-vue3/modules/quotation/QuotationSummary";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
export default {
  name: "QuotationList",
  components: {
    HiDataListKit,
    QuotationSummary,
    // HiPagination,
    // HiDataList,
  },
  setup() {
    const quotationList = provideQuotationListStore();
    quotationList.actions.defaultSortBy("quoteNum", true, "number");

    function onSelect(v) {
      quotationState.currentQuotationId = v.id;
      quotationState.revisionId = (
        Object.keys(v.revisions).length - 1
      ).toString();

      quotationState.isSend = false;
      for (const revision in v.revisions) {
        if (v.revisions[revision].status === "send") {
          quotationState.isSend = true;
        }
      }
      quotationState.currentMiddleColView = "preview";
      quotationState.isUnSaved = false;
    }

    function quotationListSearchFn(search, quotation) {
      const attName = getContactsFullNameById(
        quotation.attention
      ).toLowerCase();
      const project = getProjectById(quotation.projectId);
      const projName = project.name;
      const projAddr = (
        project.address.streetNumber +
        " " +
        project.address.streetShort +
        ", " +
        project.address.cityShort +
        " " +
        project.address.stateShort +
        ", " +
        project.address.postcode
      ).toLowerCase();

      const quoNum = (
        "QUO-" + quotation.quoteNum.toString().padStart(4, "0")
      ).toLowerCase();

      return (
        attName.indexOf(search) >= 0 ||
        projName.indexOf(search) >= 0 ||
        quoNum.indexOf(search) >= 0 ||
        projAddr.indexOf(search) >= 0
      );
    }

    const sortables = [
      {
        key: "__dateCreated",
        text: "Create",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "__dateUpdated",
        text: "Update",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: false,
      },
      {
        key: "quoteNum",
        text: "No",
        type: dataListSortingTypes.Number,
        desc: true,
        DEFAULT: false,
      },
    ];

    return {
      onSelect,
      getContactsFullNameById,
      getContactsEntityNameById,
      quotationListSearchFn,
      sortables,
      quotationState,
    };
  },
};
</script>

<style scoped></style>
