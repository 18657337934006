import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import mailPath from "@/hive-vue3/modules/gmail/dbPaths";
import { orderBy, where } from "firebase/firestore";
import { getCurrentFirebaseUser, useSystemUsers } from "@/hive-vue3/firebase";

export const provideEmailListStore = () => {
  const user = getCurrentFirebaseUser();
  if (user.email.endsWith("artimber.com.au")) {
    user.email = user.email.replace("artimber.com.au", "artimber.au");
  }
  console.log(user.email);
  const threads = useFirestoreCollectionAsRef(
    `/system/syncedEmail/allThreads`,
    {
      query: [
        where("owner", "==", user.email),
        orderBy("__dateUpdated", "desc"),
      ],
    }
  );
  const listStore = createDataListStore(threads);
  return provideDataListStore(listStore);
};

export function useMailsInThread(threadId) {
  if (threadId) {
    return useFirestoreCollectionAsRef(mailPath.allMail, {
      query: [where("threadId", "==", threadId), orderBy("internalDate")],
    });
  }
  return [];
}

export function getEmailsNotInSystemUsers(emailList) {
  const systemUsers = useSystemUsers();
  for (const user in systemUsers) {
    const index = emailList.indexOf(systemUsers[user]["email"]);
    if (index !== -1) {
      emailList.splice(index, 1);
    }
  }
  return emailList;
}
