<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard
      @successful="open = false"
      :initial-values="initialValues"
      :submit-fn="submit"
    >
      <hi-form-item label="Recipient Type">
        <hi-chips-selectable
          :items="[
            {
              text: 'People',
              value: 'people',
            },
            {
              text: 'Leads',
              value: 'leads',
            },
          ]"
          mandatory
          form-key="recipientType"
        />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { edmState } from "@/hive-vue3/modules/edm/state";
export default {
  name: "EdmChangeRecipientTypeDialog",
  components: { HiFormItem, HiChipsSelectable, HiFormHiveStandard, HiDialog },
  props: {
    initialValues: Object,
  },
  setup() {
    const open = ref(false);
    async function submit(data) {
      await updateFirestoreDoc(
        firestoreDoc("/edm/campaigns/registers", edmState.currentEdmId),
        {
          recipientType: data.recipientType,
        }
      );
      return {
        successful: true,
      };
    }
    return {
      open,
      submit,
    };
  },
};
</script>

<style scoped></style>
