<template>
  <module-layout-block-editable editable>
    <module-info-block :data="account">
      <module-labeled-info value-key="email" default-display="N/A" />
      <module-labeled-info value-key="mobile" default-display="N/A" />
      <module-labeled-info value-key="status" default-display="N/A" />

      <module-labeled-info value-key="address" default-display="N/A">
        <hi-address-display
          v-if="account && account.address"
          :model-value="account.address"
        />
        <span v-else>N/A</span>
      </module-labeled-info>
      <module-labeled-info value-key="entity" default-display="N/A">
        <hi-dialog-trigger v-if="account && account.entity">
          <contacts-entity class="cursor-pointer" :entity-id="account.entity" />
          <hi-dialog width="600px">
            <contacts-entity-overview :entity-id="account.entity" />
          </hi-dialog>
        </hi-dialog-trigger>
        <span v-else> N/A</span>
      </module-labeled-info>
    </module-info-block>
    <template v-slot:editor="{ closeDialog }">
      <accounts-common-info-editor
        :account-id="account.id"
        :initial-value="account"
        @successful="closeDialog"
      />
    </template>
  </module-layout-block-editable>
</template>

<script>
import { computed } from "vue";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import ModuleLayoutBlockEditable from "@/hive-vue3/modules/_common/ModuleLayoutBlockEditable";
import ModuleInfoBlock from "@/hive-vue3/modules/_common/ModuleInfoBlock";
import ModuleLabeledInfo from "@/hive-vue3/modules/_common/ModuleLabeledInfo";
import HiAddressDisplay from "@/hive-vue3/components/misc/HiAddressDisplay";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import AccountsCommonInfoEditor from "@/hive-vue3/modules/accounts/AccountsCommonInfoEditor";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import ContactsEntityOverview from "@/hive-vue3/modules/contacts/ContactsEntityOverview";

export default {
  name: "AccountsInfoCard",
  components: {
    ContactsEntityOverview,
    HiDialog,
    HiDialogTrigger,
    AccountsCommonInfoEditor,
    ContactsEntity,
    HiAddressDisplay,
    ModuleLabeledInfo,
    ModuleInfoBlock,
    ModuleLayoutBlockEditable,
  },
  setup() {
    const account = computed(() => {
      return getAccountById(accountState.currentAccountId);
    });
    return {
      account,
    };
  },
};
</script>

<style scoped></style>
