import {
  firestoreDoc,
  newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import { getProjectsByAccountId } from "@/hive-vue3/modules/projects/data";

const cache = {
  accounts: undefined,
};

function initAccounts() {
  if (!cache.accounts) {
    cache.accounts = useFirestoreCollectionAsRefGlobally("accounts");
  }
}

export function getAllAccounts() {
  initAccounts();
  if (cache.accounts && cache.accounts.value) {
    return cache.accounts.value;
  }
  return [];
}
export function getAllActiveAccounts() {
  initAccounts();
  const returnArr = [];
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.status === "active") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getAllClosedAccounts() {
  initAccounts();
  const returnArr = [];
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.status !== "active") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}
export function getAllAccountsByUserId(userId) {
  initAccounts();
  const returnArr = [];
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.mainOwner === userId) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}
export function getActiveAccountsByUserId(userId) {
  const returnArr = getAllAccountsByUserId(userId);
  return returnArr.filter((v) => v.status === "active");
}

export function getClosedAccountsByUserId(userId) {
  const returnArr = getAllAccountsByUserId(userId);
  return returnArr.filter((v) => v.status === "closed");
}

export function getAmountByGivenAccountArray(accountArray) {
  if (accountArray && accountArray.length) {
    let amount = 0;
    for (const i of accountArray) {
      const projects = getProjectsByAccountId(i.id);
      if (projects && projects.length) {
        let returnValue = 0;
        for (const i of projects) {
          if (i.estimateAmount) {
            returnValue += i.estimateAmount;
          }
        }
        amount += returnValue;
      }
    }
    return amount;
  }
  return 0;
}

export function getAccountById(accountId) {
  initAccounts();
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.id === accountId) {
        return i;
      }
    }
  }
}
export function getAccountNameById(accountId) {
  initAccounts();
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.id === accountId) {
        return i.name;
      }
    }
  }
}

export function getAccountNameEmailById(accountId) {
  initAccounts();
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.id === accountId) {
        return i.name + " " + i.email;
      }
    }
  }
}

export function getAccountsByPeopleId(peopleId) {
  initAccounts();
  // console.log("account function");
  const returnArr = [];
  if (cache.accounts && cache.accounts.value) {
    for (const i of cache.accounts.value) {
      if (i.people && i.people.length && i.people.indexOf(peopleId) >= 0) {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export async function createNewAccount(accountDetails) {
  return await newFirestoreDoc("accounts", accountDetails);
}
export async function updateAccountById(accountId, accountData) {
  const d = nullDataGenerate(accountData);
  return await updateFirestoreDoc(firestoreDoc("accounts/", accountId), d);
}

function nullDataGenerate(data) {
  const d = {};
  for (const key in data) {
    const v = data[key];
    if (v !== undefined) {
      d[key] = v;
    } else {
      d[key] = null;
    }
  }
  return d;
}

export function jumpToAccount(router, accountId) {
  router.push("/accounts/" + accountId);
}
