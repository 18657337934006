<template>
  <div v-if="quotation" class="items-center">
    <div class="tp-subtitle flex mb-1">
      <div class="ellipse-flex-grow-item" v-if="project && project.name">
        {{ project.name }}({{
          project.address.streetNumber +
          " " +
          project.address.streetShort +
          ", " +
          project.address.cityShort +
          " " +
          project.address.stateShort +
          ", " +
          project.address.postcode
        }}) - ${{ total }}
      </div>

      <div v-if="quotation.quoteNum">
        QUO-{{ quotation.quoteNum.toString().padStart(4, "0") }}
      </div>
    </div>

    <div class="flex items-center">
      <div class="grow">
        <div class="tp-description-sm">
          {{ getContactsFullNameById(quotation.attention) }} ({{
            quotation.legalName
              ? getContactsEntityNameById(quotation.client)
              : getContactsEntityTradingNameById(quotation.client)
          }})
          {{
            String.fromCharCode(64 + Object.keys(quotation.revisions).length)
          }}
        </div>

        <div class="tp-description-sm">
          Created by: <user-details-span :user-id="quotation.__createdBy" /> at
          <hi-date-time-display
            :model-value="quotation.__dateCreated"
            friendly
          />
        </div>
        <div class="tp-description-sm">
          Last Update:
          <hi-date-time-display
            :model-value="quotation.__dateUpdated"
            friendly
          />
        </div>
      </div>
      <div>
        <hi-tags
          small
          :modelValue="tags"
          :map="{ draft: 'draft', sent: 'sent' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import {
  getLastRevisionIdByData,
  getQuotationById,
  getTotal,
} from "@/hive-vue3/modules/quotation/data";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  getContactsEntityNameById,
  getContactsEntityTradingNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import HiTags from "@/hive-vue3/components/HiTags";

export default {
  name: "QuotationSummary",
  components: { HiTags, UserDetailsSpan, HiDateTimeDisplay },
  props: {
    quotationData: Object,
    quotationId: String,
  },
  setup(props) {
    const quotation = computed(() => {
      if (props.quotationData) return props.quotationData;
      if (props.quotationId) {
        return getQuotationById(props.quotationId);
      }
      return null;
    });

    const project = computed(() => {
      return getProjectById(quotation.value.projectId);
    });

    return {
      getContactsEntityNameById,
      getContactsFullNameById,
      getContactsEntityTradingNameById,
      quotation,
      project,
      total: computed(() => {
        const LastRevisionId = getLastRevisionIdByData(quotation.value);
        return getTotal(quotation.value.revisions[LastRevisionId]);
      }),
      tags: computed(() => {
        if (quotation.value) {
          const returnArray = [];
          for (const i in quotation.value.revisions) {
            if (quotation.value.revisions[i].status === "send") {
              if (returnArray.indexOf("sent") === -1) {
                returnArray.push("sent");
              }
            } else if (quotation.value.revisions[i].status === "draft") {
              if (returnArray.indexOf("draft") === -1) {
                returnArray.push("draft");
              }
            }
          }

          return returnArray;
        }
        return [];
      }),
    };
  },
};
</script>

<style scoped></style>
