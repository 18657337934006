import { onSnapshot } from "firebase/firestore";
import { injectSnapshotToObject } from "@/hive-vue3/firebase/utils";
import { getCurrentScope, onScopeDispose, reactive } from "vue";

/**
 *
 * @param colRefOrCalPath
 * @param queryBuilder
 * @param options
 */
export default function (
  colRefOrCalPath,
  queryBuilder,
  options = { persistData: false }
) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;
  if (!queryBuilder.limit()) {
    queryBuilder.limit(1);
  }
  const data = reactive({});
  const close = onSnapshot(
    queryBuilder.build(),
    (snapshot) => {
      const docs = snapshot.docs;
      if (!docs.length) {
        for (let member in data) delete data[member];
      } else {
        injectSnapshotToObject(docs[0], data);
      }
      // console.log("snap", data);
    },
    errorHandler
  );
  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      close();
    });
  }
  return data;
}
