<template>
  <div class="box-border" style="min-height: 140px">
    <div
      style="width: 100%; min-width: 100px"
      class="--the-container flex flex-col relative h-full"
      ref="container"
    >
      <div
        class="absolute-origin w-full flex items-top max-w-full z-10 --the-title"
        :style="{
          height: isMouseOver ? '100%' : '32px',
        }"
        style="transition: height 0.4s"
      >
        <div class="grow text-xs flex h-full face-color" style="padding: 8px">
          <div
            style="
              font-size: 7px;
              min-width: 16px;
              height: 16px;
              letter-spacing: 0.02em;
            "
            class="bg-primary rounded-sm text-white mr-2 font-bold shrink-0 grow-0 flex-center uppercase hi-font-mono shrink-0"
          >
            {{ type }}
          </div>
          <div
            class="text-gray-700 overflow-hidden"
            style="min-height: 32px; height: 40%"
            :class="{
              'ellipse-flex-grow-item': !isMouseOver,
              'break-all': isMouseOver,
            }"
          >
            {{ file && file.name }}
          </div>
        </div>
        <!--        the file-corner-->
        <div class="flex flex-col shrink-0">
          <file-corner
            class="shrink-0"
            face-color="#dddddd"
            style="width: 24px"
          />
          <div class="grow face-color"></div>
        </div>
      </div>
      <hi-file-size
        v-if="!isMouseOver && file && file.size"
        :file="file"
        class="absolute right-1 bottom-1 bg-black bg-opacity-60 rounded text-white z-40 text-xxs p-0.5 px-1"
      ></hi-file-size>
      <private-file-overlay
        v-if="isMouseOver"
        class="absolute l-0 z-20 text-primary"
        style="top: 40%; height: 60%; width: 100%"
        :file="file"
        :can-delete="canDelete"
        :can-upload="canUpload"
        @replace="$emit('upload')"
        @delete="$emit('delete')"
        @download="downloadFile"
      >
      </private-file-overlay>

      <div
        style="
          margin-top: 32px;
          border-color: #dddddd;
          border-top-color: #bbbbbb;
        "
        class="--the-body grow border text-gray-500 flex flex-col"
        ref="body"
      >
        <hi-image
          v-if="canPreview"
          class="grow w-full h-full"
          :src="previewSrc"
          fade-in
        />
        <center-box
          v-else-if="file && file.name"
          class="grow text-3xl text-gray-300 w-full h-full bg-gray-100"
        >
          <hi-icon :path="mdiFileQuestion" />
          <!--          <div class="text-xs">{{ file && file.type }}</div>-->
          <!--          <div class="text-sm">Preview Not Available</div>-->
        </center-box>
      </div>
    </div>
  </div>
</template>

<script>
import FileCorner from "@/hive-vue3/assets/svg/vue/FileCorner";
import { mdiFileQuestion } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { templateRef } from "@vueuse/core";
// import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import useMouseOver from "@/hive-vue3/utils/sensors/useMouseOver";

// import { ref } from "firebase/storage";
import { computed } from "vue";
import { getFileExtension } from "@/hive-vue3/utils/stringUtils";
import HiImage from "@/hive-vue3/components/HiImage";
import mime from "@/hive-vue3/utils/constables/mime";
import HiFileSize from "@/hive-vue3/components/misc/HiFileSize";
import { downloadFileFromUrl } from "@/hive-vue3/utils/fileSaver";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import PrivateFileOverlay from "@/hive-vue3/components/firebase/_private-file-vault-components/PrivateFileOverlay";

const supportedMime = [
  mime.ImagePng,
  mime.ImagePjpeg,
  mime.ImageJpeg,
  mime.ImageSvgXml,
  mime.ImageGif,
  mime.ApplicationPdf,
];

/**
 * trade off: inline block 会跳动？？？
 */
export default {
  name: "HiAttachment",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    CenterBox,
    HiFileSize,
    HiImage,
    PrivateFileOverlay,
    // TransitionFade,
    // eslint-disable-next-line vue/no-unused-components
    HiIcon,
    FileCorner,
  },
  emits: ["upload", "delete"],
  props: {
    file: Object,
    canUpload: Boolean,
    canDelete: Boolean,
  },
  setup(props) {
    // const body = templateRef("body");
    const container = templateRef("container");
    const type = computed(() => {
      // console.log(props.file);
      if (!props.file || !props.file.name) return "NA";

      return getFileExtension(props.file.name);
    });
    const previewSrc = computed(() => {
      if (!props.file) return null;
      return props.file.thumb || props.file.thumbUrl || props.file.url;
    });
    const canPreview = computed(() => {
      if (!props.file) return false;
      if (props.file.thumb) return true;
      const mime = props.file.type;
      if (!mime) return false;
      // console.log(
      //   mime,
      //   supportedMime,
      //   supportedMime.includes(mime.toLowerCase())
      // );
      return supportedMime.includes(mime.toLowerCase());
    });
    const isMouseOver = useMouseOver(container);
    function downloadFile() {
      if (!props.file) return;
      if (!props.file.url) return;
      downloadFileFromUrl(props.file.url, props.file.name, props.file.type);
    }
    return {
      mdiFileQuestion,
      type,
      isMouseOver,
      previewSrc,
      canPreview,
      downloadFile,
    };
  },
};
</script>

<style scoped>
.face-color {
  background-color: #dddddd;
}
</style>
