<template>
  <div>
    <mfc-input-contacts-people
      @change="change"
      v-model="inputModel"
      class="w-full"
      :excludes="selected"
      ref="input"
      no-form
      :auto-focus="autoFocus"
      :disabled="disabled"
    />
    <div class="flex flex-wrap gap-2 mt-2">
      <hi-chip
        v-for="item in selected"
        :key="item"
        :deletable="!disabled"
        big
        @delete="deletePeople(item)"
      >
        <contacts-people
          :people-id="item"
          :email="email"
          :mobile="mobile"
          :title="title"
          :gender="gender"
          :entityId="entityId"
          :position="position"
        />
      </hi-chip>
    </div>
  </div>
</template>

<script>
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import { nextTick, reactive, ref } from "vue";
import HiChip from "@/hive-vue3/components/HiChip";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import { templateRef } from "@vueuse/core";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
export default {
  name: "MfcInputContactsPeopleMultiple",
  components: { ContactsPeople, HiChip, MfcInputContactsPeople },
  props: {
    modelValue: Array,
    ...commonFormInputProps,
    disabled: Boolean,
    //below props will be passed directly to contacts-people
    email: Boolean,
    mobile: Boolean,
    title: Boolean,
    gender: Boolean,
    entityId: String,
    position: Boolean,
  },
  setup(props, context) {
    const control = formControl(props, context, {
      onModelValueUpdate,
    });
    const selected = reactive([]);
    const inputModel = ref();
    const input = templateRef("input");
    function focus() {
      input.value && input.value.focus();
    }
    function onModelValueUpdate(v) {
      if (!v) {
        selected.splice(0);
        return;
      }
      if (!Array.isArray(v)) {
        console.warn(
          "MfcInputContactsPeopleMultiple found illegal modelValue(Array is excepted)",
          v
        );
        return;
      }
      selected.splice(0).push(...v);
    }
    function updateModel() {
      if (!selected.length) {
        control.model.value = undefined;
      } else {
        control.model.value = [...selected];
      }
    }
    return {
      selected,
      inputModel,
      focus,
      deletePeople(id) {
        arrayRemove(selected, id);
        updateModel();
        focus();
      },
      change(v) {
        console.log("change", v);
        selected.push(v);
        updateModel();
        //Todo: Next Tick Issue 使用NextTick会令程序终止
        nextTick(() => {
          inputModel.value = undefined;
          // focus();
        });
      },
    };
  },
};
</script>

<style scoped></style>
