<template>
  <center-box>
    <login-basic google>
      <div>
        <artimber-logo class="w-80 m-20" />
      </div>
    </login-basic>
  </center-box>
</template>

<script>
import { googleLoginPopup, logout, useLoggedIn } from "@/hive-vue3/firebase";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import LoginBasic from "@/hive-vue3/modules/login/LoginBasic";
import ArtimberLogo from "@/hive-vue3/assets/artimber/ArtimberLogo";

export default {
  name: "LoginView",
  components: { ArtimberLogo, LoginBasic, CenterBox },
  setup() {
    return {
      loggedIn: useLoggedIn(),
      googleLoginPopup,
      logout,
    };
  },
};
</script>
