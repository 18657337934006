<template>
  <projects-view-full-kit class="h-full w-full" />
</template>

<script>
import ProjectsViewFullKit from "@/hive-vue3/modules/projects/ProjectsViewFullKit";
export default {
  name: "ProjectsView",
  components: { ProjectsViewFullKit },
};
</script>

<style scoped></style>
