<template>
  <span>{{ modelValue && modelValue.formatted }}</span>
</template>

<script>
export default {
  name: "HiAddressDisplay",
  props: {
    modelValue: Object,
  },
};
</script>

<style scoped></style>
