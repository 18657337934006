<template>
  <div
    :class="{
      __clamping: lineClamp > 0,
      __flexColFullHeight: flexColFullHeightToSupportGrow,
    }"
    :style="{ '--clamp-lines': lineClamp }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "HiDiv",
  props: {
    lineClamp: Number,
    flexColFullHeightToSupportGrow: Boolean,
  },
};
</script>

<style scoped>
.__clamping {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp-lines);
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: break-spaces;
}
.__flexColFullHeight {
  @apply max-h-full h-[50000px] flex flex-col;
}
</style>
