<template>
  <hi-card class="h-full">
    <div class="hi-ui-title">logs</div>
    <div class="p-2" v-if="logs">
      <div
        class="flex flex-row-reverse my-3"
        v-if="logs.value && logs.value.length"
      >
        <hi-dialog-button
          title="Redirect Incoming Call"
          width="600px"
          v-model="open"
          button-text="Redirect Current Call"
          :disabled="logs.value[0].CallStatus === 'completed'"
        >
          <twilio-redirect-call-dialog
            @successful="open = false"
            :current-call="logs.value[0]"
          />
        </hi-dialog-button>
      </div>
      <table style="width: 100%">
        <tr class="border-b-2">
          <th style="text-align: left">Time</th>
          <th style="text-align: left">From</th>
          <th style="text-align: left">To</th>
          <th style="text-align: left">Duration</th>
          <th style="text-align: left">Status</th>
          <th style="text-align: right; width: 30px">Processing</th>
        </tr>

        <tr
          v-for="(item, index) in logs.value"
          :key="item + index"
          class="border-b-2"
          style="line-height: 40px"
        >
          <td>
            <hi-date-time-display :model-value="item.__dateCreated" friendly />
          </td>
          <td>{{ item.From }}</td>
          <td>
            <user-details-span
              v-if="item.action"
              :user-id="getLastPhoneUser(item.action)"
            />
            <div class="font-bold" v-else>System</div>
          </td>
          <td>
            {{ item.CallDuration ? item.CallDuration + " sec" : "-" }}
          </td>
          <td>
            {{
              getLastPhoneStatus(item.action)
                ? getLastPhoneStatus(item.action)
                : item.CallStatus
            }}
          </td>
          <td style="text-align: right; width: 30px">
            <div
              :class="{
                ['circle-active']: item.CallStatus !== 'completed',
                ['circle-deActive']: item.CallStatus === 'completed',
              }"
            ></div>
          </td>
        </tr>
      </table>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { firestoreCollection } from "@/hive-vue3/firebase";
import { orderBy } from "firebase/firestore";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
import { computed, ref } from "vue";
import TwilioRedirectCallDialog from "@/hive-vue3/modules/twilio/TwilioRedirectCallDialog";
import HiDialogButton from "@/hive-vue3/components/dialogs/HiDialogButton";

export default {
  name: "TwilioThirdColView",
  components: {
    HiDialogButton,
    TwilioRedirectCallDialog,
    UserDetailsSpan,
    HiDateTimeDisplay,
    HiCard,
  },
  setup() {
    const logs = computed(() => {
      return useFirestoreCollectionAsRef(
        firestoreCollection(`/phone/voice/${twilioState.currentNumber}`),
        {
          persistData: true,
          query: [orderBy("__dateCreated", "desc")],
        }
      );
    });
    function getLastPhoneUser(action) {
      if (action) {
        const actionArray = Object.values(action);
        actionArray.sort((a, b) => {
          return b.Timestamp - a.Timestamp;
        });
        return actionArray[actionArray.length - 1]["DialCallUser"];
      }
    }

    function getLastPhoneStatus(action) {
      if (action) {
        const actionArray = Object.values(action);
        actionArray.sort((a, b) => {
          return b.Timestamp - a.Timestamp;
        });
        return actionArray[actionArray.length - 1]["DialCallStatus"];
      }
      return false;
    }

    const open = ref(false);
    return {
      getLastPhoneStatus,
      getLastPhoneUser,
      logs,
      console,
      open,
    };
  },
};
</script>

<style scoped>
.circle-deActive {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.circle-active {
  color: #0cd70c;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #0cd70c;
  border-radius: 50%;
  display: inline-block;
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
}
</style>
