<template>
  <hi-data-list-kit
    :search-fn="contactsPeopleDataListSearchFn"
    :sortables="sortables"
    :current-id="contactsState.selectedPeopleId"
    @select="(item) => $emit('select', item)"
    search-input-placeholder="Name, Email or Mobile"
  >
    <template v-slot="{ item }">
      <contacts-people-summary :people-data="item" />
    </template>
  </hi-data-list-kit>
  <!--  <div class="flex flex-col" style="min-width: 420px">-->
  <!--    <div-->
  <!--      class="flex py-2 px-4 gap-4 items-center border-b border-primary/20 bg-light-300"-->
  <!--    >-->
  <!--      <hi-search-input-->
  <!--        @search="onSearch"-->
  <!--        realtime-->
  <!--        placeholder="Name, Email or Mobile"-->
  <!--        class="grow"-->
  <!--        :model-value="state.searchValue"-->
  <!--      />-->
  <!--      <span class="text-xs"-->
  <!--        >Sort By: <hi-data-list-sorter :items="sortables"-->
  <!--      /></span>-->
  <!--    </div>-->
  <!--    <hi-data-list-->
  <!--      @select="onSelect"-->
  <!--      auto-items-per-page-->
  <!--      min-item-height="68"-->
  <!--      class="grow"-->
  <!--      vertical-center-item-->
  <!--    >-->
  <!--      <template v-slot="{ item }">-->
  <!--        <contacts-people-summary :people-data="item" />-->
  <!--      </template>-->
  <!--    </hi-data-list>-->
  <!--    <hi-pagination class="grow-0 hi-footer" />-->
  <!--  </div>-->
</template>

<script>
// import HiDataList from "@/hive-vue3/components/dataDisplay/HiDataList";
// import HiPagination from "@/hive-vue3/components/dataDisplay/HiPagination";
import ContactsPeopleSummary from "@/hive-vue3/modules/contacts/ContactsPeopleSummary";
// import HiSearchInput from "@/hive-vue3/components/HiSearchInput";
// import HiDataListSorter from "@/hive-vue3/components/dataDisplay/HiDataListSorter";
import {
  contactsPeopleDataListSearchFn,
  provideContactsPeopleListStore,
} from "@/hive-vue3/modules/contacts/data";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import { contactsState } from "@/hive-vue3/modules/contacts/state";
export default {
  name: "ContactsPeopleList",
  components: {
    HiDataListKit,
    // HiDataListSorter,
    // HiSearchInput,
    ContactsPeopleSummary,
    // HiPagination,
    // HiDataList,
  },
  setup() {
    provideContactsPeopleListStore();
    // store.actions.defaultSortBy("__dateCreated", true, "date");
    const sortables = [
      {
        key: "__dateCreated",
        text: "Date",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "name",
        text: "F.Name",
        type: dataListSortingTypes.ALPHA,
        desc: false,
        DEFAULT: false,
      },
      {
        key: "surname",
        text: "Surname",
        type: dataListSortingTypes.ALPHA,
        desc: false,
        DEFAULT: false,
      },
    ];
    // function onSelect(item) {
    //   // console.log(item);
    //   emit("select", item);
    // }
    // function onSearch(v) {
    //   // console.log(v);
    //   store.actions.search(v);
    // }
    // const state = store.state;
    return {
      // onSelect,
      sortables,
      contactsState,

      contactsPeopleDataListSearchFn,
    };
  },
};
</script>

<style scoped></style>
