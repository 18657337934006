<template>
  <hi-form-hive-standard
    :submit-fn="submit"
    @successful="$emit('successful')"
    :initial-values="initialValue"
  >
    <hi-form-item label="Email">
      <hi-input email form-key="email" />
    </hi-form-item>
    <hi-form-item label="Phone">
      <hi-input phone form-key="mobile" />
    </hi-form-item>
    <hi-form-item label="Address">
      <hi-input-address form-key="address" />
    </hi-form-item>
    <hi-form-item label="Entity">
      <mfc-input-contacts-entity form-key="entity" />
    </hi-form-item>
    <hi-form-item label="Status">
      <hi-select
        form-key="status"
        :items="[
          { text: 'Active', value: 'active' },
          { text: 'Closed', value: 'closed' },
        ]"
      />
    </hi-form-item>
  </hi-form-hive-standard>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import MfcInputContactsEntity from "@/hive-vue3/modules/contacts/controls/MfcInputContactsEntity";
import { updateAccountById } from "@/hive-vue3/modules/accounts/data";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getContactsEntityNameById } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "AccountsCommonInfoEditor",
  components: {
    HiSelect,
    MfcInputContactsEntity,
    HiInputAddress,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
  },
  props: {
    initialValue: Object,
    accountId: String,
  },
  setup(props) {
    async function submit(formData) {
      try {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.CHANGE;
        historyObj.target = "account";
        historyObj.targetId = props.accountId;
        historyObj.comments = `Account Info Changed: `;
        if (formData.status !== props.initialValue.status) {
          historyObj.comments += "\nstatus: " + formData.status;
        }
        if (formData.entity !== props.initialValue.entity) {
          historyObj.comments +=
            "\nentity: " + getContactsEntityNameById(formData.entity);
        }
        if (props.initialValue.address) {
          if (
            formData.address.formatted !== props.initialValue.address.formatted
          ) {
            console.log("1");
            historyObj.comments += "\naddress: " + formData.address.formatted;
          }
        } else {
          if (formData.address) {
            historyObj.comments += "\naddress: " + formData.address.formatted;
          }
        }
        if (formData.mobile !== props.initialValue.mobile) {
          historyObj.comments += "\nmobile: " + formData.mobile;
        }
        if (formData.email !== props.initialValue.email) {
          historyObj.comments += "\nemail: " + formData.email;
        }
        await addNewHistory(historyObj);

        await updateAccountById(props.accountId, formData);
        return { successful: true };
      } catch (e) {
        console.log(e);
        return { successful: false, errorMsg: e };
      }
    }
    return {
      submit,
    };
  },
};
</script>

<style scoped></style>
