<template>
  <hi-card>
    <div
      v-for="(item, index) in users"
      :key="item + index"
      class="p-2 border-b-2"
    >
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="item.id" />
        <div class="">
          <div class="w-[100px]">AP: {{ state[item.id].apNum }}</div>
          <div>${{ state[item.id].apAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">DPP: {{ state[item.id].dppNum }}</div>
          <div>${{ state[item.id].dppAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">WP: {{ state[item.id].wpNum }}</div>
          <div>${{ state[item.id].wpAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">LP: {{ state[item.id].lpNum }}</div>
          <div>${{ state[item.id].lpAmount.toLocaleString() }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between p-2">
      <hi-button @click="setTime(0)" :primary="state.periodNum === 0">
        All</hi-button
      >
      <hi-button @click="setTime(1)" :primary="state.periodNum === 1">
        Jan - Mar</hi-button
      >
      <hi-button @click="setTime(2)" :primary="state.periodNum === 2">
        Apr - Jun</hi-button
      >
      <hi-button @click="setTime(3)" :primary="state.periodNum === 3">
        Jul - Sep</hi-button
      >
      <hi-button @click="setTime(4)" :primary="state.periodNum === 4">
        Oct - Dec</hi-button
      >
    </div>
  </hi-card>
</template>

<script>
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiCard from "@/hive-vue3/components/HiCard";
import { timestampToDate, useSystemUsers } from "@/hive-vue3/firebase";
import { computed, reactive, watchEffect } from "vue";
import {
  getProjectsByMainOwnerId,
  getProjectTotalAmountByProjectArray,
} from "@/hive-vue3/modules/projects/data";
import HiButton from "@/hive-vue3/components/HiButton";
export default {
  name: "ProjectNumByUser",
  components: { HiButton, HiCard, UserDetailsSpan },
  setup() {
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [];
      for (const i in users) {
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push(users[i]);
        }
      }
      return returnArr;
    });

    const state = reactive({
      periodNum: 0,
    });
    watchEffect(() => {
      if (userNoMaintenance.value && userNoMaintenance.value.length) {
        for (const i of userNoMaintenance.value) {
          if (!state[i.id]) {
            state[i.id] = {};
          }

          let projects = [];
          const allProjects = getProjectsByMainOwnerId(i.id);
          if (state.periodNum === 0) {
            projects = allProjects;
          } else if (state.periodNum === 1) {
            projects = allProjects.filter((v) => {
              const date = timestampToDate(v.__dateCreated);
              const date1 = new Date("01/01/2022");
              const date2 = new Date("03/31/2022 23:59:59");
              return date > date1 && date < date2;
            });
          } else if (state.periodNum === 2) {
            projects = allProjects.filter((v) => {
              const date = timestampToDate(v.__dateCreated);
              const date1 = new Date("04/01/2022");
              const date2 = new Date("06/30/2022 23:59:59");
              return date > date1 && date < date2;
            });
          } else if (state.periodNum === 3) {
            projects = allProjects.filter((v) => {
              const date = timestampToDate(v.__dateCreated);
              const date1 = new Date("07/01/2022");
              const date2 = new Date("09/30/2022 23:59:59");
              return date > date1 && date < date2;
            });
          } else if (state.periodNum === 4) {
            projects = allProjects.filter((v) => {
              const date = timestampToDate(v.__dateCreated);
              const date1 = new Date("10/01/2022");
              const date2 = new Date("12/31/2022 23:59:59");
              return date > date1 && date < date2;
            });
          }

          const apProjects = projects.filter(
            (v) =>
              v.projStatus !== "Closed Lost" && v.projStatus !== "Closed Won"
          );
          const dppProjects = projects.filter(
            (v) => v.projStatus === "Deposit Paid"
          );
          const wpProjects = projects.filter(
            (v) => v.projStatus === "Closed Won"
          );
          const lpProjects = projects.filter(
            (v) => v.projStatus === "Closed Lost"
          );

          state[i.id].apNum = apProjects.length;
          state[i.id].apAmount =
            getProjectTotalAmountByProjectArray(apProjects);

          state[i.id].dppNum = dppProjects.length;
          state[i.id].dppAmount =
            getProjectTotalAmountByProjectArray(dppProjects);

          state[i.id].wpNum = wpProjects.length;
          state[i.id].wpAmount =
            getProjectTotalAmountByProjectArray(wpProjects);

          state[i.id].lpNum = lpProjects.length;
          state[i.id].lpAmount =
            getProjectTotalAmountByProjectArray(lpProjects);
        }
      }
    });

    function setTime(i) {
      state.periodNum = i;
    }
    return {
      users: userNoMaintenance,
      state,
      setTime,
    };
  },
};
</script>

<style scoped></style>
