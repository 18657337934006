<template>
  <div class="relative">
    <hi-loading-mask v-model="state.submitting" />
    <hi-banner multiline danger v-if="state.error" class="mb-3">
      <hi-form-error :model-value="state.error" />
    </hi-banner>
    <div class="flex flex-col gap-2">
      <slot />
    </div>
    <div class="flex flex-row-reverse mt-4">
      <hi-button
        primary
        @click="submit"
        :class="{ disabled: !valid || !changed || state.submitting }"
      >
        {{ submitText }}
      </hi-button>
      <div class="flex-grow"></div>
      <hi-button @click="cancel" ref="cancel-btn"> Cancel </hi-button>
    </div>
  </div>
</template>

<script>
import HiButton from "@/hive-vue3/components/HiButton";
import { provide, reactive } from "vue";
import formData from "@/hive-vue3/components/form/logics/formData";
import injectionKeys from "@/hive-vue3/components/_commonLogic/injectionKeys";
import { newFirestoreDoc } from "@/hive-vue3/firebase";
import HiBanner from "@/hive-vue3/components/HiBanner";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import HiLoadingMask from "@/hive-vue3/components/overlays/HiLoadingMask";
export default {
  name: "HiFireNewDocForm",
  components: { HiLoadingMask, HiFormError, HiBanner, HiButton },
  props: {
    /**
     * If docId not provided, uuid will be used
     */
    docId: String,
    collectionPath: {
      type: String,
      required: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    debug: Boolean,
    dataProcessorFn: Function,
  },
  emits: ["submit", "cancel", "successful", "error"],
  setup(props, { emit }) {
    const data = formData();
    const state = reactive({
      error: null,
      submitting: false,
    });

    provide(injectionKeys.HI_FORM_DATA, data);
    function submit() {
      let finalData;
      if (props.dataProcessorFn) {
        finalData = props.dataProcessorFn(data.values);
      } else {
        finalData = data.values;
      }
      emit("submit", data.values);

      if (props.debug) {
        if (props.docId) {
          console.log("update doc ", props.collectionPath, props.docId);
        } else {
          console.log("add new doc to", props.collectionPath);
        }
        console.log(data.values);
        return;
      }
      state.submitting = true;
      // const ts = serverTimestamp();
      // const user = getCurrentFirebaseUserId();
      // const meta = {};
      newFirestoreDoc(props.collectionPath, finalData, props.docId)
        .then(() => {
          emit("successful");
          state.submitting = false;
          emit("successful");
        })
        .catch((err) => {
          console.error(err);
          console.log(err);
          state.error = "Error writing document: " + err;
          console.log(state.error);
          state.submitting = false;
          emit("error");
        });
    }
    function cancel() {
      emit("cancel", data);
    }
    return {
      submit,
      valid: data.valid,
      cancel,
      changed: data.changed,
      state,
    };
  },
};
</script>

<style scoped></style>
