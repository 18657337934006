<template>
  <span v-if="people" class="inline-flex space-x-1 items-baseline">
    <span :class="{ ['font-bold']: !noBold }">{{
      formatContactsPeopleName(people, { title })
    }}</span>
    <span v-if="gender && people.gender"
      ><hi-icon :path="icons[people.gender]"
    /></span>
    <span v-if="mobile">{{ people.mobile }}</span>
    <span v-if="email">{{ people.email }}</span>
    <span v-if="position && people.entityPositions"
      >(<contacts-entity-position :position-id="computedPosition" />)</span
    >
    <!--    <contacts-people-overview-popup-->
    <!--      :people-id="people.id"-->
    <!--      v-if="popup"-->
    <!--      v-model="open"-->
    <!--    >-->
    <!--      &lt;!&ndash;          slot: to add contents & actions button at the bottom of the popup&ndash;&gt;-->
    <!--      <slot name="popup-footer" />-->
    <!--    </contacts-people-overview-popup>-->
  </span>
</template>

<script>
import { computed, ref } from "vue";
import {
  computedPeopleByIdOrData,
  formatContactsPeopleName,
} from "@/hive-vue3/modules/contacts/data";
// import ContactsPeopleOverviewPopup from "@/hive-vue3/modules/contacts/ContactsPeopleWithOverviewPopup";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiFaceMan, mdiFaceWoman } from "@mdi/js";
import ContactsEntityPosition from "@/hive-vue3/modules/contacts/ContactsEntityPosition";

export default {
  name: "ContactsPeople",
  components: { ContactsEntityPosition, HiIcon },
  props: {
    peopleId: String,
    peopleData: Object,
    email: Boolean,
    mobile: Boolean,
    title: Boolean,
    gender: Boolean,
    /**
     * to show position in this entity,otherwise, if position is true, showing the first entity position.
     */
    entityId: String,
    position: Boolean,
    noBold: Boolean,
  },
  setup(props) {
    const icons = {
      male: mdiFaceMan,
      female: mdiFaceWoman,
    };
    const people = computedPeopleByIdOrData(props);
    const open = ref(false);
    const computedPosition = computed(() => {
      const p = people.value;
      if (p) {
        const positions = p.entityPositions;
        // console.log(positions);
        // console.log(props.entityId);
        if (!positions) return undefined;
        if (props.entityId) {
          return positions[props.entityId];
        }
        const entities = p.entities;

        if (entities && entities.length) {
          return positions[entities[0]];
        }
      }
      return undefined;
    });
    return { people, open, formatContactsPeopleName, icons, computedPosition };
  },
};
</script>

<style scoped></style>
