<template>
  <span v-if="modelValue">{{ format(modelValue) }}</span>
</template>

<script>
import formatters from "@/hive-vue3/utils/formatters";

export default {
  name: "HiAbn",
  props: {
    modelValue: String,
  },
  setup() {
    return { format: formatters.abn };
  },
};
</script>

<style scoped></style>
