<template>
  <hi-dialog width="600px" v-model="open">
    <hi-form-hive-standard :submit-fn="submit" @successful="open = false">
      <mfc-input-account form-key="accountId" />
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { ref } from "vue";
import MfcInputAccount from "@/hive-vue3/modules/accounts/controls/MfcInputAccount";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import { updateProjectDetails } from "@/hive-vue3/modules/projects/data";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getAccountNameEmailById } from "@/hive-vue3/modules/accounts/data";
export default {
  name: "ProjectLinkAccountDialog",
  components: { MfcInputAccount, HiFormHiveStandard, HiDialog },
  setup() {
    const open = ref();
    async function submit(formData) {
      try {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.LINK;
        historyObj.target = "project";
        historyObj.targetId = projectsState.currentProjectId;
        historyObj.comments = `Link account ${getAccountNameEmailById(
          formData.accountId
        )}`;

        await addNewHistory(historyObj);

        await updateProjectDetails(projectsState.currentProjectId, formData);
        return { successful: true };
      } catch (e) {
        return { successful: false, errorMsg: e };
      }
    }
    return {
      open,
      submit,
    };
  },
};
</script>

<style scoped></style>
