<template>
  <hi-dialog v-model="open" width="600px">
    <div class="font-bold">
      Change estimate supply date for the following project:
    </div>
    <project-title-card :project-data="projectData" class="w-full my-4" />
    <hi-form-hive-standard
      cancel-button
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      :initial-values="initialValues"
    >
      <hi-form-item label="Estimate Supply Date *">
        <hi-date-picker month-only form-key="estimateSupplyDate" />
      </hi-form-item>
      <hi-form-item label="Comments">
        <hi-textarea :rows="3" form-key="comments" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import ProjectTitleCard from "@/hive-vue3/modules/projects/ProjectTitleCard";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { computed, ref } from "vue";
import { updateProjectDetails } from "@/hive-vue3/modules/projects/data";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
export default {
  name: "ProjectChangeSupplyDateDialog",
  components: {
    HiDatePicker,
    HiTextarea,
    HiFormItem,
    HiFormHiveStandard,
    ProjectTitleCard,
    HiDialog,
  },
  props: {
    projectData: Object,
  },
  setup(props) {
    const open = ref(false);
    async function submit(formData) {
      const year = formData.estimateSupplyDate.year;
      const month = formData.estimateSupplyDate.month + 1;
      formData.estimateSupplyDate = new Date(`${year}/${month}`);

      const historyData = new DefaultHistoryData();

      historyData.target = "project";
      historyData.targetId = props.projectData.id;
      historyData.action = historyActions.ADD;
      historyData.comments = `Add supply date: ${month}/${year} - ${
        formData.comments ? formData.comments : "N/A"
      } `;

      await addNewHistory(historyData);

      await updateProjectDetails(props.projectData.id, {
        estimateSupplyDate: formData.estimateSupplyDate,
      });

      return { successful: true };
    }

    return {
      open,
      submit,
      initialValues: computed(() => {
        const returnObj = {};
        Object.assign(returnObj, props.projectData);
        if (returnObj.estimateSupplyDate) {
          const esd = returnObj.estimateSupplyDate.toDate();
          returnObj.estimateSupplyDate = {};
          returnObj.estimateSupplyDate.year = esd.getFullYear();
          returnObj.estimateSupplyDate.month = esd.getMonth();

          // console.log(returnObj);
        }
        return returnObj;
      }),
    };
  },
};
</script>

<style scoped></style>
