import moment from "moment";
import Validator from "./validators";
import { numericOnly } from "@/hive-vue3/utils/stringUtils";
// import { round } from "@/hive-vue3/utils/numberUtils";

function isToday(m) {
  return m.isSame(moment(), "day");
}

function isYesterday(m) {
  return m.isSame(moment().add(-1, "d"), "day");
}

function getMoment(ts) {
  if (typeof ts.getMonth === "function") {
    return moment(ts);
  } else {
    return moment(new Date(ts.seconds * 1000));
  }
}

const formatters = {
  timestamp(ts, time) {
    if (!ts) return "Undefined Timestamp";
    const m = moment(new Date(ts.seconds * 1000));
    let str = m.format("DD MMM YY");
    if (time) str += " " + m.format("LT");
    return str;
  },
  time(date) {
    const m = getMoment(date);
    let str = m.format("DD MMM YY");
    str += " " + m.format("LT");
    // console.log(date, str);
    return str;
  },
  date(date, shortYear = false) {
    const m = getMoment(date);
    if (!m.isValid()) return "Invalid Date";
    return m.format(shortYear ? "DD MMM YY" : "DD MMM YYYY");
  },
  friendlyTime(ts) {
    if (!ts) return "";
    let m;
    if (typeof ts.getMonth === "function") {
      m = moment(ts);
    } else {
      m = moment(new Date(ts.seconds * 1000));
    }
    const time = m.format("LT");
    if (isToday(m)) {
      return "Today " + time;
    }
    if (isYesterday(m)) {
      return "Yesterday " + time;
    }
    return m.format("DD/MM/YY") + " " + time;
  },
  friendlyTimeShorter(ts) {
    const m = getMoment(ts);
    const time = m.format("LT");
    if (isToday(m)) {
      return time;
    }
    if (isYesterday(m)) {
      return "Yesterday";
    }
    if (moment().diff(m, "d") < 5) {
      return m.format("ddd");
    }
    return m.format("DD/MM/YY");
  },
  financeNumber(n) {
    if (Validator.number(n)) {
      return Math.round((Number(n) + Number.EPSILON) * 100) / 100;
    } else {
      return n;
    }
  },
  threeDecimalNumber(n) {
    if (Validator.number(n)) {
      return Math.round((Number(n) + Number.EPSILON) * 1000) / 1000;
    } else {
      return n;
    }
  },
  integer(n) {
    if (Validator.number(n)) {
      return Math.round(n);
    } else {
      return n;
    }
  },
  /**
   * Format mobile number to E.164
   * @param n  {String}  Number
   * @returns {string}
   */
  mobileE164(n) {
    // console.log("mobile", n);
    if (Validator.mobile(n)) {
      const number = n
        //delete all non-digit except for +
        .replace(/[^\d+]/g, "")
        //replace leading 00
        .replace(/^00/, "+")
        //support incomplete numbers for common mistakes for Australian numbers.
        .replace(/^61/, "+61")
        .replace(/^6104/, "+614")
        //support for Chinese mainland as well.
        .replace(/^86/, "+86");
      return number.replace(/^04/, "+614");
    } else {
      return n;
    }
  },
  /**
   * Format mobile numbers to "+614 xxx xxx xxx"
   * @param n {String}
   * @returns {string}
   */
  mobileDisplay(n) {
    if (Validator.mobile(n)) {
      const number = formatters.mobileE164(n);
      return (
        number.substr(0, 3) +
        " " +
        number.substr(3, 3) +
        " " +
        number.substr(6, 3) +
        " " +
        number.substr(9)
      );
    } else {
      return n;
    }
  },
  number(n) {
    return new Intl.NumberFormat().format(n);
  },
  upperCase(n) {
    if (n.toString) {
      return n.toString().toUpperCase();
    }
    return n.toUpperCase();
  },
  /**
   * @deprecated use formatters.currency() instead
   * @param n
   * @param toDollar
   * @returns {string}
   */
  money(n, toDollar) {
    const opts = {
      style: "currency",
      currency: "USD",
    };
    if (toDollar) {
      opts.maximumFractionDigits = 0;
    }
    return new Intl.NumberFormat("en-US", opts).format(n);
  },
  currency(n, dollarSymbol) {
    if (dollarSymbol) {
      const opts = {
        style: "currency",
        currency: "USD",
      };
      return new Intl.NumberFormat("en-US", opts).format(n);
    }
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(n);
  },
  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    // const units = si
    //   ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    //   : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    const units = ["K", "M", "G", "T", "PB", "EB", "ZB", "YB"];

    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  },
  duration(seconds) {
    const sec = Math.abs(Math.ceil(seconds));
    const h = (sec - (sec % 3600)) / 3600,
      m = ((sec - (sec % 60)) / 60) % 60,
      s = sec % 60;
    let str = "";
    if (h > 0) str += h + "h";
    if (m > 0) str += m + "m";
    str += s + "s";
    return (seconds < 0 ? "-" : "") + str;
  },
  abn(abn) {
    if (!abn) return "";
    abn = numericOnly(abn);
    if (abn.length !== 11) {
      return "Invalid ABN: " + abn;
    } else {
      return (
        abn.slice(0, 2) +
        " " +
        abn.slice(3, 5) +
        " " +
        abn.slice(5, 8) +
        " " +
        abn.slice(8)
      );
    }
  },
};
export default formatters;
