// import { readonly } from "vue";
import {
  getFirestore,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  addDoc,
  getDoc,
  // updateDoc,
  Timestamp,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import useFirestoreDocQueryable from "./useFirestoreDocQueryable";
import { computed } from "vue";
import { watchProps } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { getCurrentFirebaseUserId } from "./auth";
import useFirestoreDoc from "./useFirestoreDoc";
import { capitalize } from "@/hive-vue3/utils/stringUtils";
// import { useFirestore } from "@vueuse/firebase";
let fsdb;
export function getFirestoreDb() {
  if (!fsdb) fsdb = getFirestore();
  return fsdb;
}

export function firestoreCollection(colRefOrCalPath) {
  if (colRefOrCalPath.path && colRefOrCalPath.get) return colRefOrCalPath;

  return collection(getFirestoreDb(), colRefOrCalPath);
}
/**
 * Alternative to docREf.formControls() with auto-injected id as a property.
 * @param snap
 * @returns {Object}
 */
export function getSnapshotData(snap) {
  const data = snap.data();
  // console.log(snap.id);
  if (data) {
    Object.defineProperty(data, "id", {
      value: snap.id.toString(),
      writable: false,
    });
  }

  return data;
}

export async function getFirestoreDocData(docRef) {
  const snap = await getDoc(docRef);
  return getSnapshotData(snap);
}

export function firestoreDoc(collectionPathOrDocPathOrDocRef, docId = null) {
  if (
    collectionPathOrDocPathOrDocRef.type &&
    collectionPathOrDocPathOrDocRef.type === "document"
  )
    return collectionPathOrDocPathOrDocRef;
  // if (!docId) return null;
  // console.log(collectionPathOrDocPath, docId);
  if (!collectionPathOrDocPathOrDocRef) {
    console.error(
      "utils.firestoreDoc: null/undefined collectionPathOrDocPath found!"
    );
    return null;
  }

  if (!docId) {
    return doc(getFirestoreDb(), collectionPathOrDocPathOrDocRef);
  }
  // if (!docId) {
  //   console.error(
  //     "utils.firestoreDoc: docId is Null! collection provided: '" +
  //       collectionPathOrDocPathOrDocRef +
  //       "'"
  //   );
  //   return null;
  // }
  return doc(getFirestoreDb(), collectionPathOrDocPathOrDocRef, docId);
}
export function timestampToDate(ts) {
  if (!ts) return null;
  if (ts.toDate) return ts.toDate();
  if (ts.nanoseconds && ts.seconds) {
    return new Date(ts.seconds * 1000 + ts.nanoseconds / 1000000);
  }
  return new Date(ts.seconds * 1000);
  // return firebase.firestore.Timestamp.toDate(ts);
}

export function getDateFromTsOrDate(tsOrDate) {
  if (!tsOrDate) return null;
  if (tsOrDate.getTime) return tsOrDate;
  if (Number.isInteger(tsOrDate.seconds)) {
    return timestampToDate(tsOrDate);
  }
}

export function useDocByPropsId(props, collectionPath, idProp) {
  const result = useFirestoreDocQueryable(collectionPath, props[idProp]);
  watchProps(props, idProp, (v) => {
    result.setId(v);
  });
  return computed(() => result.data);
}

export function isDocumentReference(docRef) {
  if (!docRef) return false;
  return (docRef.path?.match(/\//g) || []).length % 2 !== 0;
}

function fireDataCheck(data) {
  const d = {};
  for (const key in data) {
    const v = data[key];
    if (v !== undefined) {
      d[key] = v;
    }
  }
  return d;
}

/**
 * Add new doc to Firestore collection & return DocumentReference.
 * @param collectionPath {String}
 * @param data {Object}
 * @param docId {String} null value will create a random id.
 * @param meta {Boolean} auto insert date created timestamp & createdBy userId
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|(function(String, String): firebase.firestore.DocumentReference<firebase.firestore.DocumentData>)>}
 */
export async function newFirestoreDoc(
  collectionPath,
  data,
  docId = null,
  meta = true
) {
  data = fireDataCheck(data);
  if (meta) {
    data.__dateCreated = serverTimestamp();
    data.__dateUpdated = serverTimestamp();
    data.__createdBy = getCurrentFirebaseUserId();
    data.__updatedBy = getCurrentFirebaseUserId();
  }

  if (docId) {
    const theDoc = firestoreDoc(collectionPath, docId);
    await setDoc(theDoc, data);
    return theDoc;
  } else {
    return await addDoc(firestoreCollection(collectionPath), data);
  }
}

export async function updateFirestoreDoc(doc, data, { meta = true } = {}) {
  data = fireDataCheck(data);
  const snap = await getDoc(doc);
  if (meta) {
    if (snap.exists) {
      data.__dateUpdated = serverTimestamp();
      data.__updatedBy = getCurrentFirebaseUserId();
    } else {
      data.__dateCreated = serverTimestamp();
      data.__dateUpdated = serverTimestamp();
      data.__createdBy = getCurrentFirebaseUserId();
      data.__updatedBy = getCurrentFirebaseUserId();
    }
  }
  return await setDoc(doc, data, { merge: true });
  // if (snap.exists()) {
  //   return await updateDoc(doc, data);
  // } else {
  //   return await setDoc(doc, data,{merge:true});
  // }
}
export async function isFirestoreDocExists(collectionPath, docId) {
  if (!docId) return false;
  const snap = await getDoc(firestoreDoc(collectionPath, docId));
  return snap.exists();
}

export async function countDocsWithCertainValue(collectionPath, key, value) {
  const q = query(firestoreCollection(collectionPath), where(key, "==", value));
  const snap = await getDocs(q);
  return snap.docs.length;
}

export function uniqueDbValueValidator(
  collectionPath,
  key,
  { label, formatter, byPassValue } = {}
) {
  return async (value) => {
    if (formatter) {
      value = formatter(value);
    }
    if (value === byPassValue) return true;
    const result = await countDocsWithCertainValue(collectionPath, key, value);
    if (result <= 0) {
      return true;
    } else {
      return (label || capitalize(key)) + " already exists!";
    }
  };
}

export async function useNewFirestoreDoc(
  collectionPath,
  data,
  docId = null,
  meta = true,
  persistData = false
) {
  await newFirestoreDoc(collectionPath, data, docId, meta);
  return useFirestoreDoc(collectionPath, docId, { persistData });
}

/**
 * Convert a Date to Firebase Timestamp
 * @param date {Date}
 * @returns {Timestamp}
 */
export function timestamp(date) {
  return Timestamp.fromDate(date);
}

export function injectSnapshotToObject(snapDoc, obj) {
  const result = getSnapshotData(snapDoc);
  if (!result) return;
  const resultKeys = Object.keys(result);
  for (let key in obj) {
    if (!resultKeys.includes(key)) {
      delete obj[key];
    }
  }
  Object.assign(obj, result);
}

/**
 * Encode docId to replace '.','/','\'
 * @returns {string}
 * @param docId {String}
 */
export const encodeDocId = (docId) => {
  return encodeURIComponent(docId).replace(/\./g, "%2E");
};

export const decodeDocId = (docId) => {
  return decodeURIComponent(docId.replace(/%2E/g, "."));
};

/**
 *
 * @param {string} collectionPath
 * @param {string} fieldPath
 * @param {"<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "array-contains-any" | "not-in"} opStr
 * @param {unknown} value
 * @return {Promise<*[]>}
 */
export async function queryFirestoreCollection(
  collectionPath,
  fieldPath,
  opStr,
  value
) {
  const q = query(
    collection(fsdb, collectionPath),
    where(fieldPath, opStr, value)
  );
  const querySnapshot = await getDocs(q);
  const resData = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    resData.push({
      docId: doc.id,
      docData: doc.data(),
    });
  });
  return resData;
}

/**
 *
 * @param {String} collectionPath
 * @param {String} document
 * @return {Promise<DocumentData|{msg: string}>}
 */
export async function getFirestoreOnce(collectionPath, document) {
  const docRef = doc(fsdb, collectionPath, document);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    return {};
  }
}

export async function getFirestoreCollectionOnce(collectionPath) {
  const returnArr = [];
  const querySnapshot = await getDocs(collection(fsdb, collectionPath));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    returnArr.push({
      docId: doc.id,
      docData: doc.data(),
    });
  });

  return returnArr;
}
