<template>
  <div>
    <div class="hi-ui-title">Woking Time</div>
    <div v-if="phoneSettings[twilioState.currentNumber]" class="p-2">
      <module-block-editable editable>
        <div class="flex gap-10">
          <div>
            Start:
            {{
              phoneSettings[twilioState.currentNumber]["workingHours"][
                "start"
              ].substring(0, 2)
            }}:{{
              phoneSettings[twilioState.currentNumber]["workingHours"][
                "start"
              ].substring(2)
            }}
          </div>
          <div>
            End:
            {{
              phoneSettings[twilioState.currentNumber]["workingHours"][
                "end"
              ].substring(0, 2)
            }}:{{
              phoneSettings[twilioState.currentNumber]["workingHours"][
                "end"
              ].substring(2)
            }}
          </div>
        </div>
        <template v-slot:editor="{ closeDialog }">
          <twilio-working-time-editor
            :initial-values="{
              start: {
                hours: parseInt(
                  phoneSettings[twilioState.currentNumber]['workingHours'][
                    'start'
                  ].substring(0, 2)
                ),
                minutes: parseInt(
                  phoneSettings[twilioState.currentNumber]['workingHours'][
                    'start'
                  ].substring(2)
                ),
                seconds: 0,
              },
              end: {
                hours: parseInt(
                  phoneSettings[twilioState.currentNumber]['workingHours'][
                    'end'
                  ].substring(0, 2)
                ),
                minutes: parseInt(
                  phoneSettings[twilioState.currentNumber]['workingHours'][
                    'end'
                  ].substring(2)
                ),
                seconds: 0,
              },
            }"
            @submit="closeDialog"
          />
        </template>
      </module-block-editable>
    </div>
    <div class="hi-ui-title">Redirect Order</div>
    <div class="p-2">
      <table class="w-full" v-if="phoneSettings[twilioState.currentNumber]">
        <tr class="select-none">
          <th class="text-left">Days</th>
          <th class="text-left">1st</th>
          <th class="text-left">2nd</th>
          <th class="text-left">3rd</th>
          <th></th>
        </tr>

        <tr
          v-for="(item, index) in redirectOrderIndex"
          :key="item + index"
          class="hi-select-item"
          :class="{
            'bg-primary/10': !index,
            higherLine: !index,
            normalLine: index,
          }"
        >
          <td class="select-none">{{ item }}</td>
          <td class="select-none">
            <user-details-span
              :user-id="
                phoneSettings[twilioState.currentNumber]['redirectOrders'][
                  index.toString()
                ][0]
              "
            />
          </td>
          <td class="select-none">
            <user-details-span
              :user-id="
                phoneSettings[twilioState.currentNumber]['redirectOrders'][
                  index.toString()
                ][1]
              "
            />
          </td>
          <td class="select-none">
            <user-details-span
              :user-id="
                phoneSettings[twilioState.currentNumber]['redirectOrders'][
                  index.toString()
                ][2]
              "
            />
          </td>

          <td class="cursor-pointer">
            <hi-dialog-trigger>
              <hi-icon :path="commonIcons.edit" />
              <twilio-change-redirect-order-dialog :order-index="index" />
            </hi-dialog-trigger>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiIcon from "@/hive-vue3/components/HiIcon";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { ref } from "vue";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
import TwilioChangeRedirectOrderDialog from "@/hive-vue3/modules/twilio/TwilioChangeRedirectOrderDialog";
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import TwilioWorkingTimeEditor from "@/hive-vue3/modules/twilio/TwilioWorkingTimeEditor";

export default {
  name: "TwilioFirstColView",
  components: {
    TwilioWorkingTimeEditor,
    ModuleBlockEditable,
    TwilioChangeRedirectOrderDialog,
    HiIcon,
    HiDialogTrigger,
    UserDetailsSpan,
  },
  setup() {
    const phoneSettings = useFirestoreDoc("phone", "settings");

    const redirectOrderIndex = [
      "Today",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const redirectOrderList = ref([]);

    return {
      redirectOrderList,
      redirectOrderIndex,
      phoneSettings,
      commonIcons,
      twilioState,
      console,
    };
  },
};
</script>

<style scoped>
.higherLine {
  line-height: 85px;
}
.normalLine {
  line-height: 45px;
}
</style>
