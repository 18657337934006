<template>
  <hi-card class="w-full h-full p-4" v-if="mailCollection">
    <div>Failed</div>
    <div
      class="flex gap-4"
      v-for="(item, index) of failedList"
      :key="item + index"
    >
      <span class="font-bold"> {{ item.to }}</span>
      <hi-date-time-display :model-value="item.time" />
    </div>
    <div class="pt-6">Delivered</div>
    <div
      class="flex gap-4"
      v-for="(item, index) of deliveredList"
      :key="item + index"
    >
      <span class="font-bold"> {{ item.to }}</span>
      <hi-date-time-display :model-value="item.time" />
    </div>
    <div class="pt-6">Opened</div>
    <div
      class="flex gap-4"
      v-for="(item, index) of openedList"
      :key="item + index"
    >
      <span class="font-bold"> {{ item.to }}</span>
      <div>
        <div
          class="flex gap-4"
          v-for="(time, index) of item.times"
          :key="time + index"
        >
          <hi-date-time-display :model-value="time" />
        </div>
      </div>
    </div>
    <div class="pt-6">Clicked</div>
    <div
      class="flex gap-4"
      v-for="(item, index) of clickedList"
      :key="item + index"
    >
      <span class="font-bold"> {{ item.to }}</span>
      <div>
        <div
          class="flex gap-4"
          v-for="(click, index) of item.click"
          :key="click + index"
        >
          <div>
            <div>{{ click.url }}</div>
            <div>
              <hi-date-time-display :model-value="click.clickedTime" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { computed, ref } from "vue";
import useFirestoreCollectionByQueryBuilder from "@/hive-vue3/firebase/useFirestoreCollectionByQueryBuilder";
import fireStoreQueryBuilder from "@/hive-vue3/firebase/fireStoreQueryBuilder";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
export default {
  name: "EdmSentResultCard",
  components: { HiDateTimeDisplay, HiCard },
  props: {
    sentEdmId: String,
  },
  setup(props) {
    const mailCollection = ref();
    watchPropsImmediately(props, "sentEdmId", (v) => {
      if (v) {
        // console.log(v);
        const q = fireStoreQueryBuilder("emails");
        q.where("edmId", "==", v);
        mailCollection.value = useFirestoreCollectionByQueryBuilder(q);
      }
    });

    const deliveredList = computed(() => {
      const returnArr = [];

      if (mailCollection.value && mailCollection.value.length) {
        for (const i of mailCollection.value) {
          if (i.status && i.status.delivered) {
            const obj = {
              to: i.to[0],
              time: i.status.delivered,
            };
            returnArr.push(obj);
          }
        }
      }
      return returnArr;
    });

    const failedList = computed(() => {
      const returnArr = [];

      if (mailCollection.value && mailCollection.value.length) {
        for (const i of mailCollection.value) {
          if (i.status && i.status.failed) {
            const obj = {
              to: i.to[0],
              time: i.status.failed,
            };
            returnArr.push(obj);
          }
        }
      }
      return returnArr;
    });

    const openedList = computed(() => {
      const returnArr = [];

      if (mailCollection.value && mailCollection.value.length) {
        for (const i of mailCollection.value) {
          if (i.status && i.status.opened) {
            const obj = {
              to: i.to[0],
              times: i.status.opened,
            };
            returnArr.push(obj);
          }
        }
      }
      return returnArr;
    });

    const clickedList = computed(() => {
      const returnArr = [];

      if (mailCollection.value && mailCollection.value.length) {
        for (const i of mailCollection.value) {
          if (i.status && i.status.clicked) {
            const obj = {
              to: i.to[0],
              click: i.status.clicked,
            };
            returnArr.push(obj);
          }
        }
      }
      return returnArr;
    });

    return {
      mailCollection,
      deliveredList,
      openedList,
      failedList,
      clickedList,
    };
  },
};
</script>

<style scoped></style>
