<template>
  <hi-card class="w-full h-full p-4">
    <div>
      <hi-dialog-trigger>
        <hi-button :disabled="edmDetails.status !== 'edit'">
          Change Template</hi-button
        >
        <EdmChangeTemplateDialog />
      </hi-dialog-trigger>
    </div>
    <div class="box">
      <div>Recipient Type</div>
      <div>{{ edmDetails.recipientType }}</div>
      <div class="text-right">
        <hi-dialog-trigger>
          <hi-button text v-if="edmDetails.status === 'edit'">Edit</hi-button>
          <edm-change-recipient-type-dialog
            :initial-values="{
              recipientType: edmDetails.recipientType,
            }"
          />
        </hi-dialog-trigger>
      </div>

      <div>Recipient Group</div>
      <div>{{ edmDetails.groups }}</div>
      <div class="text-right">
        <hi-dialog-trigger>
          <hi-button text v-if="edmDetails.status === 'edit'">Edit</hi-button>
          <edm-change-recipient-group-dialog
            :initial-values="{
              groups: edmDetails.groups,
            }"
            :recipient-type="edmDetails.recipientType"
          />
        </hi-dialog-trigger>
      </div>

      <div>Recipients</div>
      <div>
        {{ recipientNum }}
      </div>
      <div></div>

      <div>Send Name</div>
      <div>
        {{ edmDetails.sendName }}
      </div>
      <div class="text-right">
        <hi-dialog-trigger>
          <hi-button text v-if="edmDetails.status === 'edit'">Edit</hi-button>
          <edm-change-sender-dialog
            :initial-values="{
              senderId: edmDetails.senderId,
            }"
          />
        </hi-dialog-trigger>
      </div>

      <div>Reply To</div>
      <div>
        {{ edmDetails.replyTo }}
      </div>
      <div></div>
      <div>Send Address</div>
      <div>
        {{ edmDetails.sendAddr }}
      </div>
      <div></div>

      <div>Status</div>
      <div>{{ edmDetails.status }}</div>
      <div></div>

      <div v-if="edmDetails && edmDetails.status === 'scheduled'">
        Scheduled Time
      </div>
      <div v-if="edmDetails && edmDetails.status === 'scheduled'">
        <hi-date-time-display :model-value="edmDetails.scheduledTime" />
      </div>

      <div v-if="edmDetails && edmDetails.status === 'sent'">Sent Time</div>
      <div v-if="edmDetails && edmDetails.status === 'sent'">
        <hi-date-time-display :model-value="edmDetails.sentTime" />
      </div>
    </div>

    <div class="flex justify-between">
      <hi-dialog-trigger v-if="edmDetails.status === 'edit'">
        <hi-button :disabled="!canBeScheduled"> Set Schedule</hi-button>
        <edm-set-schedule-dialog />
      </hi-dialog-trigger>
      <hi-dialog-trigger v-if="edmDetails.status === 'scheduled'">
        <hi-button> Change Schedule</hi-button>
        <edm-change-schedule-dialog
          :initial-values="{
            scheduledTime: edmDetails.scheduledTime.toDate(),
            taskId: edmDetails.taskId,
          }"
        />
      </hi-dialog-trigger>
      <hi-button
        @click="cancelSchedule"
        v-if="edmDetails.status === 'scheduled'"
      >
        Cancel</hi-button
      >
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import HiButton from "@/hive-vue3/components/HiButton";
import { requestCancelSchedule } from "@/hive-vue3/api/request";
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";
import { edmState } from "@/hive-vue3/modules/edm/state.js";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import EdmChangeTemplateDialog from "@/hive-vue3/modules/edm/EdmChangeTemplateDialog";
import EdmChangeRecipientTypeDialog from "@/hive-vue3/modules/edm/EdmChangeRecipientTypeDialog";
import EdmChangeRecipientGroupDialog from "@/hive-vue3/modules/edm/EdmChangeRecipientGroupDialog";
import EdmChangeSenderDialog from "@/hive-vue3/modules/edm/EdmChangeSenderDialog";
import EdmSetScheduleDialog from "@/hive-vue3/modules/edm/EdmSetScheduleDialog";
import EdmChangeScheduleDialog from "@/hive-vue3/modules/edm/EdmChangeScheduleDialog";
import hive from "@/hive-vue3/components/hive";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import { computed } from "vue";
import { getLeadsByGroups } from "@/hive-vue3/modules/leads/data";
import { getContactsPeopleByGroup } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "EdmDetailsEditCard",
  components: {
    HiDateTimeDisplay,
    EdmChangeScheduleDialog,
    EdmSetScheduleDialog,
    EdmChangeSenderDialog,
    EdmChangeRecipientGroupDialog,
    EdmChangeRecipientTypeDialog,
    EdmChangeTemplateDialog,
    HiDialogTrigger,
    HiButton,
    HiCard,
  },
  setup() {
    const edmDetails = useFirestoreDocReactiveId(
      "/edm/campaigns/registers",
      edmState,
      "currentEdmId"
    );

    const canBeScheduled = computed(() => {
      return !!(
        edmDetails &&
        edmDetails.recipientType &&
        edmDetails.groups &&
        edmDetails.groups.length &&
        edmDetails.senderId
      );
    });

    const recipientNum = computed(() => {
      if (edmDetails.groups && edmDetails.groups.length) {
        if (edmDetails.recipientType === "people") {
          const total = getContactsPeopleByGroup(edmDetails.groups);
          return total.length;
        } else if (edmDetails.recipientType === "leads") {
          const total = getLeadsByGroups(edmDetails.groups);
          return total.length;
        }
      } else {
        return 0;
      }
      return 0;
    });

    async function cancelSchedule() {
      console.log(edmDetails.taskId);
      const res = await hive.confirm("Are you sure to cancel this EDM?");
      if (res) {
        await requestCancelSchedule({
          taskId: edmDetails.taskId,
        });
        await updateFirestoreDoc(
          firestoreDoc("edm/campaigns/registers", edmState.currentEdmId),
          {
            scheduledTime: null,
            taskId: null,
            status: "edit",
          }
        );
      }
    }

    return {
      cancelSchedule,
      edmDetails,
      canBeScheduled,
      recipientNum,
    };
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  display: grid;
  grid-template-columns: 120px auto 120px;
}
</style>
