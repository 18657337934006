<template>
  <hi-dialog width="600px" v-model="open" title="Convert lead to project">
    <hi-form-hive-standard
      :submit-fn="submit"
      cancel-button
      @cancel="open = false"
      @successful="open = false"
      :initial-values="lead"
    >
      <div class="p-2 border-2">
        <div class="font-bold">
          Please select a type for the following lead.
        </div>
        <leads-list-summary class="p-2" :lead-id="leadsState.currentLeadId" />
        <hi-select
          form-key="contacts.type"
          :items="{
            Architect: 'Architect/Designer',
            Builder: 'Builder',
            Contractor: 'Contractor',
            Developer: 'Developer',
            Owner: 'Owner',
          }"
          mandatory
        />
        <hi-form-value-holder
          form-key="accountId"
          :model-value="lead.accountId"
        />
        <hi-form-value-holder
          form-key="contacts.peopleId"
          :model-value="lead.peopleId"
        />
        <hi-form-value-holder
          v-if="lead.entityId"
          form-key="contacts.entityId"
          :model-value="lead.entityId"
        />
      </div>
      <div class="p-2 border-2 flex flex-col gap-2">
        <div class="font-bold">Project Details</div>

        <hi-form-item label="Project Name *">
          <hi-input form-key="name" model-value="123" mandatory />
        </hi-form-item>

        <hi-form-item label="Is Name Official *">
          <hi-chips-selectable
            model-value="Y"
            :items="{
              Y: 'Yes',
              N: 'No',
            }"
            mandatory
            form-key="isNameOfficial"
          />
        </hi-form-item>

        <hi-form-item label="Project Address *">
          <hi-input-address mandatory form-key="address" />
        </hi-form-item>
        <hi-form-item label="Project Type *">
          <hi-select
            mandatory
            :items="{
              residential: 'Residential',
              townhouse: 'Townhouse',
              boutique: 'Boutique',
              'multi-res': 'Multi-Res',
              hospitality: 'Hospitality',
              workplaceFitout: 'Workplace Fitout',
              retail: 'Retail',
              education: 'Education',
              hotel: 'Hotel',
              health: 'Health',
              government: 'Government',
              other: 'Other',
            }"
            form-key="projType"
          />
        </hi-form-item>
        <hi-form-item label="Project Status *">
          <hi-select
            mandatory
            :items="[
              {
                text: 'Sample Request',
                value: 'Sample Request',
              },
              {
                text: 'Builder Tender',
                value: 'Builder Tender',
              },
              {
                text: 'Builder Appointed',
                value: 'Builder Appointed',
              },
              {
                text: 'Quotation',
                value: 'Quotation',
              },
              {
                text: 'Sub Contractor Tender',
                value: 'Sub Contractor Tender',
              },
              {
                text: 'Sub Contractor Appointed',
                value: 'Sub Contractor Appointed',
              },
              {
                text: 'Proforma Invoice Issued',
                value: 'Proforma Invoice Issued',
              },
              {
                text: 'Deposit Paid',
                value: 'Deposit Paid',
              },
              {
                text: 'PO# Received',
                value: 'PO# Received',
              },
            ]"
            form-key="projStatus"
          />
        </hi-form-item>

        <hi-form-value-holder form-key="pipeId" :model-value="lead.pipeId" />

        <hi-form-item label="Main Owner">
          <div class="flex flex-col gap-2">
            <user-details-span show-email :user-id="mainOwner" />
            <mfc-system-user-selector
              v-show="
                currentUserId === lead.mainOwner ||
                permission.maintenance ||
                permission.admin
              "
              form-key="mainOwner"
              v-model="mainOwner"
              disabled
            />
          </div>
        </hi-form-item>

        <hi-form-item label="Sub Owners">
          <div class="flex flex-col gap-2">
            <div class="flex flex-col">
              <user-details-span
                v-for="(user, index) in subOwners"
                :key="user + index"
                show-email
                :user-id="user"
              />
            </div>
            <mfc-system-user-selector
              v-show="
                currentUserId === lead.mainOwner ||
                permission.maintenance ||
                permission.admin
              "
              multiple
              :disabled-ids="[mainOwner]"
              form-key="subOwners"
              v-model="subOwners"
            />
          </div>
        </hi-form-item>
      </div>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { closeLead, getLeadById } from "@/hive-vue3/modules/leads/data";
import { computed, ref } from "vue";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiInputAddress from "@/hive-vue3/components/form/controls/HiInputAddress";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
import LeadsListSummary from "@/hive-vue3/modules/leads/LeadsListSummary";
import { AddNewProjectToFirebase } from "@/hive-vue3/modules/projects/data";
import {
  getCurrentFirebaseUserId,
  useCurrentFirebaseUserId,
  useCurrentFirebaseUserPermissions,
} from "@/hive-vue3/firebase";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";

export default {
  name: "LeadsConvertToProjectDialog",
  components: {
    MfcSystemUserSelector,
    UserDetailsSpan,
    LeadsListSummary,
    HiFormValueHolder,
    HiInput,
    HiChipsSelectable,
    HiInputAddress,
    HiSelect,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  setup() {
    const mainOwner = ref(undefined);
    const subOwners = ref(undefined);

    const open = ref(false);
    const lead = computed(() => getLeadById(leadsState.currentLeadId));

    async function submit(projectData) {
      const contact = projectData.contacts;

      projectData.contacts = [contact];
      projectData.isNameOfficial = projectData.isNameOfficial === "Y";
      projectData.status = "active";

      projectData.estimateSupplyDate = new Date();
      projectData.buildStatus = "Design";

      const res = await AddNewProjectToFirebase(projectData);
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.CONVERT;
      historyObj.target = "leads";
      historyObj.targetId = leadsState.currentLeadId;
      historyObj.item = "Leads to Project";
      historyObj.value = {
        projectId: res.id,
      };
      historyObj.oldValue = {
        leadId: leadsState.currentLeadId,
      };
      await addNewHistory(historyObj);

      await closeLead(leadsState.currentLeadId, {
        isConverted: true,
        closedBy: getCurrentFirebaseUserId(),
      });

      return {
        successful: true,
      };
    }
    const permission = useCurrentFirebaseUserPermissions();
    const currentUserId = useCurrentFirebaseUserId();

    return {
      lead,
      open,
      submit,
      leadsState,
      mainOwner,
      subOwners,
      permission: permission,
      currentUserId,
    };
  },
};
</script>

<style scoped></style>
