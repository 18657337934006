<template>
  <div class="h-full">
    <div class="h-[30px]" v-if="isBigScreen">
      <span v-if="moduleName"
        ><span
          class="breadcrumbs"
          :class="{
            'breadcrumbs-clickable': !moduleSection,
            disabled: moduleSection,
          }"
          @click="$emit('back-to-summary')"
          >{{ moduleName }}</span
        >
        <span class="breadcrumbs"> / </span></span
      >
      <span
        v-if="moduleSection"
        class="breadcrumbs"
        :class="{
          'breadcrumbs-clickable': $slots.col2,
          disabled: !$slots.col2,
        }"
        @click="$emit('back-to-summary')"
      >
        {{ moduleSection }}
      </span>
      <span v-if="pageName" class="breadcrumbs"
        ><span v-if="moduleSection">/</span> {{ pageName }}</span
      >
    </div>
    <div
      class="flex gap-2"
      :style="{ height: isBigScreen ? 'calc(100% - 30px)' : '100%' }"
    >
      <div
        v-if="!isBigScreen && $slots.col2"
        class="w-[50px] shrink-0 grow-0"
        @click="$emit('back-to-summary')"
      >
        <hi-icon :path="commonIcons.back" class="clickable text-2xl" />
      </div>
      <div
        class="h-full shrink-0 flex flex-col justify-stretch"
        style="min-width: 522px"
        :style="{ width: middleBigger ? '25%' : '33%' }"
        v-if="isBigScreen || !$slots.col2"
      >
        <hi-card v-if="fullCardFirstCol" class="h-full">
          <hi-div
            flex-col-full-height-to-support-grow
            v-if="$slots['module-title']"
          >
            <div class="bg-light-400 p-4 flex items-center">
              <slot name="module-title" />
            </div>
            <div class="grow h-0">
              <slot name="col1" />
            </div>
          </hi-div>
          <slot v-else name="col1" />
        </hi-card>
        <slot v-else name="col1" />
      </div>
      <div
        class="h-full shrink-0 flex flex-col gap-2 justify-stretch min-w-[550px]"
        :style="{
          width: middleBigger || (!isBigScreen && $slots.col2) ? '40%' : '33%',
        }"
        :class="{ grow: middleBigger }"
        v-if="$slots.col2"
      >
        <slot name="col2" />
      </div>
      <div
        class="h-full shrink-0 flex flex-col gap-2 justify-stretch grow w-0 min-w-[550px]"
        v-if="$slots.col2"
      >
        <!--      Grow Issue on the Third Line-->
        <!--      <div-->
        <!--        class="h-full shrink-0 flex flex-col gap-2 justify-stretch"-->
        <!--        :style="{ width: middleBigger ? '25%' : '' }"-->
        <!--        :class="{ grow: !middleBigger }"-->
        <!--      >-->
        <slot name="col3" />
      </div>
      <div
        class="h-full shrink-0 flex flex-col gap-2 justify-stretch grow min-w-[550px]"
        v-if="!$slots.col2"
      >
        <slot name="summary">This is Summary!</slot>
      </div>
    </div>
  </div>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import HiDiv from "@/hive-vue3/components/HiDiv";
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiIcon from "@/hive-vue3/components/HiIcon";
export default {
  name: "LayoutThreeColumn",
  components: { HiIcon, HiDiv, HiCard },
  props: {
    middleBigger: Boolean,
    fullCardFirstCol: Boolean,
    moduleName: String,
    moduleSection: String,
    pageName: String,
    noBigScreen: Boolean,
  },
  setup(props) {
    const { width } = useWindowSize();
    const isBigScreen = computed(() => {
      if (props.noBigScreen) {
        return true;
      }
      return width.value > 1850;
    });

    return {
      isBigScreen,
      commonIcons,
    };
  },
};
</script>

<style scoped>
.breadcrumbs {
  @apply text-sm capitalize opacity-50;
}
.breadcrumbs-clickable:hover {
  @apply opacity-100;
}
.breadcrumbs-clickable {
  @apply underline cursor-pointer transition-opacity;
}
</style>
