<template>
  <div
    v-if="!googleAuthed"
    class="hi-banner flex p-2 items-center bg-gray-100 border border-gray-150"
  >
    <div>Gmail not synced yet.</div>
    <div class="grow" />
    <hi-spinner v-if="authing" class="w-4" />
    <hi-button v-else @click="grant">Link Gmail Account</hi-button>
  </div>
  <div v-else class="hi-banner flex p-2 items-center hi-info-successful">
    <div>Gmail synced.</div>
  </div>
</template>

<script>
import HiButton from "@/hive-vue3/components/HiButton";
import {
  grantGoogleOfflineAccess,
  useGoogleAuthed,
} from "@/hive-vue3/modules/google/googleAuth";
import HiSpinner from "@/hive-vue3/components/HiSpinner";
import { ref } from "vue";
export default {
  name: "GmailAuthStatusBar",
  components: { HiSpinner, HiButton },
  setup() {
    const googleAuthed = useGoogleAuthed();
    const authing = ref(false);
    async function grant() {
      authing.value = true;
      await grantGoogleOfflineAccess();
      authing.value = false;
    }
    return {
      googleAuthed,
      grant,
      authing,
    };
  },
};
</script>

<style scoped></style>
