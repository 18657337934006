<template>
  <hi-form @submit="submit">
    <hi-form-item label="Redirect To Number">
      <hi-input mobile v-model="mobileNum" form-key="mobile" />
    </hi-form-item>
    <hi-form-item label="Redirect To User">
      <div>
        <mfc-system-user-selector form-key="user" v-model="systemUser" />

        <div class="mt-4" v-if="systemUser">
          <user-details-span :user-id="systemUser" show-mobile />
        </div>
      </div>
    </hi-form-item>
  </hi-form>
</template>

<script>
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { ref, watch } from "vue";
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import { useSystemUsers } from "@/hive-vue3/firebase";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { requestRedirectPhoneCall } from "@/hive-vue3/api/request";
import hive from "@/hive-vue3/components/hive";
export default {
  name: "TwilioRedirectCallDialog",
  components: {
    UserDetailsSpan,
    MfcSystemUserSelector,
    HiFormItem,
    HiForm,
    HiInput,
  },
  props: {
    currentCall: Object,
  },

  setup(props, { emit }) {
    const mobileNum = ref(undefined);
    const systemUser = ref(undefined);
    const systemUserList = useSystemUsers();

    watch(systemUser, (v) => {
      mobileNum.value = systemUserList[v].mobile;
    });

    async function submit() {
      console.log(
        "uid",
        systemUserList[systemUser.value].mobile === mobileNum.value
          ? systemUser.value
          : mobileNum.value
      );
      const res = await requestRedirectPhoneCall({
        callId: props.currentCall.CallSid,
        transNumber: mobileNum.value,
        uid:
          systemUserList[systemUser.value].mobile === mobileNum.value
            ? systemUser.value
            : mobileNum.value,
      });

      console.log(res);
      if (res.successful) {
        emit("successful");
      } else {
        hive.toastError(JSON.stringify(res.data));
      }
    }

    return {
      mobileNum,
      systemUser,
      submit,
      systemUserList,
    };
  },
};
</script>

<style scoped></style>
