<template>
  <enquiries-view-full-kit class="h-full" />
</template>

<script>
import EnquiriesViewFullKit from "@/hive-vue3/modules/enquiries/EnquiriesViewFullKit";
export default {
  name: "EnquiryView",
  components: { EnquiriesViewFullKit },
};
</script>

<style scoped></style>
