import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";
import {
  watchPropsImmediately,
  watchPropsImmediatelyAndForever,
} from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default function (
  collectionPath,
  reactiveObj,
  valueKey,
  options = { persistData: false }
) {
  const { docData, setId } = useFirestoreDocQueryable(
    collectionPath,
    null,
    options
  );
  if (options.persistData) {
    watchPropsImmediatelyAndForever(reactiveObj, valueKey, (v) => setId(v));
  } else {
    watchPropsImmediately(reactiveObj, valueKey, (v) => setId(v));
  }
  return docData;
}
