<template>
  <hi-dialog
    close-button
    title="Add New Product"
    v-model="status.open"
    height="800px"
    width="1200px"
  >
    <div class="h-full w-full flex gap-2">
      <div
        class="w-1/3 flex flex-col justify-between items-center border-2 p-4"
      >
        <div class="w-2/3">
          <transition-fade>
            <color-item :color-id="formData.colorId" show-title />
          </transition-fade>
        </div>
        <div class="w-2/3">
          <transition-fade>
            <pattern-item :pattern-id="formData.patternId" show-title />
          </transition-fade>
        </div>
        <div class="h-[75px]">
          <transition-fade>
            <div class="flex gap-4" v-if="status.currentSize">
              <div class="w-[155px]" style="text-align-last: justify">
                <div>
                  {{
                    `${status.currentSize.size[0].length} × ${status.currentSize.size[0].width} × ${status.currentSize.thickness} - ${status.currentSize.veneer}`
                  }}
                </div>
                <div v-if="status.currentSize.size.length === 2">
                  {{
                    `${status.currentSize.size[1].length} × ${status.currentSize.size[1].width} × ${status.currentSize.thickness} - ${status.currentSize.veneer}`
                  }}
                </div>
              </div>
              <div class="flex flex-col items-center">
                <div class="font-bold capitalize">{{ formData.grade }}</div>
                <div>
                  {{
                    status.currentPrice !== undefined
                      ? status.currentPrice === 0
                        ? "On Application"
                        : "$" + status.currentPrice.toLocaleString()
                      : "N/A"
                  }}
                </div>
              </div>
            </div>
          </transition-fade>
        </div>
      </div>
      <div class="grow w-0 h-full flex flex-col border-2">
        <hi-stage-group class="w-full grow h-0" v-model="status.stage">
          <hi-stage name="select-color">
            <div class="p-4 h-full flex flex-col">
              <div class="flex justify-around">
                <div>Select Color</div>
              </div>
              <div
                class="grow h-0 p-4 flex gap-4 flex-wrap justify-between overflow-auto"
              >
                <div
                  class="w-[120px] p-2 transition hover:text-rose-500 cursor-pointer"
                  v-for="(item, index) in colorList"
                  :key="item + index"
                  @click="selectColor(item)"
                  :class="{ 'text-rose-500': item.id === formData.colorId }"
                >
                  <color-item show-title show-collection :color-id="item.id" />
                </div>
                <div
                  v-for="item in (colorList.length % 5) + 1"
                  :key="item"
                  class="w-[120px]"
                ></div>
              </div>
            </div>
          </hi-stage>

          <hi-stage name="select-pattern">
            <div class="p-4 h-full flex flex-col">
              <div class="flex justify-around">
                <div>Select Pattern</div>
              </div>
              <div
                class="grow h-0 p-4 flex gap-4 flex-wrap justify-between overflow-auto"
              >
                <div
                  class="w-[120px] p-2 transition hover:text-rose-500 cursor-pointer"
                  v-for="(item, index) in patternList"
                  :key="item + index"
                  @click="selectPattern(item.id)"
                  :class="{ 'text-rose-500': item.id === formData.patternId }"
                >
                  <pattern-item
                    show-title
                    show-collection
                    :pattern-id="item.id"
                  />
                </div>
                <div
                  v-for="item in (colorList.length % 5) + 1"
                  :key="item"
                  class="w-[120px]"
                ></div>
              </div>
            </div>
          </hi-stage>
          <hi-stage name="select-size">
            <div class="p-4 h-full flex flex-col">
              <div class="flex justify-around">
                <div>Select Size</div>
              </div>
              <div
                class="w-[770px] grow h-0 p-4 flex gap-4 flex-col overflow-auto"
              >
                <div
                  class="transition border-b-2"
                  v-for="(item, index) in sizeList"
                  :key="item + index"
                >
                  <div class="flex p-4">
                    <div class="min-w-[180px]">
                      <div class="w-[155px]" style="text-align-last: justify">
                        <div>
                          {{
                            `${item.size[0].length} × ${item.size[0].width} × ${item.thickness} - ${item.veneer}`
                          }}
                        </div>
                        <div v-if="item.size.length === 2">
                          {{
                            `${item.size[1].length} × ${item.size[1].width} × ${item.thickness} - ${item.veneer}`
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="grow">
                      <div
                        v-if="status.currentColorType === 'UV'"
                        class="flex justify-around w-full"
                      >
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'prime',
                          }"
                          @click="selectPrice(item, 'prime')"
                        >
                          <div class="font-bold">Prime</div>
                          <div class="min-w-[125px]">
                            {{
                              item.UV.prime
                                ? ` $${item.UV.prime.toLocaleString()}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'medium',
                          }"
                          @click="selectPrice(item, 'medium')"
                        >
                          <div class="font-bold">Medium</div>
                          <div class="min-w-[125px]">
                            {{
                              item.UV.medium
                                ? `$${item.UV.medium}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'practical',
                          }"
                          @click="selectPrice(item, 'practical')"
                        >
                          <div class="font-bold">Practical</div>
                          <div class="min-w-[125px]">
                            {{
                              item.UV.practical
                                ? `$${item.UV.practical}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="flex justify-around w-full">
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'prime',
                          }"
                          @click="selectPrice(item, 'prime')"
                        >
                          <div class="font-bold">Prime</div>
                          <div class="min-w-[125px]">
                            {{
                              item.RS.prime
                                ? ` $${item.RS.prime.toLocaleString()}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'medium',
                          }"
                          @click="selectPrice(item, 'medium')"
                        >
                          <div class="font-bold">Medium</div>
                          <div class="min-w-[125px]">
                            {{
                              item.RS.medium
                                ? `$${item.RS.medium}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                        <div
                          class="flex flex-col items-center hover:text-rose-500 cursor-pointer"
                          :class="{
                            'text-rose-500':
                              formData.sizeId === item.id &&
                              formData.grade === 'practical',
                          }"
                          @click="selectPrice(item, 'practical')"
                        >
                          <div class="font-bold">Practical</div>
                          <div class="min-w-[125px]">
                            {{
                              item.RS.practical
                                ? `$${item.RS.practical}`
                                : "On Application"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </hi-stage>
        </hi-stage-group>
        <div class="p-4 flex justify-between">
          <hi-button :disabled="!backEnable" @click="goToPreviousStage"
            >Back</hi-button
          >
          <hi-button
            v-if="saveEnable"
            :disabled="status.saveDisable"
            primary
            @click="onSave"
            >Save</hi-button
          >
          <hi-button v-else :disabled="!nextEnable" @click="goToNextStage"
            >Next</hi-button
          >
        </div>
      </div>
    </div>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { computed, reactive } from "vue";
import HiStageGroup from "@/hive-vue3/components/HiStageGroup";
import HiStage from "@/hive-vue3/components/HiStage";
import HiButton from "@/hive-vue3/components/HiButton";
import ColorItem from "@/hive-vue3/modules/projects/parts/product/ColorItem";
import PatternItem from "@/hive-vue3/modules/projects/parts/product/PatternItem";
import {
  getAllActiveColors,
  getAllActivePatterns,
  getColorById,
  getPatternByPatternId,
  getPatternSizeList,
  getProductShortNumString,
} from "@/hive-vue3/modules/products/data";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import { addProductToProject } from "@/hive-vue3/modules/projects/data";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
export default {
  name: "ProjectAddProductDialog",
  components: {
    TransitionFade,
    PatternItem,
    ColorItem,
    HiButton,
    HiStage,
    HiStageGroup,
    HiDialog,
  },
  setup() {
    const formData = reactive({
      colorId: undefined,
      patternId: undefined,
      sizeId: undefined,
      grade: undefined,
    });

    const status = reactive({
      open: false,
      stage: "select-color",
      currentColorType: undefined,
      currentPrice: undefined,
      currentSize: undefined,
      saveDisable: false,
    });

    watchPropsImmediately(status, "open", (v) => {
      if (v) {
        formData.colorId = undefined;
        formData.patternId = undefined;
        formData.sizeId = undefined;
        formData.grade = undefined;
        status.stage = "select-color";
        status.currentColorType = undefined;
        status.currentPrice = undefined;
        status.currentSize = undefined;
        status.saveDisable = false;
      }
    });

    const stageList = ["select-color", "select-pattern", "select-size"];

    const colorList = computed(() => {
      return getAllActiveColors();
    });
    const patternList = computed(() => {
      return getAllActivePatterns();
    });
    const sizeList = computed(() => {
      return getPatternSizeList(formData.patternId);
    });

    const nextEnable = computed(() => {
      switch (status.stage) {
        case "select-color":
          if (formData.colorId) {
            return true;
          }
          break;
        case "select-pattern":
          if (formData.patternId) {
            return true;
          }
          break;
        case "select-size":
          return false;
      }
      return false;
    });

    const saveEnable = computed(() => {
      return (
        status.stage === "select-size" &&
        formData.colorId &&
        formData.sizeId &&
        formData.patternId &&
        formData.grade
      );
    });

    const backEnable = computed(() => {
      const stageIndex = stageList.indexOf(status.stage);
      return stageIndex !== 0;
    });

    function goToPreviousStage() {
      const stageIndex = stageList.indexOf(status.stage);
      if (stageIndex > 0) {
        status.stage = stageList[stageIndex - 1];
      }
    }
    function goToNextStage() {
      if (nextEnable.value) {
        const stageIndex = stageList.indexOf(status.stage);
        if (stageIndex >= 0) {
          status.stage = stageList[stageIndex + 1];
        }
      }
    }

    function selectColor(color) {
      formData.colorId = color.id;
      status.currentColorType = color.type;
      formData.patternId = undefined;
      formData.sizeId = undefined;
      formData.grade = undefined;
      status.currentPrice = undefined;
      status.currentSize = undefined;

      goToNextStage();
    }
    function selectPattern(patternId) {
      formData.patternId = patternId;
      formData.sizeId = undefined;
      formData.grade = undefined;
      status.currentPrice = undefined;
      status.currentSize = undefined;
      goToNextStage();
    }
    function selectPrice(size, grade) {
      formData.sizeId = size.id;
      formData.grade = grade;
      status.currentPrice = size[status.currentColorType][grade];
      status.currentSize = size;
    }

    async function onSave() {
      status.saveDisable = true;

      const color = getColorById(formData.colorId);
      const pattern = getPatternByPatternId(formData.patternId);
      const size = status.currentSize;
      const grade = formData.grade;

      const shortNum = getProductShortNumString({
        color,
        pattern,
        size,
        grade,
      });

      await addProductToProject(projectsState.currentProjectId, {
        ...formData,
        shortNum,
      });
      status.saveDisable = false;
      status.open = false;
    }

    return {
      status,
      goToNextStage,
      formData,
      colorList,
      selectColor,
      goToPreviousStage,
      nextEnable,
      backEnable,
      selectPattern,
      patternList,
      sizeList,
      selectPrice,
      saveEnable,
      onSave,
    };
  },
};
</script>

<style scoped></style>
