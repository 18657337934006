<template>
  <transition-fade>
    <div
      v-if="status.show"
      class="hi-banner hi-info px-4 py-2 relative flex items-baseline"
      :class="{
        'hi-info-warning': warning,
        'hi-info-successful': successful,
        'hi-info-danger': danger,
        'hi-info-dark': dark,
        'whitespace-nowrap': !multiline,
      }"
    >
      <div class="grow">
        <slot />
      </div>

      <hi-icon
        :path="mdiClose"
        v-if="closable"
        class="flex-shrink-0 clickable pointer-events-auto ml-4"
        @click="hide"
      />
    </div>
  </transition-fade>
</template>

<script>
import { reactive } from "vue";
import TransitionFade from "../transitions/TransitionFade";
import { mdiClose } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
export default {
  name: "HiBanner",
  components: { HiIcon, TransitionFade },
  props: {
    warning: Boolean,
    danger: Boolean,
    successful: Boolean,
    dark: Boolean,
    closable: Boolean,
    multiline: Boolean,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const status = reactive({ show: true });
    function hide() {
      emit("close");
      status.show = false;
    }
    return {
      status,
      hide,
      mdiClose,
    };
  },
};
</script>

<style scoped></style>
