<template>
  <account-view-full-kil class="h-full" />
</template>

<script>
import AccountViewFullKil from "@/hive-vue3/modules/accounts/AccountViewFullKit";
export default {
  name: "AccountsView",
  components: { AccountViewFullKil },
};
</script>

<style scoped></style>
