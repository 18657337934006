<template>
  <div v-if="pattern" class="w-full flex flex-col gap-2 items-center">
    <hi-vault-image :aspectRatio="1" class="w-full" :file-id="pattern.img" />
    <div v-if="showTitle" class="font-bold">
      {{ pattern.name }} ({{ pattern.shortNum }})
    </div>
    <div v-if="showCollection">{{ pattern.collection }}</div>
    <div v-if="showDescription" class="w-full break-words">
      {{ pattern.description }}
    </div>
  </div>
</template>

<script>
import HiVaultImage from "@/hive-vue3/components/firebase/HiVaultImage";
import { computed } from "vue";
import { getPatternByPatternId } from "@/hive-vue3/modules/products/data";
export default {
  name: "PatternItem",
  components: { HiVaultImage },
  props: {
    patternId: String,
    showTitle: Boolean,
    showCollection: Boolean,
    showDescription: Boolean,
  },
  setup(props) {
    const pattern = computed(() => {
      return getPatternByPatternId(props.patternId);
    });

    return {
      pattern,
    };
  },
};
</script>

<style scoped></style>
