<template>
  <div class="flex gap-1">
    <hi-chip v-for="tag in displayTags" :key="tag" :small="small">
      {{ tag }}
    </hi-chip>
  </div>
</template>

<script>
import HiChip from "@/hive-vue3/components/HiChip";
import { computed, watchEffect } from "vue";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";

export default {
  name: "HiTags",
  components: { HiChip },
  props: {
    map: Object,
    tagCollectionPath: String,
    tagDocPath: {
      type: String,
    },
    modelValue: [String, Array],
    small: Boolean,
  },
  setup(props) {
    if (props.tagDocPath && props.map) {
      console.warn(
        `HiTagger: props.map has been specified. props.docPath ${props.tagDocPath} has been ignored!`
      );
    }
    let doc;
    watchEffect(() => {
      if (props.tagDocPath && !props.map) {
        doc = useFirestoreDoc(
          props.tagCollectionPath || props.tagDocPath,
          props.tagCollectionPath ? props.tagDocPath : null
        );
      }
    });

    function getTag(val) {
      const map = props.map || doc;
      return map[val];
    }

    const displayTags = computed(() => {
      const val = props.modelValue;
      if (Array.isArray(val)) {
        return val.map(getTag);
      } else {
        return [getTag(val)];
      }
    });
    return {
      displayTags,
    };
  },
};
</script>

<style scoped></style>
