<template>
  <hi-list :items="enquiries">
    <template v-slot="{ item }">
      <div class="flex items-center gap-4">
        <enquiry-list-item :enquiry="item" class="grow" />

        <hi-icon
          class="clickable-icon"
          :path="mdiOpenInNew"
          title="Link Account"
          @click="jump(item.id)"
        />
      </div>
    </template>
  </hi-list>
</template>

<script>
import { computed } from "vue";
import {
  getEnquiresByPeopleId,
  jumpToEnquiry,
} from "@/hive-vue3/modules/enquiries/data";
import { contactsState } from "@/hive-vue3/modules/contacts/state";
import HiList from "@/hive-vue3/components/HiList";
import EnquiryListItem from "@/hive-vue3/modules/enquiries/EnquiryListItem";
import { mdiOpenInNew } from "@mdi/js";
import { useRouter } from "vue-router";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "PeopleEnquiriesList",
  components: { HiIcon, EnquiryListItem, HiList },
  setup() {
    const enquiries = computed(() => {
      // console.log(contactsState.selectedPeopleId);
      return getEnquiresByPeopleId(contactsState.selectedPeopleId);
    });

    const router = useRouter();
    function jump(enquiryId) {
      jumpToEnquiry(router, enquiryId);
    }
    return {
      enquiries,
      mdiOpenInNew,
      jump,
    };
  },
};
</script>

<style scoped></style>
