import $el from "@/hive-vue3/utils/dom/$el";
import gsap from "gsap";

export default function (target, duration = 0.5, times = 1, repeatDelay = 0) {
  target = $el(target);
  const tl = gsap.timeline({ repeat: times - 1, repeatDelay });
  tl.to(target, { scale: 1.05, opacity: 0.85, duration: duration / 2 });
  tl.to(target, { scale: 1, opacity: 1, duration: duration / 2 });
  tl.play();
}
