<template>
  <div class="p-4">
    <activity-feeds v-if="activities.length" :timeline="activities" />
    <hi-view-no-data v-else />
  </div>
</template>

<script>
import { injectActivities } from "@/hive-vue3/modules/activities/activitiesDataProvider";
import ActivityFeeds from "@/hive-vue3/modules/activities/ActivityFeeds";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";

export default {
  name: "ActivityList",
  components: {
    HiViewNoData,
    ActivityFeeds,
  },
  setup() {
    const activities = injectActivities();
    return {
      activities,
    };
  },
};
</script>

<style scoped></style>
