<template>
  <hi-form :initial-values="initialValues" @submit="submit">
    <div class="my-4 font-bold">Working Hours Greeting Setting</div>

    <hi-form-item label="Greeting">
      <hi-textarea :rows="2" form-key="greeting" />
    </hi-form-item>

    <hi-form-item label="Redirecting (Busy)">
      <hi-textarea :rows="2" form-key="redirecting" />
    </hi-form-item>
    <hi-form-item label="Ending (Normal)">
      <hi-textarea :rows="2" form-key="ending" />
    </hi-form-item>
    <hi-form-item label="Ending (No Answer)">
      <hi-textarea :rows="2" form-key="noAnswerEnding" />
    </hi-form-item>
  </hi-form>
</template>

<script>
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";

export default {
  name: "TwilioWorkingHoursGreetingEditor",
  components: { HiTextarea, HiFormItem, HiForm },
  props: {
    initialValues: Object,
  },
  setup() {
    async function submit(data) {
      await updateFirestoreDoc(firestoreDoc("phone", "settings"), {
        [twilioState.currentNumber]: {
          greetings: {
            workingHours: data,
          },
        },
      });
    }
    return {
      submit,
    };
  },
};
</script>

<style scoped></style>
