<template>
  <hi-data-list-kit
    :sortables="leadsSortRules"
    @select="(v) => (leadsState.currentLeadId = v.id)"
    min-item-height="85"
    :filter-value="filterOwner"
    filter-key="mainOwner"
    :current-id="leadsState.currentLeadId"
  >
    <template v-slot:filterArea>
      <hi-select
        placeholder="Main Owner..."
        v-model="filterOwner"
        :items="userNoMaintenance"
        no-form
      />
    </template>
    <template v-slot="{ item }">
      <div class="flex gap-4">
        <leads-list-summary class="grow" :lead-id="item.id" />
        <div class="min-w-[120px]">
          <div class="tp-description-sm">{{ item.status }}</div>
          <div class="tp-description-sm">
            <hi-date-time-display :model-value="item.__dateCreated" friendly />
          </div>
        </div>
      </div>
    </template>
  </hi-data-list-kit>
</template>

<script>
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import { getClosedLeads, leadsSortRules } from "@/hive-vue3/modules/leads/data";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import { computed } from "vue";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import LeadsListSummary from "@/hive-vue3/modules/leads/LeadsListSummary";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import { ref } from "vue";
import { useSystemUsers } from "@/hive-vue3/firebase";

export default {
  name: "LeadsClosedList",
  components: { HiSelect, LeadsListSummary, HiDateTimeDisplay, HiDataListKit },
  setup() {
    const closedList = computed(() => {
      return getClosedLeads();
    });

    const storeValue = createDataListStore(closedList);
    provideDataListStore(storeValue);

    const filterOwner = ref(undefined);
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [{ text: "NAN", value: "noFilter" }];
      for (const i in users) {
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push({
            text: users[i].name,
            value: users[i].id,
          });
        }
      }
      return returnArr;
    });

    return {
      leadsSortRules,
      leadsState,
      userNoMaintenance,
      filterOwner,
    };
  },
};
</script>

<style scoped></style>
