<template>
  <div><slot /></div>
</template>

<script>
import { computed, provide } from "vue";

export default {
  name: "ModuleInfoBlock",
  props: {
    data: Object,
  },
  setup(props) {
    provide(
      "module-info-block-data",
      computed(() => props.data)
    );
  },
};
</script>

<style scoped></style>
