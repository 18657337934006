<template>
  <div class="flex items-center">
    <div class="grow" v-if="enquiry.name">
      <div class="tp-subtitle flex">
        <div class="ellipse-flex-grow-item">
          {{ enquiry.name }}
        </div>
      </div>

      <div class="tp-description-sm">
        <span v-if="enquiry.email" v-html="enquiry.email" />
        |
        <span v-if="enquiry.phoneNum" v-html="enquiry.phoneNum" />
      </div>

      <div class="flex">
        <div v-if="enquiry.contactId" class="tp-description-sm">
          Contact Linked
        </div>
        <div v-else class="tp-description-sm">No Contact</div>

        <div class="tp-description-sm" v-if="enquiry.status === 'close'">
          <div>
            &nbsp;| {{ enquiry.isConverted ? "Converted" : "Not Converted" }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="tp-description-sm text-right flex flex-col">
        Enquiry Time:
        <hi-date-time-display :model-value="enquiry.enquiryAt" friendly />
      </div>
    </div>
  </div>
</template>

<script>
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
export default {
  name: "EnquiryListItem",
  components: { HiDateTimeDisplay },
  props: {
    enquiry: Object,
  },
  setup() {},
};
</script>

<style scoped></style>
