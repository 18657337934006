<template>
  <span :title="friendly && full">{{ display }}</span>
</template>

<script>
import { ref, watchEffect } from "vue";
import formatters from "@/hive-vue3/utils/formatters";
import { parseISO, toDate, isValid, format } from "date-fns";

export default {
  name: "HiDateTimeDisplay",
  props: {
    friendly: Boolean,
    short: Boolean,
    modelValue: [Object, Date, String, Number],
    dateOnly: Boolean,
    monthOnly: Boolean,
  },
  setup(props) {
    const display = ref();
    const full = ref();
    watchEffect(() => {
      // console.log(props.modelValue);
      let value = props.modelValue;

      if (typeof value === "string") {
        value = parseISO(value);
      } else {
        if (value && value.seconds) {
          value = new Date(value.seconds * 1000);
        } else {
          value = toDate(value);
        }
      }
      if (!isValid(value)) return (display.value = "N/A");

      full.value = formatters.time(value);
      if (props.dateOnly) {
        display.value = format(value, props.short ? "dd/MM/yy" : "dd MMM yyyy");
        // display.value = formatters.date(props.modelValue);
      } else if (props.monthOnly) {
        display.value = format(value, "MMM yyyy");
        // display.value = formatters.date(props.modelValue);
      } else if (props.friendly) {
        if (props.short) {
          display.value = formatters.friendlyTimeShorter(value);
        } else {
          display.value = formatters.friendlyTime(value);
        }
      } else {
        display.value = full.value;
      }
    });
    return { display, full };
  },
};
</script>

<style scoped></style>
