import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "@/views/DashboardView";
import LoginView from "@/views/LoginView";
import usersView from "@/views/UsersView";
import ContactsView from "@/views/ContactsView";
import EnquiryView from "@/views/EnquiryView";
import EmailView from "@/views/EmailView";
import QuotationView from "@/views/QuotationView";
import ProjectsView from "@/views/ProjectsView";
import ProductsView from "@/views/ProductsView";
import phoneSettingView from "@/views/PhoneSettingView";
import leadsView from "@/views/LeadsView";
import accountsView from "@/views/AccountsView";
import InvoiceView from "@/views/InvoiceView";
import { useCurrentFirebaseUserPermissions } from "@/hive-vue3/firebase";
import EdmViewFullKit from "@/hive-vue3/modules/edm/EdmViewFullKit";
const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/contacts/:type?/:contactId?",
    name: "contacts",
    component: ContactsView,
    props: (route) => {
      return {
        urlType: route.params.type,
        urlId: route.params.contactId,
      };
    },
  },
  {
    path: "/email/:emailId?",
    name: "email",
    component: EmailView,
    props: (route) => {
      return { urlId: route.params.emailId };
    },
  },
  {
    path: "/enquiries/:enquiryId?",
    name: "enquiries",
    component: EnquiryView,
    props: (route) => {
      return { urlId: route.params.enquiryId };
    },
  },
  {
    path: "/accounts/:accountId?",
    name: "accounts",
    component: accountsView,
    props: (route) => {
      return { urlId: route.params.accountId };
    },
  },

  {
    path: "/leads/:leadId?",
    name: "leads",
    component: leadsView,
    props: (route) => {
      return { urlId: route.params.leadId };
    },
  },
  {
    path: "/projects/:projectId?",
    name: "projects",
    component: ProjectsView,
    props: (route) => {
      return { urlId: route.params.projectId };
    },
  },
  {
    path: "/quotations/:quotationId?",
    name: "quotations",
    component: QuotationView,
    props: (route) => {
      return { urlId: route.params.quotationId };
    },
  },
  {
    path: "/invoice/:invoiceId?",
    name: "invoice",
    component: InvoiceView,
    props: (route) => {
      return { urlId: route.params.invoiceId };
    },
  },
  {
    path: "/products/:patternId?",
    name: "products",
    component: ProductsView,
    props: (route) => {
      return { urlId: route.params.patternId };
    },
    beforeEnter: (to, from, next) => {
      const res = useCurrentFirebaseUserPermissions();
      if ((res && res.admin) || (res && res.maintenance)) {
        next(); // Take you to /something
      } else {
        // If params.blah is blank or in your case, does not have permission, redirect back to the home page
        next({ name: "dashboard" });
      }
    },
  },
  {
    path: "/phoneSettings",
    name: "phoneSettings",
    component: phoneSettingView,
  },
  {
    path: "/users",
    name: "users",
    component: usersView,
    beforeEnter: (to, from, next) => {
      const res = useCurrentFirebaseUserPermissions();
      if ((res && res.admin) || (res && res.maintenance)) {
        next(); // Take you to /something
      } else {
        // If params.blah is blank or in your case, does not have permission, redirect back to the home page
        next({ name: "dashboard" });
      }
    },
  },
  {
    name: "edm",
    path: "/edm/:edmId?",
    text: "EDM",
    component: EdmViewFullKit,
    props: (route) => {
      return { urlId: route.params.edmId };
    },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
