<template>
  <hi-dialog v-model="open" width="600px" title="Close & Convert to Leads">
    <div class="font-bold">
      Please confirm to convert the following contact.
    </div>

    <contacts-people-summary
      class="border-2 p-2"
      :entity-id="enquiryData.entityId"
      :people-id="enquiryData.peopleId"
    />

    <hi-form @submit="submit">
      <div class="font-bold">Please select or create an account to link.</div>
      <mfc-input-account form-key="accountId" mandatory />

      <div class="font-bold">
        Please write a description for this conversion.
      </div>
      <hi-textarea form-key="description" :rows="3" mandatory />

      <hi-form-value-holder
        form-key="peopleId"
        :model-value="enquiryData.peopleId"
      />
      <hi-form-value-holder
        form-key="enquiryId"
        :model-value="enquiryState.currentEnquiryId"
      />
      <hi-form-value-holder form-key="status" model-value="toBeAssigned" />
      <hi-form-value-holder
        v-if="enquiryData.entityId"
        form-key="entityId"
        :model-value="enquiryData.entityId"
      />

      <hi-form-value-holder
        form-key="pipeId"
        :model-value="enquiryData.pipeId"
      />
      <!--      <hi-form-value-holder form-key="assignedTo" :model-value="{}" />-->
    </hi-form>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import { computed } from "vue";
import {
  closeEnquiry,
  getEnquiryById,
} from "@/hive-vue3/modules/enquiries/data";
import HiForm from "@/hive-vue3/components/form/HiForm";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import ContactsPeopleSummary from "@/hive-vue3/modules/contacts/ContactsPeopleSummary";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
import {
  getCurrentFirebaseUserId,
  newFirestoreDoc,
} from "@/hive-vue3/firebase";
import MfcInputAccount from "@/hive-vue3/modules/accounts/controls/MfcInputAccount";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";

export default {
  name: "ConvertEnquiryToLeadsDialog",
  components: {
    MfcInputAccount,
    HiFormValueHolder,
    HiTextarea,
    ContactsPeopleSummary,
    HiForm,
    HiDialog,
  },
  props: {
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    const open = modelRef(props, emit);

    const enquiryData = computed(() => {
      return getEnquiryById(enquiryState.currentEnquiryId);
    });

    async function submit(data) {
      await newFirestoreDoc("leads", { ...data, assignedTo: {} });
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.CLOSE;
      historyObj.target = "enquiry";
      historyObj.targetId = enquiryState.currentEnquiryId;
      historyObj.comments = `Enquiry Closed: Convert to leads. ${data.description}`;

      await addNewHistory(historyObj);
      await closeEnquiry(enquiryState.currentEnquiryId, {
        isConverted: true,
        closedBy: getCurrentFirebaseUserId(),
      });
      open.value = false;
      return { successful: true };
    }

    return {
      enquiryData,
      open,
      enquiryState,
      submit,
    };
  },
};
</script>

<style scoped></style>
