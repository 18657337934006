<template>
  <div class="flex flex-col" style="min-width: 420px">
    <hi-data-list
      @select="onSelect"
      auto-items-per-page
      min-item-height="65"
      class="grow"
      no-padding
      :current-id="mailState.selectedThreadId"
    >
      <template v-slot="{ item }">
        <thread-list-item
          class="h-full test"
          :thread-id="item.threadId"
          :label-ids="item.labelIds || []"
          :class="{ selected: item.threadId === mailState.selectedThreadId }"
        />
      </template>
    </hi-data-list>
    <hi-pagination class="grow-0 hi-footer" />
  </div>
</template>

<script>
import hiDataList from "@/hive-vue3/components/dataDisplay/HiDataList";
import hiPagination from "@/hive-vue3/components/dataDisplay/HiPagination";
import { provideEmailListStore } from "@/hive-vue3/modules/gmail/data";
import ThreadListItem from "@/hive-vue3/modules/gmail/ThreadListItem";
import { mailState } from "@/hive-vue3/modules/gmail/state";

export default {
  name: "GmailThreadsList",
  components: {
    ThreadListItem,
    hiDataList,
    hiPagination,
  },
  setup() {
    provideEmailListStore();
    function onSelect(item) {
      // console.log(item);
      mailState.selectedThreadId = item.threadId;
    }
    return {
      onSelect,
      mailState,
    };
  },
};
</script>

<style scoped>
.test:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  z-index: 2;
}

.selected {
  box-shadow: inset 1px 1px 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  z-index: 2;
}
</style>
