import { computed, inject, readonly, toRef, watch } from "vue";
import { format } from "date-fns";
import { timestampToDate, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { deleteField } from "firebase/firestore";
import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import { isEqual } from "lodash";

export default function (props) {
  if (props && !props.valueKey) {
    console.warn("fireDocHelper found component without valueKey");
  }
  const docData = inject("hi-fire-doc", undefined);

  if (!docData) {
    console.warn(
      "Found fireDoc child has not been provided with HiFireDoc data"
    );
  }
  // watch(docData, (v) => console.log(v));
  const docRef = inject("hi-fire-doc-ref", undefined);
  // console.log(docRef);
  async function updateDbItem(key, value) {
    if (!docRef) {
      console.warn("docRef not injected. update value failed.");
      return;
    }
    const doc = docRef.value;
    if (!doc) {
      console.warn("doc is undefined.");
      return;
    }
    const data = {};
    data[key] = value;
    return updateFirestoreDoc(doc, data);
  }
  async function updateDb(values) {
    if (!docRef) {
      console.warn("docRef not injected. update value failed.");
      return;
    }
    const doc = docRef.value;
    if (!doc) {
      console.warn("doc is undefined.");
      return;
    }
    return updateFirestoreDoc(doc, values);
  }
  // function getRef() {
  //   return toRef(data, props.valueKey);
  // }
  // function computedValue() {
  //   return computed(() => {
  //     console.log("computed called");
  //     if (!docData) return null;
  //     console.log(docData);
  //     console.log(props.valueKey);
  //     console.log(docData[props.valueKey]);
  //     return docData[props.valueKey];
  //   });
  // }
  function toValueRef() {
    return readonly(toRef(docData, props.valueKey));
  }

  function syncRef(ref) {
    watch(
      () => docData[props.valueKey],
      (v) => {
        if (isEqual(v, ref.value)) return;
        ref.value = v;
      }
    );
    watch(ref, (v) => {
      if (isEqual(v, getValue())) return;
      updateMe(v);
    });
  }

  function computedTimestampValue(formatPattern) {
    return computed(() => {
      if (docData) {
        // console.log(data.value[props.valueKey]);

        if (docData[props.valueKey]) {
          const val = docData[props.valueKey];
          const date = timestampToDate(val);
          if (!formatPattern) return date;
          else return format(date, formatPattern);
        } else {
          return null;
        }
      }
      return null;
    });
  }
  function getValue() {
    if (!docData) return null;
    return docData[props.valueKey];
  }
  async function updateMe(value) {
    return updateDbItem(props.valueKey, value);
  }
  /*
  这个变量只作用于 value = undefined 的状态。
  如果 value 是空，需要在 uploadComplete 的时候创建新的 []，但是一旦多个push几乎同时触发的时候，如果没有 cache 更新数据库会只剩下最后一个值。
*/
  let arrCache = [];
  async function pushToMeAsArray(value) {
    let arr = getValue();
    if (!arr) {
      arr = arrCache;
    }
    arr.push(value);
    await updateMe(arr);
    arrCache = [];
  }
  async function removeFromMeAsArray(value) {
    const arr = getValue();
    if (!arr || !arr.length) return;
    arrayRemove(arr, value);
    if (!arr.length) {
      return deleteMe();
    } else {
      return updateMe(arr);
    }
  }
  async function deleteMe() {
    return updateMe(deleteField());
  }
  return {
    docData, //reactive
    docRef, //ref
    /**
     * use carefully. This updates not only current key but the whole doc.
     */
    updateDb,
    /**
     * use carefully. This updates other key rather than the current key.
     */
    updateDbItem,
    updateMe,
    pushToMeAsArray,
    removeFromMeAsArray,
    deleteMe,
    /**
     * this is non-reactive value.
     */
    getValue,
    computedTimestampValue,
    /**
     * reactive value as vue ref
     */
    toValueRef,
    syncRef,
  };
}
