import { firestoreCollection, getSnapshotData } from "./utils";
import { getCurrentScope, onScopeDispose, ref } from "vue";
import { onSnapshot, query } from "firebase/firestore";

function isDef(v) {
  return v !== undefined && v !== null;
}

/**
 *
 * @param colRefOrCalPath
 * @param options
 * @returns {reactive<{data: *[], where: function}>}
 */
export default function (colRefOrCalPath, options = { persistData: false }) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;
  const data = ref(undefined);
  const path =
    colRefOrCalPath && colRefOrCalPath.path
      ? colRefOrCalPath.path
      : colRefOrCalPath;

  let close;
  function listen() {
    // console.log("listen");
    const theCol = firestoreCollection(path);

    let q;
    if (options.query) {
      const q1 = options.query;
      if (Array.isArray(q1)) {
        q = query(theCol, ...q1);
        //support for queryBuilder
      } else if (q1.build) {
        q = q1.build();
      } else {
        q = query(theCol, q1);
      }
    } else {
      q = query(theCol);
    }
    //
    // const q = options.queryBuilder
    //   ? query(theCol, options.query)
    //   : query(theCol);
    // console.log(q);
    close = onSnapshot(
      q,
      (snapshot) => {
        data.value = snapshot.docs.map(getSnapshotData).filter(isDef);
        // console.log("snap", data);
      },
      errorHandler
    );
  }
  function stopListen() {
    // console.log("stop listen");
    if (close) close();
  }

  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      stopListen();
    });
  }
  listen();
  return data;
}
