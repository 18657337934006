<template>
  <div class="flex flex-col">
    <hi-list class="grow" no-padding :items="patternColors">
      <template v-slot="{ item: color }">
        <products-color-summary editable :color-id="color.id" />
      </template>
    </hi-list>
  </div>
</template>

<script>
import HiList from "@/hive-vue3/components/HiList";
import { computed } from "vue";
import ProductsColorSummary from "@/hive-vue3/modules/products/productsColorSummary";
import { getAllColorsByType } from "@/hive-vue3/modules/products/data";
export default {
  name: "productsColorList",
  components: {
    ProductsColorSummary,
    HiList,
  },
  props: {
    collection: String,
  },
  setup(props) {
    const patternColors = computed(() => {
      return getAllColorsByType(props.collection);
    });
    return {
      patternColors,
    };
  },
};
</script>

<style scoped></style>
