<template>
  <div class="flex items-center p-4" v-if="emailDetails">
    <div class="grow" v-if="emailDetails.headers">
      <div class="tp-subtitle flex">
        <div class="ellipse-flex-grow-item">
          {{ emailDetails.headers.subject }}
        </div>
      </div>
      <div class="tp-description-sm">
        {{ emailDetails.inbox ? "Inbox - Received From" : "Outbox - Send To" }}
        :
        <span v-if="emailDetails.inbox" v-html="emailDetails.headers.from" />
        <span v-else v-html="emailDetails.headers.to" />
      </div>
      <div class="grow"></div>

      <!--      <div class="tp-description-sm" v-if="emailDetails.attachments.length">-->
      <!--        {{ emailDetails.attachments.length }}-->
      <!--        {{-->
      <!--          emailDetails.attachments.length === 1 ? "Attachement" : "Attachments"-->
      <!--        }}-->
      <!--      </div>-->
    </div>

    <div>
      <div class="tp-description-sm text-right flex flex-col">
        <div>Last refresh:</div>
        <hi-date-time-display
          :model-value="emailDetails.internalDate"
          friendly
        />
      </div>
    </div>
  </div>
</template>

<script>
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";

export default {
  name: "GmailEmailSummary",
  components: { HiDateTimeDisplay },
  props: {
    emailDetails: Object,
  },
  setup() {},
};
</script>

<style scoped></style>
