<template>
  <Transition name="hive-fade-css">
    <slot />
  </Transition>
</template>

<script>
export default {
  name: "TransitionFadeCss",
};
</script>

<style>
.hive-fade-css-enter-active,
.hive-fade-css-leave-active {
  transition: opacity 0.5s ease;
}
.hive-fade-css-enter-to {
  opacity: 1;
}
.hive-fade-css-enter-from,
.hive-fade-css-leave-to {
  opacity: 0;
}
</style>
