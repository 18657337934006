import { getCurrentInstance, nextTick, onMounted } from "vue";
import $el from "@/hive-vue3/utils/dom/$el";

export default function (element, ...keyCode) {
  function run() {
    const el = $el(element);
    if (!el) return;
    for (let i = 0; i < keyCode.length; i++) {
      const key = keyCode[i];
      el.addEventListener("keydown", (e) => {
        if (e.keyCode === key || e.key === key) {
          // console.log(key + " is blocked");
          e.preventDefault();
        }
      });
    }
  }
  if (getCurrentInstance()) onMounted(run);
  else nextTick().then(run);
}
