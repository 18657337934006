<template>
  <hi-image class="w-full h-full" :src="previewSrc">
    <center-box
      class="w-full h-full bg-black bg-opacity-50 text-center text-white space-y-1"
    >
      <div class="text-xxxs ellipse">{{ file && file.name }}</div>
      <hi-file-size class="text-xxxs" v-if="file && file.size" :file="file" />
      <hi-progress-ring
        class="w-10"
        :model-value="state.progress"
        :thickness="6"
      />
      <div class="text-xxs opacity-70">{{ state.info }}</div>
      <div class="text-xxs clickable uppercase mt-1" @click="cancelUpload">
        Cancel
      </div>
    </center-box>
  </hi-image>
</template>

<script>
import HiImage from "@/hive-vue3/components/HiImage";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import HiProgressRing from "@/hive-vue3/components/HiProgressRing";
import { ref } from "vue";
import { vaultUploader } from "@/hive-vue3/firebase/fileVault";
import { onMounted } from "vue";
import hive from "@/hive-vue3/components/hive";
import HiFileSize from "@/hive-vue3/components/misc/HiFileSize";
export default {
  name: "privateVaultUploader",
  components: { HiFileSize, HiProgressRing, CenterBox, HiImage },
  props: {
    file: {
      type: File,
      required: true,
    },
    hash: String,
  },
  emits: ["completed", "cancel", "error"],
  setup(props, { emit }) {
    const previewSrc = ref(null);

    // console.log(docRef.value.path);
    const { state, upload, cancel } = vaultUploader();
    // console.log(state);
    function previewLocal(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          previewSrc.value = reader.result;
          resolve();
        };
      });
    }
    onMounted(async () => {
      if (props.file.type.indexOf("image") === 0) {
        await previewLocal(props.file);
      }
      upload(props.file, props.hash)
        .then((id) => {
          emit("completed", id);
        })
        .catch((e) => {
          hive.toastError("Upload file error: " + e);
          emit("error", e);
        });
    });
    function cancelUpload() {
      cancel();
      emit("cancel");
    }
    return {
      previewSrc,
      state,
      cancel,
      cancelUpload,
    };
  },
};
</script>

<style scoped></style>
