<template>
  <div
    class="overflow-auto"
    v-if="allMailList.emails && allMailList.emails.length"
  >
    <hi-list :items="allMailList.emails" no-padding>
      <template v-slot:default="{ item }">
        <div class="flex items-center">
          <gmail-tag-indicator
            v-if="pipeId"
            :thread-id="item.threadId"
            :pipe-id="pipeId"
            :target-id="targetId"
            :target="target"
          />
          <hi-dialog-trigger class="grow">
            <gmail-email-summary :email-details="item" />
            <hi-dialog width="800px" height="600px">
              <gmail-thread-mails-viewer :thread-id="item.threadId" />
              <!--              <gmail-mail-viewer :email-data="item" />-->
            </hi-dialog>
          </hi-dialog-trigger>
        </div>
      </template>
    </hi-list>
  </div>
  <div v-else>
    <hi-view-no-data />
  </div>
</template>

<script>
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { limit, orderBy, where } from "firebase/firestore";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { reactive } from "vue";
import HiList from "@/hive-vue3/components/HiList";
import GmailEmailSummary from "@/hive-vue3/modules/gmail/components/GmailEmailSummary";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { getCurrentFirebaseUser } from "@/hive-vue3/firebase";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";
import GmailThreadMailsViewer from "@/hive-vue3/modules/gmail/components/GmailThreadMailsViewer";
import GmailTagIndicator from "@/hive-vue3/modules/gmail/GmailTagIndicator";

export default {
  name: "GmailRelativeMailsCommonViewer",
  components: {
    GmailTagIndicator,
    GmailThreadMailsViewer,
    HiViewNoData,
    // GmailMailViewer,
    HiDialog,
    HiDialogTrigger,
    GmailEmailSummary,
    HiList,
  },
  props: {
    email: [String, Array],
    pipeId: String,
    target: String,
    targetId: String,
  },
  setup(props) {
    let allMailList = reactive({
      emails: undefined,
    });
    watchPropsImmediately(props, "email", (v) => {
      if (v) {
        if (Array.isArray(v)) {
          const user = getCurrentFirebaseUser();
          // Query所有与props.email相关的用户邮件
          // 列出邮件是单封email的形式，点击之后dialog中是thread
          allMailList.emails = useFirestoreCollectionAsRef(
            "/system/syncedEmail/allMail",
            {
              query: [
                where("owner", "==", user.email),
                where("emailAddresses", "array-contains-any", v),
                orderBy("internalDate", "desc"),
                limit(100),
              ],
            }
          );
        } else {
          const user = getCurrentFirebaseUser();
          // Query所有与props.email相关的用户邮件
          // 列出邮件是单封email的形式，点击之后dialog中是thread
          allMailList.emails = useFirestoreCollectionAsRef(
            "/system/syncedEmail/allMail",
            {
              query: [
                where("owner", "==", user.email),
                where("emailAddresses", "array-contains", v),
                orderBy("internalDate", "desc"),
                limit(100),
              ],
            }
          );
        }
      } else {
        allMailList.emails = undefined;
      }
    });

    return {
      allMailList,
    };
  },
};
</script>

<style scoped></style>
