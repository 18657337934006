<template>
  <div v-if="lead">
    <hi-fire-doc
      :collection-path="'leads'"
      :doc-id="leadsState.currentLeadId"
      v-if="leadsState.currentLeadId"
    >
      <div class="flex">
        <div class="grow">
          <contacts-people :people-id="lead.peopleId" />
          <hi-fire-doc-tagger
            label="Tags"
            collection-path="leads/general/settings"
            doc-path="leadTags"
            multiple
            value-key="tags"
            can-edit
          />

          <div class="tp-description-sm flex">
            <div class="min-w-[70px]">Description:</div>
            <div>{{ lead.description }}</div>
          </div>
          <div class="tp-description-sm flex" v-if="lead.status === 'closed'">
            <div class="min-w-[70px]">Reason:</div>
            <div>
              {{
                lead.reason
                  ? lead.reason
                  : lead.isConverted
                  ? "Converted to Project"
                  : "unknown"
              }}
            </div>
          </div>

          <div class="tp-description-sm flex">
            <div class="min-w-[70px]">Add by:</div>
            <div>
              <user-details-span :user-id="lead.__createdBy" no-bold /> at
              <hi-date-time-display :model-value="lead.__dateCreated" />
            </div>
          </div>
        </div>
        <div>
          <hi-dialog-trigger>
            <div
              class="hi-trigger-btn"
              :class="{
                disabled: !(
                  currentUserId === lead.mainOwner ||
                  permission.maintenance ||
                  permission.admin
                ),
              }"
            >
              <system-user-avatar-group :user-ids="assignedList" small />
            </div>
            <leads-assign-dialog />
          </hi-dialog-trigger>
        </div>
      </div>
    </hi-fire-doc>
  </div>
</template>

<script>
import { leadsState } from "@/hive-vue3/modules/leads/state";
import { getLeadById } from "@/hive-vue3/modules/leads/data";
import { computed } from "vue";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import SystemUserAvatarGroup from "@/hive-vue3/modules/users/SystemUserAvatarGroup";
import LeadsAssignDialog from "@/hive-vue3/modules/leads/LeadsAssignDialog";
import HiFireDoc from "@/hive-vue3/components/fireDoc/HiFireDoc";
import HiFireDocTagger from "@/hive-vue3/components/fireDoc/HiFireDocTagger";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  useCurrentFirebaseUserId,
  useCurrentFirebaseUserPermissions,
} from "@/hive-vue3/firebase";

export default {
  name: "LeadsTitleCard",
  components: {
    HiDateTimeDisplay,
    UserDetailsSpan,
    HiFireDocTagger,
    HiFireDoc,
    LeadsAssignDialog,
    SystemUserAvatarGroup,
    HiDialogTrigger,
    ContactsPeople,
  },
  setup() {
    const lead = computed(() => {
      return getLeadById(leadsState.currentLeadId);
    });

    const permission = useCurrentFirebaseUserPermissions();
    const currentUserId = useCurrentFirebaseUserId();

    const assignedList = computed(() => {
      if (lead.value && lead.value.mainOwner) {
        if (lead.value.subOwners) {
          return [lead.value.mainOwner, ...lead.value.subOwners];
        }
        return [lead.value.mainOwner];
      }
      return [null];
    });

    return {
      leadsState,
      lead,
      assignedList,
      permission,
      currentUserId,
    };
  },
};
</script>

<style scoped></style>
