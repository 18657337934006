<template>
  <span v-if="file && file.size">{{ size(file.size) }}</span>
</template>

<script>
import Formatters from "@/hive-vue3/utils/formatters";

export default {
  name: "HiFileSize",
  props: {
    file: Object,
  },
  setup() {
    return {
      size: Formatters.humanFileSize,
    };
  },
};
</script>

<style scoped></style>
