<template>
  <hi-data-list-kit
    @select="onSelect"
    search-input-placeholder="Name, Inv#, Address"
    :sortables="sortables"
    :search-fn="invoiceListSearchFn"
    min-item-height="85"
    :current-id="invoiceState.currentInvoiceId"
  >
    <template v-slot="{ item }">
      <invoice-summary :invoice-id="item.id" />
    </template>
  </hi-data-list-kit>
</template>

<script>
import { computed } from "vue";
import { getInvoices } from "@/hive-vue3/modules/invoice/data";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import { invoiceState } from "@/hive-vue3/modules/invoice/state";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import { getContactsFullNameById } from "@/hive-vue3/modules/contacts/data";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import InvoiceSummary from "@/hive-vue3/modules/invoice/InvoiceSummary";

export default {
  name: "InvoiceList",
  components: { InvoiceSummary, HiDataListKit },
  setup() {
    const invoiceList = computed(() => {
      return getInvoices();
    });
    const storeValue = createDataListStore(invoiceList);
    provideDataListStore(storeValue);

    function onSelect(item) {
      invoiceState.currentMiddleColView = "preview";
      invoiceState.currentInvoiceId = item.id;
      invoiceState.isUnSaved = false;
    }

    function invoiceListSearchFn(search, invoice) {
      const attName = getContactsFullNameById(invoice.attention).toLowerCase();
      const project = getProjectById(invoice.projectId);
      const projName = project.name;
      const projAddr = (
        project.address.streetNumber +
        " " +
        project.address.streetShort +
        ", " +
        project.address.cityShort +
        " " +
        project.address.stateShort +
        ", " +
        project.address.postcode
      ).toLowerCase();

      console.log(invoice);
      const invNum = (
        "INV-" + invoice.invoiceNum.toString().padStart(4, "0")
      ).toLowerCase();

      return (
        attName.indexOf(search) >= 0 ||
        projName.indexOf(search) >= 0 ||
        invNum.indexOf(search) >= 0 ||
        projAddr.indexOf(search) >= 0
      );
    }

    const sortables = [
      {
        key: "__dateCreated",
        text: "Create",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
      {
        key: "__dateUpdated",
        text: "Update",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: false,
      },
      {
        key: "invoiceNum",
        text: "No",
        type: dataListSortingTypes.Number,
        desc: true,
        DEFAULT: false,
      },
    ];

    return {
      onSelect,
      invoiceState,
      invoiceListSearchFn,
      sortables,
    };
  },
};
</script>

<style scoped></style>
