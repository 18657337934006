<template>
  <div class="full overflow-auto">
    <div
      class="bg-toolbar-light shadow absolute-origin z-navi w-full pl-[48px] h-[36px]"
    >
      <div class="px-4 h-full flex items-center">Toolbar</div>
    </div>
    <hi-nav-drawer>
      <!-- @slot Add HiNavDrawerItem here for the main menu items -->
      <slot />
      <template v-slot:bottom>
        <!-- @slot Add HiNavDrawerItem here to display at the bottom. Usually for settings stuff. -->
        <slot name="bottom" />
        <hi-nav-drawer-item :icon="mdiLogoutVariant" @click="logout">
          Log Out
        </hi-nav-drawer-item>
      </template>
    </hi-nav-drawer>
    <div class="full pl-[48px] pt-[36px]" style="padding-left: 48px">
      <div
        class="w-full h-full min-h-[870px]"
        :style="{ padding: padding }"
        style="overflow-wrap: break-word"
      >
        <router-view class="w-full h-full" v-slot="{ Component }">
          <transition-fade>
            <component :is="Component" />
          </transition-fade>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HiNavDrawer from "@/hive-vue3/components/nav/HiNavDrawer";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiNavDrawerItem from "@/hive-vue3/components/nav/HiNavDrawerItem";
import { mdiLogoutVariant } from "@mdi/js";
import { logout } from "@/hive-vue3/firebase";

export default {
  props: {
    padding: {
      default: "2em",
    },
  },
  name: "DrawerMenuView",
  components: { HiNavDrawerItem, TransitionFade, HiNavDrawer },
  setup() {
    // const currentENV =
    //   process.env.NODE_ENV === "development" ? "Development Mode!!" : "";

    return {
      logout,
      mdiLogoutVariant,
    };
  },
};
</script>

<style scoped>
.full {
  width: 100%;
  height: 100vh;
}
</style>
