<template>
  <div>
    <hi-html-viewer class="w-full h-full border-primary border" :html="html">
    </hi-html-viewer>
  </div>
</template>

<script>
import HiHtmlViewer from "@/hive-vue3/components/HiHtmlViewer";

export default {
  name: "HtmlViewer",
  components: { HiHtmlViewer },
  props: {
    html: String,
  },
  setup() {},
};
</script>

<style scoped>
.hi-tabs {
  background-color: #cccccc;
}
</style>
