import { firestoreCollection } from "@/hive-vue3/firebase/utils";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";

const collections = {};
export function useFirestoreCollectionAsRefGlobally(colRefOrColPath) {
  const col = firestoreCollection(colRefOrColPath);
  const key = col.path;
  if (!collections[key]) {
    collections[key] = useFirestoreCollectionAsRef(col, { persistData: true });
  }
  return collections[key];
}

/**
 * 使用别名建立子表
 * @param colRefOrColPath
 * @param query
 * @param alias
 * @returns {*}
 */
export function useFirestoreCollectionAsRefGloballyQueryWithAlias(
  colRefOrColPath,
  query,
  alias
) {
  const col = firestoreCollection(colRefOrColPath);
  if (!collections[alias]) {
    collections[alias] = useFirestoreCollectionAsRef(col, {
      persistData: true,
      query,
    });
  }
  return collections[alias];
}

/**
 * 使用别名获取子表
 * @param alias
 * @returns {*}
 */
export function useAliasCollection(alias) {
  return collections[alias];
}
