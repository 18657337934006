<template>
  <hi-card>
    <div v-if="quotationData.quoteNum" class="w-full flex p-2 gap-2">
      <div class="grow flex flex-wrap">
        <hi-button
          :disabled="quotationState.isUnSaved"
          v-for="(item, index) in quotationData.revisions"
          :key="item + index"
          :text="index !== quotationState.revisionId"
          @click="clickRevision(index)"
          >{{
            index
              ? getQuotationId(quotationData.quoteNum, index)
              : `REV-${String.fromCharCode(64 + parseInt(index))}`
          }}</hi-button
        >
      </div>
      <hi-button :disabled="quotationState.isUnSaved" @click="clickEdit"
        >Edit</hi-button
      >
      <hi-button :disabled="!sendEnable" @click="onPreview">Preview</hi-button>

      <hi-button
        :disabled="!sendEnable"
        @click="quotationState.currentMiddleColView = 'proforma'"
        >Convert To INV</hi-button
      >

      <hi-button :disabled="!sendEnable" primary @click="onSend"
        >Send</hi-button
      >
    </div>

    <hi-dialog width="600px" v-model="state.quotationSendDialog">
      <quotation-send-form
        @successful="state.quotationSendDialog = false"
        @cancel="state.quotationSendDialog = false"
      />
    </hi-dialog>
    <hi-dialog v-model="state.pdfPreviewDialog" height="800px" width="1200px">
      <hi-pdf-viewer :source="state.previewSource" />
    </hi-dialog>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { quotationState } from "@/hive-vue3/modules/quotation/state";
import { computed, reactive } from "vue";
import {
  formSendEnable,
  getQuotationById,
  getQuotationByIdAndRevisionId,
  requestQuotationPdf,
} from "@/hive-vue3/modules/quotation/data";
import HiButton from "@/hive-vue3/components/HiButton";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiPdfViewer from "@/hive-vue3/components/HiPdfViewer";
import QuotationSendForm from "@/hive-vue3/modules/quotation/quotationSendForm";
export default {
  name: "QuotationFunctionCard",
  components: {
    QuotationSendForm,
    HiPdfViewer,
    HiDialog,
    HiButton,
    HiCard,
  },
  setup() {
    const state = reactive({
      previewSource: undefined,
      pdfPreviewDialog: false,
      quotationSendDialog: false,
    });

    const quotationData = computed(() => {
      return getQuotationById(quotationState.currentQuotationId);
    });

    function getQuotationId(quoteNum, revisionId) {
      revisionId = parseInt(revisionId);
      if (revisionId) {
        return `QUO-${quoteNum
          .toString()
          .padStart(4, "0")}-${String.fromCharCode(64 + revisionId)}`;
      } else {
        return `QUO-${quoteNum.toString().padStart(4, "0")}`;
      }
    }

    function clickRevision(index) {
      quotationState.revisionId = index.toString();
      quotationState.currentMiddleColView = "preview";
    }

    function clickEdit() {
      quotationState.currentMiddleColView = "edit";
    }

    const sendEnable = computed(() => {
      const res = getQuotationByIdAndRevisionId(
        quotationState.currentQuotationId,
        quotationState.revisionId
      );
      return formSendEnable(res) && !quotationState.isUnSaved;
    });

    async function onPreview() {
      const quotationFormData = getQuotationByIdAndRevisionId(
        quotationState.currentQuotationId,
        quotationState.revisionId
      );
      const res = await requestQuotationPdf(quotationFormData);

      if (res) {
        state.previewSource = res;
        // console.log(res);
        state.pdfPreviewDialog = true;
      }
    }

    async function onSend() {
      state.quotationSendDialog = true;
    }

    return {
      quotationData,
      quotationState,
      getQuotationId,
      clickRevision,
      clickEdit,
      sendEnable,
      onPreview,
      state,
      onSend,
    };
  },
};
</script>

<style scoped></style>
