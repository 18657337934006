<template>
  <div class="w-full h-full">
    <system-email-full-kit class="h-full" :url-id="urlId" />
  </div>
</template>

<script>
import SystemEmailFullKit from "@/hive-vue3/modules/gmail/SystemEmailFullKit";
export default {
  name: "EmailView",
  components: { SystemEmailFullKit },
  props: {
    urlId: String,
  },
};
</script>

<style scoped></style>
