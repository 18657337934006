<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 68.1 90.4"
    xml:space="preserve"
  >
    <polygon
      :style="{ fill: faceColor }"
      points="0,0 0,90.4 68.1,90.4 68.1,52.7 15.4,0 "
    />
    <g>
      <image
        style="overflow: visible; opacity: 0.3"
        width="270"
        height="270"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAETCAYAAAAVqeK4AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAADzpJREFUeNrs3Yty2sgWQNGWkO/8
/+fONUhTroKZTrtfAgEC1q6i/IiTOMRaPt0SOARJkiRJkiRJkiRJkiRJkiRJkj6u4d5/wfTXX82/
4/j334v/Cgkmq/GAiwSTuwECFunDMWlAsubPX6AifSAmFUS2mlIWqEhvjkkBkmEjVJYWKkCR3gCT
DCTDClCGjulj6XgdKtIrY9IJydCBTA8gXagARXoxTCqQ9L7smUyWlS+BIr0SJp2QlF4vwdIDSOl1
U4q0s6YbAErxyL3dg0kOkOX8sUsonzKOP+Zf9IAi7XQySaaSGiS1Ww2UHCRL4/2WPdIbTCahA5Kx
A5UeTH5uc+PzMKFIe59MKlNJC5Gxgkr698aTxpyBZKm8v7afYkKRdjyZDJXpZEwQGQuolDBZzh8X
YzGcXx8SWMbMfooJRdrjZNI5leTwyN1KoOSWOHPhtmTebu2lmFCkne+ZlJY4l9uhAUsJk7lyO2X2
SmZ7KNLrYVI6BZxOH4cIk/TlkIDSmkpO0cshejlEkABFehFMho7JZMxMJocCLClKJUxO54+PISl9
PkCRXmyZUzuDc8jcpgwq6d5JqEAyZn5PCZXcsgco0oM6ZIX5vfna2nRNAYlvX5nXv5KPzU0xQ2GS
GUJ547jr/eM0DfPp5H9feuJk0lrm1FCZKtPJkkwlp/PHHs8fe8ygEgrLndGEIu0Pk6FzmZNOKVP0
8isBJZ480n2Ty4brMQJlDPUratPPFSjSzieT3HUnY4JKaTL5ilA5ZDAJyWQynUFJMRlD3yOdgSLt
EJPa0wuULlg7ZCD5ivZKYiRymPzcvkP+epXcvskAFOk99kxq+yYXRP4X/tx8TaeT2p5J7oK3UIAF
KNIeMOn4GTi5S+vHDChTZTqJN2NTTC57JtN5Oildo9L7LHFAkXa4ZzI0QCntm8TTSbwhO2b2TU5n
TOI9k0OoP6XBABTptZc5obFvUgIlPVWcYhLvmcRLonHlVAIUaceYDJ37JjVQ4s3Y3FLnGPLXptSe
ygAo0gtgMjQmlNpyJwdJvNyJMVmSpU58Krn17G32UKSdYjJ0TCW5fZM1+ydTsh+SYlKD5JaAIj1x
mdP6OTitMzuHFUudy77J1sscoEg72jNZu9RpXcgWTyeXgzm+1qT2iGMTivQGmNR+Vk46oRwaeyil
pU4Jkq2WO0CRnoxJ64eVl57KsQRKaalzqOyb9O7zAEV6kWVOaTLpBWUqLHUup4hby5xho38DUKQd
YFJCpeeJlNLL6+ODuzaZbAkJUKQdYTI0JpXa1bG5y+t7MLlHQJGegEntSYqGK/ZQckud9BHGPU8/
ABTphfdMeiCpPTNb+kjiFJPcGZ17BhTpiXsmoWOpU7tCNj2rc7nmZAx9P7cYKNIbYpJbCvU8O1v8
jGpLqF9n8oiAIj0Yk9LjdmqgxBe1jaH8DGyly+lLnwNQpDdb5vQse3JPW5AudcYnLnWAIu1omVMD
pfQ8KPHl9a1lzqNQAYr0RExC5cBPnz1tzVLnWQFFeiImLVBKp47jn88zP3EiAYq0I0xKS57QACU9
gJ9xRgco0s4wCYXpovbjRlvTSXjSdAIU6YGYDB2otDZlezZwnxlQpCdNJr24pGdwlp3gARRpx5j0
PGNbSEDZy2QCFOmJmNTOyOQgyU0nJhTpQzHp2TStgRIKy529oQIUweRJE0nt9ZCZRJb4gNxpQBFM
dlLtJ/btfTMWKILJDgCpoZJOL69w8AFFMNnBRDJUppTLQTdmfi9QJJh0TS7Di00nQBFMdjap5ECJ
p5Nh5/8WoAgmO4Ml1xjsoUgfj8lw4/vjZ7IfgSKZTNaAMxSmE6BIMLlqqTMARYLJ2omkNJ0s4feG
LFAkmDSXODlkgCLB5G7gAEWCyWpElgIqQJE+HJNh5ceU9k+AIplMrkYIKBJMNplagCLBZJPlD1Ak
mNyMzAIUCSZbTCsDUIAimGyJC1AkmAAFKIIJUIAimAAFKBJMgAIUwQQoQBFMgAIU6SMxAQpQBBOg
AEUwAQpQBBOgAEWCCVCAIpgABSiCCVCAIpgABShAEUyAAhTBBChAEUyAAhQJJkABimACFKAIJkAB
imACFKBIMAEKUAQToABFMAEKUAQTAQUogglQgCKYAAUogglQgCKYCChAEUyAAhTBBChAEUwEFKAI
JkABimACFKAIJkABimAioABFMAEKUAQToABFMBFQgAITAQUogglQgCKYAAUogomAAhSYCChAEUyA
AhTBREABCkwEFKAIJkABimACFKAIJgIKUGAioABFMAEKUAQTAQUoMBFQgAITAQUogglQgCKYCChA
gYmAAhSYCChAEUwEFKDAREABCkwEFKAIJkABimAioAAFJgIKUGAioABFMAEKUIACEwEFKDARUIAC
EwEFKIKJgAIUmAgoQIGJgAIUwQQoQBFMBBSgwERAAQpMBBSgCCYCClBgIqAABSYCClBgIqAARTAR
UIACEwEFKDARUIACEwkoQIGJgAIUmAgoQIGJgAIUwURAAQpMBBSgwERAAQpMJKAABSYCClBgIqAA
BSYCClBgIgEFKDARUIACEwEFKDCRgPKhoMBEQAEKTAQUoMBEQAEKTCSgAAUmAgpQYCKgAAUmElDe
HhSYCChAgYmAAhSYSEB5M1BgIqAABSYCClBgIgHlzUCBiYACFJgIKECBiQSUNwMFJgIKUGAioAAF
JhJQ3gwUmAgoQIGJgAIUmEhAeTNQYCKgAAUmAgpQ9gMKTAQUoMBEAsp+QIGJgAIUmEhA2Q8oMBFQ
gAITCSj7AQUmAgpQYCIBZT+gwERAAQpMJKDsBxSYCChAgYkElP2AAhMBBShvjclSef/geBBQ9gfK
tGM4AlC0Y1CWG77Wl42PiV2AMj0YjeUOvwcuuhc2KShDhMrhDt8ob/mzlgiUoXKcvOWeydZ3NFB0
69ffkBx0Q/Jr441/13LD1/2y8s+bW6BsPZ1MTwKjNO4tldfT/9zc29I9l0GhAsqycilT+thrf9/T
QdnLBuzSqe3S+O4h3WNayb1vbHxDLH09LyuPg1unmjnzzfcuoEw7+A9bGnfskkwmuZfSI5fhQwGV
Q2NCWYNFa2pfOvBaks91KUxdu1vmtP5xPXfkmjs4d4eYUrQ1HKWvscuBOkSoXECJv3a/Kl/jW91a
S6b0LM8vVLaYTqaNAelZ0/XeQXP0HxS/XdozCZY92hiS0td2+k0tBSWeUuKv33sgEjo/Zq4cL5uA
Mm38n9GLx1qpY1nHxp6JZY8evSQvoTJGqEzR1/JcQSb9tdrbpfflppUhmao2X+5Md/oP6MEhfX0u
3HLn+9N1q/QoVGpL9dx+yhihMp+PufRrvPb1PzdgqU08hwi1JXPMbLrcme6ASA2V0p0xd2KSrlPt
l+jRy5/SRmduaZGikgPldL59Ra+fol+bk9fTj6kBtCRT0lI4Vp56anhZiUhrAknvqDEzlqWQwETP
AKV2VmVuoJLCcjgfgyVQSrfeb8Rjso8TX8mb+/xumk6mG+/o3klkzqia3o7nOzfGJER3whj+PBVn
maM97J/kppPSEqQGywWVKUHlGL08Ju+fMsfRIQNK+jmk36g32TuZGnfeULnzWoi0JpFTcidN55dj
5s8ek2mlBglctPXSprXc6TkOelCJJ5Z4cpkiZI4FcGKQ5uibcjyNDKF+rdZNqEwr7rihoXPvNHLM
QPJz+84sX9JxrQcT6dHwlECJj4VT8nbP/kqKSoxL+mvH6PedEqBaoPyG4Yqlzi17Jj0ip3sh8cj2
Hd0x35mlzRzdWSVMgKI9LntaoOSWQaExsYzJ8XAovD1mbkPhtunj27bYMymtFecMJBdEvhNhc5ut
l/XjaDLRG4KS21DtXQrlkBkbkKTTyc3LmiYmP6PNz4hzxVTS2hNJlzRTdCekE8kpGt0OBV1holda
8pQm9nRjtbbHcikHRmsSCZ2QXA3MtPIOK529mQugHDNTyf+Tkaw0kUwZcW2+ai9TSA8qudPGrW+6
pwospYbOW+442WzfZLryTm2peyosb6YKJJff+5X5uLHzjpFedcmTTu7HkL+uZA0uJWzuctxMnXfS
0Lijavsk3xUcUkiO0dKmFxOgaI9TS+91KHPlm+93YRmUOytU28itofOQ60xyoCwFSJZQ33A9FJYq
OUi+M5ikF6uZTPRq08ktoNRgqT1txxIe+MDXqROR3Ou1Jc5luvhOXpYmktP5Y77Cf+fNx2DzVe+D
S2t7oAeUY2FvpXVG6CGoTFfcOa3TwTlQ0mliyUwl8ZV+8bnzIdh81estc2qghLBuUzY3pXyH8mN1
ShfG3RWVLCaN08Otzdcx/Hc1XulCs/SMT3rdSeksDkz0aaDEx0i6l7J2TyV3DIfw4EcN556l+/LJ
XiaHU/jzfPcxlB/9G+9i5yBpbbyCQ5+w5JnD78srevZSameAnvpzc5bCEiW9E4boThgqS5sl/H7k
4yGzvGldfCO9Oyilh6L0nPGpPRZo86nkmj2T3B0RX6JbgiSdSr7C70c75iDpWeKARa+67KktedIJ
vnVVeWm5U5tMqpBs9kC/zL7JsgKU3J2XSpsua3KPL3Chmt59OgkrJpTapHJqgNKLysOegiCHydw4
wFunj9OJJDeVBEscfSgotUfhl57aoxeSTZc70w13QsiMaKGBzuVpBU4FRHqXN0DROy2B1oAyZ/ZF
Sk/t2EJl06bWmila6qQXrcUv58adk94RPQ+ZNpXoE6eUniccmztw6Xn2+uJy51HPAZtDJZ1O0jtn
DH/+/JtTxzRi01WfMpnkvkG3zvb04FJ6Ptqez2f7ZU5hOsn95XMoX24fQ5IDBCQCSx6T2pSyVNBo
PSBw8wvXtpxMaqikP5GvBkhtaQMSfRooIfT9QLu58b7a8ubXAHHNP6L74ExOE+cO+NZtDP1P4OKy
eVn29E0q1/4c49zfEW75WcOrDswOUHphqT2VnElEqp8+DmH9z+4OLUgeikkFlNDAofV2sEciNZc9
NRB6ntPkbpBcu2eS+0fWnrFp6cQDIlLfhBIaQPTgsSkkNx2wmacoqC2BWniARLp+UlnzvrtAcvNB
W3jOk2Hl66XPAyhS33UhvY+z+fVnbQXJJgds5UmUhpVvg0Rah0oXGKXfuyUkmx60jR/cNdz775cg
0/X+zRG528HcQAUi0mNRuTsiDzmoO2EBjnQjFM9E5CkH7I24SNoJHLv+7g8a6TXQkCRJkiRJUn//
CDAARFWyTTj/Lp0AAAAASUVORK5CYII="
        transform="matrix(0.24 0 0 0.24 8.3445 -5.04)"
      ></image>
      <g>
        <polygon
          :style="{ fill: backColor }"
          class="fill-primary"
          points="68.1,52.7 15.4,0 15.4,52.7 		"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FileCorner",
  props: {
    faceColor: {
      type: String,
      default: "#e7e9ed",
    },
    /**
     * default primary color
     */
    backColor: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
