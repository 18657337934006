<template>
  <div class="flow-root">
    <div class="space-y-2">
      <div
        v-for="(event, eventIdx) in timeline"
        :key="event.datetime + eventIdx"
        class="flex space-x-2"
      >
        <div
          class="--this-is-feed-left w-14 h-full inline-flex h-10 items-center justify-end"
        >
          <HiDateTimeDisplay
            :model-value="event.datetime"
            friendly
            short
            class="tp-description-sm"
          />
        </div>
        <div
          class="--this-is-icon w-10 shrink-0 grow-0 flex flex-col"
          :title="tips[event.type]"
        >
          <div
            class="w-10 h-10 rounded-full flex items-center justify-center bg-gray-200 grow-0 shrink-0"
          >
            <hi-icon :path="icons[event.type]" class="text-xl" />
          </div>
          <div class="grow pt-2">
            <div
              class="--this-is-the-line w-1/2 h-full border-r border-gray-200"
            ></div>
          </div>
        </div>
        <div class="grow">
          <div class="tp-title flex h-10 items-center">This is title</div>
          <div class="tp-subtitle">This is more descriptions</div>
        </div>
      </div>
    </div>

    <!--    The show more icon-->
    <div class="flex space-x-2">
      <div class="--this-is-left-space-holder w-14"></div>
      <div class="w-10 text-center text-xl text-gray-400">
        <hi-icon :path="icons.more" />
      </div>
    </div>

    <!--      <div class="relative pb-8">-->
    <!--        <span-->
    <!--          v-if="eventIdx !== timeline.length - 1"-->
    <!--          class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"-->
    <!--          aria-hidden="true"-->
    <!--        />-->
    <!--        <div class="relative flex space-x-3">-->
    <!--          <div>-->
    <!--            <span-->
    <!--              class="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"-->
    <!--            >-->
    <!--              <hi-icon :path="icons.email"></hi-icon>-->
    <!--            </span>-->
    <!--          </div>-->
    <!--          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">-->
    <!--            <div>-->
    <!--              <p class="text-sm text-gray-500">-->
    <!--                {{ event.content }}-->
    <!--                <a :href="event.href" class="font-medium text-gray-900">{{-->
    <!--                  event.target-->
    <!--                }}</a>-->
    <!--              </p>-->
    <!--            </div>-->
    <!--            <div class="text-right text-sm whitespace-nowrap text-gray-500">-->
    <!--              <hi-date-time-display model-value="event.datetime" friendly />-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
  </div>
</template>

<script>
import HiIcon from "@/hive-vue3/components/HiIcon";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  mdiCoffeeOutline,
  mdiDotsHorizontalCircleOutline,
  mdiEmailReceiveOutline,
  mdiEmailSendOutline,
  mdiHomeVariantOutline,
  mdiLaptop,
  mdiPhoneIncomingOutline,
  mdiPhoneOutgoingOutline,
} from "@mdi/js";
const timeline = [
  {
    type: "email-in",
    content: "Applied to",
    target: "Front End Developer",
    href: "#",
    datetime: new Date(),
    // icon: UserIcon,
  },
  {
    type: "appointment",
    content: "Advanced to phone screening by",
    target: "Bethany Blake",
    href: "#",
    date: "Sep 22",
    datetime: "2020-09-22",
    // icon: ThumbUpIcon,
    iconBackground: "bg-blue-500",
  },
  {
    type: "zoom",
    content: "Completed phone screening with",
    target: "Martha Gardner",
    href: "#",
    date: "Sep 28",
    datetime: "2020-09-28",
    // icon: CheckIcon,
    iconBackground: "bg-green-500",
  },
  {
    type: "email-out",
    content: "Advanced to interview by",
    target: "Bethany Blake",
    href: "#",
    date: "Sep 30",
    datetime: "2020-09-30",
    // icon: ThumbUpIcon,
    iconBackground: "bg-blue-500",
  },
  {
    type: "phone-in",
    content: "Completed interview with",
    target: "Katherine Snyder",
    href: "#",
    date: "Oct 4",
    datetime: "2020-10-04",
    // icon: CheckIcon,
    iconBackground: "bg-green-500",
  },
  {
    type: "phone-out",
    content: "Completed interview with",
    target: "Katherine Snyder",
    href: "#",
    date: "Oct 4",
    datetime: "2020-10-04",
    // icon: CheckIcon,
    iconBackground: "bg-green-500",
  },
  {
    type: "visit",
    content: "Completed interview with",
    target: "Katherine Snyder",
    href: "#",
    date: "Oct 4",
    datetime: "2020-10-04",
    // icon: CheckIcon,
    iconBackground: "bg-green-500",
  },
];
export default {
  name: "HiFeeds",
  components: { HiDateTimeDisplay, HiIcon },
  setup() {
    const icons = {
      "email-in": mdiEmailReceiveOutline,
      "email-out": mdiEmailSendOutline,
      "phone-in": mdiPhoneIncomingOutline,
      "phone-out": mdiPhoneOutgoingOutline,
      visit: mdiHomeVariantOutline,
      appointment: mdiCoffeeOutline,
      zoom: mdiLaptop,
      more: mdiDotsHorizontalCircleOutline,
    };
    const tips = {
      "email-in": "Email Received",
      "email-out": "Email Sent",
      "phone-in": "Incoming Call",
      "phone-out": "Outgoing Call",
      visit: "Shop Visiting",
      appointment: "Appointment",
      zoom: "Zoom Meeting",
    };
    return {
      timeline,
      icons,
      tips,
    };
  },
};
</script>

<style scoped></style>
