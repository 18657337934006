import { useRouter } from "vue-router";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { watch } from "vue";

export function setRouterId(props, reactiveObj, key, urlPrefix) {
  const router = useRouter();

  watchPropsImmediately(props, "urlId", (v) => {
    if (v) {
      reactiveObj[key] = v;
    } else {
      if (reactiveObj[key]) {
        router.replace({ path: urlPrefix + reactiveObj[key] });
      }
    }
  });

  watch(
    () => reactiveObj[key],
    (v) => {
      if (v) {
        router.push({ path: urlPrefix + v });
      }
    }
  );
}
