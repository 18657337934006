// import firebase from "firebase/app";
import { setupAuth } from "./auth";
import { initializeApp } from "firebase/app";

export * from "./auth";
export * from "./utils";

export function initializeFirebase(conf) {
  initializeApp(conf);
}
export function initializeFirebaseWithAuth(
  conf,
  router,
  loginViewName = "login"
) {
  initializeFirebase(conf);
  setupAuth(router, loginViewName);
}
