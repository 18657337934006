<template>
  <quotation-view-full-kit class="w-full h-full" />
</template>

<script>
import QuotationViewFullKit from "@/hive-vue3/modules/quotation/QuotationViewFullKit";
export default {
  name: "QuotationView",
  components: { QuotationViewFullKit },
};
</script>

<style scoped></style>
