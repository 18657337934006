<template>
  <div class="inline-block text-center">
    <hi-avatar
      :size="state.size"
      :photo="state.user && state.user.photoUrl"
      :ring="ring"
      :ring-primary="ringPrimary"
      :ring-thicker="ringThicker"
      :title="title && state.user && state.user.name"
    />
    <div class="text-xs" v-if="name && state.user">
      {{ state.user.name
      }}<span class="uppercase pl-1" v-if="state.user.surname">
        {{ state.user.surname.substr(0, 1) }}</span
      >
    </div>
  </div>
</template>

<script>
import HiAvatar from "@/hive-vue3/components/HiAvatar";
import { reactive, watchEffect } from "vue";
import { useSystemUser } from "@/hive-vue3/firebase";
export default {
  name: "SystemUserAvatar",
  props: {
    userId: String,
    small: Boolean,
    big: Boolean,
    name: Boolean,
    ring: Boolean,
    ringThicker: Boolean,
    ringPrimary: Boolean,
    title: Boolean,
  },
  components: { HiAvatar },
  setup(props) {
    const state = reactive({
      user: null,
      size: 45,
    });
    watchEffect(() => {
      state.user = useSystemUser(props.userId);
      if (props.small) {
        state.size = 32;
      } else if (props.big) {
        state.size = 80;
      }
    });
    return {
      state,
    };
  },
};
</script>

<style scoped></style>
