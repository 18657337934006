import { firestoreDoc, injectSnapshotToObject } from "./utils";
import { getCurrentScope, onMounted, onScopeDispose, reactive, ref } from "vue";
import { onSnapshot } from "firebase/firestore";

/**
 *
 * @param collectionPath
 * @param docId
 * @param options
 * @returns {data: UnwrapNestedRefs<{}>, docRef: Ref<any>, setId: setId, getId(): null}
 */
export default function (
  collectionPath,
  docId = null,
  options = { persistData: false }
) {
  const {
    // eslint-disable-next-line no-console
    errorHandler = (err) => console.error(err),
    persistData = false,
  } = options;

  const notFound = ref(false);

  let close;
  // let docId;
  function setId(id) {
    if (id === docId) return;
    docId = id;
    notFound.value = false;
    //clear all data
    Object.keys(docData).forEach((key) => delete docData[key]);
    if (id) {
      listen();
    }
  }
  const docData = reactive({});
  const docRef = ref();
  function listen() {
    stopListen();
    if (!docId || !collectionPath) return;
    const theDoc = firestoreDoc(collectionPath, docId);
    docRef.value = theDoc;
    // console.log("new id", data.id);
    close = onSnapshot(
      theDoc,
      (doc) => {
        if (!doc.exists()) {
          // console.log("not found: ", docId);
          notFound.value = true;
          for (let key in docData) {
            delete docData[key];
          }
        } else {
          injectSnapshotToObject(doc, docData);
          docData.id = docId;
          notFound.value = false;
        }
      },
      errorHandler
    );
  }
  function stopListen() {
    docRef.value = null;
    if (close) close();
  }

  if (collectionPath && docId) {
    listen();
  }
  // else {
  //   console.warn("docRef is not a firestore docRef.", docRef);
  // }

  if (!persistData && getCurrentScope()) {
    onScopeDispose(() => {
      stopListen();
    });
    onMounted(() => {
      listen();
    });
  }
  return {
    /**
     * @deprecated use docData instead.
     */
    data: docData,
    docData,
    docRef,
    setId,
    getId() {
      return docId;
    },
    notFound,
  };
}
