<template>
  <hi-modal
    v-model="open"
    color="rgba(0,0,0,0.4)"
    @click-background="clickBg"
    :content-y-motion-enter="15"
  >
    <div
      v-if="open"
      class="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden bg-light-100"
      ref="window"
      :style="style"
      :class="{
        'pointer-events-auto': open,
        'pointer-events-none': !open,
      }"
    >
      <div
        class="flex bg-light-500 border-b border-primary/50 shrink-0 items-center p-2 select-none"
        style="min-height: 1em"
        v-if="$slots.title || title"
      >
        <div v-if="$slots.title" class="hi-dialog-title-text">
          <slot name="title"></slot>
        </div>
        <div
          v-else-if="title && !$slots.title"
          class="hi-dialog-title-text"
          style="min-height: 32px"
        >
          {{ title }}
        </div>
        <div v-else class="grow"></div>
        <div class="clickable shrink-0 text-lg" v-if="closeButton">
          <hi-icon :path="mdiClose" @click="open = false" />
        </div>
      </div>

      <div class="grow overflow-auto max-h-[90vh]">
        <div class="p-10 h-full">
          <slot />
        </div>
      </div>
      <div
        v-if="$slots.footer"
        class="w-full p-4 flex items-center border-t border-primary/30 bg-gray-100"
        :class="{ 'py-2': slimFooter }"
      >
        <slot name="footer"></slot>
      </div>
      <div v-if="$slots.more" class="bg-gray-100">
        <div class="border-t border-primary/30 text-right clickable-faded p-1">
          <span @click="moreClicked"
            ><span class="text-xs uppercase">{{ moreActionsText }}</span
            ><hi-icon :path="isMoreExpended ? moreIcons.down : moreIcons.right"
          /></span>
        </div>
        <div
          :style="{ height: moreHeight + 'px' }"
          style="transition: height 0.3s ease-in-out; overflow: hidden"
        >
          <div ref="moreContainer">
            <slot name="more" />
          </div>
        </div>
      </div>
      <div
        style="min-height: 0.5em"
        v-if="!slimFooter"
        class="bg-gray-100"
      ></div>
    </div>
  </hi-modal>
</template>

<script>
import HiModal from "../HiModal";

import { templateRef } from "@vueuse/core";

import { computed, inject, watch, ref } from "vue";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { size } from "@/hive-vue3/utils/styleUtils";
import {
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
} from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import pulse from "@/hive-vue3/animations/fns/pulse";

// import CenterBox from "../layouts/CenterBox";
export default {
  name: "HiDialog",
  components: { HiIcon, HiModal },
  props: {
    closeButton: {
      type: Boolean,
      default: true,
    },
    persist: Boolean,
    modelValue: Boolean,
    title: String,
    /**
     * if width not specified, will be auto width to contain all the content
     */
    width: [String, Number],
    minWidth: [String, Number],
    /**
     * if Height not specified, will be auto width to contain all the content
     */
    height: [String, Number],
    slimFooter: Boolean,
    moreActionsText: {
      type: String,
      default: "More Actions",
    },
  },
  emits: ["update:modelValue", "close"],
  setup(props, { emit }) {
    const open = modelRef(props, emit);
    const win = templateRef("window");
    const dialogController = inject("dialog-controller", undefined);
    const moreIcons = {
      up: mdiChevronUp,
      down: mdiChevronDown,
      right: mdiChevronRight,
    };
    const moreHeight = ref(0);
    const isMoreExpended = ref(false);
    if (dialogController) {
      watch(dialogController, (v) => {
        if (v) open.value = true;
      });
    }
    function clickBg() {
      //prevent error during the closing animation.
      if (!open.value) return;
      if (props.persist) {
        pulse(win);
      } else {
        open.value = false;
      }
    }
    const style = computed(() => {
      const s = {};
      if (props.width) {
        s.width = size(props.width);
      }
      if (props.height) {
        s.height = size(props.height);
      }
      if (props.minWidth) {
        s.minWidth = props.minWidth;
      }
      return s;
    });
    const moreContainer = templateRef("moreContainer");
    function moreClicked() {
      isMoreExpended.value = !isMoreExpended.value;
      if (isMoreExpended.value) {
        const height = moreContainer.value.offsetHeight;
        // console.log("more height", height);
        moreHeight.value = height;
      } else {
        moreHeight.value = 0;
      }
    }
    watch(open, (v) => {
      // console.log("open", v);
      if (!v) {
        emit("close");
        if (dialogController) dialogController.value = false;
      }
      isMoreExpended.value = false;
      moreHeight.value = 0;
    });
    return {
      open,
      clickBg,
      style,
      mdiClose,
      moreIcons,
      isMoreExpended,
      moreClicked,
      moreHeight,
    };
  },
};
</script>

<style scoped>
.hi-dialog-title-text {
  @apply p-1 px-4 whitespace-nowrap font-bold flex items-center flex-grow;
}
</style>
