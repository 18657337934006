<template>
  <ul
    tabindex="-1"
    role="listbox"
    aria-labelledby="listbox-label"
    ref="container"
  >
    <li
      role="option"
      class="cursor-default select-none relative flex items-center max-w-full"
      v-for="(item, index) in items"
      :class="{
        ['hi-select-item']: !noPadding,
        ['hi-select-item-no-padding']: noPadding,
        '-active': isSelected(item),
        '-dense': dense,
        '-hover': overItem === index,
      }"
      :style="{ height: itemHeight }"
      :key="(item.id || item.uid || item) + index"
      @click="selectItem(index)"
      @mouseover="mouseOver(index)"
      @mouseout="mouseOut"
    >
      <div class="grow max-w-full">
        <slot v-bind="{ item, index }">{{ item }}</slot>
      </div>
      <div v-if="tick && selectable" class="shrink-0 grow-0" style="width: 1em">
        <transition-fade>
          <hi-icon :path="mdiCheck" v-if="isSelected(item)" />
        </transition-fade>
      </div>
    </li>
  </ul>
</template>

<script>
import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiCheck } from "@mdi/js";
import { ref, watchEffect } from "vue";
import shortcuts from "@/hive-vue3/utils/keyboard/shortcuts";
import { templateRef } from "@vueuse/core";

//todo: when multiple,select to move over to next
//todo: select over state

/**
 * @event change(item) - item will be whatever items passed
 * @event select(value) - value is process selected value
 */
export default {
  name: "HiList",
  components: { HiIcon, TransitionFade },
  props: {
    modelValue: null,
    /**
     * value key for modelValue in items,like 'id'.
     */
    valueKey: String,
    items: [Array, Object],
    multiple: Boolean,
    tick: Boolean,
    itemHeight: String,
    selectable: Boolean,
    dense: Boolean,
    /**
     * no default paddings
     */
    noPadding: Boolean,
    /**
     * keyboard shortcuts switch. This is more of a manual version for keyboard shortcuts.
     * Note when keyboardOnFocus is true, this value is ignored.
     */
    keyboard: Boolean,
    /**
     * use keyboard shortcuts when focused. This is a automatic version for keyboard shortcuts.
     */
    keyboardOnFocus: Boolean,
    /**
     * Can unselect last item if turned on.
     */
    allowUnselectAll: Boolean,
  },
  emits: ["change", "update:modelValue", "select"],
  setup(props, { emit }) {
    const model = modelRef(props, emit);
    const container = templateRef("container");
    const overIndex = ref();
    function isSelected(item) {
      // console.log(model.value);
      // console.log(item);
      return isValueSelected(getValue(item));
    }
    function isValueSelected(val) {
      // console.log("isSelected", props.valueKey, val, model.value);
      if (!props.multiple) {
        return model.value === val;
      }

      if (!model.value) return false;
      for (let i = 0; i < model.value.length; i++) {
        if (model.value[i] === val) return true;
      }
      return false;
    }
    function getValue(item) {
      return props.valueKey ? item[props.valueKey] : item;
    }
    //only used when it's not multiple
    let selectedIndex;
    function selectItem(index) {
      const item = props.items[index];
      selectedIndex = index;
      const val = getValue(item);
      // console.log("selectItem", val);
      if (!props.selectable) return;
      if (isValueSelected(val)) {
        if (props.multiple) {
          if (!props.allowUnselectAll && model.value.length === 1) {
            return;
          }
          arrayRemove(model.value, val);
        } else {
          if (!props.allowUnselectAll) return;
          model.value = undefined;
        }
      } else {
        if (props.multiple) {
          // console.log(model.value);
          if (!model.value) model.value = [];
          model.value.push(val);
        } else {
          model.value = val;
        }
      }
      emit("select", item);
      emit("change", model.value);
      // console.log("model", model.value);
    }
    function mouseOver(index) {
      overIndex.value = index;
    }
    function mouseOut() {
      overIndex.value = undefined;
    }
    const { keyMap, keyControls } = shortcuts({
      //undefined will be default to document by shortcuts
      element: props.keyboardOnFocus ? container : undefined,
      preventDefault: true,
    });
    watchEffect(() => {
      if (!props.keyboardOnFocus) {
        keyControls.enabled = props.keyboard;
      }
    });

    keyMap.down = () => {
      let over =
        overIndex.value === undefined ? selectedIndex : overIndex.value;
      // console.log("initialOver", over);
      over = over === undefined ? 0 : overIndex.value + 1;
      // console.log("over2", over);
      const items = props.items;
      // console.log("items.length", items.length);
      if (items.length <= 1) {
        overIndex.value = 0;
        return;
      }
      // over++;
      if (over >= items.length) over = 0;
      if (!props.multiple) {
        while (isValueSelected(getValue(items[over]))) {
          over++;
        }
      }

      // console.log(over, items.length);
      if (over >= items.length) over = 0;
      overIndex.value = over;
      // console.log("overindex", overIndex.value);
    };
    keyMap.up = () => {
      const items = props.items;
      // console.log(items.length);
      // console.log(
      //   overIndex.value,
      //   selectedIndex,
      //   overIndex.value || selectedIndex
      // );
      let over = overIndex.value || selectedIndex;
      over = over === undefined ? items.length - 1 : over - 1;
      // console.log(over);
      // console.log(items);
      if (items.length <= 1) {
        overIndex.value = 0;
        return;
      }
      // over--;
      if (over < 0) over = items.length - 1;

      if (!props.multiple) {
        while (isValueSelected(getValue(items[over]))) {
          over--;
        }
      }
      // console.log("------", over);
      if (over < 0) over = items.length - 1;
      // console.log("===========", over);
      overIndex.value = over;
    };
    keyMap.enter = () => {
      // console.log(overIndex.value);
      // console.log(props.items);
      selectItem(overIndex.value);
    };

    return {
      mdiCheck,
      selectItem,
      isSelected,
      overItem: overIndex,
      mouseOut,
      mouseOver,
    };
  },
};
</script>

<style scoped></style>
