<template>
  <hi-dialog v-model="open" width="600px">
    <div class="font-bold">Change build status for the following project:</div>
    <project-title-card :project-data="projectData" class="w-full my-4" />
    <hi-form-hive-standard
      cancel-button
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      :initial-values="projectData"
    >
      <hi-form-item label="Build Status *">
        <hi-select
          mandatory
          :items="{
            'In Construction': 'In Construction',
            Design: 'Design',
          }"
          form-key="buildStatus"
        />
      </hi-form-item>
      <hi-form-item label="Comments">
        <hi-textarea :rows="3" form-key="comments" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import { ref } from "vue";
import { updateProjectDetails } from "@/hive-vue3/modules/projects/data";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import ProjectTitleCard from "@/hive-vue3/modules/projects/ProjectTitleCard";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";

export default {
  name: "ProjectChangeBuildStatusDialog",
  components: {
    HiTextarea,
    HiSelect,
    HiFormItem,
    HiFormHiveStandard,
    ProjectTitleCard,
    HiDialog,
  },
  props: {
    projectData: Object,
  },
  setup(props) {
    const open = ref(false);
    async function submit(formData) {
      const historyData = new DefaultHistoryData();

      historyData.target = "project";
      historyData.targetId = props.projectData.id;
      historyData.action = historyActions.STATUS;
      historyData.comments = `Change Build Status: ${formData.buildStatus} - ${
        formData.comments ? formData.comments : "N/A"
      } `;

      await addNewHistory(historyData);

      await updateProjectDetails(props.projectData.id, {
        buildStatus: formData.buildStatus,
      });

      return { successful: true };
    }

    return {
      open,
      submit,
    };
  },
};
</script>

<style scoped></style>
