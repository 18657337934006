<template>
  <module-block-editable v-if="account">
    <div class="flex">
      <div class="tp-big-fancy-display grow pb-3">{{ account.name }}</div>
      <hi-dialog-trigger>
        <div
          :class="{
            disabled: !canEdit,
          }"
        >
          <system-user-avatar-group :user-ids="owners" small />
        </div>
        <hi-dialog v-if="canEdit" v-model="open">
          <accounts-basic-info-editor
            :initial-value="account"
            :account-id="account.id"
            @successful="closeDialog"
          />
        </hi-dialog>
      </hi-dialog-trigger>
    </div>
    <div class="tp-description-sm flex">
      <div class="min-w-[70px]">Class:</div>
      <div>{{ account.quality }}</div>
    </div>
    <div class="tp-description-sm flex">
      <div class="min-w-[70px]">Size:</div>
      <div>{{ account.size }}</div>
    </div>
    <div class="tp-description-sm flex">
      <div class="min-w-[70px]">Type:</div>
      <div>{{ account.type ? account.type.join(", ") : "N/A" }}</div>
    </div>
    <div class="tp-description-sm flex">
      <div class="min-w-[70px]">Amount:</div>
      <div>{{ totalAmount ? "$ " + totalAmount.toLocaleString() : "N/A" }}</div>
    </div>
    <div class="tp-description-sm flex">
      <div class="min-w-[70px]">Add by:</div>
      <div>
        <user-details-span :user-id="account.__createdBy" no-bold /> at
        <hi-date-time-display :model-value="account.__dateCreated" />
      </div>
    </div>
  </module-block-editable>
</template>

<script>
import { computed, ref } from "vue";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import AccountsBasicInfoEditor from "@/hive-vue3/modules/accounts/AccountsBasicInfoEditor";
import SystemUserAvatarGroup from "@/hive-vue3/modules/users/SystemUserAvatarGroup";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { getProjectsByAccountId } from "@/hive-vue3/modules/projects/data";
import {
  useCurrentFirebaseUserId,
  useCurrentFirebaseUserPermissions,
} from "@/hive-vue3/firebase";

export default {
  name: "AccountsTitleCard",
  components: {
    HiDialog,
    HiDialogTrigger,
    SystemUserAvatarGroup,
    AccountsBasicInfoEditor,
    HiDateTimeDisplay,
    UserDetailsSpan,
    ModuleBlockEditable,
  },
  props: {
    accountId: String,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const open = ref(false);
    const account = computed(() => {
      return getAccountById(props.accountId || accountState.currentAccountId);
    });
    const owners = computed(() => {
      if (account.value && account.value.mainOwner && account.value.subOwners) {
        return [account.value.mainOwner, ...account.value.subOwners];
      } else if (account.value && account.value.mainOwner) {
        return [account.value.mainOwner];
      }
      return [null];
    });
    function closeDialog() {
      open.value = false;
    }

    const totalAmount = computed(() => {
      const projects = getProjectsByAccountId(
        props.accountId || accountState.currentAccountId
      );
      if (projects && projects.length) {
        let returnValue = 0;
        for (const i of projects) {
          if (i.estimateAmount) {
            returnValue += i.estimateAmount;
          }
        }
        return returnValue;
      }
      return 0;
    });

    const permission = useCurrentFirebaseUserPermissions();
    const currentUserId = useCurrentFirebaseUserId();
    const canEdit = computed(() => {
      if (permission && currentUserId) {
        if (
          account.value &&
          (currentUserId === account.value.mainOwner ||
            permission.maintenance ||
            permission.admin) &&
          props.editable
        ) {
          return true;
        }
      }

      return false;
    });

    return {
      account,
      owners,
      closeDialog,
      open,
      totalAmount,
      canEdit,
    };
  },
};
</script>

<style scoped></style>
