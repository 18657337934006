<template>
  <div class="flex-center w-full h-full flex-col p-5">
    <div class="h-12 w-12 rounded-full bg-green-100 flex-center">
      <hi-icon :path="mdiCheck" class="text-green-600 text-xl" />
    </div>
    <div class="pt-6 pb-1 tp-title">
      <slot name="title" />
    </div>

    <slot />
  </div>
</template>

<script>
import { mdiCheck } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "HiViewSuccessful",
  components: { HiIcon },
  setup() {
    return { mdiCheck };
  },
};
</script>

<style scoped></style>
