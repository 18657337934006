import { onMounted, onUnmounted, ref } from "vue";
import $el from "../dom/$el";

export default function useMouseOver(element) {
  const over = ref(false);
  onMounted(() => {
    // console.log("mounted");
    element = $el(element);
    element.addEventListener("mouseenter", onOver);
    element.addEventListener("mouseleave", onOut);
  });

  // eslint-disable-next-line no-unused-vars
  function onOver(e) {
    // console.log("mouse over");
    // console.log(e.target);
    // console.log()
    over.value = true;
  }
  // eslint-disable-next-line no-unused-vars
  function onOut(e) {
    // console.log("mouse out");
    // console.log(e.target);
    over.value = false;
  }

  onUnmounted(() => {
    element.removeEventListener("mouseenter", onOver);
    element.removeEventListener("mouseleave", onOut);
  });
  return over;
}
