import gsap from "gsap";
import { createComponent } from "@/hive-vue3/utils/componentUtils";
import HiDialog from "./dialogs/HiDialog";
import { mergeToSky, mountToSky } from "@/hive-vue3/utils/sky";
import HiAlert from "./dialogs/HiAlert";
import { h } from "vue";
import HiConfirm from "./dialogs/HiConfirm";
import HiBanner from "./HiBanner";

/**
 * function not tested yet
 * @param props
 * @param slotComponent
 * @param slotProps
 * @param slotChildren
 */
export function openDialog(props, slotComponent, slotProps, slotChildren) {
  const dialog = createComponent(HiDialog, props, () => {
    return h(slotComponent, slotProps, slotChildren);
  });
  // console.log(dialog);
  mountToSky(dialog);
}

function alert(msg) {
  return new Promise((resolve) => {
    const dialog = createComponent(
      HiAlert,
      {
        onOk() {
          resolve(true);
        },
        onModalOut() {
          // console.log("modal out");
          dialog.unmount();
          resolve(true);
        },
      },
      msg
    );
    mountToSky(dialog);
    // console.log(mountToSky(dialog));
  });
}
function confirm(msg, okText = "OK", cancelText = "Cancel") {
  return new Promise((resolve) => {
    const dialog = createComponent(
      HiConfirm,
      {
        onOk() {
          resolve(true);
          // console.log(skyObject);
        },
        onCancel() {
          resolve(false);
        },
        onModalOut() {
          // console.log("modal out");
          resolve(false);
          skyObject.destroy();
        },
        okText,
        cancelText,
      },
      msg
    );
    const skyObject = mountToSky(dialog);
    // console.log(skyObject);
    // console.log(mountToSky(dialog));
  });
}
function toast(
  msg,
  persist = false,
  {
    location = "bottom",
    offset = 25,
    lasts = 5, //1s
    warning = false,
    danger = false,
    successful = false,
  } = {}
) {
  const win = createComponent(
    HiBanner,
    {
      closable: persist,
      onClose: close,
      dark: !(warning || danger || successful),
      warning,
      danger,
      successful,
    },
    msg
  );
  const skyObject = mergeToSky(win, location, offset);
  function close() {
    //gc
    skyObject.destroyLater(1000);
  }
  if (!persist) {
    gsap.to(skyObject.element, {
      opacity: 0,
      delay: lasts,
      onComplete() {
        //gc
        skyObject.destroy();
      },
    });
  }
  // console.log(skyObject);
}
export default {
  alert,
  confirm,
  toast,
  toastError(msg, location = "bottom") {
    toast(msg, true, { location, danger: true });
  },
  toastSuccessful(msg, location = "bottom") {
    toast(msg, false, { location, successful: true });
  },
  toastWarning(msg, location = "bottom") {
    toast(msg, true, { location, warning: true });
  },
};
