<template>
  <slot />
  <div v-if="isDebugging" class="flex gap-2">
    <div class="debug-label">formKey: {{ formKey }}</div>

    <div class="debug-info">{{ modelValue }} | {{ value }}</div>
  </div>
</template>

<script>
import { watchModelImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import formControl from "@/hive-vue3/components/form/logics/formControl";

export default {
  name: "HiFormValueHolder",
  props: {
    formKey: String,
    modelValue: null,
  },
  setup(props, context) {
    const control = formControl(props, context);
    watchModelImmediately(props, (v) => {
      if (v) {
        control.model.value = v;
      }
    });
    return {
      isDebugging: control.isDebugging,
      value: control.model,
    };
  },
};
</script>

<style scoped></style>
