<template>
  <div v-if="!googleAuthed">
    <gmail-auth-status-bar />
  </div>
  <div class="flex gap-2" v-else>
    <hi-card class="h-full min-w-[422px]">
      <hi-div flex-col-full-height-to-support-grow>
        <div class="bg-light-400 p-4 flex items-center">
          <div class="tp-title-faded">Gmail</div>
        </div>
        <gmail-threads-list class="grow" />
      </hi-div>
    </hi-card>
    <hi-card class="h-full grow p-8">
      <gmail-thread-mails-viewer :thread-id="mailState.selectedThreadId" />
      <!--      <gmail-thread-viewer />-->
    </hi-card>
  </div>
</template>

<script>
import hiCard from "@/hive-vue3/components/HiCard";
import GmailThreadsList from "@/hive-vue3/modules/gmail/GmailThreadsList";
// import GmailThreadViewer from "@/hive-vue3/modules/gmail/ss/GmailThreadViewer";
import GmailAuthStatusBar from "@/hive-vue3/modules/gmail/GmailAuthStatusBar";
import { useGoogleAuthed } from "@/hive-vue3/modules/google/googleAuth";
import HiDiv from "@/hive-vue3/components/HiDiv";
import GmailThreadMailsViewer from "@/hive-vue3/modules/gmail/components/GmailThreadMailsViewer";
import { mailState } from "@/hive-vue3/modules/gmail/state";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";

export default {
  name: "SystemEmailFullKit",
  components: {
    GmailThreadMailsViewer,
    HiDiv,
    GmailAuthStatusBar,
    // GmailThreadViewer,
    GmailThreadsList,
    hiCard,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    setRouterId(props, mailState, "selectedThreadId", "/email/");
    const googleAuthed = useGoogleAuthed();
    return { googleAuthed, mailState };
  },
};
</script>

<style scoped></style>
