<template>
  <hi-card class="h-[40px]" style="color: #888">
    <div class="flex justify-around h-full items-center px-6">
      <div class="flex gap-4">
        <hi-dialog-trigger>
          <hi-button text>
            <hi-icon :path="mdiPhonePlus" />
          </hi-button>
          <activity-new-phone-call-dialog
            :target="target"
            :targetId="targetId"
            :pipeId="pipeId"
            :initialValues="initialValues"
            :main-owner="mainOwner"
          />
        </hi-dialog-trigger>
        <hi-dialog-trigger>
          <hi-button text>
            <hi-icon :path="mdiCalendarMonth" />
          </hi-button>
          <activity-new-event-dialog
            :target="target"
            :targetId="targetId"
            :pipeId="pipeId"
            :initialValues="initialValues"
            :main-owner="mainOwner"
          />
        </hi-dialog-trigger>

        <hi-dialog-trigger>
          <hi-button text>
            <hi-icon :path="mdiLaptop" />
          </hi-button>
          <activity-new-online-meeting-dialog
            :target="target"
            :targetId="targetId"
            :pipeId="pipeId"
            :initialValues="initialValues"
            :main-owner="mainOwner"
          />
        </hi-dialog-trigger>
        <hi-dialog-trigger>
          <hi-button text>
            <hi-icon :path="mdiAccountGroup" />
          </hi-button>
          <activity-new-meeting-dialog
            :target="target"
            :targetId="targetId"
            :pipeId="pipeId"
            :initialValues="initialValues"
            :main-owner="mainOwner"
          />
        </hi-dialog-trigger>
        <hi-dialog-trigger>
          <hi-button text>
            <hi-icon
              :path="mdiUploadOutline"
              :target="target"
              :targetId="targetId"
              :pipeId="pipeId"
              :initialValues="initialValues"
            />
          </hi-button>
          <activity-new-update-dialog
            :target="target"
            :targetId="targetId"
            :pipeId="pipeId"
            :initialValues="initialValues"
            :main-owner="mainOwner"
          />
        </hi-dialog-trigger>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";

import {
  mdiPhonePlus,
  mdiCalendarMonth,
  mdiAccountGroup,
  mdiLaptop,
  mdiUploadOutline,
} from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import HiButton from "@/hive-vue3/components/HiButton";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ActivityNewPhoneCallDialog from "@/hive-vue3/modules/activities/components/ActivityNewPhoneCallDialog";
import ActivityNewEventDialog from "@/hive-vue3/modules/activities/components/ActivityNewEventDialog";
import ActivityNewOnlineMeetingDialog from "@/hive-vue3/modules/activities/components/ActivityNewOnlineMeetingDialog";
import ActivityNewMeetingDialog from "@/hive-vue3/modules/activities/components/ActivityNewMeetingDialog";
import ActivityNewUpdateDialog from "@/hive-vue3/modules/activities/components/ActivityNewUpdateDialog";

export default {
  name: "ActivityToolsCard",
  components: {
    ActivityNewUpdateDialog,
    ActivityNewMeetingDialog,
    ActivityNewOnlineMeetingDialog,
    ActivityNewEventDialog,
    ActivityNewPhoneCallDialog,
    HiDialogTrigger,
    HiButton,
    HiIcon,
    HiCard,
  },
  props: {
    target: String,
    targetId: String,
    pipeId: String,
    initialValues: Object,
    mainOwner: String,
  },
  setup() {
    return {
      mdiPhonePlus,
      mdiCalendarMonth,
      mdiAccountGroup,
      mdiLaptop,
      mdiUploadOutline,
    };
  },
};
</script>

<style scoped></style>
