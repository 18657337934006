<template>
  <hi-autocomplete
    :items="entitiesInput"
    :search-keys="['name', 'abn', 'tradingName']"
    placeholder="Input entity name or ABN..."
    item-value-key="id"
    :format-display-fn="formatDisplay"
    v-model="model"
    :excludes="excludes"
    :add-new-dialog-component="NewContactEntityDialog"
    @change="(v) => $emit('change', v)"
    ref="input"
    :form-key="formKey"
    :auto-focus="autoFocus"
    :no-form="noForm"
    :disabled="disabled"
  >
    <template v-slot="{ item }">
      <contacts-entity-summary :entity-data="item" />
    </template>
  </hi-autocomplete>
</template>

<script>
import HiAutocomplete from "@/hive-vue3/components/form/controls/HiAutocomplete";
import { useContactEntities } from "@/hive-vue3/modules/contacts/data";
import formatters from "@/hive-vue3/utils/formatters";
import NewContactEntityDialog from "@/hive-vue3/modules/contacts/ContactsNewEntityDialog";
import ContactsEntitySummary from "@/hive-vue3/modules/contacts/ContactsEntitySummary";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
// import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { templateRef } from "@vueuse/core/index";
import formControl from "@/hive-vue3/components/form/logics/formControl";
export default {
  name: "MfcInputContactsEntity",
  components: { ContactsEntitySummary, HiAutocomplete },
  props: {
    formKey: String,
    modelValue: String,
    excludes: Array,
    ...commonFormInputProps,
    disabled: Boolean,
  },

  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;
    const entitiesInput = useContactEntities();
    function formatDisplay(item) {
      return `${item.tradingName} -
${item.name}  <span class='pl-1 tp-description-sm'>[A.B.N. ${formatters.abn(
        item.abn
      )}]</span>`;
    }
    const input = templateRef("input");
    function focus() {
      input.value && input.value.focus();
    }
    return {
      focus,
      model,
      entitiesInput,
      formatDisplay,
      NewContactEntityDialog,
    };
  },
};
</script>

<style scoped></style>
