<template>
  <div v-if="mailLists.mails && mailLists.mails.length">
    <div
      class="flex flex-row-reverse"
      v-if="threadDetails.target && threadDetails.targetId"
    >
      <hi-icon
        class="clickable-icon mx-6"
        :path="mdiOpenInNew"
        title="Link Account"
        @click="jumpToDetails(threadDetails.target, threadDetails.targetId)"
      />
    </div>

    <div class="text-2xl font-bold p-4">
      {{ mailLists.mails[0].headers.subject }}
    </div>
    <ul class="w-full">
      <li
        v-for="(item, index) in mailLists.mails.length - 1"
        :key="index"
        class="cursor-pointer"
      >
        <gmail-mail-viewer
          v-if="mailLists.mails[index].show"
          :email-data="mailLists.mails[index]"
          @click="mailLists.mails[index].show = !!!mailLists.mails[index].show"
          class="border-b-2"
        />
        <div
          class="hi-select-item-no-padding select-none relative items-center max-w-full p-4"
          @click="mailLists.mails[index].show = !!!mailLists.mails[index].show"
          v-else
        >
          <gmail-mail-summary :email-data="mailLists.mails[index]" />
        </div>
      </li>
    </ul>

    <gmail-mail-viewer
      :email-data="mailLists.mails[mailLists.mails.length - 1]"
    />
  </div>
</template>

<script>
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { reactive } from "vue";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { orderBy, where } from "firebase/firestore";
import GmailMailViewer from "@/hive-vue3/modules/gmail/GmailMailViewer";
import GmailMailSummary from "@/hive-vue3/modules/gmail/GmailMailSummary";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiOpenInNew } from "@mdi/js";
import { useRouter } from "vue-router";
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";

export default {
  name: "GmailThreadMailsViewer",
  components: { HiIcon, GmailMailSummary, GmailMailViewer },
  props: {
    threadId: String,
  },
  setup(props) {
    const mailLists = reactive({
      mails: undefined,
    });

    watchPropsImmediately(props, "threadId", (v) => {
      if (v) {
        // console.log(v);
        mailLists.mails = useFirestoreCollectionAsRef(
          "/system/syncedEmail/allMail",
          {
            query: [
              where("threadId", "==", props.threadId),
              orderBy("internalDate"),
            ],
          }
        );
      }
    });

    const threadDetails = useFirestoreDocReactiveId(
      "/system/syncedEmail/allThreads",
      props,
      "threadId"
    );

    const router = useRouter();

    function jumpToDetails(target, targetId) {
      switch (target) {
        case "accounts":
          router.push("/accounts/" + targetId);
          break;
        case "enquiry":
          router.push("/enquiries/" + targetId);
          break;
        case "leads":
          router.push("/leads/" + targetId);
          break;
        case "project":
          router.push("/projects/" + targetId);
          break;
      }

      console.log(target, targetId);
    }

    return {
      mailLists,
      mdiOpenInNew,
      jumpToDetails,
      threadDetails,
    };
  },
};
</script>

<style scoped></style>
