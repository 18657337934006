<template>
  <hi-card>
    <div class="h-full flex flex-col">
      <div class="bg-light-400 view-block flex items-center">
        <div class="tp-small-title-faded">{{ title }}</div>
        <div class="grow"></div>
        <div><slot name="tools" /></div>
      </div>
      <div class="overflow-auto grow">
        <slot />
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
export default {
  name: "ModuleTitleCard",
  components: { HiCard },
  props: {
    title: String,
  },
};
</script>

<style>
@import "../moduleStyles.css";
</style>
