<template>
  <hi-autocomplete
    :items="accounts"
    :search-keys="['name', 'email']"
    placeholder="Input Account name or email..."
    item-value-key="id"
    :format-display-fn="formatDisplay"
    :add-new-dialog-component="addNewAccountDialog"
    @change="(v) => $emit('change', v)"
    v-model="model"
    :excludes="excludes"
    ref="input"
    :form-key="formKey"
    :auto-focus="autoFocus"
    :no-form="noForm"
    :disabled="disabled"
  />
</template>

<script>
import HiAutocomplete from "@/hive-vue3/components/form/controls/HiAutocomplete";
import { templateRef } from "@vueuse/core";
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import NewContactsPeopleDialog from "@/hive-vue3/modules/contacts/ContactsNewPeopleDialog";
import addNewAccountDialog from "@/hive-vue3/modules/accounts/AddNewAccountDialog";
import { computed } from "vue";
import { getAllAccounts } from "@/hive-vue3/modules/accounts/data";
import formControl from "@/hive-vue3/components/form/logics/formControl";

export default {
  name: "MfcInputAccount",
  components: { HiAutocomplete },
  props: {
    modelValue: String,
    excludes: Array,
    ...commonFormInputProps,
    disabled: Boolean,
  },
  emits: ["change", "update:modelValue"],
  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;
    const accounts = computed(() => {
      return getAllAccounts();
    });

    function formatDisplay(item) {
      return `${item.name} | ${item.type} | ${item.email}`;
    }

    const input = templateRef("input");

    function focus() {
      input.value && input.value.focus();
    }

    return {
      focus,
      accounts,
      formatDisplay,
      NewContactsPeopleDialog,
      model,
      addNewAccountDialog,
    };
  },
};
</script>

<style scoped></style>
