<template>
  <div class="p-4">
    <!--    <dialog-add-product-button :pipe-id="pipeId" />-->

    <hi-dialog-trigger>
      <hi-button> Add New Product</hi-button>
      <project-add-product-dialog />
    </hi-dialog-trigger>
    <div>Active List</div>
    <hi-list :items="products.active">
      <template v-slot="{ item }">
        <product-info-view :product="item" />
        <dialog-confirm-obsolete :product="item" />
      </template>
    </hi-list>

    <div>Obsolete List</div>
    <hi-list :items="products.obsolete">
      <template v-slot="{ item }">
        <product-info-view :product="item" class="bg-gray-200" />
        <dialog-confirm-obsolete :product="item" is-active />
      </template>
    </hi-list>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import HiList from "@/hive-vue3/components/HiList";
import DialogConfirmObsolete from "@/hive-vue3/modules/products/ProductComfirmObsoleteDialogButton";
import ProductInfoView from "@/hive-vue3/modules/products/productsInfoView";
import HiButton from "@/hive-vue3/components/HiButton";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ProjectAddProductDialog from "@/hive-vue3/modules/projects/ProjectAddProductDialog";
import { getProjectProductByPipeId } from "@/hive-vue3/modules/projects/data";
export default {
  name: "ProjectProductView",
  components: {
    ProjectAddProductDialog,
    HiDialogTrigger,
    HiButton,
    ProductInfoView,
    DialogConfirmObsolete,
    // UserDetailsSpan,
    // FirebaseImage,
    HiList,
    // HiDateTimeDisplay,
    // DialogAddProductButton,
  },
  props: {
    pipeId: String,
  },
  setup(props) {
    const products = reactive({ active: undefined, obsolete: undefined });

    watchPropsImmediately(props, "pipeId", (id) => {
      if (id) {
        products.active = computed(() => {
          return getProjectProductByPipeId(id, true);
        });

        products.obsolete = computed(() => {
          return getProjectProductByPipeId(id, false);
        });
      }
    });

    return {
      products,
    };
  },
};
</script>

<style scoped></style>
