<template>
  <div class="flex justify-around" v-if="leadDetails">
    <hi-dialog-trigger>
      <hi-button
        :disabled="leadDetails.status === 'closed' || !leadDetails.mainOwner"
        text
        >Convert Lead To Project</hi-button
      >
      <leads-convert-to-project-dialog />
    </hi-dialog-trigger>
    <hi-dialog-trigger v-if="leadDetails && leadDetails.status !== 'closed'">
      <hi-button text>Close Lead</hi-button>
      <leads-close-lead-dialog />
    </hi-dialog-trigger>
    <hi-dialog-trigger v-else>
      <hi-button text @click="reopen" :disabled="leadDetails.isConverted"
        >Reopen Lead</hi-button
      >
    </hi-dialog-trigger>
  </div>
</template>

<script>
import HiButton from "@/hive-vue3/components/HiButton";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import LeadsConvertToProjectDialog from "@/hive-vue3/modules/leads/LeadsConvertToProjectDialog";
import LeadsCloseLeadDialog from "@/hive-vue3/modules/leads/LeadsCloseLeadDialog";
import { computed } from "vue";
import { getLeadById, reOpenLead } from "@/hive-vue3/modules/leads/data";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
export default {
  name: "LeadsToolsCard",
  components: {
    LeadsCloseLeadDialog,
    LeadsConvertToProjectDialog,
    HiDialogTrigger,
    HiButton,
  },
  setup() {
    async function reopen() {
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.LINK;
      historyObj.target = "leads";
      historyObj.targetId = leadsState.currentLeadId;
      historyObj.item = "Lead";
      historyObj.value = {
        status: "open",
      };
      historyObj.oldValue = {
        status: "open",
      };
      await addNewHistory(historyObj);
      await reOpenLead(leadsState.currentLeadId);
    }

    return {
      reopen,
      leadDetails: computed(() => {
        return getLeadById(leadsState.currentLeadId);
      }),
    };
  },
};
</script>

<style scoped></style>
