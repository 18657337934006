<template>
  <div class="w-full flex flex-col flex-grow p-4" v-if="emailData">
    <div class="flex justify-between">
      <div class="w-2/3">
        <div class="font-bold">
          <span>{{ emailData.headers.from }}</span>
        </div>

        <div>
          <span class="">to: </span>
          <span>{{ emailData.headers.to }}</span>
        </div>
      </div>
      <div class="tp-description-sm text-right flex flex-col">
        Last refresh:
        <hi-date-time-display :model-value="emailData.saveTime" friendly />
      </div>
    </div>

    <div
      class="mt-4"
      v-html="emailData.textHtml"
      v-if="emailData.textHtml"
    ></div>

    <!--    <hi-i-frame-->
    <!--      v-if="emailData.textHtml"-->
    <!--      :html="emailData.textHtml"-->
    <!--      class="w-full grow"-->
    <!--    />-->

    <pre v-else>{{ emailData.textPlain }}</pre>

    <!--    <div>{{ emailData.attachments }}</div>-->
    <div
      class="mt-4 pt-2 border-t-2 flex flex-col gap-2"
      v-if="emailData.attachments && emailData.attachments.length"
    >
      <div class="font-bold">
        {{ attachments.length }}
        {{ attachments.length === 1 ? "Attachement" : "Attachments" }}
      </div>
      <div class="flex gap-4">
        <hi-vault-file
          v-for="(item, index) in attachments"
          :key="item + index"
          class="w-1/5"
          :file-id="item"
        />
      </div>
    </div>

    <!--    <reply-gmail-dialog-->
    <!--      :originalEmail="emailData"-->
    <!--      :closeButton="true"-->
    <!--      buttonText="Reply"-->
    <!--    />-->
  </div>
</template>

<script>
import hiIFrame from "@/hive-vue3/components/HiIFrame";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import HiVaultFile from "@/hive-vue3/components/firebase/HiVaultFile";
import { computed } from "vue";
// import replyGmailDialog from "@/hive-vue3/modules/gmail/GmailReplyDialog";
export default {
  name: "GmailMailViewer",
  components: {
    HiVaultFile,
    HiDateTimeDisplay,
    // eslint-disable-next-line vue/no-unused-components
    hiIFrame,
    // replyGmailDialog,
  },

  props: {
    emailData: Object,
  },
  setup(props) {
    const attachments = computed(() => {
      const returnArr = [];

      if (props.emailData.attachments) {
        for (const j of props.emailData.attachments) {
          if (returnArr.indexOf(j.fileId) === -1) {
            returnArr.push(j.fileId);
          }
        }
      }

      return returnArr;
    });

    return {
      attachments,
    };
  },
};
</script>

<style scoped></style>
