<template>
  <div class="overflow-hidden" style="min-height: inherit">
    <input-base
      :placeholder="placeholder"
      large
      @input="onInput"
      class="shadow border-b border-gray-300"
      auto-focus
    />
    <transition-fade>
      <hi-view-no-result
        v-if="search && search.length && (!result || !result.length)"
      />
      <hi-list v-else :items="result" :item-height="itemHeight">
        <template v-slot="{ item }">
          <slot :item="item">{{ item }}</slot>
        </template>
      </hi-list>
    </transition-fade>
  </div>
</template>

<script>
import InputBase from "@/hive-vue3/components/form/controls/base/InputBase";
import HiList from "@/hive-vue3/components/HiList";
import { lazyFunction } from "@/hive-vue3/utils/functionUtils";
import { ref } from "vue";
import HiViewNoResult from "@/hive-vue3/views/HiViewNoResult";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
export default {
  name: "HiSearchPanel",
  components: { TransitionFade, HiViewNoResult, HiList, InputBase },
  props: {
    placeholder: String,
    /**
     * a function returns an array
     */
    searchFn: {
      type: Function,
      required: true,
    },
    itemHeight: String,
  },
  setup(props) {
    const result = ref();
    const search = ref();
    const onInput = lazyFunction(async (v) => {
      // console.log(v);
      search.value = v;
      const re = await props.searchFn(v);
      // console.log(re);
      result.value = re.data || re.value || re;
    }, 200);
    return {
      onInput,
      result,
      search,
    };
  },
};
</script>

<style scoped></style>
