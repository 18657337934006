import { deleteField } from "firebase/firestore";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";

const cache = {
  enquiries: null,
};

function initEnquiries() {
  if (!cache.enquiries) {
    cache.enquiries = useFirestoreCollectionAsRefGlobally("enquiries");
  }
}

export function getOpeningEnquiries() {
  initEnquiries();
  const returnArr = [];
  if (cache.enquiries && cache.enquiries.value) {
    for (const i of cache.enquiries.value) {
      if (i.status === "open") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getClosedEnquires() {
  initEnquiries();
  const returnArr = [];
  if (cache.enquiries && cache.enquiries.value) {
    for (const i of cache.enquiries.value) {
      if (i.status === "close") {
        returnArr.push(i);
      }
    }
  }
  return returnArr;
}

export function getEnquiresByPeopleId(peopleId) {
  initEnquiries();
  const returnArr = [];
  if (cache.enquiries && cache.enquiries.value) {
    for (const i of cache.enquiries.value) {
      if (i.peopleId === peopleId) {
        returnArr.push(i);
      }
    }
  }
  console.log(returnArr);
  return returnArr;
}

export function getEnquiryById(enquiryId) {
  initEnquiries();
  const enquiries = cache.enquiries && cache.enquiries.value;
  if (!enquiries || !enquiries.length) return null;
  for (let i = 0; i < enquiries.length; i++) {
    const enquiry = enquiries[i];
    if (enquiry.id === enquiryId) return enquiry;
  }
}

export function getEnquiryNameById(enquiryId) {
  initEnquiries();
  const enquiries = cache.enquiries && cache.enquiries.value;
  if (!enquiries || !enquiries.length) return null;
  for (let i = 0; i < enquiries.length; i++) {
    const enquiry = enquiries[i];
    if (enquiry.id === enquiryId) return enquiry.name;
  }
}

export function getEnquiryPipeIdById(enquiryId) {
  initEnquiries();
  const enquiries = cache.enquiries && cache.enquiries.value;
  if (!enquiries || !enquiries.length) return null;
  for (let i = 0; i < enquiries.length; i++) {
    const enquiry = enquiries[i];
    if (enquiry.id === enquiryId) return enquiry.pipeId;
  }
}

/**
 * Firebase Operating Functions
 */

export async function addContactToEnquiry(
  enquiryId,
  peopleId,
  entityId = undefined
) {
  // console.log(enquiryId, contactId);
  if (entityId) {
    await updateFirestoreDoc(firestoreDoc("enquiries", enquiryId), {
      peopleId,
      entityId,
    });
  } else {
    await updateFirestoreDoc(firestoreDoc("enquiries", enquiryId), {
      peopleId,
    });
  }
}

export async function removeContactFromEnquiry(enquiryId) {
  await updateFirestoreDoc(firestoreDoc("enquiries", enquiryId), {
    peopleId: deleteField(),
    entityId: deleteField(),
  });
}

export function closeEnquiry(enquiryId, data = undefined) {
  updateFirestoreDoc(firestoreDoc("enquiries", enquiryId), {
    status: "close",
    ...data,
  })
    .then()
    .catch((e) => {
      console.log("写入时出错", e);
    });
}

export async function openEnquiry(enquiryId) {
  const historyObj = new DefaultHistoryData();
  historyObj.action = historyActions.REOPEN;
  historyObj.target = "enquiry";
  historyObj.targetId = enquiryState.currentEnquiryId;
  historyObj.comments = `Enquiry Reopen`;

  await addNewHistory(historyObj);

  updateFirestoreDoc(firestoreDoc("enquiries", enquiryId), {
    status: "open",
  })
    .then()
    .catch((e) => {
      console.log("写入时出错", e);
    });
}

export function enquiryListSearchFn(search, enquiry) {
  return (
    enquiry.name.toLowerCase().indexOf(search) >= 0 ||
    enquiry.phoneNum.indexOf(search) >= 0 ||
    enquiry.phoneNum.indexOf(search.slice(1)) >= 0 ||
    enquiry.email.toLowerCase().indexOf(search) >= 0
  );
}

export function jumpToEnquiry(router, enquiryId) {
  router.push("/enquiries/" + enquiryId);
}
