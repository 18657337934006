<template>
  <layout-three-column
    middle-bigger
    full-card-first-col
    module-name="Invoices"
    :page-name="name"
    @backToSummary="invoiceState.currentInvoiceId = undefined"
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Invoices</div>
      <div class="grow"></div>
      <hi-dialog-trigger>
        <hi-button>Add New Invoice </hi-button>
        <hi-dialog v-model="open">
          <invoice-editor-form
            @cancel="
              open = false;
              invoiceState.isUnSaved = false;
            "
            @successful="
              open = false;
              invoiceState.isUnSaved = false;
            "
          />
        </hi-dialog>
      </hi-dialog-trigger>
    </template>
    <template v-slot:col1>
      <invoice-list class="h-full" />
    </template>
    <template v-slot:col2 v-if="invoiceState.currentInvoiceId">
      <invoice-function-card />

      <invoice-preview
        v-if="invoiceState.currentMiddleColView === 'preview'"
        class="grow h-0 overflow-auto"
        :invoice-id="invoiceState.currentInvoiceId"
      />
      <hi-card v-else class="grow h-0">
        <invoice-editor-form
          @successful="
            invoiceState.currentMiddleColView = 'preview';
            invoiceState.isUnSaved = false;
          "
          @cancel="
            invoiceState.currentMiddleColView = 'preview';
            invoiceState.isUnSaved = false;
          "
          :initial-values="invoiceData"
        />
      </hi-card>
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiButton from "@/hive-vue3/components/HiButton";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import InvoiceEditorForm from "@/hive-vue3/modules/invoice/InvoiceEditorForm";
import { computed, ref, watch } from "vue";
import InvoiceList from "@/hive-vue3/modules/invoice/InvoiceList";
import { invoiceState } from "@/hive-vue3/modules/invoice/state";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
import InvoicePreview from "@/hive-vue3/modules/invoice/InvoicePreview";
import InvoiceFunctionCard from "@/hive-vue3/modules/invoice/InvoiceFunctionCard";
import { getInvoiceById } from "@/hive-vue3/modules/invoice/data";
import HiCard from "@/hive-vue3/components/HiCard";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { useRouter } from "vue-router";
export default {
  name: "InvoiceViewFullKit",
  components: {
    HiCard,
    InvoiceFunctionCard,
    InvoicePreview,
    InvoiceList,
    InvoiceEditorForm,
    HiDialog,
    HiButton,
    HiDialogTrigger,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    const open = ref(false);
    const router = useRouter();
    setRouterId(props, invoiceState, "currentInvoiceId", "/invoice/");
    const invoiceData = computed(() => {
      return getInvoiceById(invoiceState.currentInvoiceId);
    });
    watch(
      () => invoiceState.currentInvoiceId,
      (v) => {
        if (v) {
          router.push("/invoice/" + v);
        } else {
          router.push("/invoice");
        }
      }
    );

    watchPropsImmediately(open, "value", (v) => {
      if (!v) {
        invoiceState.isUnSaved = false;
      }
    });

    return {
      open,
      invoiceState,
      invoiceData,
      name: computed(() => {
        if (
          invoiceData.value &&
          invoiceData.value &&
          invoiceData.value.invoiceNum
        ) {
          return (
            "INV-" + invoiceData.value.invoiceNum.toString().padStart(4, "0")
          );
        }
        return undefined;
      }),
    };
  },
};
</script>

<style scoped></style>
