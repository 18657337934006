<template>
  <hi-card v-if="projectDetails">
    <module-block-editable class="view-block-spacious">
      <div class="flex">
        <div class="grow">
          <div class="tp-big-fancy-display mb-2">
            {{ projectDetails.name
            }}{{ projectDetails.isNameOfficial ? " - Official" : "" }}
          </div>
          <div class="capitalize">
            {{ decamelize(projectDetails.projType) }}
          </div>
          <hi-address-display :model-value="projectDetails.address" />
          <div class="tp-description-sm flex">
            <div class="min-w-[70px]">Created by:</div>
            <div>
              <user-details-span
                :user-id="projectDetails.__createdBy"
                no-bold
              />
              at
              <hi-date-time-display
                :model-value="projectDetails.__dateCreated"
              />
            </div>
          </div>
        </div>
        <div class="font-bold text-2xl pr-2 pt-1">
          {{
            projectDetails.estimateAmount
              ? "$" + projectDetails.estimateAmount.toLocaleString()
              : ""
          }}
        </div>

        <div>
          <hi-dialog-trigger>
            <div
              class="hi-trigger-btn"
              :class="{
                disabled: !(
                  currentUserId === projectDetails.mainOwner ||
                  permission.maintenance ||
                  permission.admin
                ),
              }"
            >
              <system-user-avatar-group :user-ids="assignedList" small />
            </div>
            <hi-dialog v-if="editable" v-model="open">
              <project-basic-info-editor
                @successful="open = false"
                @cancel="open = false"
                :projectData="projectFormValue"
              />
            </hi-dialog>
          </hi-dialog-trigger>
        </div>
      </div>
    </module-block-editable>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import { computed, ref } from "vue";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import HiAddressDisplay from "@/hive-vue3/components/misc/HiAddressDisplay";
import ProjectBasicInfoEditor from "@/hive-vue3/modules/projects/ProjectBasicInfoEditor";
import { decamelize } from "@/hive-vue3/utils/stringUtils";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import SystemUserAvatarGroup from "@/hive-vue3/modules/users/SystemUserAvatarGroup";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  useCurrentFirebaseUserId,
  useCurrentFirebaseUserPermissions,
} from "@/hive-vue3/firebase";
export default {
  name: "ProjectTitleCard",
  components: {
    HiDateTimeDisplay,
    UserDetailsSpan,
    HiDialog,
    SystemUserAvatarGroup,
    HiDialogTrigger,
    ProjectBasicInfoEditor,
    HiAddressDisplay,
    ModuleBlockEditable,
    HiCard,
  },
  props: {
    editable: Boolean,
    projectData: Object,
  },
  setup(props) {
    const open = ref(false);

    const projectDetails = computed(() => {
      if (props.projectData) {
        return props.projectData;
      }
      return getProjectById(projectsState.currentProjectId);
    });

    const assignedList = computed(() => {
      if (projectDetails.value && projectDetails.value.mainOwner) {
        if (projectDetails.value.subOwners) {
          return [
            projectDetails.value.mainOwner,
            ...projectDetails.value.subOwners,
          ];
        }
        return [projectDetails.value.mainOwner];
      }
      return [null];
    });

    const permission = useCurrentFirebaseUserPermissions();
    const currentUserId = useCurrentFirebaseUserId();

    return {
      projectDetails,
      decamelize,
      projectFormValue: computed(() => {
        const returnValue = {};
        Object.assign(returnValue, projectDetails.value);
        if (returnValue) {
          returnValue.isNameOfficial = returnValue.isNameOfficial ? "Y" : "N";
          delete returnValue.projStatus;
        }
        return returnValue;
      }),
      assignedList,
      open,
      permission,
      currentUserId,
    };
  },
};
</script>

<style scoped></style>
