export function size(numberOrString, unit = "px") {
  if (
    numberOrString === null ||
    numberOrString === "" ||
    numberOrString === undefined
  ) {
    return "";
  }
  if (typeof numberOrString === "string") return numberOrString;
  return numberOrString + unit;
}
