<template>
  <div>
    <hi-list v-if="leads.length" :items="leads">
      <template v-slot="{ item }">
        <div class="flex items-center">
          <leads-list-summary class="grow" :lead-id="item.id" />
          <hi-icon
            class="clickable-icon"
            :path="mdiOpenInNew"
            title="Link Account"
            @click="jumpToLeads(item.id)"
          />
        </div>
      </template>
    </hi-list>
    <hi-view-no-data v-else />
  </div>
</template>

<script>
import { accountState } from "@/hive-vue3/modules/accounts/state";
import { computed } from "vue";
import {
  getLeadsByAccount,
  jumpToLeadsById,
} from "@/hive-vue3/modules/leads/data";
import HiList from "@/hive-vue3/components/HiList";
import LeadsListSummary from "@/hive-vue3/modules/leads/LeadsListSummary";
import { mdiOpenInNew } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { useRouter } from "vue-router";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";

export default {
  name: "AccountsLeadsList",
  components: { HiViewNoData, HiIcon, LeadsListSummary, HiList },
  setup() {
    const leads = computed(() => {
      return getLeadsByAccount(accountState.currentAccountId);
    });
    const router = useRouter();

    function jumpToLeads(leadId) {
      jumpToLeadsById(router, leadId);
    }

    return {
      leads,
      mdiOpenInNew,
      jumpToLeads,
    };
  },
};
</script>

<style scoped></style>
