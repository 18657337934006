<template>
  <div class="--HiInput">
    <input-base
      ref="input"
      class="hi-input"
      v-model="computedDisplay"
      :valid="valid"
      :error="error"
      :disabled="disabled"
      :placeholder="placeholder"
      :autoFocus="autoFocus"
      :prefixIcon="computedPrefixIcon"
      :prefix-text="prefixText"
      :prefixTip="prefixTip"
      :prefixDisabled="prefixDisabled"
      :prefixToggleOn="prefixToggleOn"
      :suffixIcon="suffixIcon"
      :suffix-text="suffixText"
      :suffixTip="suffixTip"
      :suffixDisabled="suffixDisabled"
      :suffixToggleOn="suffixToggleOnRef"
      :suffixLink="suffixLink"
      :suffixHref="suffixHref"
      :large="large"
      :password="password"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @prefix-click="$emit('prefix-click')"
      @suffix-click="suffixClick"
      :locked="lockedRef"
    />

    <hi-form-error v-if="!hideError" v-model="error" />
  </div>
</template>

<script>
import formControl from "../logics/formControl";
import { computed, nextTick, ref, watch } from "vue";
import { templateRef } from "@vueuse/core";
import commonFormInputProps from "../logics/commonFormInputProps";
import { propsBooleanGroupValuesCheck } from "@/hive-vue3/utils/componentUtils";
import validators from "@/hive-vue3/utils/validators";
import formatters from "@/hive-vue3/utils/formatters";
// import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
// import autoFocus from "@/hive-vue3/directives/autoFocus";
import InputBase from "@/hive-vue3/components/form/controls/base/InputBase";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";

export default {
  name: "HiInput",
  components: { InputBase, HiFormError },
  // directives: { autoFocus },

  props: {
    ...commonFormInputProps,
    modelValue: [String, Number],
    validator: Function,
    placeholder: String,
    formatter: Function,

    email: Boolean,
    mobile: Boolean,
    phone: Boolean,
    finance: Boolean,
    currency: Boolean,
    integer: Boolean,
    digits: Boolean,
    threeDecimalNumber: Boolean,
    fourLengthInteger: Boolean,
    upperCase: Boolean,
    password: Boolean,

    autoFocus: Boolean,
    prefixIcon: String,
    prefixText: String,
    prefixTip: String,
    prefixDisabled: Boolean,
    prefixToggleOn: Boolean,
    suffixIcon: String,
    suffixText: String,
    suffixTip: String,
    suffixDisabled: Boolean,
    suffixToggleOn: Boolean,
    /**
     * turn suffix to a clickable link. will disable automatic when there's no href
     */
    suffixLink: Boolean,
    suffixHref: String,
    large: Boolean,

    lockBySuffix: Boolean,
    locked: Boolean,
    lockedValue: null,
    lockedText: [String, Number],
  },
  emits: [
    "focus",
    "blur",
    "input",
    "update:modelValue",
    "update:locked",
    "update:suffixToggleOn",
    "prefix-click",
    "suffix-click",
  ],
  setup(props, context) {
    propsBooleanGroupValuesCheck(
      props,
      "email",
      "mobile",
      "phone",
      "finance",
      "integer",
      "currency",
      "digits"
    );
    const computedPrefixIcon = computed(() => {
      if (props.prefixIcon) return props.prefixIcon;
      if (props.mobile) return commonIcons.mobile;
      if (props.currency) return commonIcons.dollar;
      if (props.phone) return commonIcons.phone;
      if (props.email) return commonIcons.email;
      return undefined;
    });
    const inputModel = ref(props.modelValue);
    let validator, formatter;
    const input = templateRef("input");
    if (props.email) {
      validator = (val) => {
        if (validators.email(val)) {
          return true;
        }
        return "Invalid email address.";
      };
    } else if (props.mobile) {
      validator = (val) => {
        if (validators.mobile(val)) {
          return true;
        }
        return "Invalid mobile number.";
      };
      formatter = formatters.mobileE164;
    } else if (props.phone) {
      validator = (val) => {
        if (validators.phoneNumber(val)) {
          return true;
        }
        return "Invalid phone number.";
      };
    } else if (props.currency) {
      validator = (val) => {
        if (validators.number(val)) {
          return true;
        }
        return "Invalid number.";
      };
      formatter = formatters.financeNumber;
    } else if (props.integer) {
      validator = (val) => {
        if (validators.digits(val)) {
          return true;
        }
        return "Invalid number.";
      };
      formatter = formatters.integer;
    } else if (props.fourLengthInteger) {
      validator = (val) => {
        if (validators.digitsLength4(val)) {
          return true;
        }
        return "Invalid number.";
      };
      formatter = formatters.integer;
    } else if (props.digits) {
      validator = (val) => {
        if (validators.digits(val)) return true;
        return "Invalid digits.";
      };
    } else if (props.finance) {
      validator = (val) => {
        if (validators.number(val)) {
          return true;
        }
        return "Invalid number.";
      };
      formatter = formatters.financeNumber;
    } else if (props.upperCase) {
      formatter = formatters.upperCase;
    } else if (props.threeDecimalNumber) {
      validator = (val) => {
        if (validators.number(val)) {
          return true;
        }
        return "Invalid number.";
      };
      formatter = formatters.threeDecimalNumber;
    }

    const control = formControl(props, context, {
      //if modelValue changed from parent, need to format and validate just like blur
      onModelValueUpdate: onBlur,
      validator: validator || props.validator,
    });
    const model = control.model;
    const hasFocus = ref(false);
    function focus() {
      if (lockedRef.value) return;
      if (!hasFocus.value) {
        input.value.focus();
      }
    }
    function onFocus() {
      if (lockedRef.value) return;
      // console.log("==============onfocus====================" + props.formKey);
      hasFocus.value = true;
      context.emit("focus");
      inputModel.value = model.value;
    }

    async function onBlur() {
      if (lockedRef.value) return;
      // console.log("=====================onblur==============" + props.formKey);
      hasFocus.value = false;
      await control.touch();
      // const f = formatter || props.formatter;
      // if (f) {
      //   // console.log("format");
      //   model.value = f(control.model.value);
      // }
      context.emit("blur");
      formatCurtainValuesForDisplay();
    }
    function formatCurtainValuesForDisplay() {
      if (control.valid.value && props.currency && model.value) {
        // inputModel.value = Number(model.value).toFixed(2);
        inputModel.value = formatters.currency(model.value);
      } else {
        inputModel.value = model.value;
      }
    }
    function onInput() {
      // console.log("on input");
      const f = formatter || props.formatter;
      if (f) {
        // console.log("format");
        model.value = f(inputModel.value);
      } else {
        model.value = inputModel.value;
      }
    }
    function blur() {
      if (hasFocus.value) {
        input.value.blur();
      }
    }
    function onChange() {
      context.emit("input", model.value);
    }
    //////////// lock ///////////////////
    function suffixClick() {
      context.emit("suffix-click");
      if (props.lockBySuffix) {
        lockedRef.value = !lockedRef.value;
        suffixToggleOnRef.value = lockedRef.value;
      }
    }
    const lockedRef = modelRef(props, context.emit, { propName: "locked" });
    const suffixToggleOnRef = modelRef(props, context.emit, {
      propName: "suffixToggleOn",
    });
    watch(lockedRef, (v) => {
      // console.log("isLocked", v);
      if (v) lock();
      else unlock();
    });

    function lock() {
      blur();
      if (props.lockedText) {
        inputModel.value = props.lockedText;
      }
      if (props.lockedValue !== undefined) {
        model.value = props.lockedValue;
      }
    }
    async function unlock() {
      control.reset();
      inputModel.value = model.value;
      await nextTick();
      focus();
    }
    // const displayPlaceholder = computed(() => {
    //   const val = control.model.value;
    //   if (val && val.length) return null;
    //   return props.placeholder;
    // });
    return {
      model,
      error: control.error,
      valid: control.valid,
      focus,
      blur,
      onFocus,
      onBlur,
      // displayPlaceholder,
      // hasFocus,
      onChange,
      input,
      computedPrefixIcon,
      computedDisplay: inputModel,
      onInput,
      suffixClick,
      lockedRef,
      suffixToggleOnRef,
    };
  },
};
</script>

<style scoped></style>
