<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard @successful="open = false" :submit-fn="submit">
      <hi-form-item label="Schedule Time">
        <hi-date-picker
          form-key="scheduledTime"
          mandatory
          v-model="currentTime"
        />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref, watch } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import { requestSetSchedule } from "@/hive-vue3/api/request";
import { edmState } from "@/hive-vue3/modules/edm/state";
import hive from "@/hive-vue3/components/hive";
export default {
  name: "EdmSetScheduleDialog",
  components: { HiDatePicker, HiFormItem, HiFormHiveStandard, HiDialog },
  setup() {
    const open = ref();
    const currentTime = ref();
    watch(
      () => open.value,
      (v) => {
        if (v) {
          const now = new Date();
          currentTime.value = new Date(now.getTime() + 1000 * 60 * 180);
        }
      }
    );

    async function submit(data) {
      if (data.scheduledTime < new Date()) {
        const confirm = await hive.confirm(
          "The time you set is earlier than now. If confirmed, the email will be sent immediately."
        );
        if (!confirm) {
          return {
            successful: false,
          };
        }
      }
      await requestSetSchedule({
        scheduledTime: data.scheduledTime,
        type: "edm",
        vars: {
          edmId: edmState.currentEdmId,
        },
      });

      return {
        successful: true,
      };
    }

    return {
      open,
      submit,
      currentTime,
    };
  },
};
</script>

<style scoped></style>
