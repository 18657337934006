<template>
  <layout-three-column class="grow" fullCardFirstCol no-big-screen>
    <template v-slot:module-title>
      <div class="flex justify-between w-full items-center">
        <div class="tp-title-faded">Phone Service Setting</div>
        <hi-select
          :items="{
            '+61488849888': '+61 488 849 888',
            '+611300031113': '+61 1300 031 113',
          }"
          v-model="twilioState.currentNumber"
        />
      </div>
    </template>
    <template v-slot:col1>
      <twilio-first-col-view />
    </template>
    <template v-slot:col2>
      <twilio-sencond-col-view />
    </template>
    <template v-slot:col3>
      <twilio-third-col-view />
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import { firestoreCollection } from "@/hive-vue3/firebase";
import { orderBy } from "firebase/firestore";
import { ref } from "vue";
import TwilioFirstColView from "@/hive-vue3/modules/twilio/TwilioFirstColView";
import TwilioSencondColView from "@/hive-vue3/modules/twilio/TwilioSencondColView";
import TwilioThirdColView from "@/hive-vue3/modules/twilio/TwilioThirdColView";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";

export default {
  name: "TwilioViewFullKit",
  components: {
    HiSelect,
    TwilioThirdColView,
    TwilioSencondColView,
    TwilioFirstColView,
    LayoutThreeColumn,
  },
  setup() {
    const phoneSettings = useFirestoreDoc("phone", "settings");

    const redirectOrderIndex = [
      "Today",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const redirectOrderList = ref([]);

    const logs = useFirestoreCollectionAsRef(
      firestoreCollection("/phone/voice/+61488849888"),
      {
        persistData: true,
        query: [orderBy("__dateCreated", "desc")],
      }
    );

    function getLastPhoneUser(action) {
      if (action) {
        const actionArray = Object.values(action);
        actionArray.sort((a, b) => {
          return b.Timestamp - a.Timestamp;
        });
        return actionArray[actionArray.length - 1]["DialCallUser"];
      }
    }

    function getLastPhoneStatus(action) {
      if (action) {
        const actionArray = Object.values(action);
        actionArray.sort((a, b) => {
          return b.Timestamp - a.Timestamp;
        });
        return actionArray[actionArray.length - 1]["DialCallStatus"];
      }
      return false;
    }

    return {
      phoneSettings,
      redirectOrderIndex,
      commonIcons,
      logs,
      getLastPhoneUser,
      redirectOrderList,
      getLastPhoneStatus,
      twilioState,
    };
  },
};
</script>

<style scoped></style>
