<template>
  <auth-view>
    <drawer-menu-view>
      <hi-nav-drawer-item :icon="icons.dashboard" link="/"
        >Dashboard</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.email" :link="{ name: 'email' }"
        >Email</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.contacts" :link="{ name: 'contacts' }"
        >Contacts</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.accounts" :link="{ name: 'accounts' }"
        >Accounts</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.enquiries" :link="{ name: 'enquiries' }"
        >Enquiries</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.leads" :link="{ name: 'leads' }"
        >Leads</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.projects" :link="{ name: 'projects' }"
        >Projects</hi-nav-drawer-item
      >

      <hi-nav-drawer-item
        :icon="icons.quotations"
        :link="{ name: 'quotations' }"
      >
        Quotations
      </hi-nav-drawer-item>
      <hi-nav-drawer-item :icon="icons.invoice" :link="{ name: 'invoice' }">
        Invoice
      </hi-nav-drawer-item>
      <hi-nav-drawer-item
        v-if="permission.admin || permission.maintenance"
        :icon="icons.products"
        :link="{ name: 'products' }"
        >Products</hi-nav-drawer-item
      >
      <hi-nav-drawer-item
        v-if="permission.maintenance"
        :icon="icons.edm"
        :link="{ name: 'edm' }"
        >EDM</hi-nav-drawer-item
      >
      <hi-nav-drawer-item :icon="icons.phone" link="/phoneSettings"
        >Phone Redirect Setting</hi-nav-drawer-item
      >
      <template v-slot:bottom>
        <hi-nav-drawer-item
          v-if="permission.admin || permission.maintenance"
          :icon="icons.users"
          link="/users"
        >
          Users
        </hi-nav-drawer-item>
      </template>
    </drawer-menu-view>
  </auth-view>
</template>
<script>
import {
  mdiHome,
  mdiCog,
  mdiAccountMultiple,
  mdiContacts,
  mdiAlphaABoxOutline,
  mdiAlphaEBoxOutline,
  mdiAlphaLBoxOutline,
  mdiAlphaOBoxOutline,
  mdiAlphaQBoxOutline,
  mdiAlphaPBoxOutline,
  mdiAlphaIBoxOutline,
  mdiCogOutline,
  mdiEmailOutline,
  mdiPhoneInTalkOutline,
  mdiEmailMultipleOutline,
} from "@mdi/js";
import AuthView from "@/hive-vue3/views/AuthView";
import DrawerMenuView from "@/hive-vue3/views/DrawerMenuView";
import HiNavDrawerItem from "@/hive-vue3/components/nav/HiNavDrawerItem";
import { logEnv } from "@/hive-vue3/utils/env";
import { useCurrentFirebaseUserPermissions } from "@/hive-vue3/firebase";
export default {
  components: {
    HiNavDrawerItem,
    DrawerMenuView,
    AuthView,
  },
  setup() {
    logEnv();

    const permission = useCurrentFirebaseUserPermissions();

    const icons = {
      settings: mdiCog,
      dashboard: mdiHome,
      users: mdiAccountMultiple,
      contacts: mdiContacts,
      enquiries: mdiAlphaEBoxOutline,
      leads: mdiAlphaLBoxOutline,
      opportunities: mdiAlphaOBoxOutline,
      quotations: mdiAlphaQBoxOutline,
      projects: mdiAlphaPBoxOutline,
      products: mdiCogOutline,
      email: mdiEmailOutline,
      phone: mdiPhoneInTalkOutline,
      accounts: mdiAlphaABoxOutline,
      invoice: mdiAlphaIBoxOutline,
      edm: mdiEmailMultipleOutline,
    };

    return { icons, permission };
  },
};
</script>
