<template>
  <hi-dialog v-model="open" width="600px">
    <add-new-account-form
      @addSuccessful="onSuccessful"
      @cancel="open = false"
    />
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import AddNewAccountForm from "@/hive-vue3/modules/accounts/AddNewAccountForm";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { getAccountById } from "@/hive-vue3/modules/accounts/data";
// import { ref } from "vue";
export default {
  name: "AddNewAccountDialog",
  components: { AddNewAccountForm, HiDialog },
  props: {
    modelValue: Boolean,
    onAdded: Function,
    hideButton: Boolean,
  },
  setup(props, { emit }) {
    // const open = ref(false);
    const open = modelRef(props, emit);

    function onSuccessful(docId) {
      open.value = false;
      props.onAdded(getAccountById(docId));
    }

    return {
      open,
      onSuccessful,
    };
  },
};
</script>

<style scoped></style>
