<template>
  <module-layout-block-editable
    :editable="editable"
    :dialog-title="'Edit contact info for ' + people.name"
  >
    <module-info-block :data="people">
      <module-labeled-info value-key="email" />
      <module-labeled-info value-key="mobile" />
      <module-labeled-info value-key="address">
        <hi-address-display :model-value="people.address" />
      </module-labeled-info>
    </module-info-block>

    <div v-if="!people.email && !people.mobile && !people.address">
      No contact info yet.
      <span class="clickable-text" v-if="editable">Click to edit.</span>
    </div>
    <!-- ======================the editor dialog-->
    <template v-slot:editor="{ closeDialog }">
      <contacts-people-contact-info-editor
        :original-data="people"
        @successful="closeDialog"
      />
    </template>
  </module-layout-block-editable>
</template>

<script>
import { computedPeopleByIdOrData } from "@/hive-vue3/modules/contacts/data";
import ContactsPeopleContactInfoEditor from "@/hive-vue3/modules/contacts/ContactsPeopleContactInfoEditor";
import HiAddressDisplay from "@/hive-vue3/components/misc/HiAddressDisplay";
import ModuleLabeledInfo from "@/hive-vue3/modules/_common/ModuleLabeledInfo";
import ModuleLayoutBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import ModuleInfoBlock from "@/hive-vue3/modules/_common/ModuleInfoBlock";

export default {
  name: "ContactsPeopleContactInfo",
  components: {
    ModuleInfoBlock,
    ModuleLayoutBlockEditable,
    ModuleLabeledInfo,
    HiAddressDisplay,
    ContactsPeopleContactInfoEditor,
  },
  props: {
    peopleId: String,
    peopleData: Object,
    editable: Boolean,
  },
  setup(props) {
    return {
      people: computedPeopleByIdOrData(props),
    };
  },
};
</script>

<style scoped></style>
