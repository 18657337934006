<template>
  <hi-dialog width="600px" title="Link Entity" v-model="open">
    <hi-form
      @submit="update"
      v-if="peopleId"
      :initial-values="initialValues"
      submit-without-change
    >
      <mfc-input-contacts-entity form-key="entityId" mandatory />
      <div class="h-2" />
      <hi-tagger
        v-model="formData.position"
        :doc-path="dbPaths.positionTagDoc"
        canEdit
        edit-mode
        label="Position"
      />
    </hi-form>
    <div v-else>Please provide peopleId...</div>
  </hi-dialog>
</template>

<script>
import { mdiPlus } from "@mdi/js";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import MfcInputContactsEntity from "@/hive-vue3/modules/contacts/controls/MfcInputContactsEntity";
import { reactive, ref } from "vue";
import HiTagger from "@/hive-vue3/components/HiTagger";
import dbPaths from "../dbPaths";
import HiForm from "@/hive-vue3/components/form/HiForm";
// import { firestoreDoc } from "@/hive-vue3/firebase";
// import { arrayUnion, setDoc } from "firebase/firestore";
import { linkPeopleToEntity } from "@/hive-vue3/modules/contacts/data";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default {
  name: "LinkEntityToPeopleDialog",
  components: {
    HiForm,
    HiTagger,
    MfcInputContactsEntity,
    HiDialog,
  },
  props: {
    peopleId: {
      type: String,
    },
    initialValues: Object,
  },
  setup(props) {
    const icons = {
      add: mdiPlus,
    };
    const open = ref(false);
    const formData = reactive({});

    watchPropsImmediately(props, "peopleId", (v) => {
      if (v) {
        formData.entityId = "";
        formData.position = "";
      }
    });

    async function update(data) {
      // console.log(formformData);
      // const vals = {
      //   entities: arrayUnion(formformData.entityId),
      // };
      // if (formData.position) {
      //   vals.entityPositions = { [formformData.entityId]: formData.position };
      // }
      // // console.log(vals);
      // const doc = firestoreDoc(dbPaths.people, props.peopleId);
      // console.log(formData);
      await linkPeopleToEntity(
        props.peopleId,
        data.entityId,
        formData.position
      );
      open.value = false;
    }

    return { icons, formData, dbPaths, update, open };
  },
};
</script>

<style scoped></style>
