<template>
  <div class="flex flex-col" style="min-width: 520px">
    <div
      class="flex py-2 px-4 gap-4 items-center border-b border-primary/20 bg-light-300"
    >
      <slot name="filterArea" />
    </div>
    <div
      class="flex py-2 px-4 gap-4 items-center border-b border-primary/20 bg-light-300"
    >
      <hi-search-input
        v-if="searchFn || (searchables && searchables.length)"
        @search="onSearch"
        @select.stop
        realtime
        :placeholder="searchInputPlaceholder"
        class="grow"
        :model-value="state.searchValue"
      />
      <span class="text-xs" v-if="sortables && sortables.length"
        ><span style="font-size: 8px" class="text-gray-400 mr-2">SORT BY</span>
        <hi-data-list-sorter :items="sortables"
      /></span>
    </div>
    <hi-data-list
      @select="onSelect"
      auto-items-per-page
      :min-item-height="minItemHeight"
      class="grow bg-light-100"
      vertical-center-item
      :current-id="currentId"
    >
      <template v-slot="{ item }">
        <slot v-bind="{ item }">{{ item }}</slot>
      </template>
    </hi-data-list>
    <hi-pagination class="grow-0 hi-footer" />
  </div>
</template>

<script>
import HiDataList from "@/hive-vue3/components/dataDisplay/HiDataList";
import { injectDataListStore } from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiSearchInput from "@/hive-vue3/components/HiSearchInput";
import HiDataListSorter from "@/hive-vue3/components/dataDisplay/HiDataListSorter";
import HiPagination from "@/hive-vue3/components/dataDisplay/HiPagination";
import { watchEffect } from "vue";
export default {
  name: "HiDataListKit",
  components: { HiPagination, HiDataListSorter, HiSearchInput, HiDataList },
  props: {
    debug: Boolean,
    sortables: Array,
    searchFn: Function,
    searchables: Array,
    filterKey: String,
    filterValue: String,
    searchInputPlaceholder: String,
    minItemHeight: {
      type: [Number, String],
      default: 68,
    },
    currentId: String,
  },
  setup(props, { emit }) {
    const store = injectDataListStore();
    if (!store) {
      console.warn("HiDataListKit found no provided data");
    }
    const state = store.state;
    watchEffect(() => {
      state.sortables = props.sortables;
      state.searchFn = props.searchFn;
      state.searchables = props.searchables;
      store.actions.updateFilters(props.filterKey, props.filterValue);
    });
    function onSearch(v) {
      // console.log(v);
      store.actions.search(v);
    }
    function onSelect(item) {
      if (props.debug) {
        console.log(item);
      }
      emit("select", item);
    }
    return {
      onSearch,
      state,
      onSelect,
    };
  },
};
</script>

<style scoped></style>
