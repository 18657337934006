<template>
  <hi-data-list-kit
    :search-fn="projectListSearchFn"
    :sortables="projectSortRules"
    @select="onSelect"
    :filter-value="filterOwner"
    filter-key="mainOwner"
    search-input-placeholder="Name, Address or Type"
    :current-id="projectsState.currentProjectId"
  >
    <template v-slot:filterArea>
      <hi-select
        v-model="filterOwner"
        placeholder="Main Owner..."
        :items="userNoMaintenance"
        no-form
      />
    </template>
    <template v-slot="{ item }">
      <project-summary :project-data="item" />
    </template>
  </hi-data-list-kit>
</template>

<script>
import {
  getActiveProjects,
  projectListSearchFn,
  projectSortRules,
} from "@/hive-vue3/modules/projects/data";
import ProjectSummary from "@/hive-vue3/modules/projects/ProjectSummary";
import { projectsState } from "@/hive-vue3/modules/projects/state";
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import { computed, ref } from "vue";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import { useSystemUsers } from "@/hive-vue3/firebase";
export default {
  name: "ProjectsActiveList",
  components: { HiSelect, HiDataListKit, ProjectSummary },
  setup() {
    const filterOwner = ref(undefined);
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [{ text: "NAN", value: "noFilter" }];
      for (const i in users) {
        console.log(users[i].roles);
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push({
            text: users[i].name,
            value: users[i].id,
          });
        }
      }
      return returnArr;
    });

    const activeProjects = computed(() => getActiveProjects());

    const activeProjectListStore = createDataListStore(activeProjects);
    provideDataListStore(activeProjectListStore);

    function onSelect(item) {
      projectsState.currentProjectId = item.id;
    }

    return {
      onSelect,
      projectListSearchFn,
      projectSortRules,
      projectsState,
      filterOwner,
      userNoMaintenance,
    };
  },
};
</script>

<style scoped></style>
