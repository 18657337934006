<template>
  <headless-file-input
    droppable
    class="__hi-fire-file-drop"
    v-model="fileValue"
    :accept-mime="computedMime"
    ref="fileInput"
  >
    <transition-fade fast :start-opacity="0.3">
      <private-vault-uploader
        v-if="uploading"
        :file="fileValue"
        @completed="uploadCompleted"
        @error="uploading = false"
        @cancel="uploading = false"
      />
      <private-vault-image
        v-else-if="model && asImage"
        :model-value="model"
        class="w-full h-full"
        @delete="onDelete"
        @replace="triggerInput"
        :cover="imageDisplayCover"
      />
      <hi-vault-file
        :file-id="model"
        v-else-if="model"
        class="h-full w-full"
        can-delete
        can-replace
        @delete="onDelete"
        @replace="triggerInput"
      />
      <center-box v-else class="uppercase">
        <private-upload-button @click="triggerInput" />
      </center-box>
    </transition-fade>
  </headless-file-input>
</template>

<script>
import { computed, ref, watch } from "vue";
import { templateRef } from "@vueuse/core/index";
import HeadlessFileInput from "@/hive-vue3/components/headless/HeadlessFileInput";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import PrivateUploadButton from "./_private-file-vault-components/PrivateUploadButton";
import HiVaultFile from "@/hive-vue3/components/firebase/HiVaultFile";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import mime from "@/hive-vue3/utils/constables/mime";
import PrivateVaultImage from "@/hive-vue3/components/firebase/_private-file-vault-components/PrivateVaultImage";
import PrivateVaultUploader from "@/hive-vue3/components/firebase/_private-file-vault-components/privateVaultUploader";

export default {
  name: "HiVaultFileDrop",
  components: {
    PrivateVaultUploader,
    PrivateVaultImage,
    HiVaultFile,
    // HiAttachment,
    PrivateUploadButton,
    CenterBox,
    TransitionFade,
    HeadlessFileInput,
  },
  props: {
    acceptMime: {
      type: Array,
    },
    modelValue: String,
    asImage: Boolean,
    imageDisplayCover: Boolean,
  },
  emits: ["update:modelValue", "upload", "completed"],
  setup(props, { emit }) {
    const model = modelRef(props, emit);

    const fileValue = ref(null);
    const uploading = ref(false);
    const fileInput = templateRef("fileInput");
    watch(fileValue, (v) => {
      if (v) {
        uploading.value = true;
        model.value = undefined;
        // console.log("vault file upload started");
        emit("upload");
      }
    });

    async function uploadCompleted(id) {
      model.value = id;
      uploading.value = false;
      // console.log("vault file upload completed");
      emit("completed");
      // console.log(id);
    }

    function triggerInput() {
      fileInput.value.triggerInput();
    }
    function onDelete() {
      model.value = undefined;
    }
    const computedMime = computed(() => {
      if (props.acceptMime) return props.acceptMime;
      else if (props.asImage) return [mime.ImagePng, mime.ImageJpeg];
      else return undefined;
    });
    return {
      model,
      fileValue,
      uploading,
      uploadCompleted,
      triggerInput,
      onDelete,
      computedMime,
    };
  },
};
</script>

<style scoped></style>
