<template>
  <hi-card>
    <div
      v-for="(item, index) in users"
      :key="item + index"
      class="p-2 border-b-2"
    >
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="item.id" />
        <div class="w-[180px]">
          <div>Active: {{ getLeadsNumByUserId(item.id) }}</div>
        </div>
        <div class="w-[180px]">
          <div>Closed: {{ getActiveLeadsNumByUserId(item.id) }}</div>
        </div>
        <div class="w-[180px]">
          <div>Total: {{ getClosedLeadsNumByUserId(item.id) }}</div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import { useSystemUsers } from "@/hive-vue3/firebase";
import { computed } from "vue";
import HiCard from "@/hive-vue3/components/HiCard";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import {
  getActiveLeadsByUserId,
  getAllLeadsByUserId,
  getClosedLeadsByUserId,
} from "@/hive-vue3/modules/leads/data";

export default {
  name: "LeadsNumByUser",
  components: { UserDetailsSpan, HiCard },
  setup() {
    const users = useSystemUsers();
    const userNoMaintenance = computed(() => {
      const returnArr = [];
      for (const i in users) {
        if (users[i].roles.indexOf("maintenance") === -1) {
          returnArr.push(users[i]);
        }
      }
      return returnArr;
    });

    function getLeadsNumByUserId(userId) {
      return getAllLeadsByUserId(userId).length;
    }

    function getActiveLeadsNumByUserId(userId) {
      return getActiveLeadsByUserId(userId).length;
    }
    function getClosedLeadsNumByUserId(userId) {
      return getClosedLeadsByUserId(userId).length;
    }

    return {
      users: userNoMaintenance,
      getLeadsNumByUserId,
      getActiveLeadsNumByUserId,
      getClosedLeadsNumByUserId,
    };
  },
};
</script>

<style scoped></style>
