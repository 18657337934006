export default function (src) {
  // eslint-disable-line no-param-reassign
  return new Promise(function (resolve, reject) {
    // console.log(document.head);
    let shouldAppend = false;
    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
      el = document.createElement("script");
      el.type = "text/javascript";
      el.async = true;
      el.src = src;
      shouldAppend = true;
    } else if (el.hasAttribute("data-loaded")) {
      // console.log("loaded already");
      resolve(el);
      return;
    }

    el.addEventListener("error", (e) => {
      console.log("loadScript Error", e);
      reject(e);
    });
    el.addEventListener("abort", reject);
    el.addEventListener("load", () => {
      // console.log("script loaded", src);
      el.setAttribute("data-loaded", true);
      resolve(el);
    });

    if (shouldAppend) document.head.appendChild(el);
  });
}
