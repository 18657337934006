<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form @submit="submit" :initial-values="lead">
      <hi-form-item label="description">
        <hi-textarea form-key="description" />
      </hi-form-item>
      <hi-form-item label="Main Owner">
        <div class="flex flex-col gap-2">
          <user-details-span show-email :user-id="mainOwner" />
          <mfc-system-user-selector form-key="mainOwner" v-model="mainOwner" />
        </div>
      </hi-form-item>

      <hi-form-item label="Sub Owners">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <user-details-span
              v-for="(user, index) in subOwners"
              :key="user + index"
              show-email
              :user-id="user"
            />
          </div>
          <mfc-system-user-selector
            multiple
            :disabled-ids="[mainOwner]"
            form-key="subOwners"
            v-model="subOwners"
          />
        </div>
      </hi-form-item>
    </hi-form>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiForm from "@/hive-vue3/components/form/HiForm";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import {
  assignLead,
  getLeadById,
  getPeopleNameByLeadId,
} from "@/hive-vue3/modules/leads/data";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import { computed, ref } from "vue";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { useSystemUsers } from "@/hive-vue3/firebase";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { arrayUnique } from "@/hive-vue3/modules/utils/utils";
import moment from "moment";
import { requestActivityNotification } from "@/hive-vue3/api/request";

export default {
  name: "LeadsAssignDialog",
  components: {
    HiTextarea,
    HiFormItem,
    UserDetailsSpan,
    MfcSystemUserSelector,
    HiForm,
    HiDialog,
  },

  setup() {
    const open = ref(false);
    const mainOwner = ref(undefined);
    const subOwners = ref(undefined);

    const lead = computed(() => {
      return getLeadById(leadsState.currentLeadId);
    });

    async function submit(data) {
      if (!data.subOwners) {
        data.subOwners = [];
      }
      if (!data.mainOwner) {
        data.mainOwner = null;
      }

      if (
        data.mainOwner !== lead.value.mainOwner ||
        JSON.stringify(data.subOwners) !== JSON.stringify(lead.value.subOwners)
      ) {
        const systemUsers = useSystemUsers();
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.ASSIGN;
        historyObj.target = "leads";
        historyObj.targetId = leadsState.currentLeadId;
        historyObj.comments = `Assign owners: ${
          data.mainOwner ? systemUsers[data.mainOwner].name : "N/A"
        } (Main), ${
          data.subOwners && data.subOwners.length
            ? data.subOwners.map((v) => systemUsers[v].name).join(", ")
            : ""
        }`;

        await addNewHistory(historyObj);

        let initialSubOwners = lead.value.subOwners;
        let newSubOwners = data.subOwners;
        if (!initialSubOwners) {
          initialSubOwners = [];
        }
        if (!newSubOwners) {
          newSubOwners = [];
        }
        const users = arrayUnique([
          lead.value.mainOwner,
          ...initialSubOwners,
          data.mainOwner,
          ...newSubOwners,
        ]);

        const name = getPeopleNameByLeadId(leadsState.currentLeadId);
        const m = moment(new Date());
        const timeStr = m.format("DD MMM HH:mm");
        console.log(users);
        requestActivityNotification({
          users,
          smallTitle: "Owner Changes in Leads",
          mainTitle: name,
          timeStr,
          message: historyObj.comments,
          directLink:
            "https://my.artimber.au/leads/" + leadsState.currentLeadId,
          breadcrumbs: [
            {
              href: "https://my.artimber.au/",
              text: "My Artimber",
            },
            {
              href: "https://my.artimber.au/" + "leads/",
              text: "accounts",
            },
            {
              href: "https://my.artimber.au/leads/" + leadsState.currentLeadId,
              text: name,
            },
          ],
        }).then();
      }

      await assignLead(leadsState.currentLeadId, data);
      open.value = false;
    }
    return {
      submit,
      open,
      mainOwner,
      subOwners,
      lead,
    };
  },
};
</script>

<style scoped></style>
