<template>
  <module-titled-block
    v-if="people"
    :title="'Entities (' + (people.entities ? people.entities.length : 0) + ')'"
    class="w-full"
  >
    <template v-slot:tools v-if="editable">
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link another entity"
        />
        <link-entity-to-people-dialog :people-id="people.id" />
      </hi-dialog-trigger>
    </template>
    <hi-list :items="people.entities">
      <template v-slot="{ item: entity }">
        <hi-dialog-trigger>
          <contacts-entity
            :entity-id="entity"
            class="flex justify-between cursor-pointer"
          >
            <!--            <contacts-entity-position-->
            <!--              class="tp-description"-->
            <!--              v-if="people.entityPositions"-->
            <!--              :position-id="people.entityPositions[entity]"-->
            <!--            />-->
            <div class="tp-description" v-if="people.entityPositions">
              {{ decodeDocId(people.entityPositions[entity]) }}
            </div>
          </contacts-entity>

          <hi-dialog title="Entity Overview" height="80vh" width="600px">
            <contacts-entity-overview :entity-id="entity" />
            <template v-slot:footer>
              <hi-button text @click="removePeopleFromEntity(people.id, entity)"
                >Remove
                <contacts-entity :entity-id="entity" class="mx-1 font-bold" />
                From
                <contacts-people :people-id="people.id" class="mx-1" no-bold />
              </hi-button>
              <div class="grow"></div>
              <hi-dialog-trigger>
                <hi-button text> Change Position</hi-button>
                <link-entity-to-people-dialog
                  :people-id="people.id"
                  :initial-values="{ entityId: entity }"
                />
              </hi-dialog-trigger>
            </template>
          </hi-dialog>
        </hi-dialog-trigger>
      </template>
    </hi-list>
  </module-titled-block>
</template>

<script>
import LinkEntityToPeopleDialog from "@/hive-vue3/modules/contacts/parts/LinkEntityToPeopleDialog";
import { computedPeopleByIdOrData } from "@/hive-vue3/modules/contacts/data";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
// import ContactsEntityPosition from "@/hive-vue3/modules/contacts/ContactsEntityPosition";
import HiList from "@/hive-vue3/components/HiList";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import ModuleTitledBlock from "@/hive-vue3/modules/_common/ModuleTitledBlock";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import ContactsEntityOverview from "@/hive-vue3/modules/contacts/ContactsEntityOverview";
import HiButton from "@/hive-vue3/components/HiButton";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import { removePeopleFromEntity } from "@/hive-vue3/modules/contacts/data";
import { decodeDocId } from "@/hive-vue3/firebase";

export default {
  name: "ContactsEntitiesByPeopleView",
  components: {
    ContactsPeople,
    HiButton,
    ContactsEntityOverview,
    HiDialog,
    ModuleTitledBlock,
    HiIcon,
    HiDialogTrigger,
    HiList,
    // ContactsEntityPosition,
    ContactsEntity,
    LinkEntityToPeopleDialog,
  },
  props: {
    editable: Boolean,
    peopleData: Object,
    peopleId: String,
  },
  setup(props) {
    return {
      decodeDocId,
      commonIcons,
      people: computedPeopleByIdOrData(props),
      removePeopleFromEntity,
    };
  },
};
</script>

<style scoped></style>
