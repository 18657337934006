<template>
  <span ref="container">
    <slot />
  </span>
</template>

<script>
import Mark from "mark.js";
import { ref, onMounted, watch } from "vue";
export default {
  name: "hi-markable",
  props: {
    search: String,
    separateWordSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const container = ref(null);
    let marker;
    let timeout;
    onMounted(() => {
      marker = new Mark(container.value);
      if (props.search) mark();
    });
    function mark() {
      if (timeout) clearTimeout(timeout);
      try {
        if (marker) {
          marker.unmark();
        }
        if (!props.search || !props.search.length) return;
        setTimeout(() => {
          try {
            marker.mark(props.search, {
              acrossElements: true,
              ignoreJoiners: true,
              exclude: [".hi-marker-disabled *", ".hi-marker-disabled"],
              ignorePunctuation: [" ", "-", "'"],
              separateWordSearch: props.separateWordSearch,
            });
          } catch (e) {
            console.log(e);
          }
        }, 300);
      } catch (e) {
        console.log(e);
      }
    }
    watch(props, () => {
      mark();
    });
    return {
      container,
    };
  },
};
</script>

<style scoped></style>
