<template>
  <hi-dialog title="Close Enquiry" width="600px">
    <div class="font-bold my-4">
      Please confirm to close the following enquiry.
    </div>
    <enquiry-title class="border-2" :doc-data="currentEnquiry" />

    <hi-form @submit="submit">
      <hi-form-value-holder
        form-key="closedBy"
        :model-value="getCurrentFirebaseUserId()"
      />
      <hi-form-value-holder form-key="isConverted" :model-value="false" />

      <div class="font-bold mt-4">Please write the reason.</div>
      <hi-textarea :rows="3" form-key="reason" />
    </hi-form>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { getCurrentFirebaseUserId } from "@/hive-vue3/firebase";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
import EnquiryTitle from "@/hive-vue3/modules/enquiries/parts/EnquiryTitle";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import { computed } from "vue";
import {
  closeEnquiry,
  getEnquiryById,
} from "@/hive-vue3/modules/enquiries/data";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";

export default {
  name: "EnquiryCloseDialog",
  components: {
    EnquiryTitle,
    HiFormValueHolder,
    HiTextarea,
    HiForm,
    HiDialog,
  },
  setup() {
    const currentEnquiry = computed(() => {
      return getEnquiryById(enquiryState.currentEnquiryId);
    });

    async function submit(data) {
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.CLOSE;
      historyObj.target = "enquiry";
      historyObj.targetId = enquiryState.currentEnquiryId;
      historyObj.comments = `Enquiry Closed: ${data.reason}`;

      await addNewHistory(historyObj);

      closeEnquiry(enquiryState.currentEnquiryId, data);
      return {
        successful: true,
      };
    }
    return {
      submit,
      getCurrentFirebaseUserId,
      enquiryState,
      currentEnquiry,
    };
  },
};
</script>

<style scoped></style>
