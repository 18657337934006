<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="flex items-center opacity-10 transition-opacity duration-500"
    :class="{ 'opacity-100': ready }"
  >
    <div>
      <p class="text-xs text-gray-600">
        <span>{{ from }}</span>
        -
        <span>{{ to }}</span>
        of
        <span>{{ formatNumber(totalItems) }}</span>
      </p>
    </div>
    <div class="grow" />
    <div>
      <hi-button-group>
        <hi-tool-button
          :icon="icons.prev"
          :class="{ disabled: currentPage === 1 }"
          @click.prevent="prevPage"
        />
        <hi-tool-button
          :class="{
            active: page === currentPage,
            dots: page === '...',
            'pointer-events-none': page === '...',
          }"
          v-for="page in pagePool"
          :key="'page' + page"
          @click.prevent="gotoPage(page)"
        >
          {{ page }}
        </hi-tool-button>
        <hi-tool-button
          :icon="icons.next"
          :class="{ disabled: currentPage === totalPages || totalPages === 1 }"
          @click.prevent="nextPage"
        />
      </hi-button-group>
      <!--      <nav-->
      <!--        class="relative z-0 inline-flex shadow-sm -space-x-px text-xs font-medium text-primary-darker"-->
      <!--        aria-label="Pagination"-->
      <!--      >-->
      <!--        <center-box-->
      <!--          class="relative text-sm rounded-l-md border border-gray-300 bg-white hover:bg-gray-50 h-8 w-8 cursor-pointer"-->
      <!--          :class="{ disabled: currentPage === 1 }"-->
      <!--          @click.prevent="prevPage"-->
      <!--        >-->
      <!--          <icon-chevron left />-->
      <!--        </center-box>-->

      <!--        <center-box-->
      <!--          class="border border-gray-300 bg-white font-medium hover:bg-gray-50 w-8 cursor-pointer page-number"-->
      <!--          :class="{ active: page === currentPage, dots: page === '...' }"-->
      <!--          v-for="page in pagePool"-->
      <!--          :key="'page' + page"-->
      <!--          @click.prevent="gotoPage(page)"-->
      <!--        >-->
      <!--          {{ page }}-->
      <!--        </center-box>-->

      <!--        <center-box-->
      <!--          class="relative rounded-r-md border border-gray-300 bg-white hover:bg-gray-50 h-8 w-8 cursor-pointer"-->
      <!--          :class="{ disabled: currentPage === totalPages || totalPages === 1 }"-->
      <!--          @click.prevent="nextPage"-->
      <!--        >-->
      <!--          <icon-chevron right />-->
      <!--        </center-box>-->
      <!--      </nav>-->
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import HiButtonGroup from "@/hive-vue3/components/HiButtonGroup";
import HiToolButton from "@/hive-vue3/components/HiToolButton";
import { injectDataListStore } from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import numeral from "numeral";

export default {
  name: "HiPagination",
  components: { HiToolButton, HiButtonGroup },
  props: {},
  setup() {
    const store = injectDataListStore();
    const { from, to, totalItems, currentPage, totalPages, ready } = toRefs(
      store.state
    );
    const { gotoPage, prevPage, nextPage } = store.actions;
    const pagePool = computed(() => {
      // console.log(totalPages);
      if (totalPages.value <= 9) {
        const arr = [];
        for (let i = 1; i <= totalPages.value; i++) {
          arr.push(i);
        }
        return arr;
      }
      if (currentPage.value <= 5) {
        return [1, 2, 3, 4, 5, 6, 7, "...", totalPages.value];
      } else if (currentPage.value >= totalPages.value - 5) {
        const n = totalPages.value - 4;
        return [1, "...", n - 2, n - 1, n, n + 1, n + 2, n + 3, n + 4];
      } else {
        const n = currentPage.value;
        return [
          1,
          "...",
          n - 2,
          n - 1,
          n,
          n + 1,
          n + 2,
          "...",
          totalPages.value,
        ];
      }
    });

    const icons = {
      next: mdiChevronRight,
      prev: mdiChevronLeft,
    };
    return {
      from,
      to,
      totalItems,
      currentPage,
      totalPages,
      pagePool,
      gotoPage,
      prevPage,
      nextPage,
      ready,
      icons,
      formatNumber(n) {
        return numeral(n).format("0,0");
      },
    };
  },
};
</script>

<style scoped>
/*.active {*/
/*  @apply bg-primary-lighter cursor-default pointer-events-none;*/
/*}*/
/*.disabled {*/
/*  @apply opacity-30 cursor-default pointer-events-none;*/
/*}*/
/*.dots {*/
/*  @apply pointer-events-none bg-opacity-30 text-gray-300;*/
/*}*/
/*.page-number {*/
/*  @apply transition-all duration-500;*/
/*}*/
</style>
