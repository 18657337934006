<template>
  <invoice-view-full-kit class="h-full" />
</template>

<script>
import InvoiceViewFullKit from "@/hive-vue3/modules/invoice/InvoiceViewFullKit";
export default {
  name: "InvoiceView",
  components: { InvoiceViewFullKit },
};
</script>

<style scoped></style>
