<template>
  <hi-card>
    <div class="p-2 border-b-2">
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="item.uid" />
        <div class="w-[180px]">
          <div>Active: {{ getActiveAccountNumByUserId(item.uid) }}</div>
          <div>
            ${{ getActiveTotalAmountByUserId(item.uid).toLocaleString() }}
          </div>
        </div>
        <div class="w-[180px]">
          <div>Closed: {{ getClosedAccountNumByUserId(item.uid) }}</div>
          <div>
            ${{ getClosedTotalAmountByUserId(item.uid).toLocaleString() }}
          </div>
        </div>
        <div class="w-[180px]">
          <div>Total: {{ getAccountNumByUserId(item.uid) }}</div>
          <div>${{ getAllTotalAmountByUserId(item.uid).toLocaleString() }}</div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { getCurrentFirebaseUser, useSystemRoles } from "@/hive-vue3/firebase";
import {
  getActiveAccountsByUserId,
  getAllAccountsByUserId,
  getAmountByGivenAccountArray,
  getClosedAccountsByUserId,
} from "@/hive-vue3/modules/accounts/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
export default {
  name: "MyAccountNumCard",
  components: { UserDetailsSpan, HiCard },
  setup() {
    const user = getCurrentFirebaseUser();

    function getAccountNumByUserId(userId) {
      return getAllAccountsByUserId(userId).length;
    }

    function getActiveAccountNumByUserId(userId) {
      return getActiveAccountsByUserId(userId).length;
    }
    function getClosedAccountNumByUserId(userId) {
      return getClosedAccountsByUserId(userId).length;
    }

    function getActiveTotalAmountByUserId(userId) {
      const arr = getActiveAccountsByUserId(userId);
      return getAmountByGivenAccountArray(arr);
    }
    function getClosedTotalAmountByUserId(userId) {
      const arr = getClosedAccountsByUserId(userId);
      return getAmountByGivenAccountArray(arr);
    }

    function getAllTotalAmountByUserId(userId) {
      const arr = getAllAccountsByUserId(userId);
      return getAmountByGivenAccountArray(arr);
    }

    const roles = useSystemRoles();

    return {
      item: user,
      getAccountNumByUserId,
      getActiveAccountNumByUserId,
      getClosedAccountNumByUserId,
      getActiveTotalAmountByUserId,
      getClosedTotalAmountByUserId,
      getAllTotalAmountByUserId,
      roles,
    };
  },
};
</script>

<style scoped></style>
