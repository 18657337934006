<template>
  <div v-if="invoice" class="items-center">
    <div class="tp-subtitle flex mb-1">
      <div class="ellipse-flex-grow-item" v-if="project && project.name">
        {{ project.name }}({{
          project.address.streetNumber +
          " " +
          project.address.streetShort +
          ", " +
          project.address.cityShort +
          " " +
          project.address.stateShort +
          ", " +
          project.address.postcode
        }}) - ${{ total }}
      </div>

      <div v-if="invoice.invoiceNum">
        INV-{{ invoice.invoiceNum.toString().padStart(4, "0") }}
      </div>
    </div>

    <div class="flex items-center">
      <div class="grow">
        <div class="tp-description-sm">
          {{ getContactsFullNameById(invoice.attention) }} ({{
            invoice.legalName
              ? getContactsEntityNameById(invoice.client)
              : getContactsEntityTradingNameById(invoice.client)
          }})
        </div>

        <div class="tp-description-sm">
          Created by: <user-details-span :user-id="invoice.__createdBy" /> at
          <hi-date-time-display :model-value="invoice.__dateCreated" friendly />
        </div>
        <div class="tp-description-sm">
          Last Update:
          <hi-date-time-display :model-value="invoice.__dateUpdated" friendly />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { getTotal } from "@/hive-vue3/modules/quotation/data";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import {
  getContactsEntityNameById,
  getContactsEntityTradingNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import { getInvoiceById } from "@/hive-vue3/modules/invoice/data";

export default {
  name: "InvoiceSummary",
  components: { UserDetailsSpan, HiDateTimeDisplay },
  props: {
    invoiceData: Object,
    invoiceId: String,
  },
  setup(props) {
    const invoice = computed(() => {
      if (props.invoiceData) return props.invoiceData;
      if (props.invoiceId) {
        return getInvoiceById(props.invoiceId);
      }
      return null;
    });

    const project = computed(() => {
      return getProjectById(invoice.value.projectId);
    });

    return {
      getContactsEntityNameById,
      getContactsFullNameById,
      getContactsEntityTradingNameById,
      invoice,
      project,
      total: computed(() => {
        return getTotal(invoice.value);
      }),
    };
  },
};
</script>

<style scoped></style>
