export const videoFormats = {
  OGG: 'video/ogg; codecs="theora"',
  H264: 'video/mp4; codecs="avc1.42E01E"',
  WEBM_VP8: 'video/webm; codecs="vp8, vorbis"',
  WEBM_VP9: 'video/webm; codecs="vp9"',
  HLS: 'application/x-mpegURL; codecs="avc1.42E01E"',
};
let video;
export function canPlayVideo(type) {
  if (!video) {
    video = document.createElement("video");
  }

  return video.canPlayType(videoFormats[type] || type);
}
export function canPlayWebM() {
  return canPlayVideo(videoFormats.WEBM_VP8);
}
let canvas;
export function canUseWebP() {
  canvas = document.createElement("canvas");
  if (canvas.getContext && canvas.getContext("2d")) {
    // was able or not to get WebP representation
    return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
}

/**
 * # Checks whether running on a mobile device according to browser data.
 * Functions (each returns bool):
 * * Android
 * * BlackBerry
 * * iPhone
 * * iPod
 * * iPad
 * * iOS
 * * Opera
 * * Windows
 * * Kindle Fire
 * * any
 * @example
 * isMobile.android => true/false
 * isMobile.iOS => true/false
 * isMobile.any => true/false
 * isMobile.kindle => true/false
 * isMobile.blackBerry => true/false
 */

export const isMobile = {
  getUserAgent: () => {
    return navigator.userAgent;
  },
  get android() {
    return /Android/i.test(isMobile.getUserAgent()) && !isMobile.windows;
  },
  get blackBerry() {
    return /BlackBerry|BB10|PlayBook/i.test(isMobile.getUserAgent());
  },
  get iPhone() {
    return (
      /iPhone/i.test(isMobile.getUserAgent()) &&
      !isMobile.iPad &&
      !isMobile.windows
    );
  },
  get iPod() {
    return /iPod/i.test(isMobile.getUserAgent());
  },
  get iPad() {
    return /iPad/i.test(isMobile.getUserAgent());
  },
  get iOS() {
    return isMobile.iPad || isMobile.iPod || isMobile.iPhone;
  },
  get opera() {
    return /Opera Mini/i.test(isMobile.getUserAgent());
  },
  get windows() {
    return /Windows Phone|IEMobile|WPDesktop/i.test(isMobile.getUserAgent());
  },
  get kindle() {
    return /Kindle Fire|Silk|KFAPWA|KFSOWI|KFJWA|KFJWI|KFAPWI|KFAPWI|KFOT|KFTT|KFTHWI|KFTHWA|KFASWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFARWI/i.test(
      isMobile.getUserAgent()
    );
  },
  get any() {
    return (
      isMobile.android ||
      isMobile.blackBerry ||
      isMobile.iOS ||
      isMobile.opera ||
      isMobile.windows
    );
  },
};

/**
 * Gets whether running on a certain operating system according to browser data.
 * Functions to test for a certain operating system (each returns bool):
 * * isAndroid
 * * isBlackBerry
 * * isIos
 * * isMac
 * * isWindows
 * * isLinux
 *
 * Function to get the operating system by name:
 * * get (possible return values: iOS, Android, Windows, Linux, Mac, BlackBerry, Unknown)
 * @example
 * ```js
 * os.isAndroid() => true/false
 * os.isIos() => true/false
 * os.isWindows() => true/false
 * os.isBlackBerry() => true/false
 * os.isMac() => true/false
 * os.isLinux() => true/false
 * os.name => iOS/Android/Windows/Linux/Mac/BlackBerry/Unknown
 * ```
 */

export const os = {
  getUserAgent: () => {
    return navigator.userAgent;
  },
  getPlatform: () => {
    return navigator.platform;
  },
  isIos: () => {
    return /iPhone|iPad|iPod/.test(os.getPlatform());
  },
  isAndroid: () => {
    return /Android/.test(os.getUserAgent());
  },
  isBlackBerry: () => {
    return /BlackBerry/.test(os.getPlatform());
  },
  isMac: () => {
    return /Mac/.test(os.getPlatform());
  },
  isWindows: () => {
    return /Win/.test(os.getPlatform());
  },
  isLinux: () => {
    return /Linux/.test(os.getPlatform()) && !os.isAndroid();
  },
  get name() {
    if (os.isIos()) return "iOS";
    if (os.isAndroid()) return "Android";
    if (os.isBlackBerry()) return "BlackBerry";
    if (os.isMac()) return "Mac";
    if (os.isWindows()) return "Windows";
    if (os.isLinux()) return "Linux";
    return "Unknown";
  },
};
