<template>
  <div>
    <div
      v-if="droppable"
      class="hi-file-drop-area w-full h-full"
      style="min-height: 40px"
      :class="{ dragover: isOver }"
      @dragenter="dragEnter"
      @dragover="dragOver"
      @drop="drop"
      @mouseout="isOver = false"
      @dragleave="isOver = false"
    >
      <slot> Drop {{ multiple ? "files" : "file" }} here to upload. </slot>
      <slot name="clickZone" @click="triggerInput"></slot>
    </div>
    <span v-else @click="triggerInput">
      <slot
        ><hi-button
          >Choose {{ multiple ? "files" : "file" }}...</hi-button
        ></slot
      >
    </span>
    <span>
      <input
        type="file"
        style="display: none"
        @change="change"
        ref="input"
        :multiple="multiple"
        :accept="acceptMime ? acceptMime.join(',') : '*'"
      />
    </span>
  </div>
</template>

<script>
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import { templateRef } from "@vueuse/core/index";
import HiButton from "@/hive-vue3/components/HiButton";
import { ref } from "vue";
import hive from "@/hive-vue3/components/hive";

function sameFile(file1, file2) {
  if (!file1 && file2) return false;
  if (file1 && !file2) return false;
  if (!file1 && !file2) return true;
  return (
    file1.name + file1.size + file1.lastModified ===
    file2.name + file2.size + file2.lastModified
  );
}

/**
 * @provide HEADLESS_INPUT_TRIGGER
 */
export default {
  name: "HeadlessFileInput",
  components: { HiButton },
  props: {
    multiple: Boolean,
    modelValue: Object,
    droppable: Boolean,
    acceptMime: Array,
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const input = templateRef("input");
    const model = modelRef(props, emit);
    function handleFiles(files) {
      // console.log(files);
      const modelValue = model.value;
      if (!files || !files.length) {
        model.value = null;
        if (modelValue) {
          emit("change", null);
        }
        return;
      }
      if (props.multiple) {
        if (model.value) {
          //unique push file to model.value
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!isFileAccepted(file)) {
              continue;
            }
            let exist;
            for (let j = 0; j < model.value.length; j++) {
              if (sameFile(model.value[j], file)) {
                exist = true;
                // console.log("exist", file);
                break;
              }
            }
            if (!exist) {
              model.value.push(file);
            }
          }
        } else {
          model.value = Array.from(files);
        }
        // emit("change", files);
      } else {
        const file = files[0];
        if (!isFileAccepted(file)) return;
        //prevent model change with same file
        if (!sameFile(file, model.value)) {
          model.value = file;
        }
      }
      if (modelValue !== model.value) {
        emit("change", model.value);
      }
      input.value.value = "";
    }
    function isFileAccepted(file) {
      const accepts = props.acceptMime;

      if (!accepts || !accepts.length) return true;
      const mime = file.type;

      const accept = accepts.indexOf(mime.toLowerCase()) >= 0;

      if (!accept)
        hive.toastWarning(`File type not accepted: "${mime}" - ${file.name}`);
      return accept;
    }
    function change() {
      handleFiles(input.value.files);
    }
    const triggerInput = () => {
      input.value.click();
    };
    const isOver = ref(false);
    function preventDefault(e) {
      e.stopPropagation();
      e.preventDefault();
    }
    function dragEnter(e) {
      preventDefault(e);
    }
    function dragOver(e) {
      preventDefault(e);
      isOver.value = true;
    }
    function drop(e) {
      //   console.log(e);
      preventDefault(e);
      isOver.value = false;
      const dt = e.dataTransfer;
      const files = dt.files;
      // file.value = files[0];
      // uploader.upload(files[0]);
      handleFiles(files);
      //   console.log(files);
    }
    return {
      change,
      triggerInput,
      dragEnter,
      dragOver,
      drop,
      isOver,
    };
  },
};
</script>

<style scoped></style>
