<template>
  <layout-three-column
    full-card-first-col
    module-name="Leads"
    :page-name="name"
    @backToSummary="leadsState.currentLeadId = undefined"
  >
    <template v-slot:module-title>
      <div class="tp-title-faded">Leads</div>
    </template>
    <template v-slot:col1>
      <hi-tab-group v-model="currentTab" class="h-full">
        <hi-tab label="Active" class="h-full pt-1"
          ><leads-active-list class="h-full"
        /></hi-tab>
        <hi-tab label="Closed" class="h-full pt-1">
          <leads-closed-list class="h-full" />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:col2 v-if="leadsState.currentLeadId">
      <hi-card class="p-4">
        <leads-title-card />
      </hi-card>
      <leads-account-info-card />
      <activity-tools-card
        :pipe-id="pipeId"
        target="leads"
        :target-id="leadsState.currentLeadId"
        :main-owner="mainOwner"
      />
      <hi-card class="p-4">
        <leads-tools-card />
      </hi-card>

      <hi-card class="grow" />
    </template>
    <template v-slot:col3 v-if="leadsState.currentLeadId">
      <hi-tab-group class="h-full">
        <hi-tab label="Activity ">
          <activity-list class="h-full overflow-auto" />
        </hi-tab>
        <hi-tab label="History">
          <history-viewer
            class="h-full overflow-auto"
            target="leads"
            :target-id="leadsState.currentLeadId"
          />
        </hi-tab>
        <hi-tab label="Emails">
          <gmail-relative-mails-common-viewer
            class="h-full overflow-auto"
            :email="email"
            :pipeId="pipeId"
            target="leads"
            :target-id="leadsState.currentLeadId"
          />
        </hi-tab>
      </hi-tab-group>
    </template>
    <template v-slot:summary>
      <basic-leads-num-card class="w-1/2" />
      <my-leads-num-card class="w-1/2" />
      <leads-num-by-user
        v-if="permission.admin || permission.maintenance"
        class="w-1/2"
      />
    </template>
  </layout-three-column>
</template>

<script>
import LayoutThreeColumn from "@/hive-vue3/modules/_common/LayoutThreeColumn";
import HiTabGroup from "@/hive-vue3/components/HiTabGroup";
import HiTab from "@/hive-vue3/components/HiTab";
import LeadsActiveList from "@/hive-vue3/modules/leads/LeadsActiveList";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import HiCard from "@/hive-vue3/components/HiCard";
import LeadsTitleCard from "@/hive-vue3/modules/leads/LeadsTitleCard";
import LeadsClosedList from "@/hive-vue3/modules/leads/LeadsClosedList";
import LeadsToolsCard from "@/hive-vue3/modules/leads/LeadsToolsCard";
import { computed, ref, watch, watchEffect } from "vue";
import {
  getLeadById,
  getLeadPipeIdById,
  getPeopleEmailByLeadId,
  getPeopleNameByLeadId,
} from "@/hive-vue3/modules/leads/data";
import { setRouterId } from "@/hive-vue3/modules/_common/setRouterWithId";
import ActivityToolsCard from "@/hive-vue3/modules/activities/components/ActivityToolsCard";
import HistoryViewer from "@/hive-vue3/modules/history/HistoryViewer";
import GmailRelativeMailsCommonViewer from "@/hive-vue3/modules/gmail/components/GmailRelativeMailsCommonViewer";
import LeadsAccountInfoCard from "@/hive-vue3/modules/leads/LeadsAccountInfoCard";
import { provideActivityData } from "@/hive-vue3/modules/activities/data";
import ActivityList from "@/hive-vue3/modules/activities/ActivityList";
import { useRouter } from "vue-router";
import BasicLeadsNumCard from "@/hive-vue3/modules/leads/dashboardCards/BasicLeadsNumCard";
import LeadsNumByUser from "@/hive-vue3/modules/leads/dashboardCards/LeadsNumByUser";
import MyLeadsNumCard from "@/hive-vue3/modules/leads/dashboardCards/MyLeadsNumCard";
import { useCurrentFirebaseUserPermissions } from "@/hive-vue3/firebase";
// import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default {
  name: "LeadsViewFullKit",
  components: {
    MyLeadsNumCard,
    LeadsNumByUser,
    BasicLeadsNumCard,
    ActivityList,
    LeadsAccountInfoCard,
    GmailRelativeMailsCommonViewer,
    HistoryViewer,
    // ActivityViewer,
    ActivityToolsCard,
    LeadsToolsCard,
    LeadsClosedList,
    LeadsTitleCard,
    HiCard,
    LeadsActiveList,
    HiTab,
    HiTabGroup,
    LayoutThreeColumn,
  },
  props: {
    urlId: String,
  },
  setup(props) {
    setRouterId(props, leadsState, "currentLeadId", "/leads/");

    const pipeId = computed(() => {
      if (leadsState.currentLeadId) {
        return getLeadPipeIdById(leadsState.currentLeadId);
      }
      return "";
    });

    const mainOwner = computed(() => {
      const res = getLeadById(leadsState.currentLeadId);
      if (res) {
        return res.mainOwner;
      }
      return undefined;
    });

    const currentTab = ref("Active");
    watchEffect(() => {
      if (leadsState.currentLeadId) {
        const res = getLeadById(leadsState.currentLeadId);
        if (res) {
          if (res.status !== "closed") {
            currentTab.value = "Active";
          } else {
            currentTab.value = "Closed";
          }
        } else {
          currentTab.value = "Active";
        }
      } else {
        currentTab.value = "Active";
      }
    });

    provideActivityData(pipeId);

    const router = useRouter();
    watch(
      () => leadsState.currentLeadId,
      (v) => {
        if (v) {
          router.push("/leads/" + v);
        } else {
          router.push("/leads");
        }
      }
    );

    return {
      leadsState,
      pipeId,
      currentTab,

      email: computed(() => {
        if (leadsState.currentLeadId) {
          return getPeopleEmailByLeadId(leadsState.currentLeadId);
        }
        return "";
      }),
      name: computed(() => {
        if (leadsState.currentLeadId) {
          return getPeopleNameByLeadId(leadsState.currentLeadId);
        }
        return undefined;
      }),
      permission: useCurrentFirebaseUserPermissions(),
      mainOwner,
    };
  },
};
</script>

<style scoped></style>
