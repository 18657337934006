<template>
  <hi-chips-selectable
    :items="items"
    secondary-text-key="secVal"
    :mandatory="mandatory"
    :multiple="multiple"
    :form-key="formKey"
    v-model="model"
    class="font-bold"
  />
</template>

<script>
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { ref } from "vue";
import {
  formatContactsPeopleName,
  getContactsPeopleInEntity,
  useContactsPositions,
} from "@/hive-vue3/modules/contacts/data";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import formControl from "@/hive-vue3/components/form/logics/formControl";
export default {
  name: "MfcContactsPeopleInEntitySelector",
  components: { HiChipsSelectable },
  props: {
    entityId: String,
    modelValue: String,
    mandatory: Boolean,
    multiple: Boolean,
    formKey: String,
  },
  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;
    const items = ref();
    const allPositions = useContactsPositions();
    watchPropsImmediately(props, "entityId", (entityId) => {
      model.value = undefined;
      const arr = [];
      const p = getContactsPeopleInEntity(entityId);
      // const entity = getContactsEntity(entityId);
      if (!p || !p.length) return arr;
      for (let i = 0; i < p.length; i++) {
        const people = p[i];
        const item = {};
        item.text = formatContactsPeopleName(people);
        const positions = people.entityPositions;

        if (positions && positions[entityId]) {
          // console.log(positions[entityId]);
          item.secVal = allPositions[positions[entityId]];
        }
        item.value = people.id;
        arr.push(item);
      }
      // console.log(arr);
      items.value = arr;
    });
    return {
      items,
      model,
    };
  },
};
</script>

<style scoped></style>
