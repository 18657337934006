<template>
  <hi-form-hive-standard
    class="p-4"
    :submit-fn="submit"
    cancel-button
    :initial-values="initialValues"
    :submit-text="additionalData.saveText"
    @changed="(v) => (quotationState.isUnSaved = v)"
  >
    <hi-form-value-holder form-key="id" />
    <hi-form-value-holder form-key="status" />
    <hi-form-value-holder form-key="revisionId" />
    <hi-form-item label="Quote No. *">
      <div v-if="!(initialValues && initialValues.quoteNum)">Pending ...</div>
      <div v-else>
        {{
          getQuotationIdStr(initialValues.quoteNum, initialValues.revisionId)
        }}
      </div>
    </hi-form-item>
    <hi-form-item label="Attention *">
      <mfc-input-contacts-people
        mandatory
        v-model="additionalData.attention"
        form-key="attention"
      />
    </hi-form-item>
    <hi-form-item label="StakeHolders">
      <hi-chips-selectable
        v-model="additionalData.attention"
        :items="additionalData.stakeHolders"
        form-key="attention"
      />
    </hi-form-item>
    <hi-form-item label="Entity">
      <mfc-contacts-entities-by-people-selector
        form-key="client"
        :people-id="additionalData.attention"
      />
    </hi-form-item>
    <hi-form-item>
      <div>
        <hi-check form-key="legalName">Use Company Legal Name</hi-check>
      </div>
    </hi-form-item>
    <hi-form-item label="Project *">
      <mfc-project-selector form-key="projectId" mandatory />
    </hi-form-item>
    <hi-form-item label="Date *">
      <hi-date-picker disable-time form-key="dateCreated" />
    </hi-form-item>

    <div
      class="flex uppercase font-bold text-gray-500 text-sm border-b border-gray-500 gap-2 mb-4 items-center mt-2"
    >
      <div class="grow">Item</div>
      <div class="w-[90px] grow-0 shrink-0 text-right">Quantity</div>
      <div class="w-[120px] grow-0 shrink-0 text-right">Rate</div>
      <div class="w-[90px] grow-0 shrink-0 text-right">Amount</div>
      <div class="w-[50px] text-center">
        <hi-icon
          :path="icons.addItem"
          class="clickable"
          @click="
            additionalData.items.push({
              item: null,
              quantity: null,
              rate: null,
            })
          "
        />
      </div>
    </div>
    <hi-form-value-holder-array
      :validator="
        () => {
          return true;
        }
      "
      form-key="items"
      v-model="additionalData.items"
    />

    <quotation-editor-line
      v-for="(line, index) in additionalData.items"
      :key="'line' + index"
      v-model="additionalData.items[index]"
      @delete="additionalData.items.splice(index, 1)"
    />

    <hi-form-item label="Deposit Rate *">
      <hi-input form-key="depositRate" mandatory integer />
    </hi-form-item>
    <hi-form-item label="Deposit Condition *">
      <hi-select
        :items="{
          ['completion of works.']: 'completion of works.',
          ['prior to the delivery.']: 'prior to the delivery.',
        }"
        mandatory
        form-key="depositCondition"
      />
    </hi-form-item>
    <hi-form-item label="Valid Until *">
      <hi-date-picker mandatory disable-time text-input form-key="validUntil" />
    </hi-form-item>
  </hi-form-hive-standard>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import MfcContactsEntitiesByPeopleSelector from "@/hive-vue3/modules/contacts/controls/MfcContactsEntitiesByPeopleSelector";
import MfcProjectSelector from "@/hive-vue3/modules/projects/controls/MfcProjectSelector";
import HiDatePicker from "@/hive-vue3/components/form/controls/HiDatePicker";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiIcon from "@/hive-vue3/components/HiIcon";
import QuotationEditorLine from "@/hive-vue3/modules/quotation/parts/QuotationEditorLine";

import { mdiPencilMinus, mdiPencilPlus } from "@mdi/js";
import { computed, reactive } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import HiFormValueHolderArray from "@/hive-vue3/components/form/controls/HiFormValueHolderArray";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import {
  getContactsEntityNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import {
  getQuotationIdStr,
  getQuotationLastRevisionId,
  getQuotationNum,
  saveQuotationToFirebase,
} from "@/hive-vue3/modules/quotation/data";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
import HiCheck from "@/hive-vue3/components/form/controls/HiCheck";
import { quotationState } from "@/hive-vue3/modules/quotation/state";

export default {
  name: "QuotationEditorForm",
  components: {
    HiCheck,
    HiFormValueHolder,
    HiFormValueHolderArray,
    QuotationEditorLine,
    HiIcon,
    HiSelect,
    HiDatePicker,
    MfcProjectSelector,
    MfcContactsEntitiesByPeopleSelector,
    HiChipsSelectable,
    MfcInputContactsPeople,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
  },
  props: {
    initialValues: Object,
  },
  setup(props) {
    const from = {};
    const additionalData = reactive({});
    additionalData.saveText = computed(() => {
      const str =
        props.initialValues && props.initialValues.status === "send"
          ? `As REV-${String.fromCharCode(
              64 + getQuotationLastRevisionId(props.initialValues.id) + 1
            )}`
          : "";

      return "Save" + str;
    });

    watchPropsImmediately(props, "initialValues", (v) => {
      if (v) {
        const relativeContacts = getProjectById(v.projectId).contacts;
        if (relativeContacts && relativeContacts.length) {
          const arr = [];
          for (const index in relativeContacts) {
            if (relativeContacts[index].peopleId) {
              const item = {};
              if (relativeContacts[index].entityId) {
                item.text =
                  getContactsFullNameById(relativeContacts[index].peopleId) +
                  " @ " +
                  getContactsEntityNameById(relativeContacts[index].entityId);
                item.entityId = relativeContacts[index].entityId;
              } else {
                item.text = getContactsFullNameById(
                  relativeContacts[index].peopleId
                );
              }

              item.value = relativeContacts[index].peopleId;
              arr.push(item);
            }
          }
          additionalData.stakeHolders = arr;
        } else {
          additionalData.stakeHolders = null;
        }
      }
      if (v && v.items && v.items.length) {
        additionalData.items = JSON.parse(JSON.stringify(v.items));
      } else {
        additionalData.items = [
          {
            item: null,
            quantity: null,
            rate: null,
          },
          {
            item: null,
            quantity: null,
            rate: null,
          },
          {
            item: null,
            quantity: null,
            rate: null,
          },
        ];
      }
    });
    const icons = {
      addItem: mdiPencilPlus,
      deleteItem: mdiPencilMinus,
    };

    async function submit(formData) {
      if (!formData.quoteNum) {
        if (props.initialValues && props.initialValues.quoteNum) {
          formData.quoteNum = props.initialValues.quoteNum;
        } else {
          formData.quoteNum = await getQuotationNum();
        }
      }

      const formHeader = {};
      formHeader.client = formData.client || null;
      formHeader.projectId = formData.projectId;
      formHeader.attention = formData.attention;
      formHeader.quoteNum = formData.quoteNum;
      formHeader.legalName = formData.legalName;

      const formBody = {};
      formBody.dateCreated = formData.dateCreated;
      formBody.validUntil = formData.validUntil;
      formBody.depositRate = formData.depositRate;
      formBody.depositCondition = formData.depositCondition;
      formBody.items = formData.items;
      formBody.status = "draft";
      if (formData.status === "send") {
        formData.revisionId = (
          getQuotationLastRevisionId(formData.id) + 1
        ).toString();
      }

      if (formData.revisionId) {
        quotationState.currentQuotationId = await saveQuotationToFirebase(
          formHeader,
          formBody,
          formData.id,
          formData.revisionId
        );
        quotationState.revisionId = formData.revisionId;
      } else {
        quotationState.currentQuotationId = await saveQuotationToFirebase(
          formHeader,
          formBody,
          formData.id
        );
        quotationState.revisionId = "0";
      }
      return { successful: true };
    }
    return {
      submit,
      additionalData,
      icons,
      from,
      getQuotationIdStr,
      console,
      quotationState,
    };
  },
};
</script>

<style scoped></style>
