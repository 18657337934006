import { v1 } from "uuid";

export function pipeIdGenerator() {
  return v1();
}

function isDef(v) {
  return v !== undefined && v !== null;
}

export function arrayUnique(initialArr) {
  const arr = initialArr.filter(isDef);
  return Array.from(new Set(arr));
}
