import { onMounted, onUnmounted } from "vue";
import $el from "../dom/$el";

/**
 * @deprecated use utils/keyboard/shortcuts instead
 * @param element
 * @param enter
 * @param escape
 * @param ctrlEnter
 * @param shiftEnter
 * @param event
 * @param focusOnly
 * @param preventDefault
 * @returns {{shiftEnter: null, enter: null, ctrlEnter: null, escape: null}}
 */
export default function keyPress(
  element,
  { enter = null, escape = null, ctrlEnter = null, shiftEnter = null } = {},
  { event = "keydown", focusOnly = false, preventDefault = true } = {}
) {
  const map = {
    enter,
    escape,
    ctrlEnter,
    shiftEnter,
  };
  function prevent(e) {
    if (preventDefault) e.preventDefault();
  }
  function run(func, e) {
    if (typeof func === "function") func(e);
  }
  function onPress(e) {
    // console.log(e);
    const code = e.keyCode;
    // enter ////////////////////
    if (code === 13 && (map.enter || map.ctrlEnter || map.shiftEnter)) {
      if (map.ctrlEnter && e.ctrlKey) {
        prevent(e);
        run(map.ctrlEnter, e);
      } else if (map.shiftEnter && e.shiftKey) {
        prevent(e);
        run(map.shiftEnter, e);
      } else if (map.enter) {
        prevent(e);
        run(map.enter, e);
      }
      // escape ///////////////
    } else if (code === 27 && map.escape) {
      prevent(e);
      run(map.escape, e);
    }
  }

  function listen() {
    element.addEventListener(event, onPress);
  }
  function unListen() {
    element.removeEventListener(event, onPress);
  }
  onMounted(() => {
    element = $el(element);
    if (focusOnly) {
      element.addEventListener("focus", listen);
      element.addEventListener("blur", unListen);
    } else {
      listen();
    }
  });

  onUnmounted(unListen);
  return map;
}
