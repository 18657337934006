<template>
  <div style="min-height: 520px" class="relative">
    <hi-search-panel
      placeholder="Type in Australian business name or ABN..."
      :search-fn="searchAbn"
      class="h-full"
      item-height="40px"
    >
      <template v-slot="{ item }">
        <div class="text-sm flex w-full" @click="onClick(item)">
          <div class="grow">{{ item.name }}</div>
          <div class="grow-0 shrink-0 text-xs text-gray-400 italic">
            {{ item.state }} | {{ item.postcode }} | abn {{ item.abn }}
          </div>
        </div>
      </template>
    </hi-search-panel>
  </div>
</template>

<script>
// import { lazyFunction } from "@/hive-vue3/utils/functionUtils";
import { requestAbnByName } from "@/hive-vue3/api/request";
import HiSearchPanel from "@/hive-vue3/components/HiSearchPanel";
export default {
  name: "HiAuBusinessSearch",
  components: { HiSearchPanel },
  emits: ["select"],
  setup(props, { emit }) {
    function onClick(item) {
      emit("select", item);
    }
    return {
      async searchAbn(abn) {
        const res = await requestAbnByName(abn, 12);
        // console.log(res);
        return res;
      },
      onClick,
    };
  },
};
</script>

<style scoped></style>
