<template>
  <Transition name="hive-fade-down-css">
    <slot />
  </Transition>
</template>

<script>
export default {
  name: "TransitionFadeDownCss",
};
</script>

<style>
.hive-fade-down-css-enter-active,
.hive-fade-down-css-leave-active {
  transition: opacity 0.5s ease, transform 0.4s ease-out;
}
.hive-fade-down-css-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.hive-fade-down-css-enter-from,
.hive-fade-down-css-leave-to {
  opacity: 0;
  transform: translateY(-3%);
}
</style>
