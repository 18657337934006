<template>
  <headless-file-input
    droppable
    class=""
    v-model="inputFiles"
    :accept-mime="computedMime"
    ref="fileInput"
    multiple
  >
    <div class="__hi-fire-file-drop-multiple w-full h-full">
      <div
        v-for="hash in allHashes"
        :key="hash"
        class="__hi-fire-file-drop-multiple-item"
        :style="{ 'min-width': itemWidth, height: itemHeight }"
      >
        <transition-fade>
          <private-vault-uploader
            class="w-full h-full"
            v-if="uploadingDict[hash]"
            :file="uploadingDict[hash]"
            @completed="uploadCompleted(hash)"
            @error="cancel(hash)"
            @cancel="cancel(hash)"
            :hash="hash"
          />
          <private-vault-image
            v-else-if="asImage"
            :model-value="hash"
            class="w-full h-full"
            @delete="deleteFile(hash)"
            @replace="triggerInput"
            :cover="imageDisplayCover"
          />
          <hi-vault-file
            class="w-full h-full"
            v-else
            can-delete
            can-replace
            @delete="deleteFile(hash)"
            @replace="triggerInput"
            :file-id="hash"
          />
        </transition-fade>
      </div>

      <center-box
        class="__hi-fire-file-drop-multiple-item uppercase"
        :style="{ width: itemWidth, height: itemHeight }"
      >
        <private-upload-button @click="triggerInput" />
      </center-box>
    </div>
  </headless-file-input>
</template>

<script>
import HeadlessFileInput from "@/hive-vue3/components/headless/HeadlessFileInput";
import { templateRef } from "@vueuse/core/index";

import { mdiUpload } from "@mdi/js";
import HiVaultFile from "@/hive-vue3/components/firebase/HiVaultFile";
import { arrayRemove } from "@/hive-vue3/utils/arrayUtils";
import { computed, nextTick, reactive, ref, watch } from "vue";
import { fileMd5 } from "@/hive-vue3/utils/browser-md5";
import CenterBox from "@/hive-vue3/layouts/CenterBox";
import PrivateVaultUploader from "./_private-file-vault-components/privateVaultUploader";
import PrivateUploadButton from "./_private-file-vault-components/PrivateUploadButton";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import hive from "@/hive-vue3/components/hive";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import mime from "@/hive-vue3/utils/constables/mime";
import PrivateVaultImage from "@/hive-vue3/components/firebase/_private-file-vault-components/PrivateVaultImage";
export default {
  name: "HiVaultFileDropMultiple",
  components: {
    PrivateVaultImage,
    TransitionFade,
    PrivateUploadButton,
    CenterBox,
    PrivateVaultUploader,
    HiVaultFile,
    HeadlessFileInput,
  },
  props: {
    acceptMime: {
      type: Array,
    },
    modelValue: Array,
    asImage: Boolean,
    imageDisplayCover: Boolean,
    itemWidth: String,
    itemHeight: String,
  },
  emits: ["update:modelValue", "upload", "completed"],
  setup(props, { emit }) {
    const model = modelRef(props, emit);

    const inputFiles = ref(null);
    // const uploadingFiles = reactive({});

    const allHashes = reactive([]);
    const uploadingArr = [];
    const uploadingDict = reactive({});
    // watch(
    //   value,
    //   (hashes) => {
    //     allHashes.splice(0, allHashes.length);
    //     if (hashes && hashes.length) {
    //       allHashes.push(...hashes);
    //       // valueCache = [];
    //     }
    //     allHashes.push(...uploadingArr);
    //   },
    //   { immediate: true }
    // );

    const fileInput = templateRef("fileInput");
    watch(inputFiles, async (files) => {
      // console.log(files);
      const existFiles = [];
      if (files && files.length) {
        // const arr = value.value;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const hash = await fileMd5(file);
          let exists = allHashes.indexOf(hash) >= 0;
          if (exists) {
            existFiles.push(file);
          } else {
            allHashes.push(hash);
            uploadingArr.push(hash);
            uploadingDict[hash] = file;
          }
          //每帧 hash 一个 file，避免页面卡顿
          await nextTick();
        }
        // console.log("existing", existFiles);
        if (existFiles.length) {
          const s = existFiles.length > 1;
          let warning = `Selected File${s ? "s" : ""} Already exist${
            !s ? "s" : ""
          }: `;
          for (let i = 0; i < existFiles.length; i++) {
            const file = existFiles[i];
            warning += " [" + file.name + "]";
          }
          hive.toastWarning(warning);
        }
        inputFiles.value = null;
      }
      if (uploadingArr.length) {
        emit("upload");
      }
    });
    function modelRemove(hash) {
      if (!model.value) return;
      arrayRemove(model.value, hash);
      if (!model.value.length) model.value = undefined;
    }
    function modelPush(hash) {
      if (!model.value) model.value = [];
      model.value.push(hash);
    }
    function uploadCompleted(hash) {
      arrayRemove(uploadingArr, hash);
      delete uploadingDict[hash];
      modelPush(hash);
      checkCompleted();
    }
    function deleteFile(hash) {
      modelRemove(hash);
      arrayRemove(allHashes, hash);
    }
    function cancel(hash) {
      delete uploadingDict[hash];
      arrayRemove(allHashes, hash);
      arrayRemove(uploadingArr, hash);
      modelRemove(hash);
      checkCompleted();
      // delete uploadingFiles[hash];
    }

    function triggerInput() {
      fileInput.value.triggerInput();
    }
    function checkCompleted() {
      if (!uploadingArr.length) {
        emit("completed");
      }
    }
    const computedMime = computed(() => {
      if (props.acceptMime) return props.acceptMime;
      else if (props.asImage) return [mime.ImagePng, mime.ImageJpeg];
      else return undefined;
    });
    return {
      inputFiles,
      // uploadingFiles,
      mdiUpload,
      uploadCompleted,
      deleteFile,
      triggerInput,
      cancel,
      allHashes,
      uploadingDict,
      computedMime,
    };
  },
};
</script>

<style scoped>
.__hi-fire-file-drop-multiple {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.__hi-fire-file-drop-multiple-item {
  /*min-width: 180px;*/
  /*max-width: 240px;*/
  /*height: 150px;*/
  /*flex-grow: 1;*/
  /*flex-shrink: 1;*/
  /*flex-basis: 0;*/
}
</style>
