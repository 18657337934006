<template>
  <hi-simple-remote-request
    :param="abn"
    :request-fn="requestNameByAbn"
    class="p-4"
    v-model="result"
  >
    <template v-slot="{ result }" v-if="result">
      <div class="text-lg font-bold">{{ result.name }}</div>
      <div class="text-xs font-bold mb-2">
        {{ result.stateCode }} {{ result.postcode }}
      </div>
      <div class="text-xs">
        <span class="buzCap">abn:</span>{{ result.abn }}
      </div>
      <div class="text-xs">
        <span class="buzCap">type:</span>{{ result.entityTypeDescription }}
      </div>
      <div class="text-xs">
        <span class="buzCap">Effective From:</span>{{ result.effectiveFrom }}
      </div>
      <div class="text-xs">
        <span class="buzCap">GST Registered:</span
        >{{ result.gstEffectiveFrom || "N/A" }}
      </div>
      <div class="text-xs" v-if="result.asicNumber && result.asicNumber.length">
        <span class="buzCap">ASIC Number:</span>{{ result.asicNumber || "N/A" }}
      </div>
    </template>
  </hi-simple-remote-request>
</template>

<script>
// import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { requestNameByAbn } from "@/hive-vue3/api/request";
import HiSimpleRemoteRequest from "@/hive-vue3/components/remoteRequests/HiSimpleRemoteRequest";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";

export default {
  name: "HiAuBusinessDetails",
  components: { HiSimpleRemoteRequest },
  props: {
    abn: {
      type: String,
    },
    modelValue: Object,
  },
  setup(props, { emit }) {
    const result = modelRef(props, emit);
    // watchPropsImmediately(props, "abn", async (abn) => {
    //   console.log("looking", abn);
    //   const re = await requestNameByAbn(abn);
    //   console.log(re);
    // });
    return {
      result,
      requestNameByAbn,
    };
  },
};
</script>

<style scoped>
.buzCap {
  @apply uppercase inline-block;
  width: 100px;
  margin-right: 5px;
  font-size: 9px;
}
</style>
