<template>
  <hi-dialog title="Close Lead" width="600px" v-model="open">
    <div class="font-bold my-4">
      Please confirm to close the following lead.
    </div>
    <leads-list-summary class="p-2" :lead-id="leadsState.currentLeadId" />

    <hi-form-hive-standard
      :submit-fn="submit"
      @cancel="open = false"
      @successful="open = false"
    >
      <hi-form-value-holder
        form-key="closedBy"
        :model-value="getCurrentFirebaseUserId()"
      />
      <hi-form-value-holder form-key="isConverted" :model-value="false" />

      <div class="font-bold mt-4">Please write the reason.</div>
      <hi-textarea :rows="3" form-key="reason" />
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import LeadsListSummary from "@/hive-vue3/modules/leads/LeadsListSummary";
import { computed, ref } from "vue";
import { closeLead, getLeadById } from "@/hive-vue3/modules/leads/data";
import { leadsState } from "@/hive-vue3/modules/leads/state";
import HiFormValueHolder from "@/hive-vue3/components/form/controls/HiFormValueHolder";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { getCurrentFirebaseUserId } from "@/hive-vue3/firebase";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
export default {
  name: "LeadsCloseLeadDialog",
  components: {
    HiFormHiveStandard,
    HiTextarea,
    HiFormValueHolder,
    LeadsListSummary,
    HiDialog,
  },
  setup() {
    const open = ref(false);
    const lead = computed(() => getLeadById(leadsState.currentLeadId));

    async function submit(data) {
      await closeLead(leadsState.currentLeadId, data);
      return {
        successful: true,
      };
    }

    return {
      lead,
      open,
      submit,
      leadsState,
      getCurrentFirebaseUserId,
    };
  },
};
</script>

<style scoped></style>
