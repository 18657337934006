<template>
  <hi-chips-selectable
    :items="items"
    secondary-text-key="secVal"
    :mandatory="mandatory"
    :multiple="multiple"
    :form-key="formKey"
    v-model="model"
    class="font-bold"
  />
</template>

<script>
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { computed } from "vue";
import {
  computedPeopleByIdOrData,
  getContactsEntity,
  useContactsPositions,
} from "@/hive-vue3/modules/contacts/data";
import { watchProps } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import formControl from "@/hive-vue3/components/form/logics/formControl";
export default {
  name: "MfcContactsEntitiesByPeopleSelector",
  components: { HiChipsSelectable },
  props: {
    peopleId: String,
    modelValue: String,
    mandatory: Boolean,
    multiple: Boolean,
    formKey: String,
  },
  setup(props, context) {
    const control = formControl(props, context);
    const model = control.model;
    const allPositions = useContactsPositions();
    const people = computedPeopleByIdOrData(props);
    watchProps(props, "peopleId", () => (model.value = undefined));
    const items = computed(() => {
      const p = people.value;
      const arr = [];
      const entities = p.entities;
      if (!entities || !entities.length) return arr;
      for (let i = 0; i < entities.length; i++) {
        const entityId = entities[i];
        //todo: Failed when loading
        const entity = getContactsEntity(entityId);
        const item = {};
        // console.log(entity);
        item.value = entityId;
        if (entity.tradingName) {
          item.text = entity.name + " - " + entity.tradingName;
        } else {
          item.text = entity.name;
        }
        const positions = p.entityPositions;

        if (positions && positions[entityId]) {
          item.secVal = allPositions[positions[entityId]];
        }
        arr.push(item);
      }
      return arr;
    });
    return {
      items,
      model,
    };
  },
};
</script>

<style scoped></style>
