<template>
  <hi-data-list-kit
    :sortables="sortRules"
    @select="onSelect"
    :current-id="edmState.currentEdmId"
  >
    <template v-slot="{ item }">
      <div class="tp-subtitle flex items-center font-bold">
        {{ item.subject }}
        {{
          item.status === "sent"
            ? `sent (${item.delivered}/${item.total})`
            : item.status
        }}
      </div>

      <div class="tp-description-sm">
        Last Update:
        <hi-date-time-display :model-value="item.__dateUpdated" friendly />
      </div>
      <div class="tp-description-sm">
        Created by: <user-details-span :user-id="item.__createdBy" /> at
        <hi-date-time-display :model-value="item.__dateCreated" friendly />
      </div>
    </template>
  </hi-data-list-kit>
</template>

<script>
import HiDataListKit from "@/hive-vue3/components/dataDisplay/HiDataListKit";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import dataListSortingTypes from "@/hive-vue3/components/dataDisplay/logics/dataListSortingTypes";
import { edmState } from "@/hive-vue3/modules/edm/state.js";
import HiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
export default {
  name: "EdmCampaignList",
  components: { UserDetailsSpan, HiDateTimeDisplay, HiDataListKit },
  setup() {
    const campaigns = useFirestoreCollectionAsRef("/edm/campaigns/registers");
    const activeProjectListStore = createDataListStore(campaigns);
    provideDataListStore(activeProjectListStore);

    const sortRules = [
      {
        key: "__dateCreated",
        text: "Date",
        type: dataListSortingTypes.DATE,
        desc: true,
        DEFAULT: true,
      },
    ];

    async function onSelect(item) {
      edmState.currentEdmId = item.id;
      edmState.currentHtml = item.template;
    }

    return {
      campaigns,
      sortRules,
      edmState,
      onSelect,
    };
  },
};
</script>

<style scoped></style>
