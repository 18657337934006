<template>
  <div v-if="entity" class="view-space-divided">
    <div class="flex">
      <div class="view-block-spacious grow">
        <contacts-entity
          class="tp-big-fancy-display pb-3"
          :entity-data="entity"
        />
        <hi-tags
          small
          v-if="entity.tags"
          :model-value="entity.tags"
          :tag-doc-path="dbPaths.entityTagDoc"
        >
        </hi-tags>
      </div>
      <hi-icon
        class="clickable-icon"
        :path="mdiOpenInNew"
        title="Link entity"
        @click="jump(entity.id)"
      />
    </div>
    <contacts-entity-contact-info
      class="view-block-spacious"
      :entity-data="entity"
    />
    <contacts-people-in-entity-view :entity-id="entity.id" />
  </div>
</template>

<script>
import {
  computedEntityByIdOrData,
  jumpToEntityByEntityId,
} from "@/hive-vue3/modules/contacts/data";
import HiTags from "@/hive-vue3/components/HiTags";
import dbPaths from "./dbPaths";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import ContactsEntityContactInfo from "@/hive-vue3/modules/contacts/ContactsEntityContactInfo";
import ContactsPeopleInEntityView from "@/hive-vue3/modules/contacts/ContactsPeopleInEntityView";
import { mdiOpenInNew } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { useRouter } from "vue-router";
export default {
  name: "ContactsEntityOverview",
  components: {
    HiIcon,
    ContactsPeopleInEntityView,
    ContactsEntityContactInfo,
    ContactsEntity,
    HiTags,
  },
  props: {
    entityId: String,
    entityData: Object,
  },
  setup(props) {
    const router = useRouter();
    function jump(entityId) {
      jumpToEntityByEntityId(router, entityId);
    }

    return {
      entity: computedEntityByIdOrData(props),
      dbPaths,
      mdiOpenInNew,
      jump,
    };
  },
};
</script>

<style scoped></style>
