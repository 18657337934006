import { createApp, h } from "vue";

/**
 *
 * @param componentClass
 * @param props
 * @param children
 * @returns {App<Element>|(function(): VNode<RendererNode, RendererElement, {[p: string]: any}>)}
 */
export function createComponent(componentClass, props = null, children = null) {
  /*
  below code to prevent vue warning.
   */
  if (typeof children === "string") {
    const text = children;
    children = () => text;
  }
  return createApp({
    setup() {
      const vNode = h(componentClass, props, children);
      return () => vNode;
    },
  });
}

/**
 * 检查 props 给定的多个值只能有一个是 true
 * @param props
 * @param boolKey
 */
export function propsBooleanGroupValuesCheck(props, ...boolKey) {
  const trueValues = [];
  for (const key of boolKey) {
    if (props[key]) trueValues.push(key);
  }
  if (trueValues.length > 1) {
    console.warn(
      `The following values cannot be 'true' at the same time: '
      ${trueValues.join(",")}
      '. These values will be ignored: '
      ${trueValues.slice(1).join(",")}
      '`
    );
  }
}
