<template>
  <hi-form
    :initial-values="originalData"
    class="min-w-[600px]"
    @submit="submit"
  >
    <hi-form-item label="Title">
      <hi-chips-selectable
        :items="{
          Mr: 'Mr.',
          Miss: 'Miss.',
          Mrs: 'Mrs.',
          Ms: 'Ms.',
        }"
        value-key="titleValue"
        text-key="titleName"
        form-key="title"
      />
    </hi-form-item>
    <hi-form-item label="First Name *">
      <hi-input form-key="name" mandatory />
    </hi-form-item>
    <hi-form-item label="Middle Name">
      <hi-input form-key="midName" />
    </hi-form-item>
    <hi-form-item label="Surname">
      <hi-input form-key="surname" />
    </hi-form-item>
    <hi-form-item label="Gender">
      <hi-chips-selectable
        :items="{
          male: 'Male',
          female: 'Female',
          other: 'Other',
        }"
        value-key="genderValue"
        text-key="genderName"
        form-key="gender"
      />
    </hi-form-item>
  </hi-form>
</template>

<script>
import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import dbPaths from "@/hive-vue3/modules/contacts/dbPaths";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
export default {
  name: "ContactsPeopleBasicInfoEditor",
  components: { HiChipsSelectable, HiInput, HiFormItem, HiForm },
  props: {
    originalData: Object,
  },
  emits: ["successful"],
  setup(props, { emit }) {
    async function submit(data) {
      console.log(data);
      await updateFirestoreDoc(
        firestoreDoc(dbPaths.people, props.originalData.id),
        data
      );
      emit("successful");
    }

    return {
      dbPaths,
      submit,
    };
  },
};
</script>

<style scoped></style>
