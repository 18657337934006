<template>
  <div v-if="color" class="w-full flex flex-col gap-2 items-center">
    <hi-vault-image :aspectRatio="1" class="w-full" :file-id="color.img" />
    <div v-if="showTitle" class="font-bold">
      {{ color.name }} ({{ color.shortNum }})
    </div>
    <div v-if="showCollection">{{ color.collection }}</div>
    <div v-if="showDescription" class="w-full break-words">
      {{ color.description }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import HiVaultImage from "@/hive-vue3/components/firebase/HiVaultImage";
import { getColorById } from "@/hive-vue3/modules/products/data";

export default {
  name: "ColorItem",
  components: { HiVaultImage },
  props: {
    colorId: String,
    showTitle: Boolean,
    showCollection: Boolean,
    showDescription: Boolean,
  },
  setup(props) {
    const color = computed(() => {
      return getColorById(props.colorId);
    });
    return { color };
  },
};
</script>

<style scoped></style>
