/**
 * Create top level (highest z-index) div to mount all popups alerts, tooltips & toasts.
 * Components in the sky will not receive pointer events unless css pointer-event set to "auto"
 */

import { setStyle } from "./dom/styles";
import gsap from "gsap";
import $el from "@/hive-vue3/utils/dom/$el";
let sky;

function createSkyIfNotCreated() {
  if (sky) return;

  sky = document.createElement("div");

  setStyle(sky, {
    position: "fixed",
    left: 0,
    top: 0,
    pointerEvents: "none",
    zIndex: 999999999999999,
  });
  document.body.appendChild(sky);
}

export function getSky() {
  createSkyIfNotCreated();
  return sky;
}

/**
 * Create an element and mount to sky
 * @param type  element type. e.g. div, img...
 * @returns {HTMLDivElement}
 */
export function createSkyElement(type = "div") {
  createSkyIfNotCreated();
  const el = document.createElement(type);
  sky.appendChild(el);
  return el;
}

export function appendToSky(element) {
  createSkyIfNotCreated();
  sky.appendChild($el(element));
}

/**
 * mount a vue component to sky.
 * @param component
 * @returns {{container: HTMLDivElement, destroyLater(*=): void, vm: ComponentPublicInstance, destroy(): void, element: any}}
 */
export function mountToSky(component) {
  createSkyIfNotCreated();
  const container = document.createElement("div");
  sky.appendChild(container);
  const vm = component.mount(container);
  return {
    destroy() {
      component.unmount();
      container.remove();
    },
    /**
     * this is to delayed destroy mainly for animations.
     * @param delayMs
     */
    destroyLater(delayMs) {
      setTimeout(() => {
        component.unmount();
        container.remove();
      }, delayMs);
    },
    vm,
    element: vm.$el,
    container,
  };
}

/**
 * Mount a vue component to sky with basic enter animations.
 * @param component
 * @param {('bottom'|'right'|'left'|'top')} location
 * @param offset {Number|String}
 * @returns {{container: HTMLDivElement, destroyLater, (*=): void, vm: ComponentPublicInstance, destroy, (): void, element: *}}
 */
export function mergeToSky(component, location = "bottom", offset = 25) {
  const skyObject = mountToSky(component);
  const el = skyObject.element;
  const style = { opacity: 0, position: "fixed" };
  const to = { opacity: 1 };
  if (typeof offset === "number") offset += "px";
  switch (location.toLowerCase()) {
    case "bottom": {
      style.yPercent = 10;
      style.left = "50%";
      style.bottom = offset;
      style.transform = "translateX(-50%)";
      to.yPercent = 0;
      break;
    }
    case "right": {
      style.xPercent = 10;
      style.right = offset;
      style.top = offset;
      to.xPercent = 0;
      break;
    }
    case "left": {
      style.xPercent = -10;
      to.xPercent = 0;
      style.left = offset;
      style.top = offset;
      break;
    }
    //top
    default: {
      style.yPercent = -10;
      style.top = offset;
      style.left = "50vw";
      style.transform = "translateX(50%)";
      to.yPercent = 0;
    }
  }
  gsap.set(el, style);
  // console.log(style);
  gsap.to(el, to);
  return skyObject;
}
