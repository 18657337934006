<template>
  <hi-dialog v-model="open">
    <hi-div-with-submit-status-container
      submit-button
      cancel-button
      :submit-fn="updateRedirectOrderList"
      @cancel="open = false"
      :submit-enable="submitEnable"
      @successful="open = false"
    >
      <div>Current Redirect Order:</div>
      <div>
        <div
          v-for="(user, index) in redirectOrderList"
          :key="user + index"
          class="my-2 flex gap-4 ml-4"
        >
          <div>{{ index }}</div>
          <div v-if="users[user]">
            <span>{{ users[user].name }}</span>
            <span class="ml-4">
              {{
                users[user].mobile
                  ? users[user].mobile
                  : "No Mobile Number Found"
              }}</span
            >
          </div>
        </div>
      </div>
      <mfc-system-user-selector
        class="mt-5"
        multiple
        v-model="redirectOrderSelectedList"
      />
    </hi-div-with-submit-status-container>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import MfcSystemUserSelector from "@/hive-vue3/modules/users/controls/MfcSystemUserSelector";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { computed, ref } from "vue";
import HiDivWithSubmitStatusContainer from "@/hive-vue3/components/containers/HiDivWithSubmitStatusContainer";
import {
  firestoreDoc,
  updateFirestoreDoc,
  useSystemUsers,
} from "@/hive-vue3/firebase";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { twilioState } from "@/hive-vue3/modules/twilio/state";

export default {
  name: "TwilioChangeRedirectOrderDialog",
  components: {
    HiDivWithSubmitStatusContainer,
    MfcSystemUserSelector,
    HiDialog,
  },
  props: {
    orderIndex: Number,
  },
  setup(props) {
    const phoneSettings = useFirestoreDoc("phone", "settings");
    const open = ref(false);
    const redirectOrderSelectedList = ref([]);
    const users = useSystemUsers();

    watchPropsImmediately(open, "value", (v) => {
      if (v) {
        redirectOrderSelectedList.value =
          phoneSettings[twilioState.currentNumber]["redirectOrders"][
            props.orderIndex.toString()
          ];
      }
    });

    const redirectOrderList = computed(() => {
      if (
        redirectOrderSelectedList.value &&
        redirectOrderSelectedList.value.length <= 3
      ) {
        return redirectOrderSelectedList.value;
      }
      if (
        redirectOrderSelectedList.value &&
        redirectOrderSelectedList.value.length > 3
      ) {
        return redirectOrderSelectedList.value.slice(0, 3);
      }
      return [];
    });

    const submitEnable = computed(() => {
      if (redirectOrderList.value.length) {
        for (const i of redirectOrderList.value) {
          if (!users[i] || !users[i].mobile) {
            return false;
          }
        }
      }
      return true;
    });

    async function updateRedirectOrderList() {
      await updateFirestoreDoc(firestoreDoc("phone", "settings"), {
        [twilioState.currentNumber]: {
          redirectOrders: {
            [props.orderIndex]: redirectOrderList.value,
          },
        },
      });
      return { successful: true };
    }

    return {
      phoneSettings,
      redirectOrderSelectedList,
      redirectOrderList,
      updateRedirectOrderList,
      users,
      open,
      submitEnable,
    };
  },
};
</script>

<style scoped></style>
