<template>
  <div class="flex">
    <!--    <div>{{ peopleId }}</div>-->

    <div class="grow" v-if="people">
      <div class="tp-subtitle flex items-center">
        <div class="ellipse-flex-grow-item font-bold">
          {{ formatContactsPeopleName(people, { title: false }) }}
        </div>
        <hi-tags
          small
          v-if="lead.tags"
          :model-value="lead.tags"
          tag-doc-path="leads/general/settings/leadTags"
        >
        </hi-tags>
      </div>

      <div class="tp-subtitle hi-marker-disabled" v-if="entity">
        <contacts-entity :entity-id="entity">
          <span v-if="people.entityPositions && people.entityPositions[entity]">
            -
            <contacts-entity-position
              :position-id="people.entityPositions[entity]"
            />
          </span>
        </contacts-entity>
      </div>
      <div class="tp-description-sm flex space-x-1">
        <span v-if="people.mobile">M.{{ people.mobile }}</span>
        <span v-if="people.email">E.{{ people.email }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { getContactsPeople } from "@/hive-vue3/modules/contacts/data";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import ContactsEntityPosition from "@/hive-vue3/modules/contacts/ContactsEntityPosition";
import { formatContactsPeopleName } from "@/hive-vue3/modules/contacts/data";
import HiTags from "@/hive-vue3/components/HiTags";
import { getLeadById } from "@/hive-vue3/modules/leads/data";

export default {
  name: "LeadsListSummary",
  components: { HiTags, ContactsEntityPosition, ContactsEntity },
  props: {
    leadId: String,
  },

  setup(props) {
    const lead = computed(() => getLeadById(props.leadId));

    const people = computed(() => {
      if (lead.value.peopleId) {
        return getContactsPeople(lead.value.peopleId);
      }
      return undefined;
    });

    const entity = computed(() => {
      if (lead.value.entityId) {
        return lead.value.entityId;
      }
      return undefined;
    });
    return {
      people,
      entity,
      formatContactsPeopleName,
      lead,
    };
  },
};
</script>

<style scoped></style>
