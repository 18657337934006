<template>
  <div ref="container">
    <hi-image
      class="w-full h-full"
      :src="fileData.thumbUrl || fileData.url"
      :aspect-ratio="aspectRatio"
      :mode="cover ? 'cover' : 'contain'"
      fade-in
    >
      <div
        class="w-full h-full p-2"
        style="transition: background-color 0.5s"
        :style="{
          backgroundColor: fileData.url && mouseOver ? 'rgba(0,0,0,0.5)' : '',
        }"
      >
        <div
          v-if="!fileError && fileData.name && mouseOver"
          class="w-full h-full text-white text-xs flex flex-col"
        >
          <hi-div :line-clamp="2" v-if="fileData && fileData.name">
            <span
              class="bg-current rounded-sm mr-1 px-1 uppercase hi-font-mono"
              style="font-size: 10px"
              ><span class="invert tracking-wide">{{
                fileData.name.split(".").pop()
              }}</span></span
            >
            {{ fileData.name }}
          </hi-div>
          <hi-file-size :file="fileData" class="font-bold text-xs py-2" />
          <private-file-overlay
            class="grow"
            :file="fileData"
            can-upload
            can-delete
            @replace="replace"
            @delete="deleteFile"
            @download="downloadFile"
            text-color="white"
          />
        </div>
        <div class="error text-xs" v-if="fileError">
          <div class="text-center my-3">
            Load file Error with id {{ modelValue }}. Check console for more
            details.
          </div>
          <private-upload-button @click="replace" />
        </div>
      </div>
    </hi-image>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from "vue";

import HiImage from "@/hive-vue3/components/HiImage";
import { templateRef } from "@vueuse/core";
import useMouseOver from "@/hive-vue3/utils/sensors/useMouseOver";
import HiDiv from "@/hive-vue3/components/HiDiv";
import HiFileSize from "@/hive-vue3/components/misc/HiFileSize";
import useFirestoreDocQueryable from "@/hive-vue3/firebase/useFirestoreDocQueryable";
// import { isFirestoreDocExists } from "@/hive-vue3/firebase";
import useKeyModifierControlOrCommand from "@/hive-vue3/utils/sensors/useKeyModifierControlOrCommand";

import { downVaultFile, FILE_VAULT } from "@/hive-vue3/firebase/fileVault";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";
import PrivateFileOverlay from "./PrivateFileOverlay";
import PrivateUploadButton from "./PrivateUploadButton";

export default {
  name: "PrivateVaultImage",
  components: {
    PrivateUploadButton,
    PrivateFileOverlay,
    HiFileSize,
    HiDiv,
    HiImage,
  },
  emits: ["delete", "replace"],
  props: {
    /**
     * the fileId
     */
    modelValue: String,
    aspectRatio: { type: Number },
    cover: Boolean,
  },
  setup(props, { emit }) {
    const fileId = modelRef(props, emit);
    const fileError = ref(false);
    // const dbSrc = ref(null);
    const container = templateRef("container");
    const fileInput = templateRef("fileInput");
    const mouseOver = useMouseOver(container);
    // const fileDataRT = reactive({ name: "", size: 0 });
    const ctrlOrCmd = useKeyModifierControlOrCommand();

    const tooltip = ref("");
    const fileQ = useFirestoreDocQueryable(FILE_VAULT, props.modelValue);

    watch(fileId, async (val) => {
      // console.log("fileId", val);
      fileQ.setId(val);
      fileError.value = false;
    });
    watchEffect(() => {
      if (!fileId.value) return;
      const data = fileQ.data;
      // console.log(data);
      if (fileQ.notFound.value) {
        // console.log(fileQ.notFound);
        console.warn("Cannot find file doc with ID: " + fileId.value + ".");
        fileError.value = true;
      } else if (data.id && !data.url) {
        console.warn("File ID " + fileId.value + " found no url for the file.");
        fileError.value = true;
      } else {
        fileError.value = false;
      }
    });

    function deleteFile() {
      emit("delete", props.modelValue);
    }
    function replace() {
      emit("replace", props.modelValue);
    }
    function downloadFile() {
      downVaultFile(fileId.value);
    }

    return {
      // dbSrc,
      mouseOver,
      // fileData: fileDataRT,
      fileData: fileQ.data,

      fileError,
      tooltip,
      ctrlOrCmd,
      fileInput,
      downloadFile,
      deleteFile,
      replace,
    };
  },
};
</script>

<style scoped></style>
