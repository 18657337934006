const currentDomain = window.location.href.toLowerCase();
export default function (
  domain,
  { path = "/", localDomain = "http://localhost:3000/api" } = {}
) {
  const index = currentDomain.indexOf("localhost");
  if (index > 0 && index < 10) {
    //is local
    return localDomain + path;
  } else {
    //is server
    return domain + path;
  }
}

export function getCurrentDomain() {
  const getUrl = window.location;
  return getUrl.protocol + "//" + getUrl.host;
}
