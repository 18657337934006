<template>
  <hi-dialog v-model="open" width="600px">
    <div class="font-bold">
      Change project status for the following project:
    </div>
    <project-title-card :project-data="projectData" class="w-full my-4" />
    <hi-form-hive-standard
      cancel-button
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      :initial-values="projectData"
    >
      <hi-form-item label="Project Status *">
        <hi-select
          mandatory
          :items="[
            {
              text: 'Sample Request',
              value: 'Sample Request',
            },
            {
              text: 'Builder Tender',
              value: 'Builder Tender',
            },
            {
              text: 'Builder Appointed',
              value: 'Builder Appointed',
            },
            {
              text: 'Quotation',
              value: 'Quotation',
            },
            {
              text: 'Sub Contractor Tender',
              value: 'Sub Contractor Tender',
            },
            {
              text: 'Sub Contractor Appointed',
              value: 'Sub Contractor Appointed',
            },
            {
              text: 'Proforma Invoice Issued',
              value: 'Proforma Invoice Issued',
            },
            {
              text: 'Deposit Paid',
              value: 'Deposit Paid',
            },
            {
              text: 'PO# Received',
              value: 'PO# Received',
            },
            {
              text: 'Closed Won',
              value: 'Closed Won',
            },
            {
              text: 'Closed Lost',
              value: 'Closed Lost',
            },
          ]"
          form-key="projStatus"
          v-model="reactiveData.projStatus"
        />
      </hi-form-item>
      <hi-form-item
        label="Reason *"
        v-if="reactiveData.projStatus === 'Closed Lost'"
      >
        <hi-select
          no-form
          :items="[
            {
              text: 'Competitor',
              value: 'Competitor',
            },
            {
              text: 'Price',
              value: 'Price',
            },
            {
              text: 'Stayed With Specified',
              value: 'Stayed With Specified',
            },
          ]"
          v-model="reactiveData.reason"
        />
      </hi-form-item>
      <hi-form-item label="Comments">
        <hi-textarea :rows="3" form-key="comments" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import ProjectTitleCard from "@/hive-vue3/modules/projects/ProjectTitleCard";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import { reactive, ref } from "vue";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import { updateProjectDetails } from "@/hive-vue3/modules/projects/data";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
export default {
  name: "ProjectChangeProjStatusDialog",
  components: {
    HiTextarea,
    HiSelect,
    HiFormItem,
    HiFormHiveStandard,
    ProjectTitleCard,
    HiDialog,
  },
  props: {
    projectData: Object,
  },
  setup(props) {
    const open = ref(false);
    const reactiveData = reactive({
      projStatus: undefined,
      reason: undefined,
    });

    async function submit(formData) {
      if (formData.projStatus === "Closed Lost") {
        if (!reactiveData.reason) {
          return {
            successful: false,
            errorMessage: "Must select a reason.",
          };
        } else {
          formData.lostReason = reactiveData.reason;
        }
      }

      const historyData = new DefaultHistoryData();

      historyData.target = "project";
      historyData.targetId = props.projectData.id;
      historyData.action = historyActions.STATUS;
      historyData.comments = `Status change: ${formData.projStatus} - ${
        formData.comments ? formData.comments : "N/A"
      } ${
        formData.lostReason ? " - Lost Reason: " + formData.lostReason : ""
      }. Last Status: ${props.projectData.projStatus}`;

      await addNewHistory(historyData);

      // console.log(formData);
      await updateProjectDetails(props.projectData.id, {
        projStatus: formData.projStatus,
      });

      reactiveData.projStatus = undefined;
      reactiveData.reason = undefined;
      return { successful: true };
    }

    return {
      open,
      submit,
      reactiveData,
    };
  },
};
</script>

<style scoped></style>
