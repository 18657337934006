<template>
  <div
    :style="{ display: inline ? 'inline-flex' : 'flex' }"
    style="align-items: center"
  >
    <hi-icon :path="icon" />
    <slot />
  </div>
</template>

<script>
import HiIcon from "./HiIcon";
/**
 * To align text to center of the SVG icon
 * To make icon slightly bigger than text
 */
export default {
  name: "HiIconItem",
  components: { HiIcon },
  props: {
    inline: Boolean,
    /**
     * the svg path for icon in a String
     */
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
::v-deep svg {
  margin-right: 0.5em;
  font-size: 110%;
  flex-shrink: 0;
}
</style>
