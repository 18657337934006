<template>
  <hi-dialog
    :title="defaultData ? 'Edit Pattern' : 'Add New Pattern'"
    close-button
    width="600px"
    v-model="open"
  >
    <hi-form-hive-standard
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      cancel-button
      :initial-values="defaultData"
    >
      <hi-form-item label="Pattern Name*">
        <hi-input form-key="name" mandatory />
      </hi-form-item>
      <hi-form-item label="Type*">
        <hi-select
          form-key="type"
          :items="{
            'Engineered Flooring': 'Engineered Flooring',
            'Solid Timber': 'Solid Timber',
          }"
          item-capitalize
          mandatory
        />
      </hi-form-item>
      <hi-form-item label="Short Number*">
        <hi-input form-key="shortNum" upper-case mandatory />
      </hi-form-item>
      <hi-form-item label="Description*">
        <hi-textarea form-key="description" :rows="2" auto-height mandatory />
      </hi-form-item>
      <hi-form-item label="Pattern Photo*">
        <hi-vault-image-control form-key="img" />
      </hi-form-item>
    </hi-form-hive-standard>

    <template v-if="defaultData && defaultData.id" v-slot:more>
      <hi-button
        v-if="defaultData.isActive"
        class="ml-5"
        @click="deactivatePattern"
        >Deactivate</hi-button
      >
      <hi-button v-else class="ml-5" @click="reactivatePattern"
        >Reactivate</hi-button
      >
    </template>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import {
  addNewPatternToFirebase,
  updatePatternDetails,
} from "@/hive-vue3/modules/products/data";
import mime from "@/hive-vue3/utils/constables/mime";
import HiVaultImageControl from "@/hive-vue3/components/form/controls/HiVaultImageControl";
import HiButton from "@/hive-vue3/components/HiButton";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
export default {
  name: "productsAddNewPatternDialog",
  components: {
    HiSelect,
    HiButton,
    HiVaultImageControl,
    HiTextarea,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    defaultData: Object,
  },
  setup(props) {
    const open = ref(false);

    async function submit(data) {
      data.name = data.name.trim();
      if (props.defaultData && props.defaultData.id) {
        const res = await updatePatternDetails(props.defaultData.id, data);
        if (res) {
          return { successful: true };
        }
        return { successful: false, errorMessage: "Pattern Already Exits" };
      } else {
        const res = await addNewPatternToFirebase({ ...data, isActive: true });
        if (res) {
          return { successful: true };
        }
        return { successful: false, errorMessage: "Pattern Already Exits" };
      }
    }

    async function deactivatePattern() {
      if (props.defaultData && props.defaultData.id) {
        await updatePatternDetails(props.defaultData.id, {
          shortNum: props.defaultData.shortNum,
          isActive: false,
        });
      }
    }
    async function reactivatePattern() {
      if (props.defaultData && props.defaultData.id) {
        await updatePatternDetails(props.defaultData.id, {
          shortNum: props.defaultData.shortNum,
          isActive: true,
        });
      }
    }

    return {
      submit,
      open,
      mime,
      deactivatePattern,
      reactivatePattern,
    };
  },
};
</script>

<style scoped></style>
