<template>
  <hi-i-frame class="max-w-full max-h-full" :html="codeHtml">
    <!--    <pre :class="className">-->
    <!--    <code><slot><div v-html="codeHtml"></div></slot></code>-->
    <!--  </pre>-->
  </hi-i-frame>
</template>

<script>
import { ref, watchEffect } from "vue";
import Prism from "prismjs";
import HiIFrame from "./HiIFrame";

export default {
  name: "HiCodeViewer",
  components: { HiIFrame },
  props: {
    code: {
      type: String,
    },
    dark: {
      type: Boolean,
    },
    language: {
      type: String,
      default: "javascript",
    },
  },
  setup(props) {
    const codeHtml = ref("");
    const className = ref("");
    watchEffect(() => {
      const language = props.language;
      const prismLanguage = Prism.languages[language];

      if (!prismLanguage) {
        codeHtml.value = `Prism component for language "${language}" was not found, did you forget to register it? See all available ones: https://cdn.jsdelivr.net/npm/prismjs/components/`;
      } else {
        className.value = `language-${language}`;
        Prism.plugins.lineNumbers = true;
        const html = Prism.highlight(props.code, prismLanguage, language);
        const style = props.dark
          ? "https://unpkg.com/prismjs/themes/prism-tomorrow.css"
          : "https://unpkg.com/prismjs/themes/prism.css";
        codeHtml.value = `<html>
                    <head>
                    <meta charset="utf-8" />
                    <link rel="stylesheet" href="${style}" />
                    <style>
                        html,body{
                        padding:0;margin:0;width:100%;height:100%;background-color:${
                          props.dark ? "#2d2d2d" : "white"
                        }
                        }
                    </style>
                    </head><body class="line-numbers"><pre class="language-${language}" style="margin:0"><code>${html}</code></pre></body></html>`;
      }
    });
    return {
      className,
      codeHtml,
    };
  },
};
</script>

<style scoped>
/*@import "~prismjs/themes/prism.css";*/
</style>
