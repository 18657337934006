<template>
  <span class="inline-flex -space-x-1.5 relative z-0">
    <hi-avatar
      v-for="(photo, index) in photos"
      :key="index + photo"
      :photo="photo"
      class="relative rounded-full"
      ring
      :style="{ zIndex: 100 - index }"
      :size="size"
    />
  </span>
</template>

<script>
import HiAvatar from "@/hive-vue3/components/HiAvatar";
// const testPhotos = [
//   "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
// ];
export default {
  name: "HiAvatarGroup",
  components: { HiAvatar },
  props: {
    size: {
      type: Number,
      default: 45,
    },
    photos: Array,
  },
  // setup() {
  //   return {
  //     testPhotos,
  //   };
  // },
};
</script>

<style scoped></style>
