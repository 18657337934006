<template>
  <div
    class="hi-card bg-light-100 overflow-hidden inline-flex flex-col relative"
    :class="{ 'hi-default-card-bg': !color }"
    :style="{ 'background-color': color || '' }"
  >
    <div
      v-if="gradientTop"
      class="absolute w-full top-0 left-0 pointer-events-none opacity-50 bg-gradient-to-b from-white"
      :class="gradientSofter ? 'h-16' : 'h-8'"
    ></div>
    <div v-if="$slots.header" class="w-full">
      <div
        class="box-border border-b border-primary border-opacity-40 w-full"
        :style="{ padding: padding + 'px' }"
      >
        <slot name="header"></slot>
      </div>
    </div>
    <hi-div
      class="grow overflow-auto min-w-content"
      :style="{ padding: padding + 'px' }"
      :class="{ 'card-center-content': centerContent }"
    >
      <!--      :todo flex-col-full-height-to-support-grow doesn't work here-->
      <slot />
    </hi-div>

    <div v-if="$slots.footer" class="w-full">
      <div
        class="box-border border-t border-primary border-opacity-40 w-full"
        :style="{ padding: padding + 'px' }"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HiDiv from "@/hive-vue3/components/HiDiv";
export default {
  name: "hi-card",
  components: { HiDiv },
  props: {
    gradientTop: {
      type: Boolean,
    },
    gradientSofter: {
      type: Boolean,
    },
    padding: {
      type: Number,
      default: 0,
    },
    centerContent: Boolean,
    divider: Boolean,
    color: String,
  },
};
</script>

<style scoped>
.card-center-content {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  height: 100%;
  align-items: center;
}
</style>
