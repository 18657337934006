<template>
  <hi-card>
    <div class="p-2 border-b-2">
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="user.uid" />
        <div class="">
          <div class="w-[100px]">AP: {{ state.apNum }}</div>
          <div>${{ state.apAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">DPP: {{ state.dppNum }}</div>
          <div>${{ state.dppAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">WP: {{ state.wpNum }}</div>
          <div>${{ state.wpAmount.toLocaleString() }}</div>
        </div>
        <div class="">
          <div class="w-[100px]">LP: {{ state.lpNum }}</div>
          <div>${{ state.lpAmount.toLocaleString() }}</div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import { getCurrentFirebaseUser } from "@/hive-vue3/firebase";
import { reactive, watchEffect } from "vue";
import {
  getProjectsByMainOwnerId,
  getProjectTotalAmountByProjectArray,
} from "@/hive-vue3/modules/projects/data";
export default {
  name: "MyProjectNumCard",
  components: { UserDetailsSpan, HiCard },
  setup() {
    const user = getCurrentFirebaseUser();

    const state = reactive({});
    watchEffect(() => {
      if (user.uid) {
        const projects = getProjectsByMainOwnerId(user.uid);
        const apProjects = projects.filter(
          (v) => v.projStatus !== "Closed Lost" && v.projStatus !== "Closed Won"
        );
        const dppProjects = projects.filter(
          (v) => v.projStatus === "Deposit Paid"
        );
        const wpProjects = projects.filter(
          (v) => v.projStatus === "Closed Won"
        );
        const lpProjects = projects.filter(
          (v) => v.projStatus === "Closed Lost"
        );

        state.apNum = apProjects.length;
        state.apAmount = getProjectTotalAmountByProjectArray(apProjects);

        state.dppNum = dppProjects.length;
        state.dppAmount = getProjectTotalAmountByProjectArray(dppProjects);

        state.wpNum = wpProjects.length;
        state.wpAmount = getProjectTotalAmountByProjectArray(wpProjects);

        state.lpNum = lpProjects.length;
        state.lpAmount = getProjectTotalAmountByProjectArray(lpProjects);
      }
    });
    return {
      user,
      state,
    };
  },
};
</script>

<style scoped></style>
