<template>
  <div
    class="inline-block cursor-text min-w-[20px]"
    :contenteditable="editable"
    ref="input"
    @blur="onBlur"
  ></div>
</template>

<script>
import { templateRef } from "@vueuse/core";
import { nextTick, onMounted, ref } from "vue";
import keyPress from "@/hive-vue3/utils/sensors/keyPress";
import { tryRun } from "@/hive-vue3/utils/functionUtils";

/**
 * 简单的 就地单行编辑器
 * 封装了 enter/blur -> submit，esc -> cancel
 */
export default {
  name: "HiSimpleInlineInput",
  props: {
    submitFn: {
      type: Function,
    },
    cancelFn: Function,
  },
  emits: ["cancel", "submit"],
  setup(props, { emit }) {
    const input = templateRef("input");
    const editable = ref(true);
    let canceled = false;
    let submited = false;
    onMounted(async () => {
      canceled = false;
      submited = false;
      await nextTick();
      input.value.focus();
    });
    async function onBlur() {
      if (canceled) return;
      await submit();
    }
    async function submit() {
      if (submited) return;
      submited = true;
      const text = input.value.textContent.trim();
      if (!text.length) {
        cancel();
      } else {
        emit("submit");
        editable.value = false;
        if (props.submitFn) await props.submitFn(text);

        tryRun(() => {
          input.value.innerHTML = "";
        });
      }
    }
    function cancel() {
      canceled = true;
      emit("cancel");
      if (props.cancelFn) props.cancelFn();
    }
    const keys = keyPress(input, undefined, { focusOnly: true });
    keys.enter = async () => {
      await submit();
    };
    keys.escape = () => {
      cancel();
    };
    return {
      onBlur,
      editable,
    };
  },
};
</script>

<style scoped></style>
