<template>
  <module-titled-block :title="'People(' + peopleList.length + ')'">
    <template v-slot:tools v-if="editable">
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Link another entity"
        />
        <contacts-new-people-dialog
          :entity-id="entityId"
          text-button
          small-button
          button-text=""
        />
      </hi-dialog-trigger>
    </template>
    <hi-list :items="peopleList" @select="onSelect" :selectable="selectable">
      <template v-slot="{ item }">
        <hi-dialog-trigger>
          <contacts-people
            class="cursor-pointer"
            :people-data="item"
            title
            gender
            position
            :entity-id="entityId"
          />
          <hi-dialog title="Contact People Overview" close-button width="680px">
            <contacts-people-overview :people-data="item" />
            <template v-slot:footer>
              <div class="flex justify-end grow">
                <hi-button
                  text
                  @click="removePeopleFromEntity(item.id, entityId)"
                  >Remove
                  <contacts-people :people-data="item" class="mx-1" /> From
                  <contacts-entity :entity-id="entityId" class="mx-1" />
                </hi-button>
              </div>
            </template>
          </hi-dialog>
        </hi-dialog-trigger>
      </template>
    </hi-list>
  </module-titled-block>
</template>

<script>
import HiList from "@/hive-vue3/components/HiList";
import { computed, defineAsyncComponent } from "vue";
import {
  getContactsPeopleInEntity,
  removePeopleFromEntity,
} from "@/hive-vue3/modules/contacts/data";
// import ContactsPeopleWithOverviewPopup from "@/hive-vue3/modules/contacts/ContactsPeopleWithOverviewPopup";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import ContactsPeople from "@/hive-vue3/modules/contacts/ContactsPeople";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
// import ContactsPeopleOverview from "@/hive-vue3/modules/contacts/ContactsPeopleOverview";
import ContactsNewPeopleDialog from "@/hive-vue3/modules/contacts/ContactsNewPeopleDialog";
import ModuleTitledBlock from "@/hive-vue3/modules/_common/ModuleTitledBlock";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiIcon from "@/hive-vue3/components/HiIcon";
import HiButton from "@/hive-vue3/components/HiButton";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
export default {
  name: "ContactsPeopleInEntityView",
  components: {
    ContactsEntity,
    HiButton,
    HiIcon,
    ModuleTitledBlock,
    ContactsNewPeopleDialog,
    // Edge case (Failed to resolve component)
    ContactsPeopleOverview: defineAsyncComponent(() =>
      import("./ContactsPeopleOverview.vue")
    ),
    HiDialog,
    ContactsPeople,
    HiDialogTrigger,
    // ContactsPeopleWithOverviewPopup,

    HiList,
  },
  props: {
    entityId: String,
    selectable: Boolean,
    editable: Boolean,
  },
  emits: ["select"],
  setup(props, { emit }) {
    const peopleList = computed(() => {
      return getContactsPeopleInEntity(props.entityId);
    });
    function onSelect(data) {
      emit("select", data.id);
    }
    return {
      peopleList,
      commonIcons,
      onSelect,
      removePeopleFromEntity,
    };
  },
};
</script>

<style scoped>
@import "../moduleStyles.css";
</style>
