import {
  firestoreDoc,
  getDateFromTsOrDate,
  getFirestoreOnce,
  newFirestoreDoc,
  // newFirestoreDoc,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase";
import {
  createDataListStore,
  provideDataListStore,
} from "@/hive-vue3/components/dataDisplay/logics/dataListStore";
import { useFirestoreCollectionAsRefGlobally } from "@/hive-vue3/firebase/globalFirestoreData";
import { computed } from "vue";
import {
  getContactsEntityNameById,
  getContactsEntityTradingNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
import { getProjectById } from "@/hive-vue3/modules/projects/data";
import {
  requestInvoiceFileUrl,
  requestInvoicePreviewPDFUrl,
  requestQuotationPreviewPDFUrl,
} from "@/hive-vue3/api/request";

const cache = {
  quotations: null,
};
const store = {
  quotations: null,
};

function initQuotations() {
  if (!cache.quotations) {
    const source = useFirestoreCollectionAsRefGlobally("quotations");

    const listStore = createDataListStore(source);
    cache.quotations = source;
    store.quotations = listStore;
  }
}

export const provideQuotationListStore = () => {
  initQuotations();
  return provideDataListStore(store.quotations);
};

// 保存quotation改动
export async function saveQuotationToFirebase(
  formHeader,
  formBody,
  docId = null,
  revisionId = "0"
) {
  // 若有quotation, 则更新
  if (docId) {
    await updateFirestoreDoc(firestoreDoc("quotations", docId), {
      ...formHeader,
      revisions: {
        [revisionId]: formBody,
      },
    });
    return docId;
  } else {
    // 若没有, 则新建
    const res = await newFirestoreDoc("quotations", {
      ...formHeader,
      revisions: {
        [revisionId]: formBody,
      },
    });

    return res.id;
  }
}

// 更新 quotation 状态为 send
export function quotationStatusSend(docId, revisionId) {
  updateFirestoreDoc(firestoreDoc("quotations", docId), {
    revisions: {
      [revisionId]: {
        status: "send",
      },
    },
  })
    .then()
    .catch((e) => {
      console.log(e);
    });
}

export function getQuotationById(quotationId) {
  initQuotations();

  for (const index in cache.quotations.value) {
    if (cache.quotations.value[index].id === quotationId) {
      return cache.quotations.value[index];
    }
  }
  return {};
}

export function getQuotationByIdAndRevisionId(quotationId, revisionId) {
  initQuotations();
  if (cache.quotations && cache.quotations.value) {
    let quotationData = null;
    for (const index in cache.quotations.value) {
      if (cache.quotations.value[index].id === quotationId) {
        quotationData = cache.quotations.value[index];
        break;
      }
    }
    if (quotationData && quotationData.revisions) {
      const v = quotationData;
      const formHeader = {};

      formHeader.id = v.id;
      formHeader.client = v.client;
      formHeader.projectId = v.projectId;
      formHeader.attention = v.attention;
      formHeader.quoteNum = v.quoteNum;
      formHeader.legalName = v.legalName;

      const formBody = {};
      formBody.dateCreated = v.revisions[revisionId].dateCreated;
      formBody.validUntil = v.revisions[revisionId].validUntil;
      formBody.depositRate = v.revisions[revisionId].depositRate;
      formBody.items = JSON.parse(
        JSON.stringify(v.revisions[revisionId].items)
      );
      formBody.status = v.revisions[revisionId].status;
      formBody.status = v.revisions[revisionId].status;
      formBody.revisionId = revisionId;
      formBody.depositCondition = v.revisions[revisionId].depositCondition;

      return { ...formHeader, ...formBody };
    }
  }
  return {};
}

export function getQuotationIsSend(quotationId) {
  const v = getQuotationById(quotationId);

  for (const revision in v.revisions) {
    if (v.revisions[revision].status === "send") {
      return true;
    }
  }
  return false;
}

export function getQuotationLastRevisionId(quotationId) {
  initQuotations();
  if (quotationId) {
    const quotation = getQuotationById(quotationId);
    return Object.keys(quotation.revisions).length - 1;
  }
  return 0;
}

export async function getQuotationNum() {
  const currentYear = new Date().getFullYear();
  const returnQuotationNum =
    (await getFirestoreOnce("/system/utils/auto-incremental", "quotation"))[
      currentYear
    ] + 1;

  await updateFirestoreDoc(
    firestoreDoc("/system/utils/auto-incremental", "quotation"),
    {
      [currentYear]: returnQuotationNum,
    }
  );

  return returnQuotationNum;
}

export function getQuotationIdStr(quoteNum, revisionNum) {
  if (quoteNum && revisionNum) {
    const revisionId = parseInt(revisionNum);
    if (revisionId) {
      return `QUO-${quoteNum.toString().padStart(4, "0")}-${String.fromCharCode(
        64 + revisionId
      )}`;
    } else {
      return `QUO-${quoteNum.toString().padStart(4, "0")}`;
    }
  }
}

// 获取相关project的quotation, 返回 Ref
export function useQuotationsByProjectId(projectId) {
  initQuotations();
  return computed(() => {
    const returnArray = [];
    if (cache.quotations && cache.quotations.value) {
      for (const index in cache.quotations.value) {
        if (
          cache.quotations.value[index] &&
          cache.quotations.value[index].projectId &&
          cache.quotations.value[index].projectId === projectId
        ) {
          returnArray.push(cache.quotations.value[index]);
        }
      }
    }
    return returnArray;
  });
}

export function useAllQuotations() {
  initQuotations();
  return computed(() => cache.quotations.value);
}

// 请求服务器生成 invoice
export async function requestInvoicePdf(formData, isTax = false) {
  const formHeader = {};
  // formHeader.client = getContactsEntityNameById(formData.client);

  if (formData.legalName) {
    formHeader.client = getContactsEntityNameById(formData.client);
  } else {
    formHeader.client = getContactsEntityTradingNameById(formData.client);
  }

  const getProjectAddress = () => {
    const project = getProjectById(formData.projectId);

    if (project.isNameOfficial) {
      return `${project.name}\n${project.address.formatted}`;
    }
    return `${project.address.formatted}`;
  };

  formHeader.projectAddress = getProjectAddress();

  formHeader.attention = getContactsFullNameById(formData.attention);
  formHeader.invoiceNum = formData.invoiceNum;
  formHeader.legalName = formData.legalName;

  const formBody = {};
  formBody.dateCreated = getDateFromTsOrDate(formData.dateCreated);
  formBody.depositRate = formData.depositRate;
  formBody.depositDue = formData.depositDue;
  formBody.depositPaid = formData.depositPaid;
  formBody.items = formData.items;
  formBody.depositCondition = formData.depositCondition;
  if (isTax) {
    formBody.type = "tax";
  } else {
    formBody.type = "proForma";
  }
  // request pdf md5
  const res = await requestInvoicePreviewPDFUrl({
    ...formHeader,
    ...formBody,
  });

  if (res.successful) {
    // console.log(res);
    return res.message;
  } else {
    // console.log(res);
    return false;
  }
}

// 请求服务器生成 invoice
export async function requestInvoiceFile(formData) {
  const formHeader = {};
  // formHeader.client = getContactsEntityNameById(formData.client);

  if (formData.legalName) {
    formHeader.client = getContactsEntityNameById(formData.client);
  } else {
    formHeader.client = getContactsEntityTradingNameById(formData.client);
  }

  const getProjectAddress = () => {
    const project = getProjectById(formData.projectId);

    if (project.isNameOfficial) {
      return `${project.name}\n${project.address.formatted}`;
    }
    return `${project.address.formatted}`;
  };

  formHeader.projectAddress = getProjectAddress();

  formHeader.attention = getContactsFullNameById(formData.attention);
  formHeader.invoiceNum = formData.invoiceNum;
  formHeader.legalName = formData.legalName;

  const formBody = {};
  formBody.dateCreated = getDateFromTsOrDate(formData.dateCreated);
  formBody.depositRate = formData.depositRate;
  formBody.depositDue = formData.depositDue;
  formBody.depositPaid = formData.depositPaid;
  formBody.items = formData.items;
  formBody.depositCondition = formData.depositCondition;
  formBody.type = formData.type;
  // request pdf md5
  const res = await requestInvoiceFileUrl({
    ...formHeader,
    ...formBody,
  });

  if (res.successful) {
    return res.message;
  } else {
    return false;
  }
}

// 请求服务器生成 quotation
export async function requestQuotationPdf(formData) {
  const formHeader = {};
  // formHeader.client = getContactsEntityNameById(formData.client);

  if (formData.legalName) {
    formHeader.client = getContactsEntityNameById(formData.client);
  } else {
    formHeader.client = getContactsEntityTradingNameById(formData.client);
  }

  const getProjectAddress = () => {
    const project = getProjectById(formData.projectId);

    if (project.isNameOfficial) {
      return `${project.name}\n${project.address.formatted}`;
    }
    return `${project.address.formatted}`;
  };

  formHeader.projectAddress = getProjectAddress();

  formHeader.attention = getContactsFullNameById(formData.attention);
  formHeader.quoteNum = formData.quoteNum;
  formHeader.legalName = formData.legalName;

  const formBody = {};
  formBody.dateCreated = getDateFromTsOrDate(formData.dateCreated);
  formBody.validUntil = getDateFromTsOrDate(formData.validUntil);
  formBody.depositRate = formData.depositRate;
  formBody.items = formData.items;
  formBody.revisionId = formData.revisionId;
  formBody.depositCondition = formData.depositCondition;
  // request pdf md5
  const res = await requestQuotationPreviewPDFUrl({
    ...formHeader,
    ...formBody,
  });

  if (res.successful) {
    return res.message;
  } else {
    console.error("quotation preview error: ", res);
    return false;
  }
}
// ===== quotation 表单控制函数 =======
export function getSubTotal(formData) {
  if (formData && formData.items) {
    let subtotal = 0;
    formData.items.forEach((v) => {
      subtotal += v.rate * v.quantity;
    });
    return subtotal.toFixed(2);
  }
  return "0";
}

export function getGst(formData) {
  const subTotal = getSubTotal(formData);
  return (0.1 * subTotal).toFixed(2);
}

export function getTotal(formData) {
  const subTotal = getSubTotal(formData);
  const gst = getGst(formData);
  return (parseFloat(subTotal) + parseFloat(gst)).toFixed(2);
}

export function getLastRevisionIdByData(quotationData) {
  if (quotationData && quotationData.revisions) {
    return (Object.keys(quotationData.revisions).length - 1).toString();
  }
  return "0";
}

export function getLastRevisionIdById(quotationId) {
  const quotation = getQuotationById(quotationId);
  return getLastRevisionIdByData(quotation);
}
export function formNullCheck(formData) {
  for (const i in formData) {
    if (
      i !== "quoteNum" &&
      i !== "status" &&
      i !== "client" &&
      i !== "legalName"
    ) {
      if (formData[i] === undefined) return false;
    }
  }
  return true;
}

export function formSendEnable(formData) {
  if (formData && formData.items) {
    const formNullCheckRes = formNullCheck(formData);

    let fromCheck = true;
    for (const i in formData.items) {
      if (
        formData.items[i].item === undefined ||
        formData.items[i].quantity === undefined ||
        formData.items[i].rate === undefined ||
        formData.items[i].item === null ||
        formData.items[i].quantity === null ||
        formData.items[i].rate === null
      ) {
        fromCheck = false;
        break;
      }
    }

    return formNullCheckRes && fromCheck;
  } else {
    return false;
  }
}

// ===== quotation router 配置, 用于跳转到对应 quotation =======
export async function jumpToQuotationById(router, quotationId) {
  await router.push("/quotations/" + quotationId);
}
