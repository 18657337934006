<template>
  <div
    v-if="pattern"
    ref="container"
    class="flex gap-2 p-2"
    :style="{
      'background-color': pattern.isActive ? '' : 'rgba(180, 190, 180, 0.2)',
      color: pattern.isActive ? '' : 'grey',
    }"
  >
    <hi-vault-image class="w-20 h-20" :file-id="pattern.img" />
    <div v-if="!picOnly" class="grow">
      <div v-if="pattern && pattern.name">
        ({{ pattern.shortNum }}) {{ pattern.name }}
      </div>
      <div class="tp-description">{{ pattern.type }}</div>
      <div class="tp-description">{{ pattern.description }}</div>
    </div>

    <hi-dialog-trigger v-if="editable">
      <hi-icon
        v-if="mouseOver"
        :path="commonIcons.edit"
        class="clickable-icon"
      />
      <products-add-new-pattern-dialog :default-data="pattern" />
    </hi-dialog-trigger>
  </div>
</template>

<script>
import { computed } from "vue";
// import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { decamelize } from "@/hive-vue3/utils/stringUtils";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import HiVaultImage from "@/hive-vue3/components/firebase/HiVaultImage";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import { decodeDocId } from "@/hive-vue3/firebase";
import ProductsAddNewPatternDialog from "@/hive-vue3/modules/products/productsAddNewPatternDialog";
import { getPatternByPatternId } from "@/hive-vue3/modules/products/data";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { templateRef } from "@vueuse/core";
import useMouseOver from "@/hive-vue3/utils/sensors/useMouseOver";

export default {
  name: "productsPatternSummary",
  components: {
    HiIcon,
    ProductsAddNewPatternDialog,
    HiDialogTrigger,
    HiVaultImage,
  },
  props: {
    patternData: Object,
    patternId: String,
    editable: Boolean,
    picOnly: Boolean,
    noStatus: Boolean,
  },
  setup(props) {
    const pattern = computed(() => {
      if (props.patternData) return props.patternData;
      else {
        return getPatternByPatternId(props.patternId.trim());
      }
    });

    const container = templateRef("container");
    const mouseOver = useMouseOver(container);

    return {
      pattern,
      decamelize,
      commonIcons,
      decodeDocId,
      mouseOver,
    };
  },
};
</script>

<style scoped>
.deactivate {
  background: rgba(240, 238, 240, 0.9);
}
.circle-active {
  width: 10px;
  height: 10px;
  background-color: #0cd70c;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.circle-deActive {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
