<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard :submit-fn="submit" @successful="open = false">
      <hi-form-item label="People">
        <mfc-input-contacts-people form-key="people" />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import { arrayUnion } from "firebase/firestore";
import { updateAccountById } from "@/hive-vue3/modules/accounts/data";
import { ref } from "vue";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import { getContactsFullNameById } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "AddPeopleToAccountDialog",
  components: {
    MfcInputContactsPeople,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  setup() {
    const open = ref();
    async function submit(fromData) {
      try {
        const historyObj = new DefaultHistoryData();
        historyObj.action = historyActions.LINK;
        historyObj.target = "account";
        historyObj.targetId = accountState.currentAccountId;
        historyObj.comments =
          `Link people to account: ` + getContactsFullNameById(fromData.people);
        await addNewHistory(historyObj);
        await updateAccountById(accountState.currentAccountId, {
          people: arrayUnion(fromData.people),
        });
        return { successful: true };
      } catch (e) {
        return { successful: false, errorMsg: e };
      }
    }
    return {
      submit,
      ref,
      open,
    };
  },
};
</script>

<style scoped></style>
