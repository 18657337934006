<template>
  <module-title-card :title="projectId ? 'Quotations' : 'Recent Quotations'">
    <template v-if="projectId" v-slot:tools>
      <hi-dialog-trigger>
        <hi-icon
          class="clickable-icon"
          :path="commonIcons.add"
          title="Add new quotation"
        />
        <hi-dialog
          width="1000px"
          height="850px"
          title="Quotation"
          v-model="openNew"
        >
          <quotation-editor-form
            @cancel="openNew = false"
            @successful="
              openNew = false;
              open = true;
              quotationState.currentMiddleColView = 'preview';
              quotationState.isUnSaved = false;
            "
            :initial-values="{
              projectId: projectId,
              items: projectQuotationDefaultItemList,
            }"
          />
        </hi-dialog>
      </hi-dialog-trigger>
    </template>
    <hi-list
      allow-unselect-all
      v-if="quotationList.length"
      :items="quotationList"
      selectable
      @select="selectQuotation"
    >
      <template v-slot="{ item: quotation }">
        <quotation-summary :quotation-data="quotation" />
      </template>
    </hi-list>

    <hi-view-no-data v-else />
    <hi-dialog
      width="1000px"
      height="850px"
      title="Quotation Preview"
      v-model="open"
    >
      <quotation-function-card class="w-full" />
      <quotation-preview
        v-if="quotationState.currentMiddleColView === 'preview'"
        :quotation-id="quotationState.currentQuotationId"
        :revision-id="quotationState.revisionId"
      />

      <quotation-editor-form
        v-else-if="quotationState.currentMiddleColView === 'edit'"
        :initial-values="quotationData"
        @successful="
          quotationState.currentMiddleColView = 'preview';
          quotationState.isUnSaved = false;
        "
        @cancel="
          quotationState.currentMiddleColView = 'preview';
          quotationState.isUnSaved = false;
        "
      />
      <invoice-editor-form
        v-else
        :initial-values="quotationData"
        @successful="
          quotationState.currentMiddleColView = 'preview';
          quotationState.isUnSaved = false;
        "
        @cancel="
          quotationState.currentMiddleColView = 'preview';
          quotationState.isUnSaved = false;
        "
      />
    </hi-dialog>
  </module-title-card>
</template>

<script>
import ModuleTitleCard from "@/hive-vue3/modules/_common/ModuleTitleCard";
import HiIcon from "@/hive-vue3/components/HiIcon";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { computed, ref } from "vue";
import {
  useQuotationsByProjectId,
  useAllQuotations,
  getQuotationByIdAndRevisionId,
} from "@/hive-vue3/modules/quotation/data";
import HiList from "@/hive-vue3/components/HiList";
import QuotationSummary from "@/hive-vue3/modules/quotation/QuotationSummary";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";
import { quotationState } from "@/hive-vue3/modules/quotation/state";
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import {
  getProjectPipeIdById,
  getProjectProductByPipeId,
} from "@/hive-vue3/modules/projects/data";
import {
  getColorById,
  getPatternSizeBySizeId,
} from "@/hive-vue3/modules/products/data";
import QuotationPreview from "@/hive-vue3/modules/quotation/QuotationPreview";
import QuotationFunctionCard from "@/hive-vue3/modules/quotation/QuotationFunctionCard";
import QuotationEditorForm from "@/hive-vue3/modules/quotation/QuotationEditorForm";
import InvoiceEditorForm from "@/hive-vue3/modules/invoice/InvoiceEditorForm";
export default {
  name: "QuotationListViewCard",
  components: {
    InvoiceEditorForm,
    QuotationEditorForm,
    QuotationFunctionCard,
    QuotationPreview,
    // QuotationMultiFuncitonCard,
    HiDialog,
    HiViewNoData,
    HiDialogTrigger,
    // AddNewQuotationDialog,
    QuotationSummary,
    HiList,
    HiIcon,
    ModuleTitleCard,
  },
  props: {
    projectId: String,
    limit: Number,
  },

  setup(props) {
    const open = ref(false);
    const openNew = ref(false);

    const quotationList = computed(() => {
      if (props.projectId) {
        const quotations = useQuotationsByProjectId(props.projectId);
        quotations.value.sort(sortByCreateOrUpdate);
        return quotations.value.slice(0, props.limit);
      }
      const quotations = useAllQuotations();
      if (quotations.value) {
        quotations.value.sort(sortByCreateOrUpdate);
        return quotations.value.slice(0, props.limit);
      }
      return [];
    });

    const projectQuotationDefaultItemList = computed(() => {
      const pipeId = getProjectPipeIdById(props.projectId);
      const products = getProjectProductByPipeId(pipeId, true);

      const returnArr = [];
      if (products.length) {
        for (const i of products) {
          const color = getColorById(i.colorId);
          const size = getPatternSizeBySizeId(i.sizeId);
          let str = "";
          for (const s of size.size) {
            str += `${s.width}x${s.length}x${size.thickness}/${size.veneer}mm `;
          }

          str += "Birch multiply core ";
          str += color.name + " ";
          str += i.grade[0].toUpperCase() + i.grade.substring(1) + " Grade";
          str += `\nincludes 10% wastage for straight plank supply`;

          // console.log(color, size);
          returnArr.push({
            item: str,
            quantity: null,
            rate: size[color.type][i.grade],
          });
        }
      }
      returnArr.push({
        item: "Delivery to site",
        quantity: null,
        rate: null,
      });

      if (returnArr.length < 3) {
        const ii = 3 - returnArr.length;
        for (let i = 0; i < ii; i++) {
          returnArr.push({ item: null, quantity: null, rate: null });
        }
      } else {
        returnArr.push({ item: null, quantity: null, rate: null });
      }

      return returnArr;
    });

    function sortByCreateOrUpdate(a, b) {
      const aDate = a.__dateUpdated || a.__dateCreated;
      const bDate = b.__dateUpdated || b.__dateCreated;

      if (bDate && aDate) {
        return bDate.toDate().getTime() - aDate.toDate().getTime();
      } else {
        return 0;
      }
    }

    function selectQuotation(data) {
      open.value = true;
      quotationState.currentQuotationId = data.id;
      quotationState.revisionId = (
        Object.keys(data.revisions).length - 1
      ).toString();

      quotationState.isSend = false;
      for (const revision in data.revisions) {
        if (data.revisions[revision].status === "send") {
          quotationState.isSend = true;
        }
      }
      quotationState.currentMiddleColView = "preview";
      quotationState.isUnSaved = false;
    }

    const quotationData = computed(() => {
      return getQuotationByIdAndRevisionId(
        quotationState.currentQuotationId,
        quotationState.revisionId
      );
    });

    return {
      selectQuotation,
      quotationList,
      commonIcons,
      open,
      projectQuotationDefaultItemList,
      openNew,
      quotationState,
      quotationData,
    };
  },
};
</script>

<style scoped></style>
