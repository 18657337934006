<template>
  <span
    :class="{
      '-selected': selected,
      '-functional': functional,
      '-selectable': selectable,
      '-editable': editable,
      '-outline': outline,
      '-small': small,
      '-big': big,
      'inline-flex': deletable,
    }"
    class="hi-chips capitalize gap-2 items-center"
  >
    <slot />
    <hi-icon
      v-if="deletable"
      :path="icons.close"
      class="clickable"
      @click="$emit('delete')"
    />
  </span>
</template>

<script>
import { mdiCloseCircle } from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";

export default {
  name: "HiChip",
  components: { HiIcon },
  props: {
    selected: Boolean,
    functional: Boolean,
    selectable: Boolean,
    editable: Boolean,
    small: Boolean,
    big: Boolean,
    outline: Boolean,
    deletable: Boolean,
  },
  emits: ["delete"],
  setup() {
    const icons = {
      close: mdiCloseCircle,
    };
    return {
      icons,
    };
  },
};
</script>

<style scoped></style>
