<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard
      :submit-fn="submit"
      cancel-button
      @successful="open = false"
      @cancel="open = false"
    >
      <hi-form-item label="Url *">
        <hi-input form-key="url" mandatory />
      </hi-form-item>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import { requestGetMailTemplateFromRemoteUrl } from "@/hive-vue3/api/request";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { edmState } from "@/hive-vue3/modules/edm/state";
export default {
  name: "EdmChangeTemplateDialog",
  components: { HiInput, HiFormItem, HiFormHiveStandard, HiDialog },
  setup() {
    const open = ref(false);

    async function submit(data) {
      const res = await requestGetMailTemplateFromRemoteUrl(data.url);

      if (res.successful) {
        const html = res.data.html;
        console.log(html);
        await updateFirestoreDoc(
          firestoreDoc("/edm/campaigns/registers", edmState.currentEdmId),
          {
            template: html,
          }
        );
        edmState.currentHtml = html;
        return {
          successful: true,
        };
      } else {
        return {
          successful: false,
          errorMessage: res.data.message,
        };
      }
    }

    return {
      open,
      submit,
    };
  },
};
</script>

<style scoped></style>
