<template>
  <span>
    <hi-button
      @click="open = true"
      v-bind="$attrs"
      :outline="outlineButton"
      :text="textButton"
      :primary="primaryButton"
      v-if="!hideButton"
      :small="smallButton"
      :disabled="disabled"
    >
      {{ buttonText }}
    </hi-button>
    <hi-dialog
      :title="title"
      :persist="persist"
      :close-button="closeButton"
      v-model="open"
      :width="width"
      :min-width="minWidth"
    >
      <slot />
    </hi-dialog>
  </span>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";

import HiButton from "@/hive-vue3/components/HiButton";
import modelRef from "@/hive-vue3/utils/reactiveHelpers/modelRef";

export default {
  name: "HiDialogButton",
  components: {
    HiButton,
    HiDialog,
  },
  props: {
    persist: Boolean,
    modelValue: Boolean,
    hideButton: Boolean,
    buttonText: {
      type: String,
      default: "Add New Doc",
    },
    disabled: Boolean,
    outlineButton: Boolean,
    textButton: Boolean,
    smallButton: Boolean,
    primaryButton: Boolean,
    closeButton: Boolean,
    title: {
      type: String,
      default: "Add new document to Firestore",
    },
    width: {
      type: [Number, String],
    },
    minWidth: [Number, String],
  },
  setup(props, { emit }) {
    const open = modelRef(props, emit);

    function close() {
      // console.log("---- cancel");
      // console.log(data);
      open.value = false;
    }

    return {
      open,
      close,
    };
  },
};
</script>
