<template>
  <hi-dialog v-model="open" width="600px" title="Link contact to project">
    <hi-form-hive-standard
      :submitFn="submit"
      cancel-button
      @cancel="open = false"
      @successful="open = false"
    >
      <div class="w-full flex flex-row-reverse">
        <span
          @click="noPeople = !noPeople"
          class="underline underline-offset-4 text-sm text-grey-400/50 cursor-pointer"
        >
          {{ noPeople ? "Add People First?" : "Add Entity First?" }}
        </span>
      </div>
      <hi-form-item label="Type">
        <hi-select
          form-key="type"
          :items="{
            Architect: 'Architect/Designer',
            Builder: 'Builder',
            Contractor: 'Contractor',
            Developer: 'Developer',
            Owner: 'Owner',
          }"
          mandatory
        >
        </hi-select>
      </hi-form-item>
      <transition-fade>
        <div v-if="noPeople">
          <hi-form-item label="Contact Entity">
            <mfc-input-contacts-entity
              mandatory
              form-key="entityId"
              v-model="selectedEntity"
            />
          </hi-form-item>

          <hi-form-item v-if="selectedEntity" label="Contact People">
            <mfc-contacts-people-in-entity-selector
              form-key="contactId"
              :entity-id="selectedEntity"
              v-model="selectedPeople"
            />
          </hi-form-item>
        </div>
        <div v-else>
          <hi-form-item label="Contact People">
            <mfc-input-contacts-people
              form-key="contactId"
              v-model="selectedPeople"
              mandatory
            />
          </hi-form-item>
          <hi-form-item v-if="selectedPeople" label="Entity">
            <mfc-contacts-entities-by-people-selector
              form-key="entityId"
              :people-id="selectedPeople"
              v-model="selectedEntity"
            />
          </hi-form-item>
        </div>
      </transition-fade>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
// import HiForm from "@/hive-vue3/components/form/HiForm";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import MfcInputContactsPeople from "@/hive-vue3/modules/contacts/controls/MfcInputContactsPeople";
import MfcContactsEntitiesByPeopleSelector from "@/hive-vue3/modules/contacts/controls/MfcContactsEntitiesByPeopleSelector";
import { ref } from "vue";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { addProjectContacts } from "@/hive-vue3/modules/projects/data";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import MfcContactsPeopleInEntitySelector from "@/hive-vue3/modules/contacts/controls/MfcContactsPeopleInEntitySelector";
import MfcInputContactsEntity from "@/hive-vue3/modules/contacts/controls/MfcInputContactsEntity";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";
import {
  getContactsEntityNameById,
  getContactsFullNameById,
} from "@/hive-vue3/modules/contacts/data";
export default {
  name: "ProjectAddContactDialog",
  components: {
    TransitionFade,
    MfcInputContactsEntity,
    MfcContactsPeopleInEntitySelector,
    HiFormHiveStandard,
    // HiButton,
    MfcContactsEntitiesByPeopleSelector,
    MfcInputContactsPeople,
    HiSelect,
    HiFormItem,
    // HiForm,
    HiDialog,
  },
  props: {
    projectId: String,
  },
  setup(props) {
    const noPeople = ref(false);
    const selectedPeople = ref(undefined);
    const selectedEntity = ref(undefined);
    const open = ref(false);

    watchPropsImmediately(open, "value", () => {
      selectedPeople.value = undefined;
      selectedEntity.value = undefined;
      noPeople.value = false;
    });
    watchPropsImmediately(noPeople, "value", () => {
      selectedPeople.value = undefined;
      selectedEntity.value = undefined;
    });

    watchPropsImmediately(selectedEntity, "value", () => {
      if (noPeople.value) {
        // console.log("people ");
        selectedPeople.value = undefined;
      }
    });
    watchPropsImmediately(selectedPeople, "value", () => {
      if (!noPeople.value) {
        // console.log("entity ");
        selectedEntity.value = undefined;
      }
    });

    async function submit(data) {
      // console.log(data);

      if (noPeople.value) {
        if (selectedEntity.value) {
          const contactData = {};
          contactData.type = data.type;
          contactData.entityId = selectedEntity.value;
          if (selectedPeople.value) {
            contactData.peopleId = selectedPeople.value;
          }
          await addProjectContacts(props.projectId, contactData);

          const historyData = new DefaultHistoryData();
          historyData.action = historyActions.ADD;
          historyData.target = "project";
          historyData.targetId = props.projectId;
          historyData.value = {};
          historyData.value.entityId = selectedEntity.value;
          if (selectedPeople.value) {
            historyData.value.peopleId = selectedPeople.value;
            historyData.comments = `Add stakeholder: ${getContactsFullNameById(
              selectedPeople.value
            )} of ${getContactsEntityNameById(selectedEntity.value)}`;
          } else {
            historyData.comments = `Add stakeholder:  ${getContactsEntityNameById(
              selectedEntity.value
            )}`;
          }
          await addNewHistory(historyData);

          return { successful: true };
        } else {
          return { successful: false, errorMessage: "Please Select Entity" };
        }
      } else {
        if (selectedPeople.value) {
          const contactData = {};
          contactData.type = data.type;
          contactData.peopleId = selectedPeople.value;
          if (selectedEntity.value) {
            contactData.entityId = selectedEntity.value;
          }
          await addProjectContacts(props.projectId, contactData);

          // const contact = getContactsPeople(selectedPeople.value);

          const historyData = new DefaultHistoryData();
          historyData.action = historyActions.ADD;
          historyData.target = "project";
          historyData.targetId = props.projectId;
          historyData.item = "StakeHolder";
          historyData.value = {};
          historyData.value.peopleId = selectedPeople.value;
          if (selectedEntity.value) {
            historyData.value.entityId = selectedEntity.value;
            historyData.comments = `Add stakeholder: ${getContactsFullNameById(
              selectedPeople.value
            )} of ${getContactsEntityNameById(selectedEntity.value)}`;
          } else {
            historyData.comments = `Add stakeholder: ${getContactsFullNameById(
              selectedPeople.value
            )}`;
          }
          await addNewHistory(historyData);

          return { successful: true };
        } else {
          return { successful: false, errorMessage: "Please Select People" };
        }
      }
    }

    return {
      submit,
      selectedPeople,
      open,
      selectedEntity,
      noPeople,
    };
  },
};
</script>

<style scoped></style>
