<template>
  <div>
    <hi-list v-if="projects.length" :items="projects">
      <template v-slot="{ item }">
        <div class="flex items-center">
          <project-summary class="grow" no-time-display :project-data="item" />
          <hi-icon
            class="clickable-icon"
            :path="mdiOpenInNew"
            title="Link Account"
            @click="jumpToProject(item.id)"
          />
        </div>
      </template>
    </hi-list>
    <hi-view-no-data v-else />
  </div>
</template>

<script>
import { computed } from "vue";
import {
  getProjectsByAccountId,
  jumpToProjectById,
} from "@/hive-vue3/modules/projects/data";
import HiList from "@/hive-vue3/components/HiList";
import ProjectSummary from "@/hive-vue3/modules/projects/ProjectSummary";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { mdiOpenInNew } from "@mdi/js";
import { useRouter } from "vue-router";
import { accountState } from "@/hive-vue3/modules/accounts/state";
import HiViewNoData from "@/hive-vue3/views/HiViewNoData";

export default {
  name: "AccountsProjectsList",
  components: { HiViewNoData, HiIcon, ProjectSummary, HiList },
  setup() {
    const projects = computed(() => {
      return getProjectsByAccountId(accountState.currentAccountId);
    });

    const router = useRouter();

    function jumpToProject(projectId) {
      jumpToProjectById(router, projectId);
    }

    return {
      projects,
      jumpToProject,
      mdiOpenInNew,
    };
  },
};
</script>

<style scoped></style>
