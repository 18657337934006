<template>
  <hi-card>
    <div class="p-2 border-b-2">
      <div class="flex gap-4">
        <user-details-span class="w-[80px]" :user-id="item.uid" />
        <div class="w-[180px]">
          <div>Active: {{ getLeadsNumByUserId(item.uid) }}</div>
        </div>
        <div class="w-[180px]">
          <div>Closed: {{ getActiveLeadsNumByUserId(item.uid) }}</div>
        </div>
        <div class="w-[180px]">
          <div>Total: {{ getClosedLeadsNumByUserId(item.uid) }}</div>
        </div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import UserDetailsSpan from "@/hive-vue3/modules/users/UserDetailsSpan";
import {
  getActiveLeadsByUserId,
  getAllLeadsByUserId,
  getClosedLeadsByUserId,
} from "@/hive-vue3/modules/leads/data";
import { getCurrentFirebaseUser } from "@/hive-vue3/firebase";
import HiCard from "@/hive-vue3/components/HiCard";
export default {
  name: "MyLeadsNumCard",
  components: { HiCard, UserDetailsSpan },
  setup() {
    const user = getCurrentFirebaseUser();

    function getLeadsNumByUserId(userId) {
      return getAllLeadsByUserId(userId).length;
    }

    function getActiveLeadsNumByUserId(userId) {
      return getActiveLeadsByUserId(userId).length;
    }
    function getClosedLeadsNumByUserId(userId) {
      return getClosedLeadsByUserId(userId).length;
    }
    return {
      item: user,
      getLeadsNumByUserId,
      getActiveLeadsNumByUserId,
      getClosedLeadsNumByUserId,
    };
  },
};
</script>

<style scoped></style>
