<template>
  <hi-card class="h-full" v-if="phoneSettings[twilioState.currentNumber]">
    <div class="hi-ui-title">Voice Setting</div>

    <div class="p-2 border-b-2">
      <module-block-editable editable>
        <div class="my-4 font-bold">Working Hours</div>
        <hi-form-item class="my-4" label="Greeting">
          {{
            phoneSettings[twilioState.currentNumber]["greetings"][
              "workingHours"
            ]["greeting"]
          }}
        </hi-form-item>

        <hi-form-item label="Redirecting (Busy)">
          {{
            phoneSettings[twilioState.currentNumber]["greetings"][
              "workingHours"
            ]["redirecting"]
          }}
        </hi-form-item>
        <hi-form-item class="my-4" label="Ending (Normal)">
          {{
            phoneSettings[twilioState.currentNumber]["greetings"][
              "workingHours"
            ]["ending"]
          }}
        </hi-form-item>
        <hi-form-item class="my-4" label="Ending (No Answer)">
          {{
            phoneSettings[twilioState.currentNumber]["greetings"][
              "workingHours"
            ]["noAnswerEnding"]
          }}
        </hi-form-item>
        <template v-slot:editor="{ closeDialog }">
          <twilio-working-hours-greeting-editor
            class="w-[600px]"
            :initial-values="
              phoneSettings[twilioState.currentNumber]['greetings'][
                'workingHours'
              ]
            "
            @submit="closeDialog"
          />
        </template>
      </module-block-editable>
    </div>
    <div class="p-2">
      <module-block-editable editable>
        <div class="my-4 font-bold">After Hours (No Redirect)</div>
        <hi-form-item label="Greeting">
          {{
            phoneSettings[twilioState.currentNumber]["greetings"]["afterHours"][
              "greeting"
            ]
          }}
        </hi-form-item>
        <template v-slot:editor="{ closeDialog }">
          <twilio-after-hours-greeting-editor
            class="w-[600px]"
            :initial-values="
              phoneSettings[twilioState.currentNumber]['greetings'][
                'afterHours'
              ]
            "
            @submit="closeDialog"
          />
        </template>
      </module-block-editable>
    </div>
  </hi-card>
</template>

<script>
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiCard from "@/hive-vue3/components/HiCard";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { twilioState } from "@/hive-vue3/modules/twilio/state";
import ModuleBlockEditable from "@/hive-vue3/modules/_common/ModuleBlockEditable";
import TwilioWorkingHoursGreetingEditor from "@/hive-vue3/modules/twilio/TwilioWorkingHoursGreetingEditor";
import TwilioAfterHoursGreetingEditor from "@/hive-vue3/modules/twilio/TwilioAfterHoursGreetingEditor";
export default {
  name: "TwilioSencondColView",
  components: {
    TwilioAfterHoursGreetingEditor,
    TwilioWorkingHoursGreetingEditor,
    ModuleBlockEditable,
    HiCard,
    HiFormItem,
  },
  setup() {
    const phoneSettings = useFirestoreDoc("phone", "settings");
    return {
      phoneSettings,
      twilioState,
    };
  },
};
</script>

<style scoped></style>
