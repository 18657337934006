<template>
  <hi-dialog-trigger>
    <div
      class="flex gap-2 p-2"
      :style="{
        'background-color': color.isActive ? '' : 'rgba(180, 190, 180, 0.2)',
        color: color.isActive ? '' : 'grey',
      }"
    >
      <hi-vault-image class="w-20 h-20" :file-id="color.img" />
      <div v-if="!picOnly" class="grow w-0">
        <div>({{ color.shortNum }}) {{ color.name }}</div>

        <div class="tp-description">
          {{ color.type }} - {{ color.collection }}
        </div>
        <div class="tp-description truncate w-full">
          {{ color.description }}
        </div>
      </div>
    </div>
    <product-add-new-color-dialog
      v-if="editable"
      :initial-value="{ ...color, id: colorId || colorData.id }"
    />
  </hi-dialog-trigger>
</template>

<script>
import { computed } from "vue";
import HiDialogTrigger from "@/hive-vue3/components/dialogs/HiDialogTrigger";
import HiVaultImage from "@/hive-vue3/components/firebase/HiVaultImage";
import commonIcons from "@/hive-vue3/utils/constables/commonIcons";
import { decamelize } from "@/hive-vue3/utils/stringUtils";
import { decodeDocId } from "@/hive-vue3/firebase";
import ProductAddNewColorDialog from "@/hive-vue3/modules/products/productAddNewColorDialog";
import { getColorById } from "@/hive-vue3/modules/products/data";

export default {
  name: "productsColorSummary",
  components: { ProductAddNewColorDialog, HiVaultImage, HiDialogTrigger },
  props: {
    colorId: String,
    colorData: Object,
    picOnly: Boolean,
    editable: Boolean,
  },
  setup(props) {
    const color = computed(() => {
      if (props.colorData) return props.colorData;
      else {
        return getColorById(props.colorId);
      }
    });
    return {
      color,
      commonIcons,
      decamelize,
      decodeDocId,
    };
  },
};
</script>

<style scoped></style>
