<template>
  <div class="-HiAutocomplete">
    <!--    Need fully manage popup here as the popup logic is different from the usual dropdown components.-->
    <hi-dropdown-container
      :custom-popup="true"
      v-model="pop"
      :focus-fn="focus"
      :disabled="disabled"
      ref="container"
    >
      <!--      this is where to display the value, absolute and click thru-->
      <div
        class="hi-control-padding absolute inset-0 flex items-center ellipse z-1 pointer-events-none hi-inputable w-[99%]"
        v-if="displaySelected && !hasFocus"
        v-html="displaySelected"
      />
      <!--      {{ hasFocus }}{{ modelValue }}{{ displaySelected }}-->
      <!--      {{ isSuffixClearBtn && !!model }}-{{ isSuffixClearBtn }}-{{ !!model }}-->
      <input-base
        tabindex="-1"
        :placeholder="!hasFocus && displaySelected ? '' : placeholder"
        v-model="searchValue"
        class="hi-input"
        ref="input"
        @blur="onBlur"
        :error="error"
        :suffix-icon="isSuffixClearBtn ? mdiCloseThick : mdiPlus"
        :suffix-tip="isSuffixClearBtn ? 'Clear' : 'Add New'"
        :suffix-disabled="isSuffixClearBtn && !model"
        @suffix-click="suffixClicked"
        :auto-focus="autoFocus"
        :disabled="disabled"
      />
      <hi-form-error v-if="!hideError" v-model="error" />
      <template v-slot:dropdown>
        <hi-markable
          v-if="searchResult && searchResult.length"
          :separate-word-search="false"
          :search="searchValue"
        >
          <hi-list
            selectable
            :items="searchResult"
            @select="selectItem"
            keyboard
          >
            <template v-slot="{ item }">
              <slot :item="item">{{ formatDisplay(item) }}</slot>
            </template>
          </hi-list>
        </hi-markable>

        <div v-else class="p-3 text-gray-400">
          {{ noResult }}
        </div>
      </template>
    </hi-dropdown-container>
  </div>
</template>

<script>
import commonFormInputProps from "@/hive-vue3/components/form/logics/commonFormInputProps";
import { computed, nextTick, onMounted, ref } from "vue";
import {
  watchOnMounted,
  watchPropsOnMounted,
} from "@/hive-vue3/utils/reactiveHelpers/watchers";
import HiDropdownContainer from "@/hive-vue3/components/containers/HiDropdownContainer";
import InputBase from "@/hive-vue3/components/form/controls/base/InputBase";
import HiList from "@/hive-vue3/components/HiList";
import { templateRef } from "@vueuse/core";
import formControl from "@/hive-vue3/components/form/logics/formControl";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import { mdiCloseThick, mdiPlus } from "@mdi/js";
import { mountToSky } from "@/hive-vue3/utils/sky";
import { createComponent } from "@/hive-vue3/utils/componentUtils";
import { wait } from "@/hive-vue3/utils/miscs";
import { arrayRemove, arrayRemoveByKey } from "@/hive-vue3/utils/arrayUtils";
// import { tabbable } from "tabbable";
import { tabNext } from "@/hive-vue3/utils/keyboard/tabSimulator";
// import focusElement from "@/hive-vue3/utils/dom/focusElement";
import keyBlocker from "@/hive-vue3/utils/keyboard/keyBlocker";
import HiMarkable from "@/hive-vue3/components/HiMarkable";
export default {
  name: "HiAutocomplete",
  components: {
    HiMarkable,
    HiFormError,
    HiList,
    InputBase,
    HiDropdownContainer,
  },
  props: {
    ...commonFormInputProps,
    modelValue: null,
    placeholder: String,
    minSearchLength: {
      type: Number,
      default: 1,
    },
    // valueKey: String,
    noResult: {
      type: String,
      default: "No data available...",
    },
    items: Array,
    /**
     * @deprecated use valueKey
     */
    itemValueKey: String,
    /**
     * @deprecated use textKey
     */
    itemDisplayKey: String,
    textKey: {
      type: String,
    },
    valueKey: {
      type: String,
    },
    excludes: Array,
    searchFn: {
      type: Function,
    },
    /**
     * a HiDialog or HiDialogButton component with 'added' event
     * 注意需要再外部处理新加进来的items，譬如 items 是 reactive firestore 对象就无需处理。
     */
    addNewDialogComponent: Object,
    //below props will be irrelevant if searchFn provided
    searchKeys: [String, Array],
    caseSensitive: Boolean,
    fromStartOnly: Boolean,
    formatDisplayFn: Function,
  },
  emits: ["change", "update:modelValue"],
  setup(props, context) {
    const input = templateRef("input");
    const container = templateRef("container");
    const control = formControl(props, context, {
      onModelValueUpdate,
    });
    const searchValue = ref("");
    const displaySelected = ref();
    const pop = ref(false);
    let doSearchVal;
    function doSearch(string) {
      if (!string || !string.length) return false;
      // if(props.excludes && props.excludes.indexOf(string)>=0)return false;
      if (!props.caseSensitive) {
        string = string.toLowerCase();
      }
      const index = string.indexOf(doSearchVal);
      return props.fromStartOnly ? index === 0 : index >= 0;
    }
    function defaultSearch() {
      doSearchVal = props.caseSensitive
        ? searchValue.value
        : searchValue.value.toLowerCase();
      const src = props.items;
      if (!src || !src.length) {
        console.warn("standardListSearch found empty source!");
        return;
      }
      const searchKeys = props.searchKeys;
      //default search treat source items as Strings
      if (!searchKeys || !searchKeys.length) {
        return src.filter(doSearch);
      } else {
        return src.filter((item) => {
          if (Array.isArray(searchKeys)) {
            for (let i = 0; i < searchKeys.length; i++) {
              if (doSearch(item[searchKeys[i]])) return true;
            }
            return false;
          } else {
            const val = item[searchKeys];
            return doSearch(val);
          }
        });
      }
    }
    const searchResult = ref([]);
    function valueKey() {
      return props.valueKey || props.itemValueKey;
    }
    async function search() {
      if (searchValue.value.length < props.minSearchLength) return;
      let result;
      if (props.searchFn) {
        result = await props.searchFn(searchValue.value);
      } else {
        result = defaultSearch();
      }
      if (props.excludes && props.excludes.length) {
        // console.log(props.excludes);
        // console.log(result);
        if (valueKey()) arrayRemoveByKey(result, valueKey(), ...props.excludes);
        else {
          arrayRemove(result, ...props.excludes);
        }
      }
      searchResult.value = result;
      // console.log(searchResult.value.length);
    }
    //solve the problem with v-if. When component removed by reactive value and remount again but the modelValue not changed during the process.
    watchOnMounted(searchValue, (value) => {
      if (!value) return;
      pop.value = value.length >= props.minSearchLength;
      search();
    });
    watchPropsOnMounted(
      props,
      ["items", "searchKeys", "caseSensitive", "fromStartOnly"],
      search
    );
    function onModelValueUpdate(value) {
      // console.log("hiAutocomplete model change", value);
      if (value === null || value === undefined) displaySelected.value = null;
      if (props.items && valueKey()) {
        for (let i = 0; i < props.items.length; i++) {
          const item = props.items[i];
          if (item[valueKey()] === value) {
            setValue(item);
            return;
          }
        }
        //nothing found in items, just display the value for now
        control.model.value = value;
      } else {
        setValue(value);
      }
    }
    onMounted(() => {
      if (props.modelValue) onModelValueUpdate(props.modelValue);
    });
    function selectItem(item) {
      // console.log(item);
      pop.value = false;
      setValue(item);
      // console.log("control.model.value", control.model.value);
      context.emit("change", control.model.value);
      tabNext(container);
      // const tabables = tabbable(document.body);
      // console.log(tabables);
      // console.log(tabables.indexOf(input.value));
      // console.log(input.value);
    }

    function setValue(item) {
      const textKey = props.textKey || props.itemDisplayKey;
      if (props.formatDisplayFn) {
        displaySelected.value = props.formatDisplayFn(item);
      } else if (textKey) {
        displaySelected.value = item[textKey];
      } else if (valueKey()) {
        displaySelected.value = item[valueKey()];
      } else {
        displaySelected.value = item;
      }
      // console.log(item);
      // console.log(displaySelected.value);
      searchValue.value = "";
      control.model.value = valueKey() ? item[valueKey()] : item;
      // console.log(control.model.value);
    }

    async function onBlur() {
      //wait to see if the dialog is opening
      await wait(100);
      if (isAddingNew) return;
      await control.touch();

      if (displaySelected.value || !control.model.value) {
        searchValue.value = "";
      }
      pop.value = false;
    }
    function clear() {
      control.model.value = undefined;
      searchValue.value = "";
      displaySelected.value = null;
    }
    function suffixClicked() {
      if (isSuffixClearBtn.value) {
        clear();
        focus();
      } else {
        openAddNewDialog();
      }
    }
    let isAddingNew = false;
    function openAddNewDialog() {
      if (!props.addNewDialogComponent) return;
      isAddingNew = true;
      const dialog = createComponent(props.addNewDialogComponent, {
        modelValue: true,
        onAdded: newValueAdded,
        hideButton: true,
        "onUpdate:modelValue": dialogClosed,
      });
      // console.log(dialog);
      mountToSky(dialog);
    }

    function newValueAdded(data) {
      selectItem(data);
    }
    function dialogClosed() {
      // console.log("closed");
      isAddingNew = false;
      if (!control.model.value) focus();
      else tabNext(container);
    }
    function focus() {
      // console.log("autocomplete trying to set focus");
      // focusElement(input);
      nextTick().then(() => {
        input.value.focus();
      });
    }
    const isSuffixClearBtn = computed(() => {
      if (props.addNewDialogComponent) {
        return !!control.model.value;
      } else {
        return true;
      }
    });
    //block up & down
    keyBlocker(input, 38, 40);
    return {
      searchValue,
      searchResult,
      selectItem,
      pop,
      displaySelected,
      hasFocus: computed(() => input.value && input.value.hasFocus),
      onBlur,
      error: control.error,
      mdiPlus,
      mdiCloseThick,
      isSuffixClearBtn,
      suffixClicked,
      model: control.model,
      focus,
      formatDisplay(item) {
        if (props.formatDisplayFn) return props.formatDisplayFn(item);
        const textKey = props.textKey || props.itemDisplayKey;
        if (textKey) return item[textKey];
        return item;
      },
    };
  },
};
</script>

<style scoped></style>
