<template>
  <hi-form-dialog-hive-standard
    :submit-fn="submit"
    width="600px"
    title="Add New Enquiry"
    button-text="Add New Enquiry"
    text-button
  >
    <hi-form-item label="Name *">
      <hi-input mandatory form-key="name" />
    </hi-form-item>
    <hi-form-item label="Email *">
      <hi-input mandatory email form-key="email" />
    </hi-form-item>
    <hi-form-item label="Phone Number *">
      <hi-input mandatory phone form-key="phoneNum" />
    </hi-form-item>
    <hi-form-item label="Postcode *">
      <hi-input form-key="postcode" />
    </hi-form-item>
    <hi-form-item label="Company">
      <hi-input form-key="company" />
    </hi-form-item>
    <hi-form-item label="Resources *">
      <hi-chips-selectable
        mandatory
        multiple
        :items="{
          Requests: 'Requests',
          Samples: 'Samples',
          'Product Information': 'Product Information',
          'Shop Visit': 'Shop Visit',
        }"
        value-key="requestValue"
        text-key="requestName"
        form-key="requests"
      />
    </hi-form-item>
    <hi-form-item label="Sources">
      <hi-chips-selectable
        multiple
        :items="{
          onlineAds: 'Online Ads',
          printAds: 'Print Ads',
          wordOfMouth: 'World of Mouth',
          designer: 'Designer',
          builder: 'Builder',
          social: 'Social',
          search: 'Search',
          other: 'Other',
        }"
        value-key="sourcesValue"
        text-key="sourcesName"
        form-key="sources"
      />
    </hi-form-item>
  </hi-form-dialog-hive-standard>
</template>

<script>
import HiFormDialogHiveStandard from "@/hive-vue3/components/form/HiFormDialogHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { requestAddEnquiry } from "@/hive-vue3/api/request";
import { pipeIdGenerator } from "@/hive-vue3/modules/utils/utils";
export default {
  name: "NewEnquiryDialog",
  components: {
    HiChipsSelectable,
    HiInput,
    HiFormItem,
    HiFormDialogHiveStandard,
  },
  setup() {
    async function submit(data) {
      data.from = "my-artimber";
      data.pipeId = pipeIdGenerator();
      const res = await requestAddEnquiry(data);

      return { successful: res.successful, errorMessage: res.errorMsg };
    }
    return {
      submit,
    };
  },
};
</script>

<style scoped></style>
