<template>
  <hi-dialog title="Add New Color" close-button width="600px" v-model="open">
    <hi-form-hive-standard
      @cancel="open = false"
      @successful="open = false"
      :submit-fn="submit"
      cancel-button
      :initial-values="initialValue"
    >
      <hi-form-item label="Type *">
        <hi-select
          :items="{
            UV: 'UV Lacquer',
            RS: 'Reactive Stain',
          }"
          mandatory
          form-key="type"
        ></hi-select>
      </hi-form-item>
      <hi-form-item label="Collection *">
        <hi-select
          :items="{
            Emerging: 'Emerging',
            Enhancing: 'Enhancing',
          }"
          mandatory
          form-key="collection"
        ></hi-select>
      </hi-form-item>
      <hi-form-item label="Color Name*">
        <hi-input form-key="name" mandatory />
      </hi-form-item>
      <hi-form-item label="Short Number*">
        <hi-input form-key="shortNum" upper-case mandatory />
      </hi-form-item>
      <hi-form-item label="Description*">
        <hi-textarea form-key="description" :rows="2" mandatory />
      </hi-form-item>
      <hi-form-item label="Color Photo*">
        <hi-vault-image-control form-key="img" />
      </hi-form-item>
    </hi-form-hive-standard>

    <template v-if="initialValue && initialValue.id" v-slot:more>
      <hi-button
        v-if="initialValue.isActive"
        class="ml-5"
        @click="deactivateColor"
        >Deactivate</hi-button
      >

      <hi-button v-else class="ml-5" @click="reactivateColor"
        >Reactivate</hi-button
      >
    </template>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { ref } from "vue";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiInput from "@/hive-vue3/components/form/controls/HiInput";
import HiTextarea from "@/hive-vue3/components/form/controls/HiTextarea";
import HiVaultImageControl from "@/hive-vue3/components/form/controls/HiVaultImageControl";
import HiSelect from "@/hive-vue3/components/form/controls/HiSelect";
import {
  addColorsToFirebase,
  updateColorToFirebase,
} from "@/hive-vue3/modules/products/data";
import HiButton from "@/hive-vue3/components/HiButton";
export default {
  name: "productAddNewColorDialog",
  components: {
    HiButton,
    HiSelect,
    HiVaultImageControl,
    HiTextarea,
    HiInput,
    HiFormItem,
    HiFormHiveStandard,
    HiDialog,
  },
  props: {
    initialValue: Object,
  },
  setup(props) {
    const open = ref(false);

    async function submit(data) {
      data.name = data.name.trim();
      if (props.initialValue && props.initialValue.id) {
        const res = await updateColorToFirebase(data, props.initialValue.id);
        if (res) {
          return { successful: true };
        }
        return { successful: false, errorMessage: "Color Already Exits" };
      } else {
        const res = await addColorsToFirebase({ ...data, isActive: true });
        if (res) {
          return { successful: true };
        }
        return { successful: false, errorMessage: "Color Already Exits" };
      }
    }

    async function deactivateColor() {
      if (props.initialValue && props.initialValue.id) {
        await updateColorToFirebase(
          {
            type: props.initialValue.type,
            isActive: false,
          },
          props.initialValue.id
        );
      }
    }

    async function reactivateColor() {
      if (props.initialValue && props.initialValue.id) {
        await updateColorToFirebase(
          {
            type: props.initialValue.type,
            isActive: true,
          },
          props.initialValue.id
        );
      }
    }

    return {
      open,
      submit,
      deactivateColor,
      reactivateColor,
    };
  },
};
</script>

<style scoped></style>
