<template>
  <div v-if="show" class="w-full h-full"><slot /></div>
</template>

<script>
import { computed, inject } from "vue";

export default {
  name: "HiTab",
  props: {
    label: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const tabs = inject("hi-tabs");
    tabs.register(props.label);
    const show = computed(() => {
      // console.log(tabs.currentTab);
      return tabs.currentTab.value === props.label;
    });
    return {
      show,
    };
  },
};
</script>

<style scoped></style>
