<template>
  <div
    class="flex items-center p-4"
    v-if="labelIds"
    :class="{
      unread: labelIds.indexOf('UNREAD') === -1,
    }"
  >
    <div class="grow" v-if="latestMail.headers">
      <div class="tp-subtitle flex">
        <div class="ellipse-flex-grow-item">
          {{ latestMail.headers.subject }}
        </div>
      </div>

      <div class="tp-description-sm">
        {{ latestMail.inbox ? "Inbox - Received From" : "Outbox - Send To" }}
        :
        <span v-if="latestMail.inbox" v-html="latestMail.headers.from" />
        <span v-else v-html="latestMail.headers.to" />
      </div>
      <div class="grow"></div>
      <div class="tp-description-sm" v-if="labelIds">
        {{ labelIds.indexOf("UNREAD") === -1 ? "" : "Unread" }}
      </div>
      <div class="tp-description-sm" v-if="attachments.length">
        {{ attachments.length }}
        {{ attachments.length === 1 ? "Attachement" : "Attachments" }}
      </div>
    </div>

    <div>
      <div class="tp-description-sm text-right flex flex-col">
        <!--        {{ tag }}-->

        <div>Last refresh:</div>
        <hi-date-time-display :model-value="latestMail.internalDate" friendly />
      </div>
    </div>
  </div>
</template>

<script>
import useFirestoreDocFirstInQuery from "@/hive-vue3/firebase/useFirestoreDocFirstInQuery";
import dbPaths from "@/hive-vue3/modules/gmail/dbPaths";
import queryBuilder from "@/hive-vue3/firebase/fireStoreQueryBuilder";
import hiDateTimeDisplay from "@/hive-vue3/components/misc/HiDateTimeDisplay";
import { useMailsInThread } from "@/hive-vue3/modules/gmail/data";
import { computed } from "vue";

export default {
  name: "ThreadListItem",
  props: {
    threadId: String,
    labelIds: Array,
  },
  components: {
    hiDateTimeDisplay,
  },
  setup(props) {
    // const tag = getThreadTag(props.threadId);
    const query = queryBuilder(dbPaths.allMail);
    query.orderBy("internalDate", "desc");
    query.where("threadId", "==", props.threadId);
    const latestMail = useFirestoreDocFirstInQuery(dbPaths.allMail, query);

    const allMails = useMailsInThread(props.threadId);

    const attachments = computed(() => {
      const returnArr = [];
      if (allMails.value) {
        for (const i of allMails.value) {
          if (i.attachments) {
            for (const j of i.attachments) {
              if (returnArr.indexOf(j.fileId) === -1) {
                returnArr.push(j.fileId);
              }
            }
          }
        }
      }
      return returnArr;
    });

    return {
      latestMail,
      // tag,
      attachments,
    };
  },
};
</script>

<style scoped>
.unread {
  background: rgba(242, 245, 245, 0.9);
}
</style>
