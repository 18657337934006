<template>
  <hi-attachment
    :file="file"
    :can-delete="canDelete"
    :can-upload="canReplace"
    @upload="$emit('replace')"
    @delete="$emit('delete')"
  />
</template>

<script>
import { watchEffect } from "vue";
import { useVaultFileQueryable } from "@/hive-vue3/firebase/fileVault";
import HiAttachment from "@/hive-vue3/components/HiAttachment";

/**
 * trade off: 必须定义高度，不然会导致上传和预览高度不一致。
 * This component doesn't really replace or delete files.  Only emit events.
 */
export default {
  name: "HiVaultFile",
  components: { HiAttachment },
  emits: ["replace", "delete"],
  props: {
    fileId: String,
    canReplace: Boolean,
    canDelete: Boolean,
  },
  setup(props) {
    const fileQ = useVaultFileQueryable(props.fileId);
    watchEffect(() => {
      // console.log(props.fileId);
      fileQ.setId(props.fileId);
    });
    // watchEffect(() => {
    //   console.log(fileQ.data);
    // });
    return {
      file: fileQ.data,
    };
  },
};
</script>

<style scoped></style>
