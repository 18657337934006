<template>
  <hi-dialog v-model="open" width="600px">
    <hi-form-hive-standard
      :initial-values="initialValues"
      :submit-fn="submit"
      @successful="open = false"
    >
      <hi-form-item label="Recipient Groups *">
        <hi-chips-selectable
          :items="groupDetails"
          multiple
          mandatory
          form-key="groups"
          v-model="selectedGroups"
        />
      </hi-form-item>
      <pre>{{ recipientNum }}</pre>
    </hi-form-hive-standard>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import { computed, ref, watch } from "vue";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiFormItem from "@/hive-vue3/components/form/HiFormItem";
import HiChipsSelectable from "@/hive-vue3/components/form/controls/HiChipsSelectable";
import { firestoreDoc, updateFirestoreDoc } from "@/hive-vue3/firebase";
import { edmState } from "@/hive-vue3/modules/edm/state";
import { getLeadsByGroups } from "@/hive-vue3/modules/leads/data";
import { getContactsPeopleByGroup } from "@/hive-vue3/modules/contacts/data";
export default {
  name: "EdmChangeRecipientGroupDialog",
  components: { HiChipsSelectable, HiFormItem, HiFormHiveStandard, HiDialog },
  props: {
    initialValues: Object,
    recipientType: String,
  },
  setup(props) {
    const open = ref(false);
    const selectedGroups = ref();
    const recipientNum = computed(() => {
      if (selectedGroups.value && selectedGroups.value.length) {
        if (props.recipientType === "people") {
          console.log("people");
          const total = getContactsPeopleByGroup(selectedGroups.value);
          return total.length;
        } else if (props.recipientType === "leads") {
          const total = getLeadsByGroups(selectedGroups.value);
          return total.length;
        }
      } else {
        return 0;
      }
      return 0;
    });
    watch(
      () => open.value,
      (v) => {
        if (v) {
          selectedGroups.value = undefined;
        }
      }
    );

    const groupAssets = useFirestoreDoc("edm", "assets");

    const groupDetails = computed(() => {
      return groupAssets.mailGroups;
    });

    async function submit(data) {
      await updateFirestoreDoc(
        firestoreDoc("edm/campaigns/registers", edmState.currentEdmId),
        {
          groups: data.groups,
        }
      );
      return {
        successful: true,
      };
    }

    return {
      open,
      groupDetails,
      submit,
      selectedGroups,
      recipientNum,
    };
  },
};
</script>

<style scoped></style>
