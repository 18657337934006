<template>
  <span
    class="hi-tool-btn"
    :class="{ disabled: disabled, active: toggleOn }"
    :title="tooltip"
  >
    <hi-icon :path="icon" v-if="icon" />
    <slot />
  </span>
</template>

<script>
import HiIcon from "@/hive-vue3/components/HiIcon";
export default {
  name: "HiToolButton",
  components: { HiIcon },
  props: {
    toggleOn: Boolean,
    disabled: Boolean,
    icon: String,
    tooltip: String,
  },
};
</script>

<style scoped></style>
