<template>
  <hi-dialog :title="`Link Contact to Enquiry`" v-model="open" width="600px">
    <hi-div-with-submit-status-container
      :submit-fn="submit"
      submit-button
      submit-enable
      submit-text="Link"
      cancel-button
      @cancel="open = false"
      @successful="open = false"
    >
      <contacts-people-summary :people-id="contactId" hide-entity />
      <div class="font-bold my-4">Please select entity below</div>
      <hi-list :items="people.entities" selectable v-model="selectEntity">
        <template v-slot="{ item: entity }">
          <contacts-entity :entity-id="entity" />
        </template>
      </hi-list>
    </hi-div-with-submit-status-container>
  </hi-dialog>
</template>

<script>
import HiDialog from "@/hive-vue3/components/dialogs/HiDialog";
import {
  formatContactsPeopleName,
  getContactsEntityNameById,
  getContactsPeople,
} from "@/hive-vue3/modules/contacts/data";
import { computed, ref } from "vue";
import ContactsPeopleSummary from "@/hive-vue3/modules/contacts/ContactsPeopleSummary";
import HiList from "@/hive-vue3/components/HiList";
import HiDivWithSubmitStatusContainer from "@/hive-vue3/components/containers/HiDivWithSubmitStatusContainer";
import ContactsEntity from "@/hive-vue3/modules/contacts/ContactsEntity";
import { addContactToEnquiry } from "@/hive-vue3/modules/enquiries/data";
import { enquiryState } from "@/hive-vue3/modules/enquiries/state";
import {
  addNewHistory,
  DefaultHistoryData,
  historyActions,
} from "@/hive-vue3/modules/history/data";

export default {
  name: "EnquiryLinkContactDialog",
  components: {
    ContactsEntity,
    HiDivWithSubmitStatusContainer,
    HiList,
    ContactsPeopleSummary,
    HiDialog,
  },
  props: {
    contactId: String,
  },
  setup(props) {
    const open = ref(false);
    const people = computed(() => getContactsPeople(props.contactId));

    const selectEntity = ref(undefined);

    async function submit() {
      const historyObj = new DefaultHistoryData();
      historyObj.action = historyActions.LINK;
      historyObj.target = "enquiry";
      historyObj.targetId = enquiryState.currentEnquiryId;
      historyObj.comments = `Link ${formatContactsPeopleName(
        people.value
      )} of ${getContactsEntityNameById(selectEntity.value)}`;
      historyObj.item = "Contact";
      historyObj.value = {
        peopleId: props.contactId,
      };
      historyObj.oldValue = null;
      await addNewHistory(historyObj);

      await addContactToEnquiry(
        enquiryState.currentEnquiryId,
        props.contactId,
        selectEntity.value
      );

      return { successful: true };
    }
    return {
      submit,
      people,
      open,
      selectEntity,
    };
  },
};
</script>

<style scoped></style>
