<template>
  <hi-card>
    <div class="p-4 flex flex-col gap-2">
      <div class="flex gap-4">
        <div>Active Accounts:</div>
        <div>{{ activeNumber }}</div>
      </div>
      <div class="flex gap-4">
        <div>Closed Accounts:</div>
        <div>{{ closedNumber }}</div>
      </div>
    </div>
  </hi-card>
</template>

<script>
import HiCard from "@/hive-vue3/components/HiCard";
import { computed } from "vue";
import { getActiveLeads, getClosedLeads } from "@/hive-vue3/modules/leads/data";
export default {
  name: "BasicLeadsNumCard",
  components: { HiCard },
  setup() {
    const activeNumber = computed(() => {
      const res = getActiveLeads();
      return res.length;
    });
    const closedNumber = computed(() => {
      const res = getClosedLeads();
      return res.length;
    });

    return {
      activeNumber,
      closedNumber,
    };
  },
};
</script>

<style scoped></style>
